
export const parseDefaultCustomizeTermsPayloadFromServer = (
    isSpecialty: boolean,
    values,
  ) => {
    const paragraphs = [];
  
    const getValue = (key: string) => {
      return values[key] ? values[key].toString() : '';
    };
  
    paragraphs.push({
      key: 'Term1',
      systemReference: 'Term1',
      value: getValue('Term1'),
      position: 1,
    });
  
    paragraphs.push({
      key: 'Term2',
      systemReference: 'Term2',
      value: getValue('Term2'),
      position: 2,
    });
  
    paragraphs.push({
      key: 'Term3',
      systemReference: 'Term3',
      value: getValue('Term3'),
      position: 3,
    });
  
    paragraphs.push({
      key: 'Term4',
      systemReference: 'Term4',
      value: getValue('Term4'),
      position: 4,
    });
  
    paragraphs.push({
      key: 'Term5',
      systemReference: 'Term5',
      value: getValue('Term5'),
      position: 5,
    });
  
    paragraphs.push({
      key: 'Term6',
      systemReference: 'Term6',
      value: getValue('Term6'),
      position: 6,
    });
  
    if (!isSpecialty) {
      paragraphs.push({
        key: 'Term7',
        systemReference: 'Term7',
        value: getValue('Term7'),
        position: 7,
      });
  
      paragraphs.push({
        key: 'Term8',
        systemReference: 'Term8',
        value: getValue('Term8'),
        position: 8,
      });
  
      paragraphs.push({
        key: 'Term9',
        systemReference: 'Term9',
        value: getValue('Term9'),
        position: 9,
      });
    }
  
    return {
      id: isSpecialty
        ? values['FranchiseSSFieldsId']
        : values['FranchiseBidFieldsId'],
      franchiseId: values['FranchiseId'],
      introduction: values['Introduction']
        ? values['Introduction'].toString()
        : '',
      paragraphs: paragraphs,
    };
  };
  
  export const parseDefaultCustomizeTermsPayloadToServer = (
    isSpecialty: boolean,
    values,
  ) => {
    const apiPayload = {};
    if (isSpecialty) {
      apiPayload['FranchiseSSFieldsId'] = values.id;
    } else {
      apiPayload['FranchiseBidFieldsId'] = values.id;
    }
  
    apiPayload['FranchiseId'] = values.franchiseId;
    apiPayload['Introduction'] = values.introduction;
  
    values.paragraphs.map((paragraph, index) => {
      apiPayload[`Term${index + 1}`] = paragraph.value;
    });
  
    return apiPayload;
  };
  