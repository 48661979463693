import React, { useState, useEffect } from 'react';
import {
  getBidInfoById,
  getFranchiseById,
  getByUniqID,
} from '../../services/bidInfoServiceClient';
import { postBidInfo } from 'modules/BidInfoForm/services/bidInfoService';
import Pages from './pages';
import { getQueryString, isWebView } from '../../../../core/utils';
import { parseAfterPost, parseFromApi } from '../../parser';

const proposalExpired = (isExpired) => {
  if (isExpired) {
    document.location.replace('#/proposal/expired');
    return true;
  }

  return false;
};

const proposalSold = (isSold) => {
  if (isSold) {
    document.location.replace('#/proposal/sold');
    return true;
  }

  return false;
};

if ((!!window.performance && window.performance.navigation.type === 2)) {
  window.location.reload(); // reload whole page
}

window.onpageshow = function(event) {
  if (event.persisted) {
      window.location.reload() 
  }
};

export const Proposal = ({ location, history }) => {
  const [bidInfo, setBidInfo] = useState(null);
  const [franchise, setFranchise] = useState(null);
  const [parsedData, setParsedData] = useState(null);
  const currentUrl = location.search;
  const bidInfoId = getQueryString(currentUrl, 'formId');
  const bidInfoUniqueId = getQueryString(currentUrl, 'formUniqueId');
  const franchiseId = getQueryString(currentUrl, 'franchiseId');


  useEffect(() => {
    const rootDiv = document.getElementById('root');
    if (rootDiv) {
      rootDiv.scrollTo({
        top: 0
      });
    }
  }, [])

  useEffect(() => {
    if (!bidInfo) {
      if (bidInfoUniqueId) {
        getByUniqID(bidInfoUniqueId).then(({ data }) => {
          if (
            proposalSold(data['Data']['Status'] == 2) ||
            proposalExpired(data['Data'].IsExpired)
          ) {
            return;
          }
          getFranchiseById(franchiseId).then(({ data: franchiseData }) => {
            setBidInfo(data['Data']);
            setFranchise(franchiseData);
            setParsedData(parseFromApi(data['Data']));
          });
        });
      } else if (bidInfoId) {
        getBidInfoById(bidInfoId).then(({ data }) => {
          if (
            proposalSold(data['Data']['Status'] == 2) ||
            proposalExpired(data['Data'].IsExpired)
          ) {
            return;
          }
          getFranchiseById(franchiseId).then(({ data: franchiseData }) => {
            setBidInfo(data['Data']);
            setFranchise(franchiseData);
            setParsedData(parseFromApi(data['Data']));
          });
        });
      }
    }
  }, [getBidInfoById, getFranchiseById]);

  useEffect(() => {
    // if (isWebView() && openingPDF === 'true') {
    //   history.replace({
    //     pathname: '/bid-info-form/menu',
    //     search: `?userId=${franchiseId}`,
    //   });
    // }
    if (isWebView()) {
      history.goBack();
    }
  }, [history]);

  return (
    <>
      <Pages
        data={bidInfo}
        parsedData={parsedData}
        franchiseData={franchise}
        update={(values, oldValues, callback) => {
          postBidInfo(values).then((result) => {
            setBidInfo(parseAfterPost(values, oldValues, result.data.id));
            callback();
          });
        }}
      />
    </>
  );
};
