import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { LoadingPage } from 'modules/BidInfoForm/components/organisms';
import { HiddenAnchor } from 'modules/BidInfoForm/components/atoms';
import { PdfFooter } from './styles';
import { Button } from 'core/components';
import {
  getBase64ByParams,
  getBase64ByUrl,
  getDirectPdfDownloadUrl,
} from '../../../modules/BidInfoForm/services/bidInfoService';

export const RenderPdf = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [pdfBase64, setPdfBase64] = React.useState(null);
  const [pages, setPages] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);

  const { pdfName, onClickClose } = props;

  React.useEffect(() => {
    if (props.pdfUrl) {
      setPdfUrl(props.pdfUrl);
    } else if (props.type && props.uid) {
      (async () => {
        const { data } = await getBase64ByParams(props.type, props.uid, false);
        setPdfUrl(data['URL']);
      })();
    }
  }, [props.type, props.uid, props.pdfUrl]);

  function determineNewHeight(originalHeight, originalWidth, newWidth) {
    return (originalHeight / originalWidth) * newWidth;
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);

    setTimeout(() => {
      const wrapper = document.getElementById('pdf-wrapper');
      const wrapperWidth = wrapper.clientWidth;

      for (let item of wrapper.getElementsByTagName('canvas')) {
        const originalHeight = item.height;
        const originalWidth = item.width;

        const newHeight = determineNewHeight(
          originalHeight,
          originalWidth,
          wrapperWidth
        );

        item.style.width = `${wrapperWidth}px`;
        item.style.height = `${newHeight}px`;
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 100);
  };

  const onClickDownload = async (uid: string = null) => {
    setIsLoading(true);
    document.getElementById('hiddenAnchor').click();
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  return (
    <>
      {isLoading && <LoadingPage />}
      <div
        id="pdf-wrapper"
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(pages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={2} />
          ))}
        </Document>
        <PdfFooter
          style={{
            position: onClickClose ? 'absolute' : 'fixed',
          }}
        >
          {onClickClose && (
            <Button
              reverseSkew
              bgColor="#216eab"
              disabledStyles={{
                color: '#fff',
                background: '#ccc',
              }}
              onClick={onClickClose}
            >
              DONE
            </Button>
          )}
          <Button
            reverseSkew
            bgColor="#216eab"
            disabledStyles={{
              color: '#fff',
              background: '#ccc',
            }}
            onClick={onClickDownload}
          >
            DOWNLOAD
          </Button>
        </PdfFooter>
      </div>
      <HiddenAnchor
        href={getDirectPdfDownloadUrl(pdfUrl, pdfName.replace(/\&/, ' '))}
        download={`${pdfName.replace(/\&/, ' ')}`}
        id="hiddenAnchor"
        title="download"
      />
    </>
  );
};

export default RenderPdf;
