import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFilePdf,
  faEnvelope,
  faTimes,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { GenericAccordion, Button } from 'core/components';
import {
  isWebView,
  decodeEntities,
  stripHTMLTags,
  isSafari14,
} from 'core/utils';
import { LoadingPage } from 'modules/BidInfoForm/components/organisms';

import {
  Header,
  Heading,
  Text,
  AccordionHeader,
  ButtonHolder,
  CellHolder,
  EmailTextHolder,
  Actions,
  ActionButtonWrapper,
  ActionButtonLabel,
  AccordionBody,
  IconWrapper,
  ModalTopActions,
  ButtonLabel,
  EmailItem,
  Cell,
  Row,
  RowHolder,
  Button as Btn,
} from './styles';

import { toast } from 'react-toastify';
import { ModalWrapper, Layout } from 'modules/BidInfoForm/components/layout';
import { getPerformanceUrl } from '../../../BidInfoForm/utils';
const openEmailApp = (data: any) => {
  const url = `mailto:${data.to}?subject=${data.subject}&body=${
    isSafari14() ? data.body : encodeURIComponent(data.body)
  }`;

  window.open(url, 'New Email');
};

const openPerformance = (url: string) => {
  const str = isWebView() ? `${url}?code=print-pdf` : url;

  // const str = `${url}?code=print-pdf`;
  window.open(str, '_blank');
};

const isUniqueId = (hash: string) => hash.split('-').length > 1;

export const SummarySuccess = (props) => {
  const { params } = props.match;
  const { state = {} } = props.location;
  const buttonProps = {
    color: '#fff',
    bgColor: '#216eab',
    width: '115px !important',
    padding: '0 10px',
    skewable: false,
    borderRadius: '3px',
    height: '44px',
  };
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const emails = params.emails ? params.emails.split(';') : [];
  const emailBody = state.pefEmail;
  const bodyRef = React.useRef<HTMLPreElement>();
  const toRef = React.useRef<HTMLDivElement>();

  const textCopy =
    state.userName +
    ' has invited you to provide feedback on your Janitorial Services using the link below.\r\nClick Here: ' +
    decodeURI(state.encodedUrl) +
    '\r\nThank you.';

  function executeCopy(text) {
    var input = document.createElement('textarea');
    document.body.appendChild(input);
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('Copy');
    input.remove();
  }

  const copyTo = () => {
    if (!navigator.clipboard) {
      var doc = new DOMParser().parseFromString(
        document.getElementById('copyTo').innerHTML,
        'text/html'
      );
      executeCopy(doc.body.textContent);
      showToast();
      return;
    }

    const text = Array.from(toRef.current.querySelectorAll('div'))
      .map((x) => x.textContent)
      .concat([''])
      .join(',');

    navigator.clipboard.writeText(text);
    showToast();
  };

  const copyBody = () => {
    if (!navigator.clipboard) {
      var doc = new DOMParser().parseFromString(
        document.getElementById('copyBody').innerHTML,
        'text/html'
      );
      executeCopy(doc.body.textContent);
      showToast();
      return;
    }

    const text = decodeEntities(bodyRef.current.innerHTML);
    navigator.clipboard.writeText(text);
    showToast();
  };

  const onDoneButtonClick = () => {
    if (isWebView()) {
      // It's a webview, so we call the close-form route
      // to trigger the Xamarin webview
      // setLoadingOnCloseState(true);
      props.history.replace('close-form');
    } else {
      /*
        It's a regular desktop browser.
        In this case, we redirect to the
        Bid Information List screen on the
        Backend App Portal

        App URLs:
        https://anago-forms-app-stag.herokuapp.com
        https://anago-forms-app-prod.herokuapp.com/
        https://anago-forms-app-rc.herokuapp.com/
      */
      // const url = ''; //getBidURL();
      document.location.replace(getPerformanceUrl());
    }
  };

  const onOpenEmailPerformanceClick = () => {
    openEmailApp({
      to: emails.join(','),
      subject: 'Anago Service Performance Evaluation',
      body: emailBody,
    });
  };

  const onCopyToClipBoard = () => {
    executeCopy(textCopy);
    showToast();
  };

  const onOpenPerformanceClick = () => {
    openPerformance(state.encodedUrl);
  };

  const showToast = () => {
    toast.info('Copied to Clipboard!');
  };

  const onCloseModalClick = () => {
    onDoneButtonClick();
  };

  // React.useEffect(() => {
  //   const fetch = async () => {
  //     setIsLoading(true);
  //     const method = isUniqueId(params.id) ? (param) => {} : (param) => {}; //getByUniqID : getBidInfoById;
  //     const response = await method(params.id);

  //     setData(response.data.Data);
  //     setIsLoading(false);
  //   };

  //   if (params.id) {
  //     fetch();
  //   }
  // }, [params.id]);

  if (isLoading) {
    return <LoadingPage />;
  }
  console.debug({ params });
  return (
    <ModalWrapper>
      <Layout
        padding="50px 20px"
        margin="40px"
        maxWidth="900px"
        width="90%"
        backgroundColor="#014984"
        position="relative"
        overflow="auto"
        borderRadius="10px"
      >
        {!params.id && <Heading>The ID parameter is missing</Heading>}

        {params.id && (
          <>
            <ModalTopActions>
              <button onClick={onCloseModalClick}>
                <FontAwesomeIcon size="2x" icon={faTimes} />
              </button>
            </ModalTopActions>
            <Header>
              <IconWrapper>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="5x"
                  color="#80c066"
                />
              </IconWrapper>
              <Heading>
                Your Performance Evaluation was saved successfully
              </Heading>
            </Header>

            <Actions>
              {/* <Button
                color="#fff"
                bgColor="#F7A11D"
                reverseSkew={true}
                onClick={onOpenPerformanceClick}
              >
                <ActionButtonWrapper>
                  <FontAwesomeIcon icon={faFilePdf} />
                  <ActionButtonLabel>View Evaluation</ActionButtonLabel>
                </ActionButtonWrapper>
              </Button> */}
              <Button
                color="#fff"
                bgColor="#F7A11D"
                reverseSkew={true}
                onClick={onOpenEmailPerformanceClick}
              >
                <ActionButtonWrapper>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <ActionButtonLabel>Email Evaluation</ActionButtonLabel>
                </ActionButtonWrapper>
              </Button>
              <Button
                color="#fff"
                bgColor="#F7A11D"
                reverseSkew={true}
                onClick={onCopyToClipBoard}
              >
                <ActionButtonWrapper>
                  <FontAwesomeIcon icon={faCopy} />
                  <ActionButtonLabel>Copy Link</ActionButtonLabel>
                </ActionButtonWrapper>
              </Button>
            </Actions>
            <GenericAccordion
              contentHeight={400}
              containerStyle={{ overflow: 'auto' }}
              headerStyle={{
                backgroundColor: '#014984',
                padding: '18px',
                paddingTop: 0,
              }}
              contentStyle={{ overflow: 'auto' }}
              iconStyle={{ marginLeft: 'unset', fill: '#fff' }}
              hideAccordion
              buttonActiveText="View Email"
              buttonInactiveText="Hide Email"
              renderHeader={() => (
                <AccordionHeader style={{ width: 'fit-content' }}>
                  <Text>You may also copy the email content manually.</Text>
                </AccordionHeader>
              )}
            >
              <AccordionBody>
                {/* <RowHolder>
                  <Row>
                    <Cell>
                      <ButtonHolder>
                        <Btn {...buttonProps} onClick={copyTo}>
                          <ButtonLabel>Copy To:</ButtonLabel>
                        </Btn>
                      </ButtonHolder>
                    </Cell>
                    <Cell ref={toRef} id="copyTo">
                      {emails.length > 0 &&
                        emails.map((email) => <EmailItem>{email}</EmailItem>)}
                    </Cell>
                  </Row>
                </RowHolder> */}
                <RowHolder>
                  <Row>
                    <Cell>
                      <ButtonHolder>
                        <Btn {...buttonProps} onClick={copyBody}>
                          <ButtonLabel>Copy Body:</ButtonLabel>
                        </Btn>
                      </ButtonHolder>
                    </Cell>
                    <Cell>
                      <EmailTextHolder
                        id="copyBody"
                        ref={bodyRef}
                        dangerouslySetInnerHTML={{
                          __html: emailBody,
                        }}
                      ></EmailTextHolder>
                    </Cell>
                  </Row>
                </RowHolder>
              </AccordionBody>
            </GenericAccordion>
          </>
        )}
      </Layout>
    </ModalWrapper>
  );
};
