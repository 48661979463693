import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DatePicker } from 'core/components';
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import './styles.scss';
import {
  formatDate,
  formatDateWithSlashes,
  getQueryString,
  axiosInstance,
} from 'core/utils';
import getError from 'core/utils/getError';

interface IOwnProps {
  toggleNeedsSaving(): void;
  onEnter: Function;
  userId?: number;
}

type IProps = IDispatchToProps &
  IStateToProps &
  IOwnProps &
  RouteComponentProps;

const FirstForm: React.FC<IProps> = (props) => {
  const [pid, setPid] = useState('');
  const [disablePidField, setDisablePidField] = useState(false);

  const onKeyDown =
    (id: string) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        props.onEnter(id);
      }
    };

  useEffect(() => {
    const pidParam = getQueryString(window.location.href, 'pid');
    const isPidDisabled = getQueryString(window.location.href, 'isPidDisabled');

    if (pidParam) {
      setPid(pidParam);
    }

    if (isPidDisabled === 'true') {
      setDisablePidField(true);
    }

    const initializeDatePickers = () => {
      const datePickers = document.getElementsByClassName(
        'react-datepicker__input-container'
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute('readOnly', 'true')
      );
    };

    initializeDatePickers();
  }, []);

  useEffect(() => {
    if (!props.PID && pid) {
      props.setFirstForm({
        ...props,
        PID: pid,
      });
    }
  }, [props.PID, pid]);

  const onChange = (name, value) => {
    const { PID, InspectionClassNumber, Date, ContactForm, MasterCity } = props;

    props.setFirstForm({
      PID,
      InspectionClassNumber,
      Date,
      ContactForm,
      MasterCity,
      [name]: value,
    });
  };

  const {
    isSent: isSentProp,
    errors,
    isPreview,
    InspectionReportId,
    isUserRole,
    isOptInCleanSuite,
    IsOptInCleanSuite,
    masterCity,
  } = props;

  const isSentQueryString =
    getQueryString(window.location.href, 'isSent') == 'true';
  const isSent = isSentQueryString;

  const dateValue = useMemo(() => {
    if (props.Date.includes('/')) {
      return new Date(props.Date);
    }

    const parts = props.Date.split('-');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[2]);

    const isValidDate = !isNaN(year) && !isNaN(month) && !isNaN(day);
    const currentDate = new Date();

    let date;

    if (isValidDate) {
      date = new Date(year, month, day, 0, 0, 0, 0);
    } else {
      date = currentDate;
    }

    return date;
  }, [props.Date]);

  const locationIdQueryString = getQueryString(
    window.location.href,
    'locationId'
  );
  const externalIdQueryString = getQueryString(
    window.location.href,
    'externalId'
  );

  const locationId = !Number.isNaN(Number(locationIdQueryString))
    ? locationIdQueryString
    : !Number.isNaN(Number(externalIdQueryString))
    ? externalIdQueryString
    : null;

  return (
    <>
      {!isPreview && (
        <div className="master-city-container" style={{ display: 'flex' }}>
          <div className="master-city-container" style={{ flex: 1 }}>
            <div
              className={`master-city-content ${
                getError('MasterCity', errors).className
              }`}
            >
              <label>
                Master City
                {getError('MasterCity', errors).showError &&
                  ` (${getError('MasterCity', errors).msg})`}
              </label>
              <input
                className="master-city-select"
                value={masterCity}
                disabled
              />
            </div>
          </div>
          {props.isTemplate && props.LastUpdate ? (
            <div
              className="date-status"
              style={{ marginTop: '36px', marginRight: '24px' }}
            >
              <div>
                <h3 className="date-status-label">Created at: </h3>
                <div className="date-status-label">
                  {props.CreatedAt
                    ? formatDateWithSlashes(props.CreatedAt)
                    : '-'}
                </div>
              </div>
              <div>
                <h3 className="date-status-label">Last update: </h3>
                <div className="date-status-label">
                  {props.LastUpdate
                    ? formatDateWithSlashes(props.LastUpdate)
                    : '-'}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      <div className="first-form-container">
        <div className="first-form-content">
          <div
            className={`first-input-div ${getError('PID', errors).className}`}
            style={{
              width: props.isTemplate && props.LastUpdate ? '50%' : '52%',
            }}
          >
            <label>
              PID
              {getError('PID', errors).showError &&
                ` (${getError('PID', errors).msg})`}
            </label>
            <input
              value={props.PID || pid}
              onChange={(e) => {
                onChange('PID', e.target.value);
                props.toggleNeedsSaving();
              }}
              placeholder="PID"
              maxLength={10}
              disabled={
                isSent ||
                isPreview ||
                disablePidField ||
                (locationId && (isOptInCleanSuite || IsOptInCleanSuite)) ||
                (props.isTemplate &&
                  (isOptInCleanSuite || IsOptInCleanSuite)) ||
                (props.isTemplate && isUserRole) ||
                (isSentProp && isUserRole)
              }
              className={`auto-capitalize`}
              onKeyDown={onKeyDown('company-name')}
            />
          </div>
          {!props.isTemplate ? (
            <div className="second-input-div">
              <label>
                Inspection Class Number{' '}
                {getError('InspectionClassNumber', errors).showError &&
                  ` (${getError('InspectionClassNumber', errors).msg})`}
              </label>
              <select
                id="inspection-class-number"
                value={props.InspectionClassNumber}
                onChange={(e) => {
                  onChange('InspectionClassNumber', e.target.value);
                  props.toggleNeedsSaving();
                }}
                disabled={isSent || isPreview}
                className={`${
                  props.InspectionClassNumber === 'Inspection Class #'
                    ? 'disabled-option'
                    : ''
                }`}
              >
                <option value="">Inspection Class #</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="4*">4*</option>
                <option value="4**">4**</option>
                <option value="N/A">N/A</option>
              </select>
            </div>
          ) : null}
          {!props.isTemplate ? (
            <div
              className={`third-input-div ${
                getError('Date', errors).className
              }`}
            >
              <label>
                Date
                {getError('Date', errors).showError &&
                  ` (${getError('Date', errors).msg})`}
              </label>
              <DatePicker
                onChange={(formDate) => {
                  onChange('Date', formatDate(formDate));
                  props.toggleNeedsSaving();
                }}
                selected={dateValue}
                disabled={isSent || isPreview}
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: '5px, 10px',
                  },
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {props.isTemplate ? (
        <div className="header-content template-variation">
          <div className="header-title-content">
            <h1>Template</h1>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(FirstForm);

const Connected = connect<
  IStateToProps,
  IDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as FirstForm };
