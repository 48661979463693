import React, { memo, useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withFormik, FormikProps } from 'formik';

import { useFormProps } from 'core/components';
import { formatDateWithSlashes, getQueryString, axiosInstance } from 'core/utils';
import { IBidFormHeader } from 'modules/BidInfoForm/interfaces';
import { headerValues } from 'modules/shared/bidDefaultValues';
import { Select } from './Select';

import './styles.scss';
import { getBidURL } from '../../../BidInfoForm/utils';
import { checkMaintenenceMode } from '../../../BidInfoForm/services/bidInfoService';

interface HeaderProps {
  onExit: Function;
}

type IProps = RouteComponentProps & FormikProps<IBidFormHeader> & HeaderProps;

const calcWidth = (width) => (width + 16) * 5.8;
const setInitialSelectedWidth = (cities, cityId) => {
  const selected =
    cityId === undefined ? cities[0] : cities.find((x) => x.Id === cityId);
  let value = 80;

  if (selected && Object.keys(selected).length) {
    value = selected['Name'].length;
  }

  return calcWidth(value);
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const TemplateHeader = memo(
  ({
    location,
    values,
    setFieldValue,
    onExit,
    setIsLoading,
    initAutoSave,
    setNeedsSaving,
    showConnectionWarning,
    showEmptyPidWarning,
    franchiseId,
    setShowEmptyPidWarning,
    showAutoPidTooltip,
    ...props
  }: IProps) => {
    const { formValues, onChange, setFormValues, needsSaving } = useFormProps();
    const [cities, setCities] = useState([]);
    const [selectInputWidth, setSelectInputWidth] = useState(0);
    const { search } = location;
    const { referenceName, description } = formValues;

    const [showMaintenanceMode, setShowMaintenanceMode] = useState(null);

    useEffect(() => {
      if (franchiseId) {
        setFormValues({
          ...formValues,
          cityId: Number(franchiseId),
        })
      }
    }, [franchiseId])

    const onBlur = useCallback(
      (name: string) => () => {
        onChange(name, formValues[name]);
      },
      [formValues, onChange]
    );

    useEffect(() => {
      const loadCities = async () => {
        const currentUrl = search;
        const userId = getQueryString(currentUrl, 'userId');

        const userData = await axiosInstance.get(
          `GetUserById?userid=${userId}`
        );
        const userHasBetaOptIn = userData.data['IsOptIn'];

        const getMasterCities = async (): Promise<any> => {

          const listCloud = await axiosInstance.get(
            `InspectionReport/getFranchises?userid=${userId}`
          );

          onChange(
            'userSignNowStatus',
            userData.data['SignatureProviderStatus'] != null
              ? userData.data['SignatureProviderStatus']
              : -1
          );

          const formId = getQueryString(currentUrl, 'formId');
          const uniqueId = getQueryString(currentUrl, 'formUniqueId');
          const easterEgg = window.location.href.indexOf('pre-filled') >= 0;

          if (!(formId || uniqueId || easterEgg)) {
            setTimeout(() => {
              setNeedsSaving(false);
              setIsLoading(false);
            }, 1000);
          }

          return listCloud.data;
        };
        const cities = await getMasterCities();
        setCities(cities);
        const maintenanceMode = await checkMaintenenceMode();
        if (maintenanceMode.status == '200' && maintenanceMode.data) {
          setShowMaintenanceMode(maintenanceMode.data);
        }

        const { state = {} } = location;

        if (state.formValues) {
          setFormValues({
            ...state.formValues,
            cityId: Number(franchiseId),
            cities,
            userHasBetaOptIn,
            userId,
          });
          sessionStorage.setItem('CITIES', JSON.stringify(cities));
        } else {
          setFormValues({
            ...formValues,
            cityId: Number(franchiseId),
            cities,
            userHasBetaOptIn,
            userId,
          });
          sessionStorage.setItem('CITIES', JSON.stringify(cities));
        }
      };

      loadCities();
      const datePickers = document.getElementsByClassName(
        'react-datepicker__input-container'
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute('readOnly', true)
      );
    }, [search]);

    const onSelectChange = (
      name: string,
      value,
      element: HTMLSelectElement
    ) => {
      const selected = element.options[element.selectedIndex].label;
      setSelectInputWidth(calcWidth(selected.length));
      onChange(name, value);
    };

    if (selectInputWidth === 0 && cities.length) {
      setSelectInputWidth(setInitialSelectedWidth(cities, formValues.cityId));
    }

    const selectedFranchise = cities.find(item => item.Id == franchiseId);

    return (
      <>
        {showConnectionWarning && (
          <div className="check-connection-message-wrapper">
            <div className="check-connection-message">
              <span className="title">Auto-Save Failed - Check Connection</span>
              <span className="message">
                Please do not close this browser or you will lose any changes
                since the last Auto-Save. You will not be able to complete this
                form until your connection is restored.
              </span>
            </div>
          </div>
        )}
        {showEmptyPidWarning && (
          <div className="check-connection-message-wrapper">
            <div
              className="check-connection-message"
              style={{
                padding: '15px 0',
                display: showEmptyPidWarning ? 'flex' : 'none',
              }}
            >
              <span className="title">Attention</span>
              <span className="message">
                The Auto-Save will only be enabled after the PID Field is
                populated.
              </span>
            </div>
          </div>
        )}

        {showMaintenanceMode && (
          <div className="maintenance-alert-wrapper" style={{ width: '100%' }}>
            <div
              className="maintenance-alert-message"
              style={{
                backgroundColor: 'white',
                padding: '15px',
                maxWidth: '1200px',
                textAlign: 'center',
              }}
            >
              <span className="message" style={{ color: 'black' }}>
                <span style={{ fontWeight: 'bolder', color: 'red' }}>
                  Attention:{' '}
                </span>
                {showMaintenanceMode}
              </span>
            </div>
          </div>
        )}

        <div className="header bid-header">
          <div className="bid-space-between">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
              }}
            >
              <div className="bid-input-content" style={{ paddingTop: '15px' }}>
                <div className="bid-input-label">Master City</div>
                <input
                  className="bid-input-mastercity"
                  value={selectedFranchise?.Name}
                  disabled
                />
              </div>

            </div>
            {formValues.createdAt && formValues.lastUpdate && (
              <div className="date-status">
                <div>
                  <h3 className="date-status-label">Created at: </h3>
                  <div className="date-status-label">
                    {formatDateWithSlashes(formValues.createdAt)}
                  </div>
                </div>
                <div>
                  <h3 className="date-status-label">Last update: </h3>
                  <div className="date-status-label">
                  {formatDateWithSlashes(formValues.lastUpdate)}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="bid-form-container">
            <div className="bid-form-content" style={{ flexDirection: 'column' }}>
              <div className="bid-input-content" style={{ width: '290px' }}>
                <div className="bid-input-label">Template Name</div>
                <input
                  value={referenceName}
                  onChange={(e) => onChange('referenceName', e.target.value)}
                  maxLength={24}
                  className="input-header input-bid validate-pid"
                  onBlur={onBlur('referenceName')}
                  disabled={formValues.status === 2}
                />
              </div>

              <div className="bid-input-content">
                <div className="bid-input-label">Description</div>
                <input
                  value={description}
                  onChange={(e) => onChange('description', e.target.value)}
                  maxLength={140}
                  className="input-header input-bid"
                  onBlur={onBlur('description')}
                  disabled={formValues.status === 2}
                />
              </div>
            </div>
          </div>
          <div className="header-content">
            <h1>Template</h1>
          </div>
        </div>
      </>
    );
  }
);

const mapForm = {
  mapPropsToValues: () => ({
    ...headerValues,
  }),
  handleSubmit: () => '',
};

const EnhancedHeader = withFormik<any, IBidFormHeader>(mapForm)(
  withRouter(TemplateHeader)
);

export { EnhancedHeader as TemplateHeader };
