import React, { useState } from 'react';

import {
  Layout,
  GraySection,
  SubSection,
  Section,
} from '../../../components/layout';
import PreviewReferenceContent from '../../../../shared/sections/Summary/References/PreviewReferenceContent';

import { IntroductionTitle } from '../../../styles';
import { TermsAndConditions } from '../components/organisms';

const PageReferences = (props, ref) => {
  const { data, franchiseData, selectedItem, isBid } = props;
  const values = data || [];
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const franchiseValues = franchiseData || [];

  const dataReferences = data && data.BidInformationSheetReferences[0];

  const SectionComponent = isBid ? Section : GraySection;

  return (
    <Layout margin="0" backgroundColor="white">
      {!isBid && (
        <IntroductionTitle
          maxFontSize="30px"
          textAlign="center"
          style={{ padding: '25px 0' }}
        >
          SUPERIOR SERVICE GUARANTEE
        </IntroductionTitle>
      )}
      <SectionComponent
        padding={isBid ? '0px 0px 25px 0px' : '0px 0px 25px 0px'}
        paddingBottom="0 !important"
      >
        <SubSection
          paddingBottom="0 !important"
          style={{ width: '70% !important' }}
        >
          {dataReferences && (
            <PreviewReferenceContent
              className="page-reference"
              withShadow
              referenceDetails={dataReferences.ReferenceDetails}
              orderList={dataReferences.OrderReferenceTypes}
              withDrag={false}
              isBid={isBid}
            />
          )}
        </SubSection>
      </SectionComponent>
      <GraySection padding={isBid ? '0 20px 0px 20px' : '0 20px 0px 20px'}>
        <TermsAndConditions
          values={values}
          showTerms={false}
          franchiseValues={franchiseValues}
          isTermsChecked={isTermsChecked}
          setIsTermsChecked={setIsTermsChecked}
          pageChanged={selectedItem}
        />
      </GraySection>
    </Layout>
  );
};

export default React.forwardRef(PageReferences);
