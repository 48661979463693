import React from 'react';
const matchAll = require('string.prototype.matchall');
matchAll.shim();

const extractPropsFromString = (attr) => {
  let propsStr = attr.match(/\s*([^=]*)\s*=\s*"([^"]*)"/gi) || [];
  return Object.assign(
    {},
    ...propsStr
      .map((s) => {
        let arr = s.split('=');
        return {
          [(arr[0] || '').trim()]: (arr[1] || '').replace(/\s*["]+/g, ''),
        };
      })
      .flat()
  );
};

const cloneElement = (el, lastChild) => {
  if (!el) return [];
  if (!Array.isArray(lastChild)) lastChild = [lastChild];
  if (el.props && el.props.children) {
    return React.cloneElement(
      el,
      {},
      cloneElement(el.props.children, lastChild)
    );
  } else if (el.props) {
    return React.cloneElement(el, {}, [...lastChild]);
  } else {
    return [el, [...lastChild]];
  }
};

/**
 * 
 * const fn = (tag, props) => {
    if (tag === 'mention') {
      return <MentionBlock />;
    }
    if (tag === 'bold') {
      return <b />;
    }
    if (tag === 'italic') {
      return <i />;
    }
  }
 */

export const reactHtmlReplace = (text = '', fn = () => {}) => {
  let tags = [];
  let preTreeLen = 0;
  let nodes = [];
  let matches = text.matchAll(/<\s*(\/\s*)*([^>\s]*)[^><]*>/gi);

  for (let match of matches) {
    let [substring, isClosing, tag] = match;
    let index = match['index'];
    tag = (tag || '').trim();
    isClosing = (isClosing || '').trim();
    substring = (substring || '').trim();

    if (match[0].indexOf('/>') >= 0) {
      nodes = [
        ...nodes,
        fn(tag, {}),
        text.slice(
          text.indexOf('>', index) + 1,
          text.indexOf('<', index + 1) == -1
            ? Infinity
            : text.indexOf('<', index + 1)
        ),
      ];
      continue;
    }

    if (!isClosing) {
      tags.push({ tag, substring, index });
    } else if (
      isClosing &&
      tags.length > 0 &&
      tag === tags[tags.length - 1].tag
    ) {
      let tagObj = tags.pop();
      const { substring, tag } = tagObj;
      let attr = substring.replace(/[<>]/gi, '').replace(tag, '');
      let props = extractPropsFromString(attr);
      let JsxEl = fn(tag, { ...props });

      JsxEl = cloneElement(
        JsxEl,
        text.slice(
          text.indexOf('>', tagObj.index) + 1,
          text.indexOf('<', tagObj.index + 1)
        )
      );

      nodes = [...nodes];

      if (preTreeLen <= tags.length) {
        nodes = [
          ...nodes,
          JsxEl,
          text.slice(
            text.indexOf('>', index) + 1,
            text.indexOf('<', index + 1) == -1
              ? Infinity
              : text.indexOf('<', index + 1)
          ),
        ];
      } else {
        nodes = cloneElement(JsxEl, nodes);
      }
      preTreeLen = tags.length;
    } else {
      throw Error('Invalid string, no matching tag "' + tag + '"');
    }
  }
  if (tags.length !== 0) {
    throw Error(
      'Invalid string, no matching tags : "' +
        (tags.map((t) => t.tag) || '').toString() +
        '"'
    );
  }
  return [
    text.slice(0, text.indexOf('<') == -1 ? Infinity : text.indexOf('<')),
    ...nodes,
  ];
};

export const HtmlSanitizer = new (function () {
  var tagWhitelist_ = {
    A: true,
    ABBR: true,
    B: true,
    BLOCKQUOTE: true,
    BODY: true,
    BR: true,
    CENTER: true,
    CODE: true,
    DIV: true,
    EM: true,
    FONT: true,
    H1: true,
    H2: true,
    H3: true,
    H4: true,
    H5: true,
    H6: true,
    HR: true,
    I: true,
    IMG: false,
    LABEL: true,
    LI: true,
    OL: true,
    P: true,
    PRE: true,
    SMALL: true,
    SOURCE: true,
    SPAN: true,
    STRONG: true,
    TABLE: true,
    TBODY: true,
    TR: true,
    TD: true,
    TH: true,
    THEAD: true,
    UL: true,
    U: true,
    VIDEO: true,
  };

  var contentTagWhiteList_ = { FORM: true }; //tags that will be converted to DIVs

  var attributeWhitelist_ = {
    align: true,
    color: true,
    controls: true,
    height: true,
    href: true,
    src: true,
    style: true,
    target: true,
    title: true,
    type: true,
    width: true,
  };

  var cssWhitelist_ = {
    color: true,
    'background-color': true,
    'font-size': true,
    'text-align': true,
    'text-decoration': true,
    'font-weight': true,
  };

  var schemaWhiteList_ = [
    'http:',
    'https:',
    'data:',
    'm-files:',
    'file:',
    'ftp:',
  ]; //which "protocols" are allowed in "href", "src" etc

  var uriAttributes_ = { href: true, action: true };

  this.SanitizeHtml = function (input) {
    input = input.trim();
    if (input == '') return ''; //to save performance and not create iframe

    //firefox "bogus node" workaround
    if (input == '<br>') return '';

    var iframe = document.createElement('iframe');
    if (iframe['sandbox'] === undefined) {
      alert(
        'Your browser does not support sandboxed iframes. Please upgrade to a modern browser.'
      );
      return '';
    }
    iframe['sandbox'] = 'allow-same-origin';
    iframe.style.display = 'none';
    document.body.appendChild(iframe); // necessary so the iframe contains a document
    var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    if (iframedoc.body == null) iframedoc.write('<body></body>'); // null in IE
    iframedoc.body.innerHTML = input;

    function makeSanitizedCopy(node) {
      if (node.nodeType == Node.TEXT_NODE) {
        var newNode = node.cloneNode(true);
      } else if (
        node.nodeType == Node.ELEMENT_NODE &&
        (tagWhitelist_[node.tagName] || contentTagWhiteList_[node.tagName])
      ) {
        //remove useless empty spans (lots of those when pasting from MS Outlook)
        if (
          (node.tagName == 'SPAN' ||
            node.tagName == 'B' ||
            node.tagName == 'I' ||
            node.tagName == 'U') &&
          node.innerHTML.trim() == ''
        ) {
          return document.createDocumentFragment();
        }

        if (contentTagWhiteList_[node.tagName])
          newNode = iframedoc.createElement('DIV');
        //convert to DIV
        else newNode = iframedoc.createElement(node.tagName);

        for (var i = 0; i < node.attributes.length; i++) {
          var attr = node.attributes[i];
          if (attributeWhitelist_[attr.name]) {
            if (attr.name == 'style') {
              for (var s = 0; s < node.style.length; s++) {
                var styleName = node.style[s];
                if (cssWhitelist_[styleName])
                  newNode.style.setProperty(
                    styleName,
                    node.style.getPropertyValue(styleName)
                  );
              }
            } else {
              if (uriAttributes_[attr.name]) {
                //if this is a "uri" attribute, that can have "javascript:" or something
                if (
                  attr.value.indexOf(':') > -1 &&
                  !startsWithAny(attr.value, schemaWhiteList_)
                )
                  continue;
              }
              newNode.setAttribute(attr.name, attr.value);
            }
          }
        }
        for (i = 0; i < node.childNodes.length; i++) {
          var subCopy = makeSanitizedCopy(node.childNodes[i]);
          newNode.appendChild(subCopy, false);
        }
      } else {
        newNode = document.createDocumentFragment();
      }
      return newNode;
    }

    var resultElement = makeSanitizedCopy(iframedoc.body);
    document.body.removeChild(iframe);
    return resultElement.innerHTML
      .replace(/<br[^>]*>(\S)/g, '<br>\n$1')
      .replace(/div><div/g, 'div>\n<div'); //replace is just for cleaner code
  };

  function startsWithAny(str, substrings) {
    for (var i = 0; i < substrings.length; i++) {
      if (str.indexOf(substrings[i]) == 0) {
        return true;
      }
    }
    return false;
  }

  this.AllowedTags = tagWhitelist_;
  this.AllowedAttributes = attributeWhitelist_;
  this.AllowedCssStyles = cssWhitelist_;
  this.AllowedSchemas = schemaWhiteList_;
})();
