import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EXIF from 'exif-js';

import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import { getQueryString } from 'core/utils';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { IReduxState, IAreaPhoto } from 'core/interfaces';
import './styles.scss';
import './previewPhotoModal.scss';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { LoadingModal } from 'core/components/LoadingModal';

type IProps = IDispatchToProps & IStateToProps;

// used below in the code when the user clicks on an image
const PreviewModal = (props: any) => {
  return (
    <div className="preview-photo-modal-root-div">
      <img alt={props.photoAltName} src={props.imageURL} />
    </div>
  );
};

//### code for rotating the picture to its right orientation ##
const VERTICAL = 6;
const LEFT_HORIZONTAL = 1;
const RIGHT_HORIZONTAL = 3;
const UPSIDEDOWN = 8;
const MAX_WIDTH_RECEIPT = 1280;
const MAX_HEIGHT_RECEIPT = 1280;

function getExif(img: any) {
  EXIF.getData(img, () => null);
  return EXIF.getTag(img, 'Orientation');
}

function resetOrientation(targetFile: Blob, callback: any, lastFile: boolean) {
  let reader = new FileReader();
  reader.onload = (e: any) => {
    let img = document.createElement('img');
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height && width > MAX_WIDTH_RECEIPT) {
        height *= MAX_WIDTH_RECEIPT / width;
        width = MAX_WIDTH_RECEIPT;
      } else {
        if (height > MAX_HEIGHT_RECEIPT) {
          width *= MAX_HEIGHT_RECEIPT / height;
          height = MAX_HEIGHT_RECEIPT;
        }
      }

      const orientation = getExif(img);
      canvas.width = orientation === VERTICAL ? height : width;
      canvas.height = orientation === VERTICAL ? width : height;
      let ctx = canvas.getContext('2d');
      if (orientation) {
        switch (orientation) {
          case VERTICAL:
            ctx!.rotate(Math.PI / 2);
            ctx!.drawImage(img, 0, -height, width, height);
            break;
          case RIGHT_HORIZONTAL:
            ctx!.rotate(Math.PI);
            ctx!.drawImage(img, -width, -height, width, height);
            break;
          case LEFT_HORIZONTAL:
            ctx!.drawImage(img, 0, 0, width, height);
            break;
          case UPSIDEDOWN:
            ctx!.drawImage(img, 0, 0, width, height);
            break;
        }
      } else {
        ctx!.drawImage(img, 0, 0, width, height);
      }
      const dataurl = canvas.toDataURL('image/jpeg');
      callback(targetFile, dataurl, lastFile);
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(targetFile);
}
// ### ####

class AddPhotosModal extends React.Component<IProps> {
  state = {
    showPreview: false,
    imageURL: null,
    photoAltName: '',
    showAlertModal: false,
    photoSelected: null,
  };

  public addPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { addAreaPhotoRequest } = this.props;
    Object.entries(event.target.files).forEach((element) => {
      this.props.setLoadingState(true);
      const fileObj = element[1];
      if (parseInt(element[0], 10) === event.target.files.length - 1) {
        resetOrientation(fileObj, addAreaPhotoRequest, true);
      } else {
        resetOrientation(fileObj, addAreaPhotoRequest, false);
      }
    });
  };

  public deletePhoto = (file: IAreaPhoto) => {
    this.setState({
      showAlertModal: true,
      photoSelected: file,
    });
    // const deleteConfirmation = window.confirm(
    //   'Are you sure you want to delete this item?'
    // );

    // if (deleteConfirmation) {
    //   const { removeAreaPhotoRequest } = this.props;
    //   removeAreaPhotoRequest(file);
    // }
  };

  public onConfirmDeletion = () => {
    const { photoSelected } = this.state;
    const { removeAreaPhotoRequest } = this.props;
    removeAreaPhotoRequest(photoSelected);
    this.setState({
      showAlertModal: false,
      photoSelected: null,
    });
  };

  public previewPhoto = (file: IAreaPhoto) => {
    const src = file.PathAndFileName ? file.PathAndFileName : file.FileUrl;

    this.setState({
      showPreview: true,
      imageURL: src,
      photoAltName: (file.File! && file.File!.name) || '',
    });
  };

  public renderPhoto = (file: IAreaPhoto, index: number) => {
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = this.props.isSent || isSentQueryString;

    const src = file.PathAndFileName ? file.PathAndFileName : file.FileUrl;
    return (
      <div key={index} className="div-img-container">
        <div
          className="div-img"
          style={{ backgroundImage: `url(${src})` }}
          onClick={() => this.previewPhoto(file)}
        />
        <div className="div-img-dismiss-container">
          <span
            onClick={() => {
              !isSent && this.deletePhoto(file);
            }}
          >
            Delete
          </span>
        </div>
      </div>
    );
  };

  public render() {
    const { selectedArea, setPhotosModalState, isSent: isSentProp } = this.props;
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentProp || isSentQueryString;

    const { imageURL, showPreview, photoAltName } = this.state;
    const areaPhotos = selectedArea ? selectedArea.AreaPhotos : [];
    return (
      <>
        <LoadingModal lowOpacity message="Processing Image..." />
        <div className="add-photo-modal-root-div">
          <div className="add-photo-modal-content-div">
            <div className="add-photo-modal-title-container">
              {selectedArea ? <h3>{selectedArea.Name}</h3> : <h3> </h3>}
            </div>
            <div className="photo-text-container">
              <div className="add-photo-blue" />
              <div className="alert-text ">
                <p>
                  <span>IMPORTANT:</span> Adding more than 20 photos per
                  Inspection Report can greatly reduce the performance of the
                  The AnagoApp®.
                </p>
              </div>
            </div>
            <div className="photos-list-container">
              {!isSent && (
                <div className="add-photo-box">
                  <div className="add-photo-div">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      onChange={(e) => this.addPhoto(e)}
                      accept="image/gif, image/jpeg, image/png"
                      multiple={true}
                    />
                    <label htmlFor="file">
                      <p>+</p>
                      <span>Add Photo</span>
                    </label>
                  </div>
                </div>
              )}

              {areaPhotos.map((photo, index) => this.renderPhoto(photo, index))}
            </div>
          </div>
          <div
            className="add-photo-modal-done-button"
            onClick={() => setPhotosModalState(false)}
          >
            <button className="button">DONE</button>
          </div>

          {showPreview && (
            <>
              <div
                onClick={() => {
                  this.setState({ showPreview: false });
                }}
                className="save-as-backdrop"
              />
              <PreviewModal imageURL={imageURL} photoAltName={photoAltName} />
            </>
          )}

          <ActionAlertModal
            title=""
            message="Are you sure you want to delete this item?"
            onConfirm={() => this.onConfirmDeletion()}
            // onDismiss={this.props.closeExitModal}
            onDismiss={() => {
              this.setState({
                showAlertModal: false,
                photoSelected: null,
              });
            }}
            isVisible={this.state.showAlertModal}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(AddPhotosModal);

export { Connected as AddPhotosModal };
