import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { IInspectForm } from 'modules/InspectForm/interfaces';
import { getQueryString, axiosInstance } from 'core/utils';
import axios from 'axios';
import { calculateFacilityRating } from '../../../modules/InspectForm/redux/sagas';
import debounce from 'lodash.debounce';
import * as Sentry from '@sentry/react';
import './styles.scss';

interface IOwnProps {
  onCloseModal: () => any;
  renderTableItems: (
    item: IInspectForm,
    index: number,
    onClickAction: (form: IInspectForm) => void,
    isSent: string
  ) => any;
  onClickSent: (form: IInspectForm) => any;
}

type IProps = IOwnProps & RouteComponentProps;

interface IApiResponse {
  Drafts: IInspectForm[];
  Sents: IInspectForm[];
  Templates: IInspectForm[];
  CityTemplates: IInspectForm[];
  TotalDrafts: number;
  TotalSents: number;
  TotalTemplates: number;
  TotalCityTemplates: number;
}

// used for cancelation
const CancelToken = axios.CancelToken;
let cancel;

class SearchModal extends Component<IProps> {
  state = {
    list: [],
    searchQuery: '',
    totalNumberOfSents: 0,
    loading: false,
    page: 0,
  };

  public getFormsFromBackend = debounce(async (queryPID: string) => {
    const currentUrl = this.props.location.search;
    const userId = getQueryString(currentUrl, 'userId');
    const franchiseId = getQueryString(currentUrl, 'franchiseId');

    let uri = '';

    switch (this.props.type) {
      case 'Drafts':
        uri = 'GetDraftsReportFormByUser';
        break;
      case 'Templates':
        uri = 'GetTemplatesReportFormByUser';
        break;
      case 'CityTemplates':
        uri = 'GetCityTemplatesByUser';
        break;
      case 'Sents':
        uri = 'GetSentsReportFormByUser';
        break;
      default:
        uri = 'GetInspectionsReportFormByUser';
        break;
    }

    try {
      cancel && cancel();
      const response = await axiosInstance.get(
        `InspectionReport/${uri}?id=${userId}&page=${this.state.page}&franchiseId=${franchiseId}&pid=${queryPID}`,
        {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        }
      );

      // const { Sents, TotalSents }: IApiResponse = response.data;

      const items = response.data[this.props.type];
      const totalItems = response.data[`Total${this.props.type}`];

      const sentItems: IInspectForm[] = items;

      for (const form of sentItems) {
        form.FacilityRating = calculateFacilityRating(form);
      }

      this.setState({
        list: items,
        totalNumberOfSents: totalItems,
        loading: false,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('post request canceled', error);
      } else {
        this.setState({
          list: [],
          totalNumberOfSents: 0,
          loading: false,
        });
      }
      Sentry.captureException(error);
    }
  }, 500);

  public onChangeInput = (text: string) => {
    this.setState(
      {
        searchQuery: text,
        loading: true,
      },
      () => {
        const { searchQuery } = this.state;
        const trimmedSearch = searchQuery.trim();
        this.getFormsFromBackend(trimmedSearch);
      }
    );
  };

  render() {
    const { renderTableItems, onClickSent, onCloseModal } = this.props;
    const { searchQuery, list, loading } = this.state;
    const formType = this.props.type == 'Signed'
    ? 'Signed'
    : this.props.type
    ? this.props.type.substr(0, this.props.type.length - 1)
    : 'Signed';

    const searchLabel = formType.toLowerCase() === 'template' ? 'my template' : formType.toLowerCase() === 'citytemplate' ? 'city template' : formType

    return (
      <div className="search-modal-container">
        <div className="search-modal-content">
          <h2 className="search-title">
            {' '}
            Search{' '}
            {searchLabel}{' '}
            Items
          </h2>

          <div className="search-input">
            <input
              placeholder="Type the PID"
              value={searchQuery}
              onChange={(e) => this.onChangeInput(e.target.value.toUpperCase())}
            />
          </div>
          {loading ? (
            <div className="loader-container">
              <div className="loading-div">
                <div className="loader-search" />
                <p>Loading ...</p>
              </div>
            </div>
          ) : (
            <table className="table-container">
              <tbody>
                {list.map((item, index) =>
                  renderTableItems(item, index, onClickSent, formType.toLowerCase())
                )}
              </tbody>
            </table>
          )}

          <div className="buttons-wrapper">
            <button className="button-close" onClick={onCloseModal}>
              CLOSE
            </button>
            {/* <button
              className="button"
              // onClick={props.onConfirm}
            >
              OK
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SearchModal);
