import React, { memo, useState } from 'react';
import { withFormik, FormikProps } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faInfo } from '@fortawesome/free-solid-svg-icons';

import {
  ContentInput,
  Table,
  Select,
  FilterSelect,
  InputNumber,
  BoxInput,
  UncontrolledBoxInput,
  useFormProps,
  Tooltip,
} from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import {
  IBidRoomInventory,
  IBidRoomInventoryItem,
} from 'modules/BidInfoForm/interfaces';
import {
  roomInventoryValues,
  inventoryBlankValue,
  roomTypes,
  floorTypes,
  prodGroups,
  generateArray,
} from 'modules/shared/bidDefaultValues';
import { decimalOnePlace, numberIntegerMask } from 'core/utils';
import { calculateLxW, parseStringToNumber } from 'modules/BidInfoForm/utils';

import './styles.scss';

type IProps = FormikProps<IBidRoomInventory>;
const RoomInventory = memo(
  ({ values, setFieldValue, isSpecialty }: IProps) => {
    const { onChange, formValues } = useFormProps();
    const [showAlertModal, setShowAlertModal] = useState({
      show: false,
      index: null,
    });

    const onUpdateContext = () => onChange('inventory', values.inventory);

    React.useEffect(() => {
      if (
        (!values.inventory ||
          JSON.stringify(values.inventory) ===
            JSON.stringify(roomInventoryValues.inventory)) &&
        JSON.stringify(formValues.inventory) !==
          JSON.stringify(roomInventoryValues.inventory)
      ) {
        setFieldValue('inventory', formValues.inventory);
      } else if (!values.inventory) {
        setFieldValue('inventory', formValues.inventory);
      }
    }, [formValues.inventory, values.inventory]);

    React.useEffect(() => {
      if (formValues.referenceName) {
        setFieldValue('inventory', formValues.inventory);
      }
    }, [formValues.referenceName])

    const inventory = values.inventory || [];
    const onSelect = (index: number) => (name: string, value: any) => {
      if (formValues.status !== 2) {
        const result = [
          ...inventory.filter(
            (item: any, indexItem: number) => indexItem !== index
          ),
        ];
        result.splice(index, 0, { ...inventory[index], [name]: value });

        setFieldValue('inventory', result);

        setTimeout(() => {
          onChange('inventory', result);
        }, 0);
      }
    };

    const onDelete = (index: number) => () => {
      const updatedList = inventory.filter(
        (item: any, itemIndex: number) => itemIndex !== index
      );
      setShowAlertModal({ index: null, show: false });
      setFieldValue('inventory', updatedList);

      setTimeout(() => {
        onChange('inventory', updatedList);
      }, 0);
    };

    const onChangeTotalSqft = (index: number) => (name: string, value: any) => {
      const parsedValue =
        parseStringToNumber(value) < 10000000 ? value : inventory[index][name];
      const result = [
        ...inventory.filter(
          (item: any, indexItem: number) => indexItem !== index
        ),
      ];
      result.splice(index, 0, {
        ...inventory[index],
        [name]: parsedValue,
        width: 0,
        length: 0,
      });
      setFieldValue('inventory', result);
    };

    const onAdd = (count: number) => () => {
      const result = [
        ...inventory,
        ...generateArray(count, inventoryBlankValue),
      ];
      setFieldValue('inventory', result);
      setTimeout(() => {
        onChange('inventory', result);
      }, 0);
    };

    // const onChangeLxW = (index:number) => (name: string, value: any) => {

    // }

    const onCalculateLxW = (index: number) => (name: string, value: any) => {
      const parsedValue =
        parseStringToNumber(value) < 10000 ? value : inventory[index][name];
      const itemValue = { ...inventory[index], [name]: parsedValue };
      const totalSqft = calculateLxW(
        `${itemValue.length || 0}`,
        `${itemValue.width || 0}`
      );
      const result = [
        ...inventory.filter(
          (item: any, indexItem: number) => indexItem !== index
        ),
      ];

      const newValue = {
        ...inventory[index],
        length:
          inventory[index].length != undefined &&
          inventory[index].length != null &&
          inventory[index].length != ''
            ? inventory[index].length
            : 0,
        width:
          inventory[index].width != undefined &&
          inventory[index].width != null &&
          inventory[index].width != ''
            ? inventory[index].width
            : 0,
      };

      result.splice(index, 0, {
        ...newValue,
        [name]: parsedValue,
        totalSqft,
      });
      setFieldValue('inventory', result);
    };

    roomTypes.sort();

    const getColumns = () => {
      const cols: Array<any> = [
        {
          key: 'roomType',
          label: 'Room Type',
          width: '20%',
          render: ({ roomType }: IBidRoomInventoryItem, index: number) => (
            <FilterSelect
              disabled={formValues.status === 2}
              name="roomType"
              value={roomType}
              autoSelectOnBlur
              autoSelectOnSpace
              onChange={onSelect(index)}
              items={roomTypes}
              getLabelInputFuction={(item: any) =>
                item.indexOf(' -') >= 0
                  ? item.substr(0, item.indexOf(' -'))
                  : item.indexOf('-') >= 0
                  ? item.substr(0, item.indexOf('-'))
                  : item.indexOf(' –') >= 0
                  ? item.substr(0, item.indexOf(' –'))
                  : item.indexOf('–') >= 0
                  ? item.substr(0, item.indexOf('–'))
                  : item
              }
            />
          ),
        },
        {
          key: 'floorType',
          label: 'Floor Type',
          width: '20%',
          render: ({ floorType }: IBidRoomInventoryItem, index: number) => (
            <FilterSelect
              disabled={formValues.status === 2}
              name="floorType"
              autoSelectOnBlur
              autoSelectOnSpace
              value={floorType}
              onChange={onSelect(index)}
              items={floorTypes}
              getLabelInputFuction={(item: any) =>
                item.indexOf(' -') >= 0
                  ? item.substr(0, item.indexOf(' -'))
                  : item.indexOf('-') >= 0
                  ? item.substr(0, item.indexOf('-'))
                  : item.indexOf(' –') >= 0
                  ? item.substr(0, item.indexOf(' –'))
                  : item.indexOf('–') >= 0
                  ? item.substr(0, item.indexOf('–'))
                  : item
              }
            />
          ),
        },
        {
          key: 'length',
          label: 'L x W',
          width: '18%',
          render: (
            { length, width, roomType, floorType }: IBidRoomInventoryItem,
            index: number
          ) => (
            <div className="lxw-input">
              <BoxInput
                disabled={formValues.status === 2}
                value={
                  length
                    ? length
                    : true && (roomType != '' || floorType != '')
                    ? 0
                    : ''
                }
                name="length"
                type="tel"
                onChange={onCalculateLxW(index)}
                mask={numberIntegerMask}
                onBlur={onUpdateContext}
                alignCenter
                flex
              />
              /
              <BoxInput
                disabled={formValues.status === 2}
                value={
                  width
                    ? width
                    : true && (roomType != '' || floorType != '')
                    ? 0
                    : ''
                }
                name="width"
                type="tel"
                onChange={onCalculateLxW(index)}
                mask={numberIntegerMask}
                onBlur={onUpdateContext}
                alignCenter
                flex
              />
            </div>
          ),
        },
        {
          key: 'totalSqft',
          label: 'Total Sq. Ft',
          width: '10%',
          render: ({ totalSqft }: IBidRoomInventoryItem, index: number) => (
            <BoxInput
              disabled={formValues.status === 2}
              value={totalSqft}
              id={`totalSqft_${index}`}
              name="totalSqft"
              type="tel"
              onChange={onChangeTotalSqft(index)}
              mask={numberIntegerMask}
              maxLength={11}
              onBlur={onUpdateContext}
              alignCenter
              flex
            />
          ),
        },
      ];

      if (!isSpecialty) {
        cols.push({
          key: 'prodGroup',
          label: 'Prod. Group',
          width: '12%',
          render: ({ prodGroup }: IBidRoomInventoryItem, index: number) => (
            <Select
              disabled={formValues.status === 2}
              name="prodGroup"
              value={prodGroup}
              onChange={onSelect(index)}
              items={prodGroups}
              autoSelectOnBlur
              autoSelectOnSpace
              blockCustomInput={true}
              capitalizeFunction={(value: string) =>
                // value.toUpperCase()
                value
              }
            />
          ),
        });
        cols.push({
          key: 'fixtures',
          renderLabel: () => {
            return (
              <div className="fixtures-tooltip-wrapper">
                # of Fixtures
                <Tooltip
                  overlay={
                    <span
                      style={{
                        fontSize: '13px',
                        display: 'block',
                        textAlign: 'justify',
                      }}
                    >
                      To use the Restroom Fixture Counter, one or more rooms
                      must be in Production Rate Group “RR1” or “RR2” or “RR3”.
                      This removes the square footage of these room(s) from the
                      Office Man Hours calculation and makes the restroom
                      cleaning be calculated by number of fixtures and minutes
                      per fixture, which can be entered below
                    </span>
                  }
                  trigger="click"
                  placement="top"
                  alignedItemId="fixturesCleanInfoIcon"
                >
                  <div
                    className="fixtures-button-tooltip"
                    id="fixturesCleanInfoIcon"
                  >
                    <FontAwesomeIcon icon={faInfo} size="xs" color="#216eab" />
                  </div>
                </Tooltip>
              </div>
            );
          },
          width: '15%',
          render: (
            { fixtures, prodGroup }: IBidRoomInventoryItem,
            index: number
          ) => (
            <InputNumber
              value={fixtures || 0}
              name="fixtures"
              onChange={onSelect(index)}
              disabled={
                !prodGroup ||
                !prodGroup.includes('RR') ||
                formValues.status === 2
              }
            />
          ),
        });
      }

      cols.push({
        key: 'actions',
        label: '',
        width: '5%',
        render: (_: any, index: number) => (
          <button
            tabIndex={-1}
            className="delete-button"
            onClick={() => setShowAlertModal({ show: true, index })}
            disabled={formValues.status === 2}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="2x" color="#999" />
            Delete
          </button>
        ),
      });

      return cols;
    };

    const columns = getColumns();

    return (
      <div className="room-inventory-root">
        <div className="room-inventory-content">
          <ContentInput width="100%" padding="10px 0px">
            <h3 className="room-inventory-title">Room inventory</h3>
          </ContentInput>

          <Table columns={columns} data={inventory} />

          <div className="add-room-content">
            <div className="label-room">Add Rooms:</div>
            <button
              disabled={formValues.status === 2}
              tabIndex={-1}
              className="add-button light"
              onClick={onAdd(1)}
            >
              +1
            </button>
            <button
              disabled={formValues.status === 2}
              tabIndex={-1}
              className="add-button medium"
              onClick={onAdd(5)}
            >
              +5
            </button>
            <button
              disabled={formValues.status === 2}
              tabIndex={-1}
              className="add-button dark"
              onClick={onAdd(10)}
            >
              +10
            </button>
          </div>
        </div>
        <ActionAlertModal
          title=""
          message="Are you sure you want to delete this item?"
          onConfirm={onDelete(showAlertModal.index)}
          onDismiss={() => setShowAlertModal({ show: false, index: null })}
          isVisible={showAlertModal.show}
        />
      </div>
    );
  }
);

const mapForm = {
  mapPropsToformValues: () => ({
    ...roomInventoryValues,
  }),
  handleSubmit: () => '',
};

const EnhancedJanitorial = withFormik<any, IBidRoomInventory>(mapForm)(
  RoomInventory
);

export { EnhancedJanitorial as RoomInventory };
