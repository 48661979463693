import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, ButtonComponent } from 'core/components';
import classNames from 'classnames';
import SaveIcon from './assets/SaveIcon.svg';
import BackIcon from './assets/BackIcon.svg';
import CloseIcon from './assets/Close.svg';
import DragIconWhite from './assets/Drag-white.svg';
import { ReferenceDetail } from 'modules/shared/sections/Summary';
import { PreviewReferenceItem } from './PreviewReferenceItem';
import ReferencesBanner from './assets/references_banner.jpg';
import LogoWhite from './assets/logo-white-glow.png';
import { toast } from 'react-toastify';
import { SVGIcon } from 'core/components';
import PreviewRerefenceContent from './PreviewReferenceContent';

interface PreviewProps {
  opened: boolean;
  onClose: () => void;
  onSave?: (referenceDetails: ReferenceDetail[], orderList: any) => void;
  orderList: any;
  withDrag?: boolean;
  referenceDetails: ReferenceDetail[];
}

export const Preview: React.FC<PreviewProps> = ({
  opened,
  onClose,
  referenceDetails = [],
  withDrag = true,
  onSave,
  orderList: orderListProp,
}) => {
  const [orderList, setOrderlist] = useState([]);
  useEffect(() => {
    if (orderListProp) {
      setOrderlist(orderListProp);
    }
  }, [orderListProp]);

  const [currentReferenceDetails, setCurrentReferenceDetails] = useState(
    referenceDetails,
  );

  useEffect(() => {
    setTimeout(() => {
      const modal = document.querySelector('.modal-content') as HTMLElement;
      if (modal !== null) {
        const newHeight = `${modal.offsetHeight + 80}px`;
        modal.style.height = newHeight;
      }
    }, 500);
  }, [opened]);

  useEffect(() => setCurrentReferenceDetails(referenceDetails), [
    referenceDetails,
  ]);

  const saveOrderPreview = async () => {
    if (onSave) {
      const newReferenceList = []
      orderList.forEach(item => {
        const referencesType = currentReferenceDetails.filter(ref => ref.Type == item.Type);
        newReferenceList.push(referencesType)
      })
  
      const orderedItems = newReferenceList.flat().map((item, index) => ({
        ...item,
        Order: String(index + 1),
      }));
      onSave(orderedItems, orderList);
    }
    toast.info('Re-ordered with success!');
    onClose();
  };


  const onDragEnd = (result: any) => {
    setOrderlist(result.map((item, i) => ({...item, Order: i + 1 })));
  };

  const getType = (type: '1' | '2' | '3' | '') => {
    if (type == '1') {
      return 'Google Review';
    }
    if (type == '2') {
      return 'Client Testimonials';
    }
    if (type == '3') {
      return 'Client References';
    }
    return type;
  };

  const handleMoveCard = (cards: any) => {
    setCurrentReferenceDetails(cards);
  };

  return (
    <Modal onClose={onClose} opened={opened} className="preview-modal">
      <div className="modal-content">
        <div className="preview-close">
          <SVGIcon icon={CloseIcon} onClick={onClose} />
        </div>
        <div className="header-reference">
          <div className="header-logo-wrapper">
            <div className="header-logo"></div>
            <img src={LogoWhite} />
          </div>
        </div>
        <PreviewRerefenceContent 
          referenceDetails={currentReferenceDetails}
          onDragEnd={onDragEnd}
          orderList={orderList}
          onMoveCard={handleMoveCard}
          withDrag={withDrag}
        />
      </div>
      {withDrag && (
        <div className="confirm-buttons fixed edit-buttons">
          <ButtonComponent icon={BackIcon} onClick={onClose}>
            CANCEL
          </ButtonComponent>
          <ButtonComponent icon={SaveIcon} onClick={saveOrderPreview} secondary>
            SAVE
          </ButtonComponent>
        </div>
      )}
    </Modal>
  );
};

export default Preview;
