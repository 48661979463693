import React from 'react';
// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// intefaces
import {
  IStateToProps,
  IDispatchToProps
} from 'modules/InspectForm/interfaces';
import { IReduxState, IArea } from 'core/interfaces';
// ui
import './styles.scss';
import { IAreaItem } from '../../../core/interfaces/Area';
import { EditLabel } from '../EditLabel';
import { getQueryString } from 'core/utils';

import ActionAlertModal from 'core/components/ActionAlertModal';

type IProps = IDispatchToProps & IStateToProps;
class SectionRatingModal extends React.Component<IProps> {
  public state = {
    newItemName: '',
    showEmptyItem: false, // when adding a new item
    areaItems: [],
    editName: false,
    newTitle: '',
    showAlertModal: false,
    areaItemSelected: null,
  };

  public onChangeRange = (areaItem: IAreaItem, range: number) => {
    const { updateAreaItemRangeRequest } = this.props;
    if (areaItem.Grade === range) {
      updateAreaItemRangeRequest(areaItem, 0);
    } else {
      updateAreaItemRangeRequest(areaItem, range);
    }
  };

  public onChangeName = (index: number, value: string) => {
    const { areaItems } = this.state;
    const newAreaItems: IAreaItem[] = areaItems;
    newAreaItems[index].Name = value;
    this.setState({ areaItems: [...newAreaItems] });
  };

  public onClickRemove = (areaItem: IAreaItem) => {
    this.setState({
      showAlertModal: true,
      areaItemSelected: areaItem,
    })

    // const deleteConfirmation = window.confirm(
    //   'Are you sure you want to delete this item?'
    // );
    // if (deleteConfirmation) {
    //   const { removeAreaItemRequest } = this.props;
    //   removeAreaItemRequest(areaItem);
    // }
  };

  public onConfirmDeletion = () => {
    const { areaItemSelected } = this.state;
      const { removeAreaItemRequest } = this.props;
      removeAreaItemRequest(areaItemSelected);
      this.setState({
        showAlertModal: false,
        areaItemSelected: null,
      })
  }

  public onClickAddAreaItem = () => {
    const { newItemName } = this.state;
    const { addAreaItemRequest, setWarningModalState } = this.props;

    if (newItemName.length >= 3) {
      addAreaItemRequest(newItemName);
      this.setState({
        newItemName: '',
        showEmptyItem: false
      });
    } else {
      setWarningModalState(true, 'Invalid name for new area item!');
      // alert('Invalid name for new area item!')
    }
  };

  public onAddEmptyItem = () => {
    const { showEmptyItem } = this.state;

    this.setState({
      showEmptyItem: !showEmptyItem
    });
  };

  public onChangeTitle = (name: string, value: string) =>
    this.setState({ newTitle: value });

  public onEditTitle = (title: string) => () =>
    this.setState({
      editName: !this.state.editName,
      newTitle: this.state.newTitle || title
    });

  public onSubmitTitle = () => {
    const { newTitle } = this.state;
    const { selectedArea } = this.props;
    const title = selectedArea ? selectedArea.Name : '';
    const newArea: IArea = {
      ...Object(this.props.selectedArea),
      Name: newTitle || title
    };
    this.props.updateArea(newArea);
  };

  public renderAreaItem = (areaItem: IAreaItem, index: number) => {
    const { Grade, Name } = areaItem;
    const { areaItems } = this.state;
    const { isSent: isSentProp } = this.props;
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentProp || isSentQueryString;
    return (
      <tr className='section-item-container' key={index}>
        <td className='section-item-title'>
          <EditLabel
            name={index}
            value={Name}
            onChange={this.onChangeName}
            onBlur={() => this.props.updateAreaItems(areaItems)}
            disabled={isSent}
          />
        </td>
        <td className='section-item-rating'>
          <div
            className={`${Grade === 1 ? 'button-rate-one' : 'button-no-rate button-unselected-day'}`}
            onClick={() => !isSent && this.onChangeRange(areaItem, 1)}
          >
            1
          </div>
          <div
            className={`${Grade === 2 ? 'button-rate-two' : 'button-no-rate button-unselected-day'}`}
            onClick={() => !isSent && this.onChangeRange(areaItem, 2)}
          >
            2
          </div>
          <div
            className={`${
              Grade === 3 ? 'button-rate-three' : 'button-no-rate button-unselected-day'
            }`}
            onClick={() => !isSent && this.onChangeRange(areaItem, 3)}
          >
            3
          </div>
          <div
            className={`${Grade === 4 ? 'button-rate-four' : 'button-no-rate button-unselected-day'}`}
            onClick={() => !isSent && this.onChangeRange(areaItem, 4)}
          >
            4
          </div>
          <div
            className={`${Grade === 5 ? 'button-rate-five' : 'button-no-rate'}`}
            onClick={() => !isSent && this.onChangeRange(areaItem, 5)}
          >
            5
          </div>
        </td>
        <td
          className='section-actions-container'
          onClick={() => !isSent && this.onClickRemove(areaItem)}
        >
          <i className='trash-icon' />
          <span>Delete</span>
        </td>
      </tr>
    );
  };

  componentDidMount() {
    const { selectedArea } = this.props;
    this.setState({ areaItems: selectedArea!.AreaItems || [] });
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaItems } = this.state;
    const { Areas, selectedArea } = this.props;
    // const { Areas: prevPropsSelectedArea } = prevProps;

    const currentAreaSelected = Areas.filter((item) => item.Name === selectedArea.Name)

    if ((currentAreaSelected[0] && currentAreaSelected[0].AreaItems.length) !== areaItems.length) {
      this.setState({ areaItems: currentAreaSelected[0].AreaItems || [] })
    }

    // const prevPropsCurrentAreaSelected = prevPropsSelectedArea.filter((item) => item.Name === selectedArea.Name)

    // console.log('currentAreaSelected[0]', currentAreaSelected[0])

    // if((currentAreaSelected[0] && currentAreaSelected[0].AreaItems.length) !== (prevPropsCurrentAreaSelected[0] && prevPropsCurrentAreaSelected[0].AreaItems.length)) {
    //   this.setState({ areaItems: currentAreaSelected[0].AreaItems || [] })
    // }
  }

  public render() {
    const {
      newItemName,
      showEmptyItem,
      areaItems,
      editName,
      newTitle
    } = this.state;
    const {
      selectedArea,
      setRatingModalState,
      isSent: isSentProp,
      setAddItemModalState
    } = this.props;
    const title = selectedArea ? selectedArea.Name : '';
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentQueryString;

    // const areaItems = selectedArea ? selectedArea.AreaItems : [];
    return (
      <div className='section-rating-modal-root-div'>
        <div className='section-rating-modal-content-div'>
          <div className='modal-title-container'>
            {editName ? (
              <EditLabel
                className='title-input'
                style={{ width: `${newTitle.length * 12}px` }}
                name='title-area'
                value={newTitle}
                onChange={this.onChangeTitle}
                focus={editName}
                onBlur={() => this.onSubmitTitle()}
                disabled={isSent}
              />
            ) : (
              <h3>{newTitle || title}</h3>
            )}
            {!isSent && (
              <i
                className='edit-icon'
                onClick={() => !isSent && this.onEditTitle(title)()}
              />
            )}
          </div>
          <div className='modal-sections-indicators'>
            <div className='area-one'>
              <span>1 - Above Standard</span>
            </div>
            <div className='area-two'>
              <span>2 - Standard</span>
            </div>
            <div className='area-three'>
              <span>3 - Needs Attention</span>
            </div>
            <div className='area-four'>
              <span>4 - Schedule Not Followed</span>
            </div>
            <div className='area-five'>
              <span>5 - Fail</span>
            </div>
          </div>
          <table className='section-list-container'>
            <tbody className='full-width'>
              {areaItems.map((areaItem, index) =>
                this.renderAreaItem(areaItem, index)
              )}
              {!isSent && showEmptyItem && (
                <tr className='section-item-container'>
                  <td className='section-item-title'>
                    <input
                      placeholder='New Item'
                      value={newItemName}
                      onChange={e =>
                        this.setState({ newItemName: e.target.value })
                      }
                      onBlur={this.onClickAddAreaItem}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          this.onClickAddAreaItem();
                        }
                      }}
                    />
                  </td>
                  <td className='section-item-rating'>
                    <div className='button-no-rate disable button-unselected-day'>1</div>
                    <div className='button-no-rate disable button-unselected-day'>2</div>
                    <div className='button-no-rate disable button-unselected-day'>3</div>
                    <div className='button-no-rate disable button-unselected-day'>4</div>
                    <div className='button-no-rate disable'>5</div>
                  </td>
                  <td className='section-actions-container disable'>
                    <i className='trash-icon' />
                    <span>Delete</span>
                  </td>
                </tr>
              )}
              {!isSent && (
                <tr className='section-buttons-container'>
                  {/* Previous: onClick={this.onAddEmptyItem} */}
                  <button
                    className='button square'
                    onClick={() => setAddItemModalState(true)}
                  >
                    New Item
                  </button>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          className='rating-modal-done-button'
          onClick={() => setRatingModalState(false)}
        >
          <button className='button'>DONE</button>
        </div>

        <ActionAlertModal
          title=''
          message='Are you sure you want to delete this item?'
          onConfirm={() => this.onConfirmDeletion()}
          // onDismiss={this.props.closeExitModal}
          onDismiss={() => {
            this.setState({
              showAlertModal: false,
              areaItemSelected: null,
            })
          }}
          isVisible={this.state.showAlertModal}
        />

      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(SectionRatingModal);

export { Connected as SectionRatingModal };
