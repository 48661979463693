import styled from 'styled-components';

export const TextAreaWrapper = styled.textarea`
  width: 100%;
  padding: 10px;
  background: rgba(255, 253, 253, 0.5);
  border: none;
  font-size: 24px;
  resize: none;
  border: 1px solid black;
  background-color: white;
`;
