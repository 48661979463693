import React from 'react';
import { Button } from 'core/components';
import { Header, Heading, Actions, Title } from './styles';
import { ModalWrapper, Layout } from '../../components/layout';
import { createSignProvider, verifyUser } from '../../services/bidInfoService';

export default ({ message, title }) => {
  return (
    <ModalWrapper>
      <Layout
        padding="50px 20px"
        margin="40px"
        maxWidth="900px"
        width="90%"
        backgroundColor="#014984"
        position="relative"
        overflow="auto"
        borderRadius="10px"
      >
        <Header>
          <Heading>{title}</Heading>
          <Heading>{message}</Heading>
        </Header>
      </Layout>
    </ModalWrapper>
  );
};
