import styled from 'styled-components';

export const ParagraphRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  position: relative;
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 30px;
  margin-right: 10px;
`;

export const RightContentWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const Container = styled.div`
  padding: 20px;
  width: 90%;
  max-width: 1240px;
  margin: auto;
  overflow: auto;
`;

export const InfoBubbleWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  border: 1px solid black;
  cursor: pointer;
`;


export const PreviewHeader = styled.div`
  text-transform: none;
  margin-bottom: 0px;
  display: block;
  text-align: center;
  font-style: italic;
  font-family: OpenSans-BoldItalic;
  font-size: 23pt;
  padding-top: 0 !important;
  padding-bottom: 5px;
  position: relative;
  height: 60px;
  min-height: 60px;
  margin-top: 20px;

  &: before {
    background-color: rgb(33, 110, 171) !important;
    content: attr(data-title);
    width: 100% !important;
    height: 36px;
    position: absolute;
    top: 0;
    left: -3px;
    color: rgb(255, 255, 255);
    text-align: left;
    padding-left: 23px;
    font-size: 20pt !important;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 0px;
    content: 'Service Procurement Agreement';
  }

  &: after {
    content: '';
    position: absolute;
    right: -20px;
    border-top: 60px solid transparent;
    border-right: 55px solid white;
  }
`;

export const PreviewFooter = styled.p`
  text-align: left;
  line-height: 1.2;
  font-size: 9pt;
  font-weight: normal;
  margin: 0
  padding-top: 20px;
  padding-bottom: 70px;
`;

export const TermsContentPreview = styled.div`
  padding: 0;
  padding-right: 10px;
  text-align: justify;
  margin-bottom: 5px;
  font-size: 8pt !important;
  font-family: OpenSans-Light;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  line-height: 12px;
  margin-top: -10px;
  font-weight: 600;
`;


export const InsertNewButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 16px;
  background-color: #216eab;
  color: white;
  font-family: OpenSans-Regular;
  padding: 8px 15px 9px 15px;
  height: 40px;
  border-radius: 6px;
  margin-top: 1px;
  border-width: 1px;
  cursor: pointer;
`;

