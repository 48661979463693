import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Paragraph } from '../../../../../components/atoms';
import { RadioButton, InputFloatLabel } from 'core/components';
import paycheck from '../../../../../assets/paycheck.png';
import {
  Wrapper,
  AccountTypeHolder,
  ImageHolder,
  AccountTypeHeader,
} from './styles';
import { numberMask } from 'core/utils/masks';

import { isIpad } from 'core/utils';

type TArgs = {
  handleChange?: Function;
};
function Debit(props, ref) {
  const [dateOption, setDateOption] = React.useState(null);
  const [name, setName] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [accountType, setAccountType] = React.useState(null);
  const [routing, setRouting] = React.useState('');
  const [account, setAccount] = React.useState('');
  const [confirmAccount, setConfirmAccount] = React.useState('');
  const [billingAddress, setBillingAddress] = React.useState('');

  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] = React.useState(
    false
  );
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  function confirmAccountValidate() {
    var el = document.getElementsByClassName('validate-confirm-account')[0];
    if (account !== confirmAccount) {
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }
  React.useImperativeHandle(ref, () => ({
    getData: () => ({
      dateOption,
      name,
      bankName,
      accountType,
      routing,
      account,
      confirmAccount,
      billingAddress,
    }),
  }));

  return (
    <Wrapper>
      {/* {!props.values['IsSS'] && (
        <Paragraph fontSize="14px" fontWeight="bold">
          Please debit my account during the month of Service on the
        </Paragraph>
      )} */}

      {!props.values['IsSS'] && (
        <div
          style={{
            justifyContent: 'center',
            display: props.values['IsSS'] ? 'none' : 'flex',
          }}
        >
          <div
          data-cy="dateOption"
        ></div>
          <RadioButton
            value={dateOption}
            onChange={(name, value) => setDateOption(value)}
            name="option"
            label=""
            itemStyle={{
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
            containerStyle={{
              width: equalsOrBiggerThan768 ? '70%' : 'auto',
              justifyContent: 'center',
            }}
            contentStyle={{
              minWidth: '60px',
              justifyContent: equalsOrBiggerThan768 ? 'space-around' : 'center',
              width: equalsOrBiggerThan768 ? '100%' : 'auto',
            }}
            options={[
              {
                value: 1,
                label: <strong>5th</strong>,
              },
              {
                value: 2,
                label: <strong>15th</strong>,
              },
              {
                value: 3,
                label: <strong style={{ minWidth: '56px' }}>Last Day</strong>,
              },
            ]}
            optionLabel="label"
            optionValue="value"
          />
        </div>
      )}

      <div>
        <InputFloatLabel
          value={name}
          name="name"
          autoCapitalize={isIpad() ? 'none' : null}
          label="Name on Bank Account:"
          style={{ width: '100%' }}
          onChange={(name, value) => {
            setName(value);
          }}
          onBlur={() => setName(name)}
        />
      </div>

      <div>
        <InputFloatLabel
          value={bankName}
          name="bankName"
          autoCapitalize={isIpad() ? 'none' : null}
          label="Bank Name:"
          style={{ width: '100%' }}
          onChange={(name, value) => {
            setBankName(value);
          }}
        />
      </div>

      <AccountTypeHolder
      
        style={{ flexDirection: equalsOrBiggerThan768 ? 'row' : 'column' }}
      >
        <div style={{ width: equalsOrBiggerThan768 ? '50%' : '100%' }} data-cy="accountType">
          <AccountTypeHeader
            style={{
              flexDirection: equalsOrBiggerThan768 ? 'row' : 'column',
              alignItems: 'center',
              marginBottom: equalsOrBiggerThan768 ? '0' : '20px',
            }}
          >
            <Paragraph
              fontSize="16px"
              paddingRight={equalsOrBiggerThan768 ? '20px' : '0px'}
            >
              Account Type:
            </Paragraph>

            <RadioButton
              value={accountType}
              onChange={(name, value) => setAccountType(value)}
              contentStyle={{
                justifyContent: 'space-around',
                width: '100%',
              }}
              containerStyle={!equalsOrBiggerThan768 ? { width: '100%' } : {}}
              itemStyle={
                equalsOrBiggerThan768
                  ? {
                      marginBottom: equalsOrBiggerThan768 ? '0px' : '20px',
                    }
                  : {}
              }
              name="option"
              label=""
              options={[
                {
                  value: 1,
                  label: <strong>Checking</strong>,
                },
                {
                  value: 2,
                  label: <strong>Savings</strong>,
                },
              ]}
              optionLabel="label"
              optionValue="value"
            />
          </AccountTypeHeader>

          <InputFloatLabel
            value={routing}
            name="routing"
            autoCapilize="none"
            type="number"
            label="Routing #:"
            style={{ width: '100%' }}
            onChange={(name, value) => {
              setRouting(value);
            }}
          />

          <InputFloatLabel
            value={account}
            autoCapilize="none"
            name="account"
            type="number"
            label="Account #:"
            style={{ width: '100%' }}
            onChange={(name, value) => {
              setAccount(value);
            }}
          />

          <InputFloatLabel
            value={confirmAccount}
            name="confirmAccount"
            autoCapilize="none"
            type="number"
            label="Confirm Account #:"
            style={{ width: '100%' }}
            className="validate-confirm-account"
            onChange={(name, value) => {
              setConfirmAccount(value);
            }}
            onBlur={() => confirmAccountValidate()}
          />
        </div>
        {equalsOrBiggerThan768 && (
          <ImageHolder>
            <img src={paycheck} />
          </ImageHolder>
        )}
      </AccountTypeHolder>

      <div>
        <InputFloatLabel
          autoCapitalize={isIpad() ? 'none' : null}
          value={billingAddress}
          name="billingAddress"
          label="Bank Account Billing Address:"
          style={{ width: '100%' }}
          onChange={(name, value) => {
            setBillingAddress(value);
          }}
        />
      </div>
    </Wrapper>
  );
}

export default React.forwardRef(Debit);
