import React, { useState } from 'react';

import {
  Layout,
  GraySection,
  WhiteSection,
  SubSection,
} from '../../../components/layout';
import PreviewContent from '../../../../shared/sections/Summary/Supplies/PreviewContent';

import {
  IntroductionTitle
} from '../../../styles';
import { TermsAndConditions } from '../components/organisms';

const PageSupplies = (props, ref) => {
  const {
    data,
    franchiseData,
    selectedItem,
  } = props;
  const values = data || [];
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const franchiseValues = franchiseData || [];

  const dataSupplies = data && data.BidInformationSheetSupplys[0]

  return (
    <Layout margin="0">
      <IntroductionTitle maxFontSize="30px" textAlign="center" style={{ padding: '25px 0'}}>
        PAPER & RESTROOM SUPPLIES
      </IntroductionTitle>
      <GraySection padding="40px 25px 25px 25px" paddingBottom="0 !important">
        <SubSection paddingBottom="0 !important">
          {dataSupplies && (
            <PreviewContent
              className="page-supplies"
              withShadow
              supplies={dataSupplies.SupplyDetails.filter(item => item.Default)}
              introductionText={dataSupplies.IntroducionText}
              withDrag={false}
              showPrice={dataSupplies.ShowPrice}
            />
          )}
        </SubSection>
      </GraySection>
      <GraySection padding="0 20px 150px 20px">
        <TermsAndConditions
          values={values}
          franchiseValues={franchiseValues}
          showTerms={false}
          isTermsChecked={isTermsChecked}
          setIsTermsChecked={setIsTermsChecked}
          pageChanged={selectedItem}
        />
      </GraySection>
    </Layout>
  );
};

export default React.forwardRef(PageSupplies);
