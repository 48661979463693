import React from 'react';
import { Paragraph } from 'modules/BidInfoForm/components/atoms';
import {
  ContentInput,
  DatePicker,
  Chevron,
} from 'core/components';

import calendar from 'modules/BidInfoForm/assets/calendar.png';

export default React.forwardRef((props, ref) => {
  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] =
    React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);

  React.useEffect(() => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan768(window.innerWidth >= 769);
  }, [window.innerWidth]);

    React.useEffect(() => {
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      (el.childNodes[0] as HTMLElement).setAttribute('readOnly', 'true')
    );
  }, []);

  return (
    <ContentInput
      width={equalsOrBiggerThan768 ? '60%' : '100%'}
      position="relative"
      data-cy="serviceCommencementDate"
      flex
      flexDirection={'column'}
      justifyContent="center"
      alignItems="center"
    >
      <div
        className="proposal-date-picker"
        style={{
          display: 'flex',
          flexDirection: equalsOrBiggerThan768 ? 'row' : 'column',
          width: '100%',
        }}
      >
        <div
          onClick={() => {
            document
              .getElementsByClassName(
                'input-proposal-datepicker'
              )[0]
              .focus();
          }}
          style={{
            position: 'relative',
            textAlign: 'center',
            width: equalsOrBiggerThan768 ? '30%' : 'auto',
          }}
        >
          <div
            style={{
              padding: '5px',
              paddingLeft: '20px',
              marginRight: '35px',
            }}
          >
            <img src={calendar} width="40" height="45" />
          </div>
          <Chevron
            width={15}
            fill={'#777'}
            style={{
              transform: 'rotate(90deg)',
              position: 'absolute',
              marginLeft: '30px',
              marginTop: '20px',
              top: 0,
              zIndex: 1,
            }}
          />
        </div>
        <DatePicker
          onChange={(date) => props.onChangeDate(date)}
          onBlur={() => {}}
          className={`input-proposal-datepicker input-proposal-full-width`}
          selected={props.date}
          maxDate={new Date()}
          dateFormat="EEEE, MMMM dd, yyyy"
          style={{
            border: 'none',
            textAlign: 'center',
            width: equalsOrBiggerThan768 ? '66%' : 'auto',
          }}
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '5px, 10px',
            },
          }}
        />
      </div>
      <Paragraph
        fontSize="14px"
        textAlign={biggerThan768 ? 'left' : 'center'}
        padding="20px"
        paddingBottom="0"
        style={{
          fontStyle: 'italic',
          padding: '0',
          margin: '0',
          width: '70%',
          textAlign: 'center',
          marginLeft: equalsOrBiggerThan768 ? '110px' : '0',
          marginBottom: '20px',
        }}
        dangerouslySetInnerHTML={{
          __html: 'Please enter the date the contract was signed',
        }}
      />
    </ContentInput>
  );
});
