import { all } from 'redux-saga/effects';
import {
  watchAddArea,
  watchUpdateAreaItemRange,
  watchRemoveArea,
  watchUpdateAreaItems,
  watchUpdateArea,
  watchRemoveAreaItem,
  watchAddAreaItem,
  watchCalculateRates,
  watchUpdateDuplicatedAreas,
  watchAddAreaPhoto,
  watchRemoveAreaPhoto,
  watchSaveReport,
  watchGetEditFormRequest,
  watchGetPublicEditFormRequest,
  watchGetInspections,
  watchArchiveForm,
  watchSendEmailRequest,
  watchGetNextInspectionsPage,
  watchDuplicateForm,
  watchUpdateTemplateForm,
  watchCreateNewTemplateForm,
  watchCreateDraftFromTemplateForm,
} from 'modules/InspectForm/redux/sagas';
import { watchCreateNewTemplateFromDraftForm } from '../modules/InspectForm/redux/sagas';

export default function* rootSaga() {
  return yield all([
    // Inspection Form
    watchAddArea(),

    watchUpdateAreaItemRange(),
    watchRemoveArea(),
    watchUpdateAreaItems(),
    watchUpdateArea(),
    watchRemoveAreaItem(),
    watchAddAreaItem(),
    watchCalculateRates(),
    watchUpdateDuplicatedAreas(),
    watchAddAreaPhoto(),
    watchRemoveAreaPhoto(),
    watchSaveReport(),
    watchGetEditFormRequest(),
    watchGetPublicEditFormRequest(),
    watchGetNextInspectionsPage(),

    // Menu Inspection
    watchGetInspections(),
    watchArchiveForm(),

    // email pdf
    watchSendEmailRequest(),

    // sent form duplication
    watchDuplicateForm(),

    // template update when opt-in for cleansuite is true
    watchUpdateTemplateForm(),

    // create template from sent page
    watchCreateNewTemplateForm(),

    // create draft from template page
    watchCreateDraftFromTemplateForm(),

    // create template from draft page
    watchCreateNewTemplateFromDraftForm(),
  ]);
}
