import styled from 'styled-components';
import bannerServicesOfferingsFigure from './assets/banner-offer-protection.jpg';
import bannerServicesOfferingsFigureSpecialtyServices from './assets/banner-services-offering/banner-services-offering-figure.jpg';
import totalGaranteeRibbon from './assets/banner-services-offering/total-garantee-ribbon.png';
import bannerServicesOfferingBottom from './assets/banner-services-offering/banner-services-offering-bottom.png';
import step2ServiceFig1 from './assets/hexagon/step2-service-fig1.png';
import step2ServiceFig2 from './assets/hexagon/step2-service-fig2.png';
import step2ServiceFig3 from './assets/hexagon/step2-service-fig3.png';
import step2ServiceFig4 from './assets/hexagon/step2-service-fig4.png';
import step2ServiceFig5 from './assets/hexagon/step2-service-fig5.png';
import step2ServiceFig6 from './assets/hexagon/step2-service-fig6.png';
import step2ServiceFig7 from './assets/hexagon/step2-service-fig7.png';
import step2ServiceFig8 from './assets/hexagon/step2-service-fig8.png';
import backBackground from './assets/back-background.png';
import { IHeader, ICustomizable } from './components/interfaces';

import { Paragraph } from './components/atoms';

function getHexagonFigure(type: string) {
  switch (type) {
    case '1':
      return step2ServiceFig1;
    case '2':
      return step2ServiceFig2;
    case '3':
      return step2ServiceFig3;
    case '4':
      return step2ServiceFig4;
    case '5':
      return step2ServiceFig5;
    case '6':
      return step2ServiceFig6;
    case '7':
      return step2ServiceFig7;
    default:
      return step2ServiceFig8;
  }
}

export const BackButton = styled.button`
  background: url(${backBackground}) no-repeat;
  background-position: center;
  background-size: contain;
  color: white;
  width: 240px;
  height: 54px;
  font-family: 'OpenSans-SemiBoldItalic';
  font-size: 20px;
  border-width: 0px;
`;

export const HeaderBase = styled.span`
  text-transform: none;
  font-size: 7.2vw;
  margin-bottom: 0;
  display: block;
  width: 100%;
  text-align: left;
  ${({ fontWeight }: IHeader) =>
    fontWeight ? `font-weight: ${fontWeight};` : ''}
  ${({ paddingRight }: IHeader) =>
    paddingRight ? `padding-right: ${paddingRight};` : ''}
    ${({ paddingLeft }: IHeader) =>
    paddingLeft ? `padding-left: ${paddingLeft};` : ''}
    ${({ paddingTop }: IHeader) =>
    paddingTop ? `padding-top: ${paddingTop};` : ''}
    ${({ paddingBottom }: IHeader) =>
    paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
    ${({ padding }: IHeader) => (padding ? `margin: ${padding};` : '')}
    ${({ marginRight }: IHeader) =>
    marginRight ? `margin-right: ${marginRight};` : ''}
    ${({ marginLeft }: IHeader) =>
    marginLeft ? `margin-left: ${marginLeft};` : ''}
    ${({ marginTop }: IHeader) =>
    marginTop ? `margin-top: ${marginTop};` : ''}
    ${({ marginBottom }: IHeader) =>
    marginBottom ? `margin-bottom: ${marginBottom};` : ''}
    ${({ margin }: IHeader) => (margin ? `margin: ${margin};` : '')}
    ${({ fontSize }: IHeader) => (fontSize ? `font-size: ${fontSize};` : '')}
    ${({ textAlign }: IHeader) =>
    textAlign ? `text-align: ${textAlign};` : ''}
    ${({ color }: IHeader) => (color ? `color: ${color};` : 'color: #000')}
    ${({ width }: IHeader) => (width ? `width: ${width};` : '')}
     ${({ textTransform }: IHeader) =>
    textTransform ? `text-transform: ${textTransform};` : ''}

    @media (min-width: 768px) {
    ${({ color }: IHeader) => (color ? `color: ${color};` : 'color: #000')}
  }
`;

export const SpecialtyServiceDescription = styled(HeaderBase)`
  & p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: normal;
    ${({ color }: IHeader) => (color ? `color: ${color};` : 'color: #000')}
  }

  & p {
    margin: 0px !important;
  }
`;

export const H1BoldItalic = styled(HeaderBase)`
  font-family: 'Fujiyama Italic';
  text-transform: uppercase;
  @media (min-width: 400px) {
    font-size: 6.2vw;
  }
  @media (min-width: 1201px) {
    font-size: 78px;
  }

  @media (min-width: 1201px) {
    font-size: 78px;
  }
  ${({ maxFontSize }: IHeader) =>
    maxFontSize ? `font-size: ${maxFontSize};` : ''}
`;

export const IntroductionTitle = styled(H1BoldItalic)`
  font-size: 5vw;
  width: 100%;
  text-align: center;
  padding-left: 0;

  @media (min-width: 400px) {
    font-size: 22px;
  }
  @media (min-width: 768px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const SubSectionTitle = styled(H1BoldItalic)`
  font-size: 5vw;
  text-transform: none;
  width: 100%;
  text-align: left;
  font-family: 'OpenSans-BoldItalic';

  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const FormLabelSelect = styled.label`
  color: #3f3f3f;
  position: absolute;
  left: 0;
  ${({ top }: IHeader) => (top ? `top: ${top};` : 'top: -12px;')}
  cursor: text;
  font-size: 0.75rem;
  padding-left: 0;
  opacity: 1;
  transition: all 0.2s;
`;

export const SectionTitle = styled(H1BoldItalic)`
  font-size: 5vw;
  width: 100%;
  text-align: left;
  padding-left: 20px;

  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const AboutTitle = styled(H1BoldItalic)`
  font-size: 4.1vw;
  font-family: 'OpenSans-Bold';
  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const H2Bold = styled(HeaderBase)`
  font-size: 5vw;
  font-family: 'OpenSans-Bold';

  @media (min-width: 400px) {
    font-size: 4vw;
  }

  @media (min-width: 1201px) {
    font-size: 45px;
  }
  ${({ maxFontSize }: IHeader) =>
    maxFontSize ? `font-size: ${maxFontSize};` : ''}
`;

export const AccordionHeader = styled(HeaderBase)`
  font-family: 'OpenSans-Bold';
  font-size: 4.8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }

  span {
    font-size: 12px;
    font-weight: bold;
    color: black;
  }
`;

export const H2BoldItalic = styled(H1BoldItalic)`
  font-size: 5vw;
  font-family: 'OpenSans-BoldItalic';
  text-transform: none;

  @media (min-width: 400px) {
    font-size: 4vw;
  }

  @media (min-width: 600px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const H3Bold = styled(H2Bold)`
  font-size: 3.7vw;
  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const H3BoldItalic = styled(H1BoldItalic)`
  ${({ fontSize }: IHeader) => (fontSize ? `font-size: ${fontSize};` : '')}
  font-size: 3.70vw;

  @media (min-width: 400px) {
    font-size: 2.88vw;
  }
  ${({ maxFontSize }: IHeader) =>
    maxFontSize ? `font-size: ${maxFontSize};` : ''}
`;

export const H3LightItalic = styled(H3Bold)`
  font-family: 'OpenSans-LightItalic';
  font-weight: bold;
  @media (min-width: 400px) {
    font-size: 2.88vw;
  }
  @media (min-width: 769px) {
    font-weight: normal;
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const H3Regular = styled(H3LightItalic)`
  font-family: 'OpenSans-Regular';
  font-weight: normal;
`;

export const H3SemiBold = styled(H3LightItalic)`
  font-family: 'OpenSans-SemiBold';
  font-weight: normal;
`;

export const H3RegularItalic = styled(H3LightItalic)`
  font-family: 'OpenSans-RegularItalic';
  font-weight: normal;
  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const SignatureName = styled(H3Bold)`
  font-size: 3.5vw;
  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const SignatureTitle = styled(H3Regular)`
  font-size: 3.5vw;
  font-weight: normal;

  @media (min-width: 400px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }
`;

export const H3SemiBoldItalicHeader = styled(H3RegularItalic)`
  font-size: 3.7vw;

  @media (min-width: 400px) {
    font-size: 2.88vw;
  }
  @media (min-width: 769px) {
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }

  font-family: 'OpenSans-SemiBoldItalic';
`;

export const H3SemiBoldItalic = styled(H3RegularItalic)`
  font-size: 3.7vw;

  @media (min-width: 400px) {
    font-size: 2.88vw;
    ${({ maxFontSize }: IHeader) =>
      maxFontSize ? `font-size: ${maxFontSize};` : ''}
  }

  font-family: 'OpenSans-SemiBoldItalic';
`;

export const H4Light = styled(HeaderBase)`
  font-size: 3vw;

  @media (min-width: 400px) {
    font-size: 2.5vw;
  }

  @media (min-width: 1201px) {
    font-size: 30px;
  }
  ${({ maxFontSize }: IHeader) =>
    maxFontSize ? `font-size: ${maxFontSize};` : ''}
  font-family: 'OpenSans-Light';
`;

export const H4SemiBold = styled(H4Light)`
  font-family: 'OpenSans-SemiBold';
  @media (min-width: 1025px) {
    font-family: 'OpenSans-Regular';
  }
`;

export const DisclaimerParagraph = styled(Paragraph)<{ italic?: boolean }>`
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  min-height: 21px;
  ${({ italic }) => (italic ? 'font-style: italic;' : '')}
`;

export const HorizontalListItemSeparator = styled.span`
  font-style: normal;
  font-family: 'OpenSans-Regular';
  font-size: 20px;
`;
export const HorizontalListItem = styled.li`
  margin-left: 10px;
  margin-right: 10px;
`;
export const HorizontalList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  fontsize: 22px;
  textalign: left;
  color: #fff;
  padding-left: 0px;
  margin: 5px;
`;

export const ProtectionCardsMessage = styled.div`
  font-size: 13px;
  width: 100%;
  text-align: center;
  max-width: 555px;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    margin-top: 0;
  }
`;

export const ProtectionCardsDescription = styled.div`
  padding: 20px;
  font-size: 14px;
  text-align: justify;
  max-width: 870px;
`;

export const ProtectionCardsContactRepresentative = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  width: 100%;
  text-align: center;
  padding: 20px;
  margin-bottom: 130px;
`;

export const ProtectionDisinfetionServiceOptionsWrapper = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: rgb(128, 128, 128) 0px 1px 15px -5px;
  margin: 40px;
  padding: 10px;
`;

export const ProtectionDisinfetionServiceOptionsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 2fr));
  grid-template-rows: 1;

  div {
    padding: 5px 0px;
  }
`;

export const ProtectionDisinfetionServiceOptionsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:not(:first-child) {
    border-right: 2px solid black;
  }

  &:last-child {
    border-right: none;
  }
`;

export const ProtectionDisinfetionServiceOptionsHeaderRow = styled(
  ProtectionDisinfetionServiceOptionsRow
)`
  div {
    &:first-child {
      grid-column-start: 2;
    }
    span {
      background-color: #e8e8e8;
      width: 92%;
      text-align: center;
      padding: 0;
    }
    padding: 0px 0px 5px 0px;
  }
`;

export const ProtectionDisinfetionServiceOptionsHeaderItem = styled(
  ProtectionDisinfetionServiceOptionsItem
)`
  font-weight: bolder;
  border-right: 2px solid black;
`;

export const ProtectionCardsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.85);

  @media (max-width: 1024px) {
    transform: scale(0.75);
    margin-top: -15px;
  }
`;

export const ProtectionCard = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 315px;
  width: 290px;
  box-shadow: rgb(128, 128, 128) 0px 4px 12px -4px;

  @media (max-width: 600px) {
    width: 220px;
    height: 365px;
  }
`;

export const ProtectionCardHeader = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 26%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  position: relative;

  span {
    padding-bottom: 4px;
    opacity: 0.6;
    font-size: 16px;
    font-family: OpenSans-Bold;
    transform: scale(1, 1.4);
    letter-spacing: normal;
  }

  &::before {
    content: 'PROTECTION +';
    font-family: OpenSans-SemiBoldItalic;
    font-size: 20px;
    transform: scale(1, 2);
    position: absolute;
    letter-spacing: 1px;
    top: 17px;
  }

  @media (max-width: 600px) {
    height: 21%;
  }
`;

export const ProtectionCardBodyContrast = styled.div`
  height: 40px;
`;

export const ProtectionCardBody = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 80%;
  width: auto;
  font-family: OpenSans-Regular;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  text-align: left;
  padding: 0px 45px;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 18px;
  ${ProtectionCardBodyContrast} {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OpenSans-BoldItalic';
    letter-spacing: -1px;
    span {
      font-size: 16px;
      transform: scale(1, 1.5);
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0px 35px;
  }
`;

export const ProtectionCardBodyTitle = styled.span`
  font-family: OpenSans-Bold;
  font-style: normal;
  font-size: 18px;
  color: #000000;
  padding-bottom: 10px;
  line-height: 18px;
  margin-top: 55px;
`;

export const BannerServicesOfferingsPage2 = styled.div`
  overflow: hidden;
  z-index: -1;
  background: dimgray url(${bannerServicesOfferingsFigure}) no-repeat;
  background-size: cover;
  width: 100%;
  height: 276px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 1440px;
  margin: auto;
  background-position-y: 60% !important;
  background-blend-mode: soft-light;

  @media (min-width: 768px) {
    background: dimgray url(${bannerServicesOfferingsFigure}) right bottom
      no-repeat;
    background-blend-mode: soft-light;
    background-size: 135%;
    background-position-x: -130px;
    height: 400px;
  }

  @media (min-width: 900px) {
    background-size: 100%;
    background-position-x: 0;
  }

  @media (min-width: 1200px) {
    height: 450px;
  }

  &::before {
    background: transparent url(${totalGaranteeRibbon}) no-repeat scroll 0 0;
    content: '';
    position: absolute;
    top: -53px;
    left: -10px;
    width: 160px;
    height: 265px;
    transform: scale(0.6);

    @media (min-width: 768px) {
      top: -25px;
      left: 15px;
      transform: scale(0.8);
    }

    @media (min-width: 1023px) {
      left: 75px;
      top: 0;
      transform: scale(1);
    }
  }

  &::after {
    // background: transparent url(${bannerServicesOfferingBottom}) no-repeat
    //   scroll;
    background: #f7a11d;
    bottom: -150px;
    left: -5px;
    transform: rotate(-4deg);

    content: '';
    position: absolute;
    bottom: -50px;
    width: 102%;
    height: 120px;
    z-index: 0;
    background-position-x: center;

    // @media (min-width: 600px) {
    //   bottom: -190px;
    // }

    // @media (min-width: 900px) {
    //   bottom: -45%;
    // }
    // @media (min-width: 1200px){
    // }
  }

  ${H1BoldItalic} {
    text-align: center;
    z-index: 1;
    margin-bottom: 20px;
    // line-height: 8vw;
    text-shadow: 1px 1px 5px #1f211f;
    font-size: 22px;
    letter-spacing: 2px;
  }

  @media (min-width: 600px) {
    ${H1BoldItalic} {
      font-size: 25px;
    }
  }

  @media (min-width: 769px) {
    ${H1BoldItalic} {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }

  // @media (min-width: 1200px) {
  //   ${H1BoldItalic} {
  //     font-size: 42px;
  //   }
  // }

  @media (min-width: 1364px) {
    ${H1BoldItalic} {
      // margin-bottom: 50px;
    }
  }
`;

export const BannerServicesOfferingsPage3 = styled(
  BannerServicesOfferingsPage2
)`
  background: transparent url(${bannerServicesOfferingsFigureSpecialtyServices})
    no-repeat;
  overflow: hidden;
  z-index: -1;
  background-position-x: initial;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  background-size: cover;
  width: 100%;
  height: 276px;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
  max-width: 1440px;
  margin: auto;
  background-position-y: 60% !important;

  @media (min-width: 768px) {
    background: transparent
      url(${bannerServicesOfferingsFigureSpecialtyServices}) right bottom;
    background-size: 100%;
    background-position-x: 0;
  }

  &::after {
    background: #216eab;
  }

  @media (min-width: 600px) {
    ${H1BoldItalic} {
      font-size: 25px;
      padding-bottom: 20px;
    }
  }
`;

type THexagon = {
  type: string;
};

export const HexagonItem = styled.div<THexagon>`
  position: relative;
  width: 145px;
  height: 150px;
  background: transparent url(${({ type }) => getHexagonFigure(type)}) no-repeat
    scroll 0 0;
  background-size: cover;
`;

export const ServicesText = styled.div`
  padding: 20px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-left: -10px;
  padding-top: 10px;

  ${H3Bold} {
    font-size: 16px;
    text-align: left;
    line-height: 22px;
  }

  ${Paragraph} {
    @media (min-width: 769px) {
      font-size: 14px;
    }
    @media (max-width: 769px) {
      font-size: 12px;
    }
    line-height: 18px;
    margin: 5px 0px 0 0;
  }

  @media (max-width: 830px) {
    min-height: 150px;
    padding-right: 7px;
  }

  @media (max-width: 767px) {
    padding-right: 15px !important;
  }
`;

export const CheckboxHolder = styled.div`
  /* margin-top: 65px; */
  display: flex;
  align-items: center;

  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    margin-top: 0px;
    padding-top: 22px;
  }
`;

export const CheckBoxHolder = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 769px) {
    // margin-right: 10px;
  }
`;

interface ITextAreaInput {}

export const TextAreaInput = styled.textarea<ITextAreaInput>`
  width: 100%;
  height: 72px;
  resize: none;
  font-size: 14px;
`;

interface IList extends ICustomizable {
  prefixMargin?: string;
  prefixColor?: string;
  fontSize?: string;
  prefixFontSize?: string;
  listSpacing?: string;
  minWidth?: string;
  marginBottom?: string;
}

interface IListItem extends IList {
  prefix?: string;
}

export const ListItem = styled.li<IListItem>`
  display: flex;
  line-height: 1.5;
  flex-direction: row;
  min-width: ${({ minWidth = 'unset' }) => minWidth};
  width: ${({ width = 'unset' }) => width};

  &:last-child {
    margin-bottom: unset;
  }

  &::before {
    content: attr(prefix);
    font-family: 'OpenSans-Bold';
    text-align: center;
    min-width: 25px;
    ${({ prefixFontSize }) =>
      prefixFontSize ? `font-size: ${prefixFontSize};` : ''}

    ${({ prefixColor }) => (prefixColor ? `color: ${prefixColor};` : '')}

    ${({ prefixMargin }) =>
      prefixMargin ? `margin-right: ${prefixMargin};` : 'margin-right: 10px;'}
  }
`;

export const List = styled.ul<IList>`
  list-style: none;
  padding: 0;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: justify;
  ${({ fontSize }: IList) => (fontSize ? `font-size: ${fontSize};` : '')}

  ${({ listSpacing }) =>
    listSpacing ? `${ListItem} { margin-bottom: ${listSpacing}; }` : ''}

    ${({ justifyContent }: IList) =>
    justifyContent ? `justify-content: ${justifyContent};` : ''}
`;

export const Modal = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 185px;
  margin: 0 auto;
  padding: 30px 0;
  box-sizing: border-box;
`;
export const ModalBody = styled.div`
  padding: 20px 40px 40px 40px;
  flex: 1;
  font-size: 18px;
  letter-spacing: 1.5px;
  text-align: center;

  strong {
    color: #f7a11d;
  }

  @media (max-width: 600px) {
    padding: 20px 0px 40px 0px;
  }
`;
export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  height: 55px;
  justify-content: space-between;
`;

export const LabelPlus = styled.div`
  padding-right: 5px;
  font-size: 26px;
`;

export const LightStaticText = styled.span`
  font-family: 'OpenSans-LightItalic';
`;

export const LogoHolder = styled.div`
  margin-bottom: 10px;
`;
