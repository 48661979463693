import React, { Component } from 'react';
import { getQueryString } from 'core/utils';
import axios from 'axios';
import {
  getNextPagePerformance,
  getPerformancesByUserId,
} from '../../../services/performanceEvaluationService';
import * as Sentry from '@sentry/react';
import debounce from 'lodash.debounce';
import './SearchModalStyles.scss';

const CancelToken = axios.CancelToken;
let cancel;

export const SearchModal = (props) => {
  const [state, setStateFunction] = React.useState({
    list: [],
    searchQuery: '',
    totalNumberOfSents: 0,
    loading: false,
  });

  const { renderTableItems, onClickSent, onCloseModal } = props;
  const { searchQuery, list, loading } = state;

  const setState = (value: any) => {
    setStateFunction((prevState) => ({
      ...prevState,
      ...(value || {}),
    }));
  };

  React.useEffect(
    debounce(async () => {
      const userId = getQueryString(window.location.href, 'userId');

      try {
        cancel && cancel();
        const response = await getNextPagePerformance(
          props.type,
          userId,
          '0',
          searchQuery,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        // const { Sents, TotalSents } = response.data;
        // Sents.forEach((item) => (item.type = 'sents'));

        const items = response.data[props.type];
        const totalItems = response.data[`Total${props.type}`];

        items.forEach((item) => (item.type = 'sents'));

        setState({
          list: items,
          totalNumberOfSents: totalItems,
          loading: false,
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('post request canceled', error);
        } else {
          setState({
            list: [],
            totalNumberOfSents: 0,
            loading: false,
          });
        }
        Sentry.captureException(error);
      }
    }, 500),
    [searchQuery]
  );

  const onChangeInput = (text: string) => {
    setState({
      searchQuery: text,
      loading: true,
    });
  };

  const formType = props.type == 'Signed'
  ? 'Signed'
  : props.type
  ? props.type.substr(0, props.type.length - 1)
  : 'Signed';

  const searchLabel = formType.toLowerCase() === 'template' ? 'my template' : formType.toLowerCase() === 'citytemplate' ? 'city template' : formType

  return (
    <div className="search-modal-container">
      <div className="search-modal-content">
        <h2 className="search-title">
          Search{' '}
          {searchLabel}{' '}
          Items
        </h2>

        <div className="search-input">
          <input
            placeholder="Type the PID"
            value={searchQuery}
            onChange={(e) => onChangeInput(e.target.value.toUpperCase())}
          />
        </div>
        {loading ? (
          <div className="loader-container">
            <div className="loading-div">
              <div className="loader-search" />
              <p>Loading ...</p>
            </div>
          </div>
        ) : (
          <table className="table-container">
            <tbody>
              {list.map((item, index) =>
                renderTableItems(item, index, onClickSent, 'sent')
              )}
            </tbody>
          </table>
        )}

        <div className="buttons-wrapper">
          <button className="button-close" onClick={onCloseModal}>
            CLOSE
          </button>
          {/* <button
              className="button"
              // onClick={props.onConfirm}
            >
              OK
            </button> */}
        </div>
      </div>
    </div>
  );
};
