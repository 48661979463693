import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { isWebView } from 'core/utils';

import { BackButton } from 'core/components';

import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { IDispatchToProps } from 'modules/InspectForm/interfaces';

import './styles.scss';
import { getInspectionURL } from '../../../../../BidInfoForm/utils';
import { checkMaintenenceMode } from '../../../../../BidInfoForm/services/bidInfoService';

interface IOwnProps {
  needsSaving: boolean;
  hideButtonDesktop: boolean;
}

type IProps = IDispatchToProps & IOwnProps & RouteComponentProps;

const Header = (props: IProps) => {
  const [showMaintenanceMode, setShowMaintenanceMode] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const maintenanceMode = await checkMaintenenceMode();
      if (maintenanceMode.status == '200' && maintenanceMode.data) {
        setShowMaintenanceMode(maintenanceMode.data);
      }
    })();
  }, []);

  return (
    <>
      {showMaintenanceMode && (
        <div className="maintenance-alert-wrapper" style={{ width: '100%' }}>
          <div
            className="maintenance-alert-message"
            style={{
              backgroundColor: 'white',
              maxWidth: '1200px',
              textAlign: 'center',
              padding: '15px',
              margin: '0 auto',
            }}
          >
            <span
              className="message"
              style={{ color: 'black', textAlign: 'center' }}
            >
              <span style={{ fontWeight: 'bolder', color: 'red' }}>
                Attention:{' '}
              </span>
              {showMaintenanceMode}
            </span>
          </div>
        </div>
      )}

      <div className="header">
        <div className="header-content">
          <div className="header-title-content">
            <h1>Inspection Report</h1>
            {props.conductedBy && (
              <div className="conducted-by">
                Conducted By: {`${props.conductedBy || ''}`}{' '}
              </div>
            )}
          </div>
          {isWebView() && (
            <BackButton
              style={{
                position: 'absolute',
                right: '52px',
                top: '10px',
              }}
              onClick={() =>
                props.needsSaving
                  ? props.openExitModal()
                  : isWebView()
                  ? props.history.push('/close-form')
                  : (document.location.href = getInspectionURL())
              }
            />
          )}

          {!props.hideMenu && (
            <div
              className="hamb-menu-icon"
              onClick={() => props.openSearch()}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...InspectFormActions,
    },
    dispatch
  );

const WithRouter = connect<null, IDispatchToProps, IOwnProps>(
  null,
  mapDispatchToProps
)(withRouter(Header));

export { WithRouter as Header };
