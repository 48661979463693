import { axiosInstance } from 'core/utils';
import { toast } from 'react-toastify';
import { IAPIResponse } from '../interfaces';
import { parseToApi } from '../parser';
import { defaultPostPayload as payload } from 'modules/shared/bidDefaultValues';
import * as Sentry from '@sentry/react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {
  parseDefaultCustomizeTermsPayloadFromServer,
  parseDefaultCustomizeTermsPayloadToServer,
} from './utils';

const env = runtimeEnv();

const pathUrl = '/BidSheet';

export const getBidInfoById = (bidInfoId: string) => {
  return axiosInstance.get(`${pathUrl}/${bidInfoId}`);
};

export const getBlankPdfUrl = (bidUniqueId: string) => {
  return axiosInstance.get(`${pathUrl}/GetBlankPdfUrl/${bidUniqueId}`);
};

export const postBidInfo = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.put(`${pathUrl}/Update`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateBidCall = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.put(`${pathUrl}/Update`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const createTemplate = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/CreateTemplate`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateTemplate = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/UpdateTemplate`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const createDuplicate = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/CreateDuplicate`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const autoSaveCall = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.put(`${pathUrl}/AutoSave`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postSetNotes = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/SetNotes`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postArchiveBid = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/SetArchived`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getFranchiseById = (franchiseId: string) => {
  // http://anago-forms.api.staging.test.conceptsol.com/api/GetAllFranchises?FranchiseId=2085
  return axiosInstance.get(`/GetAllFranchises?FranchiseId=${franchiseId}`);
};

export const checkIsGeneratingPDF = (uniqueId: string) => {
  return axiosInstance.get(`${pathUrl}/CheckIsGeneratingPDF?id=${uniqueId}`);
};

export const isAlive = () => {
  return axiosInstance.get(`/itsalive`);
};

export const isTabIdValid = (uid, tabId) => {
  return axiosInstance.get(
    `${pathUrl}/CheckIfIsValidForThisTab?BidUniqueID=${uid}&TabUniqID=${tabId}`
  );
};

export const getByUniqID = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/GetByUniqID/${uniqueID}`);
};

export const loadTemp = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/LoadTemp/${uniqueID}`);
};

export const loadNonTemp = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/LoadNonTemp/${uniqueID}`);
};

export const getByUniqueIDNew = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/GetByUniqueIDNew/${uniqueID}`);
};

export const getSignNowDownloadLink = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/getdocumentlink/${uniqueID}`);
};

export const getEasterEggTemplate = () => {
  return axiosInstance.get(`${pathUrl}/GetEasterEgg`);
};

export const getEasterEggSSTemplate = () => {
  return axiosInstance.get(`${pathUrl}/GetEasterEggSS`);
};

export const getProtectionJson = () => {
  return axiosInstance.get(`${pathUrl}/GetProtectionJson`);
};

export const getNextPageBids = ({
  type,
  userId,
  page,
  pid,
  isSpecialty,
  config,
  franchiseId,
}: {
  type: string;
  userId: string | number;
  page: string | number;
  pid?: string | undefined | null;
  isSpecialty?: boolean;
  config?: any;
  franchiseId: string | number;
}) => {
  const getNext = (uri: string) =>
    axiosInstance.get(
      `${pathUrl}/${uri}?id=${userId}&page=${
        page || '0'
      }&franchiseId=${franchiseId}&pid=${pid || ''}${
        isSpecialty ? '&isSS=true' : ''
      }`,
      config
    );

  switch (type) {
    case 'Signed':
      return getNext('GetSignedsByUser');
    case 'Sents':
      return getNext('GetSentsByUser');
    case 'Drafts':
      return getNext('GetDraftsByUser');
    case 'Templates':
      return getNext('GetTemplatesByUser');
    case 'CityTemplates':
      return getNext('GetCityTemplatesByUser');
    default:
      return getNext('GetBidsByUser');
  }
};

export const getBidsByUserId = ({
  userId,
  page,
  pid,
  isSpecialty,
  config,
  franchiseId,
}: {
  userId: string | number;
  page: string | number;
  pid?: string | undefined | null;
  isSpecialty?: boolean;
  config?: any;
  franchiseId: string | number;
}) => {
  return axiosInstance.get(
    `${pathUrl}/GetBidsByUser?id=${userId}&page=${
      page || '0'
    }&franchiseId=${franchiseId}&pid=${pid || ''}${
      isSpecialty ? '&isSS=true' : ''
    }`,
    config
  );
};

export const getSetSigned = (formUniqueId: string): Promise<IAPIResponse> => {
  return axiosInstance.get(`${pathUrl}/SetSignedDocument/${formUniqueId}`);
};

export const setInvalidDocument = (
  formUniqueId: string
): Promise<IAPIResponse> => {
  return axiosInstance.get(
    `${pathUrl}/SetInvalidDocument?guid=${formUniqueId}`
  );
};

export const getSignNowStatus = (
  formUniqueId: string
): Promise<IAPIResponse> => {
  return axiosInstance.get(
    `${pathUrl}/CheckPendDocSpecificBid?guid=${formUniqueId}`
  );
};

export const updateBid = async ({ values, userId, showToastr = true }) => {
  try {
    const parsed = parseToApi(values);
    const data = {
      ...payload,
      ...parsed,
    };

    if (!data['BidUniqueID'] || !data['CreatedBy']) {
      data['CreatedBy'] = userId;
    }

    let method =
      values.isCityTemplate || values.isTemplate
        ? createTemplate
        : values.isDuplicate
        ? createDuplicate
        : updateBidCall;
    if (values.isOverwritten) {
      method = updateTemplate;
    }
    const response: IAPIResponse = await method(data);
    console.debug(response.data);

    if (showToastr) {
      let successMessage = '';

      if (values.isCityTemplate) {
        successMessage = 'City Template was overwritten successfully';
      } else if (values.isTemplate) {
        successMessage = 'My Template was overwritten successfully';
      } else if (values.sentStatus) {
        successMessage = 'Your sent proposal was saved successfully';
      } else {
        successMessage = 'Your draft was saved successfully';
      }

      toast.info(successMessage);
    }

    return {
      error: false,
      data: response.data,
    };
  } catch (e) {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;
    Sentry.captureException(e);
    return {
      error:
        msg || 'There was an error while performing a request to the server',
      data: null,
    };
  }
};

export const postToApi = async ({ values, userId, showToastr = true }) => {
  try {
    const parsed = parseToApi(values);
    const data = {
      ...payload,
      ...parsed,
    };

    if (!data['BidUniqueID'] || !data['CreatedBy']) {
      data['CreatedBy'] = userId;
    }

    const response: IAPIResponse = await postBidInfo(data);
    console.debug(response.data);

    if (showToastr) {
      let successMessage = '';

      if (values.isCityTemplate) {
        successMessage = 'City Template was overwritten successfully';
      } else if (values.isTemplate) {
        successMessage = 'My Template was overwritten successfully';
      } else if (values.sentStatus) {
        successMessage = 'Your sent proposal was saved successfully';
      } else {
        successMessage = 'Your draft was saved successfully';
      }

      toast.info(successMessage);
    }

    return {
      error: false,
      data: response.data,
    };
  } catch (e) {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;
    Sentry.captureException(e);
    return {
      error:
        msg || 'There was an error while performing a request to the server',
      data: null,
    };
  }
};

export const postAutosave = async ({ values, userId, showToastr = true }) => {
  try {
    const parsed = parseToApi(values);
    const data = {
      ...payload,
      ...parsed,
    };

    if (!data['BidUniqueID'] || !data['CreatedBy']) {
      data['CreatedBy'] = userId;
    }

    const response: IAPIResponse = await autoSaveCall(data);
    console.debug(response.data);

    if (showToastr) {
      let successMessage = '';

      // Refactor this
      if (values.isCityTemplate) {
        successMessage = 'City Template was overwritten successfully';
      } else if (values.isTemplate) {
        successMessage = 'My Template was overwritten successfully';
      } else if (values.sentStatus) {
        successMessage = 'Your sent proposal was saved successfully';
      } else {
        successMessage = 'Your draft was saved successfully';
      }

      toast.info(successMessage);
    }

    return {
      error: false,
      data: response.data,
    };
  } catch (e) {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;
    Sentry.captureException(e);
    return {
      error:
        msg || 'There was an error while performing a request to the server',
      data: null,
    };
  }
};

/**
 * Conditions:
 * draft = sentStatus === false && isTemplate === false
 * template = sentStatus === false && isTemplate === true
 * sent = sentStatus = true
 * */
export const saveDraft = async (values, userId, isAutoSave = false) => {
  if (
    (values.uid || values.bidInfoId) &&
    (values.isTemplate || values.sentStatus) &&
    !isAutoSave
  ) {
    values.uid = null;
    values.bidInfoId = null;
  }

  // values.isTemplate = false;
  // values.isCityTemplate = false;

  // values.sentStatus = false;
  values.generateBlankPDF = false;

  return postAutosave({ values, userId, showToastr: !isAutoSave });
};

export const autoSave = async (values, userId, isAutoSave = false) => {
  // Need refactor on this to not save template on autosave
  if (
    (values.uid || values.bidInfoId) &&
    (values.isTemplate || values.sentStatus) &&
    !isAutoSave
  ) {
    values.uid = null;
    values.bidInfoId = null;
  }

  // values.isTemplate = false;
  // values.isCityTemplate = false;

  // values.sentStatus = false;
  values.generateBlankPDF = false;

  return postAutosave({ values, userId, showToastr: !isAutoSave });
};

export const saveTemplate = async (
  values,
  userId,
  isCityTemplate = false,
  isSaveAs = false,
  isOverwritten = false
) => {
  let uidOriginal = null;
  if (
    (values.uid && isSaveAs && values.isTemplate) ||
    (isSaveAs && values.isCityTemplate) ||
    (isSaveAs && values.sentStatus) ||
    (isSaveAs && values.status == 2)
  ) {
    uidOriginal = values.uid;
    if (values.sentStatus || isSaveAs) {
      values.uid = null;
      values.bidInfoId = null;
    }
  }

  values.isCityTemplate = isCityTemplate;

  values.isTemplate = !isCityTemplate;
  values.isDraft = false;
  values.isAutoSave = false;
  values.sentStatus = false;
  values.status = null;

  if (isSaveAs && !isOverwritten) {
    values.BidUniqueId = null;
  }

  const result = updateBid({ values, userId });

  return result;
};

export const createSignProvider = (userId) =>
  axiosInstance.get(`${pathUrl}/CreateSignProvider?id=${userId}`);
export const verifyUser = (userId) =>
  axiosInstance.get(`${pathUrl}/VerifyUser?id=${userId}`);

export const getPDFCall = async (bidInformationSheetId: string) => {
  const getURL = (id: string) => {
    const href = document.location.href;
    let str =
      env.REACT_APP_BACKEND_PORTAL_URL ||
      'http://anago.app.dev.test.conceptsol.com/';

    // if (href.includes('localhost') || href.includes('app-dev')) {
    //   str = 'http://anago.app.dev.test.conceptsol.com/PDF/Print/[id]/9';
    // } else if (href.includes('app-stag')) {
    //   str = 'http://anago.app.staging.test.conceptsol.com/PDF/Print/[id]/9';
    // } else if (href.includes('app-rc')) {
    //   str = 'http://anago.app.rc.test.conceptsol.com/PDF/Print/[id]/9';
    // } else {
    //   // production
    //   str = 'https://admin.anago.conceptsol.com/PDF/Print/[id]/9';
    // }

    str = str + 'PDF/Print/[id]/9';

    str = str.replace('[id]', id);

    return str;
  };

  const url = getURL(bidInformationSheetId);

  const result = await axiosInstance.get(url);
  return result.Value;
};

export const getPdfBase64 = async (bidId: string) => {
  var base64 = await axiosInstance.get(
    `${pathUrl}/GetPDFBase64?bidId=${bidId}`
  );
  return base64.data;
};

export const getNewPdfBase64 = async (bidUniqueId: string) => {
  var base64 = await axiosInstance.get(
    `${pathUrl}/GetSignedPDFBase64?biduniqueid=${bidUniqueId}`
  );
  return base64.data;
};

export const checkMaintenenceMode = async () => {
  return await await axiosInstance.get(`/CheckMaintenceMode`);
};

export const getEnvironmentUrl = () => {
  const href = document.location.href;
  let str =
    env.REACT_APP_BACKEND_PORTAL_URL ||
    'https://admin.dev.anagocleansource-test.com/';

  // if (href.includes('localhost') || href.includes('app-dev')) {
  //   str = 'http://anago.app.dev.test.conceptsol.com/';
  // } else if (href.includes('app-stag')) {
  //   str = 'http://anago.app.staging.test.conceptsol.com/';
  // } else if (href.includes('app-rc')) {
  //   str = 'http://anago.app.rc.test.conceptsol.com/';
  // } else {
  //   // production
  //   str = 'https://admin.anago.conceptsol.com/';
  // }

  return str;
};

export const getAttachmentsPdf = (id: string) => {
  return `${getEnvironmentUrl()}Media/${id}.pdf`;
};

export const getInsurancePdf = (franchiseName: string) => {
  return `${getEnvironmentUrl()}Insurances/Insurance_${franchiseName}.pdf`;
};

export const verifyServerUnavailable = (userId: string) => {
  return axiosInstance.get(`/ServerMaintenance?userId=${userId}`);
};

export const getBase64ByUrl = (pdfUrl) => {
  return axiosInstance.get(`/PDFViewerController/GetBase64?link=${pdfUrl}`);
};

export const getBase64ByParams = (type, key, base64) => {
  return axiosInstance.get(
    `/PDFViewerController/GetPDF?typepdf=${type}&key=${key}&returnBase64=${base64}`
  );
};

export const getDirectPdfDownloadUrl = (link, filename) => {
  const apiUrl =
    env.REACT_APP_API_HOST ||
    'https://forms-api.dev.anagocleansource-test.com/api';
  return `${apiUrl}/PDFViewerController/downloadpdf?link=${link}&filename=${filename.replace(
    /\&/,
    ' '
  )}.pdf`;
};

export const uploadOneTimeAttachment = async (
  bidUniqueId: string,
  attachment: any
) => {
  if (attachment.fileContent) {
    const blob = await fetch(attachment.fileContent).then((res) => res.blob());

    const formData = new FormData();
    formData.append('File', blob, attachment.fileContentName);
    formData.append('BidUniqueID', bidUniqueId);

    try {
      const { data } = await axiosInstance.post(
        `${pathUrl}/uploadOneTimeAttachment`,
        formData
      );
      return true;
    } catch (e) {
      throw e;
    }
  }
};

export const uploadPhysicalContract = async ({
  file: attachment,
  id,
  isSS,
}) => {
  if (attachment.fileContent) {
    const blob = await fetch(attachment.fileContent).then((res) => res.blob());

    const formData = new FormData();
    formData.append('file', blob, attachment.fileContentName);

    try {
      const { data } = await axiosInstance.post(
        `${pathUrl}/uploadPhysicalContract?id=${id}&isSS=${isSS}&fileName=${attachment.fileContentName}`,
        formData
      );
      return data;
    } catch (e) {
      throw e;
    }
  }
};

export const getCustomizeTerms = async (franchiseId, isSpecialty) => {
  const { data } = await axiosInstance.get(
    `Franchise/get${
      isSpecialty === true ? 'SS' : 'Bid'
    }Fields?franchiseId=${franchiseId}`
  );

  return parseDefaultCustomizeTermsPayloadFromServer(!!isSpecialty, data);
};

export const saveCustomizeTerms = async (terms, isSpecialty) => {
  const { data } = await axiosInstance.post(
    `Franchise/save${isSpecialty === true ? 'SS' : 'Bid'}Fields`,
    { terms: parseDefaultCustomizeTermsPayloadToServer(!!isSpecialty, terms) }
  );

  return parseDefaultCustomizeTermsPayloadFromServer(!!isSpecialty, data);
};

export const getBidByUserTamplates = async ({
  userId,
  isSpecialty,
  page,
  franchiseId,
  referenceName = '',
}: {
  userId: string | number;
  isSpecialty: boolean;
  page: string | number;
  franchiseId: string | number;
  referenceName: string;
}): Promise<string[]> => {
  try {
    const { data } = await axiosInstance.get(
      `/BidSheet/GetBidsByUserTemplates?id=${userId}&page=${page}&referenceName=${referenceName}&pid=&IsSS=${isSpecialty}&franchiseId=${franchiseId}`
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const notificationMissingInsuranceDocument = (
  franchiseId: string | number
) => {
  return axiosInstance.post(`Franchise/notificationMissingInsuranceDocument`, {
    FranchiseId: franchiseId,
  });
};

export const getSpecialtyServiceQuoteOptions = () => {
  return axiosInstance.get(
    'SpecialtyServiceQuoteOption/GetSpecialServiceQuoteOptions?type='
  );
};
