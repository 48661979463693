import React, { useState, useEffect, useCallback } from 'react';
import { withFormik, FormikProps } from 'formik';

import {
  InputFloatLabel,
  RadioButton,
  useFormProps,
  ContentInput,
  Select,
  FilterSelect,
  ButtonGroup,
} from 'core/components';
import { isIpad, getQueryString } from 'core/utils';

import { EditButton, EditButtonSecondary, FormLabelSelect } from './styles';

import { sicValues } from './constants';

import './styles.scss';
import { IBidClientInfo } from 'modules/BidInfoForm/interfaces';
import { clientInfoValuesInspect } from 'modules/shared/bidDefaultValues';
import { phoneMask, postalCodeMask, stateMask } from 'core/utils';

type IProps = FormikProps<IBidClientInfo>;
const ClientInfoInspect = ({ refreshIntegration, isPreview }: IProps) => {
  const { onChange, setFormValues, formValues } = useFormProps();
  const isDuplicateQueryString = getQueryString(
    window.location.href,
    'isDuplicate'
  );
  const onBlur = useCallback(
    (name: string) => () => onChange(name, formValues[name]),
    [formValues, onChange]
  );

  const phoneType = ['Home', 'Cell', 'Work', 'Fax', 'Other'];
  const sentStatus = getQueryString(window.location.href, 'isSent') == 'true';
  const duplicateValues = JSON.parse(
    localStorage.getItem('INSPECTION_DUPLICATE')
  );

  useEffect(() => {
    if (
      !formValues.AccountName &&
      duplicateValues &&
      isDuplicateQueryString == 'true'
    ) {
      setFormValues({
        ...formValues,
        AccountAddress: duplicateValues.AccountAddress,
        AccountName: duplicateValues.AccountName,
        Areas: duplicateValues.Areas,
        City: duplicateValues.City,
        Contact: duplicateValues.Contact,
        ContactForm: duplicateValues.ContactForm,
        ContactPhoneExtension: duplicateValues.ContactPhoneExtension,
        ContactPhoneType: duplicateValues.ContactPhoneType,
        County: duplicateValues.County,
        CreatedAt: duplicateValues.CreatedAt,
        CreatedBy: duplicateValues.CreatedBy,
        Date: duplicateValues.Date,
        DaysCleanedFriday: duplicateValues.DaysCleanedFriday,
        DaysCleanedMonday: duplicateValues.DaysCleanedMonday,
        DaysCleanedSaturday: duplicateValues.DaysCleanedSaturday,
        DaysCleanedSunday: duplicateValues.DaysCleanedSunday,
        DaysCleanedThursday: duplicateValues.DaysCleanedThursday,
        DaysCleanedTuesday: duplicateValues.DaysCleanedTuesday,
        DaysCleanedWednesday: duplicateValues.DaysCleanedWednesday,
        DecisionMakerEmail: duplicateValues.DecisionMakerEmail,
        DecisionMakerFirstName: duplicateValues.DecisionMakerFirstName,
        DecisionMakerLastName: duplicateValues.DecisionMakerLastName,
        DecisionMakerName: duplicateValues.DecisionMakerName,
        DecisionMakerPhone: duplicateValues.DecisionMakerPhone,
        DecisionMakerPhoneExtension:
          duplicateValues.DecisionMakerPhoneExtension,
        DecisionMakerPhoneType: duplicateValues.DecisionMakerPhoneType,
        DecisionMakerTitle: duplicateValues.DecisionMakerTitle,
        ExternalId: duplicateValues.ExternalId,
        FacilityRating: duplicateValues.FacilityRating,
        FranchiseId: duplicateValues.FranchiseId,
        FranchiseOwnerName: duplicateValues.FranchiseOwnerName,
        GrandTotal: duplicateValues.GrandTotal,
        InspectedBy: duplicateValues.InspectedBy,
        InspectedByInStore: duplicateValues.InspectedByInStore,
        InspectionClassNumber: duplicateValues.InspectionClassNumber,
        InspectionReportId: duplicateValues.InspectionReportId,
        IsCityTemplate: duplicateValues.IsCityTemplate,
        IsMonthlyCleaning: duplicateValues.IsMonthlyCleaning,
        IsTemplate: duplicateValues.IsTemplate,
        MasterCity: duplicateValues.MasterCity,
        Notes: duplicateValues.Notes,
        Owner: duplicateValues.Owner,
        PID: duplicateValues.PID,
        Phone: duplicateValues.Phone,
        PobilePhone: duplicateValues.PobilePhone,
        SentStatus: false,
        SicId: duplicateValues.SicId,
        SicName: duplicateValues.SicName,
        State: duplicateValues.State,
        Suite: duplicateValues.Suite,
        Time: duplicateValues.Time,
        TimeOfDay: duplicateValues.TimeOfDay,
        TimesPerMonth: duplicateValues.TimesPerMonth,
        Token: duplicateValues.Token,
        WalkThruEmail: duplicateValues.WalkThruEmail,
        WalkThruFirstName: duplicateValues.WalkThruFirstName,
        WalkThruLastName: duplicateValues.WalkThruLastName,
        WalkThruName: duplicateValues.WalkThruName,
        WalkThruPhone: duplicateValues.WalkThruPhone,
        WalkThruPhoneExtension: duplicateValues.WalkThruPhoneExtension,
        WalkThruPhoneType: duplicateValues.WalkThruPhoneType,
        WalkThruTitle: duplicateValues.WalkThruTitle,
        WaterStreetUpdateUrl: duplicateValues.WaterStreetUpdateUrl,
        Zip: duplicateValues.Zip,
      });
    }
  }, [isDuplicateQueryString, duplicateValues, formValues.AccountAddress]);

  return (
    <div className="client-info-root">
      <div className="client-info-content">
        <ContentInput
          width="100%"
          display="flex"
          justify="space-between"
          align="center"
        >
          <div className="header-actions">
            <h3 className="client-info-title">Client Info</h3>
            <ContentInput flex gap="50px" justify="flex-end" padding="0px">
              {formValues.isSpecialty == 1 && (
                <ButtonGroup
                  disabled={
                    sentStatus ||
                    isPreview ||
                    (formValues.isTemplate && formValues.isUserRole)
                  }
                  tabIndexable={false}
                  options={[
                    { value: true, label: 'Existing Client' },
                    { value: false, label: 'New Client' },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  // multiple={false}
                  type="oneOption"
                  name="existentClient"
                  onChange={(name, item) => onChange(name, item)}
                  value={!!formValues.existentClient}
                  buttonsStyle={{ width: '140px', padding: '10px' }}
                />
              )}
            </ContentInput>
          </div>
        </ContentInput>
        <div className="client-info-content">
          <ContentInput width="100%">
            <InputFloatLabel
              value={formValues.AccountName}
              hasCounter
              maxLength={75}
              name="AccountName"
              label="Location Name"
              className="validate-company-name"
              onChange={onChange}
              onBlur={onBlur('AccountName')}
              capitalizeFunction={(v) =>
                // capitalizeInput(v, true)
                v
              }
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="52%" padding>
            <InputFloatLabel
              value={formValues.AccountAddress}
              name="AccountAddress"
              label="Address"
              onChange={onChange}
              onBlur={onBlur('address')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="45%">
            <InputFloatLabel
              value={formValues.Suite}
              name="Suite"
              label="Address 2"
              onChange={onChange}
              onBlur={onBlur('Suite')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="31%" padding>
            <InputFloatLabel
              value={formValues.City}
              name="City"
              label="City"
              onChange={onChange}
              onBlur={onBlur('City')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="30%" padding>
            <InputFloatLabel
              value={formValues.State}
              name="State"
              label="State / Province"
              onChange={onChange}
              onBlur={onBlur('State')}
              mask={stateMask}
              capitalizeFunction={(value: string) => value.toUpperCase()}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="33%">
            <InputFloatLabel
              value={formValues.Zip}
              name="Zip"
              className="validate-postal-code"
              label="Postal Code"
              onChange={(name: string, value: any) =>
                onChange(name, value ? value.toUpperCase() : value)
              }
              onBlur={onBlur('Zip')}
              mask={postalCodeMask}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="40%" padding>
            <InputFloatLabel
              value={formValues.Phone}
              name="Phone"
              type="tel"
              label="Office Phone"
              className="validate-phone"
              onChange={onChange}
              onBlur={onBlur('Phone')}
              mask={phoneMask}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="12%" padding>
            <InputFloatLabel
              value={formValues.ContactPhoneExtension}
              name="ContactPhoneExtension"
              className="validate-postal-code"
              label="Extension"
              onChange={onChange}
              onBlur={onBlur('ContactPhoneExtension')}
              maxLength={10}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>
          <ContentInput width="42%">
            <InputFloatLabel
              value={formValues.County}
              name="County"
              label="County"
              onChange={onChange}
              onBlur={onBlur('County')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
              maxLength={7}
            />
          </ContentInput>
        </div>
        <ContentInput width="100%">
          <h3 className="client-info-title">Contact Info</h3>
        </ContentInput>
        <div className="client-info-content" style={{ paddingRight: 0 }}>
          <ContentInput width="22%" padding>
            <InputFloatLabel
              value={formValues.DecisionMakerFirstName}
              name="DecisionMakerFirstName"
              label="Decision Maker First Name"
              onChange={onChange}
              onBlur={onBlur('DecisionMakerFirstName')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="22%" padding>
            <InputFloatLabel
              value={formValues.DecisionMakerLastName}
              name="DecisionMakerLastName"
              label="Decision Maker Last Name"
              onChange={onChange}
              onBlur={onBlur('DecisionMakerLastName')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="22%" padding>
            <InputFloatLabel
              value={formValues.WalkThruFirstName}
              name="WalkThruFirstName"
              label="Walk-thru First Name"
              onChange={onChange}
              onBlur={onBlur('WalkThruFirstName')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="22%" padding>
            <InputFloatLabel
              value={formValues.WalkThruLastName}
              name="WalkThruLastName"
              label="Walk-thru Last Name"
              onChange={onChange}
              onBlur={onBlur('WalkThruLastName')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="47%" padding>
            <InputFloatLabel
              value={formValues.DecisionMakerTitle}
              name="DecisionMakerTitle"
              label="Title"
              onChange={onChange}
              onBlur={onBlur('DecisionMakerTitle')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="47%">
            <InputFloatLabel
              value={formValues.WalkThruTitle}
              name="WalkThruTitle"
              label="Title"
              onChange={onChange}
              onBlur={onBlur('WalkThruTitle')}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="47%" padding>
            <InputFloatLabel
              value={formValues.DecisionMakerEmail}
              type="email"
              name="DecisionMakerEmail"
              label="Email"
              className="validate-decision-maker-email"
              onChange={onChange}
              onBlur={onBlur('DecisionMakerEmail')}
              capitalizeFunction={(item: string) =>
                // item.charAt(0).toUpperCase() + item.slice(1)
                item
              }
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput width="47%">
            <InputFloatLabel
              value={formValues.WalkThruEmail}
              type="email"
              name="WalkThruEmail"
              label="Email"
              className="validate-walkthru-maker-email"
              onChange={onChange}
              onBlur={onBlur('WalkThruEmail')}
              capitalizeFunction={(item: string) =>
                //item.charAt(0).toUpperCase() + item.slice(1)
                item
              }
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput
            width="15%"
            position="relative"
            paddingTop="22px"
            flex
            align="center"
            padding="0 10px 0 0"
          >
            <FormLabelSelect>Phone Type</FormLabelSelect>
            <Select
              name="DecisionMakerPhoneType"
              value={phoneType[parseInt(formValues.DecisionMakerPhoneType) - 1]}
              onChange={(name, value) => {
                onChange(name, phoneType.indexOf(value) + 1);
              }}
              items={phoneType}
              disableInput={true}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>
          <ContentInput width="25%" padding="15px 10px 0 0">
            <InputFloatLabel
              autoCapitalize={isIpad() ? 'none' : null}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
              value={formValues.DecisionMakerPhone}
              name="DecisionMakerPhone"
              className="validate-phone-decision"
              label="Phone"
              type="tel"
              style={{ width: '100%' }}
              onChange={onChange}
              onBlur={onBlur('DecisionMakerPhone')}
              mask={phoneMask}
            />
          </ContentInput>

          <ContentInput width="12%" padding>
            <InputFloatLabel
              value={formValues.DecisionMakerPhoneExtension}
              name="DecisionMakerPhoneExtension"
              className="validate-postal-code"
              label="Extension"
              onChange={onChange}
              onBlur={onBlur('DecisionMakerPhoneExtension')}
              // mask={postalCodeMask}
              maxLength={10}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>

          <ContentInput
            width="15%"
            position="relative"
            paddingTop="22px"
            flex
            align="center"
            padding="0 10px 0 0"
          >
            <FormLabelSelect>Phone Type</FormLabelSelect>
            <Select
              name="WalkThruPhoneType"
              value={phoneType[parseInt(formValues.WalkThruPhoneType) - 1]}
              onChange={(name, value) => {
                onChange(name, phoneType.indexOf(value) + 1);
              }}
              items={phoneType}
              disableInput={true}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>
          <ContentInput width="25%" padding="15px 10px 0 0">
            <InputFloatLabel
              autoCapitalize={isIpad() ? 'none' : null}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
              value={formValues.WalkThruPhone}
              name="WalkThruPhone"
              className="validate-phone-walk"
              label="Phone"
              type="tel"
              style={{ width: '100%' }}
              onChange={onChange}
              onBlur={onBlur('WalkThruPhone')}
              mask={phoneMask}
            />
          </ContentInput>

          <ContentInput width="12%" padding>
            <InputFloatLabel
              value={formValues.WalkThruPhoneExtension}
              name="WalkThruPhoneExtension"
              className="validate-postal-code"
              label="Extension"
              onChange={onChange}
              onBlur={onBlur('WalkThruPhoneExtension')}
              // mask={postalCodeMask}
              maxLength={10}
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
          </ContentInput>
        </div>
      </div>
    </div>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...clientInfoValuesInspect,
  }),
  handleSubmit: () => '',
};

const EnhancedClient = withFormik<any, IBidClientInfo>(mapForm)(
  ClientInfoInspect
);

export { EnhancedClient as ClientInfoInspect };
