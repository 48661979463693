import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Layout,
  ResponsiveIpadLayout,
  RoundSubSection,
  SubSectionHeader,
  GraySection,
  WhiteSection,
} from '../../../components/layout';
import { Paragraph } from '../../../components/atoms';
import {
  InputFloatLabel,
  ContentInput,
  Checkbox,
  RadioButton,
  DatePicker,
  Select,
  Chevron,
} from 'core/components';

import { phoneMask, postalCodeMask, stateMask } from 'core/utils';

import {
  SectionTitle,
  SubSectionTitle,
  FormLabelSelect,
} from '../../../styles';
import calendar from '../../../assets/calendar.png';

import { isIpad } from 'core/utils';

const daysArr = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const phoneType = ['Home', 'Cell', 'Work', 'Fax', 'Other'];

export default React.forwardRef((props, ref) => {
  const values = props.data || [];
  const isMonthlyCleaning = props.data ? props.data.IsMonthlyCleaning : false;

  const selectedDays =
    !isMonthlyCleaning && props.data
      ? (values['SmartClean']
          ? values['SelectedDaysCleanedListText']
          : !props.selectedValues.page1.selectedFrequency
          ? values['DaysCleanedListText']
          : values['SecondDaysCleanedListText']
        )
          .split(', ')
          .map((x) => daysArr.indexOf(x))
      : [];

  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] =
    React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);
  const [minorThan400, setMinorThan400] = React.useState(false);

  React.useEffect(() => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
    setMinorThan450(window.innerWidth < 400);
  }, [window.innerWidth]);

  const [checkboxStates, setCheckboxStates] = React.useState({});
  const [dirtySections, setDirtySections] = React.useState([]);
  const [formState, setFormState] = React.useState({});

  const setStateData = (values: any) => {
    setFormState((prevState) => ({
      ...prevState,
      ...(values || {}),
    }));
  };

  const onChange = (section) => (name: string, value: string) => {
    if (section) {
      setDirtySections((prevState) => [...prevState, section]);
    }
    setStateData({
      [name]: value,
    });
  };

  const [serviceCommencementDate, setServiceCommencementDate] =
    React.useState();

  useEffect(() => {
    setCheckboxStates({
      billingAddressSameAsAbove: true,
      sameAsBilling: true,
      sameAsAccounts: true,
    });
  }, []);

  const onChangeCheckBoxState = (name, value) => {
    const proceed = () => {
      setCheckboxStates((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setDirtySections((prevState) => prevState.filter((i) => i != name));
    };

    if (value && dirtySections.includes(name)) {
      props.setAlertModal({
        message: (
          <>
            <strong>Warning:</strong> Proceed with this action will cause all
            information you entered to be reseted!
          </>
        ),
        proceed: () => {
          proceed();
          props.setAlertModal({});
        },
        dismiss: () => props.setAlertModal({}),
      });
    } else {
      proceed();
    }
  };

  const { billingAddressSameAsAbove, sameAsBilling, sameAsAccounts }: any =
    checkboxStates;

  const setBillingInfo = (billingAddressSameAsAbove) => {
    if (!billingAddressSameAsAbove) {
      return;
    }
    setStateData({
      billingAddress1: values['Address'],
      billingSuite: values['Suite'],
      billingCity: values['City'],
      billingState: values['State'],
      billingPostalCode: values['Zip'],
      billingEmail: values['AddressedToWalkThru']
        ? values['EmailAddress']
        : values['DecisionMakerEmail'],
    });
  };

  useEffect(() => {
    setBillingInfo(billingAddressSameAsAbove);
  }, [billingAddressSameAsAbove, values]);
  const setSameAsBilling = (sameAsBilling) => {
    if (!sameAsBilling) {
      return;
    }

    setStateData({
      accountsEmail: values['AddressedToWalkThru']
        ? values['WalkThruEmail']
        : values['DecisionMakerEmail'],
      accountsFirstName: values['AddressedToWalkThru']
        ? values['WalkThruFirstName']
        : values['DecisionMakerFirstName'],
      accountsLastName: values['AddressedToWalkThru']
        ? values['WalkThruLastName']
        : values['DecisionMakerLastName'],
      accountsPhoneType: values['AddressedToWalkThru']
        ? phoneType[parseInt(values['WalkThruPhoneType']) - 1]
        : phoneType[parseInt(values['DecisionMakerPhoneType']) - 1],
      accountsPhoneExtension: values['AddressedToWalkThru']
        ? values['WalkThruPhoneExtension']
        : values['DecisionMakerPhoneExtension'],
      accountsPhone: values['AddressedToWalkThru']
        ? values['WalkThruPhone']
        : values['DecisionMakerPhone'],
    });
  };

  useEffect(() => {
    setSameAsBilling(sameAsBilling);
  }, [sameAsBilling, values]);

  const setAccountsInfo = (sameAsAccounts) => {
    if (!sameAsAccounts) {
      return;
    }

    setStateData({
      emergencyFirstName: values['AddressedToWalkThru']
        ? values['WalkThruFirstName']
        : values['DecisionMakerFirstName'],
      emergencyLastName: values['AddressedToWalkThru']
        ? values['WalkThruLastName']
        : values['DecisionMakerLastName'],
      emergencyEmail: values['AddressedToWalkThru']
        ? values['WalkThruEmail']
        : values['DecisionMakerEmail'],
      emergencyPhoneType: values['AddressedToWalkThru']
        ? phoneType[parseInt(values['WalkThruPhoneType']) - 1]
        : phoneType[parseInt(values['DecisionMakerPhoneType']) - 1],
      emergencyPhone: values['AddressedToWalkThru']
        ? values['WalkThruPhone']
        : values['DecisionMakerPhone'],
      emergencyPhoneExtension: values['AddressedToWalkThru']
        ? values['WalkThruPhoneExtension']
        : values['DecisionMakerPhoneExtension'],
    });
  };
  useEffect(() => {
    setAccountsInfo(sameAsAccounts);
  }, [sameAsAccounts, values]);

  const reset = () => {
    setFormState({});
    setBillingInfo(true);
    setSameAsBilling(true);
    setAccountsInfo(true);
    setDirtySections([]);
    setServiceCommencementDate(undefined);
  };

  React.useImperativeHandle(ref, () => ({
    reset,
    getData: () => ({ ...formState, serviceCommencementDate }),
  }));

  React.useEffect(() => {
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      (el.childNodes[0] as HTMLElement).setAttribute('readOnly', 'true')
    );
  }, []);
  const containsPreviousDays = (date: Date) => {
    return date >= new Date();
  };
  const containsTheDaysCleaned = (date: Date) => {
    const day = date.getDay();
    const found = selectedDays.filter((x) => x === day).length > 0;
    return found;
  };

  console.log(formState);

  return (
    <Layout
      margin="auto"
      marginTop="-10px"
      width="100%"
      backgroundColor="#ededed"
    >
      <Layout
        margin="auto"
        width="100%"
        maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
      >
        <GraySection width="auto" margin="0" padding="30px 20px 150px 20px">
          <RoundSubSection
            paddingBottom="30px"
            width="100%"
            backgroundColor="#fff"
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#216eab"
              color="#fff"
              padding="20px 0 20px 0"
            >
              <SectionTitle
                maxFontSize="20px"
                paddingLeft="20px !important"
                color="#fff"
                data-cy="billing-information-title"
              >
                Billing Information
              </SectionTitle>
            </SubSectionHeader>
            <Layout width="100%" backgroundColor="#fff">
              <SubSectionTitle
                maxFontSize="20px"
                textAlign={'left'}
                paddingLeft="0px"
                margin="20px"
                data-cy="billing-address-title"
              >
                Billing Address
              </SubSectionTitle>
              <Layout
                flexDirection="row"
                justifyContent={'flex-start'}
                width="100%"
                paddingLeft={'20px'}
                paddingBottom="15px"
              >
                <Checkbox
                  borderColor="gray"
                  name="billingAddressSameAsAbove"
                  dataCy="billing-address-same-as-above"
                  value={1}
                  checked={checkboxStates.billingAddressSameAsAbove || false}
                  onChange={(...args) => {
                    onChangeCheckBoxState(args[0], args[2]);
                  }}
                />
                <ContentInput padding="5px 0 0 0">Same as above</ContentInput>
              </Layout>
              <Layout
                backgroundColor="#fff"
                style={{
                  opacity: checkboxStates.billingAddressSameAsAbove ? 0.8 : 1,
                  pointerEvents: checkboxStates.billingAddressSameAsAbove
                    ? 'none'
                    : 'auto',
                }}
                width="100%"
              >
                <ResponsiveIpadLayout
                  width="auto"
                  marginLeft="20px"
                  marginRight="20px"
                >
                  <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                    <InputFloatLabel
                      disabled={checkboxStates.billingAddressSameAsAbove}
                      value={formState.billingAddress1}
                      name="billingAddress1"
                      label="Address 1"
                      autoCapitalize={isIpad() ? 'none' : null}
                      style={{ width: '100%' }}
                      onChange={onChange('billingAddressSameAsAbove')}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                  <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                    <InputFloatLabel
                      disabled={checkboxStates.billingAddressSameAsAbove}
                      value={formState.billingSuite}
                      autoCapitalize={isIpad() ? 'none' : null}
                      name="billingSuite"
                      label="Address 2"
                      style={{ width: '100%' }}
                      onChange={onChange('billingAddressSameAsAbove')}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                </ResponsiveIpadLayout>
                <ResponsiveIpadLayout
                  width="auto"
                  marginLeft="20px"
                  marginRight="20px"
                >
                  <ContentInput width={biggerThan768 ? '31%' : '100%'}>
                    <InputFloatLabel
                      disabled={checkboxStates.billingAddressSameAsAbove}
                      value={formState.billingCity}
                      autoCapitalize={isIpad() ? 'none' : null}
                      name="billingCity"
                      label="City"
                      style={{ width: '100%' }}
                      onChange={onChange('billingAddressSameAsAbove')}
                      onBlur={() => {}}
                    />
                  </ContentInput>

                  <ContentInput width={biggerThan768 ? '31%' : '100%'}>
                    <InputFloatLabel
                      disabled={checkboxStates.billingAddressSameAsAbove}
                      value={formState.billingState}
                      name="billingState"
                      autoCapitalize={isIpad() ? 'none' : null}
                      label="State or Province"
                      style={{ width: '100%' }}
                      onChange={onChange('billingAddressSameAsAbove')}
                      onBlur={() => {}}
                      mask={stateMask}
                      capitalizeFunction={(value: string) =>
                        value.toUpperCase()
                      }
                    />
                  </ContentInput>

                  <ContentInput width={biggerThan768 ? '31%' : '100%'}>
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      disabled={checkboxStates.billingAddressSameAsAbove}
                      value={formState.billingPostalCode}
                      className="validate-billing-postal-code"
                      name="billingPostalCode"
                      label="Postal Code"
                      type="tel"
                      style={{ width: '100%' }}
                      onChange={onChange('billingAddressSameAsAbove')}
                      mask={postalCodeMask}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                </ResponsiveIpadLayout>
                {/* <ContentInput width="auto" margin="0 20px 0 20px">
                  <InputFloatLabel
                    autoCapitalize={isIpad() ? 'none' : null}
                    disabled={checkboxStates.billingAddressSameAsAbove}
                    value={formState.billingEmail}
                    name="billingEmail"
                    className="validate-billing-email"
                    label="Email"
                    style={{ width: '100%' }}
                    onChange={onChange('billingAddressSameAsAbove')}
                    onBlur={() => {}}
                    capitalizeFunction={(item: string) =>
                      // item.charAt(0).toUpperCase() + item.slice(1)
                      item
                    }
                  />
                </ContentInput> */}
              </Layout>
              <Layout backgroundColor="#fff" width="100%">
                <SubSectionTitle
                  maxFontSize="22px"
                  padding="20px"
                  paddingLeft="0"
                  textAlign={'left'}
                  data-cy="accounts-payable-title"
                >
                  Accounts Payable Contact
                </SubSectionTitle>
                <Layout
                  flexDirection="row"
                  justifyContent={'flex-start'}
                  width="100%"
                  paddingLeft={'20px'}
                  paddingBottom="15px"
                >
                  <Checkbox
                    dataCy="accounts-payable-same-as-above"
                    borderColor="gray"
                    name="sameAsBilling"
                    checked={checkboxStates.sameAsBilling}
                    onChange={(...args) => {
                      onChangeCheckBoxState(args[0], args[2]);
                    }}
                  />
                  <ContentInput padding="5px 0 0 0">Same as above</ContentInput>
                </Layout>
                <Layout
                  backgroundColor="#fff"
                  width="100%"
                  style={{
                    opacity: checkboxStates.sameAsBilling ? 0.8 : 1,
                    pointerEvents: checkboxStates.sameAsBilling
                      ? 'none'
                      : 'auto',
                  }}
                >
                  <ResponsiveIpadLayout
                    width="auto"
                    marginLeft="20px"
                    marginRight="20px"
                  >
                    <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                      <InputFloatLabel
                        autoCapitalize={isIpad() ? 'none' : null}
                        disabled={checkboxStates.sameAsBilling}
                        value={formState.accountsFirstName}
                        name="accountsFirstName"
                        label="First Name"
                        style={{ width: '100%' }}
                        onChange={onChange('sameAsBilling')}
                        onBlur={() => {}}
                      />
                    </ContentInput>
                    <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                      <InputFloatLabel
                        autoCapitalize={isIpad() ? 'none' : null}
                        disabled={checkboxStates.sameAsBilling}
                        value={formState.accountsLastName}
                        name="accountsLastName"
                        label="Last Name"
                        style={{ width: '100%' }}
                        onChange={onChange('sameAsBilling')}
                        onBlur={() => {}}
                      />
                    </ContentInput>
                  </ResponsiveIpadLayout>
                  <ResponsiveIpadLayout>
                    <ContentInput width="46%" padding>
                      <InputFloatLabel
                        autoCapitalize={isIpad() ? 'none' : null}
                        disabled={checkboxStates.sameAsBilling}
                        value={formState.accountsEmail}
                        className="validate-accounts-email"
                        name="accountsEmail"
                        label="Email"
                        style={{
                          width: '100%',
                          marginLeft: '20px',
                          marginRight: '20px',
                        }}
                        onChange={onChange('sameAsBilling')}
                        onBlur={() => {}}
                      />
                    </ContentInput>
                    <ContentInput
                      width="13%"
                      position="relative"
                      paddingTop="10px"
                    >
                      <FormLabelSelect>Phone Type</FormLabelSelect>
                      <Select
                        name="accountsPhoneType"
                        value={formState.accountsPhoneType}
                        onChange={onChange('sameAsBilling')}
                        items={phoneType}
                        // width="300px"
                        disableInput={true}
                        disabled={checkboxStates.sameAsBilling}
                      />
                    </ContentInput>
                    <ContentInput width="15%">
                      <InputFloatLabel
                        autoCapitalize={isIpad() ? 'none' : null}
                        disabled={checkboxStates.sameAsBilling}
                        value={formState.accountsPhone}
                        name="accountsPhone"
                        className="validate-accounts-phone"
                        label="Phone"
                        type="tel"
                        style={{ width: '100%' }}
                        onChange={onChange('sameAsBilling')}
                        onBlur={() => {}}
                        mask={phoneMask}
                      />
                    </ContentInput>
                    <ContentInput width="12%" padding>
                      <InputFloatLabel
                        autoCapitalize={isIpad() ? 'none' : null}
                        disabled={checkboxStates.sameAsBilling}
                        value={formState.accountsPhoneExtension}
                        className="validate-accounts-email"
                        name="accountsPhoneExtension"
                        label="Extension"
                        maxLength={10}
                        style={{
                          width: '100%',
                          marginRight: '20px',
                        }}
                        onChange={onChange('sameAsBilling')}
                        onBlur={() => {}}
                      />
                    </ContentInput>
                  </ResponsiveIpadLayout>
                </Layout>
              </Layout>
            </Layout>
          </RoundSubSection>
          <ContentInput
            padding="0"
            margin="30px 0 0 0"
            width="100%"
            flex
            flexDirection={equalsOrBiggerThan768 ? 'row' : 'column'}
          >
            <RoundSubSection
              paddingBottom="15px"
              width={equalsOrBiggerThan768 ? '50%' : '100%'}
              backgroundColor="#fff"
              marginRight="20px !important"
            >
              <SubSectionHeader
                alignItems={biggerThan768 ? 'start' : 'center'}
                backgroundColor="#216eab"
                color="#fff"
                padding={
                  equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
                }
              >
                <SectionTitle
                  maxFontSize="20px"
                  color="#fff"
                  paddingLeft="20px !important"
                  data-cy="emergency-contact -title"
                >
                  Emergency Contact
                </SectionTitle>
              </SubSectionHeader>
              <Layout backgroundColor="#fff" width="100%">
                <Layout
                  flexDirection="row"
                  justifyContent={'flex-start'}
                  width="100%"
                  paddingLeft={'20px'}
                  paddingTop="20px"
                  paddingBottom="15px"
                >
                  <Checkbox
                    dataCy="emergency-contact-same-as-above"
                    borderColor="gray"
                    name="sameAsAccounts"
                    checked={checkboxStates.sameAsAccounts}
                    onChange={(...args) => {
                      onChangeCheckBoxState(args[0], args[2]);
                    }}
                  />
                  <ContentInput padding="5px 0 0 0">Same as above</ContentInput>
                </Layout>
                <ResponsiveIpadLayout
                  width="auto"
                  marginLeft="20px"
                  marginRight="20px"
                  style={{
                    opacity: checkboxStates.sameAsAccounts ? 0.8 : 1,
                    pointerEvents: checkboxStates.sameAsAccounts
                      ? 'none'
                      : 'auto',
                  }}
                >
                  <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      disabled={checkboxStates.sameAsAccounts}
                      value={formState.emergencyFirstName}
                      name="emergencyFirstName"
                      label="First Name"
                      style={{ width: '100%' }}
                      onChange={onChange('sameAsAccounts')}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                  <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      disabled={checkboxStates.sameAsAccounts}
                      value={formState.emergencyLastName}
                      name="emergencyLastName"
                      label="Last Name"
                      style={{ width: '100%' }}
                      onChange={onChange('sameAsAccounts')}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                </ResponsiveIpadLayout>
                <ResponsiveIpadLayout
                  width="auto"
                  marginLeft="20px"
                  marginRight="20px"
                  style={{
                    opacity: checkboxStates.sameAsAccounts ? 0.8 : 1,
                    pointerEvents: checkboxStates.sameAsAccounts
                      ? 'none'
                      : 'auto',
                  }}
                >
                  <ContentInput
                    width="30%"
                    position="relative"
                    paddingTop="4px"
                  >
                    <FormLabelSelect top="-20px">Phone Type</FormLabelSelect>
                    <Select
                      name="emergencyPhoneType"
                      value={formState.emergencyPhoneType}
                      onChange={onChange('sameAsAccounts')}
                      items={phoneType}
                      disableInput={true}
                      disabled={checkboxStates.sameAsAccounts}
                    />
                  </ContentInput>
                  <ContentInput width="40%">
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      disabled={checkboxStates.sameAsAccounts}
                      value={formState.emergencyPhone}
                      name="emergencyPhone"
                      className="validate-emergency-phone"
                      label="Phone"
                      type="tel"
                      style={{ width: '100%' }}
                      onChange={onChange('sameAsAccounts')}
                      onBlur={() => {}}
                      mask={phoneMask}
                    />
                  </ContentInput>
                  <ContentInput width="26%">
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      disabled={checkboxStates.sameAsAccounts}
                      value={formState.emergencyPhoneExtension}
                      name="emergencyPhoneExtension"
                      className="validate-emergency-phone"
                      maxLength={10}
                      label="Extension"
                      type="tel"
                      style={{ width: '100%' }}
                      onChange={onChange('sameAsAccounts')}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                </ResponsiveIpadLayout>
                <ResponsiveIpadLayout
                  width="auto"
                  marginLeft="20px"
                  marginRight="20px"
                  style={{
                    opacity: checkboxStates.sameAsAccounts ? 0.8 : 1,
                    pointerEvents: checkboxStates.sameAsAccounts
                      ? 'none'
                      : 'auto',
                  }}
                >
                  <ContentInput width="100%">
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      disabled={checkboxStates.sameAsAccounts}
                      value={formState.emergencyEmail}
                      className="validate-emergency-email"
                      name="emergencyEmail"
                      label="Email"
                      style={{
                        width: '100%',
                      }}
                      onChange={onChange('sameAsAccounts')}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                </ResponsiveIpadLayout>
              </Layout>
            </RoundSubSection>
            <RoundSubSection
              paddingBottom="15px"
              width={equalsOrBiggerThan768 ? '50%' : '100%'}
              backgroundColor="#fff"
              marginTop={equalsOrBiggerThan768 ? null : '30px'}
            >
              <SubSectionHeader
                alignItems={biggerThan768 ? 'start' : 'center'}
                backgroundColor="#216eab"
                color="#fff"
                padding={
                  equalsOrBiggerThan768 ? '15px 0 15px 0' : '20px 0 20px 0'
                }
              >
                <SectionTitle
                  maxFontSize="20px"
                  color="#fff"
                  paddingLeft="20px !important"
                >
                  Tax-Exemption
                </SectionTitle>
              </SubSectionHeader>
              <Layout
                backgroundColor="#fff"
                width="100%"
                display={equalsOrBiggerThan768 ? 'flex' : null}
                flexDirection={equalsOrBiggerThan768 ? 'row' : 'column'}
              >
                <ResponsiveIpadLayout
                  width={equalsOrBiggerThan768 ? '30%' : 'auto'}
                  marginLeft="20px"
                  marginRight={equalsOrBiggerThan768 ? '0' : '20px'}
                  marginTop="20px"
                >
                  {/* <ContentInput width={biggerThan768 ? '48%' : '100%'}>
                  <SectionTitle
                    maxFontSize="22px"
                    textAlign={biggerThan768 ? 'left' : 'center'}
                  >
                    Is this Facility Tax-Exempt?
                  </SectionTitle>
                </ContentInput> */}
                  <ContentInput
                    data-cy="taxFacilityExempt"
                    width={biggerThan768 ? '48%' : '100%'}
                    justify={biggerThan768 ? 'left' : 'center'}
                    paddingTop={equalsOrBiggerThan768 ? '0px' : null}
                  >
                    <RadioButton
                      value={formState.taxFacilityExempt}
                      onChange={onChange()}
                      containerStyle={{ width: minorThan400 ? '80%' : '30%' }}
                      itemStyle={
                        equalsOrBiggerThan768
                          ? {
                              paddingTop: '10px',
                            }
                          : {}
                      }
                      contentStyle={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: '18px',
                        flexDirection: equalsOrBiggerThan768 ? 'column' : null,
                      }}
                      name="taxFacilityExempt"
                      label=""
                      options={[
                        { value: false, label: 'No' },
                        { value: true, label: 'Yes' },
                      ]}
                      optionLabel="label"
                      optionValue="value"
                    />
                  </ContentInput>
                </ResponsiveIpadLayout>
                <ResponsiveIpadLayout
                  width={equalsOrBiggerThan768 ? '70%' : 'auto'}
                  marginLeft="20px"
                  marginRight="20px"
                  display={equalsOrBiggerThan768 ? 'flex' : null}
                  paddingTop={equalsOrBiggerThan768 ? '27px' : null}
                  flexDirection={
                    equalsOrBiggerThan768 ? 'column !important' : null
                  }
                >
                  <ContentInput
                    paddingTop="20px"
                    width={biggerThan768 ? '100%' : '100%'}
                    justify={biggerThan768 ? 'left' : 'center'}
                  >
                    <InputFloatLabel
                      autoCapitalize={isIpad() ? 'none' : null}
                      value={formState.taxExemptionNumber}
                      type="tel"
                      name="taxExemptionNumber"
                      label=" "
                      center
                      style={{ width: '100%', textAlign: 'center' }}
                      onChange={onChange()}
                      onBlur={() => {}}
                    />
                  </ContentInput>
                  <ContentInput
                    paddingTop="0"
                    marginTop="-15px"
                    justify={biggerThan768 ? 'left' : 'center'}
                    width={biggerThan768 ? 'auto' : '100%'}
                  >
                    <Paragraph
                      fontSize={equalsOrBiggerThan768 ? '10px' : '12px'}
                      maxWidth={minorThan400 ? '150px' : '100%'}
                      minWidth={biggerThan768 ? '230px' : '0'}
                      textAlign={biggerThan768 ? 'left' : 'center'}
                      paddingTop="0"
                      marginTop="-15px"
                    >
                      (If Yes) Please enter your Tax-Exemption number
                    </Paragraph>
                  </ContentInput>
                </ResponsiveIpadLayout>
              </Layout>
            </RoundSubSection>
          </ContentInput>

          {!values['IsSS'] &&
            props.selectedValues.page1.selectedFrequency != -1 &&
            props.selectedValues.page2.selectedFrequency != -1 && (
              <RoundSubSection
                marginTop="30px !important"
                marginBottom="30px !important"
                width="100%"
                backgroundColor="#fff"
                paddingBottom="15px"
              >
                <SubSectionHeader
                  alignItems={biggerThan768 ? 'start' : 'center'}
                  backgroundColor="#216eab"
                  color="#fff"
                  padding="20px 0 20px 0"
                >
                  <SectionTitle
                    maxFontSize="20px"
                    color="#fff"
                    paddingLeft={
                      equalsOrBiggerThan768 ? '20px !important' : null
                    }
                  >
                    Service Commencement Date
                  </SectionTitle>
                </SubSectionHeader>
                <Layout
                  backgroundColor="#fff"
                  width="100%"
                  display={equalsOrBiggerThan768 ? 'flex' : null}
                  flexDirection={equalsOrBiggerThan768 ? 'row' : 'column'}
                >
                  <ContentInput width={equalsOrBiggerThan768 ? '40%' : '100%'}>
                    <SubSectionTitle
                      maxFontSize="16px"
                      padding="20px"
                      marginTop="3px"
                      paddingLeft="0"
                      textAlign={'left'}
                      dangerouslySetInnerHTML={{
                        __html:
                          'Please enter the date you <br />would like service to begin',
                      }}
                    />
                  </ContentInput>
                  <ContentInput
                    width={equalsOrBiggerThan768 ? '60%' : '100%'}
                    position="relative"
                    data-cy="serviceCommencementDate"
                    flex
                    flexDirection={'column'}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div
                      className="proposal-date-picker"
                      style={{
                        display: 'flex',
                        flexDirection: equalsOrBiggerThan768 ? 'row' : 'column',
                        width: '100%',
                      }}
                    >
                      <div
                        onClick={() => {
                          document
                            .getElementsByClassName(
                              'input-proposal-datepicker'
                            )[0]
                            .focus();
                        }}
                        style={{
                          position: 'relative',
                          textAlign: 'center',
                          width: equalsOrBiggerThan768 ? '30%' : 'auto',
                        }}
                      >
                        <div
                          style={{
                            padding: '5px',
                            paddingLeft: '20px',
                            marginRight: '35px',
                          }}
                        >
                          <img src={calendar} width="40" height="45" />
                        </div>
                        <Chevron
                          width={15}
                          fill={'#777'}
                          style={{
                            transform: 'rotate(90deg)',
                            position: 'absolute',
                            marginLeft: '30px',
                            marginTop: '20px',
                            top: 0,
                            zIndex: 1,
                          }}
                        />
                      </div>
                      <DatePicker
                        onChange={(date) => setServiceCommencementDate(date)}
                        onBlur={() => {}}
                        minDate={new Date()}
                        filterDate={
                          isMonthlyCleaning
                            ? containsPreviousDays
                            : containsTheDaysCleaned
                        }
                        className={`input-proposal-datepicker input-proposal-full-width`}
                        selected={serviceCommencementDate}
                        dateFormat="EEEE, MMMM dd, yyyy"
                        style={{
                          border: 'none',
                          textAlign: 'center',
                          width: equalsOrBiggerThan768 ? '66%' : 'auto',
                        }}
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                        }}
                      />
                    </div>
                    {(isMonthlyCleaning ||
                      (!isMonthlyCleaning && selectedDays.length === 7)) && (
                      <Paragraph
                        fontSize="14px"
                        textAlign={biggerThan768 ? 'left' : 'center'}
                        padding="20px"
                        paddingBottom="0"
                        style={{
                          fontStyle: 'italic',
                          padding: '0',
                          margin: '0',
                          width: '70%',
                          textAlign: 'center',
                          marginLeft: equalsOrBiggerThan768 ? '110px' : '0',
                          marginBottom: '20px',
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            'Please ensure the date you select falls on one of the Days Serviced.',
                        }}
                      />
                    )}
                  </ContentInput>
                </Layout>
              </RoundSubSection>
            )}
        </GraySection>
      </Layout>
    </Layout>
  );
});
