import React, { useState, useEffect, Children } from 'react';
import { Header } from '../organisms';
import { Layout } from '../layout';
import { GenericAccordion } from '../../../../core/components';
import { AccordionHeader } from '../../styles';

import {
  BackButton,
  NextButton,
  StepsNumber,
  StepsSeparator,
  StepperBody,
  StepsHolder,
  StepsButtonPlaceholder,
} from '../atoms';
import { useMediaQuery } from 'react-responsive';

const dateFormatZeroPad = (date) => date.toString().padStart(2, 0);

const Stepper = (props: any) => {
  const { length, selectedItem, onSelectItem, date, expirationDays } = props;
  const validThroughDate = new Date(date.valueOf());
  validThroughDate.setDate(validThroughDate.getDate() + expirationDays);

  const [steps, setSteps] = useState([]);
  const biggerThan768 = window.innerWidth >= 769;

  useEffect(() => {
    var items = [];
    for (let i = 0; i < props.length; i++) {
      items.push(
        <StepsNumber key={`step-${i}`} selected={props.selectedItem === i}>
          {i + 1}
        </StepsNumber>
      );

      items.push(<StepsSeparator key={`step-hr-${i}`} />);
    }

    items.splice(-1, 1);
    setSteps(items);
  }, [length, selectedItem]);

  return (
    <div className="pagination-footer-proposal">
      <StepperBody>
        <StepsButtonPlaceholder>
          {selectedItem > 0 && biggerThan768 && (
            <BackButton
              onClick={() => onSelectItem(selectedItem - 1, true)}
              data-cy="back-button"
            />
          )}
        </StepsButtonPlaceholder>

        <StepsHolder data-cy="steps-wrapper">{steps}</StepsHolder>

        <StepsButtonPlaceholder>
          {biggerThan768 && selectedItem < length - 1 && (
            <NextButton
              data-cy="next-button"
              title={selectedItem == length - 1 ? 'SUBMIT' : null}
              onClick={() => onSelectItem(selectedItem + 1, false)}
            />
          )}
        </StepsButtonPlaceholder>
      </StepperBody>

      {!biggerThan768 && (
        <StepperBody>
          <StepsButtonPlaceholder>
            {selectedItem > 0 && (
              <BackButton
                onClick={() => onSelectItem(selectedItem - 1, true)}
                data-cy="back-button"
              />
            )}
          </StepsButtonPlaceholder>

          <StepsButtonPlaceholder>
            {!biggerThan768 && selectedItem < length - 1 && (
              <NextButton
                data-cy="next-button"
                title={selectedItem == length - 1 ? 'SUBMIT' : null}
                onClick={() => onSelectItem(selectedItem + 1, false)}
              />
            )}
          </StepsButtonPlaceholder>
        </StepperBody>
      )}

      <StepperBody
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          fontSize: '13px',
          color: '#000',
        }}
      >
        <span style={{ paddingLeft: '5px' }}>
          {`Last Updated: ${dateFormatZeroPad(
            date.getMonth() + 1
          )}/${dateFormatZeroPad(date.getDate())}/${date.getFullYear()}`}
        </span>
        <span style={{ paddingRight: '5px' }}>
          {`Valid through: ${dateFormatZeroPad(
            validThroughDate.getMonth() + 1
          )}/${dateFormatZeroPad(
            validThroughDate.getDate()
          )}/${validThroughDate.getFullYear()}`}
        </span>
      </StepperBody>
    </div>
  );
};

export const Template = (props) => {
  // Changed conditional rendering to toggleVisibility
  // in order to prevent component rerender and state loss
  // Changed  to use the children props directly, as the state wasn't updating the children set to it
  const children = React.Children.map(props.children, (child, index) => {
    const style =
      index === props.selectedItem
        ? {}
        : { overflow: 'hidden', width: 0, height: 0 };
    return <div style={style}>{child}</div>;
  });

  return (
    <Layout>
      <Header
        data={props.data}
        page={props.selectedItem}
        franchiseData={props.franchiseData}
        showHeaderAddress={props.showHeaderAddress}
        downloadBlankPdf={props.downloadBlankPdf}
      />
      {children}
      <Stepper
        {...props}
        date={props.data ? new Date(props.data.LastUpdate) : new Date()}
        expirationDays={
          props.data ? parseInt(props.data['ExpirationDays']) : 90
        }
        length={props.children.length}
      />
    </Layout>
  );
};

export const CollapsibleSection = (props) => (
  <GenericAccordion
    heightChanged={props.heightChanged}
    canOpenSection={props.canOpenSection}
    shouldKeepHidden={props.shouldKeepHidden}
    disabled={props.disabled}
    sectionId={props.sectionId}
    headerStyle={{
      backgroundColor: props.backgroundColor,
      borderRadius: '10px',
      padding: '10px',
    }}
    containerStyle={{
      marginTop: '15px',
      borderRadius: '10px',
      border: '1px solid silver',
      ...props.containerStyle,
    }}
    contentStyle={{
      marginTop: '-10px',
      paddingBottom: '10px',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      backgroundColor: props.backgroundColor,
    }}
    renderHeader={() => (
      <AccordionHeader
        maxFontSize="18px"
        width="100%"
        textAlign={'left'}
        textTransform="capitalize"
        color="#3f3f3f"
        style={{
          fontFamily: 'OpenSans-BoldItalic',
        }}
      >
        {props.headeTitle}
      </AccordionHeader>
    )}
    isExpandedByDefault={props.isExpandedByDefault}
  >
    {props.children}
  </GenericAccordion>
);
