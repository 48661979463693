import React from 'react';
import { SVGIcon } from 'core/components';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ButtonComponentProps {
  secondary?: boolean;
  outline?: boolean;
  icon?: any;
  rounded?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  skew?: boolean;
  loading?: boolean;
}

export const ButtonComponent: React.FC<ButtonComponentProps> = ({
  secondary,
  onClick,
  rounded = false,
  icon,
  skew = true,
  children,
  disabled,
  loading = false,
  outline,
  ...rest,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames('buttonComponent-button', {
        secondary,
        skew,
        loading,
        rounded,
        outline,
        disabled,
      })}
      {...rest}
    >
      {loading && (
        <FontAwesomeIcon
          icon={faSpinner}
          size="sm"
          className="loading-spinner"
        />
      )}
      <label
        className={`buttonComponent-label ${secondary ? 'secondary' : ''}`}
      >
        {icon && <SVGIcon size="20px" icon={icon} />}
        {children}
      </label>
    </button>
  );
};

export default ButtonComponent;
