import { useEffect } from 'react';

type BeforeUnloadHandler = () => void;

function useBeforeUnload(handler?: BeforeUnloadHandler): void {
  useEffect(() => {
    const isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';

      // Cancela todas as tarefas assíncronas pendentes
      window.clearTimeout();
      window.clearInterval();
      document.fonts.ready.then(() => {}).catch(() => {});

      if (handler) {
        handler();
      }
    };

    const handleUnload = () => {
      // Cancela todas as tarefas assíncronas pendentes
      window.clearTimeout();
      window.clearInterval();
      document.fonts.ready.then(() => {}).catch(() => {});
    };

    window.addEventListener(eventName, handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener(eventName, handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [handler]);
}

export default useBeforeUnload;
