import styled from 'styled-components';
import { StringifyOptions } from 'querystring';

interface IContentInput {
  width?: string;
  padding?: boolean | string;
  justify?: string;
  self?: string;
  margin?: string;
  flex?: boolean;
  flexDirection?: string;
  paddingTop?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  position?: string;
  gap?: string;
  alignItems?: string;
  marginLeft?: string;
}

export const ContentInput = styled.div`
  ${({ width }: IContentInput) => (width ? `width: ${width};` : '')}
  ${({ minWidth }: IContentInput) =>
    minWidth ? `min-width: ${minWidth};` : ''}

    ${({ minHeight }: IContentInput) =>
      minHeight ? `min-height: ${minHeight};` : ''}
      ${({ maxWidth }: IContentInput) =>
        maxWidth ? `max-width: ${maxWidth};` : ''}
  
      ${({ maxHeight }: IContentInput) =>
        maxHeight ? `max-height: ${maxHeight};` : ''}
  
  ${({ justify }) =>
    justify ? `display: flex; justify-content: ${justify};` : ''}
  padding-top: 15px;
  ${({ padding }: IContentInput) =>
    padding === true
      ? 'padding-right: 3%'
      : padding
      ? `padding: ${padding}`
      : ''};
  ${({ paddingTop }: IContentInput) =>
    paddingTop ? `padding-top: ${paddingTop};` : ''}
    ${({ marginLeft }: IContentInput) =>
    marginLeft ? `margin-left: ${marginLeft};` : ''}
  ${({ self }) => (self ? `align-self: ${self}` : '')}
  ${({ margin }) => (margin ? `margin: ${margin}` : '')}
  ${({ gap }) => (gap ? `gap: ${gap}` : '')}
  ${({ flex }) => (flex ? `display:flex; flex: 1;` : '')}
  ${({ flexDirection }: IContentInput) =>
    flexDirection ? `flex-direction: ${flexDirection};` : ''}
  ${({ position }: IContentInput) => (position ? `position: ${position};` : '')}
  ${({ alignItems }: IContentInput) =>
    alignItems ? `align-items: ${alignItems};` : ''}

`;
