// export const capitalizeInput = (
//   str: string,
//   preserveUppercaseChars = false
// ) => {
//   if (!str) {
//     return str;
//   }

//   return str
//     .toString()
//     .split(' ')
//     .map(s => {
//       const f = s.charAt(0).toUpperCase();
//       const o = preserveUppercaseChars
//         ? s
//             .slice(1)
//             .split('')
//             .map(x => (x === x.toUpperCase() ? x : x.toLowerCase()))
//             .join('')
//         : s.slice(1).toLowerCase();

//       return f + o;
//     })
//     .join(' ');
// };

export const capitalizeInput = (
  str: string,
  preserveUppercaseChars = false
) => {
  return str;
};

export const convertStringToNumber = (value) => {
  if (!value || typeof value !== 'string') {
    return value;
  }
  if (value[value.length - 1] == '.') {
    value = value.substr(0, value.length - 1);
  }
  return parseFloat(value.replace(/\$/g, '').replace(/,/g, ''));
};

export const extractNumberFromMoneyMask = (value: string) =>
  value && typeof value == 'string' ? Number(value.replace(',', '')) : value;

export const formatNumberByMask = (value: string, maskParams: any) => {
  let formattedValue =
    maskParams && maskParams.prefix
      ? value.replace(maskParams.prefix, '')
      : value;
  formattedValue =
    maskParams && maskParams.decimalLimit
      ? Number(extractNumberFromMoneyMask(formattedValue))?.toFixed?.(
          maskParams.decimalLimit
        )
      : formattedValue;
  if (maskParams.thousandsSeparatorSymbol) {
    return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return formattedValue;
  }
};

export const formatNumberStringAsCurrency = (value: string) => {
  return value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatPrice = (value: string, userHasBetaOptIn: boolean) => {
  if (value != '0' && !value) {
    return value;
  }
  return `$${formatNumberStringAsCurrency(
    (
      convertStringToNumber(value || 0) + (userHasBetaOptIn ? 5 : 0) || 0
    )?.toFixed?.(2)
  )}`;
};

export const toTitleCase = function (inputString: string) {
  if (!inputString) {
    return inputString;
  }
  var i, j, str, lowers, uppers;
  str = inputString.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    'A',
    'An',
    'The',
    'And',
    'But',
    'Or',
    'For',
    'Nor',
    'As',
    'At',
    'By',
    'For',
    'From',
    'In',
    'Into',
    'Near',
    'Of',
    'On',
    'Onto',
    'To',
    'With',
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(
      new RegExp('\\s' + lowers[i] + '\\s', 'g'),
      function (txt) {
        return txt.toLowerCase();
      }
    );

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ['Id', 'Tv'];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(
      new RegExp('\\b' + uppers[i] + '\\b', 'g'),
      uppers[i].toUpperCase()
    );

  return str;
};

export const isNumber = (value) => {
  const sanitizedValue = String(value).replace(/,/g, '');
  
  return !isNaN(sanitizedValue) && Number.parseFloat(sanitizedValue) === Number(sanitizedValue);
}
