import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// interfaces
import { IReduxState } from 'core/interfaces';
import * as Sentry from '@sentry/react';
import { AccountInfoInspect } from '../../../BidInfoForm/screens/BidForm/sections';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from 'core/utils/constants';
import { InspectionMenuScreen } from 'modules/InspectForm/screens';
import './styles.scss';
import {
  FirstForm,
  SiteInformationForm,
  AreasForm,
  FooterBar,
  Header,
} from './sections';

import { ClientInfoInspect } from 'modules/shared/sections';
import {
  SectionRatingModal,
  AddPhotosModal,
  FormProvider,
  NotesModal,
  AddAreaModal,
  AddItemModal,
  PdfModal,
  GenericModal,
} from 'core/components';
import { LoadingModal, SuccessModal } from 'core/components';

import { LoadingPage } from 'modules/BidInfoForm/components/organisms';

import ActionAlertModal from 'core/components/ActionAlertModal';

import { getQueryString, axiosInstance } from 'core/utils';
import { getInspectionURL } from '../../../BidInfoForm/utils';
import { isWebView } from '../../../../core/utils';

import {
  verifyServerUnavailable,
  getFranchiseById,
} from '../../../BidInfoForm/services/bidInfoService';
import { BlockingOverlay } from '../../../BidInfoForm/components/organisms';
import BlockingPopupModal from '../../../BidInfoForm/screens/BlockingPopupModal';
import { useActivityTracking } from '../../../../core/hooks/useActivityTracking';
import { useAuthenticationHandler } from '../../../../core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import { getCookie } from '../../../../core/utils/cookiesUtils';

interface IState {
  needsSaving: boolean;
  isNewModalOpen: boolean;
  hideButtonDesktop: boolean;
  isNewModalAlreadyOpened: boolean;
  blockingMessage?: string;
  error: boolean;
}

type IProps = IDispatchToProps & IStateToProps & RouteComponentProps;

let logSentry = null;

const TrackActivityComponent = (props) => {
  logSentry = useActivityTracking(() => props.values);

  let { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  React.useEffect(() => {
    if (!!error && error.status == 401) {
      props.setError(true);
      props.setLoadingState(false);
      props.showWarningModal(
        true,
        'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        'Access Denied',
        () => {
          localStorage.setItem(
            'anago_redirect_after_login',
            window.location.href
          );
          window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
            'login',
            'home'
          );
        }
      );
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          props.setLoadingState(false);
          props.showWarningModal(
            true,
            'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            'Attention',
            () => {
              window.location.reload(true);
            }
          );
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!!genericError) {
      logSentry(genericError);
      props.showWarningModal(
        true,
        'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        'Attention'
      );
    }
  }, [genericError]);

  return <></>;
};

class NewInspectScreen extends Component<IProps, IState> {
  public state = {
    needsSaving: false,
    isNewModalOpen: false,
    isNewModalAlreadyOpened: false,
    hideButtonDesktop: false,
    showMenu: undefined,
    error: false,
    blockingModal: null,
    isCityUser: false,
    isTemplate: false,
    isCityTemplate: false,
    isSent: false,
  };

  onOpenSearch = () => {
    this.setState({
      ...this.state,
      showMenu: {
        renderTemplateSection: true,
        renderCityTemplateSection: true,
        renderDraftSection: true,
        renderSentSection: true,
        renderSignedSection: true,
      },
    });
  };

  public async loadForm() {
    const {
      location,
      getEditFormRequest,
      setUserAndFranchiseId,
      setIsSent,
      isTemplate,
      isCityTemplate,
      newForm,
      showPopup,
      successResult,
      setLoadingState,
      setAccountInfo,
    } = this.props;

    const currentUrl = location.search;

    let id = getQueryString(currentUrl, 'id');
    let franchiseId = getQueryString(currentUrl, 'FranchiseId');
    let userId = getQueryString(currentUrl, 'userId');

    const isSentQueryString = getQueryString(currentUrl, 'isSent');
    const isTemplateQueryString = getQueryString(currentUrl, 'isTemplate');
    const shouldHideDraftAlertPopup = getQueryString(
      currentUrl,
      'draftPopupDisabled'
    );

    const isDuplicateQueryString = getQueryString(currentUrl, 'isDuplicate');

    const isCityTemplateQueryString = getQueryString(
      currentUrl,
      'isCityTemplate'
    );

    const locationIdQueryString = getQueryString(currentUrl, 'locationId');
    const externalIdQueryString = getQueryString(currentUrl, 'externalId');

    const locationId = !Number.isNaN(Number(locationIdQueryString))
      ? locationIdQueryString
      : !Number.isNaN(Number(externalIdQueryString))
      ? externalIdQueryString
      : null;

    const cleanSuiteUserId = getQueryString(currentUrl, 'cleansuiteuserid');
    const cleanSuiteCityId = getQueryString(currentUrl, 'cleansuitecityid');

    let isOptInCleanSuite = false;

    if (cleanSuiteUserId && cleanSuiteCityId && locationId) {
      try {
        const { data } = await axiosInstance.get(
          `InspectionReport/getUserAndFranhiseByWaterStreet?userWaterStreetID=${cleanSuiteUserId}&waterStreetMasterCityID=${cleanSuiteCityId}&locationId=${locationId}`
        );

        franchiseId = data.FranchiseId;
        userId = data.UserId;
        id = data.InspectionReportId;
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    if (franchiseId) {
      try {
        const { data: franchise } = await getFranchiseById(franchiseId);

        isOptInCleanSuite = franchise.IsOptInCleanSuite;

        setAccountInfo({
          isOptInCleanSuite,
          masterCity: franchise.AnagoOfCityName,
          IsOptInCleanSuite: franchise.IsOptInCleanSuite,
        });
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    if (locationId) {
      try {
        const { data: locationData } = await axiosInstance.get(
          `/InspectionReport/WaterStreet/GetLocationByID/${locationId}`
        );

        setAccountInfo({
          ...locationData.WaterStreet,
          locationId,
          AccountName: locationData.WaterStreet.BusinessName,
          AccountAddress: locationData.WaterStreet.Address,
        });
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    this.setState({
      isCityUser,
      isTemplate: isTemplateQueryString === 'true',
      isCityTemplate: isCityTemplateQueryString === 'true',
      isSent: isSentQueryString === 'true',
    });

    const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');

    if (id) {
      getEditFormRequest(
        id,
        locationId && isOptInCleanSuite ? locationId : null,
        isTemplateQueryString === 'true',
        isOptInCleanSuite
      );
    }

    setUserAndFranchiseId(
      franchiseId ? Number(franchiseId) : null,
      userId ? Number(userId) : null
    );

    const isTrulySent = isSentQueryString === 'true';

    if (isTrulySent) {
      setIsSent();
    }

    if (shouldHideDraftAlertPopup) {
      this.setState({
        isNewModalOpen: false,
        isNewModalAlreadyOpened: false,
      });
    } else {
      if (
        (isTemplateQueryString === 'true' ||
          isTemplate ||
          ((isCityTemplateQueryString === 'true' || isCityTemplate) &&
            isCityUser)) &&
        showPopup
      ) {
        this.setState({
          isNewModalOpen: true,
          isNewModalAlreadyOpened: true,
        });
      } else if (
        (isCityTemplateQueryString === 'true' || isCityTemplate) &&
        (showPopup || (!isCityUser && newForm))
      ) {
        this.setState({
          isNewModalOpen: true,
          isNewModalAlreadyOpened: true,
        });
      }
    }

    if (hideButtonDesktop === 'true') {
      this.setState({ hideButtonDesktop: true });
    }

    if (isDuplicateQueryString === 'true') {
      try {
        const formValues = JSON.parse(
          localStorage.getItem('INSPECTION_DUPLICATE')
        );

        toast.info('The proposal was duplicated successfully.');

        this.props.duplicate(formValues);
      } catch (e) {}
    }

    if (userId) {
      try {
        const response = await axiosInstance.get(
          `getUserById?userid=${userId}`
        );

        const userData = response.data;

        const isUserRole =
          userData &&
          userData.Roles &&
          !userData.Roles.includes('Super Admin') &&
          !userData.Roles.includes('Master User') &&
          !userData.Roles.includes('Corporate Admin') &&
          !userData.Roles.includes('Corporate User') &&
          !userData.Roles.includes('Super Master');

        this.props.setAccountInfo({ isUserRole });

        const { setInspectedBy } = this.props;

        setInspectedBy(userData.Name, userData.UserId);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    if (window.history.pushState) {
      let searchParams = new URLSearchParams(this.props.location.search);

      if (successResult) {
        searchParams.set('id', successResult.originalId);
      } else if (this.props.InspectionReportId) {
        searchParams.set('id', this.props.InspectionReportId);
      }

      searchParams.delete('cleansuiteuserid');
      searchParams.delete('cleansuitecityid');

      searchParams.set('FranchiseId', '' + franchiseId);
      searchParams.set('userId', '' + userId);

      searchParams.set('isTemplate', '' + !!isTemplate);
      searchParams.set('isCityTemplate', '' + !!isCityTemplate);

      window.history.pushState(
        {
          path:
            window.location.protocol +
            '//' +
            window.location.host +
            '/#/inspection-report-form',
        },
        '',
        `${window.location.protocol}//${window.location.host}/#/inspection-report-form?${searchParams}`
      );
    }

    setLoadingState(false);
  }

  public componentDidMount() {
    this.loadForm();

    const currentUrl = this.props.location.search;
    const userId = getQueryString(currentUrl, 'userId');

    (async () => {
      try {
        const result = await verifyServerUnavailable(userId);
        if (!result || result.error) {
          this.setState({
            blockingModal: {
              message:
                'Server under maintenance at this time, please try later',
            },
          });
        } else {
          this.setState({ blockingModal: null });
        }
      } catch (e) {
        if (e.response.status == '503') {
          this.setState({
            blockingModal: {
              message:
                'Server under maintenance at this time, please try later',
            },
          });
        }
      }
    })();
  }

  public componentDidUpdate(prevProps: Readonly<any>): void {
    const {
      location: prevPropsLocation,
      isDraft,
      isSent,
      isTemplate,
    } = prevProps;
    const { setIsTemplate } = this.props;

    const locationSearch = prevPropsLocation.search.split('&');
    const isTemplateSearch = locationSearch.find(
      (item: string) => item === 'isTemplate=true'
    );

    if (isTemplateSearch && !isTemplate && !isDraft && !isSent) {
      setIsTemplate(true);
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { isNewModalAlreadyOpened } = this.state;

    const currentUrl = nextProps.location.search;

    const { isTemplate, isCityTemplate, isSent, newForm, showPopup } =
      nextProps;

    const showModal = () => {
      const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';
      const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');
      const shouldHideDraftAlertPopup = getQueryString(
        currentUrl,
        'draftPopupDisabled'
      );

      if (shouldHideDraftAlertPopup) {
        this.setState({
          isNewModalOpen: false,
          isNewModalAlreadyOpened: false,
          newForm,
        });
      } else {
        if (
          (!isNewModalAlreadyOpened || showPopup) &&
          (isTemplate || isCityTemplate)
        ) {
          this.setState({
            isNewModalOpen: true,
            isNewModalAlreadyOpened: true,
            newForm,
          });
        } else if (
          (!isNewModalAlreadyOpened || showPopup || (!isCityUser && newForm)) &&
          isCityTemplate
        ) {
          this.setState({
            isNewModalOpen: true,
            isNewModalAlreadyOpened: true,
            newForm,
          });
        } else {
          this.setState({
            isNewModalOpen: false,
            newForm,
          });
        }
      }

      if (hideButtonDesktop === 'true') {
        this.setState({ hideButtonDesktop: true });
      }
    };

    const { error } = nextProps;

    if (error && error !== this.props.error) {
      const currentUrl = nextProps.location.search;
      const id = getQueryString(currentUrl, 'id');

      if (logSentry) {
        logSentry(error, { uid: id });
      }

      if (error.status === 500) {
        this.props.history.push(`/forms/unable-to-open`);
      }
    } else if (!this.state.isNewModalAlreadyOpened) {
      showModal();
    } else if (this.props.location.search != nextProps.location.search) {
      showModal();
    }

    const { successResult, FranchiseId, userId } = nextProps;

    if (window.history.pushState) {
      let searchParams = new URLSearchParams(nextProps.location.search);

      if (successResult) {
        searchParams.set('id', successResult.originalId);
      } else if (this.props.InspectionReportId) {
        searchParams.set('id', this.props.InspectionReportId);
      }

      if (
        searchParams.get('cleansuiteuserid') &&
        searchParams.get('cleansuiteuserid') &&
        FranchiseId &&
        userId
      ) {
        searchParams.delete('cleansuiteuserid');
        searchParams.delete('cleansuitecityid');
        searchParams.set('FranchiseId', '' + FranchiseId);
        searchParams.set('userId', '' + userId);
      }

      searchParams.set('isTemplate', '' + !!isTemplate);
      searchParams.set('isCityTemplate', '' + !!isCityTemplate);

      if (newForm !== undefined) {
        if (newForm === 'new') {
          searchParams.set('isSent', 'false');
        } else {
          searchParams.set('isSent', isSent ? 'true' : 'false');
        }
      }

      window.history.pushState(
        {
          path:
            window.location.protocol +
            '//' +
            window.location.host +
            '/#/inspection-report-form',
        },
        '',
        `${window.location.protocol}//${window.location.host}/#/inspection-report-form?${searchParams}`
      );

      showModal();
    }
  }

  public dealClose = () => {
    const { history } = this.props;

    if (isWebView()) {
      history.push('/close-form');
    } else {
      document.location.href = getInspectionURL();
    }
  };

  public closeWarningModal = () => {
    const { setWarningModalState } = this.props;

    if (this.state.error) {
      window.location.href = ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
        'login',
        'home'
      );
    }

    setWarningModalState(false, '');
  };

  public onClickDraft = () => {
    const { transformIntoDraft } = this.props;

    transformIntoDraft();

    setTimeout(() => {
      this.setState({
        isNewModalOpen: false,
        isTemplate: false,
        isCityTemplate: false,
      });
      this.props.setShowPopup(false);
    }, 0);
  };

  public onEnter = (id: string) => {
    const input = document.getElementById(id);
    input!.focus();
  };

  setClientInfoValues = (params) => {
    this.props.setAccountInfo({
      ...this.state,
      ...params,
    });
  };

  setFieldValue = (name, value) => {
    this.props.setAccountInfo({
      ...this.state,
      [name]: value,
    });
  };

  public render() {
    const { hideButtonDesktop } = this.state;

    const {
      showRatingModal,
      showNotesModal,
      showPhotosModal,
      showAddAreasModal,
      showAddItemModal,
      showExitModal,
      setWarningModalState,
      showWarningModal,
      showPdfModal,
      successOnSave,
      setNeedsSaving,
      needsSaving,
      setLoadingState,
      isTemplate,
      userId,
      isLocationIdWarningVisible,
      setLocationIdWarningVisible,
    } = this.props;

    return (
      <FormProvider
        formValues={this.props}
        setFormValues={this.setClientInfoValues}
        onChange={this.setFieldValue}
      >
        <TrackActivityComponent
          values={this.props.values}
          showWarningModal={setWarningModalState}
          setLoadingState={setLoadingState}
          setError={(error: boolean) => this.setState({ error })}
        />
        <div className="inspection-root-div">
          <div className="inspection-content-screen">
            {this.props.loading && <LoadingPage />}
            {this.state.blockingModal && (
              <BlockingOverlay>
                <BlockingPopupModal
                  message={this.state.blockingModal.message}
                />
              </BlockingOverlay>
            )}
            {!isTemplate ? (
              <Header
                needsSaving={needsSaving}
                hideButtonDesktop={hideButtonDesktop}
                openSearch={this.onOpenSearch}
              />
            ) : null}
            {/* <AdditionalInformation /> */}
            <FirstForm
              toggleNeedsSaving={() => setNeedsSaving(true)}
              onEnter={this.onEnter}
              openSearch={this.onOpenSearch}
              userId={userId}
            />
            <ClientInfoInspect />
            <AccountInfoInspect />
            <SiteInformationForm
              toggleNeedsSaving={() => setNeedsSaving(true)}
              onEnter={this.onEnter}
            />
            <AreasForm toggleNeedsSaving={() => setNeedsSaving(true)} />
            <FooterBar
              setNeedsSaving={setNeedsSaving}
              onSaveCallback={() => {
                this.setState({ isNewModalAlreadyOpened: false });
              }}
            />
          </div>
          <LoadingModal />
          {successOnSave && <SuccessModal {...this.props} />}
          {/* <SuccessModal /> */}
          {showPhotosModal && <AddPhotosModal />}
          {showNotesModal && !this.state.showMenu && <NotesModal />}
          {showRatingModal && <SectionRatingModal />}
          {showAddAreasModal && <AddAreaModal />}
          {showAddItemModal && <AddItemModal />}
          {showPdfModal.status && <PdfModal />}
          {showWarningModal.status && (
            <ActionAlertModal
              title={showWarningModal.title}
              message={showWarningModal.message}
              onConfirm={() => {
                this.closeWarningModal();
                if (showWarningModal.onClose) {
                  showWarningModal.onClose();
                }
              }}
              // onDismiss={this.props.closeExitModal}
              isVisible={showWarningModal.status}
            />
          )}
          <ActionAlertModal
            title="Exit without saving?"
            message="Remember, that if you exit without saving, you will lose all progress."
            onConfirm={this.dealClose}
            onDismiss={this.props.closeExitModal}
            isVisible={showExitModal}
          />

          {!this.state.error &&
            (((this.state.isCityUser || !this.state.isCityUser) &&
              this.state.isCityTemplate) ||
              !this.state.isCityTemplate) && (
              <ActionAlertModal
                title="Template"
                message="Do you want to edit or start a new draft based on the current template?"
                onConfirm={this.onClickDraft}
                confirmText="NEW DRAFT"
                onDismiss={() => {
                  this.setState({ isNewModalOpen: false });
                  this.props.setShowPopup(false);
                }}
                // onDismiss={() => this.props.setTemplateModal(false)}
                dismissText="EDIT TEMPLATE"
                // isVisible={this.props.showOpenTemplateModal}
                isVisible={this.state.isNewModalOpen}
              ></ActionAlertModal>
            )}

          {/* {!this.state.error &&
            !this.state.isCityUser &&
            this.state.isCityTemplate &&
            !this.state.newForm && (
              <ActionAlertModal
                title="Template"
                message="This will crate a new draft based on your City template"
                onConfirm={this.onClickDraft}
                confirmText="OK"
                // isVisible={this.props.showOpenTemplateModal}
                isVisible={this.state.isNewModalOpen}
              ></ActionAlertModal>
            )} */}

          {!this.state.error &&
            !this.state.isCityUser &&
            this.state.isCityTemplate &&
            this.state.newForm && (
              <ActionAlertModal
                title="Success"
                message="The Bid Form was successfully saved as a City Template. A Draft copy of the template will now be opened for further edits."
                onConfirm={this.onClickDraft}
                confirmText="OK"
                // isVisible={this.props.showOpenTemplateModal}
                isVisible={this.state.isNewModalOpen}
              ></ActionAlertModal>
            )}
        </div>

        {this.state.showMenu && (
          <GenericModal
            isOpen={this.state.showMenu}
            showHeader={false}
            clickOutsideToClose={false}
            fullscreen={false}
            height="710px"
            width="600px"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <InspectionMenuScreen
              {...this.props}
              showBackground={false}
              {...this.state.showMenu}
              doneAction={(state) => {
                this.setState({ ...this.state, showMenu: null });
                // setIsLoading(state);
              }}
            />
          </GenericModal>
        )}

        <ActionAlertModal
          style={{ maxWidth: '500px' }}
          renderMessage={() => (
            <div>
              <p style={{ textAlign: 'center' }}>
                Uh oh, it seems you don't have a Location ID associated with
                your Inspection Report.
              </p>
              <p style={{ textAlign: 'center' }}>
                Please contact AFI Support at{' '}
                <a href="https://anagocleaning.com">anagocleaning.com</a> so we
                can address this issue immediately. <br />
                We apologize for the inconvenience.
              </p>
            </div>
          )}
          confirmText="CLOSE"
          onConfirm={() => setLocationIdWarningVisible(false)}
          isVisible={isLocationIdWarningVisible}
        />
      </FormProvider>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
  values: state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(NewInspectScreen);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as NewInspectScreen };
