import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { IReduxState } from 'core/interfaces';
import { useFormProps } from 'core/components';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import './styles.scss';
import './floatingLabel.scss';
import getError from '../../../../../../core/utils/getError';

interface IOwnProps {
  toggleNeedsSaving(): void;
  onEnter: Function;
}

type IProps = IDispatchToProps & IStateToProps & IOwnProps;

const SiteInformationForm: React.FC<IProps> = (props) => {
  const { onChange, formValues } = useFormProps();

  const { toggleNeedsSaving, FranchiseOwnerName, isPreview } = props;
  const { isSent } = props;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange(name, value);
    toggleNeedsSaving();
  };

  useEffect(() => {
    onChange('FranchiseOwnerName', FranchiseOwnerName);
  }, [FranchiseOwnerName]);

  if (formValues?.isOptInCleanSuite) {
    return null
  }

  return (
    <div className="second-form-container" style={{ width: '500px' }}>
      <div className="second-form-content-container">
        <div className="second-form-rows-container">
          <div
            className="has-float-label"
          >
            <input
              id="franchise-owner"
              placeholder=" "
              name="FranchiseOwnerName"
              value={formValues.FranchiseOwnerName}
              onChange={handleInputChange}
              disabled={
                isSent ||
                formValues.SentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
            />
            <label htmlFor="franchise-owner">
              Franchise Owner Name
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<
  IStateToProps,
  IDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(SiteInformationForm);

export { Connected as SiteInformationForm };
