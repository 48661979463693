import React from 'react';
import { LoadingPage } from '../../../BidInfoForm/components/organisms';
import { getQueryString } from 'core/utils';
import { generatePDFWithoutSave } from '../../services/performanceEvaluationService';
import { getBase64ByUrl } from 'modules/BidInfoForm/services/bidInfoService';
import { parseValues } from '../../services/parser';
import { isWebView } from 'core/utils';
import { HiddenAnchor } from 'modules/BidInfoForm/components/atoms';
import { getDirectPdfDownloadUrl } from '../../../BidInfoForm/services/bidInfoService';

export const PrintPdf = (props) => {
  const { search } = props.location;
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const [uniqueId, setUniqueId] = React.useState(null);
  const pid = decodeURI(getQueryString(search, 'pid'));
  const userId = decodeURI(getQueryString(search, 'userId'));
  const company = decodeURI(getQueryString(search, 'companyName'));
  const franchiseId = decodeURI(getQueryString(search, 'franchiseId'));
  const companyRepresentative = decodeURI(
    getQueryString(search, 'companyRepresentative')
  );

  React.useEffect(() => {
    if (isWebView()) {
      const interval = setInterval(() => {
        clearInterval(interval);

        props.history.push(
          `/performance-evaluation?userId=${userId}&pid=${pid}&companyName=${company}&franchiseId=${franchiseId}&companyRepresentative=${companyRepresentative}`
        );
      }, 100);
      return;
    }

    const values = parseValues({
      pid,
      company,
      companyRepresentative,
      franchiseId,
    });

    generatePDFWithoutSave(values).then(({ data }) => {
      setUniqueId(data['PEFUniqID']);
      setPdfUrl(data.Pdfpath);
      setTimeout(() => {
        document.getElementById('hiddenAnchor').click();
      }, 0);
      // window.location.href = data.Pdfpath;
    });
  }, [search]);

  return (
    <>
      <LoadingPage />
      <HiddenAnchor
        href={getDirectPdfDownloadUrl(
          pdfUrl,
          `${pid.replace(/\&/, ' ')}PerfEval`
        )}
        download={`${pid.replace(/\&/, ' ')}_PerfEval`}
        id="hiddenAnchor"
        title="download"
      />
    </>
  );
};
