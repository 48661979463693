import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButtonWrapper, IconWrapper, CaptionWrapper } from './styles';

const IconButton: React.FC = ({
  icon,
  caption,
  iconStyle,
  ...props
}) => (
  <IconButtonWrapper {...props}>
    <IconWrapper style={iconStyle}>
      <FontAwesomeIcon icon={icon} width="12px" />
    </IconWrapper>
    {caption && <CaptionWrapper>{caption}</CaptionWrapper>}
  </IconButtonWrapper>
);

export default IconButton;
