import React from 'react';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
// utils
import { getRatingClassName, adjustRating, getQueryString } from 'core/utils';
// ui
import './styles.scss';
import { AutoComplete } from 'core/components';
import { IArea } from 'core/interfaces/Area';
import getError from '../../../../../../core/utils/getError';

import ActionAlertModal from 'core/components/ActionAlertModal';

interface IOwnProps {
  toggleNeedsSaving(): void;
}

type IProps = IDispatchToProps & IStateToProps & IOwnProps;

interface IState {
  newAreaName: string;
  showEmptyArea: boolean;
  suggestionClicked: boolean;
  showAlertModal: boolean;
  areaSelected: IArea;
}

class AreasForm extends React.PureComponent<IProps, IState> {
  public state = {
    newAreaName: '',
    showEmptyArea: false, // when adding a new area
    suggestionClicked: false,
    showAlertModal: false,
    areaSelected: null,
  };

  // render the area rows at the table
  public renderAreaRow = (area: IArea, index: number) => {
    const { isSent: isSentProp, isTemplate, isUserRole } = this.props;
    const isSentQueryString =
      getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentProp || isSentQueryString;

    const { AreaPhotos, RatingAverage } = area;

    const areaRatingClassName = getRatingClassName(RatingAverage);
    const ratingAdjusted = adjustRating(RatingAverage);

    const disabledOpacity = !(isTemplate && isUserRole) ? 1 : 0.5;

    return (
      <tbody className="row-div" key={index}>
        <th
          className={`td-area-name capitalize-first ${
            isSent ? 'disabled-grey' : ''
          }`}
          onClick={() =>
            !(isTemplate && isUserRole) ? this.onClickAreaName(area) : null
          }
        >
          {area.Name}
        </th>
        <th
          className="td-area-average"
          onClick={() =>
            !(isTemplate && isUserRole) ? this.onClickAreaName(area) : null
          }
        >
          {area.RatingAverage > 0 ? (
            <>
              <div
                className={`average-mark ${areaRatingClassName}`}
                style={{
                  left: `${ratingAdjusted}`,
                }}
              >
                <span>{area.RatingAverage?.toFixed?.(1)}</span>
              </div>
              <div className="average-item">
                <span>1</span>
              </div>
              <div className="average-item">
                <span>2</span>
              </div>
              <div className="average-item">
                <span>3</span>
              </div>
              <div className="average-item">
                <span>4</span>
              </div>
              <div className="average-item">
                <span>5</span>
              </div>
            </>
          ) : (
            <button
              className="button-dark square"
              disabled={isTemplate && isUserRole}
              onClick={() => this.onClickAreaName(area)}
            >
              Rate Area
            </button>
          )}
        </th>
        <th
          className="td-area-files"
          onClick={() =>
            !(isTemplate && isUserRole) ? this.onClickAddPhoto(area) : null
          }
        >
          <div className="camera-icon" style={{ opacity: disabledOpacity }}>
            {AreaPhotos.length > 0 && (
              <div className="photos-number">
                <span>{AreaPhotos.length}</span>
              </div>
            )}
          </div>
          {isSent ? (
            <span>View Photo</span>
          ) : (
            <span style={{ opacity: disabledOpacity }}>Add Photo</span>
          )}
        </th>
        <th
          className="td-area-action"
          onClick={() =>
            !isSent && !(isTemplate && isUserRole)
              ? this.onClickDismiss(area)
              : null
          }
        >
          <i
            className={`trash-icon ${isSent ? 'blocked' : ''}`}
            style={{ opacity: disabledOpacity }}
          />
          <span
            className={isSent ? 'blocked' : ''}
            style={{ opacity: disabledOpacity }}
          >
            Delete
          </span>
        </th>
      </tbody>
    );
  };

  // dismiss action: remove the area
  public onClickDismiss = (area: IArea) => {
    this.setState({
      showAlertModal: true,
      areaSelected: area,
    });

    // const deleteConfirmation = window.confirm(
    //   'Are you sure you want to delete this item?'
    // );
    // if (deleteConfirmation) {
    //   const { removeAreaRequest } = this.props;
    //   removeAreaRequest(area);
    // }
  };

  public onConfirmDeletion = () => {
    const { areaSelected } = this.state;
    const { removeAreaRequest } = this.props;
    removeAreaRequest(areaSelected);
    this.setState({
      showAlertModal: false,
      areaSelected: null,
    });
  };

  // add new area action
  public onClickAdd = () => {
    const { newAreaName, suggestionClicked } = this.state;
    const { addAreaRequest, setWarningModalState } = this.props;

    if (!suggestionClicked) {
      if (newAreaName.length > 3) {
        addAreaRequest(newAreaName);
        this.setState({
          newAreaName: '',
          showEmptyArea: false,
        });
      } else {
        setWarningModalState(true, 'Please, insert a valid name');
        // alert('Please, insert a valid name');
      }
    }
  };

  // on click the area name open the add rating modal
  public onClickAreaName = async (area: IArea) => {
    const { setAreaSelected, setRatingModalState } = this.props;
    await setAreaSelected(area);
    setRatingModalState(true);
  };

  // on click the add photo opens the photo modal
  public onClickAddPhoto = (area: IArea) => {
    const { setAreaSelected, setPhotosModalState } = this.props;
    setAreaSelected(area);
    setPhotosModalState(true);
  };

  // render the add area row
  public renderAddArea = () => {
    const { addPreDefinedArea } = this.props;
    const { newAreaName } = this.state;
    return (
      <tbody className="row-div">
        <th className="td-area-name capitalize-first">
          <AutoComplete
            type="area"
            placeholder="New Area"
            inputValue={newAreaName}
            onChangeInputValue={(value) => {
              this.setState({ newAreaName: value });
              this.props.toggleNeedsSaving();
            }}
            onClickArea={(area) => {
              addPreDefinedArea(area);
              this.setState({
                showEmptyArea: false,
              });
            }}
            // onBlurAddArea={() => setTimeout(this.onClickAdd, 50)}
            onSuggestionClicked={(value) =>
              this.setState({ suggestionClicked: value })
            }
          />
        </th>
        <th className="td-area-average">
          <div className="average-item">
            <span>1</span>
          </div>
          <div className="average-item">
            <span>2</span>
          </div>
          <div className="average-item">
            <span>3</span>
          </div>
          <div className="average-item">
            <span>4</span>
          </div>
          <div className="average-item">
            <span>5</span>
          </div>
        </th>
        <th className="td-area-files">
          <div className="camera-icon blocked" />
          <span className="blocked">Add photo</span>
        </th>
        <th className="td-area-action">
          <i className="trash-icon blocked" />
          <span className="blocked">Delete</span>
        </th>
      </tbody>
    );
  };

  // render the area section of the screen
  public render() {
    const {
      Areas,
      isSent: isSentProp,
      setAddAreasModal,
      errors,
      FacilityRating,
      isTemplate,
      isUserRole,
    } = this.props;
    const { showEmptyArea } = this.state;
    const areaClassName = getRatingClassName(FacilityRating);
    const isSentQueryString =
      getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentQueryString;

    return (
      <React.Fragment>
        <div className="section-division">
          <div className="areas-header-container">
            <div className="title-container">
              <h2 className={`${getError('grandTotal', errors).className}`}>
                Ratings
              </h2>
            </div>
            <span
              className={`is-invalid ${
                getError('grandTotal', errors).className
              }`}
            >
              {getError('grandTotal', errors).showError &&
                ` (${getError('grandTotal', errors).msg})`}{' '}
            </span>
            <div className="tabs-rating-container">
              <div className="tab" style={{ backgroundColor: 'rgb(0,176,72)' }}>
                <p>1 - Above Standard</p>
              </div>
              <div className="tab" style={{ backgroundColor: 'rgb(0,96,255)' }}>
                <p>2 - Standard</p>
              </div>
              <div
                className="tab"
                style={{ backgroundColor: 'rgb(178,156,17)' }}
              >
                <p>3 - Needs Attention</p>
              </div>
              <div
                className="tab"
                style={{ backgroundColor: 'rgb(255,140,68)' }}
              >
                <p>4 - Schedule Not Followed</p>
              </div>
              <div className="tab" style={{ backgroundColor: 'rgb(222,0,44)' }}>
                <p>5 - Fail</p>
              </div>
            </div>
          </div>
        </div>
        <div className="third-form-container">
          {
            <table className="table-areas">
              <tbody className="tr-header-areas">
                <th className="th-area-name">AREAS</th>
                <th className="th-area-average">AREA RATINGS</th>
                <th className="th-area-action" />
              </tbody>
              {Areas.map((area, index) => this.renderAreaRow(area, index))}
              {!isSent && showEmptyArea && this.renderAddArea()}
              <div className="add-new-area">
                <div className="score-overall-container">
                  <div className="content">
                    <p>Overall Rating:</p>
                    <span className={`score ${areaClassName}`}>
                      {FacilityRating?.toFixed?.(1)}
                    </span>
                  </div>
                </div>
                {!isSent && !isUserRole && (
                  <button
                    className="button square"
                    disabled={isTemplate && isUserRole}
                    onClick={() => setAddAreasModal(true)}
                  >
                    Add New Area
                  </button>
                )}
              </div>
            </table>
          }
        </div>

        <ActionAlertModal
          title=""
          message="Are you sure you want to delete this item?"
          onConfirm={() => this.onConfirmDeletion()}
          // onDismiss={this.props.closeExitModal}
          onDismiss={() => {
            this.setState({
              showAlertModal: false,
              areaSelected: null,
            });
          }}
          isVisible={this.state.showAlertModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<
  IStateToProps,
  IDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(AreasForm);

export { Connected as AreasForm };
