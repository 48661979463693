import React, { useEffect, useState } from 'react';
import { getQueryString } from 'core/utils';
import {
  AutocompleteSummary as Autocomplete,
  Modal,
  ButtonComponent,
} from 'core/components';
import SaveIcon from './assets/SaveIcon.svg';
import BackIcon from './assets/BackIcon.svg';
import { toast } from 'react-toastify';
import { insertSupplyDetail } from 'modules/BidInfoForm/services/bidInfoServiceClient';
import { SupplyDetail } from 'modules/shared/sections/Summary';

interface EditModalProps {
  opened: boolean;
  onClose: () => void;
  header: string;
  onCreate: (supplyDetail: SupplyDetail) => void;
  onUpdate: (supplyDetail: SupplyDetail) => void;
  units: string[];
  supplyCategories: string[];
  brandInfos: string[];
  isEdit: boolean;
  supplyId: string | number;
  allowEditPrice: boolean;
  supplyDetailReference?: SupplyDetail;
}

export const EditModal: React.FC<EditModalProps> = ({
  allowEditPrice,
  opened,
  onClose,
  header,
  units,
  supplyCategories,
  brandInfos,
  supplyId,
  isEdit,
  supplyDetailReference,
  onUpdate,
  onCreate,
}) => {
  const currentUrl = window.location.href;
  const userId = getQueryString(currentUrl, 'userId');

  const [supplyDetail, setSupplyDetail] = useState<{
    BrandInfo: string;
    Price: null | number;
    Unit: string;
    SupplyCategory: string;
  }>({
    BrandInfo: '',
    Price: null,
    Unit: '',
    SupplyCategory: '',
  });

  const [errors, setErrors] = useState({
    BrandInfo: false,
    Unit: false,
    SupplyCategory: false,
  });

  useEffect(() => {
    if (isEdit && supplyDetailReference) {
      setSupplyDetail({
        BrandInfo: supplyDetailReference.BrandInfo,
        SupplyCategory: supplyDetailReference.SupplyCategory,
        Unit: supplyDetailReference.Unit,
        Price: supplyDetailReference.Price,
      });
    } else {
      setSupplyDetail({
        BrandInfo: '',
        Price: null,
        Unit: '',
        SupplyCategory: '',
      });
    }
    setErrors({
      BrandInfo: false,
      Unit: false,
      SupplyCategory: false,
    });
  }, [isEdit, supplyDetailReference, opened]);

  const handleClose = () => {
    setSupplyDetail({
      BrandInfo: '',
      Price: null,
      Unit: '',
      SupplyCategory: '',
    });

    onClose();
  };

  const createSupplyDetail = async () => {
    const validated = validateFields();
    if (validated) {
      const newSupplyDetail = {
        SupplyId: supplyId,
        SupplyCategory: supplyDetail.SupplyCategory,
        BrandInfo: supplyDetail.BrandInfo,
        Unit: supplyDetail.Unit,
        Price: supplyDetail.Price || 0,
        UserId: userId,
        Order: 1,
        Default: false,
      };
      try {
        const { request } = await insertSupplyDetail(newSupplyDetail);
        toast.info('Supply created successfully');
        onCreate(request);
        handleClose();
      } catch {
        toast.error('Error!', {
          icon: false,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        });
      }
    }
  };

  const editSupplyDetail = async () => {
    const validated = validateFields();
    if (supplyDetailReference && validated) {
      const newSupplyDetail = {
        ...supplyDetailReference,
        SupplyCategory: supplyDetail.SupplyCategory,
        BrandInfo: supplyDetail.BrandInfo,
        Unit: supplyDetail.Unit,
        Price: supplyDetail.Price || 0,
      };
      onUpdate(newSupplyDetail);
      handleClose();
    }
  };

  const validateFields = () => {
    const newErrors = {
      BrandInfo: false,
      Unit: false,
      SupplyCategory: false,
    };
    let valid = true;
    if (!supplyDetail.SupplyCategory.trim()) {
      newErrors.SupplyCategory = true;
      valid = false;
    }
    if (!supplyDetail.BrandInfo.trim()) {
      newErrors.BrandInfo = true;
      valid = false;
    }
    if (!supplyDetail.Unit.trim()) {
      newErrors.Unit = true;
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  return (
    <Modal
      header={header}
      onClose={handleClose}
      opened={opened}
      className="edit-modal-supply"
    >
      <div className="modal-content">
        <div className="modal-content-row">
          <Autocomplete
            onChange={(value) =>
              setSupplyDetail({ ...supplyDetail, SupplyCategory: value })
            }
            value={supplyDetail.SupplyCategory}
            list={supplyCategories}
            placeholder="Search Item"
            maxLength={50}
            label="Item*"
            error={errors.SupplyCategory ? 'This field is required' : ''}
          />
          <Autocomplete
            onChange={(value) =>
              setSupplyDetail({ ...supplyDetail, BrandInfo: value })
            }
            list={brandInfos}
            value={supplyDetail.BrandInfo}
            maxLength={150}
            placeholder="Search Description"
            error={errors.BrandInfo ? 'This field is required' : ''}
            label="Description*"
          />
        </div>
        <div className="modal-content-row">
          <Autocomplete
            onChange={(value) =>
              setSupplyDetail({ ...supplyDetail, Unit: value })
            }
            maxLength={50}
            value={supplyDetail.Unit}
            list={units}
            error={errors.Unit ? 'This field is required' : ''}
            placeholder="Search Unit(s)"
            label="Unit(s)*"
          />
          {(allowEditPrice || !isEdit) && (
            <div>
              <div>
                <label className="autocomplete-label">Price</label>
              </div>
              <input
                className="autocomplete-input price"
                value={supplyDetail.Price || ''}
                type="number"
                pattern="^[$\-\s]*[\d\,]*?([\.]\d{0,2})?\s*$"
                onKeyDown={(e) =>
                  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                }
                onChange={(event) => {
                  const { value: val } = event.target;
                  if (val.length > 11) return;
                  setSupplyDetail({
                    ...supplyDetail,
                    Price: parseFloat(event.target.value),
                  });
                }}
                placeholder="Price"
              />
            </div>
          )}
        </div>
      </div>
      <div className="confirm-buttons edit-buttons">
        <ButtonComponent icon={BackIcon} onClick={handleClose}>
          CANCEL
        </ButtonComponent>
        <ButtonComponent
          icon={SaveIcon}
          onClick={() => {
            isEdit ? editSupplyDetail() : createSupplyDetail();
          }}
          secondary
        >
          SAVE
        </ButtonComponent>
      </div>
    </Modal>
  );
};

export default EditModal;
