import React, { createRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  IStateToProps,
  IDispatchToProps
} from 'modules/InspectForm/interfaces';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { IReduxState } from 'core/interfaces';
import { getQueryString } from 'core/utils';

import ActionAlertModal from 'core/components/ActionAlertModal';

import { toast } from 'react-toastify';

import './styles.scss';
import { getCookie } from '../../utils/cookiesUtils';

type IProps = IDispatchToProps & IStateToProps & RouteComponentProps;

function iosCopyToClipboard(el: any) {
  const oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  const s = window.getSelection();
  s!.removeAllRanges();
  s!.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
}

class NotesModal extends React.Component<IProps> {
  public textArea = createRef<HTMLTextAreaElement>();

  public state = {
    Notes: '',
    showAlertModal: false,
  };

  public componentDidMount() {
    const {
      Notes,
      location,
      formClickedOnMenu,
      getEditFormRequest,
      isFromMenu,
      isSent: isSentProp
    } = this.props;
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentQueryString;

    const { pathname } = location;
    if ((isFromMenu) && formClickedOnMenu.id && !isSent) {
      getEditFormRequest(formClickedOnMenu.id);
    }

    this.setState({
      Notes
    });
  }

  public componentWillReceiveProps(nextProps: IProps) {
    const { Notes } = nextProps;
    if (Notes !== this.props.Notes) {
      this.setState({ Notes });
    }
  }

  public onClose = () => {
    const {
      setNote,
      setNotesModalState,
      location,
      saveReportRequest,
      formClickedOnMenu,
      isFromMenu
    } = this.props;
    setNote(this.state.Notes);
    setNotesModalState(false);
    // saving the Notes only for draft and template form on the /menu route when closing the modal
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    if (
      // location.pathname.includes('/menu') &&
      (isFromMenu) && 
      (formClickedOnMenu.type !== 'sent' && (formClickedOnMenu.type !== 'citytemplate' || isCityUser))
    ) {
      saveReportRequest(formClickedOnMenu.type);
    }
  };

  public onClickDismiss = () => {
    const { isSent: isSentProp } = this.props;
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentProp || isSentQueryString;
    if (!isSent) {
      this.setState({
        showAlertModal: true
      })
      // const deleteConfirmation = window.confirm(
      //   'Are you sure you want to clear the notes?'
      // );
      // if (deleteConfirmation) {
      //   this.setState({ Notes: '' });
      //   setNote('');
      // }
    }
  };

  public onConfirmDeletion = () => {
    const { setNote } = this.props;
    this.setState({
      Notes: '',
      showAlertModal: false,
    })
    // setNote('');
  }

  public onClickCopy = () => {
    const { Notes } = this.state;
    if (!navigator.clipboard) {
      iosCopyToClipboard(document.getElementById('textAreaToCopy'));
      toast.info('Notes Copied to Clipboard');
      return;
    }
    navigator.clipboard.writeText(Notes);
    toast.info('Notes Copied to Clipboard');
  };

  public render() {
    const { isSent: isSentProp, showCancel = true } = this.props;
    const isSentQueryString = getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentQueryString;

    return (
      <div className='notes-modal-root-div'>
        <div className='notes-modal-content-div'>
          <div className='notes-modal-title-container'>
            <h3>Notes</h3>
          </div>
          <div className='text-area-container'>
            <textarea
              id='textAreaToCopy'
              ref={this.textArea}
              value={this.state.Notes}
              onChange={e => this.setState({ Notes: e.target.value })}
              disabled={isSent}
            />
          </div>
          <div className='actions-area-container'>
            {!isSent && <div className='action-container' onClick={this.onClickDismiss}>
              <i className='trash-icon' />
              <span>Clear</span>
            </div>}
            <div className='action-container' onClick={this.onClickCopy}>
              <i className='copy-icon' />
              <span>Copy</span>
            </div>
          </div>
        </div>
        {/* <div className="notes-modal-done-button">

          <button className="button" onClick={this.onClose}>
            DONE
          </button>
        </div> */}
        <div className='notes-modal-done-button'>
          {!isSent && showCancel && (
            <button
              className='button-light'
              onClick={() => this.props.setNotesModalState(false)}
            >
              CANCEL
            </button>
          )}
          <button className='button' onClick={this.onClose}>
            DONE
          </button>
        </div>

        <ActionAlertModal
          title=''
          message='Are you sure you want to clear the notes?'
          onConfirm={() => this.onConfirmDeletion()}
          // onDismiss={this.props.closeExitModal}
          onDismiss={() => {
            this.setState({
              showAlertModal: false,
            })
          }}
          isVisible={this.state.showAlertModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState, ownProps) => {
  return { 
    ...state.inspectForm,
  ...ownProps
  }
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(NotesModal);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as NotesModal };
