import React, { memo, useState, useEffect, useMemo } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Sentry from '@sentry/react';
import {
  ContentInput,
  useFormProps,
  Select,
  Accordion,
  SpecialNotes,
  IconButton,
} from 'core/components';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import { IBidCleaningSpecification } from 'modules/BidInfoForm/interfaces';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import {
  cleaningSpecificationValues,
  oneDayFacilityTypes,
  moreDaysWeeklyFacilityTypes,
  monthlyFacilityTypes,
} from 'modules/shared/bidDefaultValues';
import {
  oneDayTypesValues as oneDayTypes,
  weeklyTypesValues as weeklyTypes,
  monthlyTypesValues as monthlyTypes,
  dataKeysByType,
  additionalTypes,
  orderingItems,
  specialNotesDefault,
  disinfectionNightlyCleaning,
} from './constants';
import { Button, EditButton } from './styles';

import './styles.scss';

import { objectToArray, arrayToObject, toTitleCase } from 'core/utils';
import { Tooltip } from '../../../../../../core/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setTime } from '../../../../../../lib/react-datepicker/src/date_utils';

interface IAutocompleteData {
  keys: Array<string>;
  values: Array<string>;
}

const defaultAutocompleteData: IAutocompleteData = { keys: [], values: [] };

type IProps = FormikProps<IBidCleaningSpecification>;

const CleaningSpecification = memo(
  ({
    values,
    setFieldValue: fieldValueChange,
    setValues: setAllValues,
  }: IProps) => {
    const {
      formValues,
      onChange: providerOnChange,
      setSectionDirty,
      isSectionDirty,
      dirtySections,
      cleanSectionDirty,
      showDeleteConfirmationModal,
      showWarningModal,
      setFormValues,
    } = useFormProps();
    const {
      monthlyPorterPrice,
      facilityType,
      cleaningFrequency: cleaningFrequencyFormValues,
      frequencyService,
      frequency,
      disinfection,
      monthlyDisinfection,
      smartClean,
      secondFrequency,
      addFrequency,
      secondDisinfection,
    } = formValues;

    // const (secondDisinfection && addFrequency) = !!(
    //   formValues.(secondDisinfection && addFrequency) && formValues.addFrequency
    // );

    // const [(secondDisinfection && addFrequency), setSecondDisinfection] = useState(
    //   !!(formValues.(secondDisinfection && addFrequency) && addFrequency)
    // );

    // React.useEffect(() => {
    //   setSecondDisinfection(!!(formValues.(secondDisinfection && addFrequency) && addFrequency));
    // }, [formValues.(secondDisinfection && addFrequency), addFrequency]);

    // React.useEffect(() => {
    //   alert(addFrequency);
    // }, [addFrequency]);

    const { cleaningFrequency } = values;
    const { setError } = useGenericErrorHandler();
    sessionStorage.setItem(
      'sessionStorageCleaningSpecification',
      JSON.stringify(cleaningFrequency)
    );

    const bindValues = (values) => {
      if (values) {
        try {
          sessionStorage.setItem(
            'sessionStorageCleaningSpecification',
            JSON.stringify({
              ...JSON.parse(
                sessionStorage.getItem('sessionStorageCleaningSpecification')
              ),
              ...values,
            })
          );
        } catch (e) {
          setError(e);
        }
      }
    };

    const getBindedValues = () => {
      try {
        if (sessionStorage.getItem('sessionStorageCleaningSpecification')) {
          return JSON.parse(
            sessionStorage.getItem('sessionStorageCleaningSpecification')
          );
        } else {
          return null;
        }
      } catch (e) {
        setError(e);
      }
    };

    const getTitle = (item: any) =>
      item.mainTitle ? `${item.mainTitle} - ${item.subTitle}` : item.title;

    const [biggerThan768, setBiggerThan768] = React.useState(false);
    const [checkboxSelecteds, setCheckboxSelecteds] = React.useState([]);
    const [editState, setEditState] = React.useState(false);
    const fixedKeys = [];

    const removeSelecteds = () => {
      showDeleteConfirmationModal(() => {
        let v = getBindedValues();
        const removedIndexGroup = [];
        const removedIndexItems = [];
        const newCleaningSpecs = { ...v };
        const invalidState = [];
        for (var el of document
          .getElementsByClassName('cleaning-specification-content')[0]
          .getElementsByClassName('is-invalid')) {
          if (el) {
            invalidState.push(el.id);
            el.classList.remove('is-invalid');
          }
        }

        checkboxSelecteds
          .sort((prev, next) => {
            return next.split('_').length - prev.split('_').length;
          })
          .map((i) => {
            const values = i.split('_');
            //strip index and delete correctly
            if (values.length == 1) {
              if (!fixedKeys.includes(values[0].toLowerCase())) {
                delete newCleaningSpecs[values[0]];
              }
            } else if (values.length == 2) {
              //strip index and delete correctly
              const item = newCleaningSpecs[values[0]];
              const obj = values[1].split('#')[1];

              if (item) {
                // const idx = item.items.findIndex((i) => i.title == values[1] || !i.title)
                item.items.splice(obj[0], 1, undefined);
                removedIndexGroup.push({ key: item.title, idx: obj[0] });
              }
            } else if (values.length == 3) {
              //strip index and delete correctly
              const mainItem = newCleaningSpecs[values[0]];
              if (mainItem) {
                const parentIdx = values[1].split('#')[1];
                const item = mainItem.items[parentIdx];
                if (item) {
                  // const parentIdx = mainItem.items.findIndex((i) => i.title == values[1] || !i.title);

                  // const idx =  item.frequencyTypes.findIndex(
                  //   // (i) => i == values[2] || !i.title
                  //   (i) => i == values[2]
                  // );
                  const idx = values[2].split('#')[1];
                  item.frequencyTypes.splice(idx, 1, undefined);
                  removedIndexItems.push({
                    key: item.title,
                    parentTitle: item.title,
                    parentIdx,
                    idx,
                  });
                }
              }
            }
          });

        Object.keys(newCleaningSpecs).forEach((key) => {
          newCleaningSpecs[key].items = newCleaningSpecs[key].items.filter(
            (i) => i != undefined
          );
          newCleaningSpecs[key].items.forEach((item) => {
            item.frequencyTypes = item.frequencyTypes.filter(
              (ft) => ft != undefined
            );
          });
        });

        checkboxSelecteds.map((i) => {
          const values = i.split('_');
          if (values.length == 1) {
            if (fixedKeys.includes(values[0].toLowerCase())) {
              newCleaningSpecs[values[0]].items.push({
                title: '',
                frequencyTypes: [{ value: '', uid: Math.random() }],
              });
            }
          }
        });

        onChange('cleaningFrequency', getSortedFrequency(newCleaningSpecs));
        setCheckboxSelecteds([]);
        setAutoCompleteFilter('');
        setEditState(false);

        setTimeout(() => {
          for (var id of invalidState) {
            if (document.getElementById(id)) {
              document.getElementById(id).classList.add('is-invalid');
            }
          }
        });
        // console.log({ checkboxSelecteds });

        // removedIndexGroup.map(obj=>{
        //   let el = document.getElementById(`${obj.key}_${obj.idx}_item_header_id`);
        //   if(el){
        //     el.parentNode.classList.remove('is-invalid')
        //   }
        // })

        // removedIndexItems.map(obj=>{
        //   let el = document.getElementById(`${obj.key}_${obj.parentIdx}_item_header_id`);
        //   if(el){
        //     el.parentNode.classList.remove('is-invalid')
        //   }
        // })
      }, checkboxSelecteds.length > 1);
    };
    const setBreakpoints = () => {
      setBiggerThan768(window.innerWidth >= 769);
    };

    React.useEffect(() => {
      setBreakpoints();
    }, [window.innerWidth]);

    React.useLayoutEffect(() => {
      window.addEventListener('resize', setBreakpoints);
      setBreakpoints();
      return () => window.removeEventListener('resize', setBreakpoints);
    }, []);

    const [facilityTypes, setFacilityTypes] = useState(oneDayFacilityTypes);
    const [autoCompleteData, setAutoCompleteData] = useState(dataKeysByType());
    const [resetRender, setResetRender] = useState(true);
    const [additionalFrequency, setAdditionalFrequency] = useState('');
    const [daysSelecteds, setDaysSelecteds] = useState([]);
    const [frequencyServiceSelected, setFrequencyServiceSelected] =
      useState('');
    const [frequencySelectedChanged, setFrequencySelectedChanged] =
      useState(false);
    const [daysSelectedsState, setdaysSelectedsState] = useState('');
    const [daysSelectedsChanged, setDaysSelectedsChanged] = useState(false);
    const [autoCompleteFilter, setAutoCompleteFilter] = useState('');

    const [accordionState, setAccordionState] = useState({});

    const setAccState = (value: any) => {
      setAccordionState((prevState) => ({
        ...prevState,
        ...(value || {}),
      }));
    };

    const setValues = (obj) => {
      setAllValues({ ...obj });
      setFormValues({ ...obj });
    };

    const setFieldValue = (name, value) => {
      fieldValueChange(name, value);
      providerOnChange(name, value);
      if (name === 'cleaningFrequency') {
        bindValues(value);
      }
    };

    const onChange = (name: string, value: any) => {
      setSectionDirty('CleaningSpecification' + daysSelectedsState);

      if (
        disinfection ||
        (secondDisinfection && addFrequency) ||
        monthlyDisinfection
      ) {
        let oldDisinfectionArray = Object.keys(values.cleaningFrequency);
        let newDisinfectionArray = Object.keys(values.cleaningFrequency);

        if (frequencyService === 'monthly') {
          oldDisinfectionArray =
            values.cleaningFrequency[
              getKey('monthly', values.cleaningFrequency)
            ];
          newDisinfectionArray =
            value[getKey('monthly', values.cleaningFrequency)];
        } else if (daysSelecteds.length > 1) {
          oldDisinfectionArray =
            values.cleaningFrequency[
              getKey('nightly', values.cleaningFrequency)
            ];
          newDisinfectionArray =
            value[getKey('nightly', values.cleaningFrequency)];
        } else {
          oldDisinfectionArray =
            values.cleaningFrequency[
              getKey('weekly', values.cleaningFrequency)
            ];
          newDisinfectionArray =
            value[getKey('weekly', values.cleaningFrequency)];
        }

        if (
          !!oldDisinfectionArray &&
          oldDisinfectionArray.length > 0 &&
          !!newDisinfectionArray &&
          newDisinfectionArray.length > 0
        ) {
          const oldDisinfection = oldDisinfectionArray.filter(
            (item) => item.key === 'disinfection'
          );
          const newDisinfection = newDisinfectionArray.filter(
            (item) => item.key === 'disinfection'
          );

          if (
            JSON.stringify(oldDisinfection) !== JSON.stringify(newDisinfection)
          ) {
            setSectionDirty('CleaningSpecification_disinfection');
          }
        }
      }

      return setFieldValue(name, value);
      // return providerOnChange(name, value)
    };

    const dayPorterCleaningKey = 'Day Porter Cleaning';

    useEffect(() => {
      if (facilityType) {
        setFieldValue('facilityType', facilityType);
        if (frequencyService === 'monthly') {
          setFacilityTypes(monthlyFacilityTypes);
        } else if (
          frequency.selecteds.length > 1 ||
          (secondFrequency && secondFrequency.selecteds.length) ||
          (secondFrequency &&
            frequency.selecteds.length + secondFrequency.selecteds.length > 1)
        ) {
          setFacilityTypes(moreDaysWeeklyFacilityTypes);
        } else {
          setFacilityTypes(oneDayFacilityTypes);
        }
      }
      if (
        Object.keys(cleaningFrequencyFormValues).length > 0 &&
        JSON.stringify(values.cleaningFrequency) !=
          JSON.stringify(cleaningFrequencyFormValues)
      ) {
        setTimeout(() => {
          setFieldValue('cleaningFrequency', cleaningFrequencyFormValues);
          setFieldValue(
            'specialNotes',
            formValues.specialNotes || [
              {
                description: specialNotesDefault[facilityType],
                additionalNotes: [''],
              },
            ]
          );
        }, 0);
        // bindValues(cleaningFrequencyFormValues);
      }
    }, [facilityType, cleaningFrequencyFormValues, autoCompleteFilter]);

    useEffect(() => {
      if (
        JSON.stringify(values.specialNotes) !=
        JSON.stringify(formValues.specialNotes)
      ) {
        setFieldValue('specialNotes', formValues.specialNotes);
      }
    }, [formValues.specialNotes]);

    useEffect(() => {
      if (!values.facilityType && !facilityType) {
        setFieldValue('facilityType', 'General Office');
        if (frequencyService === 'monthly') {
          setFacilityTypes(monthlyFacilityTypes);
          // setAutoCompleteData(
          //   dataKeysByType(
          //     'monthly',
          //     values.cleaningFrequency[
          //       getKey(
          //         autoCompleteFilter || 'monthly',
          //         values.cleaningFrequency,
          //         'monthly'
          //       )
          //     ]
          //       ? values.cleaningFrequency[
          //           getKey(
          //             autoCompleteFilter || 'monthly',
          //             values.cleaningFrequency,
          //             'monthly'
          //           )
          //         ].items.map((item) => getTitle(item))
          //       : []
          //   )
          // );
          return;
        }
        if (daysSelecteds.length > 1) {
          setFacilityTypes(moreDaysWeeklyFacilityTypes);
          // setAutoCompleteData(
          //   dataKeysByType(
          //     'weekly',
          //     values.cleaningFrequency[
          //       getKey(
          //         autoCompleteFilter || 'weekly',
          //         values.cleaningFrequency,
          //         'weekly'
          //       )
          //     ]
          //       ? values.cleaningFrequency[
          //           getKey(
          //             autoCompleteFilter || 'weekly',
          //             values.cleaningFrequency,
          //             'weekly'
          //           )
          //         ].items.map((item) => getTitle(item))
          //       : []
          //   )
          // );
          return;
        }
        setFacilityTypes(oneDayFacilityTypes);
        // setAutoCompleteData(
        //   dataKeysByType(
        //     'oneDay',
        //     values.cleaningFrequency[
        //       getKey(
        //         autoCompleteFilter || 'weekly',
        //         values.cleaningFrequency,
        //         'weekly'
        //       )
        //     ]
        //       ? values.cleaningFrequency[
        //           getKey(
        //             autoCompleteFilter || 'weekly',
        //             values.cleaningFrequency,
        //             'weekly'
        //           )
        //         ].items.map((item) => getTitle(item))
        //       : []
        //   )
        // );
      }
    }, [values, facilityType, autoCompleteFilter]);

    useEffect(() => {
      if (frequencyService === 'monthly') {
        // setAutoCompleteData(
        //   dataKeysByType(
        //     'monthly',
        //     values.cleaningFrequency[
        //       getKey(
        //         autoCompleteFilter || 'monthly',
        //         values.cleaningFrequency,
        //         'monthly'
        //       )
        //     ]
        //       ? values.cleaningFrequency[
        //           getKey(
        //             autoCompleteFilter || 'monthly',
        //             values.cleaningFrequency,
        //             'monthly'
        //           )
        //         ].items.map((item) => getTitle(item))
        //       : []
        //   )
        // );
        return;
      }
      if (daysSelecteds.length > 1) {
        // setAutoCompleteData(
        //   dataKeysByType(
        //     'weekly',
        //     values.cleaningFrequency[getKey('weekly', values.cleaningFrequency)]
        //       ? values.cleaningFrequency[
        //           getKey(
        //             autoCompleteFilter || 'weekly',
        //             values.cleaningFrequency,
        //             'weekly'
        //           )
        //         ].items.map((item) => getTitle(item))
        //       : []
        //   )
        // );
        return;
      }
      // setAutoCompleteData(
      //   dataKeysByType(
      //     'oneDay',
      //     values.cleaningFrequency[getKey('weekly', values.cleaningFrequency)]
      //       ? values.cleaningFrequency[
      //           getKey(
      //             autoCompleteFilter || 'weekly',
      //             values.cleaningFrequency,
      //             'weekly'
      //           )
      //         ].items.map((item) => getTitle(item))
      //       : []
      //   )
      // );
    }, [values.cleaningFrequency, autoCompleteFilter]);

    useEffect(() => {
      let result = values.cleaningFrequency;

      if (monthlyPorterPrice > 0) {
        if (
          Object.keys(result).filter(
            (key) => key.toLowerCase() === dayPorterCleaningKey.toLowerCase()
          ).length === 0
        ) {
          result = {
            ...result,
            [dayPorterCleaningKey]: {
              items: [
                {
                  title: 'General Facility',
                  frequencyTypes: [
                    {
                      value:
                        'The above specifications serve as a general guide as to what is expected of the Day Porter services. Final Order of duties and frequencies to be determined by Client and Anago.',
                      uid: Math.random(),
                    },
                    {
                      value:
                        'Report any property damages / security issues to Manager on duty.',
                      uid: Math.random(),
                    },
                  ],
                },
              ],
              title: dayPorterCleaningKey,
            },
          };
          setFieldValue('cleaningFrequency', result);
          // bindValues(result);
        }
      } else {
        if (
          Object.keys(result).filter((key) => key === dayPorterCleaningKey)
            .length > 0
        ) {
          result = Object.keys(result).reduce((acc, item) => {
            if (item !== dayPorterCleaningKey) {
              acc[item] = result[item];
            }
            return acc;
          }, {});
          setFieldValue('cleaningFrequency', result);
          // bindValues(result);
        }
      }
    }, [monthlyPorterPrice]);

    const additionalFrequencies = useMemo(() => {
      const result = additionalTypes.filter(
        (item: string) =>
          !Object.keys(cleaningFrequency)
            .map((i) => i.toLowerCase())
            .includes(item.toLowerCase())
      );
      setAdditionalFrequency(result[0] || '');
      return result;
    }, [cleaningFrequency]);

    useEffect(() => {
      if (frequency) {
        if (smartClean) {
          const newDaysSelecteds = [
            ...frequency.selecteds,
            ...secondFrequency.selecteds,
          ];
          if (
            // JSON.stringify(daysSelecteds) != JSON.stringify(newDaysSelecteds)
            (daysSelecteds.length >= 2 && newDaysSelecteds.length < 2) ||
            (daysSelecteds.length < 2 && newDaysSelecteds.length >= 2)
          ) {
            setDaysSelectedsChanged(true);
          } else {
            setDaysSelectedsChanged(false);
          }
          setDaysSelecteds(newDaysSelecteds);
          setdaysSelectedsState('_smartClean');
        } else if (secondFrequency) {
          if (
            secondFrequency.selecteds != null &&
            secondFrequency.selecteds.length > frequency.selecteds.length
          ) {
            if (
              // JSON.stringify(daysSelecteds) !=
              // JSON.stringify(secondFrequency.selecteds)
              (daysSelecteds.length >= 2 &&
                secondFrequency.selecteds.length < 2) ||
              (daysSelecteds.length < 2 &&
                secondFrequency.selecteds.length >= 2)
            ) {
              setDaysSelectedsChanged(true);
            } else {
              setDaysSelectedsChanged(false);
            }
            setDaysSelecteds(secondFrequency.selecteds);
            setdaysSelectedsState('_secondFrequency');
          } else {
            if (
              // JSON.stringify(daysSelecteds) !=
              // JSON.stringify(frequency.selecteds)
              (daysSelecteds.length >= 2 && frequency.selecteds.length < 2) ||
              (daysSelecteds.length < 2 && frequency.selecteds.length >= 2)
            ) {
              setDaysSelectedsChanged(true);
            } else {
              setDaysSelectedsChanged(false);
            }
            setDaysSelecteds(frequency.selecteds);
            setdaysSelectedsState('_firstFrequency');
          }
        } else {
          if (
            // JSON.stringify(daysSelecteds) != JSON.stringify(frequency.selecteds)
            (daysSelecteds.length >= 2 && frequency.selecteds.length < 2) ||
            (daysSelecteds.length < 2 && frequency.selecteds.length >= 2)
          ) {
            setDaysSelectedsChanged(true);
          } else {
            setDaysSelectedsChanged(false);
          }
          setDaysSelecteds(frequency.selecteds);
          setdaysSelectedsState('_firstFrequency');
        }

        if (frequencyServiceSelected != frequencyService) {
          setFrequencyServiceSelected(frequencyService);
          setFrequencySelectedChanged(true);
        } else {
          setFrequencySelectedChanged(false);
        }
      }
    }, [frequency, frequencyService, secondFrequency, smartClean]);

    const disinfectionDifferentFilterFunc = (item) =>
      item.key !== disinfectionNightlyCleaning.key;

    const onSelectFacility = (name: string, value: any) => {
      try {
        const proceed = () => {
          setFieldValue(name, value);
          setResetRender(!resetRender);

          var stopTheFlow;
          if (frequencyService === 'monthly') {
            const cf = JSON.parse(JSON.stringify(monthlyTypes[value]));

            if (monthlyDisinfection && getDisinfectState('monthly', cf)) {
              if (cf[getKey('nightly', cf)]) {
                cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              if (formValues.optionProtection == null) {
                //default option(old bids)
                cf[getKey('monthly', cf)].push(disinfectionNightlyCleaning);
              } else {
                if (
                  cf[getKey(formValues.optionProtection, cf)].filter(
                    (e) => e.key == 'disinfection'
                  ).length == 0
                ) {
                  //prevent duplicates
                  cf[getKey(formValues.optionProtection, cf)].push(
                    disinfectionNightlyCleaning
                  );
                }
              }
            } else if (
              !monthlyDisinfection &&
              !getDisinfectState('monthly', cf, stopTheFlow)
            ) {
              cf[getKey('monthly', cf)] = cf[getKey('monthly', cf)].filter(
                disinfectionDifferentFilterFunc
              );
            }

            const newCf = {};

            Object.keys(cf).map((i) => {
              newCf[i] = {
                items: cf[i],
                title: i,
              };
            });

            if (values.cleaningFrequency[dayPorterCleaningKey]) {
              setFieldValue('cleaningFrequency', {
                ...(newCf || {}),
                [dayPorterCleaningKey]:
                  values.cleaningFrequency[dayPorterCleaningKey],
              });
            } else {
              setFieldValue('cleaningFrequency', {
                ...(newCf || {}),
              });
            }

            setFieldValue(
              'specialNotes',
              formValues.specialNotes || [
                {
                  description: specialNotesDefault[value],
                  additionalNotes: [''],
                },
              ]
            );
            return;
          }

          if (daysSelecteds.length > 1) {
            const cf = JSON.parse(JSON.stringify(weeklyTypes[value]));

            if (
              (disinfection || (secondDisinfection && addFrequency)) &&
              getDisinfectState('nightly', cf, stopTheFlow)
            ) {
              if (cf[getKey('weekly', cf)]) {
                cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }
              if (formValues.optionProtection == null) {
                //default option(old bids)
                cf[getKey('nightly', cf)].push(disinfectionNightlyCleaning);
              } else {
                if (
                  cf[getKey(formValues.optionProtection, cf)].filter(
                    (e) => e.key == 'disinfection'
                  ).length == 0
                ) {
                  //prevent duplicates
                  cf[getKey(formValues.optionProtection, cf)].push(
                    disinfectionNightlyCleaning
                  );
                }
              }
            } else if (
              !disinfection &&
              (!secondDisinfection || !addFrequency) &&
              !getDisinfectState('nightly', cf, stopTheFlow)
            ) {
              cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                disinfectionDifferentFilterFunc
              );
            }

            try {
              if (stopTheFlow.stop == true) {
                return;
              }
            } catch (e) {
              console.log('stopTheFlow ex');
              Sentry.captureException(e);
            }

            const newCf = {};

            Object.keys(cf).map((i) => {
              newCf[i] = {
                items: cf[i],
                title: i,
              };
            });

            if (values.cleaningFrequency[dayPorterCleaningKey]) {
              setFieldValue('cleaningFrequency', {
                ...(newCf || {}),
                [dayPorterCleaningKey]:
                  values.cleaningFrequency[dayPorterCleaningKey],
              });
            } else {
              setFieldValue('cleaningFrequency', {
                ...(newCf || {}),
              });
            }

            setFieldValue(
              'specialNotes',
              formValues.specialNotes || [
                {
                  description: specialNotesDefault[value],
                  additionalNotes: [''],
                },
              ]
            );
            // setFormValues({
            //   cleaningFrequency: { ...(weeklyTypes[value] || {}) },
            //   specialNotes: [
            //     { description: specialNotesDefault[value], additionalNotes: [""] }
            //   ]
            // });
            return;
          }

          const cf = JSON.parse(JSON.stringify(oneDayTypes[value]));

          if (
            (disinfection || (secondDisinfection && addFrequency)) &&
            getDisinfectState('weekly', cf, stopTheFlow)
          ) {
            if (cf[getKey('nightly', cf)]) {
              cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                disinfectionDifferentFilterFunc
              );
            }

            if (formValues.optionProtection == null) {
              //default option(old bids)
              cf[getKey('weekly', cf)].push(disinfectionNightlyCleaning);
            } else {
              if (
                cf[getKey(formValues.optionProtection, cf)].filter(
                  (e) => e.key == 'disinfection'
                ).length == 0
              ) {
                //prevent duplicates
                cf[getKey(formValues.optionProtection, cf)].push(
                  disinfectionNightlyCleaning
                );
              }
            }
          } else if (
            !disinfection &&
            (!secondDisinfection || !addFrequency) &&
            !getDisinfectState('weekly', cf, stopTheFlow)
          ) {
            cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
              disinfectionDifferentFilterFunc
            );
          }

          const newCf = {};

          Object.keys(cf).map((i) => {
            newCf[i] = {
              items: cf[i],
              title: i,
            };
          });

          if (values.cleaningFrequency[dayPorterCleaningKey]) {
            setFieldValue('cleaningFrequency', {
              ...(newCf || {}),
              [dayPorterCleaningKey]:
                values.cleaningFrequency[dayPorterCleaningKey],
            });
          } else {
            setFieldValue('cleaningFrequency', {
              ...(newCf || {}),
            });
          }
          setFieldValue(
            'specialNotes',
            formValues.specialNotes || [
              {
                description: specialNotesDefault[value],
                additionalNotes: [''],
              },
            ]
          );
          return;
        };

        if (isSectionDirty('CleaningSpecification' + daysSelectedsState)) {
          showWarningModal({
            message:
              'If you proceed with this action you will lost the current changes in this Section. Do you want to continue? ',
            proceed: () => {
              cleanSectionDirty(
                [
                  'CleaningSpecification' + daysSelectedsState,
                  'CleaningSpecification_disinfection',
                ],
                () => {
                  proceed();
                  showWarningModal(null);
                }
              );
            },
            dismiss: () => showWarningModal(null),
          });
        } else {
          proceed();
        }
      } catch (e) {
        setError(e);
      }
    };

    const onRemoveItem = (key: string) => () => {
      showDeleteConfirmationModal(() => {
        const frequency = values.cleaningFrequency;
        delete frequency[key];

        onChange('cleaningFrequency', getSortedFrequency(frequency));
      });
    };

    const onRemoveData = (key: string) => (index: number) => {
      showDeleteConfirmationModal(() => {
        const invalidState = [];
        for (var el of document
          .getElementsByClassName('cleaning-specification-content')[0]
          .getElementsByClassName('is-invalid')) {
          if (el) {
            invalidState.push(el.id);
            el.classList.remove('is-invalid');
          }
        }

        const result = {
          ...values.cleaningFrequency,
          [key]: {
            ...values.cleaningFrequency[key],
            items: values.cleaningFrequency[key].items.filter(
              (item: any, dataIndex: number) => dataIndex !== index
            ),
            title: values.cleaningFrequency[key].title,
          },
        };
        onChange('cleaningFrequency', getSortedFrequency(result));

        setTimeout(() => {
          for (var id of invalidState) {
            if (document.getElementById(id)) {
              document.getElementById(id).classList.add('is-invalid');
            }
          }
        });
      });
    };

    const onRemoveTypeValue =
      (key: string) => (index: number, valueIndex: number) => {
        showDeleteConfirmationModal(() => {
          const frequencyTypes = values.cleaningFrequency[key].items[
            index
          ].frequencyTypes.filter(
            (item: any, dataIndex: number) => valueIndex !== dataIndex
          );

          const invalidState = [];
          for (var el of document
            .getElementsByClassName('cleaning-specification-content')[0]
            .getElementsByClassName('is-invalid')) {
            if (el) {
              invalidState.push(el.id);
              el.classList.remove('is-invalid');
            }
          }

          const result = values.cleaningFrequency[key].items.filter(
            (item: any, dataIndex: number) => dataIndex !== index
          );
          result.splice(index, 0, {
            ...values.cleaningFrequency[key].items[index],
            frequencyTypes,
          });

          onChange(
            'cleaningFrequency',
            getSortedFrequency({
              ...values.cleaningFrequency,
              [key]: {
                items: [...result],
                title: values.cleaningFrequency[key].title,
              },
            })
          );

          setTimeout(() => {
            for (var id of invalidState) {
              if (document.getElementById(id)) {
                document.getElementById(id).classList.add('is-invalid');
              }
            }
          });
        });
      };

    const getSortedFrequency = (values) => {
      const arrayToSort = objectToArray(values);

      const refArray = orderingItems.map((item) => item.toLowerCase());

      arrayToSort.sort((a: Array<any>, b: Array<any>) => {
        return (
          (refArray.indexOf(a[0].toLowerCase()) >= 0
            ? refArray.indexOf(a[0].toLowerCase())
            : refArray.length) -
          (refArray.indexOf(b[0].toLowerCase()) >= 0
            ? refArray.indexOf(b[0].toLowerCase())
            : refArray.indexOf(a[0].toLowerCase()) + 1)
        );
      });

      return arrayToObject(arrayToSort);
    };

    const onAddFrequency = (newValue) => {
      setAdditionalFrequency(newValue);
      const cleaningFrequency = {
        ...values.cleaningFrequency,
        [newValue.toLowerCase()]: {
          items: [
            {
              title: '',
              frequencyTypes: [{ value: '', uid: Math.random() }],
            },
          ],
          title: toTitleCase(newValue),
        },
      };

      setAutoCompleteFilter(newValue.toLowerCase());

      onChange('cleaningFrequency', getSortedFrequency(cleaningFrequency));
    };

    const onAddData = (key: string) => () => {
      let v = getBindedValues();
      if (!v) {
        v = cleaningFrequency;
      }

      const frequency = {
        ...v,
        [key]: {
          ...v[key],
          items: [
            ...v[key].items,
            {
              title: '',
              frequencyTypes: [{ value: '', uid: Math.random() }],
              uid: Math.random(),
            },
          ],
        },
      };

      onChange('cleaningFrequency', getSortedFrequency(frequency));
    };

    const onAddValue = (key: string) => (dataIndex: number) => {
      // const data = values.cleaningFrequency[key].items;
      const v = getBindedValues() || values.cleaningFrequency;
      const data = v[key].items;
      data[dataIndex].frequencyTypes.push({ value: '', uid: Math.random() });
      const frequency = {
        ...v,
        [key]: {
          ...v[key],
          items: data,
        },
      };
      onChange('cleaningFrequency', getSortedFrequency(frequency));
    };

    const getFacilityType = (
      facilityType: string,
      array: Array<string>,
      defaultIndex: number = 0
    ) => {
      if (array.includes(facilityType)) {
        return array[array.indexOf(facilityType)];
      }
      return array[defaultIndex];
    };

    const getKey = (title: string, array: any, key: string = '') => {
      if (key == '') {
        key = title;
      }
      if (array && (title || key)) {
        return Object.keys(array).filter(
          (k) =>
            k.toLowerCase() == title.toLowerCase() ||
            k.toLowerCase() == key.toLowerCase()
        )[0];
      }
      return key;
    };

    //stoptheflow =  a JS object, so we can change like "by ref" and allow this method return 1 more output
    const getDisinfectState = (key: string, array: any, stopTheFlow) => {
      try {
        return (
          array[getKey(key, array)].findIndex(
            (item) => item['key'] === disinfectionNightlyCleaning.key
          ) == -1
        );
      } catch (e) {
        var mens = '';

        if (key == 'nightly') {
          //disable both
          mens =
            'In order to add Disinfection you must have a Nightly Frequency in the Cleaning Specifications.';

          formValues.disinfection = false;
          formValues.secondDisinfection = false;
        } else if (key == 'weekly') {
          mens =
            'In order to add Disinfection you must have a Weekly Frequency in the Cleaning Specifications.';
          formValues.disinfection = false;
          formValues.secondDisinfection = false;
        } else if (key == 'monthly') {
          mens =
            'In order to add Disinfection you must have a Monthly Frequency in the Cleaning Specifications.';
          formValues.monthlyDisinfection = false;
          formValues.secondMonthlyDisinfection = false;
        }

        showWarningModal({
          message: mens,
          proceed: () => {
            showWarningModal(null);
          },
        });

        stopTheFlow.stop = true;
        Sentry.captureException(e);
        return;
      }
    };

    useEffect(() => {
      try {
        if (
          (isSectionDirty('AccountInformation') &&
            (daysSelectedsChanged || frequencySelectedChanged)) ||
          resetRender
        ) {
          const proceed = () => {
            setResetRender(!resetRender);
            var stopTheFlow = {};
            let dayPorterRow = {};
            if (monthlyPorterPrice > 0) {
              dayPorterRow = values.cleaningFrequency[dayPorterCleaningKey]
                ? {
                    [dayPorterCleaningKey]:
                      values.cleaningFrequency[dayPorterCleaningKey],
                  }
                : {};
            }

            if (frequencyService === 'monthly') {
              const facilityType = getFacilityType(
                values.facilityType,
                monthlyFacilityTypes
              );
              setFacilityTypes(monthlyFacilityTypes);

              const cf = JSON.parse(JSON.stringify(monthlyTypes[facilityType]));

              if (
                monthlyDisinfection &&
                getDisinfectState('monthly', cf, stopTheFlow)
              ) {
                if (cf[getKey('weekly', cf)]) {
                  cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                    disinfectionDifferentFilterFunc
                  );
                }
                if (cf[getKey('nightly', cf)]) {
                  cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                    disinfectionDifferentFilterFunc
                  );
                }

                if (formValues.optionProtection == null) {
                  //default option(old bids)
                  cf[getKey('monthly', cf)].push(disinfectionNightlyCleaning);
                } else {
                  if (
                    cf[getKey(formValues.optionProtection, cf)].filter(
                      (e) => e.key == 'disinfection'
                    ).length == 0
                  ) {
                    //prevent duplicates
                    cf[getKey(formValues.optionProtection, cf)].push(
                      disinfectionNightlyCleaning
                    );
                  }
                }
              } else if (
                !monthlyDisinfection &&
                !getDisinfectState('monthly', cf, stopTheFlow)
              ) {
                cf[getKey('monthly', cf)] = cf[getKey('monthly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              try {
                if (stopTheFlow.stop == false) {
                  return;
                }
              } catch (e) {
                console.log('stopTheFlow ex');
                Sentry.captureException(e);
              }
              const newCf = {};

              Object.keys(cf).map((i) => {
                newCf[i] = {
                  items: cf[i],
                  title: i,
                };
              });

              setValues({
                ...values,
                facilityType: facilityType,
                specialNotes: [
                  {
                    description: specialNotesDefault[facilityType],
                    additionalNotes: [''],
                  },
                ],
                cleaningFrequency: {
                  ...newCf,
                  ...dayPorterRow,
                },
              });

              // setAutoCompleteData(
              //   dataKeysByType(
              //     'monthly',
              //     values.cleaningFrequency[
              //       getKey(
              //         autoCompleteFilter || 'monthly',
              //         values.cleaningFrequency,
              //         'monthly'
              //       )
              //     ]
              //       ? values.cleaningFrequency[
              //           getKey(
              //             autoCompleteFilter || 'monthly',
              //             values.cleaningFrequency,
              //             'monthly'
              //           )
              //         ].items.map((item) => getTitle(item))
              //       : []
              //   )
              // );
              return;
            }
            if (daysSelecteds.length > 1) {
              const facilityType = getFacilityType(
                values.facilityType,
                moreDaysWeeklyFacilityTypes,
                8
              );

              setFacilityTypes(moreDaysWeeklyFacilityTypes);

              const cf = JSON.parse(JSON.stringify(weeklyTypes[facilityType]));

              if (
                (disinfection || (secondDisinfection && addFrequency)) &&
                getDisinfectState('nightly', cf, stopTheFlow)
              ) {
                if (cf[getKey('weekly', cf)]) {
                  cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                    disinfectionDifferentFilterFunc
                  );
                }

                if (formValues.optionProtection == null) {
                  //default option(old bids)
                  cf[getKey('nightly', cf)].push(disinfectionNightlyCleaning);
                } else {
                  if (
                    cf[getKey(formValues.optionProtection, cf)].filter(
                      (e) => e.key == 'disinfection'
                    ).length == 0
                  ) {
                    //prevent duplicates
                    cf[getKey(formValues.optionProtection, cf)].push(
                      disinfectionNightlyCleaning
                    );
                  }
                }
              } else if (
                !disinfection &&
                (!secondDisinfection || !addFrequency) &&
                !getDisinfectState('nightly', cf, stopTheFlow)
              ) {
                cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              try {
                if (stopTheFlow.stop == true) {
                  return;
                }
              } catch (e) {
                console.log('stopTheFlow ex');
                Sentry.captureException(e);
              }

              const newCf = {};

              Object.keys(cf).map((i) => {
                newCf[i] = {
                  items: cf[i],
                  title: i,
                };
              });

              setValues({
                ...values,
                facilityType: facilityType,
                specialNotes: [
                  {
                    description: specialNotesDefault[facilityType],
                    additionalNotes: [''],
                  },
                ],
                cleaningFrequency: {
                  ...newCf,
                  ...dayPorterRow,
                },
              });

              // setAutoCompleteData(
              //   dataKeysByType(
              //     'weekly',
              //     values.cleaningFrequency[
              //       getKey(
              //         autoCompleteFilter || 'weekly',
              //         values.cleaningFrequency,
              //         'weekly'
              //       )
              //     ]
              //       ? values.cleaningFrequency[
              //           getKey(
              //             autoCompleteFilter || 'weekly',
              //             values.cleaningFrequency,
              //             'weekly'
              //           )
              //         ].items.map((item) => getTitle(item))
              //       : []
              //   )
              // );

              return;
            }
            const facilityType = getFacilityType(
              values.facilityType,
              oneDayFacilityTypes,
              9
            );

            setFacilityTypes(oneDayFacilityTypes);

            const cf = JSON.parse(JSON.stringify(oneDayTypes[facilityType]));

            if (
              (disinfection || (secondDisinfection && addFrequency)) &&
              getDisinfectState('weekly', cf, stopTheFlow)
            ) {
              if (cf[getKey('nightly', cf)]) {
                cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              if (formValues.optionProtection == null) {
                //default option(old bids)
                cf[getKey('weekly', cf)].push(disinfectionNightlyCleaning);
              } else {
                if (
                  cf[getKey(formValues.optionProtection, cf)] &&
                  cf[getKey(formValues.optionProtection, cf)].filter(
                    (e) => e.key == 'disinfection'
                  ).length == 0
                ) {
                  //prevent duplicates
                  cf[getKey(formValues.optionProtection, cf)].push(
                    disinfectionNightlyCleaning
                  );
                }
              }
            } else if (
              !disinfection &&
              (!secondDisinfection || !addFrequency) &&
              !getDisinfectState('weekly', cf, stopTheFlow)
            ) {
              cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                disinfectionDifferentFilterFunc
              );
            }

            try {
              if (stopTheFlow.stop == true) {
                return;
              }
            } catch (e) {
              console.log('stopTheFlow ex');
              Sentry.captureException(e);
            }

            const newCf = {};

            Object.keys(cf).map((i) => {
              newCf[i] = {
                items: cf[i],
                title: i,
              };
            });

            setValues({
              ...values,
              facilityType: facilityType,
              specialNotes: [
                {
                  description: specialNotesDefault[facilityType],
                  additionalNotes: [''],
                },
              ],
              cleaningFrequency: {
                ...newCf,
                ...dayPorterRow,
              },
            });

            // setAutoCompleteData(
            //   dataKeysByType(
            //     'oneDay',
            //     values.cleaningFrequency[
            //       getKey(
            //         autoCompleteFilter || 'weekly',
            //         values.cleaningFrequency,
            //         'weekly'
            //       )
            //     ]
            //       ? values.cleaningFrequency[
            //           getKey(
            //             autoCompleteFilter || 'weekly',
            //             values.cleaningFrequency,
            //             'weekly'
            //           )
            //         ].items.map((item) => getTitle(item))
            //       : []
            //   )
            // );
          };

          cleanSectionDirty(
            [
              'CleaningSpecification_smartClean',
              'CleaningSpecification_firstFrequency',
              'CleaningSpecification_secondFrequency',
              'CleaningSpecification_disinfection',
              'AccountInformation',
            ],
            () => proceed()
          );
        } else if (isSectionDirty('AccountInformation_disinfection')) {
          const proceed = () => {
            // setResetRender(!resetRender);

            var stopTheFlow = {};

            if (frequencyService === 'monthly') {
              const cf = values.cleaningFrequency;
              if (
                monthlyDisinfection &&
                getDisinfectState('monthly', cf, stopTheFlow)
              ) {
                if (cf[getKey('weekly', cf)]) {
                  cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                    disinfectionDifferentFilterFunc
                  );
                }
                if (cf[getKey('nightly', cf)]) {
                  cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                    disinfectionDifferentFilterFunc
                  );
                }

                if (formValues.optionProtection == null) {
                  //default option(old bids)
                  cf[getKey('monthly', cf)].push(disinfectionNightlyCleaning);
                } else {
                  if (
                    cf[getKey(formValues.optionProtection, cf)] &&
                    cf[getKey(formValues.optionProtection, cf)].filter(
                      (e) => e.key == 'disinfection'
                    ).length == 0
                  ) {
                    //prevent duplicates
                    cf[getKey(formValues.optionProtection, cf)].push(
                      disinfectionNightlyCleaning
                    );
                  }
                }
              } else if (
                !monthlyDisinfection &&
                !getDisinfectState('monthly', cf, stopTheFlow)
              ) {
                try {
                  if (stopTheFlow.stop == true) {
                    return;
                  }
                } catch (e) {
                  console.log('stopTheFlow ex');
                  Sentry.captureException(e);
                }
                cf[getKey('monthly', cf)] = cf[getKey('monthly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              try {
                if (stopTheFlow.stop == true) {
                  return;
                }
              } catch (e) {
                console.log('stopTheFlow ex');
                Sentry.captureException(e);
              }

              if (formValues.optionProtection != null && !monthlyDisinfection) {
                //default option(old bids)
                cf[getKey(formValues.optionProtection, cf)] = cf[
                  getKey(formValues.optionProtection, cf)
                ].filter(disinfectionDifferentFilterFunc);
                formValues.optionProtection = null;
              }

              const newCf = {};

              Object.keys(cf).map((i) => {
                newCf[i] = {
                  items: cf[i],
                  title: i,
                };
              });

              setValues({
                ...values,
                cleaningFrequency: {
                  ...values.cleaningFrequency,
                  ...newCf,
                },
              });

              return;
            }
            if (daysSelecteds.length > 1) {
              var stopTheFlow = {};
              const cf = values.cleaningFrequency;

              if (
                (disinfection || (secondDisinfection && addFrequency)) &&
                getDisinfectState('nightly', cf, stopTheFlow)
              ) {
                if (cf[getKey('weekly', cf)]) {
                  cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                    disinfectionDifferentFilterFunc
                  );
                }

                if (formValues.optionProtection == null) {
                  //default option(old bids)
                  cf[getKey('nightly', cf)].push(disinfectionNightlyCleaning);
                } else {
                  if (
                    cf[getKey(formValues.optionProtection, cf)].filter(
                      (e) => e.key == 'disinfection'
                    ).length == 0
                  ) {
                    //prevent duplicates
                    cf[getKey(formValues.optionProtection, cf)].push(
                      disinfectionNightlyCleaning
                    );
                  }
                }
              } else if (
                !disinfection &&
                (!secondDisinfection || !addFrequency) &&
                !getDisinfectState('nightly', cf, stopTheFlow)
              ) {
                cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              try {
                if (stopTheFlow.stop == true) {
                  return;
                }
              } catch (e) {
                console.log('stopTheFlow ex');
                Sentry.captureException(e);
              }

              if (
                formValues.optionProtection != null &&
                !disinfection &&
                (!secondDisinfection || !addFrequency)
              ) {
                //default option(old bids)
                cf[getKey(formValues.optionProtection, cf)] = cf[
                  getKey(formValues.optionProtection, cf)
                ].filter(disinfectionDifferentFilterFunc);
                formValues.optionProtection = null;
              }

              const newCf = {};

              Object.keys(cf).map((i) => {
                newCf[i] = {
                  items: cf[i],
                  title: i,
                };
              });

              setValues({
                ...values,
                cleaningFrequency: {
                  ...values.cleaningFrequency,
                  ...newCf,
                },
              });

              return;
            }

            const cf = values.cleaningFrequency;

            if (
              formValues.optionProtection != null &&
              disinfection == false &&
              (secondDisinfection && addFrequency) == false
            ) {
              //default option(old bids)
              cf[getKey(formValues.optionProtection, cf)] = cf[
                getKey(formValues.optionProtection, cf)
              ].filter(disinfectionDifferentFilterFunc);
              formValues.optionProtection = null;
            }

            if (
              (disinfection || (secondDisinfection && addFrequency)) &&
              getDisinfectState('weekly', cf, stopTheFlow)
            ) {
              if (cf[getKey('nightly', cf)]) {
                cf[getKey('nightly', cf)] = cf[getKey('nightly', cf)].filter(
                  disinfectionDifferentFilterFunc
                );
              }

              if (formValues.optionProtection == null) {
                //default option(old bids)
                cf[getKey('weekly', cf)].push(disinfectionNightlyCleaning);
              } else {
                //prevent duplicates
                if (
                  cf[getKey(formValues.optionProtection, cf)].filter(
                    (e) => e.key == 'disinfection'
                  ).length == 0
                ) {
                  cf[getKey(formValues.optionProtection, cf)].push(
                    disinfectionNightlyCleaning
                  );
                }
              }
            } else if (
              !disinfection &&
              (!secondDisinfection || !addFrequency) &&
              !getDisinfectState('weekly', cf, stopTheFlow)
            ) {
              cf[getKey('weekly', cf)] = cf[getKey('weekly', cf)].filter(
                disinfectionDifferentFilterFunc
              );
            }

            try {
              if (stopTheFlow.stop == true) {
                return;
              }
            } catch (e) {
              console.log('stopTheFlow ex');
              Sentry.captureException(e);
            }

            if (
              formValues.optionProtection != null &&
              disinfection == false &&
              (secondDisinfection && addFrequency) == false
            ) {
              //default option(old bids)
              cf[getKey(formValues.optionProtection, cf)] = cf[
                getKey(formValues.optionProtection, cf)
              ].filter(disinfectionDifferentFilterFunc);

              formValues.optionProtection = null;
            }

            const newCf = {};

            Object.keys(cf).map((i) => {
              newCf[i] = {
                items: cf[i],
                title: i,
              };
            });

            setValues({
              ...values,
              cleaningFrequency: {
                ...values.cleaningFrequency,
                ...newCf,
              },
            });
          };

          cleanSectionDirty(
            [
              'CleaningSpecification_disinfection',
              'AccountInformation_disinfection',
            ],
            () => proceed()
          );
        }
      } catch (e) {
        setError(e);
      }
      // eslint-disable-next-line
    }, [
      frequencyService,
      daysSelecteds,
      isSectionDirty,
      dirtySections,
      disinfection,
      secondDisinfection,
      addFrequency,
      monthlyDisinfection,
      autoCompleteFilter,
    ]);

    const onDragAreasEnd = (title, result) => {
      let v = getBindedValues();
      if (!v) {
        v = values.cleaningFrequency;
      }

      // const newValues = {
      //   ...v,
      //   [title]: result,
      // };

      const newValues = {
        ...v,
        [title]: {
          ...v[title],
          items: [...result],
        },
      };

      onChange('cleaningFrequency', getSortedFrequency(newValues));
    };

    return (
      <div className="cleaning-specification-root">
        <div className="cleaning-specification-content">
          <ContentInput width="100%" padding="10px 0px">
            <h3 className="cleaning-specification-title">
              Cleaning Specifications
            </h3>
          </ContentInput>
          <div className="cleaning-header" style={{ position: 'relative' }}>
            <div className="cleaning-label">Facility Type</div>
            <Select
              name="facilityType"
              value={values.facilityType}
              onChange={onSelectFacility}
              items={facilityTypes}
              disableInput={true}
              canTypeCustomValue={false}
              width={biggerThan768 ? '300px' : '280px'}
              disabled={formValues.status === 2}
            />
            <div
              style={{ position: 'absolute', right: '72px', display: 'flex' }}
            >
              {!editState && (
                <>
                  <Tooltip
                    overlay={
                      <span
                        style={{
                          fontSize: '13px',
                          display: 'block',
                          textAlign: 'left',
                        }}
                      >
                        To delete multiple specs, areas, or frequencies, use the
                        Edit button to initiate the Multi-Delete tool.
                      </span>
                    }
                    trigger="click"
                    placement="topLeft"
                    alignedItemId="multiDeleteInfoIcon"
                    positioningFunction={(node, align) => {
                      const targetEl = document.getElementById(
                        'multiDeleteInfoIcon'
                      );
                      const rect = targetEl.getBoundingClientRect();
                      node.style.left = `${rect.left - 280}px`;
                    }}
                  >
                    <div
                      className="cleaning-specification-button-tooltip"
                      id="multiDeleteInfoIcon"
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        size="xs"
                        color="#216eab"
                      />
                    </div>
                  </Tooltip>

                  <EditButton
                    tabIndex={-1}
                    onClick={() => setEditState(true)}
                    className={`quote-add-button`}
                  >
                    Edit
                  </EditButton>
                </>
              )}
              {editState && (
                <>
                  <EditButton
                    tabIndex={-1}
                    onClick={() => {
                      setCheckboxSelecteds([]);
                      setEditState(false);
                    }}
                    className={`quote-add-button`}
                    style={{
                      backgroundColor: '#216eab',
                      marginRight: '15px',
                    }}
                  >
                    Cancel
                  </EditButton>
                  <EditButton
                    tabIndex={-1}
                    onClick={() => removeSelecteds()}
                    className={`quote-add-button`}
                    style={{
                      backgroundColor: '#CC181F',
                    }}
                  >
                    Delete
                  </EditButton>
                </>
              )}
            </div>
          </div>
          {Object.keys(values.cleaningFrequency).map(
            (key: string, index: number) => {
              return (
                <div
                  className="accordion-content"
                  id={key}
                  key={`${key}_${index}`}
                >
                  <Accordion
                    multiDelete
                    editState={editState}
                    addSelectedOption={(options) => {
                      // alert(options);
                      setCheckboxSelecteds([...checkboxSelecteds, ...options]);
                    }}
                    removeSelectedOptions={(options) => {
                      setCheckboxSelecteds(
                        checkboxSelecteds.filter(
                          (item) => !options.includes(item)
                        )
                      );
                    }}
                    selectedOptions={checkboxSelecteds}
                    title={values.cleaningFrequency[key].title || ''}
                    uid={values.cleaningFrequency[key].uid || ''}
                    itemKey={key}
                    data={values.cleaningFrequency[key].items}
                    onChangeTitle={(value: string) => {
                      onChange(
                        'cleaningFrequency',
                        getSortedFrequency({
                          ...values.cleaningFrequency,
                          [key]: {
                            ...values.cleaningFrequency[key],
                            title: value,
                          },
                        })
                      );
                    }}
                    onBlurTitle={() => {
                      onChange(
                        'cleaningFrequency',
                        getSortedFrequency({
                          ...values.cleaningFrequency,
                          [key]: {
                            ...values.cleaningFrequency[key],
                            title:
                              values.cleaningFrequency[key].title &&
                              values.cleaningFrequency[key].title.trim() !== ''
                                ? values.cleaningFrequency[key].title
                                : key,
                          },
                        })
                      );
                    }}
                    onChange={(value: any) => {
                      // bindValues(
                      //   getSortedFrequency({
                      //     ...values.cleaningFrequency,
                      //     [key]: value,
                      //   })
                      // );
                      onChange(
                        'cleaningFrequency',
                        getSortedFrequency({
                          ...values.cleaningFrequency,
                          [key]: {
                            ...values.cleaningFrequency[key],
                            items: value,
                          },
                        })
                      );
                    }}
                    removeData={onRemoveData(key)}
                    removeValue={onRemoveTypeValue(key)}
                    addData={onAddData(key)}
                    addValue={onAddValue(key)}
                    dataKeys={autoCompleteData.keys.map((i) =>
                      typeof i === 'string' ? { value: i } : i
                    )}
                    dataValues={autoCompleteData.values.map((i) =>
                      typeof i === 'string' ? { value: i } : i
                    )}
                    resetRender={resetRender}
                    draggable={formValues.status !== 2}
                    disabled={formValues.status === 2}
                    onDragEnd={onDragAreasEnd}
                    setAccordionState={setAccState}
                    isOpen={accordionState[key] && accordionState[key].state}
                    subListOpened={
                      accordionState[key] && accordionState[key].subListState
                    }
                    setAutoCompleteFilter={setAutoCompleteFilter}
                  />
                  {/* {key.toLowerCase() != 'weekly' &&
                  key.toLowerCase() != 'monthly' &&
                  key.toLowerCase() != 'nightly' && (
                    <IconButton
                      disabled={formValues.status === 2 || key === 'weekly'}
                      onClick={onRemoveItem(key)}
                      padding="0px 10px"
                    />
                  )}

                {(key.toLowerCase() == 'weekly' ||
                  key.toLowerCase() == 'monthly' ||
                  key.toLowerCase() == 'nightly') && (
                  <div style={{ width: '77px', height: '40px' }}></div>
                )} */}
                  <div style={{ width: '77px', height: '40px' }}></div>
                </div>
              );
            }
          )}
          {/* <DraggableSection
            id="list"
            onChange={onDragEnd}
            transformFunctionBefore={objectToArray}
            transformFunctionAfter={arrayToObject}
            values={values.cleaningFrequency}
          >
            {Object.keys(values.cleaningFrequency).map(
              (key: string, index: number) => (
                <div className="accordion-content" key={`${key}_${index}`}>
                  <Accordion
                    title={key}
                    data={values.cleaningFrequency[key]}
                    onBlur={() => {}}
                    onChange={(value: any) => {
                      onChange('cleaningFrequency', {
                        ...values.cleaningFrequency,
                        [key]: value,
                      });
                    }}
                    removeData={onRemoveData(key)}
                    removeValue={onRemoveTypeValue(key)}
                    addData={onAddData(key)}
                    addValue={onAddValue(key)}
                    dataKeys={autoCompleteData.keys}
                    dataValues={autoCompleteData.values}
                    resetRender={resetRender}
                    draggable={true}
                    onDragEnd={onDragAreasEnd}
                  />
                  <IconButton onClick={onRemoveItem(key)} padding="0px 10px" />
                </div>
              )
            )}
          </DraggableSection> */}
          <SpecialNotes
            disabled={formValues.status === 2}
            onChange={onChange}
            data={
              !(
                Object.keys(values.specialNotes).length === 0 &&
                values.specialNotes.constructor === Object
              )
                ? values.specialNotes
                : [
                    {
                      description: '',
                      additionalNotes: [''],
                    },
                  ]
            }
            onBlur={() => onChange('specialNotes', values.specialNotes)}
            resetRender={resetRender}
          />
          <div className="additional-content-frequency">
            <span>Add Additional Cleaning Frequency</span>

            <Select
              name="additionalFrequency"
              value={additionalFrequency}
              onChange={(name: string, value: any) => onAddFrequency(value)}
              items={additionalFrequencies}
              width="300px"
              disableInput={true}
              disabled={formValues.status === 2}
            />
          </div>
        </div>
      </div>
    );
  }
);

//  const Teste = React.forwardRef(CleaningSpecification);

const mapForm = {
  mapPropsToValues: () => ({
    ...cleaningSpecificationValues,
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const EnhancedCleaning = withFormik<any, IBidCleaningSpecification>(mapForm)(
  CleaningSpecification
);

export { EnhancedCleaning as CleaningSpecification };
