/* eslint-disable no-console */
import {
  Checkbox,
  ButtonComponent,
  TableSummary,
  BaseButton,
  SVGIcon,
  Modal,
  AutocompleteSummary as Autocomplete,
  Tooltip,
} from 'core/components';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PenIcon from './assets/Edit.svg';
import BackIcon from 'modules/shared/sections/Summary/Supplies/assets/BackIcon.svg';
import React, { useEffect, useMemo, useState } from 'react';
import { IconWrapper, Footer } from '../styles';
import { formatCurrency } from 'core/utils';
import {
  getBrandInfoList,
  getUnitList,
  getSuppliesDetails,
  getSupplyByFranchiseId,
  getSupplyCategoryList,
} from 'modules/BidInfoForm/services/bidInfoServiceClient';
import { Supply, SupplyDetail } from 'modules/shared/sections/Summary';
import SaveIcon from './assets/SaveIcon.svg';
import { toast } from 'react-toastify';
import EditModal from './EditModal';
import Preview from './Preview';

export type header = {
  sortable?: boolean;
  label?: string;
  name: string;
  CustomComponent?: any;
  format?: any;
};

export interface TableSummaryProps {
  headers: header[];
  data: any[];
  loading?: boolean;
  pagination: Pagination;
  onSort?: (name: string, order: string) => void;
  sorting?: Sorting;
}

export interface Pagination {
  page: number;
  offset: number;
  pageCount: number;
  limit: number;
  count: number;
  total: number;
  onChangePage?: any;
}

export interface Sorting {
  order: string;
  field: string;
}

interface ISuppliesProps {
  opened: boolean;
  canEdit: boolean;
  isSupplyCustomized: boolean;
  onClose: (initialSupply?: Supply) => void;
  supply: Supply;
  onSave: (supply: Supply, isSupplyCustomized: boolean) => void;
}
export const Supplies = ({
  opened,
  onClose,
  onSave,
  canEdit,
  isSupplyCustomized: isSupplyCustomizedProp,
  supply: supplyProp,
}: ISuppliesProps) => {
  const [supply, setSupply] = useState<Supply>(supplyProp);
  const { SupplyId: supplyId } = supply;
  const [supplyDetailModal, setSupplyDetailModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [supplyDetailReference, setSupplyDetailReference] =
    useState<SupplyDetail>();
  const [isSupplyCustomized, setIsSupplyCustomized] = useState(
    isSupplyCustomizedProp
  );
  const [suppliesDetails, setSuppliesDetails] = useState<SupplyDetail[]>(
    supplyProp.SupplyDetails
  );
  const [showPreview, setShowPreview] = useState(false);
  const [brandInfos, setBrandInfos] = useState<string[]>([]);
  const [units, setUnits] = useState<string[]>([]);
  const [supplyCategories, setSupplyCategories] = useState<string[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    offset: 0,
    pageCount: Math.ceil(supplyProp.SupplyDetails.length / 10),
    limit: 10,
    total: supplyProp.SupplyDetails.length,
    count: supplyProp.SupplyDetails.length,
  });
  const [sorting, setSorting] = useState<Sorting>({
    order: 'ASC',
    field: 'Default',
    keepOrder: false,
  });

  const [supplyDetailFilters, setSupplyDetailFilters] = useState({
    defaultValue: '',
    supplyCategory: '',
    unit: '',
    brandInfo: '',
  });

  const paginate = (array) => {
    return array.slice(
      (pagination.page - 1) * pagination.limit,
      pagination.page * pagination.limit
    );
  };

  const fetchDefaultSupplyDetails = async () => {
    const { supplyDetails } = await getSuppliesDetails({
      supplyId,
      defaultValue: '',
      supplyCategory: '',
      unit: '',
      brandInfo: '',
      limit: 999999,
      offset: 0,
      page: 1,
      order: 'ASC',
      sort: 'Order',
    });
    const { Data } = supplyDetails;
    return Data;
  };

  const handleClearFilters = () => {
    setSupplyDetailFilters({
      defaultValue: '',
      supplyCategory: '',
      unit: '',
      brandInfo: '',
    });
    setSorting({
      order: 'ASC',
      field: 'Default',
      keepOrder: false,
    });
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const getDefaultSupplies = () =>
    suppliesDetails.filter((item) => item.Default);

  const onChangePage = (page: number) => {
    setPagination({ ...pagination, page });
  };

  useEffect(() => {
    if (supplyProp && opened) {
      setSupply({
        ...supplyProp,
        ShowPrice:
          supplyProp.ShowPrice !== undefined
            ? supplyProp.ShowPrice
            : supplyProp.DefaultShowPriceClient,
      });
      setSuppliesDetails(supplyProp.SupplyDetails);
    }
  }, [supplyProp, opened]);

  const getFilters = () => {
    getBrandInfos(supplyId);
    getUnits(supplyId);
    getSupplyCategories(supplyId);
  };

  useEffect(() => {
    if (supplyId) {
      getFilters();
    }
  }, [supplyId]);

  const getBrandInfos = async (supplySelected: Supply) => {
    const brandInfosResponse = await getBrandInfoList(supplySelected);
    setBrandInfos(brandInfosResponse);
  };

  const getUnits = async (supplySelected: Supply) => {
    const brandInfosResponse = await getUnitList(supplySelected);
    setUnits(brandInfosResponse);
  };

  const getSupplyCategories = async (supplySelected: Supply) => {
    const brandInfosResponse = await getSupplyCategoryList(supplySelected);
    setSupplyCategories(brandInfosResponse);
  };

  const openPreview = () => {
    const defaultSupplies = getDefaultSupplies();

    if (defaultSupplies.length > 0) {
      setShowPreview(true);
    } else {
      toast.info(
        'You need to select at least one supply before using the Preview & Re-order!'
      );
    }
  };

  const onSort = (field: string, order: string) => {
    setSorting({ field, order, keepOrder: false });
    setPagination({ ...pagination, page: 1 });
  };

  const previewIcon = ({
    id,
    message,
    placement = 'left',
  }: {
    id: string;
    message: string;
    placement?: string;
  }) => {
    return (
      <Tooltip
        overlay={
          <span
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {message}
          </span>
        }
        // trigger="click"
        placement={placement}
        alignedItemId={`previewTooltip_${id}`}
        positioningFunction={(node, align) => {
          const targetEl = document.getElementById(`previewTooltip_${id}`);
          const rect = targetEl.getBoundingClientRect();
          node.style.left = `${
            placement === 'left' ? rect.left - 340 : rect.right - 10
          }px`;
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.isPropagationStopped();
          }}
        >
          <IconWrapper
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.isPropagationStopped();
            }}
            id={`previewTooltip_${id}`}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.isPropagationStopped();
              }}
              style={{ fontSize: 12 }}
              size="xs"
              color="#216eab"
            />
          </IconWrapper>
        </div>
      </Tooltip>
    );
  };

  const handleUpdateFilters = (supplyDetail: SupplyDetail) => {
    const addProperty = (property: string, array: string[]) => {
      if (array.indexOf(property) === -1) array.push(property);
      return array;
    };

    const { SupplyCategory, BrandInfo, Unit } = supplyDetail;
    setBrandInfos(addProperty(BrandInfo, brandInfos));
    setUnits(addProperty(Unit, units));
    setSupplyCategories(addProperty(SupplyCategory, supplyCategories));
  };

  const handleUpdateSupply = (supplyDetail, { keepOrder = false } = {}) => {
    const index = suppliesDetails.findIndex(
      (item) => item.SupplyDetailId === supplyDetail.SupplyDetailId
    );

    const newSuppliesDetails = suppliesDetails.slice();
    setSorting({ ...sorting, keepOrder });
    newSuppliesDetails[index] = supplyDetail;
    setSuppliesDetails(newSuppliesDetails);
    setIsSupplyCustomized(true);
    handleUpdateFilters(supplyDetail);
    toast.info('Supply Detail updated successfully');
  };

  const setDefaultSupplyDetail = async (
    supplyDetailReference: SupplyDetail
  ) => {
    const defaultSupplies = getDefaultSupplies();
    if (defaultSupplies.length > 1 || supplyDetailReference.Default) {
      handleUpdateSupply(supplyDetailReference, { keepOrder: true });
    } else {
      toast.info(
        "Unable to perform this action. The Custom Supply List can't be empty."
      );
    }
  };

  const DefaultCheckbox = ({ reference }: { reference: SupplyDetail }) => (
    <Checkbox
      borderColor="gray"
      name="default"
      value={reference.Default}
      onChange={() => {
        setDefaultSupplyDetail({ ...reference, Default: !reference.Default });
      }}
      checked={reference.Default}
      containerStyle={{ justifyContent: 'center' }}
    />
  );

  const SelectedHeader = () => (
    <div style={{ display: 'flex' }}>
      {previewIcon({
        id: 'checkItem',
        message: 'Only items checked as Selected will show on the Proposal',
        placement: 'right',
      })}
      <span>Selected</span>
    </div>
  );

  const EditButton = ({ reference }: { reference: SupplyDetail }) => (
    <button
      className="table-button"
      disabled={!canEdit}
      onClick={() => {
        setIsEdit(true);
        setSupplyDetailModal(true);
        setSupplyDetailReference(reference);
      }}
    >
      <SVGIcon size="20px" icon={PenIcon} />
    </button>
  );

  const CustomDescription = ({ reference }: { reference: SupplyDetail }) => (
    <div style={{ overflowWrap: 'anywhere' }}>{reference.BrandInfo}</div>
  );

  const showPriceColumn =
    supplyProp.AllowRepresentativeShowPrice ||
    supplyProp.DefaultShowPriceClient;

  const headers: header[] = [
    {
      label: 'Selected',
      name: 'Default',
      CustomComponent: DefaultCheckbox,
      CustomHeader: SelectedHeader,
      sortable: true,
      textAlign: 'center',
      display: 'flex',
    },
    { label: 'Item', name: 'SupplyCategory', sortable: true },
    {
      label: 'Description',
      name: 'BrandInfo',
      sortable: true,
      CustomComponent: CustomDescription,
    },
    { label: 'Unit(s)', name: 'Unit', sortable: true },
    {
      ...(showPriceColumn && {
        label: 'Price',
        name: 'Price',
        format: formatCurrency,
        sortable: true,
      }),
    },
    {
      label: 'Customize',
      name: '',
      CustomComponent: EditButton,
      textAlign: 'center',
    },
  ];

  const onChangeEditPrice = () => {
    const newSupply = {
      ...supply,
      ShowPrice: !supply.ShowPrice,
    };
    setSupply(newSupply);
    setIsSupplyCustomized(true);
    toast.info('Price display setting saved successfully.');
  };

  const onChangeFilter = (field: string) => (value: string) => {
    setSorting({ ...sorting, keepOrder: false });
    setSupplyDetailFilters({ ...supplyDetailFilters, [field]: value });
    setPagination({ ...pagination, page: 1 });
  };

  const handleChangeDefaultValue = (value: string) => {
    setSorting({ ...sorting, keepOrder: false });
    setSupplyDetailFilters({ ...supplyDetailFilters, defaultValue: value });
    setPagination({ ...pagination, page: 1 });
  };

  const introducionTextTrim =
    supply && supply.IntroducionText && supply.IntroducionText.trim();

  const handleSave = async () => {
    onSave(
      {
        ...supply,
        SupplyDetails: suppliesDetails,
      },
      isSupplyCustomized
    );
    onClose();
    handleClearFilters();
    toast.info('Saved Successfully');
  };

  const handleRestoreDefault = async () => {
    try {
      const supplyResponse = await getSupplyByFranchiseId({
        franchiseId: supplyProp.FranchiseId,
      });
      const defaultSupplyDetails = await fetchDefaultSupplyDetails();
      setSupply(supplyResponse);
      setSuppliesDetails(defaultSupplyDetails);
      setIsSupplyCustomized(false);
      toast.info('Default values have been restored successfully!');
    } catch {
      toast.error('Error!', {
        icon: false,
        style: {
          backgroundColor: 'red',
          color: 'white',
        },
      });
    }
  };

  const handleCancel = () => {
    setSupply(supplyProp);
    handleClearFilters();
    onClose({
      ...supplyProp,
      SupplyDetails: supplyProp.SupplyDetails,
    });
  };

  function addSupplyDetail(array, newObject) {
    let lastIndex = -1;

    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].Default === true) {
        lastIndex = i;
        break;
      }
    }

    if (lastIndex !== -1) {
      array.splice(lastIndex + 1, 0, newObject);
    }

    return array;
  }

  const handleCreate = (suppliesDetail: SupplyDetail) => {
    const newSuppliesDetail = {
      ...suppliesDetail,
      createdByUser: true,
    };
    const newSupplies = addSupplyDetail(suppliesDetails, newSuppliesDetail);
    setSuppliesDetails(newSupplies);
    getFilters();
    setIsSupplyCustomized(true);
  };

  const normalizeSupplies = useMemo(() => {
    const { defaultValue, supplyCategory, unit, brandInfo } =
      supplyDetailFilters;

    const filteredSupplies = suppliesDetails
      .filter((item) => (defaultValue ? item.Default : item))
      .filter((item) =>
        !!supplyCategory ? item.SupplyCategory === supplyCategory : item
      )
      .filter((item) => (!!unit ? item.Unit === unit : item))
      .filter((item) => (!!brandInfo ? item.BrandInfo === brandInfo : item));

    if (sorting.keepOrder && sorting.field === 'Default') {
      return filteredSupplies;
    }

    if (!sorting.field) {
      return filteredSupplies;
    }

    const sortedSupplies = filteredSupplies.sort((a, b) => {
      let first = a;
      let second = b;
      if (sorting.field === 'Default') {
        first = b;
        second = a;
      }

      if (sorting.order === 'DESC') {
        return String(second[sorting.field]).localeCompare(
          String(first[sorting.field])
        );
      } else {
        return String(first[sorting.field]).localeCompare(
          String(second[sorting.field])
        );
      }
    });
    return sortedSupplies;
  }, [sorting, supplyDetailFilters, suppliesDetails, suppliesDetails.length]);

  useEffect(() => {
    setPagination({
      ...pagination,
      offset: (pagination.page - 1) * 10,
      pageCount: Math.ceil(normalizeSupplies.length / 10),
      total: normalizeSupplies.length,
      count: normalizeSupplies.length,
    });
  }, [normalizeSupplies]);

  const handleMoveCard = (newSuppliesDetails: SupplyDetail[]) => {
    const notDefaultSupplies = suppliesDetails.filter((item) => !item.Default);
    setSuppliesDetails(newSuppliesDetails.concat(notDefaultSupplies));
  };

  const maxLines =
    introducionTextTrim && introducionTextTrim.split(/\r\n|\r|\n/).length > 7;
  const maxChar = introducionTextTrim && introducionTextTrim.length > 280;
  const disableSaveButton = maxLines || maxChar;

  return (
    <>
      <Modal opened={opened} onClose={handleCancel} className="modal-supplies">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <h1>Custom Supply List</h1>
          <div className="restore-default-container">
            <BaseButton onClick={handleRestoreDefault}>
              <span>Restore Defaults</span>
            </BaseButton>
          </div>
        </div>
        <div className="supplies-container">
          <div className="introduction-input">
            <label className="introduction-text">
              INTRODUCTION TEXT (OPTIONAL)
            </label>
            <textarea
              value={supply && supply.IntroducionText}
              onChange={(event) =>
                setSupply({
                  ...supply,
                  IntroducionText: event.target.value,
                })
              }
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <div className="char-counter">
                  <span>
                    {(introducionTextTrim && introducionTextTrim.length) || 0}{' '}
                    characters
                  </span>
                </div>
                <div className="validation-error">
                  {(maxLines || maxChar) && (
                    <span>
                      This text is too long! It cannot exceed the limit of 7
                      lines and 280 characters!
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames('supplies-edit-price', {
              end: !supply.AllowRepresentativeShowPrice,
            })}
          >
            {supply.AllowRepresentativeShowPrice && (
              <div>
                <Checkbox
                  borderColor="gray"
                  name="showPrice"
                  value={supply && supply.ShowPrice}
                  onChange={onChangeEditPrice}
                  checked={supply && supply.ShowPrice}
                />
                <label>Show Price to Client</label>
              </div>
            )}
            {canEdit && (
              <div style={{ display: 'flex' }}>
                {previewIcon({
                  id: 'add-supply-button',
                  message:
                    'Added Supplies will be saved and made available to all Master City Users',
                })}
                <BaseButton
                  id="add-supply-button"
                  onClick={() => {
                    setIsEdit(false);
                    setSupplyDetailModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Supply</span>
                </BaseButton>
              </div>
            )}
          </div>
          <div>
            <div className="filter-container filter-container-supply">
              <div className="checkItem">
                <div>
                  <label style={{ paddingLeft: 0 }}>Show Selected Only</label>
                </div>
                {/* {previewIcon({
                  id: 'checkItem',
                  message:
                    'Only items checked as Selected will show on the Proposal',
                })} */}
                <div className="checkItem-box">
                  <Checkbox
                    borderColor="gray"
                    name="defaultValue"
                    value={Boolean(supplyDetailFilters.defaultValue)}
                    onChange={() => {
                      handleChangeDefaultValue(
                        supplyDetailFilters.defaultValue ? '' : 'true'
                      );
                      setPagination({ ...pagination, page: 1 });
                    }}
                    checked={Boolean(supplyDetailFilters.defaultValue)}
                  />
                </div>
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('supplyCategory')}
                  value={supplyDetailFilters.supplyCategory}
                  list={supplyCategories}
                  placeholder="Filter"
                  maxLength={50}
                  label="Item"
                />
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('brandInfo')}
                  list={brandInfos}
                  value={supplyDetailFilters.brandInfo}
                  maxLength={150}
                  placeholder="Filter"
                  label="Description"
                />
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('unit')}
                  maxLength={50}
                  value={supplyDetailFilters.unit}
                  list={units}
                  placeholder="Filter"
                  label="Unit(s)"
                />
              </div>
            </div>
          </div>
        </div>
        <TableSummary
          data={paginate(normalizeSupplies)}
          headers={headers}
          pagination={{ ...pagination, onChangePage }}
          onSort={onSort}
          sorting={sorting}
        />
        <Footer style={{ padding: 0, width: '100%' }}>
          <div>
            <ButtonComponent icon={BackIcon} onClick={handleCancel}>
              BACK
            </ButtonComponent>
          </div>
          <div>
            <ButtonComponent outline secondary onClick={openPreview}>
              PREVIEW & RE-ORDER
            </ButtonComponent>
            <ButtonComponent
              secondary
              icon={SaveIcon}
              onClick={handleSave}
              disabled={!!disableSaveButton}
            >
              SAVE AND CLOSE
            </ButtonComponent>
          </div>
        </Footer>
      </Modal>
      <EditModal
        units={units}
        supplyCategories={supplyCategories}
        brandInfos={brandInfos}
        header={`${isEdit ? 'Update Supply' : 'Add Supply'}`}
        isEdit={isEdit}
        opened={supplyDetailModal}
        onClose={() => setSupplyDetailModal(false)}
        onCreate={handleCreate}
        onUpdate={handleUpdateSupply}
        supplyId={supplyId}
        allowEditPrice={
          supply.AllowRepresentativeEdit ||
          (supplyDetailReference && supplyDetailReference.createdByUser)
        }
        supplyDetailReference={supplyDetailReference}
      />
      <Preview
        onSave={handleMoveCard}
        introductionText={supply.IntroducionText || ''}
        onClose={() => {
          setShowPreview(false);
        }}
        supplyDetails={getDefaultSupplies()}
        opened={showPreview}
        showPrice={supply.ShowPrice}
      />
    </>
  );
};

export default Supplies;
