import React, { memo, useCallback } from 'react';
import { withFormik, FormikProps } from 'formik';

import {
  InputFloatLabel,
  RadioButton,
  useFormProps,
  ContentInput,
  Select,
  FilterSelect,
  ButtonGroup,
} from 'core/components';
import { isIpad } from 'core/utils';

import { EditButton, EditButtonSecondary, FormLabelSelect } from './styles';

import { sicValues } from './constants';

import './styles.scss';
import { IBidClientInfo } from 'modules/BidInfoForm/interfaces';
import { clientInfoValues } from 'modules/shared/bidDefaultValues';
import { phoneMask, postalCodeMask, stateMask } from 'core/utils';

type IProps = FormikProps<IBidClientInfo>;
const ClientInfo = memo(({ refreshIntegration }: IProps) => {
  const { onChange, formValues } = useFormProps();
  const onBlur = useCallback(
    (name: string) => () => onChange(name, formValues[name]),
    [formValues, onChange]
  );

  const phoneType = ['Home', 'Cell', 'Work', 'Fax', 'Other'];

  return (
    <div className="client-info-root">
      <div className="client-info-content">
        <ContentInput
          width="100%"
          display="flex"
          justify="space-between"
          align="center"
        >
          <div className="header-actions">
            <h3 className="client-info-title">Client Info</h3>
            <ContentInput flex gap="50px" justify="flex-end" padding="0px">
              {formValues.isSpecialty == 1 && (
                <ButtonGroup
                  disabled={formValues.status === 2}
                  tabIndexable={false}
                  options={[
                    { value: true, label: 'Existing Client' },
                    { value: false, label: 'New Client' },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  // multiple={false}
                  type="oneOption"
                  name="existentClient"
                  onChange={(name, item) => onChange(name, item)}
                  value={!!formValues.existentClient}
                  buttonsStyle={{ width: '140px', padding: '10px' }}
                />
              )}
            </ContentInput>
          </div>
        </ContentInput>

        <ContentInput width="100%">
          <InputFloatLabel
            value={formValues.businessName}
            hasCounter
            maxLength={75}
            name="businessName"
            label="Location Name"
            className="validate-company-name"
            onChange={onChange}
            onBlur={onBlur('businessName')}
            capitalizeFunction={(v) =>
              // capitalizeInput(v, true)
              v
            }
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="52%" padding>
          <InputFloatLabel
            value={formValues.address}
            name="address"
            label="Address"
            onChange={onChange}
            onBlur={onBlur('address')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="45%">
          <InputFloatLabel
            value={formValues.suite}
            name="suite"
            label="Address 2"
            onChange={onChange}
            onBlur={onBlur('suite')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="31%" padding>
          <InputFloatLabel
            value={formValues.city}
            name="city"
            label="City"
            onChange={onChange}
            onBlur={onBlur('city')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="30%" padding>
          <InputFloatLabel
            value={formValues.state}
            name="state"
            label="State / Province"
            onChange={onChange}
            onBlur={onBlur('state')}
            mask={stateMask}
            capitalizeFunction={(value: string) => value.toUpperCase()}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="33%">
          <InputFloatLabel
            value={formValues.postalCode}
            name="postalCode"
            className="validate-postal-code"
            label="Postal Code"
            onChange={(name: string, value: any) =>
              onChange(name, value ? value.toUpperCase() : value)
            }
            onBlur={onBlur('postalCode')}
            mask={postalCodeMask}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="40%" padding>
          <InputFloatLabel
            value={formValues.phone}
            name="phone"
            type="tel"
            label="Office Phone"
            className="validate-phone"
            onChange={onChange}
            onBlur={onBlur('phone')}
            mask={phoneMask}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="12%" padding>
          <InputFloatLabel
            value={formValues.contactPhoneExtension}
            name="contactPhoneExtension"
            className="validate-postal-code"
            label="Extension"
            onChange={onChange}
            onBlur={onBlur('contactPhoneExtension')}
            maxLength={10}
            disabled={formValues.status === 2}
          />
        </ContentInput>
        <ContentInput width="42%">
          <InputFloatLabel
            value={formValues.county}
            name="county"
            label="County"
            onChange={onChange}
            onBlur={onBlur('county')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="100%" paddingTop="35px">
          <h3 className="client-info-title">Contact Info</h3>
        </ContentInput>

        <ContentInput width="22%" padding>
          <InputFloatLabel
            value={formValues.decisionMakerFirstName}
            name="decisionMakerFirstName"
            label="Decision Maker First Name"
            onChange={onChange}
            onBlur={onBlur('decisionMakerFirstName')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="22%" padding>
          <InputFloatLabel
            value={formValues.decisionMakerLastName}
            name="decisionMakerLastName"
            label="Decision Maker Last Name"
            onChange={onChange}
            onBlur={onBlur('decisionMakerLastName')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="22%" padding>
          <InputFloatLabel
            value={formValues.walkThruFirstName}
            name="walkThruFirstName"
            label="Walk-thru First Name"
            onChange={onChange}
            onBlur={onBlur('walkThruFirstName')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="22%" padding>
          <InputFloatLabel
            value={formValues.walkThruLastName}
            name="walkThruLastName"
            label="Walk-thru Last Name"
            onChange={onChange}
            onBlur={onBlur('walkThruLastName')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="47%" padding>
          <InputFloatLabel
            value={formValues.decisionMakerTitle}
            name="decisionMakerTitle"
            label="Title"
            onChange={onChange}
            onBlur={onBlur('decisionMakerTitle')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="47%">
          <InputFloatLabel
            value={formValues.walkThruTitle}
            name="walkThruTitle"
            label="Title"
            onChange={onChange}
            onBlur={onBlur('walkThruTitle')}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="47%" padding>
          <InputFloatLabel
            value={formValues.decisionMakerEmail}
            type="email"
            name="decisionMakerEmail"
            label="Email"
            className="validate-decision-maker-email"
            onChange={onChange}
            onBlur={onBlur('decisionMakerEmail')}
            capitalizeFunction={(item: string) =>
              // item.charAt(0).toUpperCase() + item.slice(1)
              item
            }
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="47%">
          <InputFloatLabel
            value={formValues.walkThruEmail}
            type="email"
            name="walkThruEmail"
            label="Email"
            className="validate-walkthru-maker-email"
            onChange={onChange}
            onBlur={onBlur('walkThruEmail')}
            capitalizeFunction={(item: string) =>
              //item.charAt(0).toUpperCase() + item.slice(1)
              item
            }
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput
          width="15%"
          position="relative"
          paddingTop="22px"
          flex
          align="center"
          padding="0 10px 0 0"
        >
          <FormLabelSelect>Phone Type</FormLabelSelect>
          <Select
            name="decisionMakerPhoneType"
            value={phoneType[parseInt(formValues.decisionMakerPhoneType) - 1]}
            onChange={(name, value) => {
              onChange(name, phoneType.indexOf(value) + 1);
            }}
            items={phoneType}
            disableInput={true}
            disabled={formValues.status === 2}
          />
        </ContentInput>
        <ContentInput width="25%" padding="15px 10px 0 0">
          <InputFloatLabel
            autoCapitalize={isIpad() ? 'none' : null}
            disabled={formValues.status === 2}
            value={formValues.decisionMakerPhone}
            name="decisionMakerPhone"
            className="validate-phone-decision"
            label="Phone"
            type="tel"
            style={{ width: '100%' }}
            onChange={onChange}
            onBlur={onBlur('decisionMakerPhone')}
            mask={phoneMask}
          />
        </ContentInput>

        <ContentInput width="12%" padding>
          <InputFloatLabel
            value={formValues.decisionMakerPhoneExtension}
            name="decisionMakerPhoneExtension"
            className="validate-postal-code"
            label="Extension"
            onChange={onChange}
            onBlur={onBlur('decisionMakerPhoneExtension')}
            // mask={postalCodeMask}
            maxLength={10}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput
          width="15%"
          position="relative"
          paddingTop="22px"
          flex
          align="center"
          padding="0 10px 0 0"
        >
          <FormLabelSelect>Phone Type</FormLabelSelect>
          <Select
            name="walkThruPhoneType"
            value={phoneType[parseInt(formValues.walkThruPhoneType) - 1]}
            onChange={(name, value) => {
              onChange(name, phoneType.indexOf(value) + 1);
            }}
            items={phoneType}
            disableInput={true}
            disabled={formValues.status === 2}
          />
        </ContentInput>
        <ContentInput width="25%" padding="15px 10px 0 0">
          <InputFloatLabel
            autoCapitalize={isIpad() ? 'none' : null}
            disabled={formValues.status === 2}
            value={formValues.walkThruPhone}
            name="walkThruPhone"
            className="validate-phone-walk"
            label="Phone"
            type="tel"
            style={{ width: '100%' }}
            onChange={onChange}
            onBlur={onBlur('walkThruPhone')}
            mask={phoneMask}
          />
        </ContentInput>

        <ContentInput width="12%" padding>
          <InputFloatLabel
            value={formValues.walkThruPhoneExtension}
            name="walkThruPhoneExtension"
            className="validate-postal-code"
            label="Extension"
            onChange={onChange}
            onBlur={onBlur('walkThruPhoneExtension')}
            // mask={postalCodeMask}
            maxLength={10}
            disabled={formValues.status === 2}
          />
        </ContentInput>

        <ContentInput width="100%" justify="center">
          <RadioButton
            value={formValues.proposalAddressedTo}
            onChange={(name: string, value: any) => {
              onChange(name, value);
            }}
            name="proposalAddressedTo"
            label="Proposal Prepared For"
            options={[
              { value: 'decisionMaker', label: 'Decision Maker' },
              { value: 'walkThru', label: 'Walk-thru Contact' },
            ]}
            optionLabel="label"
            optionValue="value"
            disabled={formValues.status === 2}
          />
        </ContentInput>
        <ContentInput width="100%" flexDirection="row" flex>
          <FilterSelect
            disabled={formValues.status === 2}
            placeholder="SIC CODE"
            value={formValues.sicId || 0}
            items={sicValues}
            name="sic"
            onChange={(name: string, value: any) => {
              onChange('sicId', value.id);
              onChange('sic', value.name);
            }}
            width="100%"
            getLabelFunction={(item: any) =>
              `${item.id || ''} ${item.id ? '-' : ''} ${item.name}`
            }
            getValueFunction={(item: any) => item.id}
            canTypeCustomValue={false}
            blockCustomInput={false}
          />
          <EditButton
            disabled={formValues.status === 2}
            onClick={() => {
              onChange('sicId', null);
              onChange('sic', '');
            }}
          >
            CLEAR
          </EditButton>
        </ContentInput>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...clientInfoValues,
  }),
  handleSubmit: () => '',
};

const EnhancedClient = withFormik<any, IBidClientInfo>(mapForm)(ClientInfo);

export { EnhancedClient as ClientInfo };
