import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { RouteComponentProps, withRouter } from 'react-router';
// import { toast } from 'react-toastify';
import Scrollspy from 'react-scrollspy';
import * as Sentry from '@sentry/react';
import { getQueryString, axiosInstance } from 'core/utils';
import { useAuthenticationHandler } from 'core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import { IWarningModal } from '../../../shared/bidInterfaces';
import { IStateToProps } from '../../interfaces';
import { SiteInformationForm } from '../NewInspectScreen/sections';
import { IArea, IAreaItem, IAreaPhoto } from '../../../../core/interfaces';
import { FirstForm, Header } from '../NewInspectScreen/sections';
// for images
import logo from './logo.png';
import companyinfo from './companyinfo.png';
import { defaultAreaNames, getDefaultAreaNames } from './iconsPath';

import {
  LoadingModal,
  PdfModal,
  FormProvider,
  ActionAlertModal,
} from 'core/components';

import './floatingLabel.scss';
import './styles.scss';
import '../../../../core/components/AddPhotoModal/previewPhotoModal.scss';
import { HiddenAnchor } from '../../../BidInfoForm/components/atoms';
import { GenericModal, RenderPdf } from '../../../../core/components';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from '../../../../core/utils';
import { AccountInfoInspect } from '../../../BidInfoForm/screens/BidForm/sections';
import { ClientInfoInspect } from '../../../shared/sections/ClientInfoInspect';

//####### PreviewModal ######
// used below in the code when the user clicks on an image
const PreviewModal = (props: any) => {
  return (
    <div className="preview-photo-modal-root-div">
      <img alt={props.photoAltName} src={props.imageURL} />
    </div>
  );
};

//####### PreviewModal ######

interface IState {
  inspectForm: IStateToProps & { InspectedBy: string };
}

interface IQueryProps {
  match: {
    params: {
      id: string; // comes from the route URL
    };
  };
}

type ReportPreviewType = RouteComponentProps & IQueryProps;

function ReportPreviewScreen({ location, match, history }: ReportPreviewType) {
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [photoAltName, setPhotoAltName] = useState('');
  const [showPrintBubble, setShowPrintBubble] = useState(false);
  // const [activeMenu, setActiveMenu] = useState('Company Info');
  const [openMenu, setOpenMenu] = useState(false);
  const [pdfNoPhotoUri, setPdfNoPhotoUri] = useState('');
  const [pdfPhotoUri, setPdfPhotoUri] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [loadingPdfURIs, setLoadingPdfURIs] = useState(true);
  const [currentLeft, setCurrentLeft] = useState(0);
  const [warningModal, setWarningModal] = useState<IWarningModal>(null);

  const [showBackButton, setShowBackButton] = useState(false);

  // ########
  // const [pdfPhotoURL, setPdfPhotoURL] = useState('');
  // const [pdfNoPhotoURL, setPdfNoPhotoURL] = useState('');
  // const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);
  // ########

  const currentUrl = location.search;
  const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop'); // hide the BACK button when viewing from a desktop (outside of ipad application)
  const sourceAccessOfPage = getQueryString(currentUrl, 'source'); // hide the BACK button when viewing from a desktop (outside of ipad application)

  function onClickOpenPDF(pdfUri: string) {
    dispatch(InspectFormActions.setPdfModalState(true, pdfUri));
  }

  useEffect(() => {
    const standalone = window.navigator.standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (!standalone && safari) {
        //browser
      } else if (standalone && !safari) {
        //standalone
      } else if (!standalone && !safari) {
        //uiwebview
        setShowBackButton(true);
        history.goBack();
      }
    } else {
      //not iOS
    }
  }, [history]);

  // function onClickCopy(Notes) {
  //   if (!navigator.clipboard) {
  //     iosCopyToClipboard(document.getElementById('textAreaToCopyReportPreview'));
  //     toast.info('Notes Copied to Clipboard');
  //     return;
  //   }
  //   navigator.clipboard.writeText(Notes);
  //   toast.info('Notes Copied to Clipboard');
  // }

  function scrollIntoViewHandler(id: string) {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  function previewPhoto(file: IAreaPhoto) {
    const src = file.PathAndFileName ? file.PathAndFileName : file.FileUrl;
    const altName = (file.File! && file.File!.name) || '';
    setShowPreview(true);
    setImageURL(src);
    setPhotoAltName(altName);
  }

  function getGradeClassName(grade: number) {
    if (grade === 1) {
      return 'area-one';
    } else if (grade === 2) {
      return 'area-two';
    } else if (grade === 3) {
      return 'area-three';
    } else if (grade === 4) {
      return 'area-four';
    } else if (grade === 5) {
      return 'area-five';
    } else {
      return '';
    }
  }

  const loading = useSelector((state: IState) => state.inspectForm.loading);
  const showPdfModal = useSelector(
    (state: IState) => state.inspectForm.showPdfModal
  );
  const [showPdf, setShowPdf] = useState(false);
  const [pdfName, setPdfName] = useState('');
  const [pdfType, setPdfType] = useState();
  const [pdfKey, setPdfKey] = React.useState(null);

  const areas: IArea[] = useSelector(
    (state: IState) => state.inspectForm.Areas
  );
  const Notes: string = useSelector((state: IState) => state.inspectForm.Notes);
  const headerInfo = useSelector((state: IState) => {
    return {
      pid: state.inspectForm.PID,
      inspectionClassNumber: state.inspectForm.InspectionClassNumber,
      date: state.inspectForm.Date,
    };
  });
  const conductedBy = useSelector(
    (state: IState) => state.inspectForm.InspectedBy
  );
  const companyInfo = useSelector((state: IState) => {
    const selecteds = [];

    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    for (const day of daysOfWeek) {
      if (state.inspectForm[`DaysCleaned${day}`]) {
        selecteds.push(day.slice(0, 3));
      }
    }

    return {
      ...state.inspectForm,
      status: 2,
      frequency: {
        ...state.inspectForm.frequency,
        selecteds,
        timeWindow: state.inspectForm.Time,
      },
      frequencyService: state.inspectForm.IsMonthlyCleaning
        ? 'monthly'
        : 'weekly',
      companyName: state.inspectForm.AccountName,
      contact: state.inspectForm.Contact,
      phone: state.inspectForm.Phone,
      accountAddress: state.inspectForm.AccountAddress,
      suite: state.inspectForm.Suite,
      city: state.inspectForm.City,
      state: state.inspectForm.State,
      zip: state.inspectForm.Zip,
      daysCleanedMonday: state.inspectForm.DaysCleanedMonday,
      daysCleanedTuesday: state.inspectForm.DaysCleanedTuesday,
      daysCleanedWednesday: state.inspectForm.DaysCleanedWednesday,
      daysCleanedThursday: state.inspectForm.DaysCleanedThursday,
      daysCleanedFriday: state.inspectForm.DaysCleanedFriday,
      daysCleanedSaturday: state.inspectForm.DaysCleanedSaturday,
      daysCleanedSunday: state.inspectForm.DaysCleanedSunday,
      time: state.inspectForm.Time,
      franchiseOwnerName: state.inspectForm.FranchiseOwnerName,
      facilityRating: state.inspectForm.FacilityRating,
    };
  });

  const areaNamesAux = useMemo(
    () =>
      areas.map((area: IArea, index: number) => {
        // only display those areas with at least one rated item
        return {
          id: area.AreaId,
          name: area.Name,
          average: area.RatingAverage,
        };
      }),
    [areas]
  );

  const areaNames = areaNamesAux.filter((area) => area.average > 0);

  const idsArray1 = areaNames.map((area) => {
    return `${area.name}-${area.id}`;
  });
  const idsArray = ['Company-Info-000', ...idsArray1];

  useEffect(() => {
    function getPdfInfo() {
      dispatch(InspectFormActions.getPublicEditFormRequest(match.params.id));
    }
    getPdfInfo();
  }, [dispatch, match.params.id]);

  useEffect(() => {
    async function getPdfs() {
      try {
        const response = await axiosInstance.get(
          `InspectionReport/getPdfs?id=${match.params.id}`
        );
        const pdfs = response.data;

        setPdfNoPhotoUri(pdfs.pdfNoPhotoUri);
        setPdfPhotoUri(pdfs.pdfPhotoUri);

        setLoadingPdfURIs(false); // closing the loading when the pdfs uri have been returned
      } catch (error) {
        console.log('error pdf modal', error);
        Sentry.captureException(error);
      }
    }

    async function incrementAccessOnBackend() {
      try {
        // if accessing the url from email, increment the number of accesses in the backend
        if (sourceAccessOfPage === 'email') {
          const response = await axiosInstance.get(
            `InspectionReport/incrementAccess/${match.params.id}`
          );
          const accessData = response.data;
        }
      } catch (error) {
        console.log('error incrementAccessOnBackend', error);
        Sentry.captureException(error);
      }
    }

    incrementAccessOnBackend();
    getPdfs();
  }, [match.params.id, sourceAccessOfPage]);

  const { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  React.useEffect(() => {
    if (!!error && error.status == 401) {
      //setIsLoading(false);
      setWarningModal({
        title: 'Access Denied',
        message:
          'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        proceed: () => {
          localStorage.setItem(
            'anago_redirect_after_login',
            window.location.href
          );
          window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
            'login',
            'home'
          );
        },
      });
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          setWarningModal({
            title: 'Attention',
            message:
              'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            proceed: () => {
              window.location.reload(true);
            },
          });
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!!genericError) {
      setWarningModal({
        title: 'Attention',
        message:
          'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        proceed: () => {
          setWarningModal(null);
          setGenericError(undefined);
        },
      });
    }
  }, [genericError]);

  return loading || loadingPdfURIs ? (
    <LoadingModal localLoading={loadingPdfURIs} />
  ) : (
    <FormProvider
      formValues={companyInfo}
      setFormValues={() => {}}
      onChange={() => {}}
    >
      <div
        className="report-root-div"
        style={
          {
            // overflow: 'hidden',
          }
        }
      >
        <div className="report-content-screen">
          <div className="report-header">
            {hideButtonDesktop === 'true' || showBackButton === false ? (
              <div style={{ width: '240px' }}></div>
            ) : (
              <button
                className="button btn-back"
                // onClick={() => history.push('/close-form')}
                onClick={() => history.goBack()}
              >
                BACK
              </button>
            )}
            <img src={logo} alt="" className="logo" />
            <div className="back-button-container relative">
              <button
                className="button btn-print"
                onClick={() => {
                  setShowPrintBubble(true);
                }}
              >
                PREVIEW
              </button>
              {showPrintBubble && (
                <>
                  <div
                    onClick={() => {
                      setShowPrintBubble(false);
                    }}
                    className="save-as-backdrop"
                  />
                  <div className="bubble go-down">
                    <div className="content">
                      <>
                        <div
                          className="first-row underlined"
                          onClick={() => {
                            // //on desktop, opens new tab, on ipad, open the preview from react-pdf
                            // hideButtonDesktop === 'true' ||
                            // showBackButton === false
                            //   ? window.open(`${pdfPhotoUri}`, '_blank')
                            //   : onClickOpenPDF(pdfPhotoUri);
                            // // (hideButtonDesktop === 'true') ? window.open(`${pdfPhotoUri}`, "_blank") : window.open(`${pdfPhotoUri}?code=print-pdf`, "_blank")

                            setPdfName(`${headerInfo.pid}_InspectionPics`);
                            setPdfKey(match.params.id);
                            setPdfType(30);
                            setPdfUrl(pdfPhotoUri);
                            setShowPrintBubble(false);
                            setTimeout(() => {
                              setShowPdf(true);
                            }, 0);
                          }}
                        >
                          WITH PHOTOS
                        </div>
                        <div
                          className="second-row"
                          onClick={() => {
                            // //on desktop, opens new tab, on ipad, open the preview from react-pdf
                            // hideButtonDesktop === 'true' ||
                            // showBackButton === false
                            //   ? window.open(`${pdfNoPhotoUri}`, '_blank')
                            //   : onClickOpenPDF(pdfNoPhotoUri);
                            // // (hideButtonDesktop === 'true') ? window.open(`${pdfNoPhotoUri}`, "_blank") : window.open(`${pdfNoPhotoUri}?code=print-pdf`, "_blank")

                            setPdfName(`${headerInfo.pid}_Inspection`);
                            setPdfType(31);
                            setPdfKey(match.params.id);
                            setPdfUrl(pdfNoPhotoUri);
                            setShowPrintBubble(false);
                            setTimeout(() => {
                              setShowPdf(true);
                            }, 0);
                          }}
                        >
                          WITHOUT PHOTOS
                        </div>
                      </>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <Header conductedBy={conductedBy} hideMenu />
          <FirstForm isPreview />
          <div className="report-container-body">
            <nav className={`report-menu stick ${openMenu ? 'menu-open' : ''}`}>
              <ul>
                <button
                  className="btn-menu"
                  onClick={() => setOpenMenu(!openMenu)}
                ></button>

                <div
                  className="report-menu-relative"
                  style={
                    window.matchMedia('(max-width: 600px)').matches
                      ? {
                          // position: 'relative',
                          // WebkitOverflowScrolling: 'touch',
                          left: currentLeft || 0,
                        }
                      : {}
                  }
                >
                  <Scrollspy
                    items={idsArray}
                    currentClassName="active"
                    // scrolledPastClassName="past-active"
                    onUpdate={(activeElement) => {
                      const id = activeElement && activeElement.id;

                      const lengths = idsArray.map((item) => {
                        const elem = document.getElementById(
                          `list-item-${item}`
                        );
                        return elem.offsetWidth;
                      });

                      const isCompanyInfoId = id === 'Company-Info-000';
                      const idsLength = idsArray.length;

                      if (isCompanyInfoId) {
                        // for the first element
                        setCurrentLeft(0);
                      } else if (
                        idsLength > 0 &&
                        id === idsArray[idsLength - 1]
                      ) {
                        // for the last element
                        const sum = lengths.reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue
                        );
                        const newValue = sum - window.innerWidth;
                        setCurrentLeft(-newValue);
                      } else {
                        // elements in the middle
                        const index = idsArray.findIndex((item) => item === id);
                        if (index !== -1) {
                          const sliced = lengths.slice(0, index);
                          const sum = sliced.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue
                          );
                          const newValue =
                            sum - (window.innerWidth - lengths[index]) / 2;
                          setCurrentLeft(-newValue);
                        }
                      }
                    }}
                  >
                    <li
                      // className={`${activeMenu === 'Company Info' ? 'active' : ''}`}
                      id="list-item-Company-Info-000"
                    >
                      <img
                        src={companyinfo}
                        alt=""
                        className="menu-icon"
                        onClick={() => {
                          scrollIntoViewHandler('Company-Info-000');
                          // setActiveMenu('Company Info');
                        }}
                      />
                      <div
                        onClick={() => {
                          scrollIntoViewHandler('Company-Info-000');
                          // setActiveMenu('Company Info');
                        }}
                      >
                        Company Info
                      </div>
                    </li>

                    {areaNames.map((area, index) => {
                      const nameToLookFor = getDefaultAreaNames(area.name);

                      return (
                        <li
                          // className={`${activeMenu === area.name ? 'active' : ''}`}
                          id={`list-item-${area.name}-${area.id}`}
                          key={index + 1}
                        >
                          <img
                            src={defaultAreaNames[nameToLookFor].src}
                            alt=""
                            className="menu-icon"
                            onClick={() => {
                              scrollIntoViewHandler(`${area.name}-${area.id}`);
                              // setActiveMenu(area.name);
                            }}
                          />
                          <div
                            onClick={() => {
                              scrollIntoViewHandler(`${area.name}-${area.id}`);
                              // setActiveMenu(area.name);
                            }}
                          >
                            {area.name}
                          </div>
                        </li>
                      );
                    })}
                  </Scrollspy>
                </div>
              </ul>
            </nav>
            <div className="report-container-content">
              <div className="box-container" id="Company-Info-000">
                <ClientInfoInspect isPreview />
                <AccountInfoInspect isPreview />
                <SiteInformationForm isPreview />
              </div>
              <div className="areas-header-container">
                <div className="title-container">
                  <h2>Ratings</h2>
                  <div className="average-rating">
                    Overall Rating:
                    <span className="average-rating-value">
                      {Number.isInteger(companyInfo.facilityRating)
                        ? companyInfo.facilityRating
                        : companyInfo.facilityRating?.toFixed?.(1)}
                    </span>
                  </div>
                </div>
                <div className="tabs-rating-container">
                  <div
                    className="tab"
                    style={{ backgroundColor: 'rgb(0,176,72)' }}
                  >
                    <p>1 - Above Standard</p>
                  </div>
                  <div
                    className="tab"
                    style={{ backgroundColor: 'rgb(0,96,255)' }}
                  >
                    <p>2 - Standard</p>
                  </div>
                  <div
                    className="tab"
                    style={{ backgroundColor: 'rgb(178,156,17)' }}
                  >
                    <p>3 - Needs Attention</p>
                  </div>
                  <div
                    className="tab"
                    style={{ backgroundColor: 'rgb(255,140,68)' }}
                  >
                    <p>4 - Schedule Not Followed</p>
                  </div>
                  <div
                    className="tab"
                    style={{ backgroundColor: 'rgb(222,0,44)' }}
                  >
                    <p>5 - Fail</p>
                  </div>
                </div>
              </div>

              {areas.map((area: IArea, index: number) => {
                // area.na
                const areaId = area.AreaId;
                const areaName = area.Name;
                const areaNameInitial = area.Name.split(' ')[0];
                const averageRating = area.RatingAverage;
                const numberOfPhotos = area.AreaPhotos.length;

                // only display areas with at least one rated item
                if (averageRating > 0) {
                  return (
                    // CAMILA
                    <div className="box-container" id={`${areaName}-${areaId}`}>
                      <div className="box" key={`${index}-box-ratings-1`}>
                        <div className="box-title">
                          <h3>{areaName}</h3>
                          <span>
                            {numberOfPhotos} photo{' '}
                            {numberOfPhotos === 1 ? '' : '(s)'}
                          </span>
                          <div className="average-rating">
                            Rating:
                            <span className="average-rating-value">
                              {Number.isInteger(averageRating)
                                ? averageRating
                                : averageRating?.toFixed?.(1)}
                            </span>
                          </div>
                        </div>
                        <div className="report-box-body">
                          <div className="rating-item-list">
                            {area.AreaItems.map(
                              (item: IAreaItem, index: number) => {
                                // only display rated items
                                if (item.Grade > 0) {
                                  return (
                                    <div
                                      className="rating-item"
                                      key={`${index}-rating-item`}
                                    >
                                      <div className="rating-item-main">
                                        {item.Name}
                                      </div>
                                      <div
                                        className={`rating-item-score ${getGradeClassName(
                                          item.Grade
                                        )}`}
                                      >
                                        {item.Grade}
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                          </div>
                          {numberOfPhotos > 0 && (
                            <div className="report-photo-list">
                              <h3>Photos</h3>
                              {area.AreaPhotos.map(
                                (photoItem: IAreaPhoto, index: number) => {
                                  return (
                                    <div
                                      className="report-photo-item"
                                      key={`${index}-report-photo-item`}
                                    >
                                      <div
                                        className="report-photo-item-img"
                                        onClick={() => previewPhoto(photoItem)}
                                      >
                                        <img
                                          src={photoItem.PathAndFileName}
                                          alt=""
                                        />
                                      </div>
                                      {areaNameInitial} Photo {index + 1}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div> //close-camila
                  );
                } else {
                  return null;
                }
              })}

              <div className="box">
                <div className="report-box-body">
                  <h3>Notes</h3>
                  <div className="notes-textarea">{Notes}</div>
                  {/* <textarea
                    id='textAreaToCopyReportPreview'
                    // className='notes-textarea'
                    onChange={() => {}}
                    style={{
                      opacity: 0
                    }}
                    disabled={true}
                    value={Notes}
                  >
                    {Notes}
                  </textarea>

                  <div className='right-box-btn'>
                    <button onClick={() => onClickCopy(Notes)}>
                      Copy Notes
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="back-to-top">
                <span
                  onClick={() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

                    // setActiveMenu('Company Info');
                  }}
                >
                  Back to Top
                </span>
              </div>
            </div>
          </div>
        </div>

        {showPreview && (
          <div className="preview-photo-root-div">
            <div
              onClick={() => {
                setShowPreview(false);
                setImageURL(null);
                setPhotoAltName('');
              }}
              className="save-as-backdrop"
            />
            <PreviewModal imageURL={imageURL} photoAltName={photoAltName} />
          </div>
        )}

        {warningModal && (
          <ActionAlertModal
            title={warningModal.title}
            message={warningModal.message}
            onConfirm={warningModal.proceed}
            onDismiss={warningModal.dismiss}
            isDismissPrimary={warningModal.isDismissPrimary}
            dismissText={warningModal.dismissText}
            confirmText={warningModal.confirmText}
            isVisible={true}
          />
        )}

        {showPdfModal.status && <PdfModal />}

        {showPdf && (
          <GenericModal
            isOpen={showPdf}
            showHeader={false}
            clickOutsideToClose={false}
            fullscreen={false}
            height="100%"
            width="100%"
            // maxWidth="1200px"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <RenderPdf
              onClickClose={() => setShowPdf(false)}
              pdfName={pdfName}
              pdfUrl={pdfUrl}
              type={pdfType}
              uid={pdfKey}
            />
          </GenericModal>
        )}

        {/* <LoadingModal localLoading={loadingDownloadPdf} /> */}
      </div>
    </FormProvider>
  );
}

const EnhancedReportPreview = withRouter(ReportPreviewScreen);

export { EnhancedReportPreview as ReportPreviewScreen };
