import React, { memo, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidSupplies } from 'modules/BidInfoForm/interfaces';
import {
  suppliesValues,
  suppliesOptions,
} from 'modules/shared/bidDefaultValues';

import {
  getSuppliesDetails,
  getSupplyByFranchiseId,
} from 'modules/BidInfoForm/services/bidInfoServiceClient';
import {
  ContentInput,
  Checkbox,
  BaseButton,
  BoxInputWithValidation,
  useFormProps,
} from 'core/components';
import { Select } from 'core/components/SelectV2';
import { moneyMask, moneyMaskParams } from 'core/utils/masks';
import Preview from 'modules/shared/sections/Summary/Supplies/Preview';
import SuppliesModal from 'modules/shared/sections/Summary/Supplies';

import './styles.scss';

interface SupplyDetail {
  SupplyDetailId: number | string;
  SupplyId: number | string;
  SupplyCategory: string;
  BrandInfo: string;
  Unit: string;
  Price: number;
  LastUpdate: string;
  UserId: number | string;
  Order: number;
  UserFirstName?: string;
  UserLastName?: string;
  Default: boolean;
}

interface Supply {
  SupplyId: number | string;
  formUniqueId?: string;
  FranchiseId: number | string;
  IntroducionText: string;
  AllowRepresentativeEdit: boolean;
  ShowPrice: boolean;
  canEdit: boolean;
  SupplyDetails: SupplyDetail[];
}

type IProps = FormikProps<IBidSupplies>;
const Supplies = ({
  onChange: onChangeSupply,
  backgroundColor,
  franchiseId,
  formUniqueId,
  bidFullData,
  isSpecialty,
  sectionId,
  canEdit,
  shouldHideToggle,
}: IProps) => {
  const [isSupplyCustomized, setIsSupplyCustomized] = React.useState(false);
  const {
    BidInformationSheetSupplys = [],
    FlgSupply,
    BidInformationSheetSupplyId,
    FlgSupplyCustomize,
  } = bidFullData;
  const [supply, setSupply] = React.useState<Supply>({
    SupplyId: '',
    FranchiseId: '',
    IntroducionText: '',
    AllowRepresentativeEdit: true,
    SupplyDetails: [],
  });
  const [showSuppliesModal, setShowSuppliesModal] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);

  const { onChange, formValues } = useFormProps();
  const [disabledCost, setDisabledCost] = useState(true);

  const onSelect = (name: string, value: any) => {
    const allValues = { ...formValues, [name]: value };
    const enableValues = ['Included in Price'];
    const enableCost =
      enableValues.includes(allValues.suppliesLiners) ||
      enableValues.includes(allValues.suppliesRestroom);
    setDisabledCost(!enableCost);

    let cost = 0;
    if (formValues.costSupplies !== '' && enableCost) {
      cost = formValues.costSupplies;
    }
    onChange(name, value);
    onChange('costSupplies', cost);
  };

  React.useEffect(() => {
    if (formValues.pid === '') {
      return;
    }
    if (formValues.suppliesRestroom) {
      onSelect('suppliesRestroom', formValues.suppliesRestroom);
    }

    if (formValues.suppliesLiners) {
      onSelect('suppliesLiners', formValues.suppliesLiners);
    }
  }, [formValues.pid]);

  const getSupply = async () => {
    const supplyResponse = await getSupplyByFranchiseId({
      franchiseId,
    });
    const defaultSupplyDetails = await getSupplyDetails(supplyResponse);
    if (
      BidInformationSheetSupplys.length > 0 &&
      BidInformationSheetSupplys[0] &&
      BidInformationSheetSupplys[0].SupplyId
    ) {
      const bidSupplyDetails = BidInformationSheetSupplys[0].SupplyDetails;

      defaultSupplyDetails.forEach((supplyDetail) => {
        const hasSupplyDetail = bidSupplyDetails.some(
          (sup) => sup.SupplyDetailId === supplyDetail.SupplyDetailId
        );
        if (!hasSupplyDetail) {
          bidSupplyDetails.push({
            ...supplyDetail,
            Default: false,
          });
        }
      });

      setSupply({
        ...supplyResponse,
        ...BidInformationSheetSupplys[0],
        SupplyId: supplyResponse.SupplyId,
        FranchiseId: supplyResponse.FranchiseId,
        SupplyDetails: bidSupplyDetails,
        ShowPrice: supplyResponse.DefaultShowPriceClient,
      });
    } else {
      setSupply({
        ...supplyResponse,
        SupplyDetails: defaultSupplyDetails,
        ShowPrice: supplyResponse.DefaultShowPriceClient,
      });
    }
  };

  const getSupplyDetails = async (supplyResponse: Supply) => {
    const { supplyDetails } = await getSuppliesDetails({
      supplyId: supplyResponse.SupplyId,
      defaultValue: '',
      supplyCategory: '',
      unit: '',
      brandInfo: '',
      limit: 999999,
      offset: 0,
      page: 1,
      order: 'ASC',
      sort: 'Order',
    });
    const { Data } = supplyDetails;
    return Data;
  };

  const openPreview = async (event) => {
    event.stopPropagation();
    setShowPreview(true);
  };

  React.useEffect(() => {
    if (franchiseId) {
      getSupply();
    }
  }, [
    franchiseId,
    BidInformationSheetSupplys?.[0]?.BidInformationSheetSupplyId,
  ]);

  React.useEffect(() => {
    setIsSupplyCustomized(!!FlgSupplyCustomize);
  }, [FlgSupplyCustomize]);

  const handleChangeSuppliesAttachment = () => {
    const newFlgSupply = !FlgSupply;
    const currentSupplies =
      newFlgSupply && !BidInformationSheetSupplys?.[0]
        ? [supply]
        : BidInformationSheetSupplys;
    onChangeSupply({
      ...bidFullData,
      BidInformationSheetSupplys: currentSupplies,
      FlgSupply: newFlgSupply,
    });
  };

  const handleSaveSupplies = (newSupply: Supply, isCustomized: boolean) => {
    setSupply(newSupply);
    setIsSupplyCustomized(FlgSupplyCustomize || isCustomized);
    onChangeSupply({
      ...bidFullData,
      BidInformationSheetSupplys: [newSupply],
      BidInformationSheetSupplyId,
      FlgSupplyCustomize: FlgSupplyCustomize || isCustomized,
    });
  };

  const handleCloseSupplyModal = (initialSupply) => {
    setShowSuppliesModal(false);
    if (initialSupply) {
      setSupply(initialSupply);
      onChangeSupply({
        ...bidFullData,
        BidInformationSheetSupplys: [initialSupply],
        BidInformationSheetSupplyId,
      });
    }
  };

  const formDisabled = formValues.status === 2;

  return (
    <>
      <div className="supplies-root" style={{ background: backgroundColor }}>
        <div className="supplies-content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              marginBottom: '20px',
            }}
          >
            {!shouldHideToggle && (
              <Checkbox
                label={
                  <div style={{ display: 'flex' }}>
                    Include
                    <a
                      className="supply-label"
                      onClick={openPreview}
                      style={{ marginLeft: '5px' }}
                    >
                      Custom Supply List
                    </a>
                  </div>
                }
                borderColor="gray"
                name="flgSupply"
                value={FlgSupply}
                disabled={formDisabled}
                onChange={handleChangeSuppliesAttachment}
                checked={FlgSupply}
              />
            )}
            <BaseButton
              isActive={isSupplyCustomized}
              onClick={() => {
                setShowSuppliesModal(true);
              }}
              disabled={formDisabled}
            >
              {isSupplyCustomized ? 'Review Customization' : 'Customize'}
            </BaseButton>
          </div>
          {!isSpecialty && (
            <div
              style={{
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '32%' }}>
                Liners:
                <Select
                  name="suppliesLiners"
                  value={formValues.suppliesLiners}
                  items={suppliesOptions}
                  onChange={onSelect}
                  disabled={formDisabled}
                  canTypeCustomValue={false}
                  blockCustomInput={true}
                />
              </div>

              <div style={{ width: '32%' }}>
                Restroom:
                <Select
                  name="suppliesRestroom"
                  value={formValues.suppliesRestroom}
                  items={suppliesOptions}
                  onChange={onSelect}
                  disabled={formDisabled}
                  canTypeCustomValue={false}
                  blockCustomInput={true}
                />
              </div>

              <div className="supplies-cost" style={{ width: '32%' }}>
                Cost of Supplies:
                <BoxInputWithValidation
                  sectionId={sectionId}
                  mask={moneyMask}
                  maskParams={{
                    ...moneyMaskParams,
                    thousandsSeparatorSymbol: true,
                  }}
                  onBlur={(name, value) => {
                    onChange(name, value);
                  }}
                  // inputStyle={boxInputWithValidationStyles}
                  inputStyle={{ flex: 1 }}
                  value={formValues.costSupplies}
                  name="costSupplies"
                  onChange={(name, value) => {
                    onChange(name, value);
                  }}
                  fieldName="Cost of Supplies"
                  width="80px"
                  disabled={disabledCost || formDisabled}
                  // color={disabledCost ? '#888' : null}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {supply.SupplyDetails && (
        <Preview
          introductionText={supply.IntroducionText}
          withDrag={false}
          onClose={() => {
            setShowPreview(false);
          }}
          supplyDetails={supply.SupplyDetails.filter((item) => item.Default)}
          opened={showPreview}
          showPrice={supply.ShowPrice}
        />
      )}
      {supply.SupplyDetails && (
        <SuppliesModal
          supply={supply}
          opened={showSuppliesModal}
          canEdit={canEdit}
          isSupplyCustomized={isSupplyCustomized}
          onClose={handleCloseSupplyModal}
          onSave={handleSaveSupplies}
        />
      )}
    </>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...suppliesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedSupplies = withFormik<any, IBidSupplies>(mapForm)(Supplies);

export { EnhancedSupplies as Supplies };
