import React, { useState } from 'react';
import classNames from 'classnames'
import { SVGIcon } from 'core/components';
import ShortChevron from './assets/ShortChevron.svg';

interface AutocompleteProps {
  label: string;
  placeholder: string;
  list: string[];
  value: string;
  filter?: boolean;
  onChange: (value: string) => void;
  error?: string;
  maxLength?: number;
  fullWidth?: boolean;
}

export const AutocompleteSummary: React.FC<AutocompleteProps> = ({
  label,
  placeholder,
  list = [],
  filter = true,
  value,
  onChange,
  error,
  maxLength,
  fullWidth,
}) => {
  const [openList, setOpenList] = useState(false);
  const filteredList = filter
    ? list.filter(itemList =>
        itemList.toLowerCase().includes(value.toLowerCase()),
      )
    : list;

  return (
    <div className={classNames("autocomplete-container", { fullWidth })}>
      <label className="autocomplete-label">{label}</label>
      <div className="autocomplete-row">
        <input
          maxLength={maxLength}
          onFocus={() => setOpenList(true)}
          className="autocomplete-input"
          value={value}
          onChange={event => onChange(event.target.value)}
          placeholder={placeholder}
          onBlur={() => {
            setTimeout(() => {
              setOpenList(false);
            }, 200);
          }}
        />
        {list.length > 0 && (
          <button
            tabIndex={-1}
            onClick={() => setOpenList(!openList)}
            className="autocomplete-button"
          >
            <SVGIcon size="10px" icon={ShortChevron} />
          </button>
        )}
      </div>
      {openList && (
        <div className="autocomplete-list-container">
          {filteredList.map((item: string) => {
            return (
              <div
                className="autocomplete-list-item"
                key={item}
                onClick={event => {
                  event.stopPropagation();
                  setOpenList(false);
                  onChange(item);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}
      {error && (
        <div className="validation-error">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default AutocompleteSummary;
