import styled from 'styled-components';

export const EditButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 16px;
  background-color: #216eab;
  color: white;
  font-family: OpenSans-Regular;
  padding: 8px 15px 9px 15px;
  height: 40px;
  border-radius: 6px;
  margin-top: 1px;
  margin-left: 10px;
  border-width: 1px;
  &:disabled {
    color: #666;
    background-color: #f1f1f1;
    cursor: unset;
  }
`;

export const EditButtonSecondary = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 16px;
  background-color: transparent;
  border-color: #216eab;
  color: #216eab;
  font-family: OpenSans-Regular;
  padding: 8px 15px 9px 15px;
  height: 40px;
  border-radius: 6px;
  margin-top: 1px;
  margin-left: 10px;
  border-width: 1px;
  &:disabled {
    color: #666;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    cursor: unset;
  }
`;

export const FormLabelSelect = styled.label`
  color: #3f3f3f;
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 0.75rem;
  padding-left: 0;
  opacity: 1;
  transition: all 0.2s;
`;
