import React from 'react';
import DoubleChevron from './assets/DoubleChevron.svg';
import Chevron from './assets/Chevron.svg';
import Arrow from './assets/Arrow.svg';
import { SVGIcon } from 'core/components';
import { TableSummaryProps } from './interfaces';
import { Fragment } from 'react';

export const TableSummary: React.FC<TableSummaryProps> = ({
  headers = [],
  data = [],
  pagination,
  loading = false,
  onSort,
  sorting,
}) => {
  const renderFirstButton = () => {
    if (pagination.page < 3) {
      return <Fragment />;
    }
    return (
      <button
        disabled={loading}
        className="pagination-button"
        onClick={() => pagination.onChangePage(pagination.page - 2)}
      >
        {pagination.page - 2}
      </button>
    );
  };

  const renderSecondButton = () => {
    if (pagination.page < 2) {
      return <Fragment />;
    }
    return (
      <button
        disabled={loading}
        className="pagination-button"
        onClick={() => pagination.onChangePage(pagination.page - 1)}
      >
        {pagination.page - 1}
      </button>
    );
  };

  const renderFourthButton = () => {
    if (pagination.page + 1 > pagination.pageCount) {
      return <Fragment />;
    }
    return (
      <button
        disabled={loading}
        className="pagination-button"
        onClick={() => pagination.onChangePage(pagination.page + 1)}
      >
        {pagination.page + 1}
      </button>
    );
  };

  const renderFifthButton = () => {
    if (pagination.page + 2 > pagination.pageCount) {
      return <Fragment />;
    }
    return (
      <button
        disabled={loading}
        className="pagination-button"
        onClick={() => pagination.onChangePage(pagination.page + 2)}
      >
        {pagination.page + 2}
      </button>
    );
  };

  const renderMiddleButton = () => {
    return (
      <button
        className="pagination-button pagination-selected"
        onClick={() => pagination.onChangePage(pagination.page)}
      >
        {pagination.page}
      </button>
    );
  };

  return (
    <div className="container">
      <table className="table">
        <thead className="table-table">
          <tr className="table-tr">
            {headers.map(
              ({
                label,
                sortable,
                width = 'auto',
                name,
                textAlign = 'left',
                CustomHeader,
                display = '',
              }) => (
                <th
                  className={sortable ? 'sortable' : ''}
                  key={label}
                  style={{ width, textAlign, display }}
                  onClick={() =>
                    sortable &&
                    onSort &&
                    onSort(
                      name,
                      !(sorting && sorting.order)
                        ? 'ASC'
                        : sorting.order === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    )
                  }
                >
                  {CustomHeader ? <CustomHeader /> : label}
                  {sortable && sorting && sorting.field === name && (
                    <div
                      className={`${
                        sorting && sorting.order !== 'ASC'
                          ? 'sort-icon-desc'
                          : 'sort-icon'
                      }`}
                    >
                      <SVGIcon size="14px" icon={Arrow} />
                    </div>
                  )}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.length <= 0 && (
            <tr>
              <td className="no-results" colSpan={3}>
                No results found
              </td>
            </tr>
          )}
          {data.map((itemData) => (
            <tr key={itemData.SupplyDetailId} className="table-tr">
              {headers.map(({ name, CustomComponent, format, tdStyle }) =>
                CustomComponent ? (
                  <td style={{ ...tdStyle }} key={name}>
                    {<CustomComponent reference={itemData} />}
                  </td>
                ) : (
                  <td style={{ ...tdStyle }} key={name}>
                    {format ? format(itemData[name], itemData) : itemData[name]}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <div className="pagination-container">
          {pagination.page !== 1 && (
            <div>
              <button
                disabled={loading}
                className="pagination-button"
                onClick={() => pagination.onChangePage(1)}
              >
                <SVGIcon size="10px" icon={DoubleChevron} />
              </button>
            </div>
          )}
          {pagination.page > 1 && (
            <div>
              <button
                disabled={loading}
                className="pagination-button"
                onClick={() => pagination.onChangePage(pagination.page - 1)}
              >
                <SVGIcon size="10px" icon={Chevron} />
              </button>
            </div>
          )}
          <div>{pagination.page - 3 > 0 ? '...' : ''}</div>
          <div>
            {renderFirstButton()}
            {renderSecondButton()}
            {renderMiddleButton()}
            {renderFourthButton()}
            {renderFifthButton()}
          </div>
          <div>{pagination.page + 3 <= pagination.pageCount ? '...' : ''}</div>
          {pagination.page <= pagination.pageCount && (
            <Fragment>
              <div>
                <button
                  disabled={loading}
                  className="pagination-button-right"
                  onClick={() => pagination.onChangePage(pagination.page + 1)}
                >
                  <SVGIcon size="10px" icon={Chevron} />
                </button>
              </div>
              <div>
                <button
                  disabled={loading}
                  className="pagination-button-right"
                  onClick={() => pagination.onChangePage(pagination.pageCount)}
                >
                  <SVGIcon size="10px" icon={DoubleChevron} />
                </button>
              </div>
            </Fragment>
          )}
          <div>
            <label>
              {`Items ${pagination.offset ? pagination.offset : 1} to ${
                pagination.offset + data.length
              } of ${pagination.count}`}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableSummary;
