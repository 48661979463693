import React, {useEffect} from 'react';
import { getQueryString } from 'core/utils';
import { getBidByUserTamplates } from 'modules/BidInfoForm/services/bidInfoService';
import debounce from 'lodash.debounce';
import './SearchModalStyles.scss';

export const SearchTemplateModal = (props) => {
  const userId = getQueryString(window.location.href, 'userId');
  const [state, setStateFunction] = React.useState({
    list: [],
    searchQuery: '',
    totalNumberOfSents: 0,
    loading: false,
  });
  const { renderTableItems, type, data: dataProps, onClickSent, onCloseModal, franchiseId, isSpecialty } = props;

  const [data, setData] = React.useState({
    'MyTemplates': [],
    'CityTemplates': [],
  });

  useEffect(() => {
    if (dataProps) {
      setData(dataProps);
      setStateFunction((prevState) => ({
        ...prevState,
        searchQuery: '',
      }));
    }
  }, [dataProps])

  const { searchQuery, loading } = state;

  const formType =
    props.type == 'Signed'
      ? 'Signed'
      : props.type
      ? props.type.substr(0, props.type.length - 1)
      : 'Signed';

  const searchLabel =
    formType.toLowerCase() === 'mytemplate'
      ? 'my template'
      : formType.toLowerCase() === 'citytemplate'
      ? 'city template'
      : formType;


  const handleSearch = () => {
    console.log('entroou')
    if (userId) {
      getBidByUserTamplates({ userId, page: 0, franchiseId, referenceName: searchQuery, isSpecialty }).then(
        (foundValues) => {
          setState({
            loading: false,
          })
          setData({
            ...data,
            [type]: foundValues[type],
          });
        }
      );
    }
  }

  const setState = (value: any) => {
    setStateFunction((prevState) => ({
      ...prevState,
      ...(value || {}),
    }));
  };

  React.useEffect(
    () => {
      const debouncedHandleSearch = debounce(handleSearch, 500);
      debouncedHandleSearch();
      
      return () => {
        debouncedHandleSearch.cancel();
      };
    },
    [searchQuery]
  );


  const onChangeInput = (text: string) => {
    setState({
      searchQuery: text,
      loading: true,
    });
  };

  return (
    <div className="search-modal-container">
      <div className="search-modal-content">
        <h2 className="search-title">Search {searchLabel} Items</h2>

        <div className="search-input">
          <input
            placeholder="Type the Template Name"
            value={searchQuery}
            onChange={(e) => onChangeInput(e.target.value)}
          />
        </div>
        {loading ? (
          <div className="loader-container">
            <div className="loading-div">
              <div className="loader-search" />
              <p>Loading ...</p>
            </div>
          </div>
        ) : (
          <table className="table-container">
            <tbody>
              {data[type].map((item, index) =>
                renderTableItems(
                  item,
                  index,
                  onClickSent,
                  formType.toLowerCase()
                )
              )}
            </tbody>
          </table>
        )}

        <div className="buttons-wrapper">
          <button className="button-close" onClick={onCloseModal}>
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};
