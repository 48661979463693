import React, { useState, useCallback, useEffect } from 'react';
// components
import { getFranchiseById } from 'modules/BidInfoForm/services/bidInfoService';
import { LoadingModal, ActionAlertModal } from 'core/components';
import { getQueryString } from 'core/utils';
import { isWebView } from 'core/utils';
import Modal from './Modal';

import {
  getPerformancesByUserId,
  getNextPagePerformance,
} from '../../services/performanceEvaluationService';
import { getInspectionURL } from '../../../BidInfoForm/utils';

export const PerformanceEvaluationMenuScreen = (props) => {
  const { search } = props.location;
  const franchiseId = getQueryString(search, 'FranchiseId');
  const userId = getQueryString(search, 'userId');
  const {
    renderTemplateSection = false,
    renderDraftSection = false,
    renderSentSection = true,
    renderSignedSection = true,
    showBackground = true,
  } = props;
  const [franchiseData, setFranchiseData] = useState([]);
  const [showGreetModal, setShowGreetModal] = useState(false);
  const [formItem, setFormItem] = useState({});
  const [data, setData] = useState({
    'Signed': [],
    'Drafts': [],
    'Sents': [],
    'Templates': [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [itemsCurrentPage, setItemsCurrentPage] = useState({
    Signed: 0,
    Templates: 0,
    Drafts: 0,
    Sents: 0,
  });

  const fetchPerformances = useCallback(() => {
    setIsLoading(true);
    getPerformancesByUserId({ userId, page: 0, pid: '', franchiseId }).then(
      (foundValues) => {
        setIsLoading(false);
        // set types to drafts, sents and templates, so we can identify the items later
        setData({
          ...data,
          ...foundValues.data,
          'Signed': [...data['Signed'], ...foundValues.data['Signed']],
          'Drafts': [...data['Drafts'], ...foundValues.data['Drafts']],
          'Sents': [...data['Sents'], ...foundValues.data['Sents']],
          'Templates': [
            ...data['Templates'],
            ...foundValues.data['Templates'],
          ],
        });
      }
    );
  }, [data, franchiseId, userId])

  useEffect(() => {
    if (userId && franchiseId) {
      fetchPerformances();
    }
  }, [userId, franchiseId]);

  useEffect(() => {
    if (franchiseId) {
      getFranchiseById(franchiseId).then(({ data }) => {
        setFranchiseData(data);
      });
    }
  }, [franchiseId]);

  const handleRefresh = () => {
    fetchPerformances();
  };

  const getPerformanceNextPage = (type: string) => {
    const nextPage = itemsCurrentPage[type] + 1;
    if (userId) {
      getNextPagePerformance({ type, userId, page: nextPage, pid: '', franchiseId }).then(
        (foundValues) => {
          setIsLoading(false);
          setItemsCurrentPage({
            ...itemsCurrentPage,
            [type]: nextPage,
          });
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            [type]: [...data[type], ...foundValues.data[type]],
          });
        }
      );
    }
  };

  const navigateToForm = (formItem, type) => {
    setFormItem(formItem);
    if (type === 'template') {
      setShowGreetModal(true);
    } else {
      openForm(formItem);
    }
  };

  const openURL = (str) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    props.history.push({
      pathname: '/performance-evaluation',
      search: `?userId=${userId}&${str}&v=${Math.random()
        .toString(36)
        .substring(7)}`,
    });
  };


  const navigate = (item, param = '') => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    if (item['PEFUniqID']) {
      openURL(`formUniqueId=${item['PEFUniqID']}&franchiseId=${item.FranchiseId}${param}`);
    } else {
      openURL(`formId=${item['PerformanceEvaluationId']}&franchiseId=${item.FranchiseId}${param}`);
    }
  };

  const openForm = (item) => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    const isSentType = item.type === 'sents';

    if (isSentType) {
      navigate(item);
    } else {
      if (item['PEFUniqID']) {
        openURL(`formUniqueId=${item['PEFUniqID']}&franchiseId=${item.FranchiseId}`);
      } else {
        openURL(`formId=${item['BidInformationSheetId']}&franchiseId=${item.FranchiseId}`);
      }
    }
  };

  const onEditTemplateClick = () => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    openForm(formItem);
  };

  const onNewDraftClick = async () => {
    if (props.doneAction) {
      props.doneAction(true);
    }
    setIsLoading(true);
  };

  return (
    <>
      {isLoading && <LoadingModal localLoading />}

      <Modal
        navigateToForm={navigateToForm}
        data={data}
        doneAction={
          props.doneAction
            ? () => props.doneAction(false)
            : () => {
                if (isWebView()) {
                  document.location.replace('/#/close-form');
                } else {
                  document.location.href = getInspectionURL();
                }
              }
        }
        background={showBackground}
        renderDraftSection={renderDraftSection}
        renderTemplateSection={renderTemplateSection}
        franchiseName={franchiseData?.AnagoOfCityName}
        renderSentSection={renderSentSection}
        renderSignedSection={renderSignedSection}
        getNextPage={getPerformanceNextPage}
        onRefresh={handleRefresh}
      />

      <ActionAlertModal
        title="Template"
        message="Do you want to edit or start a new draft based on the current template?"
        onConfirm={onNewDraftClick}
        confirmText="NEW DRAFT"
        onDismiss={onEditTemplateClick}
        dismissText="EDIT TEMPLATE"
        isVisible={showGreetModal}
      />
    </>
  );
};
