import React from 'react';
import { Heading, Wrapper, Content, Clipper, Control } from './styles';
import { Paragraph } from './styles';
import { Chevron } from 'core/components';

import { SectionTitle } from '../../../../../styles';
import { useMediaQuery } from 'react-responsive';

import { Layout, HiddenLayout } from '../../../../../components/layout';
import { Anchor } from '../../../../../components/atoms';

export default function ({ values, pageChanged }) {
  const [opened, setOpened] = React.useState(false);

  React.useEffect(() => {
    setOpened(false);
  }, [pageChanged]);

  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] =
    React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const htmlString = values['IsSS']
    ? `<ol style="padding-left: 12px"><li style="padding-left: 10px">These instructions are valid until (i) Non-Renewal of Agreement; (ii) Termination of Agreement; or (iii) Client provides written instructions to ${values['FranchiseName']} (“Company”) to the contrary.</li>
  <li style="padding-left: 10px">Amount charged to Clients on Eco-Invoice may vary based on Specialty Services requested or credits to Client.</li>
  <li style="padding-left: 10px">Client agrees to inform Company in writing if the nominated bank account is closed and shall provide Company with a secondary bank account within five (5) days of written notice should a payment be declined.</li>
  <li style="padding-left: 10px">Client shall be responsible for any bank charges due to Client account having insufficient funds. Client shall remain liable for any and all outstanding invoices.</li>
  <li style="padding-left: 10px">Client agrees to account being debited on or within five (5) days after completion of Services.</li><ol>`
    : `<ol style="padding-left: 12px"><li style="padding-left: 10px">These instructions are valid until (i) Non-Renewal of Agreement;
  (ii) Termination of Agreement; or (iii) Client provides written
  instructions to ${values['FranchiseName']} (“Company”) to the
  contrary.</li><li style="padding-left: 10px">Amount charged to Clients on Eco-Invoice may vary based
  on Specialty Services requested or credits to Client.</li><li style="padding-left: 10px">Client agrees to inform Company in writing if the nominated bank account is
  closed and shall provide Company with a secondary bank account
  within five (5) days of written notice should a payment be declined.
  </li><li style="padding-left: 10px">Client shall be responsible for any bank charges due to Client
  account having insufficient funds. Client shall remain liable for
  any and all outstanding invoices.</li><li style="padding-left: 10px">Client agrees to account being
  debited on or within five (5) days from selected payment date.
  Payment date will vary based on actual number of days in month for
  Client’s selecting Last Day.</li><ol>`;

  return (
    <Layout margin="0" width="100%" maxWidth="900px">
      <Layout padding="0">
        <Layout flexDirection="row">
          <Paragraph
            fontSize="16px"
            textAlign="center"
            paddingBottom="0 !important"
          >
            <Anchor onClick={() => setOpened(!opened)}>
              Eco-Invoice&#8482; Terms
            </Anchor>
          </Paragraph>
        </Layout>

        <HiddenLayout opened={opened} maxWidth="900px">
          <Paragraph
            marginLeft="0px"
            paddingBottom="0 !important"
            textAlign="justify"
            style={{
              whiteSpace: 'unset',
              fontSize: '10px',
            }}
            dangerouslySetInnerHTML={{ __html: htmlString }}
          />
          <Paragraph style={{ columnCount: 1, fontSize: '10px' }}>
            As an authorized agent, I hereby acknowledge and agree to
            the Eco-Invoice Terms &amp; Conditions and accept this Agreement.
          </Paragraph>
        </HiddenLayout>
      </Layout>
    </Layout>
  );
}
