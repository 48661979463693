import React from 'react';
// ui
import './styles.scss';

//magnifying glass for search
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { SearchModal } from './SearchModal';
import ActionAlertModal from 'core/components/ActionAlertModal';

const formatDate = (dateToFormat: string) =>
  dateToFormat ? new Date(dateToFormat).toLocaleDateString() : '-';

const formatTime = (timeToFormat: string) =>
  timeToFormat ? new Date(timeToFormat).toLocaleTimeString() : '-';

export default (props) => {
  const {
    renderTemplateSection,
    renderDraftSection,
    renderSignedSection,
    renderSentSection,
    onRefresh,
    franchiseName,
    data,
    background,
    doneAction,
  } = props;
  const [state, setStateFunction] = React.useState({
    sectionSelected: '',
    sortByMostRecent: true,
    azAscending: true,
    dateMostRecent: true,
    showSearchModal: false,
    showAlertModal: false,
    formSelected: null,
    currentSortFunction: () => {},
  });

  const setState = (value: any) => {
    setStateFunction((prevState) => ({
      ...prevState,
      ...(value || {}),
    }));
  };

  const setType = (arr) => {
    arr['Signed'] = arr['Signed'].map((item) => {
      item.type = 'signed';
      return item;
    });
    arr['Drafts'] = arr['Drafts'].map((item) => {
      item.type = 'drafts';
      return item;
    });
    arr['Templates'] = arr['Templates'].map((item) => {
      item.type = 'templates';
      return item;
    });
    arr['Sents'] = arr['Sents'].map((item) => {
      item.type = 'sents';
      return item;
    });
  };

  React.useEffect(() => {
    if (Object.keys(data).length > 0) {
      setType(data);

      setState({
        totalOfSignedItems: data['TotalSigned'],
        totalOfDraftItems: data['TotalDrafts'],
        totalOfSentItems: data['TotalSents'],
        totalOfTemplateItems: data['TotalTemplates'],
        signedItems: data['Signed'].sort(state.currentSortFunction),
        sentItems: data['Sents'].sort(state.currentSortFunction),
        draftItems: data['Drafts'].sort(state.currentSortFunction),
        templateItems: data['Templates'].sort(state.currentSortFunction),
      });
    }
  }, [data]);

  const setSectionSelected = (section: string) => {
    setState({ sectionSelected: section });
  };

  const onClickNote = (form: any, isSent: boolean) => {
    const { showNotesModal } = props;
    showNotesModal(!isSent, form);
  };

  const onClickDismiss = (form: any) => {
    setState({
      showAlertModal: true,
      formSelected: form,
    });
  };

  const onConfirmDeletion = () => {
    const { formSelected } = state;
    props.archiveForm(formSelected);
    setState({
      showAlertModal: false,
      formSelected: null,
    });
  };

  const onClickLoadMore = (type) => {
    props.getNextPage(type);
  };

  const onClickSearchIcon = (type) => {
    setState({
      type,
      showSearchModal: true,
    });
  };

  const truncateName = (name: string) => {
    if (name && name.length > 10) {
      return name.slice(0, 10) + '...';
    } else {
      return name;
    }
  };

  const renderTableItems = (
    item: any,
    index: number,
    onClickAction: (form: any) => void,
    type: string
  ) => {
    return (
      <tr
        data-id={item.BidInformationSheetId}
        key={index}
        onClick={() => onClickAction(item)}
      >
        <td className="td-title">{truncateName(item.PID)}</td>
        <td className="td-last-update">
          Last Updated: {formatDate(item.LastUpdate)}
          <br />
          {formatTime(item.LastUpdate)}
        </td>
        {item.Notes && (
          <td
            className="td-actions"
            style={{
              opacity:
                !item.Notes && (type === 'sent' || type === 'signed') ? 0.5 : 1,
            }}
            onClick={(e: any) => {
              e.stopPropagation(); // adding this so when pressing the EditNote button, the modal stays on the /menu route
              e.preventDefault();
              if (!!item.Notes) {
                onClickNote(item, type === 'sent' || type === 'signed');
              }
            }}
          >
            <i className="note-icon" />
            {type === 'sent' || type === 'signed' ? (
              <span>View Note</span>
            ) : (
              <span>Edit Note</span>
            )}
          </td>
        )}
        {/* <td
          className="td-actions"
          onClick={(e) => {
            e.stopPropagation();
            onClickDismiss(item);
          }}
        >
          <i className="trash-icon" />
          <span>Archive</span>
        </td> */}
      </tr>
    );
  };

  const renderSigned = () => {
    const { sectionSelected, totalOfSignedItems, signedItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>SIGNED</p>
            {sectionSelected === 'Signed' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={() => setSectionSelected('Signed')}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Signed' && (
          <table className="table-container">
            <tbody>
              {signedItems.map((item, index) =>
                renderTableItems(item, index, props.navigateToForm, 'signed')
              )}
              {signedItems.length < totalOfSignedItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('Signed')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('Signed')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderDrafts = () => {
    const { sectionSelected, totalOfDraftItems, draftItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>DRAFTS</p>
            {sectionSelected === 'Drafts' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={() => setSectionSelected('Drafts')}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Drafts' && (
          <table className="table-container">
            <tbody>
              {draftItems.map((item, index) =>
                renderTableItems(item, index, props.navigateToForm, 'draft')
              )}
              {draftItems.length < totalOfDraftItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('Drafts')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('Drafts')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderTemplates = () => {
    const { sectionSelected, totalOfTemplateItems, templateItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>TEMPLATES</p>
            {sectionSelected === 'Templates' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={() => setSectionSelected('Templates')}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Templates' && (
          <table className="table-container">
            <tbody>
              {templateItems.map((item, index) =>
                renderTableItems(
                  item,
                  index,
                  (formItem) => props.navigateToForm(formItem, 'template'),
                  'template'
                )
              )}
              {templateItems.length < totalOfTemplateItems && (
                <tr className="load-more-wrapper">
                  <button
                    className="button-secondary"
                    onClick={() => onClickLoadMore('Templates')}
                  >
                    LOAD MORE
                  </button>
                  <div onClick={() => onClickSearchIcon('Templates')}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="2x"
                      color="#216eab"
                    />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderSent = () => {
    const { sectionSelected, totalOfSentItems, sentItems } = state;
    return (
      <div className="section-container">
        <div className="title-container">
          <div className="content-container">
            <p>SENT</p>
            {sectionSelected === 'Sent' ? (
              <i
                className="arrow-up-icon"
                onClick={() => setSectionSelected('')}
              />
            ) : (
              <i
                className="arrow-down-icon"
                onClick={() => setSectionSelected('Sent')}
              />
            )}
          </div>
        </div>
        {sectionSelected === 'Sent' && (
          <table className="table-container">
            <tbody>
              {sentItems.map((item, index) =>
                renderTableItems(item, index, props.navigateToForm, 'sent')
              )}
              {
                // when there is more than 20 items in the backend and we have less items loaded in the frontend than the number found in the backend, show both buttons
                totalOfSentItems > 20 &&
                  sentItems.length < totalOfSentItems && (
                    <tr className="load-more-wrapper">
                      <button
                        className="button-secondary"
                        onClick={() => onClickLoadMore('Sents')}
                      >
                        LOAD MORE
                      </button>
                      <div onClick={() => onClickSearchIcon('Sents')}>
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="2x"
                          color="#216eab"
                        />
                      </div>
                    </tr>
                  )
              }
              {
                // show only search icon if there is no more items to load from backend
                sentItems.length < totalOfSentItems && (
                  <tr className="load-more-wrapper">
                    <td>
                      <div onClick={() => onClickSearchIcon('Sents')}>
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="2x"
                          color="#216eab"
                        />
                      </div>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const closeModal = () => {
    setState({
      showModal: false,
    });
    if (doneAction) {
      doneAction();
    }
  };

  const closeSearchModal = () => {
    setState({
      showSearchModal: false,
    });
  };

  const sortByDate = () => {
    const sortFunction = (a, b) => {
      const field = a.LastChange ? 'LastChange' : a.Date ? 'Date' : 'CreatedAt';
      if (state.dateMostRecent) {
        return new Date(a[field]) - new Date(b[field]);
      }
      return new Date(b[field]) - new Date(a[field]);
    };
    const currSortFunc = sortFunction;
    setState({
      sortByMostRecent: true,
      dateMostRecent: state.sortByMostRecent ? !state.dateMostRecent : true, // if condition is false, it means it came from AZ to date button, so we set the initial state, otherwise, we just toggle
      azAscending: true, // settting it true so when clicking the date button again, we see the labels changing back to its default value
      draftItems: state.draftItems.sort(sortFunction),
      sentItems: state.sentItems.sort(sortFunction),
      templateItems: state.templateItems.sort(sortFunction),
      currentSortFunction: currSortFunc,
    });
  };

  const sortByAZ = () => {
    const sortFunction = (a, b) => {
      if (state.azAscending && !state.sortByMostRecent) {
        return b.PID ? b.PID.localeCompare(a.PID) : -1;
      }
      return a.PID ? a.PID.localeCompare(b.PID) : -1;
    };

    const currSortFunc = sortFunction;

    setState({
      sortByMostRecent: false,
      dateMostRecent: false,
      azAscending: state.sortByMostRecent ? true : !state.azAscending, // if condition is true, it means it came from the date button, so we set the initial state, otherwise, we just toggle
      // dateMostRecent: false, // settting it false so when clicking to sort by date it returns to its default value
      draftItems: state.draftItems.sort(sortFunction),
      sentItems: state.sentItems.sort(sortFunction),
      templateItems: state.templateItems.sort(sortFunction),
      currentSortFunction: currSortFunc,
    });
  };

  const { showModal, sortByMostRecent, showSearchModal } = state;
  // const { showModal, showSearchModal } =  state;

  const getSortButtonClass = (type: 'date' | 'alpha') => {
    if (type === 'date') {
      return sortByMostRecent ? 'button' : 'button-secondary';
    } else {
      return sortByMostRecent ? 'button-secondary' : 'button';
    }
  };

  return (
    <>
      <div
        className={
          background ? 'modal-background-bid' : 'modal-bid-no-background'
        }
      >
        <div className="modal-content-container">
          <div className="modal-title-container">
            <h3>PERFORMANCE EVALUATION</h3>
            <div className="buttons-row">
              <p>Sort by:</p>
              <button
                className={`button-filter-date ${getSortButtonClass('date')} ${
                  state.dateMostRecent ? 'button-invert' : ''
                }`}
                onClick={() => sortByDate()}
              >
                Date
              </button>
              <button
                className={`button-filter-sort ${getSortButtonClass('alpha')}`}
                onClick={() => sortByAZ()}
              >
                {state.azAscending ? 'A-Z' : 'Z-A'}
              </button>
              {/* {sortByMostRecent ? "Sorting by Most Recent" : "Sorting by A-Z"} */}
            </div>
          </div>
          <div className="bid-menu-container">
            <div
              style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>{franchiseName}</h3>
              <span className={`refresh-icon`} onClick={onRefresh} />
            </div>
            {renderDraftSection && renderDrafts()}
            {renderTemplateSection && renderTemplates()}
            {renderSentSection && renderSent()}
            {renderSignedSection && renderSigned()}
          </div>
        </div>
        <div className="modal-done-button" onClick={() => closeModal()}>
          <button className="button">DONE</button>
        </div>
      </div>

      {showSearchModal && (
        <SearchModal
          type={state.type}
          onCloseModal={closeSearchModal}
          renderTableItems={renderTableItems}
          onClickSent={props.navigateToForm}
        />
      )}

      <ActionAlertModal
        title=""
        message="Are you sure you want to archive this item?"
        onConfirm={() => onConfirmDeletion()}
        // onDismiss={ props.closeExitModal}
        onDismiss={() => {
          setState({
            showAlertModal: false,
            formSelected: null,
          });
        }}
        isVisible={state.showAlertModal}
      />
    </>
  );
};
