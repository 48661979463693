const performanceKeys = {
  pid: 'PID',
  company: 'Company',
  personContacted: 'PersonContacted',
  suggestions: 'Suggestions',
  companyRepresentative: 'OperationsManagerName',
  sentStatus: 'SentStatus',
  isTemplate: 'IsTemplate',
  uid: 'PEFUniqID',
  pefId: 'PerformanceEvaluationId',
  createdBy: 'CreatedBy',
  printName: 'PrintName',
  emailAddress: 'EmailAddress',
  ipAddress: 'IpAddress',
  franchiseId: 'FranchiseId',
  generatePdf: 'GeneratePDF',
  signatureId: 'ClientSignatureImageId',
  isCompleted: 'IsComplete',
  pdfPath: 'Pdfpath',
  locationId: 'LocationId',
};

export const parseValues = (values: any, isFromApi?: boolean, keys?: any) => {
  if (!values) return {};
  keys = keys || performanceKeys;
  let parsedValues = {};
  Object.keys(keys).forEach((key: string) => {
    parsedValues = {
      ...parsedValues,
      [isFromApi ? key : keys[key]]: values[isFromApi ? keys[key] : key],
    };
  });

  if (isFromApi) {
    const el = [
      { idx: 1, value: 'Superior' },
      { idx: 2, value: 'Excellent' },
      { idx: 2.5, value: 'Good' },
      { idx: 3, value: 'Fair' },
    ].find((el, i) => el.idx === values['NewDomainSatisfactionLevelId']);

    parsedValues['selectedSatisfaction'] = el ? el.value : null;

    parsedValues['date'] = values['Date'] ? new Date(values['Date']) : null;
    parsedValues['signatureUri'] = values['ClientSignatureImageDTO']
      ? values['ClientSignatureImageDTO']['Path']
      : null;
  } else {
    const el = [
      { idx: 1, value: 'Superior' },
      { idx: 2, value: 'Excellent' },
      { idx: 2.5, value: 'Good' },
      { idx: 3, value: 'Fair' },
    ].find((el) => el.value === values.selectedSatisfaction);

    parsedValues['NewDomainSatisfactionLevelId'] = el ? el.idx : null;

    parsedValues['NewDomainSatisfactionLevelId'] =
      parsedValues['NewDomainSatisfactionLevelId'] > 0
        ? parsedValues['NewDomainSatisfactionLevelId']
        : 0;

    parsedValues['Date'] = values['date'];
    parsedValues['PID'] = values.pid ? values.pid.toUpperCase() : values.pid;
  }

  return parsedValues;
};
