import React, { useEffect, useState } from 'react';
import {
  AutocompleteSummary as Autocomplete,
  Modal,
  ButtonComponent,
  RadioButton,
} from 'core/components';
import { getQueryString } from 'core/utils';
import SaveIcon from './assets/SaveIcon.svg';
import BackIcon from './assets/BackIcon.svg';
import { toast } from 'react-toastify';
import { insertReferenceDetail } from 'modules/BidInfoForm/services/bidInfoServiceClient';
import { ReferenceDetail } from 'modules/shared/sections/Summary';

interface EditModalProps {
  opened: boolean;
  onClose: () => void;
  header: string;
  onCreate: (referenceDetail: ReferenceDetail) => void;
  onUpdate: (referenceDetail: ReferenceDetail) => void;
  names: string[];
  industries: string[];
  companies: string[];
  titles: string[];
  isEdit: boolean;
  referenceId: string | number;
  referenceDetailReference?: ReferenceDetail;
}

export const EditModal: React.FC<EditModalProps> = ({
  opened,
  onClose,
  header,
  industries,
  referenceId,
  isEdit,
  referenceDetailReference,
  onUpdate,
  onCreate,
}) => {
  const currentUrl = window.location.href;
  const userId = getQueryString(currentUrl, 'userId');

  const [referenceDetail, setReferenceDetail] = useState<{
    Title: string;
    ContactInfo: string;
    ContactType: string;
    Company: string;
    Name: string;
    Type: string;
    Industry: string;
    TestimonialReview: string;
  }>({
    Title: '',
    ContactInfo: '',
    ContactType: '',
    Company: '',
    Type: '1',
    Name: '',
    Industry: '',
    TestimonialReview: '',
  });

  const [errors, setErrors] = useState({
    Title: false,
    Company: false,
    ContactInfo: false,
    ContactType: false,
    Type: false,
    Name: false,
    TestimonialReview: false,
    Industry: false,
  });

  useEffect(() => {
    if (isEdit && referenceDetailReference) {
      setReferenceDetail({
        Title: referenceDetailReference.Title,
        Type: referenceDetailReference.Type,
        Name: referenceDetailReference.Name,
        Company: referenceDetailReference.Company,
        TestimonialReview: referenceDetailReference.TestimonialReview,
        ContactInfo: referenceDetailReference.ContactInfo,
        ContactType: referenceDetailReference.ContactType,
        Industry: referenceDetailReference.Industry,
      });
    } else if (!isEdit && opened) {
      setReferenceDetail({
        Title: '',
        ContactInfo: '',
        ContactType: '',
        Type: '1',
        Company: '',
        Name: '',
        Industry: '',
        TestimonialReview: '',
      });
    }

    setErrors({
      Title: false,
      Company: false,
      Name: false,
      Type: false,
      Industry: false,
      ContactInfo: false,
      ContactType: false,
      TestimonialReview: false,
    });
  }, [isEdit, referenceDetailReference, opened]);

  const handleClose = () => {
    setReferenceDetail({
      Title: '',
      ContactInfo: '',
      ContactType: '',
      Company: '',
      Type: '1',
      Name: '',
      Industry: '',
      TestimonialReview: '',
    });

    setErrors({
      Title: false,
      Company: false,
      ContactInfo: false,
      ContactType: false,
      Type: false,
      Name: false,
      TestimonialReview: false,
      Industry: false,
    });

    onClose();
  };

  const createReferenceDetail = async () => {
    const validated = validateFields();

    if (validated) {
      const newReferenceDetail = {
        ReferenceId: referenceId,
        Name: referenceDetail.Name,
        Type: referenceDetail.Type,
        Title: referenceDetail.Title,
        Company: referenceDetail.Company,
        Industry: referenceDetail.Industry,
        ContactInfo: referenceDetail.ContactInfo,
        ContactType: referenceDetail.ContactType,
        TestimonialReview: referenceDetail.TestimonialReview,
        UserId: userId,
        Order: 1,
        Default: false,
      };

      try {
        const { request } = await insertReferenceDetail(newReferenceDetail);
        toast.info('Reference created successfully');
        onCreate(request);
        handleClose();
      } catch {
        toast.error('Error!', {
          icon: false,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        });
      }
    }
  };

  const editReferenceDetail = async () => {
    const validated = validateFields();
    if (referenceDetailReference && validated) {
      const newReferenceDetail = {
        ...referenceDetailReference,
        Name: referenceDetail.Name,
        Type: referenceDetail.Type,
        Title: referenceDetail.Title,
        Company: referenceDetail.Company,
        Industry: referenceDetail.Industry,
        ContactInfo: referenceDetail.ContactInfo,
        ContactType: referenceDetail.ContactType,
        TestimonialReview: referenceDetail.TestimonialReview,
      };

      try {
        onUpdate(newReferenceDetail);
        handleClose();
      } catch {
        toast.error('Error!', {
          icon: false,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        });
      }
    }
  };

  const validateFields = () => {
    const newErrors = {
      Title: false,
      Type: false,
      Company: false,
      Name: false,
      ContactInfo: false,
      Industry: false,
      TestimonialReview: false,
      ContactType: false,
    };

    let valid = true;

    const validateContact = (contact, contactType) => {
      if (contactType === 'Cell' && contact.replace(/\D/g, '').length === 10) {
        return true;
      }
      if (contactType === 'Work' && contact.replace(/\D/g, '').length >= 10) {
        return true;
      }
      return false;
    };

    if (!referenceDetail.Name.trim()) {
      newErrors.Name = true;
      valid = false;
    }

    if (referenceDetail.Type == '3' && !referenceDetail.Company.trim()) {
      newErrors.Company = true;
      valid = false;
    }

    if (
      (referenceDetail.Type == '2' || referenceDetail.Type == '3') &&
      !referenceDetail.Title.trim()
    ) {
      newErrors.Title = true;
      valid = false;
    }

    if (referenceDetail.Type == '3' && !referenceDetail.ContactInfo.trim()) {
      newErrors.ContactInfo = true;
      valid = false;
    }

    if (
      referenceDetail.Type == '3' &&
      (!referenceDetail.ContactType || !referenceDetail.ContactType.trim())
    ) {
      newErrors.ContactType = true;
      valid = false;
    }

    if (
      referenceDetail.Type == '3' &&
      (referenceDetail.ContactType === 'Cell' ||
        referenceDetail.ContactType === 'Work')
    ) {
      if (
        !validateContact(
          referenceDetail.ContactInfo,
          referenceDetail.ContactType
        )
      ) {
        newErrors.ContactInfo = true;
        valid = false;
      }
    }

    if (
      referenceDetail.Type == '3' &&
      referenceDetail.ContactType === 'Email' &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(referenceDetail.ContactInfo)
    ) {
      newErrors.ContactInfo = true;
      valid = false;
    }

    if (
      (referenceDetail.Type == '1' || referenceDetail.Type == '2') &&
      (!referenceDetail.TestimonialReview ||
        !referenceDetail.TestimonialReview.trim())
    ) {
      newErrors.TestimonialReview = true;
      valid = false;
    }

    if (
      referenceDetail.TestimonialReview &&
      referenceDetail.TestimonialReview.trim().length > 4000
    ) {
      newErrors.TestimonialReview = true;
      valid = false;
    }

    if (!referenceDetail.Type) {
      newErrors.Type = true;
      valid = false;
    }

    setErrors(newErrors);

    return valid;
  };

  const typeOptions = [
    {
      value: '1',
      label: 'Google Review',
    },
    {
      value: '2',
      label: 'Testimonial',
    },
    {
      value: '3',
      label: 'References',
    },
  ];

  const formatContactInfo = (value) => {
    if (referenceDetail.ContactType === 'Cell') {
      const formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length === 10) {
        return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6, 10)}`;
      }
    } else if (referenceDetail.ContactType === 'Work') {
      const formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length === 10) {
        return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6, 10)}`;
      } else if (formattedValue.length === 13) {
        return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(
          3,
          6
        )}-${formattedValue.slice(6, 10)} ${formattedValue.slice(10, 13)}`;
      }
    }

    return value;
  };

  const handleChangeType = (name: string, value: string) => {
    setReferenceDetail({ ...referenceDetail, [name]: value });

    if (value === 'Cell' || value === 'Work') {
      setReferenceDetail((prevState) => ({
        ...prevState,
        ContactInfo: '',
      }));
    }
  };

  const maxChar =
    referenceDetail.TestimonialReview &&
    referenceDetail.TestimonialReview.trim().length > 4000;

  const contactTypeOptions = ['Blank', 'Email', 'Cell', 'Work'];

  const getMaxLengthContact = () => {
    if (referenceDetail.ContactType === 'Cell') {
      return 14;
    }

    if (referenceDetail.ContactType === 'Work') {
      return 22;
    }

    return 256;
  };

  return (
    <Modal header={header} onClose={handleClose} opened={opened}>
      <div className="modal-content modal-content-reference">
        <div className="modal-content-row  between">
          <Autocomplete
            fullWidth
            onChange={(value) =>
              setReferenceDetail({ ...referenceDetail, Name: value })
            }
            value={referenceDetail.Name}
            list={[]}
            placeholder="Name"
            maxLength={128}
            label="Name"
            error={errors.Name ? 'This field is required' : ''}
          />
        </div>
        <div className="modal-content-row between">
          <Autocomplete
            onChange={(value) =>
              setReferenceDetail({ ...referenceDetail, Industry: value })
            }
            maxLength={128}
            value={referenceDetail.Industry}
            list={industries}
            error={errors.Industry ? 'This field is required' : ''}
            placeholder="Industry"
            label="Industry"
          />
          <Autocomplete
            onChange={(value) =>
              setReferenceDetail({ ...referenceDetail, Company: value })
            }
            maxLength={128}
            value={referenceDetail.Company}
            list={[]}
            error={errors.Company ? 'This field is required' : ''}
            placeholder="Company"
            label="Company"
          />
        </div>
        <div className="modal-content-row between">
          <Autocomplete
            fullWidth
            onChange={(value) =>
              setReferenceDetail({ ...referenceDetail, Title: value })
            }
            list={[]}
            value={referenceDetail.Title}
            maxLength={128}
            placeholder="Title"
            error={errors.Title ? 'This field is required' : ''}
            label="Title"
          />
        </div>
        <div className="modal-content-row between">
          <Autocomplete
            onChange={(value) => {
              setReferenceDetail({
                ...referenceDetail,
                ContactInfo: value,
              });
            }}
            maxLength={getMaxLengthContact()}
            value={formatContactInfo(referenceDetail.ContactInfo)}
            list={[]}
            error={
              errors.ContactInfo
                ? 'Invalid Contact Info! Please verify and try again'
                : ''
            }
            placeholder="Contact Info"
            label="Contact Info"
          />
          <Autocomplete
            filter={false}
            onChange={(value) =>
              setReferenceDetail({
                ...referenceDetail,
                ContactType: value === 'Blank' ? '' : value,
              })
            }
            value={referenceDetail.ContactType}
            list={contactTypeOptions}
            error={errors.ContactType ? 'This field is required' : ''}
            placeholder="Contact Type"
            label="Contact Type"
          />
        </div>
        <div className="modal-content-row center" style={{ marginBottom: 0 }}>
          <textarea
            className="testimonial-textarea"
            value={referenceDetail && referenceDetail.TestimonialReview}
            placeholder="Testimonial / Review"
            onChange={(event) =>
              setReferenceDetail({
                ...referenceDetail,
                TestimonialReview: event.target.value,
              })
            }
          />
        </div>
        <div className="modal-content-row between">
          <div className="validation-error">
            {errors.TestimonialReview && !maxChar && 'This field is required'}
            {maxChar &&
              'This text is too long! It cannot exceed the limit of 4000 characters!'}
          </div>
        </div>
        <div className="modal-content-row center" style={{ marginBottom: 0 }}>
          <RadioButton
            value={String(referenceDetail.Type)}
            onChange={handleChangeType}
            name="Type"
            options={typeOptions}
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div className="modal-content-row center">
          <div className="validation-error">
            {errors.Type && 'This field is required'}
          </div>
        </div>
      </div>
      <div className="confirm-buttons edit-buttons">
        <ButtonComponent icon={BackIcon} onClick={handleClose}>
          CANCEL
        </ButtonComponent>
        <ButtonComponent
          icon={SaveIcon}
          onClick={() => {
            isEdit ? editReferenceDetail() : createReferenceDetail();
          }}
          secondary
        >
          SAVE
        </ButtonComponent>
      </div>
    </Modal>
  );
};

export default EditModal;
