import React, { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useFormProps } from '../components';

const STACK_SIZE = 50;

const getMbSize = (object) => {
  const size = new TextEncoder().encode(JSON.stringify(object)).length
  const mbSize = size / 1024 / 1024;
  return mbSize;
}

export const useActivityTracking = (getValuesFunction) => {
  const [stack, setStack] = React.useState([]);

  const logSentry = useCallback((e: any, values: any) => {
    Sentry.captureException(e, {
      extra: {
        CLICK_STACK: stack.reverse(),
        FORM_VALUES: values,
      },
    });
  }, [stack]);

  React.useEffect(useCallback(() => {
    document.body.addEventListener('click', (event) => {

      const htmlTemp = event.target.outerHTML.toString();
      let divInner = document.createElement('div');
      divInner.innerHTML = htmlTemp;
      let toRemoveSvgs = divInner.getElementsByTagName('svg');
      let toRemoveSunEditor = divInner.getElementsByClassName('sun-editor');

      while(toRemoveSvgs.length > 0){
        toRemoveSvgs[0].parentNode.removeChild(toRemoveSvgs[0])
      }

      while(toRemoveSunEditor.length > 0){
        toRemoveSunEditor[0].parentNode.removeChild(toRemoveSunEditor[0])
      }

      const html = divInner.innerHTML

      const parentHTMLTemp= event.target.parentNode && event.target.parentNode.innerHTML.toString();

      let divOuter = document.createElement('div');
      divOuter.innerHTML = parentHTMLTemp;
      toRemoveSvgs = divOuter.getElementsByTagName('svg');
      toRemoveSunEditor = divOuter.getElementsByClassName('sun-editor');
      
      while(toRemoveSvgs.length > 0){
        toRemoveSvgs[0].parentNode.removeChild(toRemoveSvgs[0])
      }

      while(toRemoveSunEditor.length > 0){
        toRemoveSunEditor[0].parentNode.removeChild(toRemoveSunEditor[0])
      }

      const parentHTML = divOuter.innerHTML

      const fieldInfo = {
        html,
        parentHTML
      };

      if (event.target.id) {
        fieldInfo.id = event.target.id;
      }
      if (event.target.dataCy || event.target.getAttribute('data-cy')) {
        fieldInfo.dataCy =
          event.target.dataCy || event.target.getAttribute('data-cy');
      }

      fieldInfo.timestamp = new Date().toString();

      setStack((st) => {
        const l = [
          ...st,
          {
            fieldInfo,
          },
        ];
        if (l.length >= STACK_SIZE) {
          l.shift();
        }

        return l;
      });
    });
  },[]), []);

  React.useEffect(()=>{
    const values = [...stack];
    let mbSize = getMbSize(values);

    try{
      if(mbSize > 3){
        while(mbSize > 3){
          values.shift();
          mbSize = getMbSize(values);
        }
  
        setStack(values);
      }else{
        sessionStorage.setItem(
          'SENTRY_DATA',
          JSON.stringify({
            CLICK_STACK: values
              .reverse()
              .map((i) => JSON.stringify(i.fieldInfo, null, 2)),
            FORM_VALUES: getValuesFunction(),
          })
        );
      }
    }catch(e){
      logSentry(e, values);
    }
  },[stack])

  return logSentry
}

 



  