import React, { useCallback, useEffect, useState } from 'react';

import {
  ContentInput,
  DisabledBoxValue as BoxValue,
  useFormProps,
  InputFloatLabelWithValidation,
} from 'core/components';

import {
  calculateDailyManInventory,
  calculateDailyManProduction,
  calculateDailyManFixtures,
  calculateMonthlyBids,
  calculateWeeklyBids,
  calculateSmartCleanBasePrice,
  calculateSmartCleanTotal,
  mathRoundToFixed1,
  mathRoundToFixed0,
} from 'modules/BidInfoForm/utils';

import { withFormik, FormikProps } from 'formik';

import { dailyManHoursValues } from 'modules/shared/bidDefaultValues';

import { IBidDailyManHours } from 'modules/BidInfoForm/interfaces';

import {
  moneyMask,
  moneyMaskParams,
  formatNumberWithCommas,
} from 'core/utils/masks';
import { convertStringToNumber, formatPrice } from 'core/utils';

import './styles.scss';

const formatNumberStringAsCurrency = (value: string) => {
  return value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

type IProps = FormikProps<IBidDailyManHours>;
const DailyManHours = React.memo(({ values, setFieldValue }: IProps) => {
  const { formValues, setFormValues, onChange } = useFormProps();
  const [oldFixtures, setOldFixtures] = useState();
  const [oldRates, setOldRates] = useState();
  const [bidCalculatedBasePrice, setBidCalculatedBasePrice] = useState();
  const [calculatedPrice, setCalculatedPrice] = useState();
  const [secondBidCalculatedBasePrice, setSecondBidCalculatedBasePrice] =
    useState();
  const [calculatedSmartCleanBasePrice, setCalculatedSmartCleanBasePrice] =
    useState();
  const [calculatedSmartCleanPrice, setCalculatedSmartCleanPrice] = useState();
  const [secondCalculatedPrice, setSecondCalculatedPrice] = useState();
  const {
    inventory,
    productionRates,
    restroomFixtures,
    totalManHrs,
    totalSecondOfficeManHrs,
    cleaning,
    secondCleaning,
    frequencyService,
    frequency,
    addFrequency,
    secondFrequency,
    monthlyAddFrequency,
    smartClean,
    includeInPrice,
    porterOverride,
    secondPorterOverride,
    monthlyPorterPrice,
    costSupplies,
    overrideCostSupplies,
    secondOverrideCostSupplies,
    userHasBetaOptIn,
    expirationDays,
    bidPriceHour,
    bidPriceHourSecondFrequency,
    bidOverrideBasePrice,
    secondBidOverrideBasePrice,
  } = formValues;
  const [biggerThan1024, setBiggerThan1024] = React.useState(false);

  useEffect(() => {
    if (
      formValues.calculatedPrice != undefined &&
      formValues.calculatedPrice != calculatedPrice
    ) {
      setCalculatedPrice(formValues.calculatedPrice);
    }

    if (
      formValues.secondCalculatedPrice != undefined &&
      formValues.secondCalculatedPrice != secondCalculatedPrice
    ) {
      setSecondCalculatedPrice(formValues.secondCalculatedPrice);
    }

    if (
      formValues.calculatedSmartCleanPrice != undefined &&
      formValues.calculatedSmartCleanPrice != calculatedSmartCleanPrice
    ) {
      setCalculatedSmartCleanPrice(formValues.calculatedSmartCleanPrice);
    }
  }, [
    formValues.calculatedPrice,
    formValues.secondCalculatedPrice,
    formValues.calculatedSmartCleanPrice,
  ]);

  useEffect(() => {
    if (
      formValues.smartCleanCalculatedBasePrice != undefined &&
      formValues.smartCleanCalculatedBasePrice != calculatedSmartCleanBasePrice
    ) {
      setCalculatedSmartCleanBasePrice(
        formValues.smartCleanCalculatedBasePrice
      );
    }
  }, [formValues.smartCleanCalculatedBasePrice]);

  useEffect(() => {
    if (
      bidCalculatedBasePrice != undefined &&
      formValues.bidCalculatedBasePrice != bidCalculatedBasePrice
    ) {
      setTimeout(() => {
        onChange('bidCalculatedBasePrice', bidCalculatedBasePrice);
      }, 0);
    }
  }, [bidCalculatedBasePrice]);

  useEffect(() => {
    if (
      calculatedSmartCleanBasePrice != undefined &&
      formValues.smartCleanCalculatedBasePrice != calculatedSmartCleanBasePrice
    ) {
      setTimeout(() => {
        onChange(
          'smartCleanCalculatedBasePrice',
          calculatedSmartCleanBasePrice
        );
      }, 0);
    }
  }, [calculatedSmartCleanBasePrice]);

  const onBlurSmartClean = useCallback(() => {
    // console.log({
    //   b1: calculatedSmartCleanBasePrice,
    //   b2: formValues.smartCleanCalculatedBasePrice
    // })
    // if (
    //   calculatedSmartCleanBasePrice != undefined &&
    //   formValues.smartCleanCalculatedBasePrice != calculatedSmartCleanBasePrice
    // ) {
    //   setTimeout(() => {
    //     onChange('smartCleanCalculatedBasePrice', calculatedSmartCleanBasePrice);
    //   }, 0);
    // }
  }, [calculatedSmartCleanBasePrice]);

  useEffect(() => {
    if (
      calculatedSmartCleanPrice != undefined &&
      formValues.calculatedSmartCleanPrice != calculatedSmartCleanPrice
    ) {
      setTimeout(() => {
        onChange('calculatedSmartCleanPrice', calculatedSmartCleanPrice);
      }, 0);
    }
  }, [calculatedSmartCleanPrice]);

  useEffect(() => {
    if (
      secondBidCalculatedBasePrice != undefined &&
      formValues.secondBidCalculatedBasePrice != secondBidCalculatedBasePrice
    ) {
      setTimeout(() => {
        onChange('secondBidCalculatedBasePrice', secondBidCalculatedBasePrice);
      }, 0);
    }
  }, [secondBidCalculatedBasePrice]);

  useEffect(() => {
    if (
      calculatedPrice != undefined &&
      formValues.calculatedPrice != calculatedPrice
    ) {
      setTimeout(() => {
        onChange('calculatedPrice', calculatedPrice);
      }, 0);
    }
  }, [calculatedPrice]);

  useEffect(() => {
    if (
      secondCalculatedPrice != undefined &&
      formValues.secondCalculatedPrice != secondCalculatedPrice
    ) {
      setTimeout(() => {
        onChange('secondCalculatedPrice', secondCalculatedPrice);
      }, 0);
    }
  }, [secondCalculatedPrice]);

  const calculateMonthlyQuotes = (
    bidPriceHour,
    bidOverrideBasePrice,
    secondBidPriceHour,
    secondBidOverrideBasePrice,
    isSmartClean = false
  ) => {
    const { calculatedPrice, basePrice: bidCalculatedBasePrice } =
      calculateMonthlyBids(
        { totalManHrs },
        { cleaning, frequencyService },
        { includeInPrice, porterOverride, monthlyPorterPrice },
        { costSupplies: overrideCostSupplies || costSupplies },
        bidPriceHour,
        bidOverrideBasePrice,
        isSmartClean
          ? 0
          : formValues.protectionIncludedInPrice
          ? formValues.protectionPackages
          : []
      );

    const {
      calculatedPrice: secondCalculatedPrice,
      basePrice: secondBidCalculatedBasePrice,
    } = calculateMonthlyBids(
      { totalManHrs: totalSecondOfficeManHrs },
      { cleaning: parseInt(secondCleaning), frequencyService },
      { includeInPrice, porterOverride: porterOverride, monthlyPorterPrice },
      { costSupplies: overrideCostSupplies || costSupplies },
      secondBidPriceHour,
      secondBidOverrideBasePrice,
      isSmartClean
        ? 0
        : formValues.secondProtectionIncludedInPrice
        ? formValues.secondProtectionPackages
        : []
    );

    return {
      calculatedPrice,
      bidCalculatedBasePrice,
      secondCalculatedPrice,
      secondBidCalculatedBasePrice,
    };

    // setCalculatedPrice(calculatedPrice);
    // setBidCalculatedBasePrice(bidCalculatedBasePrice);
    // setSecondCalculatedPrice(secondCalculatedPrice);
    // setSecondBidCalculatedBasePrice(secondBidCalculatedBasePrice);
  };

  const calculateWeeklyQuotes = (
    bidPriceHour,
    bidOverrideBasePrice,
    secondBidPriceHour,
    secondBidOverrideBasePrice,
    isSmartClean = false
  ) => {
    const { calculatedPrice, basePrice: bidCalculatedBasePrice } =
      calculateWeeklyBids(
        { totalManHrs: totalManHrs || 0 },
        { frequencyService, frequency },
        { includeInPrice, porterOverride, monthlyPorterPrice },
        { costSupplies: overrideCostSupplies || costSupplies },
        convertStringToNumber(bidPriceHour),
        convertStringToNumber(bidOverrideBasePrice),
        false,
        isSmartClean
          ? 0
          : formValues.protectionIncludedInPrice
          ? formValues.protectionPackages
          : []
      );
    const {
      calculatedPrice: secondCalculatedPrice,
      basePrice: secondBidCalculatedBasePrice,
    } = calculateWeeklyBids(
      { totalSecondOfficeManHrs: totalSecondOfficeManHrs || 0 },
      { frequencyService, secondFrequency },
      { includeInPrice, porterOverride: porterOverride, monthlyPorterPrice },
      { costSupplies: overrideCostSupplies || costSupplies },
      convertStringToNumber(secondBidPriceHour),
      convertStringToNumber(secondBidOverrideBasePrice),
      true,
      isSmartClean
        ? 0
        : formValues.secondProtectionIncludedInPrice
        ? formValues.secondProtectionPackages
        : []
    );
    return {
      calculatedPrice,
      bidCalculatedBasePrice,
      secondCalculatedPrice,
      secondBidCalculatedBasePrice,
    };

    // setCalculatedPrice(calculatedPrice);
    // setBidCalculatedBasePrice(bidCalculatedBasePrice);
    // setSecondCalculatedPrice(secondCalculatedPrice);
    // setSecondBidCalculatedBasePrice(secondBidCalculatedBasePrice);
  };

  useEffect(() => {
    if (frequencyService === 'monthly') {
      const {
        calculatedPrice,
        bidCalculatedBasePrice,
        secondCalculatedPrice,
        secondBidCalculatedBasePrice,
      } = calculateMonthlyQuotes(
        bidPriceHour,
        bidOverrideBasePrice,
        bidPriceHourSecondFrequency,
        secondBidOverrideBasePrice
      );
      setCalculatedPrice(calculatedPrice);
      setBidCalculatedBasePrice(bidCalculatedBasePrice);
      setSecondCalculatedPrice(secondCalculatedPrice);
      setSecondBidCalculatedBasePrice(secondBidCalculatedBasePrice);
    }
    // eslint-disable-next-line
  }, [
    totalManHrs,
    totalSecondOfficeManHrs,
    cleaning,
    secondCleaning,
    frequencyService,
    bidPriceHour,
    bidPriceHourSecondFrequency,
    includeInPrice,
    porterOverride,
    secondPorterOverride,
    monthlyPorterPrice,
    costSupplies,
    overrideCostSupplies,
    secondOverrideCostSupplies,
    monthlyAddFrequency,
    bidOverrideBasePrice,
    secondBidOverrideBasePrice,
    formValues.protectionIncludedInPrice,
    formValues.protectionPackages,
    formValues.secondProtectionIncludedInPrice,
    formValues.secondProtectionPackages,
    inventory,
    calculatedPrice,
    bidCalculatedBasePrice,
    secondCalculatedPrice,
    secondBidCalculatedBasePrice,
  ]);

  useEffect(() => {
    if (frequencyService === 'weekly') {
      const {
        calculatedPrice,
        bidCalculatedBasePrice,
        secondCalculatedPrice,
        secondBidCalculatedBasePrice,
      } = calculateWeeklyQuotes(
        bidPriceHour,
        bidOverrideBasePrice,
        bidPriceHourSecondFrequency,
        secondBidOverrideBasePrice
      );
      setCalculatedPrice(calculatedPrice);
      setBidCalculatedBasePrice(bidCalculatedBasePrice);
      setSecondCalculatedPrice(secondCalculatedPrice);
      setSecondBidCalculatedBasePrice(secondBidCalculatedBasePrice);
    }
    // eslint-disable-next-line
  }, [
    totalManHrs,
    totalSecondOfficeManHrs,
    frequencyService,
    frequency,
    addFrequency,
    secondFrequency,
    includeInPrice,
    porterOverride,
    secondPorterOverride,
    overrideCostSupplies,
    secondOverrideCostSupplies,
    monthlyPorterPrice,
    costSupplies,
    bidPriceHour,
    bidPriceHourSecondFrequency,
    bidOverrideBasePrice,
    secondBidOverrideBasePrice,
    formValues.protectionIncludedInPrice,
    formValues.protectionPackages,
    formValues.secondProtectionIncludedInPrice,
    formValues.secondProtectionPackages,
    inventory,
    calculatedPrice,
    bidCalculatedBasePrice,
    secondCalculatedPrice,
    secondBidCalculatedBasePrice,
  ]);

  useEffect(() => {
    if (smartClean) {
      let calculatedPrice,
        bidCalculatedBasePrice,
        secondCalculatedPrice,
        secondBidCalculatedBasePrice;

      if (frequencyService === 'monthly') {
        ({
          calculatedPrice,
          bidCalculatedBasePrice,
          secondCalculatedPrice,
          secondBidCalculatedBasePrice,
        } = calculateMonthlyQuotes(
          formValues.bidPriceHour || 0,
          null,
          formValues.bidPriceHour || 0,
          null,
          true
        ));
      } else {
        ({
          calculatedPrice,
          bidCalculatedBasePrice,
          secondCalculatedPrice,
          secondBidCalculatedBasePrice,
        } = calculateWeeklyQuotes(
          formValues.bidPriceHour || 0,
          null,
          formValues.bidPriceHour || 0,
          null,
          true
        ));
      }

      const calculatedSmartCleanBasePrice = calculateSmartCleanBasePrice({
        calculatedPrice,
        bidCalculatedBasePrice,
        secondCalculatedPrice,
        secondBidCalculatedBasePrice,
      });

      setCalculatedSmartCleanBasePrice(calculatedSmartCleanBasePrice);

      const calculatedSmartCleanPrice = calculateSmartCleanTotal(
        {
          calculatedPrice,
          bidCalculatedBasePrice,
          secondCalculatedPrice,
          secondBidCalculatedBasePrice,
          smartCleanCalculatedBasePrice:
            calculatedSmartCleanBasePrice ||
            formValues.smartCleanCalculatedBasePrice,
          smartCleanOverrideBasePrice: convertStringToNumber(
            formValues.smartCleanOverrideBasePrice
          ),
        },
        {
          porterOverride: convertStringToNumber(porterOverride),
          monthlyPorterPrice: convertStringToNumber(monthlyPorterPrice),
          includeInPrice,
        },
        {
          costSupplies: convertStringToNumber(costSupplies),
        }
      );
      setCalculatedSmartCleanPrice(calculatedSmartCleanPrice);

      setTimeout(() => {
        onBlurSmartClean();
      }, 0);

      return;
    }
    setCalculatedSmartCleanBasePrice(0);
    setCalculatedSmartCleanPrice(0);
    // eslint-disable-next-line
  }, [
    smartClean,
    formValues.bidPriceHour,
    totalManHrs,
    totalSecondOfficeManHrs,
    frequency,
    secondFrequency,
    includeInPrice,
    porterOverride,
    secondPorterOverride,
    overrideCostSupplies,
    secondOverrideCostSupplies,
    monthlyPorterPrice,
    costSupplies,
    inventory,
    formValues.smartCleanOverrideBasePrice,
    formValues.smartCleanCalculatedBasePrice,
  ]);

  const setBreakpoints = () => {
    setBiggerThan1024(window.innerWidth > 1024);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  useEffect(() => {
    let manProduction = calculateDailyManProduction(productionRates);
    let manFixtures = calculateDailyManFixtures(restroomFixtures);
    let manInventory = calculateDailyManInventory(inventory);
    let result = {};

    result = {
      ...manProduction,
      ...{ RestroomManHours: 0, ...manFixtures },
      ...manInventory,
      totalManHrs:
        (manProduction.officeManHrs || 0) + (manFixtures.RestroomManHours || 0),
      totalSecondOfficeManHrs: manProduction.secondOfficeManHrs
        ? (manProduction.secondOfficeManHrs || 0) +
          (manFixtures.RestroomManHours || 0)
        : 0,
    };

    if (
      oldRates !==
      `${manProduction.officeManHrs}_${manProduction.secondOfficeManHrs}`
    ) {
      setOldRates(
        `${manProduction.officeManHrs}_${manProduction.secondOfficeManHrs}`
      );
    }

    if (oldFixtures !== manFixtures.RestroomManHours) {
      setOldFixtures(manFixtures.RestroomManHours);
    }

    setFormValues({ ...result });
    // eslint-disable-next-line
  }, [inventory, productionRates, restroomFixtures]);

  const showSecond = () => {
    return (
      (formValues.frequencyService === 'weekly' &&
        formValues.addFrequency &&
        formValues.secondFrequency &&
        formValues.secondFrequency.selecteds.length > 0) ||
      (formValues.frequencyService === 'monthly' &&
        formValues.monthlyAddFrequency &&
        !!formValues.secondCleaning)
    );
  };

  return (
    <>
      <div className="daily-man-hours-root">
        <div className="daily-man-hours-content">
          <ContentInput width="100%" padding="15px 0px">
            <h3 className="daily-man-hours-title">Daily Man Hours</h3>
          </ContentInput>
          <div className="daily-man-row">
            <BoxValue
              width="28%"
              label="Office Sq. Ft.:"
              labelWidth="67%"
              value={`${formatNumberWithCommas(
                mathRoundToFixed0(formValues.sqftOffice || 0)
              )}`}
            />
            <div className="daily-man-item">+</div>
            <BoxValue
              width="28%"
              label="Restroom Sq. Ft.:"
              labelWidth="67%"
              value={`${formatNumberWithCommas(
                mathRoundToFixed0(formValues.sqftRestroom || 0)
              )}`}
            />
            <div className="daily-man-item">=</div>
            <BoxValue
              width="34%"
              label="Total Sq. Ft.:"
              labelWidth="67%"
              value={`${formatNumberWithCommas(
                mathRoundToFixed0(formValues.sqftTotal || 0)
              )}`}
            />
          </div>

          <div className="daily-man-row">
            <BoxValue
              width="28%"
              labelWidth="67%"
              label={`Office Man Hrs. ${
                formValues.frequencyService === 'weekly'
                  ? formValues.frequency.selecteds.length
                  : formValues.cleaning
              }x:`}
              value={`${mathRoundToFixed1(formValues.officeManHrs || 0)}`}
            />
            <div className="daily-man-item">+</div>

            <BoxValue
              width="28%"
              labelWidth="67%"
              label="Restroom Man Hrs.:"
              value={`${mathRoundToFixed1(formValues.RestroomManHours || 0)}`}
              valueStyle={
                showSecond()
                  ? {
                      paddingTop: '43px',
                      paddingBottom: '43px',
                    }
                  : {}
              }
              contentStyle={
                showSecond()
                  ? {
                      height: '110px',
                      display: 'flex',
                      alignItems: 'center',
                    }
                  : {}
              }
            />
            <div className="daily-man-item">=</div>

            <BoxValue
              labelWidth="67%"
              width="34%"
              label={`Total Daily Man Hrs. ${
                formValues.frequencyService === 'weekly'
                  ? formValues.frequency.selecteds.length
                  : formValues.cleaning
              }x:`}
              value={`${mathRoundToFixed1(formValues.totalManHrs || 0)}`}
            />
          </div>

          {showSecond() && (
            <div className="daily-man-row">
              <BoxValue
                labelWidth="67%"
                width="28%"
                label={`Office Man Hrs. ${
                  formValues.frequencyService === 'weekly' &&
                  formValues.secondFrequency
                    ? formValues.secondFrequency.selecteds.length
                    : formValues.frequencyService === 'monthly'
                    ? formValues.secondCleaning
                    : 0
                }x:`}
                value={`${mathRoundToFixed1(
                  formValues.secondOfficeManHrs || 0
                )}`}
              />
              <div className="daily-man-item">+</div>
              <div style={{ width: '28%' }}></div>
              <div className="daily-man-item">=</div>

              <BoxValue
                labelWidth="67%"
                width="34%"
                label={`Total Daily Man Hrs. ${
                  formValues.frequencyService === 'weekly' &&
                  formValues.secondFrequency
                    ? formValues.secondFrequency.selecteds.length
                    : formValues.frequencyService === 'monthly'
                    ? formValues.secondCleaning
                    : 0
                }x:`}
                value={`${mathRoundToFixed1(
                  formValues.totalSecondOfficeManHrs || 0
                )}`}
              />
            </div>
          )}
        </div>
      </div>
      <div className="bid-pricing-root">
        <div className="bid-pricing-content">
          <ContentInput width="100%" paddingTop="15px">
            <h3 className="bid-pricing-title">Bid Pricing</h3>
          </ContentInput>

          {((formValues.frequencyService == 'weekly' &&
            !formValues.smartClean) ||
            formValues.frequencyService == 'monthly') && (
            <>
              <div className="bid-pricing-header">
                <div className="bid-pricing-label">
                  Quote 1:{' '}
                  {(formValues.frequencyService === 'monthly'
                    ? `${formValues.cleaning}x `
                    : `${formValues.frequency.selecteds.length}x `) +
                    formValues.frequencyService}
                </div>
                {showSecond() && (
                  <div className="bid-pricing-label">
                    Quote 2:{' '}
                    {(formValues.frequencyService === 'monthly'
                      ? `${formValues.secondCleaning}x `
                      : `${
                          (formValues.secondFrequency &&
                            formValues.secondFrequency.selecteds.length) ||
                          0
                        }x `) + formValues.frequencyService}
                  </div>
                )}
              </div>

              <div className="bid-pricing-content">
                <div className="bid-content-item">
                  <InputFloatLabelWithValidation
                    mask={moneyMask}
                    maskParams={{
                      ...moneyMaskParams,
                      thousandsSeparatorSymbol: true,
                    }}
                    onBlur={(name, value) => {
                      onChange(name, value);
                    }}
                    value={formValues.bidPriceHour}
                    className="bid-pricing-break-label"
                    name="bidPriceHour"
                    label="Bid Price Per Hour"
                    style={{ width: biggerThan1024 ? '35%' : '25%' }}
                    onChange={onChange}
                    disabled={formValues.status === 2}
                  />
                  <BoxValue
                    labelWidth="67%"
                    label="Calc'd Price:"
                    labelWidth="30%"
                    width="45%"
                    value={`$${formatNumberStringAsCurrency(
                      Number(bidCalculatedBasePrice)?.toFixed?.(2)
                    )}`}
                    labelStyle={
                      biggerThan1024
                        ? {
                            width: '50%',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                          }
                        : {
                            width: '40%',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            lineHeight: '22px',
                          }
                    }
                  />
                  <InputFloatLabelWithValidation
                    mask={moneyMask}
                    maskParams={{
                      ...moneyMaskParams,
                      thousandsSeparatorSymbol: true,
                    }}
                    onBlur={(name, value) => {
                      onChange(name, value);
                    }}
                    value={formValues.bidOverrideBasePrice}
                    name="bidOverrideBasePrice"
                    label="Override"
                    style={{ width: biggerThan1024 ? '18%' : '25%' }}
                    onChange={onChange}
                    disabled={formValues.status === 2}
                  />
                </div>
                {showSecond() && (
                  <div className="bid-content-item">
                    <InputFloatLabelWithValidation
                      mask={moneyMask}
                      maskParams={{
                        ...moneyMaskParams,
                        thousandsSeparatorSymbol: true,
                      }}
                      onBlur={(name, value) => {
                        onChange(name, value);
                      }}
                      value={formValues.bidPriceHourSecondFrequency}
                      className="bid-pricing-break-label"
                      name="bidPriceHourSecondFrequency"
                      label="Bid Price Per Hour"
                      style={{ width: biggerThan1024 ? '35%' : '25%' }}
                      onChange={onChange}
                      disabled={formValues.status === 2}
                    />
                    <BoxValue
                      labelWidth="67%"
                      label="Calc'd Price:"
                      labelWidth="30%"
                      width="45%"
                      value={`$${formatNumberStringAsCurrency(
                        Number(secondBidCalculatedBasePrice)?.toFixed?.(2)
                      )}`}
                      labelStyle={
                        biggerThan1024
                          ? {
                              width: '50%',
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }
                          : {
                              width: '40%',
                              paddingTop: '0px',
                              paddingBottom: '0px',
                              lineHeight: '22px',
                            }
                      }
                    />
                    <InputFloatLabelWithValidation
                      mask={moneyMask}
                      maskParams={{
                        ...moneyMaskParams,
                        thousandsSeparatorSymbol: true,
                      }}
                      onBlur={(name, value) => {
                        onChange(name, value);
                      }}
                      value={formValues.secondBidOverrideBasePrice}
                      name="secondBidOverrideBasePrice"
                      label="Override"
                      style={{ width: biggerThan1024 ? '18%' : '25%' }}
                      onChange={onChange}
                      disabled={formValues.status === 2}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {formValues.smartClean && formValues.frequencyService == 'weekly' && (
            <div
              className="bid-pricing-content"
              style={{ flexDirection: 'column' }}
            >
              <div
                className="bid-pricing-label"
                style={{ alignSelf: 'flex-start' }}
              >
                SmartClean<sup className="sm">&#8480;</sup> Quote:{' '}
                {`${
                  formValues.frequency.selecteds.length +
                  ((formValues.secondFrequency &&
                    formValues.secondFrequency.selecteds.length) ||
                    0)
                }x ` + formValues.frequencyService}
              </div>
              <div
                className="bid-content-item"
                style={{
                  paddingLeft: '0',
                  width: 'calc(50% - 10px)',
                  paddingTop: '25px',
                }}
              >
                <InputFloatLabelWithValidation
                  mask={moneyMask}
                  maskParams={{
                    ...moneyMaskParams,
                    thousandsSeparatorSymbol: true,
                  }}
                  onBlur={(name, value) => {
                    onChange(name, value);
                    onBlurSmartClean();
                  }}
                  value={formValues.bidPriceHour}
                  className="bid-pricing-break-label"
                  name="bidPriceHour"
                  label="Bid Price Per Hour"
                  style={{ width: biggerThan1024 ? '35%' : '25%' }}
                  onChange={onChange}
                  disabled={formValues.status === 2}
                />
                <BoxValue
                  label="Calc'd Price:"
                  labelWidth="30%"
                  width="45%"
                  value={`$${formatNumberStringAsCurrency(
                    Number(calculatedSmartCleanBasePrice || 0)?.toFixed?.(2)
                  )}`}
                  labelStyle={
                    biggerThan1024
                      ? {
                          width: '50%',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                        }
                      : {
                          width: '40%',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          lineHeight: '22px',
                        }
                  }
                />
                <InputFloatLabelWithValidation
                  mask={moneyMask}
                  maskParams={{
                    ...moneyMaskParams,
                    thousandsSeparatorSymbol: true,
                  }}
                  onBlur={(name, value) => {
                    onChange(name, value !== '' ? value : undefined);
                  }}
                  value={formValues.smartCleanOverrideBasePrice}
                  name="smartCleanOverrideBasePrice"
                  label="Override"
                  style={{ width: biggerThan1024 ? '18%' : '25%' }}
                  onChange={onChange}
                  disabled={formValues.status === 2}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...dailyManHoursValues,
  }),
  handleSubmit: () => '',
};

const EnhancedDailyManHours = withFormik<any, IBidDailyManHours>(mapForm)(
  DailyManHours
);

export { EnhancedDailyManHours as DailyManHours };
