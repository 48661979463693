import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faEye,
  faCloudUploadAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useFilePicker } from 'use-file-picker';
// import { genericModalState } from 'state/genericModal';
import { Button, IconInputWrapper } from './styles';
import { Row } from '../styles'
import {
  BoxInput,
} from 'core/components';
import { IWarningModal } from 'modules/shared/bidInterfaces';

export interface ISystemAttachment {
  fileContent?: string;
  fileContentName?: string;
}

interface PdfFileProps {
  attachment: ISystemAttachment;
  onChangeProp(attachment: ISystemAttachment): void;
  onRemove(): void;
  setWarningModal(warningProps: IWarningModal): void;
}

export const OneTimeAttachment: React.FC<PdfFileProps> = ({
  onChangeProp,
  onRemove,
  setWarningModal,
  attachment,
}) => {
  const [openFileSelector, { errors, filesContent }] = useFilePicker({
    accept: '.pdf',
    readAs: 'DataURL',
    maxFileSize: 10,
    limitFilesConfig: { max: 1 },
  });

  React.useEffect(() => {
    if (filesContent.length > 0) {
      if (
        Object.keys(errors).length == 0 &&
        filesContent[0].content.indexOf('data:application/pdf') >= 0
      ) {
        onChangeProp({
          fileContent: filesContent[0].content,
          fileContentName: filesContent[0].name,
        });
      } else {
          setWarningModal({
            title: 'Attention',
            message:
              'Problem occurred while reading file! Pay attention if you are selecting a valid pdf file.',
            proceed: () => setWarningModal(null),
          });
      }
    }
  }, [filesContent]);

  React.useEffect(() => {
    if (errors.length > 0 && errors[0].fileSizeToolarge) {
      setWarningModal({
        title: 'Attention',
        message:
          'File size is too large. Select a valid PDF file with less than 10mb.',
        proceed: () => setWarningModal(null),
      });
    }
  }, [errors]);

  const onRemoveItem = () => {
    setWarningModal({
      message: 'Are you sure you want to delete this flyer?',
      proceed: () => {
        onRemove();
        setWarningModal(null);
      },
      dismiss: () => setWarningModal(null),
    });
  };

  const onClickPreview = () => {
    if (attachment.fileContent) {
      const byteCharacters = window.atob(
        attachment.fileContent.replace('data:application/pdf;base64,', ''),
      );
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      window.open(attachment.fileContentName, '_blank');
    }
  };

  return (
    <Row
      style={{
        height: '30px',
        marginBottom: '30px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <BoxInput
        style={{
          border: '1px solid black',
          height: '100%',
        }}
        width="100%"
        disabled={true}
        value={attachment.fileContentName}
      />

      <span style={{marginLeft:'10px'}} />

      <Button
        onClick={onClickPreview}
        disabled={
          (!attachment.fileContentName || !attachment.fileContentName.trim()) && (!attachment.fileContent || !attachment.fileContent.trim())
        }
      >
        <FontAwesomeIcon
          icon={faEye}
          width="20px"
          style={{ pointerEvents: 'none' }}
        />
      </Button>

      <span style={{marginLeft:'10px'}} />

      <Button onClick={() => openFileSelector()}>
        <FontAwesomeIcon
          icon={faCloudUploadAlt}
          width="20px"
          style={{ pointerEvents: 'none' }}
        />
      </Button>

      <span style={{marginLeft:'10px'}} />

      <Button onClick={onRemoveItem}>
        <FontAwesomeIcon
          icon={faTrash}
          width="20px"
          style={{ pointerEvents: 'none' }}
        />
      </Button>
    </Row>
  );
};
