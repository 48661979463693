import styled from 'styled-components';

const noSelectableStyles = `
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none;
`;

export const ImageWrapper = styled.img`
  ${noSelectableStyles}
  pointerEvents: none
`;
