import React, { useEffect } from 'react'
import classNames from 'classnames';

interface ModalProps {
  header?: string;
  opened: boolean;
  onClose: (opened: boolean) => void;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  header,
  children,
  opened,
  onClose,
  className,
  ...props,
}) => {
  useEffect(() => {
    const elHtml: HTMLElement | null = document.getElementById('root');

    if (!elHtml) {
      return;
    }

    if (opened) {
      elHtml.classList.add('no-scroll');
    } else {
      elHtml.classList.remove('no-scroll');
    }
  }, [opened]);

  return (
    <>
      {opened ? (
        <div onClick={() => onClose(false)} className="modal-container" {...props}>
          <div
            className={classNames('modal-container-content', className)}
            onClick={e => e.stopPropagation()}
          >
            {header && (
              <div className="modal-container-content-header">
                <label>{header}</label>
              </div>
            )}
            <div
              className="modal-container-content-body"
            >
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
