import React from 'react';
import { LoadingPage, ThanksPage } from '../../components/organisms';
import { HiddenAnchor } from '../../components/atoms';
import { getQueryString, isWebView } from 'core/utils';
import { getSetSigned, getByUniqID } from '../../services/bidInfoServiceClient';
import { getBidURL } from 'modules/BidInfoForm/utils';
import { getUserPefById } from '../../../PerformanceEvaluation/services/performanceEvaluationService';
import {
  getBase64ByParams,
  getDirectPdfDownloadUrl,
  getSignNowDownloadLink,
} from '../../services/bidInfoService';

export default (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [url, setUrl] = React.useState(null);
  const [fromEmail, setFromEmail] = React.useState(false);
  const [fromPerformance, setFromPerformance] = React.useState(false);
  const [bidData, setBidData] = React.useState(null);
  const [performanceData, setPerformanceData] = React.useState(null);
  const [performanceRating, setPerformanceRating] = React.useState(null);
  const [formUniqueId, setFormUniqueId] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState();

  React.useEffect(() => {
    const uId = getQueryString(window.location.href, 'formUniqueId');
    setFormUniqueId(uId);

    const isFromEmail =
      getQueryString(window.location.href, 'fromEmail') == 'true';
    setFromEmail(isFromEmail);

    const isFromPerformance =
      getQueryString(window.location.href, 'fromPerformance') == 'true' ||
      props.fromPerformance;
    setFromPerformance(isFromPerformance);

    if (uId) {
      if (!isFromEmail && !isFromPerformance) {
        getSetSigned(uId).then((result) => {
          getByUniqID(uId).then((result2) => {
            setBidData(result2.data.Data);
            setIsLoading(false);
            setUrl(result.data['UrlCompletedDocument']);
          });
        });
      } else if (isFromPerformance) {
        getUserPefById(uId).then((result) => {
          setPerformanceData(result.data);
          // onClickDownload(uId);
          setPerformanceRating(
            [null, 'Superior', 'Excellent', 'Good', 'Fair'].find(
              (el, idx) => idx === result.data['DomainSatisfactionLevelId']
            )
          );

          setIsLoading(false);
        });
      } else {
        getByUniqID(uId).then((result) => {
          setBidData(result.data.Data);
          onClickDownload(uId, result.data.Data);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  // const onClickDownload = async (uId = null, formData = null) => {
  //   setIsLoading(true);

  //   const {
  //     data,
  //   } = await getBase64ByParams(
  //     fromPerformance ? 41 : formData && formData['IsSS'] ? 21 : bidData && bidData['IsSS'] ? 21 : 11,
  //     uId
  //       ? uId
  //       : fromPerformance
  //       ? performanceData['PEFUniqID']
  //       : formData ? formData['BidUniqueID'] : bidData ? bidData['BidUniqueID'] : null,
  //     false
  //   );

  //   setPdfUrl(data['URL']);

  //   setTimeout(() => {
  //     document.getElementById('hiddenAnchor').click();
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, 700)
  //   }, 0);
  // };

  const onClickDownload = async (uid: string = null) => {
    setIsLoading(true);
    if (fromPerformance) {
      const { data } = await getBase64ByParams(
        41,
        performanceData['PEFUniqID'],
        false
      );

      setPdfUrl(data['URL']);

      setTimeout(() => {
        document.getElementById('hiddenAnchorPerformance').click();
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      }, 0);
    } else {
      if (!url) {
        const result = await getSignNowDownloadLink(formUniqueId || uid);
        setUrl(result.data);
      }

      setTimeout(() => {
        document.getElementById('hiddenAnchor').click();
        setIsLoading(false);
        setUrl(null);
      }, 0);
    }
  };

  return (
    <>
      {isLoading && <LoadingPage />}
      {!isLoading && (
        <ThanksPage
          fromEmail={fromEmail}
          fromPerformance={fromPerformance}
          pdfName={
            fromPerformance
              ? `${performanceData['PID']}_PerfEvalCompleted`
              : bidData['IsSS']
              ? `${bidData['PID']}_SpSvcAgreement`
              : `${bidData['PID']}_AnagoAgreement`
          }
          bidData={bidData}
          onClickDownload={() => onClickDownload()}
          performanceRating={performanceRating}
          pefData={performanceData}
          setIsLoading={setIsLoading}
          onBack={() => {
            if (isWebView()) {
              props.history.push('/close-form');
            } else {
              document.location.href = getBidURL();
            }
          }}
        />
      )}

      {fromPerformance && performanceData && (
        <HiddenAnchor
          id="hiddenAnchorPerformance"
          href={getDirectPdfDownloadUrl(
            pdfUrl,
            fromPerformance
              ? `${performanceData['PID'].replace(/\&/, ' ')}_PerfEvalCompleted`
              : bidData['IsSS']
              ? `${bidData['PID'].replace(/\&/, ' ')}_SpSvcAgreement`
              : `${bidData['PID'].replace(/\&/, ' ')}_AnagoAgreement`
          )}
          download={
            fromPerformance
              ? `${performanceData['PID'].replace(/\&/, ' ')}_PerfEvalCompleted`
              : bidData['IsSS']
              ? `${bidData['PID'].replace(/\&/, ' ')}_SpSvcAgreement`
              : `${bidData['PID'].replace(/\&/, ' ')}_AnagoAgreement`
          }
        />
      )}
      <HiddenAnchor id="hiddenAnchor" href={url} />
    </>
  );
};
