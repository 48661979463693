import React, { memo, useCallback, useEffect, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { getQueryString } from 'core/utils';
import {
  ContentInput,
  ButtonGroup,
  InputFloatLabel,
  RadioButton,
  Tooltip,
  Select,
  Checkbox,
  useFormProps,
  ActionAlertModal,
} from 'core/components';
import { Select as SelectV2 } from 'core/components/SelectV2';

import { IBidAccountInfo } from 'modules/BidInfoForm/interfaces';
import { accountInfoValues, daysOfWeek } from 'modules/shared/bidDefaultValues';

import {
  Item,
  QuoteLabel,
  Label,
  LabelPlus,
  RadioItem,
  Holder,
  TextInputWrapper,
  Button,
  CentralizedSection,
} from './styles';

import './styles.scss';
import { prototype } from 'core-js/fn/symbol';

const booleanOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

type IProps = FormikProps<IBidAccountInfo>;
const AccountInfoInspect = memo(({ isPreview }: IProps) => {
  const {
    formValues,
    setFormValues,
    isSectionDirty,
    setSectionDirty,
    cleanSectionDirty,
    showWarningModal,
    onChange,
    setNeedsSaving,
  } = useFormProps();
  const [sameAsAbove, setSameAsAbove] = useState(true);
  const [monthlySameAsAbove, setMonthlySameAsAbove] = useState(true);
  const [addFrequency, setAddFrequency] = useState(false);
  const [monthlyAddFrequency, setMonthlyAddFrequency] = useState(false);
  const [smartCleanTooltipVisible, setSmartCleanTooltipVisible] =
    useState(false);
  const sentStatus = getQueryString(window.location.href, 'isSent') == 'true';

  const {
    DaysCleanedFriday,
    DaysCleanedMonday,
    DaysCleanedSaturday,
    DaysCleanedSunday,
    DaysCleanedThursday,
    DaysCleanedTuesday,
    DaysCleanedWednesday,
    Time,
    IsMonthlyCleaning,
  } = formValues;

  useEffect(() => {
    const selecteds = [];

    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    for (const day of daysOfWeek) {
      if (formValues[`DaysCleaned${day}`]) {
        selecteds.push(day.slice(0, 3));
      }
    }

    setFormValues({
      ...formValues,
      frequency: {
        ...formValues.frequency,
        selecteds,
        timeWindow: Time,
      },
      frequencyService: IsMonthlyCleaning ? 'monthly' : 'weekly',
    });
  }, [
    DaysCleanedFriday,
    DaysCleanedMonday,
    DaysCleanedSaturday,
    DaysCleanedSunday,
    DaysCleanedThursday,
    DaysCleanedTuesday,
    DaysCleanedWednesday,
    IsMonthlyCleaning,
    Time,
  ]);

  useEffect(() => {
    if (addFrequency != formValues.addFrequency) {
      setAddFrequency(!!formValues.addFrequency);
    }
  }, [formValues.addFrequency]);

  useEffect(() => {
    if (monthlyAddFrequency != formValues.monthlyAddFrequency) {
      setMonthlyAddFrequency(!!formValues.monthlyAddFrequency);
    }
  }, [formValues.monthlyAddFrequency]);

  const onChangeValue = useCallback(
    (key: string) => async (name: string, value: any) => {
      const currentValues = formValues[key];
      const proceed = () => {
        onChange(key, {
          ...formValues[key],
          [name]: value,
        });

        if (key === 'frequency' && name === 'timeWindow' && sameAsAbove) {
          onChange('secondFrequency', {
            ...formValues['secondFrequency'],
            [name]: value,
          });
        }
      };

      const shouldShowWarning = () => {
        if (key === 'frequency' || key === 'secondFrequency') {
          if (
            key === 'frequency' &&
            !formValues.smartClean &&
            ((name === 'selecteds' &&
              currentValues.selecteds.length <= 1 &&
              value.length > 1) ||
              (name === 'selecteds' &&
                currentValues.selecteds.length > 1 &&
                value.length <= 1)) &&
            (!formValues.secondFrequency ||
              formValues.secondFrequency.selecteds.length <= 1)
          ) {
            return 'frequency';
          } else if (
            key === 'secondFrequency' &&
            !formValues.smartClean &&
            ((name === 'selecteds' &&
              currentValues.selecteds.length <= 1 &&
              value.length > 1) ||
              (name === 'selecteds' &&
                currentValues.selecteds.length > 1 &&
                value.length <= 1)) &&
            (!formValues.frequency ||
              formValues.frequency.selecteds.length <= 1)
          ) {
            return 'secondFrequency';
          } else if (
            key === 'frequency' &&
            formValues.smartClean &&
            ((currentValues.selecteds.filter(
              (i) => !formValues.secondFrequency.selecteds.includes(i)
            ).length +
              formValues.secondFrequency.selecteds.length <=
              1 &&
              value.filter(
                (i) => !formValues.secondFrequency.selecteds.includes(i)
              ).length +
                formValues.secondFrequency.selecteds.length >
                1) ||
              (currentValues.selecteds.filter(
                (i) => !formValues.secondFrequency.selecteds.includes(i)
              ).length +
                formValues.secondFrequency.selecteds.length >
                1 &&
                value.filter(
                  (i) => !formValues.secondFrequency.selecteds.includes(i)
                ).length +
                  formValues.secondFrequency.selecteds.length <=
                  1))
          ) {
            return 'smartClean';
          } else if (
            key === 'secondFrequency' &&
            formValues.smartClean &&
            ((currentValues.selecteds.filter(
              (i) => !formValues.frequency.selecteds.includes(i)
            ).length +
              formValues.frequency.selecteds.length <=
              1 &&
              value.filter((i) => !formValues.frequency.selecteds.includes(i))
                .length +
                formValues.frequency.selecteds.length >
                1) ||
              (currentValues.selecteds.filter(
                (i) => !formValues.frequency.selecteds.includes(i)
              ).length +
                formValues.frequency.selecteds.length >
                1 &&
                value.filter((i) => !formValues.frequency.selecteds.includes(i))
                  .length +
                  formValues.frequency.selecteds.length <=
                  1))
          ) {
            return 'smartClean';
          }
        }

        return false;
      };

      const shouldShow = shouldShowWarning();

      if (shouldShow) {
        if (
          isSectionDirty('CleaningSpecification_firstFrequency') ||
          isSectionDirty('CleaningSpecification_secondFrequency') ||
          (isSectionDirty('CleaningSpecification_smartClean') &&
            (shouldShow === 'frequency' ||
              shouldShow === 'secondFrequency' ||
              shouldShow === 'smartClean'))
        ) {
          showWarningModal({
            message:
              'Changing the Bid Frequency will cause a loss of customized Cleaning Specifications. Do you wish to continue?',
            proceed: () => {
              cleanSectionDirty(
                [
                  'CleaningSpecification_firstFrequency',
                  'CleaningSpecification_secondFrequency',
                  'CleaningSpecification_smartCleanFrequency',
                ],
                () => {
                  setSectionDirty('AccountInformation', () => {
                    proceed();
                    showWarningModal(null);
                  });
                }
              );
            },
            dismiss: () => showWarningModal(null),
          });
        } else {
          setSectionDirty('AccountInformation', () => proceed());
        }
      } else {
        proceed();
      }
    },
    [setSectionDirty, onChange, sameAsAbove]
  );

  const onBlur = useCallback(
    (name: string) => () => {
      onChange(name, formValues[name]);
    },
    [formValues, onChange]
  );

  const onBlurDynamic = useCallback(
    (name: string, key: string) => () => {
      if (formValues && formValues[name]) {
        let obj = { ...formValues[name], [key]: formValues[name][key] };
        return onChange(name, obj);
      }
    },
    [formValues]
  );

  const onToggleSmartClean = (name: string, value: any, callback: Function) => {
    const proceed = () => {
      if (value) {
        const secondFrequency = {
          ...formValues.secondFrequency,
          selecteds: formValues.secondFrequency.selecteds.filter(
            (item: any) => !formValues.frequency.selecteds.includes(item)
          ),
        };
        onChange('secondFrequency', secondFrequency);
      }
      onChange(name, value);
      callback();
    };

    const shouldShowWarning = () => {
      if (
        formValues.frequency.selecteds.length < 2 &&
        formValues.secondFrequency.selecteds.length < 2 &&
        formValues.frequency.selecteds.filter(
          (i) => !formValues.secondFrequency.selecteds.includes(i)
        ).length +
          formValues.secondFrequency.selecteds.length >=
          2
      ) {
        return 'smartClean';
      }
      return false;
    };

    if (
      shouldShowWarning() &&
      (isSectionDirty('CleaningSpecification_firstFrequency') ||
        isSectionDirty('CleaningSpecification_secondFrequency') ||
        isSectionDirty('CleaningSpecification_smartClean'))
    ) {
      setSmartCleanTooltipVisible(false);
      showWarningModal({
        message:
          'Changing the Bid Frequency will cause a loss of customized Cleaning Specifications. Do you wish to continue?',
        proceed: () => {
          cleanSectionDirty(['CleaningSpecification_smartClean'], () => {
            setSectionDirty('AccountInformation', () => {
              proceed();
              showWarningModal(null);
            });
          });
        },
        dismiss: () => showWarningModal(null),
      });
    } else {
      if (
        formValues.secondFrequency &&
        formValues.secondFrequency.selecteds &&
        formValues.secondFrequency.selecteds.length > 0
      ) {
        setSectionDirty('AccountInformation', () => proceed());
      } else {
        proceed();
      }
    }
  };

  // const disabledDays = formValues.frequency.selecteds;

  // useEffect(() => {
  //   if (formValues.smartClean) {
  //     onToggleSmartClean('smartClean', true);
  //   }
  //   // eslint-disable-next-line
  // }, [disabledDays]);

  const onChangeServicePeriod = (value: string) => {
    const proceed = () => {
      if (value === 'monthly' && !formValues.TimeOfDay) {
        onChange('TimesPerMonth', 1);
      }
      onChange('frequencyService', value);
      onChange('protectionPackages', []);
      onChange('secondProtectionPackages', []);
    };

    const userHasProtection =
      formValues.protectionPackages.length > 0 ||
      formValues.secondProtectionPackages.length > 0;

    if (
      isSectionDirty('CleaningSpecification_firstFrequency') ||
      isSectionDirty('CleaningSpecification_secondFrequency') ||
      isSectionDirty('CleaningSpecification_smartClean')
    ) {
      const message = userHasProtection
        ? 'Changing the Bid Frequency will cause a loss of customized Cleaning Specifications and will reset the Protection+ Packages. Do you wish to continue?'
        : 'Changing the Bid Frequency will cause a loss of customized Cleaning Specifications. Do you wish to continue?';

      showWarningModal({
        message: message,
        proceed: () => {
          setSectionDirty('AccountInformation', () => {
            cleanSectionDirty(
              [
                'CleaningSpecification_firstFrequency',
                'CleaningSpecification_secondFrequency',
                'CleaningSpecification_smartCleanFrequency',
              ],
              () => {
                proceed();
                showWarningModal(null);
              }
            );
          });
        },
        dismiss: () => showWarningModal(null),
      });
    } else {
      if (userHasProtection) {
        showWarningModal({
          message:
            'Changing the Bid Frequency will reset the Protection+ Packages. Do you wish to continue?',
          proceed: () => {
            setSectionDirty('AccountInformation', () => {
              proceed();
              showWarningModal(null);
            });
          },
          dismiss: () => showWarningModal(null),
        });
      } else {
        setSectionDirty('AccountInformation', () => proceed());
      }
    }
  };

  useEffect(() => {
    const secondFrequency = formValues.secondFrequency
      ? formValues.secondFrequency.timeWindow
      : null;
    if (secondFrequency) {
      if (secondFrequency !== formValues.frequency.timeWindow) {
        setSameAsAbove(false);
      } else if (secondFrequency === formValues.frequency.timeWindow) {
        setSameAsAbove(true);
      }
    }
  }, [formValues.secondFrequency]);

  useEffect(() => {
    if (formValues.TimeOfDay) {
      if (formValues.TimeOfDay !== formValues.timeWindow) {
        setMonthlySameAsAbove(false);
      } else if (formValues.TimeOfDay === formValues.timeWindow) {
        setMonthlySameAsAbove(true);
      }
    }
  }, [formValues.secondCleaning]);

  useEffect(() => {
    const hideSmartCleanPopup = (e) => {
      let canHide = true;
      var path = e.path || (e.composedPath && e.composedPath());

      if (e && path) {
        for (var el of path) {
          if (
            el.id == 'smartCleanTooltip' ||
            el.id == 'smartCleanInfoIcon' ||
            el.id == 'smartCleanLabel' ||
            el.id == 'smartClean'
          ) {
            canHide = false;
            break;
          }
        }
        if (canHide) setSmartCleanTooltipVisible(false);
      }
    };
    const event = window.ontouchstart === null ? 'touchstart' : 'click';

    if (smartCleanTooltipVisible) {
      document.body.addEventListener(event, hideSmartCleanPopup);
    } else {
      document.body.removeEventListener(event, hideSmartCleanPopup);
    }
  }, [smartCleanTooltipVisible]);

  const userHasProtection =
    formValues.protectionPackages.length > 0 ||
    formValues.secondProtectionPackages.length > 0;

  return (
    <div className="account-info-root">
      <div className="account-info-content">
        <ContentInput width="100%" padding="0px 0px 20px 0px">
          <h3 className="account-info-title">Service Info</h3>
        </ContentInput>
        <CentralizedSection>
          <ContentInput
            width="100%"
            justify="center"
            padding="0px 0px 20px 0px"
          >
            <Button
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
              className={`select-button button-space ${
                formValues.frequencyService === 'weekly' && 'button-selected'
              }`}
              onClick={() => onChangeServicePeriod('weekly')}
            >
              Weekly Service
            </Button>

            <Button
              disabled={
                sentStatus ||
                isPreview ||
                (formValues.isTemplate && formValues.isUserRole)
              }
              className={`select-button ${
                formValues.frequencyService === 'monthly' && 'button-selected'
              }`}
              onClick={() => onChangeServicePeriod('monthly')}
            >
              Monthly Service
            </Button>
          </ContentInput>

          {formValues.frequencyService === 'weekly' ? (
            <>
              <ContentInput margin="0 0 10px 0" width="100%">
                <ContentInput justify="space-between" width="100%">
                  <Item>
                    <QuoteLabel>
                      {(formValues.addFrequency && 'Quote 1') || 'Quote'}
                    </QuoteLabel>
                    <Label>Days Cleaned</Label>
                    <Holder>
                      <ButtonGroup
                        disabled={
                          sentStatus ||
                          isPreview ||
                          (formValues.isTemplate && formValues.isUserRole)
                        }
                        tabIndexable={false}
                        label=""
                        options={daysOfWeek}
                        name="selecteds"
                        className="validate-frequency"
                        onChange={onChangeValue('frequency')}
                        value={formValues.frequency.selecteds || []}
                        disabledValues={
                          formValues.smartClean &&
                          formValues.addFrequency &&
                          formValues.secondFrequency
                            ? formValues.secondFrequency.selecteds
                            : []
                        }
                      />
                    </Holder>
                  </Item>
                  <Item>
                    <TextInputWrapper>
                      <Label>Time Window</Label>
                      <Holder>
                        <ContentInput padding="0" flex>
                          <InputFloatLabel
                            disabled={
                              sentStatus ||
                              isPreview ||
                              (formValues.isTemplate && formValues.isUserRole)
                            }
                            value={formValues.frequency.timeWindow}
                            maxLength={20}
                            name="timeWindow"
                            maxLength={20}
                            placeholder="Time Window"
                            onChange={onChangeValue('frequency')}
                            onBlur={onBlurDynamic('frequency', 'timeWindow')}
                          />
                        </ContentInput>
                      </Holder>
                    </TextInputWrapper>
                  </Item>
                  {/* )} */}
                  <ContentInput
                    padding="0px"
                    self="center"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  ></ContentInput>
                </ContentInput>
              </ContentInput>

              {formValues.addFrequency && (
                <>
                  <ContentInput margin="0 0 10px 0" width="100%">
                    <ContentInput justify="space-between" width="100%">
                      <Item>
                        <QuoteLabel>Quote 2</QuoteLabel>
                        <Label>Days Cleaned</Label>
                        <Holder>
                          <ButtonGroup
                            disabled={
                              sentStatus ||
                              isPreview ||
                              (formValues.isTemplate && formValues.isUserRole)
                            }
                            tabIndexable={false}
                            label=""
                            options={daysOfWeek}
                            name="selecteds"
                            onChange={onChangeValue('secondFrequency')}
                            value={formValues.secondFrequency.selecteds || []}
                            disabledValues={
                              formValues.smartClean
                                ? formValues.frequency.selecteds
                                : []
                            }
                          />
                        </Holder>
                      </Item>
                      {/* {biggerThan1024 && ( */}
                      <Item>
                        <TextInputWrapper>
                          <Label>Time Window</Label>
                          <Holder style={{ marginBottom: '10px' }}>
                            <ContentInput padding="0" flex>
                              <InputFloatLabel
                                value={formValues.secondFrequency.timeWindow}
                                id="TimeOfDay"
                                disabled={
                                  formValues.smartClean ||
                                  sentStatus ||
                                  (formValues.isTemplate &&
                                    formValues.isUserRole)
                                }
                                placeholder="Time Window"
                                name="timeWindow"
                                maxLength={20}
                                onChange={onChangeValue('secondFrequency')}
                                onBlur={onBlurDynamic(
                                  'secondFrequency',
                                  'timeWindow'
                                )}
                              />
                            </ContentInput>
                          </Holder>
                          <Checkbox
                            label={<Label>Same as Above</Label>}
                            inverted
                            disabled={
                              sentStatus ||
                              isPreview ||
                              (formValues.isTemplate && formValues.isUserRole)
                            }
                            borderColor="gray"
                            dataCy="sameAsAbove"
                            name="sameAsAbove"
                            value="sameAsAbove"
                            onChange={() => {
                              if (!sameAsAbove) {
                                setFormValues({
                                  ...formValues,
                                  secondFrequency: {
                                    ...formValues.secondFrequency,
                                    timeWindow: formValues.frequency.timeWindow,
                                  },
                                });
                              }
                              setSameAsAbove(!sameAsAbove);
                            }}
                            checked={sameAsAbove}
                          />
                        </TextInputWrapper>
                      </Item>
                      {/* )} */}

                      <ContentInput
                        padding="0px"
                        self="center"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Item>
                          <ContentInput paddingTop="5px">
                            <Tooltip
                              id="smartCleanTooltip"
                              overlay={
                                !userHasProtection ? (
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      display: 'block',
                                      textAlign: 'justify',
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: '#ff2d29',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      Note: Protection+ is not available when
                                      SmartClean is selected
                                    </span>
                                    <br />A SmartClean
                                    <sup className="sm">&#8480;</sup> is a
                                    budget-friendly way to offer a quote where
                                    selected day(s) can be provided with reduced
                                    service. Typically, a “full clean” is
                                    provided on certain days and a “partial
                                    clean” (e.g. restrooms, kitchen, and trash)
                                    is provided on other days. The separated
                                    frequencies allow for specific days to be
                                    designated with an adjusted production rate
                                    for the partial clean. A SmartClean
                                    <sup className="sm">&#8480;</sup> proposal
                                    will combine both frequencies into one
                                    overall SmartClean
                                    <sup className="sm">&#8480;</sup> bid price.
                                    See the Unit Franchise Operating Manual for
                                    specific bidding guidelines.
                                  </span>
                                ) : (
                                  <span>
                                    SmartClean is not compatible with
                                    Protection+ plans. Please remove all
                                    Protection+ Plans before adding SmartClean
                                    to this Proposal.
                                  </span>
                                )
                              }
                              // trigger="click"
                              placement="topLeft"
                              alignedItemId="smartCleanInfoIcon"
                              visible={smartCleanTooltipVisible}
                              positioningFunction={(node, align) => {
                                const targetEl =
                                  document.getElementById('smartCleanInfoIcon');
                                const rect = targetEl.getBoundingClientRect();
                                node.style.left = `${rect.left - 280}px`;
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Label
                                  id="smartCleanLabel"
                                  onClick={(e) => {
                                    setSmartCleanTooltipVisible(
                                      !smartCleanTooltipVisible
                                    );
                                  }}
                                >
                                  SmartClean
                                  <sup className="sm">&#8480;</sup>
                                </Label>
                                <div
                                  className="smartclean-button-tooltip"
                                  id="smartCleanInfoIcon"
                                  onClick={(e) => {
                                    setSmartCleanTooltipVisible(
                                      !smartCleanTooltipVisible
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faInfo}
                                    size="xs"
                                    color="#216eab"
                                  />
                                </div>
                                <Checkbox
                                  // label={
                                  //   <>
                                  //     <Label
                                  //       onClick={(e) => {
                                  //         e.stopPropagation();
                                  //         // setSmartCleanTooltipVisible(
                                  //         //   !smartCleanTooltipVisible
                                  //         // );
                                  //       }}
                                  //     >
                                  //       SmartClean
                                  //       <sup className="sm">&#8480;</sup>
                                  //     </Label>
                                  //     <div
                                  //       className="smartclean-button-tooltip"
                                  //       id="smartCleanInfoIcon"
                                  //       onClick={(e) => {
                                  //         e.stopPropagation();
                                  //         // setSmartCleanTooltipVisible(
                                  //         //   !smartCleanTooltipVisible
                                  //         // );
                                  //       }}
                                  //     >
                                  //       <FontAwesomeIcon
                                  //         icon={faInfo}
                                  //         size="xs"
                                  //         color="#216eab"
                                  //       />
                                  //     </div>
                                  //   </>
                                  // }
                                  inverted
                                  disabled={
                                    sentStatus ||
                                    userHasProtection ||
                                    (formValues.isTemplate &&
                                      formValues.isUserRole)
                                  }
                                  pointerEvents={
                                    userHasProtection ? 'auto' : null
                                  }
                                  borderColor="gray"
                                  dataCy="smartClean"
                                  id="smartClean"
                                  name="smartClean"
                                  value="smartClean"
                                  onClick={(e) => {
                                    if (userHasProtection) {
                                      setSmartCleanTooltipVisible(
                                        (curValue) => !smartCleanTooltipVisible
                                      );
                                    }
                                  }}
                                  onChange={(name, value, newValue, event) => {
                                    onToggleSmartClean(
                                      'smartClean',
                                      !formValues.smartClean,
                                      () =>
                                        setSmartCleanTooltipVisible(newValue)
                                    );
                                  }}
                                  checked={formValues.smartClean}
                                />
                              </div>
                            </Tooltip>
                          </ContentInput>
                        </Item>
                      </ContentInput>
                    </ContentInput>
                  </ContentInput>
                </>
              )}
            </>
          ) : (
            <>
              <ContentInput justify="flex-start" width="100%">
                <ContentInput padding="0" margin="0px 20px 0px 0px">
                  <div className="account-info-cleaning-content validate-frequency">
                    Cleaning (times per month)
                    <SelectV2
                      disabled={
                        sentStatus ||
                        isPreview ||
                        (formValues.isTemplate && formValues.isUserRole)
                      }
                      className="cleaning-select"
                      value={formValues.TimesPerMonth}
                      items={['1', '2', '3']}
                      name="TimesPerMonth"
                      onChange={onChange}
                      onBlur={(name, value) => {
                        onChange(name, 1);
                      }}
                      width="40%"
                      canTypeCustomValue={false}
                      blockCustomInput={true}
                    />
                  </div>
                </ContentInput>

                <ContentInput flex>
                  <InputFloatLabel
                    disabled={
                      sentStatus ||
                      isPreview ||
                      (formValues.isTemplate && formValues.isUserRole)
                    }
                    value={formValues.TimeOfDay}
                    name="TimeOfDay"
                    maxLength={20}
                    label="Time Window"
                    onChange={(name, value) => {
                      onChange(name, value);
                      if (monthlySameAsAbove) {
                        onChange('TimeOfDay', value);
                      }
                    }}
                    onBlur={onBlur('TimeOfDay')}
                  />
                </ContentInput>
              </ContentInput>
              {monthlyAddFrequency && (
                <ContentInput justify="flex-start" width="100%">
                  <ContentInput padding="0" margin="0px 20px 0px 0px">
                    <div className="account-info-cleaning-content">
                      Cleaning (times per month)
                      <SelectV2
                        disabled={
                          sentStatus ||
                          isPreview ||
                          (formValues.isTemplate && formValues.isUserRole)
                        }
                        className="cleaning-select"
                        value={formValues.secondCleaning}
                        items={['1', '2', '3']}
                        name="secondCleaning"
                        onChange={onChange}
                        width="40%"
                        canTypeCustomValue={false}
                        blockCustomInput={true}
                      />
                    </div>
                  </ContentInput>

                  <ContentInput
                    flex
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <InputFloatLabel
                      disabled={
                        sentStatus ||
                        isPreview ||
                        (formValues.isTemplate && formValues.isUserRole)
                      }
                      value={formValues.TimeOfDay}
                      name="TimeOfDay"
                      label="Time Window"
                      onChange={(name, value) => {
                        if (!monthlySameAsAbove) {
                          onChange(name, value);
                          return;
                        }
                        setMonthlySameAsAbove(!monthlySameAsAbove);
                        onChange(name, value);
                      }}
                      onBlur={onBlur('TimeOfDay')}
                    />
                    <Checkbox
                      label={<Label>Same as Above</Label>}
                      inverted
                      disabled={
                        sentStatus ||
                        isPreview ||
                        (formValues.isTemplate && formValues.isUserRole)
                      }
                      borderColor="gray"
                      dataCy="monthlySameAsAbove"
                      name="monthlySameAsAbove"
                      value="monthlySameAsAbove"
                      onChange={() => {
                        if (!monthlySameAsAbove) {
                          setFormValues({
                            ...formValues,
                            TimeOfDay: formValues.timeWindow,
                          });
                        }
                        setMonthlySameAsAbove(!monthlySameAsAbove);
                      }}
                      checked={monthlySameAsAbove}
                    />
                  </ContentInput>
                  <ContentInput
                    padding="0px"
                    self="center"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      marginLeft: '20px',
                      minWidth: '130px',
                    }}
                  ></ContentInput>
                </ContentInput>
              )}
            </>
          )}
        </CentralizedSection>
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToValues: () => ({
    ...accountInfoValues,
  }),
  handleSubmit: () => '',
};

const EnhancedAccountInfo = withFormik<any, IBidAccountInfo>(mapForm)(
  AccountInfoInspect
);

export { EnhancedAccountInfo as AccountInfoInspect };
