import React from 'react';

import { ImageWrapper } from './styles';

interface ISVGIconProps {
  icon: string;
  size?: string;
  onClick?: () => void;
}

export const SVGIcon: React.FC<ISVGIconProps> = ({ icon, size = '32px', onClick }) => (
  <ImageWrapper src={icon} width={size} height={size} onClick={onClick} />
);

