import { Types } from './types';
import {
  IArea,
  IAreaItem,
  IFirstForm,
  ISiteInformation,
  IAreaPhoto,
  IFormErrors,
} from 'core/interfaces';
import {
  IDispatchToProps,
  IInspectForm,
  ISaveReportRequest,
  ISuccessResult,
} from '../interfaces';

export const InspectFormActions: IDispatchToProps = {
  setAreaSelected: (Area: IArea) => ({
    type: Types.SET_AREA_SELECTED,
    payload: {
      Area,
    },
  }),
  setFirstForm: (firstForm: IFirstForm) => ({
    type: Types.SET_FIRST_FORM,
    payload: {
      firstForm,
    },
  }),
  setAccountInfo: (accountInfo: any) => ({
    type: Types.SET_ACCOUNT_INFO,
    payload: {
      accountInfo,
    },
  }),
  setSiteInformation: (siteInfo: ISiteInformation) => ({
    type: Types.SET_SITE_INFORMATION,
    payload: {
      siteInfo,
    },
  }),
  setNote: (note: string) => ({
    type: Types.SET_NOTE,
    payload: {
      note,
    },
  }),
  setInspectedBy: (inspectedBy: string, userIDFromApi: number) => ({
    type: Types.SET_INSPECTED_BY,
    payload: {
      inspectedBy,
      userIDFromApi,
    },
  }),
  addAreaRequest: (name: string) => ({
    type: Types.ADD_AREA_REQUEST,
    payload: {
      name,
    },
  }),
  setArea: (Areas: IArea[]) => ({
    type: Types.SET_AREA,
    payload: {
      Areas,
    },
  }),
  setItem: (areaItem: IAreaItem) => ({
    type: Types.SET_ITEM,
    payload: {
      areaItem,
    },
  }),
  setError: (error: string) => ({
    type: Types.SET_ERROR,
    payload: {
      error,
    },
  }),
  removeAreaRequest: (area: IArea) => ({
    type: Types.REMOVE_AREA_REQUEST,
    payload: {
      area,
    },
  }),
  addAreaItemRequest: (name: string) => ({
    type: Types.ADD_AREA_ITEM_REQUEST,
    payload: {
      name,
    },
  }),

  updateAreaItemRangeRequest: (areaItem: IAreaItem, grade: number) => ({
    type: Types.UPDATE_AREA_ITEM_RANGE_REQUEST,
    payload: {
      areaItem,
      grade,
    },
  }),
  updateAreaItems: (areaItems: IAreaItem[]) => ({
    type: Types.UPDATE_AREA_ITEMS,
    payload: {
      areaItems,
    },
  }),
  updateArea: (area: IArea) => ({
    type: Types.UPDATE_AREA,
    payload: {
      area,
    },
  }),
  removeAreaItemRequest: (areaItem: IAreaItem) => ({
    type: Types.REMOVE_AREA_ITEM_REQUEST,
    payload: {
      areaItem,
    },
  }),
  calculateRatesRequest: () => ({
    type: Types.CALCULATE_RATE_REQUEST,
  }),
  calculateRatesSuccess: (
    Areas: IArea[],
    GrandTotal: number,
    FacilityRating: number
  ) => ({
    type: Types.CALCULATE_RATE_SUCCESS,
    payload: {
      Areas,
      GrandTotal,
      FacilityRating,
    },
  }),
  calculateRatesFailure: (error: string) => ({
    type: Types.CALCULATE_RATE_FAILURE,
    payload: {
      error,
    },
  }),
  updateDuplicatedAreas: () => ({
    type: Types.UPDATE_DUPLICATED_AREAS,
  }),
  addAreaPhotoRequest: (file: File, fileUrl: string, lastFile: boolean) => ({
    type: Types.ADD_AREA_PHOTO_REQUEST,
    payload: {
      file,
      fileUrl,
      lastFile,
    },
  }),
  removeAreaPhotoRequest: (areaPhoto: IAreaPhoto) => ({
    type: Types.REMOVE_AREA_PHOTO_REQUEST,
    payload: {
      areaPhoto,
    },
  }),
  saveReportRequest: (
    type: 'draft' | 'template' | 'sent' | 'citytemplate',
    newForm?: 'new'
  ) => ({
    type: Types.SAVE_REPORT_REQUEST,
    payload: {
      type,
      newForm,
    },
  }),
  setShowPopup: (showPopup?: boolean) => ({
    type: Types.SET_SHOW_POPUP,
    payload: {
      showPopup,
    },
  }),
  saveReportSuccess: (
    successResult: ISuccessResult,
    type: 'draft' | 'template' | 'sent' | 'citytemplate',
    newForm?: boolean
  ) => ({
    type: Types.SAVE_REPORT_SUCCESS,
    payload: {
      successResult,
      type,
      newForm,
    },
  }),
  saveReportFailure: (error: string) => ({
    type: Types.SAVE_REPORT_FAILURE,
    payload: {
      error,
    },
  }),
  openSentModal: () => ({
    type: Types.OPEN_SENT_MODAL,
  }),
  sendEmailRequest: (successResult: ISuccessResult) => {
    return {
      type: Types.SEND_EMAIL_REQUEST,
      payload: {
        successResult, // pdfUri and orginalId
      },
    };
  },
  sendEmailSuccess: () => ({
    // missing the type result from backend
    type: Types.SEND_EMAIL_SUCCESS,
  }),
  sendEmailFailure: () => ({
    type: Types.SEND_EMAIL_FAILURE,
  }),
  validationFailure: () => ({
    type: Types.VALIDATION_FAILURE,
  }),
  getEditFormRequest: (
    id: string,
    locationId?: string,
    isTemplate?: boolean,
    isOptInCleanSuite?: boolean
  ) => ({
    type: Types.GET_EDIT_FORM_REQUEST,
    payload: {
      id,
      locationId,
      isTemplate,
      isOptInCleanSuite,
    },
  }),
  getPublicEditFormRequest: (id: string) => ({
    type: Types.GET_PUBLIC_EDIT_FORM_REQUEST,
    payload: {
      id,
    },
  }),
  getEditFormSuccess: (inspectionForm: IInspectForm) => ({
    type: Types.GET_EDIT_FORM_SUCCESS,
    payload: {
      inspectionForm,
    },
  }),
  getEditFormFailure: (error: string) => ({
    type: Types.GET_EDIT_FORM_FAILURE,
    payload: {
      error,
    },
  }),
  setUserAndFranchiseId: (franchiseId: number, userId: number) => ({
    type: Types.SET_USER_AND_FRANCHISE_ID,
    payload: {
      franchiseId,
      userId,
    },
  }),
  setPhotosModalState: (status: boolean) => ({
    type: Types.SET_PHOTOS_MODAL_STATE,
    payload: {
      status,
    },
  }),
  setNotesModalState: (status: boolean) => ({
    type: Types.SET_NOTES_MODAL_STATE,
    payload: {
      status,
    },
  }),
  setRatingModalState: (status: boolean) => ({
    type: Types.SET_RATING_MODAL_STATE,
    payload: {
      status,
    },
  }),
  setTemplateModal: (status: boolean) => ({
    type: Types.SET_TEMPLATE_MODAL,
    payload: {
      status,
    },
  }),
  setAddAreasModal: (status: boolean) => ({
    type: Types.SET_AREAS_MODAL_STATE,
    payload: {
      status,
    },
  }),
  setAddItemModalState: (status: boolean) => ({
    type: Types.SET_ADD_ITEM_MODAL_STATE,
    payload: {
      status,
    },
  }),
  setPdfModalState: (status: boolean, pdfUrl: string) => ({
    type: Types.SET_PDF_MODAL_STATE,
    payload: {
      status,
      pdfUrl,
    },
  }),
  setWarningModalState: (
    status: boolean,
    message: string | Array<string>,
    title?: string,
    onClose?: Function
  ) => ({
    type: Types.SET_WARNING_MODAL_STATE,
    payload: {
      status,
      message,
      title,
      onClose,
    },
  }),
  getInspectionsRequest: (userId: string) => ({
    type: Types.GET_INSPECTIONS_REQUEST,
    payload: {
      userId,
    },
  }),
  getInspectionsSuccess: (
    sentItems: IInspectForm[],
    draftItems: IInspectForm[],
    cityTemplateItems: IInspectForm[],
    totalDrafts: number,
    totalSents: number,
    totalCityTemplates: number
  ) => ({
    type: Types.GET_INSPECTIONS_SUCCESS,
    payload: {
      sentItems,
      draftItems,
      cityTemplateItems,
      totalDrafts,
      totalSents,
      totalCityTemplates,
    },
  }),
  getNextInspectionPageRequest: (
    type: string,
    userId: string,
    page: number,
    sortByMostRecent: boolean,
    dateMostRecent: boolean,
    azAscending: boolean
  ) => ({
    type: Types.GET_NEXT_INSPECTIONS_PAGE_REQUEST,
    payload: {
      type,
      userId,
      page,
      sortByMostRecent,
      dateMostRecent,
      azAscending,
    },
  }),
  getNextInspectionPageSuccess: (
    sentItems: IInspectForm[],
    draftItems: IInspectForm[],
    templateItems: IInspectForm[],
    cityTemplateItems: IInspectForm[],
    totalDrafts: number,
    totalSents: number,
    totalTemplates: number,
    totalCityTemplates: number
  ) => ({
    type: Types.GET_NEXT_INSPECTIONS_PAGE_SUCCESS,
    payload: {
      sentItems,
      draftItems,
      templateItems,
      cityTemplateItems,
      totalDrafts,
      totalSents,
      totalTemplates,
      totalCityTemplates,
    },
  }),
  getInspectionsFailure: (error: string) => ({
    type: Types.GET_INSPECTIONS_FAILURE,
    payload: {
      error,
    },
  }),
  sortInspections: (
    sortByMostRecent: boolean,
    dateMostRecent: boolean,
    azAscending: boolean
  ) => ({
    type: Types.SORT_INSPECTIONS,
    payload: {
      sortByMostRecent,
      dateMostRecent,
      azAscending,
    },
  }),
  archiveFormRequest: (form: IInspectForm) => ({
    type: Types.ARCHIVE_FORM_REQUEST,
    payload: {
      form,
    },
  }),
  archiveFormSuccess: () => ({
    type: Types.ARCHIVE_FORM_SUCCESS,
  }),
  archiveFormClose: () => ({
    type: Types.ARCHIVE_FORM_CLOSE,
  }),
  archiveFormFailure: (error: string) => ({
    type: Types.ARCHIVE_FORM_FAILURE,
    payload: {
      error,
    },
  }),
  openDraft: (form: IInspectForm) => ({
    type: Types.OPEN_DRAFT,
    payload: {
      form,
    },
  }),
  transformIntoDraft: () => ({
    type: Types.TRANSFORM_INTO_DRAFT,
  }),
  revertTransformedToDraftState: () => ({
    type: Types.REVERT_TRANSFORMED_TO_DRAFT_STATE,
  }),
  setInspectionReportId: (id: number) => ({
    type: Types.SET_INSPECTION_REPORT_ID,
    payload: {
      id,
    },
  }),

  duplicate: (form: IInspectForm) => ({
    type: Types.DUPLICATE,
    payload: {
      form,
    },
  }),

  openTemplate: (form: IInspectForm) => ({
    type: Types.OPEN_TEMPLATE,
    payload: {
      form,
    },
  }),
  openCityTemplate: (form: IInspectForm) => ({
    type: Types.OPEN_CITY_TEMPLATE,
    payload: {
      form,
    },
  }),

  openSent: (form: IInspectForm) => ({
    type: Types.OPEN_TEMPLATE,
    payload: {
      form,
    },
  }),

  openForm: (form: IInspectForm) => ({
    type: Types.OPEN_FORM,
    payload: {
      form,
    },
  }),
  addPreDefinedArea: (area: IArea) => ({
    type: Types.ADD_PRE_DEF_AREA,
    payload: {
      area,
    },
  }),
  setIsSent: () => ({
    type: Types.SET_IS_SENT,
  }),
  setIsNotSent: () => ({
    type: Types.SET_IS_NOT_SENT,
  }),

  setIsTemplate: (isTemplate) => ({
    type: Types.SET_IS_TEMPLATE,
    payload: {
      isTemplate,
    },
  }),

  setIsCityTemplate: (isCityTemplate) => ({
    type: Types.SET_IS_CITY_TEMPLATE,
    payload: {
      isCityTemplate,
    },
  }),

  openExitModal: () => ({
    type: Types.OPEN_EXIT_MODAL,
  }),
  closeExitModal: () => ({
    type: Types.CLOSE_EXIT_MODAL,
  }),
  setFormErrors: (errors: IFormErrors) => ({
    type: Types.SET_FORM_ERRORS,
    payload: {
      errors,
    },
  }),
  setNeedsSaving: (needsSaving: boolean) => ({
    type: Types.SET_NEEDS_SAVING,
    payload: {
      needsSaving,
    },
  }),
  setFormClicked: (id: number, type: string) => ({
    type: Types.SET_FORM_CLICKED,
    payload: {
      id,
      type,
    },
  }),
  setLoadingState: (loading: boolean) => ({
    type: Types.SET_LOADING_STATE,
    payload: loading,
  }),

  updateTemplate: (id: string | number, shouldChangePage?: boolean) => ({
    type: Types.UPDATE_TEMPLATE,
    payload: {
      id,
      shouldChangePage,
    },
  }),

  createTemplateFromSent: (id?: string | number) => ({
    type: Types.CREATE_TEMPLATE_FROM_SENT,
    payload: {
      id,
    },
  }),

  createDraftFromTemplate: () => ({
    type: Types.CREATE_DRAFT_FROM_TEMPLATE,
    payload: null,
  }),

  createTemplateFromDraft: () => ({
    type: Types.CREATE_TEMPLATE_FROM_DRAFT,
    payload: null,
  }),

  setLocationIdWarningVisible: (visible: boolean) => ({
    type: Types.SET_LOCATION_ID_WARNING_VISIBLE,
    payload: visible,
  }),
};
