import React, { useEffect, useState } from 'react';
import {
  ParagraphRow,
  LeftContentWrapper,
  Container,
  RightContentWrapper,
  InfoBubbleWrapper,
  InsertNewButton,
} from './styles';
import TextArea from './components/TextArea';
import IconButton from './components/IconButton';
// import DefaultButton from './components/atoms/Button';
import { faInfo, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { romanize } from './utils';
import {
  getCustomizeTerms,
  saveCustomizeTerms,
} from 'modules/BidInfoForm/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PreviewTerms } from './Preview';

// export const Button: React.FC<IButtonProps> = (props: IButtonProps) => (
//   <DefaultButton width="325px" height="50px" {...props} />
// );

interface ParagraphProps {
  paragraphNumber?: number;
  onClickReset?(): void;
  onClickDelete?(): void;
  onChange?(value: string): void;
  content: string;
  tagsContent?: string;
  isDynamic?: boolean;
  hasModification?: boolean;
  readonly?: boolean;
}

interface ResetButtonProps {
  onClickReset(): void;
}

interface DeleteButtonProps {
  onClickDelete(): void;
}

const ResetButton: React.FC<ResetButtonProps> = ({ onClickReset }) => {
  return (
    <IconButton
      icon={faRedo}
      iconStyle={{
        transformOrigin: 'center',
        transform: 'scaleX(-1)',
      }}
      caption="Reset"
      onClick={onClickReset}
    />
  );
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClickDelete }) => {
  return (
    <IconButton
      icon={faTrash}
      iconStyle={{
        transformOrigin: 'center',
        transform: 'scaleX(-1)',
      }}
      caption="Delete"
      onClick={onClickDelete}
    />
  );
};

const InfoBubble: React.FC = (props) => {
  // const tooltipRef = useRef<HTMLSpanElement>();
  // const [isShowing, setIsShowing] = useState(false);

  return (
    <InfoBubbleWrapper {...props}>
      <FontAwesomeIcon icon={faInfo} width="5px" />
    </InfoBubbleWrapper>
  );
};

const Paragraph: React.FC<ParagraphProps> = ({
  paragraphNumber,
  onClickReset,
  onClickDelete,
  onChange,
  content,
  tagsContent,
  isDynamic,
  hasModification,
  readonly,
}) => {
  const paragraphStyles = () => {
    const readonlyStyles = {
      outline: 'none',
      backgroundColor: '#f0f0f0',
      pointerEvents: 'none',
    };

    let styles = {
      fontSize: '12px',
      height: '150px',
      outline: 'none',
      border: `1px solid ${getEditionColor()}`,
    };

    switch (actualParagraphStyle) {
      case 'readonly':
        styles = { ...readonlyStyles, ...styles };
        break;
      default:
    }

    return styles;
  };
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [actualParagraphStyle, setactualParagraphStyle] = useState<string>('');

  useEffect(() => {
    if (readonly && actualParagraphStyle !== 'readonly') {
      setactualParagraphStyle('readonly');
    }
  }, [readonly, setactualParagraphStyle, actualParagraphStyle]);

  const getEditionColor = () => {
    if (isEditing) {
      return '#4D90FE';
    }

    if (hasModification) {
      return '#eda328';
    }

    return '#000000';
  };

  return (
    <ParagraphRow
      style={{
        marginBottom: hasModification && !isDynamic ? '35px' : '20px',
      }}
    >
      <LeftContentWrapper>
        {paragraphNumber ? romanize(paragraphNumber) : ''}
        {!readonly && onClickReset && !isDynamic && (
          <ResetButton onClickReset={onClickReset} />
        )}
        {onClickDelete && isDynamic && (
          <DeleteButton onClickDelete={onClickDelete} />
        )}
      </LeftContentWrapper>
      <TextArea
        style={{
          ...paragraphStyles(),
          paddingRight: tagsContent ? 40 : 10,
        }}
        value={content}
        readOnly={readonly}
        onChange={readonly ? () => {} : onChange}
        onFocus={() => {
          setIsEditing(true);
        }}
        onBlur={() => {
          setIsEditing(false);
        }}
      />
      {hasModification && (
        <span
          style={{
            padding: '0px 10px 0px 10px',
            fontSize: '10px',
            position: 'absolute',
            top: '-12px',
            left: '50px',
            background: 'white',
            color: getEditionColor(),
          }}
        >
          This content has been manually edited
        </span>
      )}
    </ParagraphRow>
  );
};

interface ICustomizeTermsTemplateProps {
  isSpecialty?: boolean;
  franchiseId?: number;
  previewState?: boolean;
  introduction: string;
  paragraphs: string[];
  onRestoreParagraph(index: number): void;
  onChangeParagraph(index: number): void;
  onDeleteParagraph(index: number): void;
  setParagraphs(paragraphs: string[]): void;
  setIntroduction(introduction: string): void;
  introductionModified?: boolean;
}

export const NewCustomizeTerms: React.FC<ICustomizeTermsTemplateProps> = ({
  isSpecialty,
  franchiseId,
  previewState,
  introduction,
  introductionModified,
  paragraphs,
  onRestoreParagraph,
  onChangeParagraph,
  onDeleteParagraph,
  setParagraphs,
  setIntroduction,
}) => {
  if (previewState) {
    return <PreviewTerms terms={{ introduction, paragraphs }} />;
  } else {
    return (
      <Container>
        <Paragraph
          onClickReset={onRestoreParagraph(-1)}
          onChange={onChangeParagraph(-1)}
          content={introduction}
          readonly
          hasModification={introductionModified}
        />
        {paragraphs.map((paragraph, index, arr) => {
          const paragraphContent = (
            <Paragraph
              key={paragraph.key}
              onClickReset={onRestoreParagraph(index)}
              onChange={onChangeParagraph(index)}
              paragraphNumber={paragraph.position || index + 1}
              content={paragraph.value}
              isDynamic={
                isSpecialty
                  ? index > 4 && index !== arr.length - 1
                  : index > 7 && index !== arr.length - 1
              }
              onClickDelete={onDeleteParagraph(index)}
              hasModification={paragraph.modified}
            />
          );

          if (
            index == arr.length - 1 &&
            ((!isSpecialty && arr.length < 12) ||
              (isSpecialty && arr.length < 9))
          ) {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '20px',
                  }}
                >
                  <InsertNewButton
                    onClick={() => {
                      setParagraphs((p) => {
                        let newArray = [...p];
                        newArray.splice(index, 0, {
                          key: `Terms${index + 1}`,
                          systemReference: null,
                          value: '',
                          modified: true,
                        });
                        newArray = newArray.map((i, idx) => ({
                          ...i,
                          key: `Terms${idx + 1}`,
                          position: idx + 1,
                        }));
                        return newArray;
                      });
                    }}
                  >
                    Insert new paragraph
                  </InsertNewButton>
                </div>
                {paragraphContent}
              </>
            );
          }

          return paragraphContent;
        })}
      </Container>
    );
  }
};

export default NewCustomizeTerms;
