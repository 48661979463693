import React, { useState, useMemo, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { isNumber, getQueryString } from 'core/utils';
import { simplifyConsecutiveDays } from '../../../utils';
import aboutAnagoPic from '../../../assets/about-anago-pic.png';
import aboutAnagoWomanPic from '../../../assets/about-anago-woman.png';

import {
  ContentInput,
  GenericAccordion,
  RadioButton,
  Checkbox,
} from 'core/components';

import {
  Layout,
  ResponsiveLayout,
  PricingBoxes,
  ResponsiveBoxes,
  BlueSection,
  GraySection,
  WhiteSection,
  RoundSubSection,
  SubSectionHeader,
  SubSection,
  HiddenLayout,
} from '../../../components/layout';

import {
  Paragraph,
  SectionParagraph,
  AboutParagraph,
  HexagonParagraph,
  Hexagon,
  Stamps,
  IntroductionPicture,
  AboutAnagoPicture,
  PeoplePicture,
  Anchor,
  SignatureLine,
  Input,
  DivInput,
  Logo,
} from '../../../components/atoms';

import { TermsAndConditions } from '../components/organisms';

import {
  H2BoldItalic,
  H3Bold,
  H3SemiBoldItalic,
  List,
  ListItem,
  SectionTitle,
  IntroductionTitle,
  AboutTitle,
  AccordionHeader,
  SignatureName,
  SignatureTitle,
  H3SemiBold,
  H3BoldItalic,
  LogoHolder,
  LabelPlus,
  HeaderBase,
} from '../../../styles';

import { parseAreas } from '../../../parser';

import { formatNumberByMask, formatPrice, toTitleCase } from 'core/utils';
import { moneyMaskParams } from 'core/utils/masks';

import about1 from '../../../assets/about-1.png';
import about2 from '../../../assets/about-2.png';
import about3 from '../../../assets/about-3.png';
import about4 from '../../../assets/about-4.png';
import about5 from '../../../assets/about-5.png';
import about6 from '../../../assets/about-6.png';

import stamp1 from '../../../assets/stamp-1.png';
import stamp2 from '../../../assets/stamp-2.png';
import stamp3 from '../../../assets/stamp-3.png';
import stamp4 from '../../../assets/stamp-4.png';
import stamp5 from '../../../assets/stamp-5.png';
import logo from '../../../assets/logo.png';
import PageReferences from './PageReferences';

const declineOption = (value, active, biggerThan768) => {
  const bgColor = active ? '#216eab' : '#efefef';
  let color = active ? '#fff' : 'gray';

  if (biggerThan768) {
    return (
      <Layout
        width="100%"
        paddingLeft="20px"
        marginBottom="0"
        marginTop={'3px'}
        paddingTop={biggerThan768 ? '0px' : '30px'}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <PricingBoxes padding="0 10px 0 10px" minWidth="140px" width="100%">
          <Input
            data-cy="decline-option"
            defaultValue={value}
            backgroundColor={bgColor}
            color={color}
            style={{ textAlign: 'left', paddingLeft: '20px' }}
          />
        </PricingBoxes>
      </Layout>
    );
  }

  color = active ? '#fff' : null;

  return (
    <ContentInput padding="0" width="100%">
      <ResponsiveLayout
        padding="15px"
        paddingLeft="20px"
        paddingRight="20px"
        margin="0"
        width="auto"
        style={{
          backgroundColor: bgColor,
          border: !active ? '1px solid gray' : '1px solid #216eab',
          borderRadius: '5px',
          color: color,
        }}
      >
        <ContentInput paddingTop="0">
          <HeaderBase
            fontSize="17px"
            padding="5px"
            textAlign="left"
            width="auto"
            color={color}
          >
            {value}
          </HeaderBase>
        </ContentInput>
      </ResponsiveLayout>
    </ContentInput>
  );
};
const renderBoxes = (
  frequency: any,
  active: boolean,
  isHeader: boolean = false,
  biggerThan768: boolean = false,
  isSmartCleanSet = false,
  salesTax: boolean = false,
  hasDesinfection: boolean = false,
  growHeight: boolean = false
) => {
  const bgColor = active ? '#216eab' : '#efefef';
  const color = active ? '#fff' : null;
  const servicesColor = null;

  return (
    <ContentInput padding="0" width="100%">
      <ResponsiveLayout
        padding="10px"
        paddingLeft="20px"
        paddingRight="20px"
        margin="0"
        width="auto"
        style={{
          backgroundColor: bgColor,
          border: !active ? '1px solid gray' : '1px solid #216eab',
          borderRadius: '5px',
          color: color,
        }}
      >
        <ContentInput paddingTop="0">
          <HeaderBase
            fontSize="15px"
            padding="5px"
            textAlign="left"
            style={{ fontWeight: 'bold' }}
            color={color}
          >
            {frequency.value}
          </HeaderBase>
          {hasDesinfection && (
            <H3BoldItalic
              color="#F7A11D"
              marginTop="-5px"
              padding="5px"
              textAlign="center"
              style={{ display: 'flex' }}
            >
              & Protection{' '}
              <LabelPlus style={{ paddingLeft: '2px' }}> +</LabelPlus>{' '}
              Disinfection&#174;
            </H3BoldItalic>
          )}
        </ContentInput>
        <hr
          style={{
            width: '100%',
            borderColor: 'white',
            marginTop: '-5px',
          }}
        />
        <ContentInput
          paddingTop="0"
          flex
          flexDirection="row"
          justify="flex-start"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            data-cy={`frequency-header-${frequency.index}`}
            textAlign={biggerThan768 ? 'center' : 'left'}
          >
            Frequency:
          </H3Bold>
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{ fontFamily: 'OpenSans-Regular' }}
            data-cy={`frequency-input-${frequency.index}`}
            color={color}
          >
            {frequency.frequency}
          </H3Bold>
        </ContentInput>
        <ContentInput
          paddingTop="0"
          flex
          flexDirection="row"
          justify="flex-start"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            data-cy={`frequency-days-serviced-header-${frequency.index}`}
            textAlign={biggerThan768 ? 'center' : 'left'}
          >
            Days Serviced:
          </H3Bold>
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{ fontFamily: 'OpenSans-Regular' }}
            data-cy={`frequency-days-serviced-input-${frequency.index}`}
            color={color}
          >
            {frequency.daysServiced}
          </H3Bold>
        </ContentInput>
        <ContentInput
          paddingTop="0"
          flex
          flexDirection="row"
          justify="flex-start"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            data-cy={`frequency-time-window-header-${frequency.index}`}
            textAlign={biggerThan768 ? 'center' : 'left'}
          >
            Time Window:
          </H3Bold>
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{ fontFamily: 'OpenSans-Regular' }}
            data-cy={`frequency-time-window-input-${frequency.index}`}
            color={color}
          >
            {frequency.timeWindow}
          </H3Bold>
        </ContentInput>
        {frequency.services &&
          frequency.services.map((service, sIndex) => (
            <ContentInput
              flex
              flexDirection="row"
              justify="space-between"
              alignItems="center"
              style={{
                lineHeight: '16px',
                backgroundColor: color,
                borderRadius: '10px',
                border: active ? 'none' : '1px solid gray',
                padding: '0 5px',
                marginBottom: '5px',
              }}
            >
              <H3Bold
                padding="5px"
                textAlign="left"
                color={servicesColor}
                width="auto"
                textAlign={biggerThan768 ? 'center' : 'left'}
                data-cy={`frequency-service-label-header-${frequency.index}-${sIndex}`}
                style={{
                  fontFamily: 'OpenSans-Regular',
                  position: 'relative',
                  fontSize: '12.5px',
                }}
              >
                {service.label}
              </H3Bold>
              <H3Bold
                fontSize="12.5px"
                padding="5px"
                textAlign="left"
                width="auto"
                data-cy={`frequency-service-label-input-${frequency.index}-${sIndex}`}
                style={{ fontFamily: 'OpenSans-Regular', fontSize: '12.5px' }}
                color={servicesColor}
              >
                {service.value}
              </H3Bold>
            </ContentInput>
          ))}
        <ContentInput
          paddingTop="10px"
          flex
          flexDirection="row"
          justify="space-between"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            textAlign={biggerThan768 ? 'center' : 'left'}
            data-cy={`frequency-monthly-price-header-${frequency.index}`}
            style={{ position: 'relative' }}
          >
            Total Monthly Price:
            {salesTax && (
              <sup style={{ position: 'absolute', top: '-5px' }}>†</sup>
            )}
          </H3Bold>
          <HeaderBase
            fontSize="15px"
            padding="5px"
            textAlign="left"
            width="auto"
            data-cy={`frequency-monthly-price-input-${frequency.index}`}
            style={{ fontFamily: 'OpenSans-Bold' }}
            color={color}
          >
            {frequency.monthlyPrice}
          </HeaderBase>
        </ContentInput>
      </ResponsiveLayout>
      {salesTax && (
        <Paragraph
          width={'100%'}
          textAlign={'center'}
          paddingRight="0"
          color="#000"
        >
          <i>
            <small className="tm">†</small> Plus Applicable Sales Tax.
          </i>
        </Paragraph>
      )}
    </ContentInput>
  );
};

const renderBoxes768 = (
  frequency: any,
  active: boolean,
  isHeader: boolean = false,
  biggerThan768: boolean = false,
  isSmartCleanSet = false,
  salesTax: boolean = false,
  hasDesinfection: boolean = false,
  growHeight: boolean = false
) => {
  const bgColor = active ? '#216eab' : '#efefef';
  const color = active ? '#fff' : 'gray';

  return (
    <Layout
      width="100%"
      paddingLeft="20px"
      marginBottom="0"
      marginTop={isHeader ? '-45px' : '3px'}
      paddingTop={biggerThan768 ? (isHeader ? '8px' : '0px') : '30px'}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <PricingBoxes
        padding="0 10px 0 10px"
        minWidth="140px"
        width="20%"
        position="relative"
      >
        {isHeader && (
          <H3Bold
            color="#000"
            maxFontSize="16px"
            padding="5px"
            textAlign="center"
            data-cy={`frequency-header-${frequency.index}`}
          >
            Frequency
          </H3Bold>
        )}
        <Input
          defaultValue={frequency.frequency}
          backgroundColor={bgColor}
          color={color}
          hasDesinfection={hasDesinfection || growHeight}
          paddingDesinfection={hasDesinfection}
          data-cy={`frequency-input-${frequency.index}`}
        />
        {hasDesinfection && (
          <span
            style={{
              color: '#F7A11D',
              fontSize: '12px',
              marginTop: '-30px',
              textAlign: 'center',
              paddingBottom: '6.5px',
            }}
          >
            & Protection+ Disinfect
          </span>
        )}
      </PricingBoxes>
      <PricingBoxes padding="0 10px 0 10px" minWidth="140px" width="40%">
        {isHeader && (
          <H3Bold
            color="#000"
            maxFontSize="16px"
            padding="5px"
            textAlign="center"
            data-cy={`frequency-days-serviced-header-${frequency.index}`}
          >
            Days Serviced
          </H3Bold>
        )}
        <Input
          defaultValue={frequency.daysServiced}
          backgroundColor={bgColor}
          color={color}
          data-cy={`frequency-days-serviced-input-${frequency.index}`}
          hasDesinfection={hasDesinfection || growHeight}
        />
      </PricingBoxes>
      <PricingBoxes padding="0 10px 0 10px" minWidth="140px" width="20%">
        {isHeader && (
          <H3Bold
            color="#000"
            maxFontSize="16px"
            padding="5px"
            textAlign="center"
            data-cy={`frequency-time-window-header-${frequency.index}`}
          >
            Time Window
          </H3Bold>
        )}
        <DivInput
          backgroundColor={bgColor}
          color={color}
          hasDesinfection={hasDesinfection || growHeight}
          data-cy={`frequency-time-window-input-${frequency.index}`}
        >
          {frequency.timeWindow}
        </DivInput>
      </PricingBoxes>
      <PricingBoxes padding="0 10px 0 10px" minWidth="140px" width="20%">
        {isHeader && (
          <H3Bold
            color="#000"
            maxFontSize="16px"
            padding="5px"
            textAlign="center"
            data-cy={`frequency-monthly-price-header-${frequency.index}`}
          >
            {isSmartCleanSet && (
              <span>
                SmartClean<sup className="sm">&#8480;</sup> Price{' '}
                {salesTax && <sup>†</sup>}
              </span>
            )}
            {!isSmartCleanSet && (
              <span>
                Monthly Price
                {salesTax && <sup>†</sup>}
              </span>
            )}
          </H3Bold>
        )}
        <Input
          defaultValue={frequency.monthlyPrice}
          backgroundColor={bgColor}
          color={color}
          data-cy={`frequency-monthly-price-input-${frequency.index}`}
          hasDesinfection={hasDesinfection || growHeight}
        />
      </PricingBoxes>
    </Layout>
  );
};

const Page1 = (props, ref) => {
  const {
    data,
    parsedData,
    franchiseData,
    updateData,
    selectedValues,
    downloadBlankPdf,
    selectedItem,
  } = props;
  const values = data || [];

  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] =
    React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);
  const [hasProtection, setHasProtection] = React.useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    if (parsedData) {
      setHasProtection(
        (parsedData.protectionPackages.length > 0 ||
          parsedData.secondProtectionPackages.length > 0) &&
          !parsedData.smartClean
      );
    }
  }, [parsedData]);

  const [selectedFrequency, setSelectedFrequency] = useState(
    selectedValues.selectedFrequency == false
      ? 1
      : selectedValues.selectedFrequency == true
      ? 2
      : selectedValues.selectedFrequency == 3
      ? -1
      : null
  );

  const [isDirty, setIsDirty] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const isSmartCleanSet = data && data['SmartClean'];

  const getCleaningSpecificationsFooterText = () => {
    const getValue = (val: string) => {
      switch (val) {
        case 'Client Supplied':
          return 'to be furnished by the Client';
        case 'Included in Price':
          return 'are included in the price';
        case 'Billed Separately':
        default:
          return 'will be billed separately';
      }
    };

    return `
      All trash liners ${getValue(values['DomainLinersId'])}.<br />
      All Restroom supplies (towels, tissue, hand soap) ${getValue(
        values['DomainRestroomId']
      )}.`;
  };

  useEffect(() => {
    if (!selectedValues.selectedFrequency) {
      setSelectedFrequency(
        values['SelectedFrequency'] == true
          ? 2
          : values['SelectedFrequency'] == false
          ? 1
          : null
      );
    }
  }, [values]);

  React.useImperativeHandle(ref, () => ({
    getData: () => ({
      clearSelection: () => {
        setSelectedFrequency(null);
      },
      selectedFrequency: hasProtection
        ? null
        : selectedFrequency == 1
        ? false
        : selectedFrequency == 2
        ? true
        : selectedFrequency == 3
        ? -1
        : null,
      isTermsChecked,
      isDirty,
      data,
      hasProtectionPackages:
        parsedData &&
        (parsedData.protectionPackages.length > 0 ||
          parsedData.secondProtectionPackages.length > 0),
    }),
    clearIsDirty: () => {
      setIsDirty(false);
    },
  }));

  const franchiseValues = franchiseData || [];
  let serviceAreas = parseAreas(values, true);

  if (Array.isArray(serviceAreas)) {
    serviceAreas = serviceAreas.map((i) => `${i}(s)`);
    if (values['OthersArea']) {
      serviceAreas.push(...values['OthersArea'].split(','));
    }
    serviceAreas = serviceAreas.filter((i) => !!i.trim());
  }

  let daysServiced1 = values['DaysCleanedListText']
    ? values['DaysCleanedListText']
        .split(', ')
        .map((item) => item.substring(0, 3))
    : [];
  daysServiced1 = simplifyConsecutiveDays(daysServiced1);

  let daysServiced2 = values['SecondDaysCleanedListText']
    ? values['SecondDaysCleanedListText']
        .split(', ')
        .map((item) => item.substring(0, 3))
    : [];

  daysServiced2 = values['IsMonthlyCleaning']
    ? 'MONTHLY'
    : simplifyConsecutiveDays(daysServiced2);

  const getDaysCleaned = () => {
    let val = values['SelectedDaysCleanedListText'];

    val = val.split(', ').map((x) => x.substr(0, 3));

    if (val.length) {
      return simplifyConsecutiveDays(val);
    }

    return '';
  };

  const frequenciesOptions = () => {
    const ret = [];
    const functionRender = equalsOrBiggerThan768 ? renderBoxes768 : renderBoxes;
    const services = [
      parsedData &&
        (parsedData.costSupplies ||
          (parsedData.includeInPrice &&
            (isNumber(parsedData.porterOverride) ||
              parsedData.monthlyPorterPrice > 0))) && {
          label: 'Custom Cleaning Service:',
          value: 'Included',
        },
      parsedData &&
        parsedData.includeInPrice &&
        (isNumber(parsedData.porterOverride) ||
          parsedData.monthlyPorterPrice > 0) && {
          label: 'Day Porter Included',
          value: formatPrice(
            isNumber(parsedData.porterOverride)
              ? parsedData.porterOverride
              : parsedData.monthlyPorterPrice
          ),
        },
      parsedData &&
        parsedData.costSupplies && {
          label: 'Supplies Included',
          value: formatPrice(parsedData.costSupplies),
        },
    ].filter((i) => !!i);

    if (data) {
      if (isSmartCleanSet) {
        ret.push({
          value: 1,
          render: functionRender(
            {
              index: 0,
              value:
                values['TitleQuote1'] ||
                `Quote 1: ${
                  values['SmartCleanDaysCleaned'] || '0'
                }x Weekly SmartClean Service`,
              frequency: `${values['SmartCleanDaysCleaned'] || '0'}x Weekly`,
              daysServiced: values['SelectedDaysCleanedListCompactText'],
              timeWindow: values['TimeOfDay'],
              services: services,
              monthlyPrice: `$${formatNumberByMask(
                (
                  (values['SmartCleanBidAmount']
                    ? values['SmartCleanBidAmount']
                    : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
                ).toString(),
                { ...moneyMaskParams, thousandsSeparatorSymbol: ',' }
              )}`,
            },
            selectedFrequency == 1,
            true,
            equalsOrBiggerThan768,
            isSmartCleanSet,
            franchiseValues && franchiseValues.SalesTax,
            false,
            values['TimeOfDay'] && values['TimeOfDay'].length > 15
          ),
        });
      } else {
        ret.push({
          value: 1,
          render: functionRender(
            {
              index: 0,
              value:
                values['TitleQuote1'] ||
                `${
                  !values.IsMonthlyCleaning
                    ? `Quote 1: ${values.DaysCleaned || '0'}x Weekly Service`
                    : `Quote 1: ${values.TimesPerMonth}x Monthly Service`
                }`,
              frequency: `${
                !values.IsMonthlyCleaning
                  ? `${values.DaysCleaned || '0'}x Weekly`
                  : `${values.TimesPerMonth}x Monthly`
              }`,
              services: services,
              daysServiced: `${
                !values.IsMonthlyCleaning
                  ? values['DaysCleanedListTextCompactText']
                  : 'MONTHLY'
              }`,
              timeWindow: values['TimeOfDay'],
              monthlyPrice: `$${formatNumberByMask(
                (
                  (values['BidAmountOverride']
                    ? values['BidAmountOverride']
                    : values['BidAmount']
                    ? values['BidAmount']
                    : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
                ).toString(),
                { ...moneyMaskParams, thousandsSeparatorSymbol: ',' }
              )}`,
            },
            selectedFrequency == 1,
            true,
            equalsOrBiggerThan768,
            isSmartCleanSet,
            franchiseValues && franchiseValues.SalesTax,
            values['IsMonthlyCleaning']
              ? values['MonthlyFrequencyDisinfection']
              : values['FirstFrequencyDisinfection'],
            values['TimeOfDay'] && values['TimeOfDay'].length > 15
          ),
        });

        if (values['SecondDaysCleaned']) {
          ret.push({
            index: 1,
            value: 2,
            render: functionRender(
              {
                value:
                  values['TitleQuote2'] ||
                  `Quote 2: ${
                    values['SecondDaysCleaned'] || '0'
                  }x Weekly Service`,
                frequency: `${values['SecondDaysCleaned'] || '0'}x Weekly`,
                daysServiced: values['SecondDaysCleanedListTextCompactText'],
                timeWindow: values['SecondTimeOfDay'],
                services: services,
                monthlyPrice: `$${formatNumberByMask(
                  (
                    (values['SecondBidAmountOverride']
                      ? values['SecondBidAmountOverride']
                      : values['SecondBidAmount']
                      ? values['SecondBidAmount']
                      : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
                  ).toString(),
                  { ...moneyMaskParams, thousandsSeparatorSymbol: ',' }
                )}`,
              },
              selectedFrequency == 2,
              !equalsOrBiggerThan768,
              equalsOrBiggerThan768,
              isSmartCleanSet,
              franchiseValues && franchiseValues.SalesTax,
              values['SecondFrequencyDisinfection'],
              values['TimeOfDay'] && values['TimeOfDay'].length > 15
            ),
          });
        }

        if (
          values['IsMonthlyCleaning'] &&
          values['MonthlyAddFrequency'] &&
          values['SecondTimesPerMonth']
        ) {
          ret.push({
            index: 1,
            value: 2,
            render: functionRender(
              {
                value:
                  values['TitleQuote2'] ||
                  `Quote 2: ${values['SecondTimesPerMonth']}x Monthly Service`,
                frequency: `${values['SecondTimesPerMonth']}x Monthly`,
                daysServiced: 'MONTHLY',
                timeWindow: values['SecondTimeOfDay'],
                services: services,
                monthlyPrice: `$${formatNumberByMask(
                  (
                    (values['SecondBidAmountOverride']
                      ? values['SecondBidAmountOverride']
                      : values['SecondBidAmount']
                      ? values['SecondBidAmount']
                      : 0) + (values['OwnerHasEcoInvoice'] ? 5 : 0)
                  ).toString(),
                  { ...moneyMaskParams, thousandsSeparatorSymbol: ',' }
                )}`,
              },
              selectedFrequency == 2,
              !equalsOrBiggerThan768,
              equalsOrBiggerThan768,
              isSmartCleanSet,
              franchiseValues && franchiseValues.SalesTax,
              values['SecondMonthlyFrequencyDisinfection'],
              values['TimeOfDay'] && values['TimeOfDay'].length > 15
            ),
          });
        }
      }
      ret.push({
        index: 2,
        value: 3,
        render: declineOption(
          `I decline a Monthly Service option.`,
          selectedFrequency == 3,
          equalsOrBiggerThan768
        ),
      });
    }
    return ret;
  };

  const getFirstName = (fullName: string = '') => {
    return fullName.trim().split(' ')[0];
  };

  const isPhysicalContract =
    getQueryString(window.location.href, 'physicalContract') == 'true';

  return (
    <Layout margin="0">
      <WhiteSection padding="40px 25px 25px 25px" paddingBottom="0 !important">
        <SubSection paddingBottom="0 !important">
          <IntroductionTitle
            maxFontSize="30px"
            textAlign="center"
            paddingLeft="0"
          >
            Introduction
          </IntroductionTitle>
          <IntroductionPicture />
          <Layout
            alignItems="start"
            width="100%"
            paddingTop={'20px'}
            margin="-10px 0 0 0"
            position="relative"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <H3Bold maxFontSize="15px" margin="10px 0 5px 0">
              {values['Date']}
            </H3Bold>
            <Paragraph fontSize={biggerThan768 ? '14px' : '12px'} marginTop="0">
              Dear{' '}
              {getFirstName(
                values['AddressedToWalkThru']
                  ? values['WalkThruFirstName']
                  : values['DecisionMakerFirstName']
              )}
            </Paragraph>

            <Paragraph
              fontSize={biggerThan768 ? '14px' : '12px'}
              margin="-15px 0 0 0"
              textAlign="justify"
              paddingBottom="0"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: values['ProposalLetter'],
                }}
              />
            </Paragraph>
            <Layout width="100%">
              <ContentInput
                width={'100%'}
                maxWidth={equalsOrBiggerThan768 ? '285px' : 'auto'}
              >
                <SignatureLine marginTop="80px" />
                <ContentInput
                  width="100%"
                  padding="0"
                  justify={equalsOrBiggerThan768 ? 'flex-start' : 'center'}
                  margin="-100px 0 0 0"
                >
                  <img src={values['SalesSignature']} height="71px" />
                </ContentInput>
                <ContentInput
                  justify={equalsOrBiggerThan768 ? 'flex-start' : 'center'}
                  flexDirection="column"
                  width="100%"
                  margin="-20px 0 20px 0"
                >
                  <SignatureName
                    maxFontSize="15px"
                    width="100%"
                    textAlign={equalsOrBiggerThan768 ? 'left' : 'center'}
                  >
                    {values['CreatedByName']}
                  </SignatureName>
                  <SignatureTitle
                    maxFontSize="15px"
                    width="100%"
                    marginTop="-5px"
                    textAlign={equalsOrBiggerThan768 ? 'left' : 'center'}
                  >
                    {values['SalesRepresentativeTitle']}
                  </SignatureTitle>
                </ContentInput>
              </ContentInput>
              <Paragraph
                marginTop="0"
                fontSize={biggerThan768 ? '14px' : '12px'}
                lineHeight="16px"
                paddingBottom="40px"
              >
                {franchiseValues['AnagoOfCityName']} <br />
                {franchiseValues['MasterSitePhone'] && (
                  <>
                    {franchiseValues['MasterSitePhone']} <br />{' '}
                  </>
                )}
                {franchiseValues['MasterAddress1']},{' '}
                {franchiseValues['MasterAddress2']}
                <br />
                {franchiseValues['MasterSiteCity']},{' '}
                {franchiseValues['MasterSiteStateAbbrev']}{' '}
                {franchiseValues['MasterSiteZip']} <br />
              </Paragraph>
            </Layout>
            <AboutAnagoPicture src={aboutAnagoPic} />
          </Layout>
        </SubSection>
      </WhiteSection>
      <BlueSection padding="0 25px 0 25px" position="relative">
        <SubSection>
          <ContentInput paddingTop="50px">
            <SectionTitle
              maxFontSize="30px"
              textAlign="center !important"
              color="#fff"
              paddingLeft="0 !important"
            >
              THE <i style={{ textDecoration: 'underline' }}>SUPERIOR</i> CHOICE
              FOR COMMERCIAL CLEANING.
            </SectionTitle>
          </ContentInput>
          <Layout
            alignItems="start"
            width="100%"
            paddingTop="15px"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <AboutParagraph color="#fff" textAlign="justify">
              Our quote is based on time values outlined by the Building Service
              Contractors Association International and the International
              Sanitary Standards Association. These time values are standards
              within our industry utilized in estimating Housekeeping Tasks. In
              the summary that follows, a custom-tailored program has been
              compiled for your facility. All supervision, labor, supplies and
              equipment, payroll taxes, and insurance have been included in the
              pricing schedule.
            </AboutParagraph>
            <ResponsiveLayout
              style={{
                display: 'flex',
                flexDirection: equalsOrBiggerThan768 ? 'row' : 'column',
                flexWrap: equalsOrBiggerThan768 ? 'wrap' : 'no-wrap',
              }}
              paddingTop="10px"
            >
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: equalsOrBiggerThan768 ? '-15px' : '0',
                  paddingRight: equalsOrBiggerThan768 ? '30px' : '0',
                }}
                paddingTop="0"
                minHeight="160px"
                flex
              >
                <Hexagon src={about6} />
                <ContentInput paddingTop="2px">
                  <AboutTitle
                    maxFontSize="16px"
                    textAlign="left"
                    marginTop="5px"
                    color="#fff"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    Protection<LabelPlus>+</LabelPlus> Disinfection&#174;
                  </AboutTitle>
                  {values?.IsIndependentContractorModel ? (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Using EPA approved disinfectants, service providers
                      provide a germ-busting clean that looks great but
                      disinfects and sanitizes, which helps to minimize the
                      spread of illnesses and limits sick-time.
                    </Paragraph>
                  ) : (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Using EPA approved disinfectants, Anago franchisees
                      provide a germ-busting clean that looks great but
                      desinfects and sanitizes, which helps to minimize the
                      spread of illnesses and limits sick-time.
                    </Paragraph>
                  )}
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                minHeight="160px"
                flex
                paddingTop="0"
              >
                <Hexagon src={about4} />
                <ContentInput paddingTop="2px">
                  <AboutTitle
                    maxFontSize="16px"
                    textAlign="left"
                    marginTop="5px"
                    color="#fff"
                  >
                    2-Hour Response Guarantee
                  </AboutTitle>
                  <Paragraph
                    textAlign="justify"
                    style={{
                      fontSize: biggerThan768 ? '14px' : '12px',
                      color: 'white',
                      lineHeight: '18px',
                      marginTop: '5px',
                    }}
                  >
                    Because of our commitment to your custom-tailored cleaning
                    needs, we offer our 2-Hour Response Guarantee following the
                    reporting of any items not completed to your satisfaction.
                  </Paragraph>
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: equalsOrBiggerThan768 ? '-15px' : '0',
                  paddingRight: equalsOrBiggerThan768 ? '30px' : '0',
                }}
                paddingTop="0"
                minHeight="160px"
                flex
              >
                <Hexagon src={about1} />
                <ContentInput paddingTop="2px">
                  <AboutTitle
                    maxFontSize="16px"
                    textAlign="left"
                    marginTop="5px"
                    color="#fff"
                  >
                    Instant Communications
                  </AboutTitle>
                  {values?.IsIndependentContractorModel ? (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Anago provides a direct communication platform to stay in
                      close contact with service providers regarding all
                      services performed, through Anago CleanCom<sup>®</sup>.
                    </Paragraph>
                  ) : (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Anago provides a direct communication platform to stay in
                      close contact with franchise owners regarding all services
                      performed, through Anago CleanCom<sup>®</sup>.
                    </Paragraph>
                  )}
                </ContentInput>
              </ContentInput>

              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                minHeight="160px"
                paddingTop="0"
                color="#fff"
                flex
              >
                <Hexagon src={about3} />
                <ContentInput paddingTop="2px">
                  <AboutTitle
                    maxFontSize="16px"
                    textAlign="left"
                    marginTop="5px"
                    color="#fff"
                  >
                    Insurance & Bonding
                  </AboutTitle>
                  {values?.IsIndependentContractorModel ? (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Services are covered under with insurance for bodily
                      injury, property damage, public liability, and workers
                      compensation (where required).
                      <br />
                      <Anchor
                        target="_blank"
                        href={franchiseValues['InsurancePDF']}
                      >
                        {/* <i>Click here to view our insurance certificate</i> */}
                      </Anchor>
                    </Paragraph>
                  ) : (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Our franchisees’ services are covered under an insurance
                      policy for bodily injury, property damage, public
                      liability, and workers compensation (where required).
                      <br />
                      <Anchor
                        target="_blank"
                        href={franchiseValues['InsurancePDF']}
                      >
                        {/* <i>Click here to view our insurance certificate</i> */}
                      </Anchor>
                    </Paragraph>
                  )}
                </ContentInput>
              </ContentInput>

              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                style={{
                  marginLeft: equalsOrBiggerThan768 ? '-15px' : '0',
                  paddingRight: equalsOrBiggerThan768 ? '30px' : '0',
                }}
                minHeight="160px"
                paddingTop="0"
                flex
              >
                <Hexagon src={about2} />
                <ContentInput paddingTop="2px">
                  <AboutTitle
                    maxFontSize="16px"
                    textAlign="left"
                    marginTop="5px"
                    color="#fff"
                  >
                    The Third Pass
                  </AboutTitle>
                  {values?.IsIndependentContractorModel ? (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Service providers take extra measures with nightly
                      checklists to ensure your facility is properly secured
                      upon completion of duties to give you peace of mind.
                    </Paragraph>
                  ) : (
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      Anago’s franchise owners take extra measures with nightly
                      checklists to ensure your facility is properly secured
                      upon completion of duties to give you peace of mind.
                    </Paragraph>
                  )}
                </ContentInput>
              </ContentInput>
              <ContentInput
                width={equalsOrBiggerThan768 ? '45%' : '100%'}
                minWidth={equalsOrBiggerThan768 ? '45%' : '100%'}
                flex
                minHeight="160px"
                paddingTop="0"
              >
                <Hexagon src={about5} />
                {values?.IsIndependentContractorModel ? (
                  <ContentInput paddingTop="2px">
                    <AboutTitle
                      maxFontSize="16px"
                      textAlign="left"
                      marginTop="5px"
                      color="#fff"
                    >
                      Ownership Concept
                    </AboutTitle>
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      The ownership concept works because of the simple
                      principle that the owner of the business is the supervisor
                      of, and involved in, the cleaning and maintenance of your
                      facility.
                    </Paragraph>
                  </ContentInput>
                ) : (
                  <ContentInput paddingTop="2px">
                    <AboutTitle
                      maxFontSize="16px"
                      textAlign="left"
                      marginTop="5px"
                      color="#fff"
                    >
                      Franchising Concept
                    </AboutTitle>
                    <Paragraph
                      textAlign="justify"
                      style={{
                        fontSize: biggerThan768 ? '14px' : '12px',
                        color: 'white',
                        lineHeight: '18px',
                        marginTop: '5px',
                      }}
                    >
                      The franchising concept works because of the simple
                      principle that the owner of the business is the supervisor
                      of, and participant in, the actual cleaning and
                      maintenance of your facility.
                    </Paragraph>
                  </ContentInput>
                )}
              </ContentInput>
            </ResponsiveLayout>
            <AboutParagraph textAlign="center" width="100%" color="#fff">
              We look forward to working with you in the near future!
            </AboutParagraph>
          </Layout>
        </SubSection>
        {/* {franchiseValues['ProposalOptionalTitle'] && (
          <SubSection paddingTop="30px">
            <ContentInput paddingTop="10px">
              <SectionTitle
                maxFontSize="26px"
                textAlign="left"
                color="#fff"
                dangerouslySetInnerHTML={{
                  __html: franchiseValues['ProposalOptionalTitle'],
                }}
              ></SectionTitle>
            </ContentInput>
            <Layout alignItems="start" width="100%" paddingTop="15px">
              <AboutParagraph
                clear
                dangerouslySetInnerHTML={{
                  __html: franchiseValues['ProposalOptionalParagraph'],
                }}
              ></AboutParagraph>
            </Layout>
          </SubSection>
        )} */}
      </BlueSection>
      <GraySection padding="0 0px 180px 0px">
        <ContentInput
          flex
          justify="center"
          flexDirection="row"
          style={{ flexWrap: 'wrap', paddingBottom: '20px' }}
        >
          <Stamps
            src={stamp1}
            style={{ width: minorThan450 ? '33.333%' : '20%' }}
          />
          <Stamps
            src={stamp2}
            style={{ width: minorThan450 ? '33.333%' : '20%' }}
          />
          <Stamps
            src={stamp3}
            style={{ width: minorThan450 ? '33.333%' : '20%' }}
          />
          <Stamps
            src={stamp4}
            style={{ width: minorThan450 ? '33.333%' : '20%' }}
          />
          <Stamps
            src={stamp5}
            style={{ width: minorThan450 ? '33.333%' : '20%' }}
          />
        </ContentInput>
        {data && data.FlgReference && !isPhysicalContract && (
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <PageReferences
              key="references"
              pageName="references"
              data={data}
              parsedData={parsedData}
              franchiseData={franchiseData}
              selectedValues={values}
              selectedItem={selectedItem}
              isBid
            />
          </div>
        )}
        <div style={{ display: values['IsSS'] ? 'none' : 'block' }}>
          <RoundSubSection
            marginTop="30px"
            width="100%"
            backgroundColor="#fff"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#F7A11D"
              color="#fff"
              padding={'20px 0 20px 0'}
            >
              <SectionTitle maxFontSize="20px" color="#fff">
                Area Specifications
              </SectionTitle>
            </SubSectionHeader>
            <Layout
              alignItems="start"
              width="100%"
              paddingBottom="0px"
              position="relative"
            >
              <SectionParagraph
                style={{
                  fontFamily: 'OpenSans-Regular !important',
                  fontSize: '14px !important',
                }}
                data-cy="area-specification-paragraph"
                fontSize="14px"
                // dangerouslySetInnerHTML={{ __html: values['ProposalAreaSpecs'] }}
              >
                We are pleased to submit this Proposal to procure Janitorial
                Services for the following location:
              </SectionParagraph>
              <SectionParagraph
                fontSize="14px"
                data-cy="area-specification-company-data"
              >
                {values['BusinessName']}
                <br />
                {values['Address'] + ' ' + values['Suite']}
                <br />
                {values['City']}, {values['State']}, {values['Zip']} <br />
              </SectionParagraph>

              <SectionParagraph>
                <H2BoldItalic maxFontSize="22px">Service Areas</H2BoldItalic>
              </SectionParagraph>

              <List
                justifyContent="flex-start"
                style={{
                  width: equalsOrBiggerThan768
                    ? serviceAreas.length > 26
                      ? '100%'
                      : '65%'
                    : '100%',
                  maxHeight: equalsOrBiggerThan768
                    ? Math.round(
                        serviceAreas.length /
                          (serviceAreas.length > 26 ? 3 : 2) +
                          0.45
                      ) * 35
                    : 'none',
                  flexDirection: equalsOrBiggerThan768 ? 'column' : 'row',
                }}
              >
                {serviceAreas.map((item, index) => (
                  <ListItem
                    data-cy={`area-specification-list-item-${index + 1}`}
                    style={{
                      padding: '5px 0 5px 0',
                      width: equalsOrBiggerThan768
                        ? serviceAreas.length > 26
                          ? '33.333333%'
                          : '50%'
                        : '100%',
                    }}
                    prefixColor="#F7A11D"
                    // prefix={`${(index + 10).toString(36).toUpperCase()}.`}
                    prefix={`${index + 1}.`}
                    minWidth="175px"
                  >
                    {item}
                  </ListItem>
                ))}
              </List>

              <SectionParagraph
                fontSize="14px"
                style={{
                  width: equalsOrBiggerThan768
                    ? serviceAreas.length > 26
                      ? 'auto'
                      : '65%'
                    : 'auto',
                }}
                paddingBottom={equalsOrBiggerThan768 ? '30px' : '0'}
              >
                The specifications and the terms as set forth describe the
                Services to be performed, frequency, and conditions of the
                Agreement. The Client requests that products, equipment, and
                materials used are those that are recommended by{' '}
                {franchiseValues['AnagoOfCityName']}.
              </SectionParagraph>
              {serviceAreas.length <= 26 && (
                <PeoplePicture
                  src={aboutAnagoWomanPic}
                  style={{
                    margin: 'auto',
                    marginTop: '20px',
                    position: equalsOrBiggerThan768 ? 'absolute' : 'relative',
                    right: equalsOrBiggerThan768 ? '35px' : '0',
                    bottom: 0,
                  }}
                />
              )}
            </Layout>
          </RoundSubSection>

          <RoundSubSection
            marginTop="30px"
            width="100%"
            backgroundColor="#fff"
            maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
          >
            <SubSectionHeader
              alignItems={biggerThan768 ? 'start' : 'center'}
              backgroundColor="#216eab"
              color="#fff"
              padding={'20px 0 20px 0'}
            >
              <SectionTitle maxFontSize="20px" color="#fff">
                Cleaning Specifications
              </SectionTitle>
            </SubSectionHeader>
            <Layout width="100%" margin="0">
              <Layout margin="15px" marginRight="17px">
                {values['Frequencies'] &&
                  values['Frequencies'].length > 0 &&
                  values['Frequencies'].filter((i) => !i['IsNote']).length >
                    0 &&
                  values['Frequencies']
                    .filter((i) => !i['IsNote'] && !i['IsProtection'])
                    .map((item) => {
                      if (
                        item['CleaningAreas'] &&
                        item['CleaningAreas'].length > 0 &&
                        item['CleaningAreas'].reduce((acc, i) => {
                          if (
                            i['Specifications'] &&
                            i['Specifications'].length > 0
                          ) {
                            acc.push(i['Specifications']);
                          }
                          return acc;
                        }, []).length > 0
                      ) {
                        return (
                          <GenericAccordion
                            headerStyle={{
                              backgroundColor: 'transparent',
                              borderRadius: '10px',
                              padding: '10px',
                            }}
                            containerStyle={{
                              marginTop: '15px',
                              borderRadius: '10px',
                              border: 'solid 2px gray',
                            }}
                            contentStyle={{
                              paddingRight: '30px',
                            }}
                            renderHeader={() => (
                              <AccordionHeader
                                maxFontSize="22px"
                                width="100%"
                                textAlign={'center'}
                                textTransform="capitalize"
                              >
                                {/* {item['Title'] === 'Day porter cleaning' ? (<span style="text-tra") } */}
                                {item['Title'] || item['KeyString']}
                              </AccordionHeader>
                            )}
                          >
                            <Layout>
                              {item['CleaningAreas'].map((ca) => (
                                <Layout width="100%">
                                  <AccordionHeader
                                    paddingTop="5px"
                                    maxFontSize="18px"
                                    textAlign={'left'}
                                    paddingLeft={'20px'}
                                  >
                                    {ca['Title']}
                                    {ca['Subtitle'] && <br />}
                                    {ca['Subtitle'] && (
                                      <span
                                        style={{
                                          fontFamily: 'OpenSans-Regular',
                                          fontSize: '15px',
                                          fontWeight: 'normal',
                                          textDecoration: 'normal',
                                        }}
                                      >
                                        {ca['Subtitle']}
                                      </span>
                                    )}
                                  </AccordionHeader>
                                  <List
                                    fontSize={biggerThan768 ? '14px' : '12px'}
                                    listSpacing="10px"
                                  >
                                    {ca['Specifications'].map((item, index) => (
                                      <ListItem
                                        prefix={`${index + 1}.`}
                                        width="100%"
                                      >
                                        {item['Title']}
                                      </ListItem>
                                    ))}
                                  </List>
                                </Layout>
                              ))}
                            </Layout>
                          </GenericAccordion>
                        );
                      }
                    })}
                {values['Frequencies'] &&
                  values['Frequencies'].length > 0 &&
                  values['Frequencies'].filter((i) => i['IsNote']).length > 0 &&
                  values['Frequencies']
                    .filter(
                      (i) =>
                        i['IsNote'] &&
                        i['CleaningAreas'].filter((v) => !!v['Title']).length >
                          0
                    )
                    .map((item) => {
                      return (
                        <GenericAccordion
                          headerStyle={{
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            padding: '10px',
                          }}
                          containerStyle={{
                            marginTop: '15px',
                            borderRadius: '10px',
                            border: 'solid 2px gray',
                          }}
                          renderHeader={() => (
                            <AccordionHeader
                              maxFontSize="22px"
                              width="100%"
                              textAlign={'center'}
                              textTransform="capitalize"
                            >
                              {/* {item['Title'] === 'Day porter cleaning' ? (<span style="text-tra") } */}
                              Special Notes
                            </AccordionHeader>
                          )}
                        >
                          <Layout>
                            {item['CleaningAreas'].map((ca, idx) => (
                              <Layout width="100%">
                                <List
                                  fontSize={biggerThan768 ? '14px' : '12px'}
                                  listSpacing="10px"
                                  style={{
                                    paddingRight: '30px',
                                    marginBottom:
                                      item['CleaningAreas'].length - 1 === idx
                                        ? '15px'
                                        : 0,
                                    marginTop: idx === 0 ? '15px' : 0,
                                  }}
                                >
                                  <ListItem
                                    prefix={''}
                                    width="100%"
                                    style={{ whiteSpace: 'pre-line' }}
                                  >
                                    {ca['Title']}
                                  </ListItem>
                                </List>
                              </Layout>
                            ))}
                          </Layout>
                        </GenericAccordion>
                      );
                    })}

                <Paragraph
                  textAlign="center"
                  fontSize={equalsOrBiggerThan768 ? '14px' : '12px'}
                  lineHeight="15px"
                  marginTop="10px"
                >
                  <strong>
                    <i
                      dangerouslySetInnerHTML={{
                        __html: getCleaningSpecificationsFooterText(),
                      }}
                    />
                  </strong>
                </Paragraph>
              </Layout>
            </Layout>
          </RoundSubSection>
          {!hasProtection && (
            <RoundSubSection
              marginTop="30px"
              width="100%"
              position="relative"
              paddingBottom="20px"
              backgroundColor="white"
              maxWidth={equalsOrBiggerThan768 ? '900px' : 'auto'}
            >
              <SubSectionHeader
                backgroundColor="#F7A11D"
                color="#fff"
                padding={'20px 0 20px 0'}
                alignItems={biggerThan768 ? 'start' : 'center'}
              >
                <SectionTitle maxFontSize="20px" color="#fff">
                  Monthly Service Quote(s)
                </SectionTitle>
              </SubSectionHeader>
              <Layout
                alignItems="start"
                color="#fff"
                paddingLeft="20px"
                paddingTop="20px"
                marginRight={equalsOrBiggerThan768 ? '10px' : 'auto'}
                paddingRight={equalsOrBiggerThan768 ? '0' : '20px'}
                paddingBottom={'0'}
                position="relative"
                backgroundColor="white"
              >
                <Paragraph
                  fontSize="14px"
                  margin="0px"
                  paddingBottom={equalsOrBiggerThan768 ? '15px' : '0'}
                >
                  Please select your monthly service plan. You will have a
                  chance to review and confirm your selection before submitting
                  your agreement.
                </Paragraph>
                <RadioButton
                  value={selectedFrequency}
                  onChange={(name, value) => {
                    // updateData("SelectedFrequency", value);
                    setSelectedFrequency(value);
                    setIsDirty(true);
                  }}
                  containerStyle={{
                    width: '100%',
                  }}
                  contentStyle={{
                    flexWrap: 'wrap',
                    paddingTop: equalsOrBiggerThan768 ? '25px' : '10px',
                    width: '100%',
                  }}
                  itemStyle={{
                    width: '100%',
                    marginTop: '15px',
                    paddingRight: '0',
                    alignItems: equalsOrBiggerThan768 ? 'center' : 'flex-start',
                  }}
                  radioStyle={{
                    alignSelf: equalsOrBiggerThan768 ? 'center' : 'flex-start',
                    marginTop:
                      equalsOrBiggerThan768 && isSmartCleanSet ? '35px' : '0',
                  }}
                  name="frequency"
                  options={frequenciesOptions()}
                  optionLabel="render"
                  optionValue="value"
                />
                {franchiseValues.SalesTax && equalsOrBiggerThan768 && (
                  <Paragraph
                    width={'100%'}
                    textAlign={'right'}
                    color="#000"
                    style={{
                      marginLeft: '-10px',
                    }}
                  >
                    <i>
                      <small className="tm">†</small> Plus Applicable Sales Tax.
                    </i>
                  </Paragraph>
                )}
              </Layout>
            </RoundSubSection>
          )}
          <TermsAndConditions
            values={values}
            franchiseValues={franchiseValues}
            isTermsChecked={isTermsChecked}
            setIsTermsChecked={setIsTermsChecked}
            pageChanged={selectedItem}
          />
        </div>
      </GraySection>
    </Layout>
  );
};

export default React.forwardRef(Page1);
