import React from 'react';
import classNames from 'classnames';
import { SVGIcon } from 'core/components';

interface ButtonProps {
  onClick?: () => void;
  icon?: any;
  disabled?: boolean;
  isActive?: boolean;
}

export const BaseButton: React.FC<ButtonProps> = ({
  onClick,
  disabled,
  children,
  isActive,
  icon,
}) => {
  return (
    <button onClick={onClick} className={classNames("button-button", { active: isActive })} disabled={disabled}>
      {icon && <SVGIcon size="20px" icon={icon} />}
      {children}
    </button>
  );
};

export default BaseButton;
