import React, { useState } from 'react';
import {
  Wrapper,
  Header,
  Heading,
  Text,
  HeaderTop,
  HeaderBottom,
  Content,
  Row,
  Cell,
  CellHeader,
  Info,
  Holder,
  RowHolder,
  Footer,
  Button,
  ButtonContent,
  ButtonLabel,
  IconHolder,
  TooltipContent,
  InputText,
  InputLabel,
  CheckboxHolder,
  QuoteOptionWrapper,
  ProtectionMonthlyServiceQuoteOptionsWrapper,
  QuoteTitleWrapper,
  QuoteOptionRow,
  QuoteOptionBox,
  ItemizedListWrapper,
  ItemizedItem,
  ItemizedItemHeader,
  ItemizedItemRow,
  ProtectedOptionsWrapper,
  ProtectionGuidanceLabel,
  ProtectionRow,
  ProtectionItemWrapper,
  QuoteOptionTotalWrapper,
  QuoteOptiontotalLabel,
  QuoteOptionValue,
  ProtectionPlans,
  ProtectionPlanWrapper,
  ProtectionPlanValue,
  QuoteOptionDisinfection,
} from './styles';

import {
  H2BoldItalic,
  H3Bold,
  H3SemiBoldItalic,
  List,
  ListItem,
  SectionTitle,
  IntroductionTitle,
  AboutTitle,
  AccordionHeader,
  SignatureName,
  SignatureTitle,
  H3SemiBold,
  H3BoldItalic,
  LogoHolder,
  LabelPlus,
  HeaderBase,
} from 'modules/BidInfoForm/styles';

import {
  Layout,
  ResponsiveLayout,
  PricingBoxes,
  ResponsiveBoxes,
  BlueSection,
  GraySection,
  WhiteSection,
  RoundSubSection,
  SubSectionHeader,
  SubSection,
  HiddenLayout,
} from 'modules/BidInfoForm/components/layout';

import { Paragraph } from 'modules/BidInfoForm/components/atoms';

import { ContentInput, GenericAccordion, Checkbox } from 'core/components';

import { convertStringToNumber, formatPrice } from 'core/utils';
import styled from 'styled-components';

import { Tooltip, RadioButton } from 'core/components';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAlphabetLetter } from '../../utils/objectUtils';

import { DisclaimerParagraph } from 'modules/BidInfoForm/styles';

export const DeclineMonthlyServiceOption = styled.div`
  background-color: #e6e6e6;
  border-radius: 10px;
  width: auto;
  padding: 20px;
  padding-left: 35px;
`;

export const MonthlyServiceQuoteOption = (
  item,
  hasBeta,
  optionBeta,
  packageIncluded,
  calculatedTotal,
  readyOnly,
  color,
  selected,
  unselected,
  selectedProtection,
  setSelectedProtection,
  plusApplicableTax,
  biggerThan768 = false,
  hasDesinfection = false,
  selectFrequency = () => {},
  isHtml,
  hasContainer = true
) => {
  const [quoteTotal, setQuoteTotal] = React.useState(0);
  const [selectedProtectionQuoteValue, setSelectedProtectionQuoteValue] =
    React.useState(0);

  React.useEffect(() => {
    setQuoteTotal(
      convertStringToNumber(calculatedTotal) +
        convertStringToNumber(selectedProtectionQuoteValue || 0)
    );
  }, [selectedProtectionQuoteValue, calculatedTotal]);

  React.useEffect(() => {
    const v = item.protectionOptions.filter(
      (i) => i.index == selectedProtection
    )[0];
    if (selectedProtection >= 0) {
      if (v) {
        setSelectedProtectionQuoteValue(v.value);
      }
    } else {
      setSelectedProtectionQuoteValue(0);
    }
  }, [selectedProtection]);

  return (
    <QuoteOptionWrapper
      unselected={unselected}
      style={{ padding: hasContainer ? '20px' : 0 }}
    >
      <QuoteTitleWrapper
        style={{
          color: color,
        }}
      >
        {item.title}
      </QuoteTitleWrapper>
      <QuoteOptionRow>
        <QuoteOptionBox
          style={{
            color: color,
          }}
        >
          Frequency
          <QuoteOptionValue
            style={
              item.hasDisinfection
                ? { paddingTop: '15px', paddingBottom: '25px' }
                : {}
            }
          >
            {item.frequency}
            {item.hasDisinfection && (
              <QuoteOptionDisinfection>
                & Protection+ Disinfect
              </QuoteOptionDisinfection>
            )}
          </QuoteOptionValue>
        </QuoteOptionBox>
        <QuoteOptionBox style={{ width: '55%', color: color }}>
          Days Serviced
          <QuoteOptionValue>{item.daysServiced}</QuoteOptionValue>
        </QuoteOptionBox>
        <QuoteOptionBox
          style={{
            color: color,
          }}
        >
          Time Window
          <QuoteOptionValue>{item.timeWindow || <>&nbsp;</>}</QuoteOptionValue>
        </QuoteOptionBox>
      </QuoteOptionRow>

      {item.services && (
        <ItemizedListWrapper>
          <ItemizedItemRow>
            <ItemizedItemHeader
              style={{
                color: color,
              }}
            >
              Itemized list of Included Service
            </ItemizedItemHeader>
            <ItemizedItemHeader style={{ width: '20%', color: color }}>
              Price
            </ItemizedItemHeader>
          </ItemizedItemRow>

          {item.services
            .filter((item) => Object.keys(item).length > 0)
            .map(({ service, value }) => (
              <ItemizedItemRow>
                <ItemizedItem
                  style={{
                    justifyContent: 'flex-start',
                    paddingLeft: '2.5%',
                  }}
                >
                  {service}
                </ItemizedItem>
                <ItemizedItem style={{ width: '20%' }}>{value}</ItemizedItem>
              </ItemizedItemRow>
            ))}
        </ItemizedListWrapper>
      )}

      {item.protectionOptions && (
        <ProtectedOptionsWrapper>
          {item.protectionOptions.length > 0 && (
            <ProtectionGuidanceLabel
              style={{
                color: color,
              }}
            >
              {`Protection Disinfection Package Option ${
                !readyOnly && !packageIncluded ? '(Choose One)' : ''
              }`}
            </ProtectionGuidanceLabel>
          )}
          <RadioButton
            value={selectedProtection}
            onChange={(name, value) => {
              if (!readyOnly) {
                setSelectedProtection(value);
                if (!selected) {
                  selectFrequency();
                }
              }
              // setIsDirty(true);
            }}
            containerStyle={{
              width: '100%',
            }}
            contentStyle={{
              flexWrap: 'wrap',
              paddingTop: '20px',
              width: '100%',
            }}
            itemStyle={{
              width: '100%',
              paddingRight: '0',
              alignItems: 'center',
              position: 'relative',
              marginBottom: '20px',
            }}
            radioStyle={{
              alignSelf: 'flex-start',
              zIndex: 99,
              marginLeft: '20px',
              position: 'absolute',
              marginTop: '12px',
              display: packageIncluded || readyOnly ? 'none' : 'flex',
            }}
            name="frequency"
            options={item.protectionOptions.map((option, index) => ({
              value: option.index,
              render: (
                <ProtectionRow
                  style={{
                    width: '100%',
                  }}
                >
                  <ProtectionItemWrapper>
                    {selectedProtection != null &&
                      selectedProtection != undefined &&
                      selectedProtection != option.index && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 1,
                            backgroundColor: 'white',
                            opacity: 0.4,
                            width: '100%',
                            height: '100%',
                            marginTop: '-15px',
                            borderRadius: '10px',
                          }}
                        ></div>
                      )}
                    <ProtectionPlans>
                      <label>
                        {option.index < 0
                          ? 'I decline PROTECTION+ SERVICES'
                          : `PROTECTION+ PACKAGE ${getAlphabetLetter(
                              option.index || 0
                            )}`}
                      </label>
                      {option.index >= 0 &&
                        option.plans.map(({ plan, frequency, color }) => (
                          <ProtectionPlanWrapper>
                            <ProtectionPlanValue
                              style={{ backgroundColor: color }}
                            >
                              {plan}
                            </ProtectionPlanValue>
                            <ProtectionPlanValue
                              style={{ backgroundColor: color }}
                            >
                              {frequency}
                            </ProtectionPlanValue>
                          </ProtectionPlanWrapper>
                        ))}
                    </ProtectionPlans>
                  </ProtectionItemWrapper>
                  <ProtectionItemWrapper
                    style={{
                      width: '20%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontFamily: 'OpenSans-SemiBold',
                    }}
                  >
                    {selectedProtection != null &&
                      selectedProtection != undefined &&
                      selectedProtection != option.index && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 1,
                            backgroundColor: 'white',
                            opacity: 0.4,
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                          }}
                        ></div>
                      )}

                    {packageIncluded ? 'Included' : option.value}
                  </ProtectionItemWrapper>
                </ProtectionRow>
              ),
            }))}
            optionLabel="render"
            optionValue="value"
          />
        </ProtectedOptionsWrapper>
      )}
      <QuoteOptionTotalWrapper>
        <QuoteOptiontotalLabel
          style={{
            color: color,
          }}
        >
          Total Monthly Price
          {plusApplicableTax && (
            <small className="tm" style={{ marginTop: '-10px' }}>
              †
            </small>
          )}
          {hasBeta && !isHtml && (
            <IconHolder>
              <Tooltip
                overlay={
                  <TooltipContent>
                    $5 will automatically be added to any Calculated or Override
                    Bid Price. The client can have this discounted back by
                    selecting Eco-Invoice (ACH Debit) as their payment method.
                  </TooltipContent>
                }
                placement="top"
              >
                <FontAwesomeIcon icon={faInfoCircle} size="1x" />
              </Tooltip>
            </IconHolder>
          )}
        </QuoteOptiontotalLabel>
        <QuoteOptionValue
          style={{
            width: '20%',
            padding: '10px 0',
            fontFamily: 'OpenSans-SemiBold',
            textAlign: 'center',
          }}
        >
          {optionBeta && (
            <div>
              <span style={{ color: '#219729' }}>
                {' '}
                Eco-Invoice<sup>&#8482; {plusApplicableTax && '†'}</sup>
              </span>
              <br />
              <span>
                <del
                  style={{ fontSize: '14px', fontFamily: 'OpenSans-Regular' }}
                >
                  {formatPrice(quoteTotal, true)}
                </del>
              </span>
              <br />
              <span
                style={{
                  paddingLeft: '0',
                  color: '#219729',
                }}
              >
                <strong>{formatPrice(quoteTotal, false)}</strong>
              </span>
            </div>
          )}

          {!optionBeta && formatPrice(quoteTotal, hasBeta)}
        </QuoteOptionValue>
      </QuoteOptionTotalWrapper>
    </QuoteOptionWrapper>
  );
};

export const MonthlyServiceQuoteOptionsMobile = (
  item,
  hasBeta,
  optionBeta,
  packageIncluded,
  calculatedTotal,
  readyOnly,
  color,
  selected,
  unselected,
  selectedProtection,
  setSelectedProtection,
  plusApplicableTax,
  biggerThan768 = false,
  hasDesinfection = false,
  isHtml,
  hasContainer = true
) => {
  const [quoteTotal, setQuoteTotal] = React.useState(0);
  const [selectedProtectionQuoteValue, setSelectedProtectionQuoteValue] =
    React.useState(0);

  React.useEffect(() => {
    if (packageIncluded) {
      setQuoteTotal(calculatedTotal);
    } else {
      setQuoteTotal(
        convertStringToNumber(calculatedTotal) +
          convertStringToNumber(selectedProtectionQuoteValue || 0)
      );
    }
  }, [selectedProtectionQuoteValue, calculatedTotal]);

  React.useEffect(() => {
    const v = item.protectionOptions.filter(
      (i) => i.index == selectedProtection
    )[0];
    if (selectedProtection >= 0) {
      if (v) {
        setSelectedProtectionQuoteValue(v.value);
      }
    } else {
      setSelectedProtectionQuoteValue(0);
    }
  }, [selectedProtection]);

  const bgColor = selected ? '#216eab' : '#efefef';
  const servicesColor = '#515151';

  return (
    <ContentInput padding="0" width="100%">
      <ResponsiveLayout
        padding="10px"
        paddingLeft="20px"
        paddingRight="20px"
        margin="0"
        width="auto"
        style={{
          backgroundColor: bgColor,
          border: !selected ? '1px solid gray' : '1px solid #216eab',
          borderRadius: '5px',
          color: color,
        }}
      >
        <ContentInput paddingTop="0">
          <HeaderBase
            fontSize="15px"
            padding="5px"
            textAlign="left"
            style={{ fontWeight: 'bold' }}
            color={color}
          >
            {item.title}
          </HeaderBase>
          {hasDesinfection && (
            <H3BoldItalic
              color="#F7A11D"
              marginTop="-5px"
              padding="5px"
              textAlign="center"
              style={{ display: 'flex' }}
            >
              & Protection{' '}
              <LabelPlus style={{ paddingLeft: '2px' }}> +</LabelPlus>{' '}
              Disinfection&#174;
            </H3BoldItalic>
          )}
        </ContentInput>
        <hr
          style={{
            width: '100%',
            borderColor: 'white',
            marginTop: '-5px',
          }}
        />
        <ContentInput
          paddingTop="0"
          flex
          flexDirection="row"
          justify="flex-start"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            textAlign={biggerThan768 ? 'center' : 'left'}
          >
            Frequency:
          </H3Bold>
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{ fontFamily: 'OpenSans-Regular' }}
            color={color}
          >
            {item.frequency}
          </H3Bold>
        </ContentInput>
        <ContentInput
          paddingTop="0"
          flex
          flexDirection="row"
          justify="flex-start"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            textAlign={biggerThan768 ? 'center' : 'left'}
          >
            Days Serviced:
          </H3Bold>
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{ fontFamily: 'OpenSans-Regular' }}
            color={color}
          >
            {item.daysServiced}
          </H3Bold>
        </ContentInput>
        <ContentInput
          paddingTop="0"
          flex
          flexDirection="row"
          justify="flex-start"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            textAlign={biggerThan768 ? 'center' : 'left'}
          >
            Time Window:
          </H3Bold>
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{ fontFamily: 'OpenSans-Regular' }}
            color={color}
          >
            {item.timeWindow}
          </H3Bold>
        </ContentInput>
        {item.services &&
          item.services
            .filter((i) => !!i)
            .map((service) => (
              <ContentInput
                flex
                flexDirection="row"
                justify="space-between"
                alignItems="center"
                style={{
                  lineHeight: '16px',
                  backgroundColor: selected ? color : 'transparent',
                  borderRadius: '10px',
                  border: selected ? 'none' : '1px solid gray',
                  padding: '0 5px',
                  marginBottom: '5px',
                }}
              >
                <H3Bold
                  padding="5px"
                  textAlign="left"
                  color={servicesColor}
                  width="auto"
                  textAlign={biggerThan768 ? 'center' : 'left'}
                  style={{
                    fontFamily: 'OpenSans-Regular',
                    position: 'relative',
                    fontSize: '12.5px',
                  }}
                >
                  {service.service}
                </H3Bold>
                <H3Bold
                  fontSize="12.5px"
                  padding="5px"
                  textAlign="left"
                  width="auto"
                  style={{ fontFamily: 'OpenSans-Regular', fontSize: '12.5px' }}
                  color={servicesColor}
                >
                  {service.value}
                </H3Bold>
              </ContentInput>
            ))}

        {item.protectionOptions && (
          <ProtectedOptionsWrapper style={{ fontSize: '14px' }}>
            {item.protectionOptions.length > 0 && (
              <ProtectionGuidanceLabel
                style={{
                  color: color,
                  fontSize: '65%',
                  marginTop: '0px',
                }}
              >
                {`Protection Disinfection Package Option ${
                  !readyOnly && !packageIncluded ? '(Choose One)' : ''
                }`}
              </ProtectionGuidanceLabel>
            )}
            <RadioButton
              value={selectedProtection}
              onChange={(name, value) => {
                if (!readyOnly && selected) {
                  setSelectedProtection(value);
                }
                // setIsDirty(true);
              }}
              containerStyle={{
                width: '100%',
              }}
              contentStyle={{
                flexWrap: 'wrap',
                paddingTop: '20px',
                width: '100%',
                marginBottom: '0px',
              }}
              itemStyle={{
                width: '100%',
                paddingRight: '0',
                alignItems: 'center',
                position: 'relative',
                marginBottom: '10px',
              }}
              radioStyle={{
                alignSelf: 'flex-start',
                zIndex: 99,
                marginLeft: '5px',
                transform: 'scale(0.7)',
                position: 'absolute',
                marginTop: '12px',
                display: packageIncluded || readyOnly ? 'none' : 'flex',
              }}
              name="frequency"
              options={item.protectionOptions.map((option, index) => ({
                value: option.index,
                render: (
                  <ProtectionRow
                    style={{
                      width: '100%',
                    }}
                  >
                    <ProtectionItemWrapper style={{ width: '100%' }}>
                      {selectedProtection != null &&
                        selectedProtection != undefined &&
                        selectedProtection != option.index && (
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 1,
                              backgroundColor: 'white',
                              opacity: 0.4,
                              width: '100%',
                              height: '100%',
                              marginTop: '-15px',
                              borderRadius: '10px',
                            }}
                          ></div>
                        )}

                      <ProtectionPlans
                        style={{ marginLeft: '40px', marginRight: '10px' }}
                      >
                        <label style={{ fontSize: '100%' }}>
                          {option.index < 0
                            ? 'I decline PROTECTION+ SERVICES'
                            : `PROTECTION+ PACKAGE ${getAlphabetLetter(
                                option.index || 0
                              )}`}
                        </label>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '5px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '70%',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            {option.index >= 0 ? (
                              option.plans.map(
                                ({ plan, frequency, color }, idx) => (
                                  <ProtectionPlanWrapper
                                    style={{
                                      marginTop: idx == 0 ? '0' : '5px',
                                    }}
                                  >
                                    <ProtectionPlanValue
                                      style={{
                                        backgroundColor: color,
                                        fontSize: '100%',
                                        padding: '0 5px',
                                        width: '100%',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {plan} / {frequency}
                                    </ProtectionPlanValue>
                                  </ProtectionPlanWrapper>
                                )
                              )
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </div>
                          <span
                            style={{
                              fontSize: '12.5px',
                              color: servicesColor,
                              textAlign: 'right',
                              minWidth: '65px',
                            }}
                          >
                            {packageIncluded ? 'Included' : option.value}
                          </span>
                        </div>
                      </ProtectionPlans>
                    </ProtectionItemWrapper>
                  </ProtectionRow>
                ),
              }))}
              optionLabel="render"
              optionValue="value"
            />
          </ProtectedOptionsWrapper>
        )}
        <ContentInput
          paddingTop="0px"
          flex
          flexDirection="row"
          justify="space-between"
          style={{ lineHeight: '16px' }}
        >
          <H3Bold
            maxFontSize="14px"
            padding="5px"
            textAlign="left"
            color={color}
            width="auto"
            textAlign={biggerThan768 ? 'center' : 'left'}
            style={{ position: 'relative' }}
          >
            Total Monthly Price:
            {plusApplicableTax && (
              <sup style={{ position: 'absolute', top: '-5px' }}>†</sup>
            )}
          </H3Bold>
          <HeaderBase
            fontSize="15px"
            padding="5px"
            textAlign="left"
            width="auto"
            style={{
              fontFamily: 'OpenSans-Bold',
              textDecoration: optionBeta ? 'line-through' : 'none',
            }}
            color={color}
          >
            {formatPrice(quoteTotal, hasBeta)}
          </HeaderBase>
        </ContentInput>

        {optionBeta && (
          <ContentInput
            paddingTop="0px"
            flex
            flexDirection="row"
            justify="space-between"
            style={{ lineHeight: '16px' }}
          >
            <H3Bold
              maxFontSize="14px"
              padding="5px"
              textAlign="left"
              color="white"
              width="auto"
              textAlign={biggerThan768 ? 'center' : 'left'}
              style={{ position: 'relative' }}
            >
              <span style={{ color: '#98ff98' }}>
                {' '}
                Eco-Invoice
                <sup
                  style={{
                    position: 'absolute',
                    top: '-5px',
                  }}
                >
                  &#8482;
                </sup>
                {plusApplicableTax && (
                  <sup
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-16px',
                    }}
                  >
                    †
                  </sup>
                )}
              </span>
            </H3Bold>
            <HeaderBase
              fontSize="15px"
              padding="5px"
              textAlign="left"
              width="auto"
              style={{
                fontFamily: 'OpenSans-Bold',
              }}
              color="white"
            >
              <span
                style={{
                  paddingLeft: '0',
                  color: '#98ff98',
                }}
              >
                <strong>{formatPrice(quoteTotal)}</strong>
              </span>
            </HeaderBase>
          </ContentInput>
        )}
      </ResponsiveLayout>
    </ContentInput>
  );
};

export const MonthlyServiceQuoteOptions = ({
  hasBeta,
  items,
  packageIncluded,
  style = {},
  containerStyle = {},
  calculatedTotal,
  readyOnly = false,
  color,
  selected,
  unselected,
  selectedProtection,
  setSelectedProtection,
  optionBeta,
  plusApplicableTax,
  hasContainer = true,
  selectFrequency = () => {},
  biggerThan768 = true,
  hasDesinfection = false,
  isHtml,
}) => {
  const RenderComponent = biggerThan768
    ? MonthlyServiceQuoteOption
    : MonthlyServiceQuoteOptionsMobile;
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...containerStyle,
      }}
    >
      <ProtectionMonthlyServiceQuoteOptionsWrapper style={style}>
        {unselected && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              backgroundColor: 'white',
              opacity: 0.4,
              width: '100%',
              height: '100%',
            }}
          ></div>
        )}
        {items &&
          items.map((item) => {
            return RenderComponent(
              item,
              hasBeta,
              optionBeta,
              packageIncluded,
              calculatedTotal,
              readyOnly,
              color,
              selected,
              unselected,
              selectedProtection,
              setSelectedProtection,
              plusApplicableTax,
              biggerThan768,
              hasDesinfection,
              selectFrequency,
              isHtml,
              hasContainer
            );
          })}
      </ProtectionMonthlyServiceQuoteOptionsWrapper>
      {plusApplicableTax && (
        <DisclaimerParagraph
          italic
          paddingTop="15px"
          paddingBottom="0px"
          marginTop="0px"
          marginBottom="0px"
          width={'100%'}
          style={{
            fontSize: '14px',
            paddingTop: hasContainer ? '2px' : '22px',
            textAlign: 'right',
          }}
        >
          <i style={{}}>
            <small className="tm">†</small> Plus Applicable Sales Tax.
          </i>
        </DisclaimerParagraph>
      )}
    </div>
  );
};
