import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// redux
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import { getFranchiseById } from 'modules/BidInfoForm/services/bidInfoService';

// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
// components
import { InspectionsModal, NotesModal, LoadingModal } from 'core/components';

import ActionAlertModal from 'core/components/ActionAlertModal';

import { getQueryString } from 'core/utils';

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps;

const InspectionMenuScreen: React.FC<IProps> = ({
  location,
  getInspectionsRequest,
  history,
  showNotesModal,
  showWarningModal,
  successOnArchive,
  error,
  setIsSent,
  setIsNotSent,
  setIsTemplate,
  setIsCityTemplate,
  calculateRatesRequest,
  archiveFormClose,
  doneAction,
  setWarningModalState,
}) => {
  const currentUrl = location.search;
  const userId = getQueryString(currentUrl, 'userId');
  const franchiseId = getQueryString(currentUrl, 'FranchiseId');
  const [franchiseData, setFranchiseData] = useState([]);

  useEffect(() => {
    if (userId) {
      getInspectionsRequest(userId);
    }
  }, [location.search]);

  useEffect(() => {
    if (successOnArchive) {
      getInspectionsRequest(userId);
      setWarningModalState(
        true,
        'Form archived successfully!',
        null,
        closeWarningModal
      );
    }
    if (error) {
      setWarningModalState(
        true,
        'There was an error while performing a request to the server'
      );
    }
  }, [successOnArchive, error]);

  useEffect(() => {
    if (franchiseId) {
      getFranchiseById(franchiseId).then(({ data }) => {
        setFranchiseData(data);
      });
    }
  }, [franchiseId]);

  const handleRefresh = () => {
    getInspectionsRequest(userId);
  };

  const navigateToForm = (
    id: string,
    isSent: boolean,
    isTemplateURL: boolean,
    isCityTemplateURL: boolean
  ) => {
    if (isSent) {
      setIsSent(isSent);
    } else {
      setIsNotSent(isSent);
    }

    setIsTemplate(isTemplateURL);
    setIsCityTemplate(isCityTemplateURL);

    history.push({
      pathname: '/inspection-report-form',
      search: `?userId=${userId}&id=${id}&FranchiseId=${franchiseId}&Owner=Anago&isSent=${isSent}&isTemplate=${isTemplateURL}&isCityTemplate=${isCityTemplateURL}&v=${Math.random()
        .toString(36)
        .substring(7)}`,
    });

    // calculateRatesRequest();

    doneAction && doneAction();
    window.location.reload();
  };

  const closeWarningModal = () => {
    setWarningModalState(false, '');
    archiveFormClose();
  };

  return (
    <>
      <InspectionsModal
        navigateToForm={navigateToForm}
        doneAction={doneAction}
        franchiseName={franchiseData?.AnagoOfCityName}
        onRefresh={handleRefresh}
      />
      {showNotesModal && <NotesModal showCancel isFromMenu />}
      {showWarningModal.status && (
        <ActionAlertModal
          message={showWarningModal.message}
          onConfirm={closeWarningModal}
          isVisible={showWarningModal.status}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const WithRouter = withRouter(InspectionMenuScreen);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(WithRouter);

export { Connected as InspectionMenuScreen };
