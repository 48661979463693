import {
  IBidRoomInventoryItem,
  IBidRestroomFixtureItem,
  IBidProductionRatesItem,
  IBidDayPorter,
  IBidDailyManHours,
  IBidAccountInfo,
  IBidInformation,
  IBidSupplies,
  IBidAdditionalQuoteItem,
} from '../interfaces';

import { convertStringToNumber } from 'core/utils';
import { IBidProtectionPackage } from '../../shared/bidInterfaces';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

export { default as simplifyConsecutiveDays } from './simplifyConsecutiveDays';

export const parseStringToNumber = (value: string | number) =>
  parseFloat(`${value || 0}`.replace(/[,]/g, ''));

export const calculateLxW = (length: string, width: string) => {
  const parsedData = {
    length: parseFloat(length ? length.replace(',', '') : '0'),
    width: parseFloat(width ? width.replace(',', '') : '0'),
  };

  return parseFloat(
    mathRoundToFixed1(parsedData.length * parsedData.width).toString()
  );
};

export const calculateFloorTypeTotals = (
  inventory: Array<IBidRoomInventoryItem>
) => {
  if (!inventory || !inventory.length) {
    return [];
  }

  let result: any = {};
  inventory
    .filter(inventoryFilterFunction)
    .forEach((item: IBidRoomInventoryItem, index: any) => {
      if (item.floorType) {
        if (result[item.floorType]) {
          result[item.floorType].totalSqft += parseStringToNumber(
            item.totalSqft
          );
          return result;
        }
        result = {
          ...result,
          [item.floorType]: {
            totalSqft: parseStringToNumber(item.totalSqft),
            row: index + 1,
          },
        };
      }
    });

  return Object.keys(result).map((key: any) => ({
    floorType: key,
    totalSqft: result[key].totalSqft,
    rowNumber: result[key].row,
  }));
};

export const calculateRestroomFixture = (
  restroomFixtures: Array<IBidRestroomFixtureItem>,
  inventory: Array<IBidRoomInventoryItem>
) => {
  if (!inventory || !inventory.length) {
    return [];
  }
  let fixtures: any = {};
  const groupsByInventory: Array<string> = [];

  inventory
    .filter(inventoryFilterFunction)
    .forEach((item: IBidRoomInventoryItem) => {
      if (!groupsByInventory.includes(item.prodGroup)) {
        groupsByInventory.push(item.prodGroup);
      }
    });

  if (restroomFixtures && restroomFixtures.length) {
    restroomFixtures.forEach((item: IBidRestroomFixtureItem) => {
      if (groupsByInventory.includes(item.group)) {
        if (fixtures[item.group]) {
          fixtures[item.group] = {
            ...fixtures[item.group],
            ...item,
            restroomFixtures: 0,
          };
          return;
        }
        fixtures = {
          ...fixtures,
          [item.group]: { ...item, restroomFixtures: 0 },
        };
      }
    });
  }

  inventory
    .filter(inventoryFilterFunction)
    .forEach((item: IBidRoomInventoryItem) => {
      if (item.prodGroup.includes('RR')) {
        if (fixtures[item.prodGroup]) {
          fixtures[item.prodGroup].restroomFixtures += item.fixtures;
          return;
        }
        fixtures[item.prodGroup] = { restroomFixtures: item.fixtures };
      }
    });

  return Object.keys(fixtures).map((key: string) =>
    calculateManHoursRestroom({
      group: key,
      restroomFixtures: fixtures[key].restroomFixtures,
      fixtureName: fixtures[key].fixtureName || '',
      minutesFixture: fixtures[key].minutesFixture || 0,
      totalRRManHr: fixtures[key].totalRRManHr || 0,
    })
  );
};

export const calculateManHoursRestroom = (
  restroomItem: IBidRestroomFixtureItem
) => {
  if (!restroomItem) {
    return {};
  }

  return {
    ...restroomItem,
    totalRRManHr:
      !restroomItem.minutesFixture || !restroomItem.restroomFixtures
        ? 0
        : parseFloat(
            (
              (restroomItem.minutesFixture * restroomItem.restroomFixtures) /
              60
            )?.toFixed?.(1)
          ),
  };
};

export const calculateProductionRates = (
  productionRates: Array<IBidProductionRatesItem>,
  inventory: Array<IBidRoomInventoryItem>
) => {
  if (!inventory || !inventory.length) {
    return [];
  }
  let rates: any = {};
  const groupsByInventory: Array<string> = [];

  inventory
    .filter(inventoryFilterFunction)
    .forEach((item: IBidRoomInventoryItem) => {
      if (!groupsByInventory.includes(item.prodGroup)) {
        groupsByInventory.push(item.prodGroup);
      }
    });

  if (productionRates && productionRates.length) {
    productionRates.forEach((item: IBidProductionRatesItem) => {
      if (groupsByInventory.includes(item.group)) {
        if (rates[item.group]) {
          rates[item.group] = {
            ...rates[item.group],
            ...item,
            sqft: 0,
          };
          return;
        }
        rates = {
          ...rates,
          [item.group]: { ...item, sqft: 0 },
        };
      }
    });
  }

  inventory
    .filter(inventoryFilterFunction)
    .forEach((item: IBidRoomInventoryItem) => {
      if (!item.prodGroup.includes('RR')) {
        if (rates[item.prodGroup]) {
          rates[item.prodGroup].sqft += parseStringToNumber(item.totalSqft);
          return;
        }
        rates[item.prodGroup] = {
          sqft: parseStringToNumber(item.totalSqft),
        };
      }
    });

  return Object.keys(rates).map((key: string) => ({
    group: key,
    sqft: rates[key].sqft,
    productionName: rates[key].productionName || '',
    rate: rates[key].rate || null,
    secondRate: rates[key].secondRate || null,
  }));
};

export const calculateDailyManInventory = (
  inventory: Array<IBidRoomInventoryItem>
) => {
  if (!inventory || !inventory.length) {
    return {};
  }
  let dailyManTotals: any = {
    sqftRestroom: undefined,
    sqftOffice: undefined,
    sqftTotal: undefined,
  };

  inventory
    .filter(inventoryFilterFunction)
    .forEach((item: IBidRoomInventoryItem) => {
      if (item.prodGroup.includes('RR')) {
        dailyManTotals = {
          ...dailyManTotals,
          sqftRestroom:
            (dailyManTotals.sqftRestroom || 0) +
            parseStringToNumber(item.totalSqft),
          sqftTotal:
            (dailyManTotals.sqftTotal || 0) +
            parseStringToNumber(item.totalSqft),
        };
        return dailyManTotals;
      }
      dailyManTotals = {
        ...dailyManTotals,
        sqftOffice:
          (dailyManTotals.sqftOffice || 0) +
          parseStringToNumber(item.totalSqft),
        sqftTotal:
          (dailyManTotals.sqftTotal || 0) + parseStringToNumber(item.totalSqft),
      };
    });

  return dailyManTotals;
};

export const calculateDailyManProduction = (
  productionRates: Array<IBidProductionRatesItem>
) => {
  let dailyManTotals: any = {
    officeManHrs: undefined,
    secondOfficeManHrs: undefined,
  };

  productionRates.forEach((item: IBidProductionRatesItem) => {
    dailyManTotals = {
      officeManHrs:
        (dailyManTotals.officeManHrs || 0) +
        (item.sqft
          ? parseStringToNumber(item.rate) === 0
            ? 0
            : parseStringToNumber(item.sqft) /
              (parseStringToNumber(item.rate) || 1)
          : 0),
      secondOfficeManHrs:
        (dailyManTotals.secondOfficeManHrs || 0) +
        (item.sqft
          ? parseStringToNumber(item.secondRate) === 0
            ? 0
            : parseStringToNumber(item.sqft) /
              (parseStringToNumber(item.secondRate) || 1)
          : 0),
    };
  });
  return dailyManTotals;
};

export const calculateDailyManFixtures = (
  fixtures: Array<IBidRestroomFixtureItem>
) => {
  let dailyManTotals: any = {};

  fixtures.forEach((item: IBidRestroomFixtureItem) => {
    dailyManTotals = {
      RestroomManHours:
        (dailyManTotals.RestroomManHours || 0) +
        (item.restroomFixtures
          ? (parseStringToNumber(item.restroomFixtures) *
              parseStringToNumber(item.minutesFixture)) /
            60
          : 0),
    };
  });
  return dailyManTotals;
};

export const calculatePorterMonthly = (dayPorter: IBidDayPorter) => {
  if (!dayPorter || !dayPorter.porterDays || !dayPorter.porterDays.length)
    return 0;

  const porterConstant = 4.33;
  const porterDays =
    dayPorter.porterDays.length === 7 ? 7.5 : dayPorter.porterDays.length;
  const result =
    porterDays *
    parseStringToNumber(dayPorter.porterHoursDay || 0) *
    parseStringToNumber(dayPorter.porterValueHour || 0) *
    porterConstant;

  return result;
};

export const calculateQuote = (additionalQuote: IBidAdditionalQuoteItem) => {
  if (
    !additionalQuote ||
    !additionalQuote.sqftApplied ||
    !additionalQuote.quoteSqft
  )
    return 0;

  return (
    parseStringToNumber(additionalQuote.sqftApplied) *
    parseStringToNumber(additionalQuote.quoteSqft)
  );
};

const isValidNumber = (n) => {
  return n != undefined && n != null && n !== '';
};

export const calculateMonthlyBids = (
  dailyMan: IBidDailyManHours,
  accountInfo: IBidAccountInfo,
  dayPorter: IBidDayPorter,
  supplies: IBidSupplies,
  hourBidRate: number,
  overrideBasePrice: number,
  protectionIncluded: Array<IBidProtectionPackage>
) => {
  if (!dailyMan || !accountInfo || accountInfo.frequencyService !== 'monthly')
    return 0;

  const monthlyManHours = convertStringToNumber(
    (dailyMan.totalManHrs || 0) * accountInfo.cleaning
  );
  const dayPorterValue = convertStringToNumber(
    dayPorter.includeInPrice
      ? dayPorter.porterOverride || dayPorter.monthlyPorterPrice || 0
      : 0
  );
  const basePrice = monthlyManHours * convertStringToNumber(hourBidRate || 0);
  const validPrice = convertStringToNumber(
    isValidNumber(overrideBasePrice) ? overrideBasePrice || 0 : basePrice || 0
  );
  return {
    basePrice,
    calculatedPrice:
      validPrice +
      dayPorterValue +
      convertStringToNumber(supplies.costSupplies || 0) +
      (protectionIncluded.length > 0
        ? convertStringToNumber(
            protectionIncluded[0].totalOverride || protectionIncluded[0].total
          )
        : 0),
  };
};

export const calculateWeeklyBids = (
  dailyMan: IBidDailyManHours,
  accountInfo: IBidAccountInfo,
  dayPorter: IBidDayPorter,
  supplies: IBidSupplies,
  hourBidRate: number,
  overrideBasePrice: number,
  isSecondFrequency?: boolean,
  protectionIncluded?: Array<IBidProtectionPackage>
) => {
  if (!dailyMan || !accountInfo || accountInfo.frequencyService !== 'weekly')
    return 0;

  const frequency =
    accountInfo[isSecondFrequency ? 'secondFrequency' : 'frequency'];

  const daysSelecteds =
    frequency && frequency.selecteds && frequency.selecteds.length
      ? frequency.selecteds.length === 7
        ? 7.5
        : frequency.selecteds.length
      : 0;

  const weeklyConstant = 4.33;

  const weeklyManHours =
    (dailyMan[isSecondFrequency ? 'totalSecondOfficeManHrs' : 'totalManHrs'] ||
      0) * daysSelecteds;

  const dayPorterValue = convertStringToNumber(
    dayPorter.includeInPrice
      ? dayPorter.porterOverride || dayPorter.monthlyPorterPrice || 0
      : 0
  );
  const monthlyManHours = convertStringToNumber(
    weeklyManHours * weeklyConstant
  );

  const basePrice = monthlyManHours * convertStringToNumber(hourBidRate || 0);
  const validPrice = convertStringToNumber(
    isValidNumber(overrideBasePrice) ? overrideBasePrice || 0 : basePrice || 0
  );

  return {
    basePrice,
    calculatedPrice:
      validPrice +
      dayPorterValue +
      convertStringToNumber(supplies.costSupplies || 0) +
      (protectionIncluded.length > 0
        ? convertStringToNumber(
            protectionIncluded[0].totalOverride || protectionIncluded[0].total
          )
        : 0),
  };
};

export const calculateSmartCleanBasePrice = (bidInfo: IBidInformation) => {
  if (!bidInfo) return 0;

  return bidInfo.bidCalculatedBasePrice + bidInfo.secondBidCalculatedBasePrice;
};

export const calculateSmartCleanTotal = (
  bidInfo: IBidInformation,
  dayPorter: IBidDayPorter,
  supplies: IBidSupplies
) => {
  if (!bidInfo) return 0;

  const basePrice = isValidNumber(bidInfo.smartCleanOverrideBasePrice)
    ? bidInfo.smartCleanOverrideBasePrice
    : bidInfo.smartCleanCalculatedBasePrice;
  return (
    basePrice +
    (isValidNumber(supplies.costSupplies) ? supplies.costSupplies : 0) +
    (dayPorter.includeInPrice
      ? isValidNumber(dayPorter.porterOverride)
        ? dayPorter.porterOverride
        : dayPorter.monthlyPorterPrice || 0
      : 0)
  );
};

export const mathRoundToFixed2 = (value: number) =>
  value ? (value + 0.0000000001)?.toFixed?.(2) : value;
export const mathRoundToFixed1 = (value: number) =>
  value ? (value + 0.0000000001)?.toFixed?.(1) : value;
export const mathRoundToFixed0 = (value: number) =>
  value ? (value + 0.0000000001)?.toFixed?.(0) : value;

const inventoryFilterFunction = (item: IBidRoomInventoryItem, index: any) =>
  item.floorType;

/*
  App URLs:
  https://anago-forms-app-stag.herokuapp.com
  https://anago-forms-app-prod.herokuapp.com/
  https://anago-forms-app-rc.herokuapp.com/
*/
export const getBaseURL = () => {
  const { href } = document.location;

  let str =
    env.REACT_APP_BACKEND_PORTAL_URL ||
    'http://admin.dev.anagocleansource-test.com/';

  // if (href.includes('localhost') || href.includes('app-dev')) {
  //   return 'http://anago.app.dev.test.conceptsol.com/';
  // } else if (href.includes('app-stag')) {
  //   return 'http://anago.app.staging.test.conceptsol.com/';
  // } else if (href.includes('app-rc')) {
  //   return 'http://anago.app.rc.test.conceptsol.com/';
  // }

  // // production
  // return 'https://admin.anago.conceptsol.com/';

  return str;
};

export const getInspectionURL = () => `${getBaseURL()}Index/Inspection/`;
export const getBidURL = () => `${getBaseURL()}Index/BidInfoSheet/`;
export const getSpecialtyURL = () => `${getBaseURL()}Index/ExtraWork/`;
export const getPerformanceUrl = () => `${getBaseURL()}Index/Performance/`;

export const round = (number) => {
  let num =
    typeof number === 'string' ? Number(number.replace(',', '')) : number;
  return num ? Number(num.toFixed(2)) : num;
};
