import React from 'react';
import { romanize } from './utils';
import { PreviewFooter, PreviewHeader, TermsContentPreview } from './styles';
import { HtmlSanitizer } from 'core/utils/htmlUtils';

export const TermsContent: React.FC = ({ terms }) => {
  return (
    <TermsContentPreview>
      <p style={{ paddingTop: 0, marginTop: 0 }}>
        {HtmlSanitizer.SanitizeHtml(terms.introduction)}
      </p>
      {terms.paragraphs.map((paragraph, idx) => {
        return (
          <p
            style={{
              whiteSpace: 'pre-line',
            }}
          >
            {`${romanize(paragraph.position || idx + 1)} ${
              !paragraph.value
                ? 'N/A'
                : HtmlSanitizer.SanitizeHtml(paragraph.value)
            }`}
          </p>
        );
      })}
    </TermsContentPreview>
  );
};

export const PreviewTerms: React.FC = ({ terms }) => {
  return (
    <div style={{
      overflowY: 'hidden',
      overflowX: 'auto',
    }}>
      <div style={{
        transformOrigin: 'left top',
        transform: 'scale(0.8)'
      }}>
        <div
          style={{
            width: '1010px',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '20px',
            paddingRight: '20px',
            flexDirection: 'column',
            marginLeft: '25px',
          }}
        >
          <PreviewHeader />

          <TermsContent terms={terms} />

          <PreviewFooter>
            By signing this agreement I represent and warrant that I am duly
            authorized and have the legal capacity to execute and deliver this
            agreement.
          </PreviewFooter>
        </div>
      </div>
    </div>
  );
};
