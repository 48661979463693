import React, { memo, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidSupplies } from 'modules/BidInfoForm/interfaces';
import { suppliesValues } from 'modules/shared/bidDefaultValues';

import {
  getReferencesDetails,
  getReferenceByFranchiseId,
  getListOrderReferenceTypeByReferenceID,
} from 'modules/BidInfoForm/services/bidInfoServiceClient';
import {
  ContentInput,
  useFormProps,
  Checkbox,
  BaseButton,
} from 'core/components';
import Preview from 'modules/shared/sections/Summary/References/PreviewReference';
import ReferencesModal from 'modules/shared/sections/Summary/References';

import './styles.scss';

interface ReferenceDetail {
  ReferenceId: number | string;
  Title: string;
  Name: string;
  Company: string;
  Industry: string;
  ContactInfo: string;
  TestimonialReview: string;
  Type: number | string;
  formUniqueId?: string;
  Order: number;
  UserId: number | string;
  Default: boolean;
}

interface Reference {
  ReferenceId: number | string;
  FranchiseId: number | string;
  ReferenceDetails: ReferenceDetail[];
  OrderReferenceTypes: any[];
}

type IProps = FormikProps<IBidSupplies>;
const References = ({
  onChange: onChangeReference,
  backgroundColor,
  franchiseId,
  bidFullData,
  isSpecialty,
  canEdit,
  formUniqueId,
  shouldHideToggle,
}: IProps) => {
  const [isReferenceCustomized, setIsReferenceCustomized] =
    React.useState(false);
  const {
    BidInformationSheetReferences = [],
    FlgReference,
    BidInformationSheetReferenceId,
    FlgReferenceCustomize,
  } = bidFullData;
  const { formValues } = useFormProps();
  const formDisabled = formValues.status === 2;
  const [reference, setReference] = React.useState<Reference>({
    ReferenceId: '',
    FranchiseId: '',
    ReferenceDetails: [],
  });
  const [showReferencesModal, setShowSuppliesModal] = React.useState(false);
  const [showReferencePreview, setShowReferencePreview] = React.useState(false);

  const getReference = async () => {
    const referenceResponse = await getReferenceByFranchiseId({
      franchiseId,
    });

    const defaultReferenceDetails = await fetchReferenceDetails(
      referenceResponse
    );

    if (
      BidInformationSheetReferences.length > 0 &&
      BidInformationSheetReferences[0] &&
      BidInformationSheetReferences[0].ReferenceId
    ) {
      const bidReferenceDetails =
        BidInformationSheetReferences[0].ReferenceDetails;
      defaultReferenceDetails.forEach((referenceDetail) => {
        const hasReferenceDetail = bidReferenceDetails.some(
          (ref) => ref.ReferenceDetailId === referenceDetail.ReferenceDetailId
        );
        if (!hasReferenceDetail) {
          bidReferenceDetails.push({
            ...referenceDetail,
            Default: false,
          });
        }
      });

      let orderReferenceTypes =
        BidInformationSheetReferences[0].OrderReferenceTypes;

      if (!orderReferenceTypes) {
        orderReferenceTypes = await fetchListOrderReferenceType(
          referenceResponse.ReferenceId
        );
      }
      setReference({
        ...referenceResponse,
        ...BidInformationSheetReferences[0],
        ReferenceId: referenceResponse.ReferenceId,
        FranchiseId: referenceResponse.FranchiseId,
        ReferenceDetails: bidReferenceDetails,
        OrderReferenceTypes: orderReferenceTypes,
      });
    } else {
      const orderReferenceTypes = await fetchListOrderReferenceType(
        referenceResponse.ReferenceId
      );
      setReference({
        ...referenceResponse,
        OrderReferenceTypes: orderReferenceTypes,
        ReferenceDetails: defaultReferenceDetails,
      });
    }
  };

  const fetchReferenceDetails = async (referenceResponse: Reference) => {
    const { referenceDetails } = await getReferencesDetails({
      referenceId: referenceResponse.ReferenceId,
      defaultValue: '',
      name: '',
      company: '',
      title: '',
      industry: '',
      limit: 999999,
      offset: 0,
      page: 1,
      order: 'ASC',
      sort: 'Order',
    });
    const { Data } = referenceDetails;
    return Data;
  };

  const fetchListOrderReferenceType = async (referenceId: number) => {
    const { orderReferenceType } = await getListOrderReferenceTypeByReferenceID(
      {
        referenceId,
      }
    );
    // setReferenceOrderList(orderReferenceType);

    return orderReferenceType;
  };

  const openPreview = async (event) => {
    event.stopPropagation();
    setShowReferencePreview(true);
  };

  React.useEffect(() => {
    if (franchiseId) {
      getReference();
    }
  }, [
    franchiseId,
    BidInformationSheetReferences?.[0]?.BidInformationSheetReferenceId,
  ]);

  React.useEffect(() => {
    setIsReferenceCustomized(!!FlgReferenceCustomize);
  }, [FlgReferenceCustomize]);

  const handleChangeSuppliesAttachment = () => {
    const newFlgReference = !FlgReference;
    const currentReferences =
      newFlgReference && !BidInformationSheetReferences?.[0]
        ? [reference]
        : BidInformationSheetReferences;
    onChangeReference({
      ...bidFullData,
      BidInformationSheetReferences: currentReferences,
      FlgReference: newFlgReference,
    });
  };

  const handleSaveReferences = (
    newReference: Reference,
    isCustomized: boolean
  ) => {
    setReference(newReference);
    setIsReferenceCustomized(FlgReferenceCustomize || isCustomized);
    onChangeReference({
      ...bidFullData,
      BidInformationSheetReferences: [newReference],
      BidInformationSheetReferenceId,
      FlgReferenceCustomize: FlgReferenceCustomize || isCustomized,
    });
  };

  const handleCloseReferenceModal = (initialReference) => {
    setShowSuppliesModal(false);
    if (initialReference) {
      setReference(initialReference);
      onChangeReference({
        ...bidFullData,
        BidInformationSheetReferences: [initialReference],
        BidInformationSheetReferenceId,
      });
    }
  };

  return (
    <>
      <div className="supplies-root" style={{ background: backgroundColor }}>
        <div className="supplies-content">
          {isSpecialty && (
            <ContentInput width="100%" justify="flex-start">
              <h3 className="supplies-title">References</h3>
            </ContentInput>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              marginBottom: '20px',
            }}
          >
            {!shouldHideToggle && (
              <Checkbox
                label={
                  <div style={{ display: 'flex' }}>
                    Include
                    <a
                      className="supply-label"
                      onClick={openPreview}
                      style={{ marginLeft: '5px' }}
                    >
                      Google Reviews, References, & Testimonials
                    </a>
                  </div>
                }
                borderColor="gray"
                disabled={formDisabled}
                name="flgReference"
                value={FlgReference}
                onChange={handleChangeSuppliesAttachment}
                checked={FlgReference}
              />
            )}
            <BaseButton
              isActive={isReferenceCustomized}
              onClick={() => {
                setShowSuppliesModal(true);
              }}
              disabled={formDisabled}
            >
              {isReferenceCustomized ? 'Review Customization' : 'Customize'}
            </BaseButton>
          </div>
        </div>
      </div>
      {reference.ReferenceDetails && (
        <Preview
          withDrag={false}
          orderList={reference.OrderReferenceTypes}
          onClose={(e) => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
            setShowReferencePreview(false);
          }}
          referenceDetails={reference.ReferenceDetails.filter(
            (item) => item.Default
          )}
          opened={showReferencePreview}
        />
      )}
      {reference.ReferenceDetails && (
        <ReferencesModal
          reference={reference}
          franchiseId={franchiseId}
          opened={showReferencesModal}
          canEdit={canEdit}
          isReferenceCustomized={isReferenceCustomized}
          onClose={handleCloseReferenceModal}
          onSave={handleSaveReferences}
        />
      )}
    </>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...suppliesValues,
  }),
  handleSubmit: () => '',
};

const EnhancedReferences = withFormik<any, IBidSupplies>(mapForm)(References);

export { EnhancedReferences as References };
