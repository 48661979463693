import { validateEmail } from './utils';

const validateElement = (className, elValue, elName, arr) => {
  var el = document.getElementsByClassName(className)[0];
  if (!elValue) {
    arr.push(elName);
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }
};

export const validateForm = (formValues: any, userEvaluation = false) => {
  const ret = [];
  var el = document.getElementsByClassName('validate-pid')[0];
  if (!formValues.pid) {
    ret.push('PID');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  el = document.getElementsByClassName('validate-company-name')[0];

  if (!formValues.company) {
    ret.push('COMPANY NAME');
    el.classList.add('is-invalid');
  } else {
    if (el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  if (userEvaluation) {
    if (!formValues.selectedSatisfaction) {
      ret.push('RATING');
    }

    validateElement(
      'validate-print-name',
      formValues.printName,
      'PRINT NAME',
      ret
    );

    // validateElement(
    //   'validate-email-address',
    //   formValues.emailAddress,
    //   'EMAIL ADDRESS',
    //   ret
    // );

    var el = document.getElementsByClassName('validate-email-address')[0];
    if (!validateEmail(formValues.emailAddress)) {
      ret.push('EMAIL ADDRESS');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  if (ret.length > 0) {
    window.scrollTo(0, 0);
  }

  return ret;
};

export const validateSaveDraft = (formValues: any) => {
  const ret = [];

  if (!formValues.pid) {
    ret.push('PID');
  }

  return ret;
};

export const validateSaveTemplate = (formValues: any) => {
  const ret = [];

  var el = document.getElementsByClassName('validate-pid')[0];

  if (!formValues.referenceName || !formValues.referenceName.trim()) {
    ret.push('Template Name');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  return { required: ret, incorrect: [] };
};
