import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import ActionAlertModal from 'core/components/ActionAlertModal';
import {
  RoundSubSection,
  SubSectionHeader,
} from 'modules/BidInfoForm/components/layout';
import { uploadPhysicalContract } from 'modules/BidInfoForm/services/bidInfoService';
import  { ButtonComponent } from 'core/components';
import {
  SectionTitle,
} from 'modules/BidInfoForm/styles';
import DatePickerPhysicalContract from '../DatePickerPhysicalContract';

const PhysicalContract = (props) => {
  const [invalidFormat, setInvalidFormat] = React.useState(false);
  const [signatureDate, setSignatureDate] = React.useState(props.data?.SignatureDate);
  const [loadingUpload, setLoadingUpload] = React.useState(false);
  const [loadingUpdateBid, setLoadingUpdateBid] = React.useState(false);
  const [physicalURL, setPhysicalURL] = React.useState('');

  useEffect(() => {
    if (props.data?.SignatureDate) {
      setSignatureDate(new Date(props.data?.SignatureDate));
    }
  }, [props.data?.SignatureDate]);

  useEffect(() => {
    if (props.data?.PhysicalURL) {
      setPhysicalURL(props.data?.PhysicalURL);
    }
  }, [props.data?.PhysicalURL]);

  const inputRef = useRef();

  const buttonStyle = {
    pointerEvents: 'auto',
    padding: '15px',
    marginBottom: 0,
    borderRadius: '5px',
    opacity: 1,
    color: 'white',
    border: '2px solid transparent',
    width: 'auto',
    minWidth: '200px',
    fontFamily: 'OpenSans-SemiBold',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  const iconButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#fff',
    border: '2px solid #ccc',
    minWidth: 'auto',
    color: '#555',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    justifyContent: 'center',
  };

  const isPDFFile = (file) => {
    if (!file) return false;
    const extension = file.name.split('.').pop().toLowerCase();
    return extension === 'pdf';
  }
  
  const handleChangeFile = async (event) => {
    const file = event.target.files[0];
    if (isPDFFile(file)) {
      setLoadingUpload(true);
      const reader = new FileReader();
  
      reader.onloadend = async function () {
        const fileContent = reader.result;
  
        const data = await uploadPhysicalContract({
          file: {
            fileContent,
            fileContentName: file.name,
          },
          id: props.data.BidUniqueID,
          isSS: props.data.IsSS,
        });
        setLoadingUpload(false);

        setPhysicalURL(data.Uri);
      }
  
      reader.readAsDataURL(file);
    } else {
      setInvalidFormat(true);
    }
  };
    
  const handleUpdateBid = async (newData) => {
    setLoadingUpdateBid(true);
    const result = await props.clickToSign(true, newData);
    setLoadingUpdateBid(false);
    if (result?.data) {
      window.location.reload();
    }
  }

  const handleOpenFile = () => {
    window.open(physicalURL, '_blank');
    // Logic to open the PDF file
    // You can use the file URL or any other method to open the PDF
    console.log('Opening the PDF file...');
  };

  const handleClickInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  const handleClickSold = async () => {
    console.log(props.data, 'props.data')
    handleUpdateBid({
      IsPhysicalSigned: true,
      Status: 2,
      SignatureDate: signatureDate,
      PhysicalURL: physicalURL,
    });
  }

  const buttonDisabled = !physicalURL || !signatureDate || props.data?.IsPhysicalSigned;

  return (
    <RoundSubSection
      marginTop="30px !important"
      paddingBottom="30px"
      width="100%"
      backgroundColor="#fff"
    >
      <SubSectionHeader
        alignItems={'start'}
        backgroundColor="#216eab"
        color="#fff"
        padding={'15px 0 15px 0'}
      >
        <SectionTitle
          maxFontSize="20px"
          padding="0 20px 0 20px"
          color="#fff"
          margin="0 !important"
        >
          FINISHED?
        </SectionTitle>
      </SubSectionHeader>

      <DatePickerPhysicalContract date={signatureDate} onChangeDate={setSignatureDate} />
      <div style={{ display: 'flex', gap: '60px' }}>
        <input
          id="file-input"
          ref={inputRef}
          type="file"
          accept=".pdf"
          style={{ display: 'none' }}
          onChange={handleChangeFile}
          data-cy="file-input"
        />
        {physicalURL ? (
          <div style={{ display: 'flex', gap: '20px' }}>
            {!props.data?.IsPhysicalSigned && (
              <button
                  style={iconButtonStyle}
                  onClick={handleClickInput}
                >
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                </button>
            )}
              <ButtonComponent
                onClick={handleOpenFile}
                rounded
                secondary
                loading={loadingUpload}
                outline
                skew={false}
                data-cy="click-here-to-display"
              >
                DISPLAY SIGNED CONTRACT
              </ButtonComponent>
          </div>
        ) : (
          <ButtonComponent
            onClick={handleClickInput}
            secondary
            loading={loadingUpload}
            rounded
            skew={false}
            data-cy="click-here-to-upload"
          >
            UPLOAD SIGNED CONTRACT
          </ButtonComponent>
        )}
          <ButtonComponent
            onClick={handleClickSold}
            disabled={buttonDisabled}
            style={{ width: '175px' }}
            skew={false}
            loading={loadingUpdateBid}
            rounded
            data-cy="click-here-to-mark-sold"
          >
            {props.data?.IsPhysicalSigned ? 'SOLD' : 'MARK AS SOLD'}
          </ButtonComponent>
      </div>
      <ActionAlertModal
        message="Invalid Document Format: Only PDF files are accepted."
        isVisible={invalidFormat}
        onConfirm={() => {
          setInvalidFormat(false);
        }}
      />
    </RoundSubSection>
  );
};

export default React.forwardRef(PhysicalContract);
