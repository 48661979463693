import React, { useState, useEffect } from 'react';
import {
  getBidInfoById,
  postBidInfo,
  getByUniqID,
  getFranchiseById,
  getCustomizeTerms,
} from '../../services';
import CustomizeTermsAndConditions from './CustomizeTermsAndConditions';
import { getQueryString } from 'core/utils';
import { LoadingPage } from '../../components/organisms';
import { parseAfterPost, parseGetToPost, parseTerms } from '../../parser';

export const CustomizeTerms = ({
  location,
  onSave,
  onCancel,
  uuid,
  franchiseId,
  showing,
  isSpecialty,
}) => {
  const [bidInfo, setBidInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [franchiseData, setFranchiseData] = useState(null);
  const [termsDefault, setTermsDefault] = useState(null);

  const currentUrl = location.search;

  useEffect(() => {
    setIsLoading(true);

    if (franchiseId) {
      if (!uuid && !bidInfo) {
        const formId = getQueryString(currentUrl, 'formId');
        const uniqueId = getQueryString(currentUrl, 'formUniqueId');
        if (uniqueId) {
          getByUniqID(uniqueId).then(({ data }) => {
            setBidInfo(data['Data']);
            setIsLoading(false);
          });
        } else if (formId) {
          getBidInfoById(formId).then(({ data }) => {
            setBidInfo(data['Data']);
            setIsLoading(false);
          });
        }
      } else {
        getByUniqID(uuid).then(({ data }) => {
          setBidInfo(data['Data']);
          setIsLoading(false);
        });
      }
    }
  }, [uuid, franchiseId]);

  // useEffect(() => {
  //   // if (franchiseId) {
  //   //   getFranchiseById(franchiseId).then((result) => {
  //   //     setFranchiseData(result.data);
  //   //     // if (bidInfo['IsSS']) {
  //   //     //   if (Object.keys(data).length != 0 && !data.paragraphV) {
  //   //     //     setAllValues({
  //   //     //       paragraphV: result.data['EWANewProposalSection5'] || '',
  //   //     //     });
  //   //     //   }
  //   //     // }
  //   //   });
  //   // }

  //   getCustomizeTerms(Number(franchiseId), isSpecialty).then((result) => {
  //     setTermsDefault(result);
  //   });
  // }, [franchiseId]);

  return (
    <>
      {isLoading && <LoadingPage />}

      <CustomizeTermsAndConditions
        isSpecialty={isSpecialty}
        showing={showing}
        data={bidInfo}
        cancel={onCancel}
        franchiseData={franchiseData}
        franchiseId={franchiseId}
        setIsLoading={setIsLoading}
        update={(terms) => {
          setIsLoading(true);

          const values = parseGetToPost(bidInfo);

          values.generateBlankPDF = true;

          values['Terms'] = parseTerms(terms, false);

          postBidInfo(values).then((result) => {
            setBidInfo(parseAfterPost(values, result.data.id));
            setIsLoading(false);
            // if (callback) callback();
            if (onSave) onSave(values['Terms']);
          });
        }}
        // update={(termsAndConditionsValues, oldValues, callback) => {
        //   setIsLoading(true);
        //   const values = parseGetToPost(oldValues);

        //   if (bidInfo['IsSS']) {
        //     values['ProposalSection5'] = termsAndConditionsValues.paragraphV;
        //   } else {
        //     values['ProposalHolidays'] =
        //       termsAndConditionsValues['HolidaySection'];
        //     values['ProposalSection3'] =
        //       termsAndConditionsValues['Section3Section'];
        //     values['ProposalSection4'] =
        //       termsAndConditionsValues['Section4Section'];
        //     values['ProposalSection5'] =
        //       termsAndConditionsValues['Section5Section'];
        //     values['ProposalSection7'] =
        //       termsAndConditionsValues['Section7Section'];
        //     values['ProposalSection8'] =
        //       termsAndConditionsValues['Section8Section'];

        //     values.generateBlankPDF = true;
        //   }

        //   postBidInfo(values).then((result) => {
        //     setBidInfo(parseAfterPost(values, oldValues, result.data.id));
        //     setIsLoading(false);
        //     if (callback) callback();
        //     if (onSave) onSave();
        //   });
        // }}
      />
    </>
  );
};
