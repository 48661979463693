import React from 'react';
import { Modal, SVGIcon } from 'core/components';
import CloseIcon from 'assets/icons/close.svg';
import Image1 from 'assets/img/preview-protection-disinfection-plans.png';

interface PreviewProps {
  opened: boolean;
  onClose: (e: any) => void;
}

export const Preview: React.FC<PreviewProps> = ({
  opened,
  onClose,
}) => {

  return (
    <Modal onClose={onClose} opened={opened}>
      <div className="modal-content">
        <div className="preview-close">
          <SVGIcon icon={CloseIcon} onClick={onClose} />
        </div>
        <img src={Image1} />
      </div>
    </Modal>
  );
};

export default Preview;
