import React, { useState, useEffect, useLayoutEffect } from 'react';

import { getQueryString } from 'core/utils';

import logo from '../../assets/logo.png';
import headerPic from '../../assets/header-pic.png';

import {
  HeaderLogo,
  HeaderPic,
  Background,
  Content,
  HeaderTitle,
  Paragraph,
  FakeAnchor,
  HeaderSection,
  HiddenAnchor,
} from '../../components/atoms';

import { LoadingPage } from '../../components/organisms';

import { getSignNowDownloadLink } from '../../services/bidInfoService';

export const ProposalDownload = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState('');
  const [formUniqueId, setFormUniqueId] = useState(null);
  const [biggerThan796, setBiggerThan796] = useState(false);

  const setBreakpoints = () => {
    setBiggerThan796(window.innerWidth >= 796);
  };

  const getDownloadUrl = async (uniqueId) => {
    const result = await getSignNowDownloadLink(uniqueId);
    setUrl(result.data);
  };

  const onClickDownload = async (
    uniqueId: string | null,
    downloadTimeout: number = 0
  ) => {
    if (!url && uniqueId) {
      await getDownloadUrl(uniqueId);
    }

    setTimeout(() => {
      document.getElementById('hiddenAnchor')?.click();
      setIsLoading(false);
      setUrl('');
    }, downloadTimeout);
  };

  useEffect(() => {
    const uId = getQueryString(window.location.href, 'formUniqueId');
    setFormUniqueId(uId);
    getDownloadUrl(uId);

    if (uId) {
      onClickDownload(uId, 2000);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Content>
      <HeaderSection paddingBottom="0px">
        <Content padding="0">
          <HeaderLogo src={logo} />
        </Content>
        <Content
          padding="0"
          style={{
            width: '100%',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <HeaderPic src={headerPic} />
          <Background />
        </Content>
        <Content
          style={{
            paddingTop: biggerThan796 ? '5vw' : '8vw',
            paddingBottom: biggerThan796 ? '5vw' : '8vw',
          }}
        >
          <HeaderTitle marginTop="15px">Proposal Download</HeaderTitle>
        </Content>
      </HeaderSection>
      <Content
        padding="30px"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paragraph
          textAlign="center"
          fontSize="18px"
          style={{ fontWeight: 'bold', maxWidth: '600px' }}
        >
          Your signed proposal should automatically begin downloading shortly.
          If it doesn't, please click the link below to initiate the download.
        </Paragraph>

        <Paragraph
          textAlign="center"
          fontSize="18px"
          style={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '600px',
          }}
        >
          <FakeAnchor onClick={() => onClickDownload(formUniqueId, 0)}>
            Click here to download
          </FakeAnchor>
        </Paragraph>

        <Paragraph
          textAlign="center"
          fontSize="18px"
          style={{ fontWeight: 'bold', maxWidth: '600px' }}
        >
          If you encounter any issues or need further assistance, please don't
          hesitate to contact our support team.
        </Paragraph>
      </Content>

      <HiddenAnchor id="hiddenAnchor" href={url} />
    </Content>
  );
};
