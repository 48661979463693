import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import DragIconWhite from './assets/Drag-white.svg';
import DragIcon from './assets/Drag.svg';
import { ReferenceDetail } from 'modules/shared/sections/Summary';
import { PreviewReferenceItem } from './PreviewReferenceItem';
import ReferencesBanner from './assets/references_banner.jpg';
import { SVGIcon } from 'core/components';

interface PreviewProps {
  opened: boolean;
  onClose: () => void;
  onSave?: (referenceDetails: ReferenceDetail[], orderList: any) => void;
  orderList: any;
  className?: string;
  onMoveCard?: (item: any) => void;
  onDragEnd?: (item: any) => void;
  withShadow?: boolean;
  withDrag?: boolean;
  isBid?: boolean;
  referenceDetails: ReferenceDetail[];
}

export const PreviewReferenceContent: React.FC<PreviewProps> = ({
  referenceDetails = [],
  onDragEnd,
  orderList,
  onMoveCard,
  className,
  withDrag,
  withShadow = false,
  isBid,
}) => {
  const reorder = (
    list: ReferenceDetail[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      // change background colour if dragging
      opacity: isDragging ? 0.3 : 1,
      width: '100%',
      background: '#ffffff',

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = () => ({
    width: '100%',
  });

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      orderList,
      result.source.index,
      result.destination.index
    );

    if (onDragEnd) {
      onDragEnd(items);
    }
  };

  const getType = (type: '1' | '2' | '3' | '') => {
    if (type == '1') {
      return 'Google Review';
    }
    if (type == '2') {
      return 'Client Testimonials';
    }
    if (type == '3') {
      return 'Client References';
    }
    return type;
  };

  const handleMoveCard = (cards: any) => {
    const currentType = cards[0].Type;
    const otherReferences = referenceDetails.filter(
      (item) => item.Type !== currentType
    );
    const newCards = cards
      .concat(otherReferences)
      .sort((a: any, b: any) => a.Order - b.Order);

    if (onMoveCard) {
      onMoveCard(newCards);
    }
  };

  const renderGoogleTitle = () => (
    <div style={{ display: 'flex', paddingLeft: withDrag ? '20px' : 0 }}>
      <span style={{ color: '#2E80EC' }}>G</span>
      <span style={{ color: '#F07776' }}>O</span>
      <span style={{ color: '#F4D473' }}>O</span>
      <span style={{ color: '#2E80EC' }}>G</span>
      <span style={{ color: '#209653' }}>L</span>
      <span style={{ color: '#F07776' }}>E</span>
      <span>&nbsp;</span>
      <span style={{ color: '#2E80EC' }}>R</span>
      <span style={{ color: '#F07776' }}>E</span>
      <span style={{ color: '#F4D473' }}>V</span>
      <span style={{ color: '#2E80EC' }}>I</span>
      <span style={{ color: '#209653' }}>E</span>
      <span style={{ color: '#F07776' }}>W</span>
      <span style={{ color: '#2E80EC' }}>S</span>
    </div>
  );

  return (
    <div className={className}>
      <div className="title-image">
        {!withShadow && <h3>SUPERIOR SERVICE GUARANTEED</h3>}
        <img
          src={ReferencesBanner}
          style={{ width: isBid ? '100vw' : '100vw' }}
        />
      </div>
      <div className="preview-content">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" isDropDisabled={!withDrag}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle()}
              >
                {orderList.map((item: any, index: number) => {
                  const currentReferenceDetails = referenceDetails.filter(
                    (a: ReferenceDetail) => a.Type == item.Type
                  );
                  if (currentReferenceDetails.length > 0) {
                    return (
                      <Draggable
                        key={`item-${item.Type}`}
                        draggableId={`item-${item.Type}`}
                        index={index}
                        isDragDisabled={!withDrag}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={classNames('preview-reference-item', {
                              'with-shadow': withShadow,
                            })}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className={classNames(
                                'preview-reference-item-header',
                                {
                                  white: item.Type == '1',
                                  blue: item.Type == '3',
                                  withDrag,
                                }
                              )}
                              data-title={
                                item.Type != '1' ? getType(item.Type) : ''
                              }
                              {...provided.dragHandleProps}
                            >
                              {withDrag && (
                                <div className="dragIcon">
                                  <SVGIcon
                                    icon={
                                      item.Type == '1'
                                        ? DragIcon
                                        : DragIconWhite
                                    }
                                  />
                                </div>
                              )}
                              {item.Type == '1' && renderGoogleTitle()}
                            </div>
                            <PreviewReferenceItem
                              withDrag={withDrag}
                              onMoveCard={handleMoveCard}
                              referenceDetails={currentReferenceDetails}
                              index={index}
                              id={item.Type}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default PreviewReferenceContent;
