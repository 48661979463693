import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import logo from './logo.png';
import { useFormProps, BackButton } from 'core/components';
import { isWebView } from 'core/utils';
import { Select } from 'modules/shared/sections/Header/Select';
import './styles.scss';
import styled from 'styled-components';
import { getFranchiseById } from '../../../../services/performanceEvaluationService';
import { checkMaintenenceMode } from '../../../../../BidInfoForm/services/bidInfoService';

const Button = styled.button`
  font-size: 16px;
  font-family: 'OpenSans-Regular';
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #216eab;
  color: white;
  border: 1px solid gray;
  border-radius: 6px;
  width: 215px;
  height: 58px;
  margin-left: 20px;
  margin-bottom: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.7;
  }
`;

const Header = (props: any) => {
  const { formValues, onChange, needsSaving } = useFormProps();
  const { userEvaluation } = props;
  const [selectInputWidth, setSelectInputWidth] = React.useState(0);
  const [showMaintenanceMode, setShowMaintenanceMode] = React.useState(null);

  const calcWidth = (width) => (width + 16) * 5.8;
  const setInitialSelectedWidth = (cities, cityId) => {
    const selected =
      cityId === undefined ? cities[0] : cities.find((x) => x.Id === cityId);
    let value = 80;

    if (selected && Object.keys(selected).length) {
      value = selected['Name'].length;
    }

    return calcWidth(value);
  };
  React.useEffect(() => {
    if (formValues.franchiseId) {
      setTimeout(() => {
        const selected = props.cities.find(
          (x) => x.Id == formValues.franchiseId
        );
        let value = 80;

        if (selected && Object.keys(selected).length) {
          value = selected['Name'].length;
        }

        setSelectInputWidth(calcWidth(value));
      }, 10);
    }
  }, [formValues.franchiseId, props.cities]);

  React.useEffect(() => {
    (async () => {
      const maintenanceMode = await checkMaintenenceMode();
      if (maintenanceMode.status == '200' && maintenanceMode.data) {
        setShowMaintenanceMode(maintenanceMode.data);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!userEvaluation) {
      if (formValues.franchiseId) {
        setTimeout(async () => {
          const franchiseData = await getFranchiseById(formValues.franchiseId);
          props.onSelectFranchise(franchiseData.data);
        }, 0);
      }
    }
  }, [userEvaluation, formValues.franchiseId]);

  const onSelectChange = async (
    name: string,
    value,
    element: HTMLSelectElement
  ) => {
    const selected = element.options[element.selectedIndex].label;
    setSelectInputWidth(calcWidth(selected.length));
    onChange(name, value);

    const franchiseData = await getFranchiseById(value);
    props.onSelectFranchise(franchiseData.data);
  };

  if (selectInputWidth === 0 && props.cities.length) {
    setSelectInputWidth(
      setInitialSelectedWidth(props.cities, formValues.franchiseId)
    );
  }
  return (
    <>
      {!userEvaluation && showMaintenanceMode && (
        <div
          className="maintenance-alert-wrapper"
          style={{
            borderBottom: '1px solid black',
            width: '100%',
          }}
        >
          <div
            className="maintenance-alert-message"
            style={{
              backgroundColor: 'white',
              maxWidth: '1200px',
              textAlign: 'center',
              padding: '15px',
            }}
          >
            <span className="message" style={{ color: 'black' }}>
              <span style={{ fontWeight: 'bolder', color: 'red' }}>
                Attention:{' '}
              </span>
              {showMaintenanceMode}
            </span>
          </div>
        </div>
      )}

      <div className="performance-header">
        <div className="header-content">
          {!userEvaluation && (
            <div className="bid-input-content">
              <div className="bid-input-label">Master City</div>
              <Select
                width={selectInputWidth}
                value={formValues.franchiseId}
                name="franchiseId"
                items={props.cities}
                onChange={onSelectChange}
                optionLabel="Name"
                optionValue="Id"
                placeholder="Select a city"
                disabled
              />
            </div>
          )}

          <img src={logo} height="auto" width="170px" />
          {isWebView() && !userEvaluation && (
            // <div className="back-button-container">
            //   <button
            //     className="button"
            //     onClick={() => (needsSaving ? props.onExit() : props.onBack())}
            //   >
            //     BACK
            //   </button>
            // </div>

            <BackButton
              style={{
                position: 'absolute',
                right: '25px',
                top: '27px',
              }}
              skewable
              reverseSkew
              onClick={() => (needsSaving ? props.onExit() : props.onBack())}
            />
          )}
        </div>
        <div className="fields-section" style={{ position: 'relative' }}>
          {/* {!userEvaluation && (
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: '15px',
              }}
            >
              <Button
                disabled={formValues.status === 2}
                onClick={props.openSearch}
              >
                Open Performance forms
              </Button>
            </div>
          )} */}

          <div className="title">
            Performance Evaluation
            {!userEvaluation && (
              <div
                className="hamb-menu-icon"
                onClick={() => props.openSearch()}
              />
            )}
          </div>
          <div className="fields">
            <div className="field-content less-width-input">
              <span className="header-title">PID</span>
              <input
                placeholder="PID"
                className="input-header input-bid validate-pid auto-capitalize"
                style={{ borderStyle: 'none', outline: 'none' }}
                value={formValues.pid}
                onChange={(e) => onChange('pid', e.target.value)}
                disabled={
                  userEvaluation ||
                  props.isCompleted ||
                  formValues.isPidDisabled
                }
                maxLength={10}
              />
            </div>
            <div className="field-content more-width-input">
              <span className="header-title">Company Name</span>
              <input
                placeholder="Company Name"
                className="input-header input-bid validate-company-name"
                style={{ borderStyle: 'none', outline: 'none' }}
                value={formValues.company}
                onChange={(e) => onChange('company', e.target.value)}
                disabled={userEvaluation || props.isCompleted}
                autoCapitalize="words"
                maxLength={75}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...{},
  }),
  handleSubmit: () => '',
};

const EnhancedHeader = withFormik<any, any>(mapForm)(withRouter(Header));

export { EnhancedHeader as Header };
