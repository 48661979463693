import React from 'react';
import { SVGIcon } from 'core/components';
import { formatCurrency } from 'core/utils';
import DragIcon from './assets/Drag.svg';

export interface ItemDragProps {
  supplyDetailItem: SupplyDetail;
  withDrag?: boolean;
  showPrice: boolean;
}

interface SupplyDetail {
  SupplyDetailId: number | string;
  SupplyId: number | string;
  SupplyCategory: string;
  BrandInfo: string;
  Unit: string;
  Price: number;
  LastUpdate: string;
  UserId: number | string;
  Order: number;
  UserFirstName?: string;
  UserLastName?: string;
  Default: boolean;
}

export const PreviewItem: FC<ItemDragProps> = ({
  supplyDetailItem,
  withDrag = true,
  showPrice,
}) => {
  return (
    <>
      {withDrag && (
        <td>
          <div className="dragIcon">
            <SVGIcon icon={DragIcon} />
          </div>
        </td>
      )}
      <td>{supplyDetailItem.SupplyCategory}</td>
      <td style={{ overflowWrap: 'anywhere' }}>{supplyDetailItem.BrandInfo}</td>
      <td>{supplyDetailItem.Unit}</td>
      {showPrice && <td>{formatCurrency(supplyDetailItem.Price)}</td>}
    </>
  );
};
