import React from 'react';
import BidModal from 'modules/shared/Menu/BidModal'
import { LoadingPage } from 'modules/BidInfoForm/components/organisms';
import { getQueryString } from 'core/utils';
import { LoadingModal } from 'core/components/LoadingModal';
import { NoteModal } from 'modules/shared/sections/Footer/NoteModal';
import { getBidByUserTamplates, postSetNotes, postArchiveBid } from 'modules/BidInfoForm/services/bidInfoService';
import { ActionAlertModal } from '../../../../../../core/components';

const LoadTemplateModal = ({ doneAction, location, franchiseName, navigateToForm, isSpecialty = false }) => {
  const { search } = location;
  const franchiseId = decodeURI(getQueryString(search, 'franchiseId'));
  const userId = getQueryString(search, 'userId');
  const [data, setData] = React.useState({
    'Signed': [],
    'Drafts': [],
    'Imports': [],
    'Sents': [],
    'MyTemplates': [],
    'CityTemplates': [],
  });

  const page = 0;
  const [form, setForm] = React.useState({})
  const [showAlertModal, setShowAlertModal] = React.useState(false)
  const [itemsCurrentPage, setItemsCurrentPage] = React.useState({
    Signed: 0,
    MyTemplates: 0,
    Drafts: 0,
    Sents: 0,
    CityTemplates: 0,
    Imports: 0,
  });
  const [notesModalState, setNotesModalState] = React.useState({
    showModal: false,
    canEdit: false,
    notesText: undefined,
    formItem: undefined,
  });

  const showNotesModal = (
    showModal: boolean,
    canEdit: boolean,
    formItem: any
  ) => {
    setNotesModalState((prevState) => ({
      ...prevState,
      showModal,
      canEdit,
      formItem,
    }));
  };

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (userId) {
      getBidByUserTamplates({ userId, page, isSpecialty , franchiseId }).then(
        (foundValues) => {
          setIsLoading(false);
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            ...foundValues.data,
            MyTemplates: [
              ...data.MyTemplates,
              ...foundValues.MyTemplates || [],
            ],
            CityTemplates: [
              ...data.CityTemplates,
              ...foundValues.CityTemplates || [],
            ],
            TotalCityTemplates: foundValues.TotalCityTemplates,
            TotalMyTemplates: foundValues.TotalMyTemplates,
          });
        }
      );
    }
  }, [userId]);

  const getBidNextPage = (type) => {
    const nextPage = itemsCurrentPage[type] + 1;
    if (userId) {
      getBidByUserTamplates({ userId, page: nextPage, isSpecialty, franchiseId }).then(
        (foundValues) => {
          setIsLoading(false);
          setItemsCurrentPage({
            ...itemsCurrentPage,
            [type]: nextPage,
          });
          // set types to drafts, sents and templates, so we can identify the items later
          setData({
            ...data,
            [type]: [...data[type], ...foundValues[type]],
          });
        }
      );
    }
  };

  const handleSearchBid = ({ type, query, page }) => {
    if (userId) {
      getBidByUserTamplates({ userId, page, referenceName: query, isSpecialty, franchiseId }).then(
        (foundValues) => {
          setIsLoading(false);
          setData({
            ...data,
            [type]: [...data[type], ...foundValues[type]],
          });
        }
      );
    }
  };
  
  const handleShowAlertModal = (formProps) => {
    setShowAlertModal(true);
    setForm(formProps)
  }
  
  const handleNavigateToForm = () => {
    setIsLoading(true);
    setShowAlertModal(false);
    navigateToForm(form);
  }

  const handleDismiss = () => {
    setIsLoading(false);
    setShowAlertModal(false);
  }

  const updateBid = () => {
    setIsLoading(true);
    getBidByUserTamplates({ userId, page, isSpecialty, franchiseId }).then(
      (foundValues) => {
        setIsLoading(false);
        // set types to drafts, sents and templates, so we can identify the items later
        setData({
          ...data,
          ...foundValues.data,
          MyTemplates: [
            ...foundValues.MyTemplates || [],
          ],
          CityTemplates: [
            ...foundValues.CityTemplates || [],
          ],
          TotalCityTemplates: foundValues.TotalCityTemplates,
          TotalMyTemplates: foundValues.TotalMyTemplates,
        });
      }
    );
  }

  const archiveForm = (formItem) => {
    setIsLoading(true);

    postArchiveBid({
      BidUniqueID: formItem.BidUniqueID,
      isArchived: true,
      BidInformationSheetId: formItem.BidInformationSheetId,
    }).then(updateBid);
  };

  const saveNotes = (notes) => {
    if (notesModalState.canEdit) {
      setIsLoading(true);
      postSetNotes({
        BidUniqueID: notesModalState?.formItem?.BidUniqueID,
        Notes: notes,
        BidInformationSheetId: notesModalState?.formItem?.BidInformationSheetId,
      }).then((result) => {
        const newData = { ...data };
        Object.values(newData).forEach((v) => {
          if (Array.isArray(v)) {
            v.map((i) => {
              if (
                i['BidUniqueID'] == notesModalState?.formItem?.BidUniqueID ||
                i['BidInformationSheetId'] ==
                  notesModalState?.formItem?.BidInformationSheetId
              ) {
                i['Notes'] = notes;
              }
            });
          }
        });

        setData(newData);

        setNotesModalState((prevState) => ({
          ...prevState,
          showModal: false,
          canEdit: false,
          formItem: undefined,
        }));
        setIsLoading(false);

        updateBid();
      });
    } else {
      setNotesModalState((prevState) => ({
        ...prevState,
        showModal: false,
        canEdit: false,
        formItem: undefined,
      }));
    }
  };

  return (
    <>
      {isLoading && <LoadingModal localLoading />}
      <BidModal
        isTemplate
        isSpecialty={isSpecialty}
        title="Select template"
        className="load-template-modal"
        navigateToForm={handleShowAlertModal}
        data={data}
        franchiseId={franchiseId}
        onRefresh={updateBid}
        franchiseName={franchiseName}
        doneAction={doneAction}
        background
        onSearch={handleSearchBid}
        renderTemplateSection
        renderCityTemplatesSection
        archiveForm={archiveForm}
        showNotesModal={(canEdit, formItem) =>
          showNotesModal(true, canEdit, formItem)
        }
        getNextPage={getBidNextPage}
      />
      <ActionAlertModal
        title={`Are you sure you want to apply the ${form.ReferenceName}`}
        renderMessage={() => (
          <span className='required-field' style={{ textAlign: 'center', display: 'block', textTransform: 'uppercase' }}>
            THIS ACTION WILL OVERWRITE ALL CURRENT BID DETAILS
          </span>
        )}
        titleStyle={{
          fontFamily: 'OpenSans-Regular',
          fontSize: '18px',
          textTransform: 'initial',
        }}
        onConfirm={handleNavigateToForm}
        isVisible={showAlertModal}
        onDismiss={handleDismiss}
        confirmText="APPLY TEMPLATE"
      />
      {isLoading && <LoadingPage />}
      {notesModalState.showModal && (
        <NoteModal
          onCancel={() =>
            setNotesModalState((prevState) => ({
              ...prevState,
              showModal: false,
              canEdit: false,
              formItem: undefined,
            }))
          }
          notesText={notesModalState.formItem.Notes || ' '}
          canEdit={notesModalState.canEdit}
          onClose={(notes) => saveNotes(notes)}
        />
      )}
    </>
  );
};

export default LoadTemplateModal;