import React, { useEffect, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import { IBidInfoForm, IWarningModal } from '../../interfaces';
import {
  defaultValues,
  defaultPostPayload as payload,
} from 'modules/shared/bidDefaultValues';
import { FormProvider, GenericModal, RenderPdf } from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { ProtectionDisinfectionPlansSs } from 'modules/BidInfoForm/screens/BidForm/sections';
import { getQueryString, axiosInstance } from 'core/utils';
import { stripHTMLTags, decodeEntities, isWebView } from 'core/utils';
import { ServiceProvider } from 'modules/SpecialtyForm/screens/sections/ServiceProvider';
import { getBaseURL, getBidURL } from '../../utils';
import { parseFromApi, parseGetToPost, parseWaterStreet } from '../../parser';
import { IAPIResponse } from '../../interfaces';
import EventEmitter from 'core/utils/events';
import {
  postBidInfo,
  getByUniqID,
  uploadOneTimeAttachment,
  getEasterEggTemplate,
  getBidInfoById,
  getFranchiseById,
  saveDraft,
  saveTemplate,
  checkIsGeneratingPDF,
  isAlive,
  isTabIdValid,
  getBlankPdfUrl,
  verifyServerUnavailable,
  updateBid,
  autoSave,
} from '../../services/bidInfoService';
import { LoadingPage, BlockingOverlay } from '../../components/organisms';
import { toast } from 'react-toastify';

import {
  AccountInfo,
  AdditionalInformation,
  ServiceArea,
  RestroomFixtures,
  ProductionRates,
  DailyManHours,
  CleaningSpecification,
  ProtectionDisinfectionPlans,
  CancellationClause,
  DayPorter,
  Supplies,
  References,
  BidInformation,
  SectionGroup,
} from '../BidForm/sections';

import { useActivityTracking } from '../../../../core/hooks/useActivityTracking';

import {
  TemplateHeader,
  ClientInfo,
  RoomInventory,
  TemplateFooter,
  AdditionalQuotes,
  ConfirmationModal,
  FloorTypeTotals,
} from 'modules/shared/sections';

import { BidFormMenuScreen } from 'modules/shared/Menu';

import './styles.scss';
import { validateSaveTemplate } from '../../../../core/validators/BidInfoFormValidator';
import SignNowScreen from '../SignNowScreen';
import BlockingPopupModal from '../BlockingPopupModal';
import { additionalQuotesValues } from '../../../shared/bidDefaultValues';
import { useAuthenticationHandler } from '../../../../core/hooks/useAuthenticationHandler';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from '../../../../core/utils';
import { useGenericErrorHandler } from '../../../../core/hooks/useGenericErrorHandler';
import { getCookie } from 'core/utils/cookiesUtils';

sessionStorage.removeItem('sessionStorageCleaningSpecification');

const compareValues = (v1, v2) => {
  const orderedV1 = {},
    orderedV2 = {};

  Object.keys(v1)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV1[key] = v1[key];
      }
    });

  Object.keys(v2)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV2[key] = v2[key];
      }
    });
  const equal = JSON.stringify(orderedV1) == JSON.stringify(orderedV2);
  return equal;
};

type BidFormType = FormikProps<IBidInfoForm> & RouteComponentProps;

const NewTemplate = ({
  values,
  setFieldValue,
  setValues,
  dirty,
  ...props
}: BidFormType) => {
  const { search } = props.location;
  const uniqueId = getQueryString(search, 'formUniqueId');
  const [showExit, setShowExit] = useState(false);
  const [ready, setReady] = useState(false);
  const [tabId, setTabId] = useState(new Date().toISOString());
  const franchiseIdQs = getQueryString(window.location.href, 'franchiseId');
  const setAllValues = (params: any) => {
    return setValues({ ...values, ...params });
  };
  const [isCurrentSaveAs, setIsCurrentSaveAs] = useState(false);
  const [isCurrentCityTemplate, setIsCurrentCityTemplate] = useState(false);
  const [duplicateReferenceName, setDuplicateReferenceName] = useState(false);
  const [warningModal, setWarningModal] = useState<IWarningModal>(null);
  const [blockingModal, setBlockingModal] = useState<IWarningModal>(null);
  const [warningCityTemplate, setWarningCityTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hadChanges, setHadChanges] = useState(false);
  const [showConfirmation, setShowConfirmationModal] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [userData, setUserData] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [showAlreadySignedModal, setShowAlreadySignedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [franchiseId, setFranchiseId] = useState(franchiseIdQs);
  const [franchiseData, setFranchiseData] = useState(null);
  const [successModalData, setSuccessModalData] = useState({
    emails: '',
    emailBody: '',
    location: props.location,
    formUniqueId: '',
    franchiseId: '',
    bidLink: '',
  });
  const logSentry = useActivityTracking(() => values);

  const [autoSaveTimeout, setAutoSaveTimeout] = React.useState(1);
  const [executeAutoSave, setExecuteAutoSave] = React.useState(null);
  const [tempData, setTempData] = React.useState({});
  const [completeButtonEnabled, setCompleteButtonEnabled] = useState(true);
  const [blockAutoSave, setBlockAutoSave] = React.useState(false);
  const [showConnectionWarning, setShowConnectionWarning] =
    React.useState(false);
  const [showEmptyReferenceNameWarning, setShowEmptyReferenceNameWarning] =
    React.useState(false);

  const [showAutoPidTooltip, setShowAutoPidTooltip] = React.useState(false);
  const [newForm, setNewForm] = React.useState(false);

  const { userSignNowStatus } = values;
  const [userStatus, setUserStatus] = React.useState(null);
  const shouldShowSignNowModal = userStatus !== null && userStatus !== 2;

  const [minorThan768, setMinorThan768] = React.useState(false);

  const { state = {} } = props.location;
  const userId = getQueryString(window.location.href, 'userId');
  const isSS = getQueryString(window.location.href, 'isSS') === 'true';
  const isUserRole =
    userData &&
    userData.Roles &&
    !userData.Roles.includes('Super Admin') &&
    !userData.Roles.includes('Master User') &&
    !userData.Roles.includes('Corporate Admin') &&
    !userData.Roles.includes('Corporate User') &&
    !userData.Roles.includes('Super Master');

  useEffect(() => {
    if (franchiseIdQs) {
      setFranchiseId(franchiseIdQs);
      setValues({ ...values, cityId: franchiseId });
    }
  }, [franchiseIdQs]);

  const { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  React.useEffect(() => {
    const fetch = async () => {
      if (userId) {
        const userData = await axiosInstance.get(
          `GetUserById?userid=${userId}`
        );
        setUserData(userData.data);
      }
    };
    fetch();
  }, [userId]);

  React.useEffect(() => {
    if (!!error && error.status == 401) {
      setIsLoading(false);
      setWarningModal({
        title: 'Access Denied',
        message:
          'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        proceed: () => {
          localStorage.setItem(
            'anago_redirect_after_login',
            window.location.href
          );
          window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
            'login',
            'home'
          );
          // setWarningModal(null);
          // setError(undefined)
        },
      });
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          setIsLoading(false);
          setWarningModal({
            title: 'Attention',
            message:
              'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            proceed: () => {
              window.location.reload(true);
            },
          });
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!!genericError) {
      setIsLoading(false);
      logSentry(genericError, values);
      setWarningModal({
        title: 'Attention',
        message:
          'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        proceed: () => {
          setWarningModal(null);
          setGenericError(undefined);
        },
      });
    }
  }, [genericError]);

  const handleError = (e, showBanner = false) => {
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;

    if (e && e.response && e.response.status == '401') {
      setBlockingModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else if (e && e.response && e.response.status == '409') {
      setWarningModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else if (e && e.response && e.response.status == '503') {
      setWarningModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (showBanner) {
        setShowConnectionWarning(true);
      } else {
        setWarningModal({
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            setWarningModal(null);
          },
        });
      }
    }
  };

  React.useEffect(() => {
    if (state.formValues) {
      setTimeout(() => {
        setAllValues(state.formValues);
        toast.info('The proposal was duplicated successfully.');
        window.scrollTo(0, 0);
      }, 2000);
    }
  }, [state]);

  const setBreakpoints = () => {
    setMinorThan768(window.innerWidth < 768);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    const message =
      'This form is designed to be rendered on iPad sized screens or larger. Utilizing this form on a smaller screen will impact its usability. It is strongly recommended to change to larger device before proceeding.';
    if (minorThan768) {
      setWarningModal({
        message: message,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (warningModal && warningModal.message == message) {
        setWarningModal(null);
      }
    }
  }, [minorThan768]);

  const onOpenSearch = () => {
    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(null);
    setExecuteAutoSave(null);
    setReady(false);

    setShowMenu({
      renderDraftSection: true,
      renderSentSection: true,
      renderSignedSection: true,
      renderTemplateSection: true,
    });
  };

  React.useEffect(() => {
    if (
      userSignNowStatus != null &&
      userSignNowStatus != undefined &&
      userSignNowStatus != userStatus
    ) {
      setUserStatus(userSignNowStatus == 1 ? 2 : userSignNowStatus);
    }
  }, [userSignNowStatus]);

  React.useEffect(() => {
    const openingPDF =
      getQueryString(window.location.href, 'openingPDF') === 'true';

    if (isWebView() && openingPDF) {
      props.history.goBack();
    }
  }, [props.history]);

  const showDeleteConfirmationModal = (
    proceedFunction: Function,
    isPlural: boolean = false
  ) => {
    setWarningModal({
      message: isPlural
        ? 'Are you sure you want to delete these items?'
        : 'Are you sure you want to delete this item?',
      proceed: () => {
        proceedFunction();
        setWarningModal(null);
      },
      dismiss: () => setWarningModal(null),
    });
  };
  const [bidData, setBidData] = useState({ id: '' });
  const [bidFullData, setBidFullData] = useState({});
  const [errors, setErrors] = useState({});

  const onClickDownloadBlankPdf = async (bidId) => {
    const pdfUrl = await getBlankPdfUrl(bidId);
    const w = window.open(pdfUrl.data, '_blank');
    if (isWebView()) {
      setTimeout(() => {
        w.close();
      }, 100);
    }
  };

  React.useEffect(() => {
    if (!userData) return;

    const formId = getQueryString(search, 'formId');
    const urlTabId = getQueryString(search, 'tabId');
    const easterEgg = window.location.href.indexOf('pre-filled') >= 0;

    if (!!urlTabId) {
      setTabId(urlTabId);
    } else {
      setTabId(new Date().toISOString());
    }

    const validTabId = urlTabId || tabId;

    const fetch = async (askIfTemplate = true) => {
      const goGetIt = easterEgg
        ? () => getEasterEggTemplate()
        : uniqueId
        ? () => getByUniqID(uniqueId)
        : () => getBidInfoById(formId);

      const foundValues = await goGetIt();
      setBidFullData(foundValues.data['Data']);
      const parsedValues = parseFromApi(foundValues.data['Data']);
      const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

      if (easterEgg) {
        parsedValues['uid'] = null;
        parsedValues['isTemplate'] = false;
        parsedValues['sentStatus'] = false;
        parsedValues['status'] = 0;
        parsedValues['bidInfoId'] = null;
        parsedValues['originalBidInfoId'] = null;
        parsedValues['createdBy'] = userId;
        parsedValues['userId'] = userId;
        parsedValues['bidDate'] = null;
        parsedValues['isExpired'] = false;
        parsedValues['createdByName'] = userData['Name'];
        parsedValues['expirationDays'] = 90;
      }

      let userHasBetaOptIn = false;
      let userSignNowStatus = -1;

      if (userData['IsOptIn'] != null && userData['IsOptIn'] != undefined) {
        userHasBetaOptIn = userData['IsOptIn'];
      }

      userSignNowStatus =
        userData['SignatureProviderStatus'] != null
          ? userData['SignatureProviderStatus']
          : -1;

      const openForm = (tempValues = false) => {
        const v = tempValues || parsedValues || {};
        setIsLoading(true);
        setTimeout(() => {
          setAllValues({
            ...v,
            userSignNowStatus,
            userHasBetaOptIn,
          });
          setNeedsSaving(false);
          if (isLoading) {
            setIsLoading(false);
          }
          // if (tempValues && v.status !== 2) {
          //   toast.info('Draft Auto-Save Enabled');
          //   startAutoSaveTimeout();
          // }
          setTimeout(() => {
            setAutoSaveTimeout(1);
            setHadChanges(false);
            setReady(true);
            EventEmitter.emit('VALIDATE_FIELDS', '');
          }, 1500);
        }, 1000);

        setShowAlreadySignedModal(v.status === 2);
        setWarningModal(null);
      };

      const continueFlow = async () => {
        if (foundValues.data['Data']['Status'] != 2 && !easterEgg) {
          try {
            if (foundValues.data['Data']['BidUniqueID'] && validTabId) {
              await isTabIdValid(
                foundValues.data['Data']['BidUniqueID'],
                validTabId
              );
            } else {
              await isAlive();
            }
          } catch (e) {
            const msg =
              e &&
              e.response &&
              e.response.data &&
              typeof e.response.data == 'string'
                ? e.response.data
                : undefined;

            setTimeout(() => {
              handleError(e);
            }, 0);
            openForm();
            logSentry(e, values);
            return;
          }
        }

        const tempValues = foundValues.data['DataTemp'];

        if (
          tempValues &&
          foundValues.data['Data']['Status'] != 2 &&
          !easterEgg &&
          !foundValues.data['Data']['IsTemplate']
        ) {
          setIsLoading(false);
          setWarningModal({
            message:
              'We found a draft for this form on the server. Do you want to start from where stopped?',
            proceed: () => {
              setIsLoading(true);

              const parsedTempValues = tempValues
                ? parseFromApi(tempValues)
                : null;

              openForm({
                ...parsedTempValues,
                uid: parsedValues.uid,
                bidInfoId: parsedValues.bidInfoId,
                isTemp: parsedValues.isTemp,
                originalBidUid: parsedValues.originalBidUid,
                sentStatus: false,
                isTemplate: !!parsedValues.isTemplate,
                isAutoSave: false,
              });

              setTempData(parsedTempValues);
              // setShowAutoPidTooltip(true);
            },
            dismiss: async () => {
              setIsLoading(true);

              setTempData(null);

              setWarningModal(null);

              openForm();
            },
            confirmText: 'YES',
            dismissText: 'NO',
          });
        } else {
          if (!!parsedValues.sentStatus) {
            openForm();
          } else {
            openForm();
            setTimeout(() => {
              setTempData(null);
              // setShowAutoPidTooltip(true);
              setAutoSaveTimeout(1);
            }, 1500);
          }
        }
      };

      //   if (parsedValues.bidVersion != 2 && parsedValues.status != 2) {
      //     setIsLoading(false);
      //     setWarningModal({
      //       title: 'WARNING: Removing Bid Overrides',
      //       message:
      //         'This proposal was created using previous version of Anago CleanSource®. Editing this proposal will clear any Bid Overrides entered previously. This action cannot be undone and these Overrides will be lost. Please download a copy of the PDF for reference before proceeding.',
      //       proceed: () => {
      //         onClickDownloadBlankPdf(parsedValues.uid);
      //       },
      //       dismiss: () => {
      //         continueFlow();
      //         // openForm();
      //       },
      //       confirmText: 'Download PDF',
      //       dismissText: 'Continue',
      //       isDismissPrimary: true,
      //     });
      //   } else {
      //     continueFlow();
      //     // openForm();
      //   }
      continueFlow();
    };

    if (formId || uniqueId || easterEgg) {
      try {
        fetch().catch((e) => {
          logSentry(e, { uid: formId || uniqueId || 'easterEgg' });
          if (e.status === 500) {
            props.history.push(`/forms/unable-to-open`);
          }
        });
      } catch (e) {
        setIsLoading(false);
        setWarningModal({
          message:
            'Something wrong happened with this proposal. Please, contact your support.',
          proceed: () => {
            setWarningModal(null);
          },
          dismiss: () => {
            setWarningModal(null);
          },
          confirmText: 'Download PDF',
          dismissText: 'Continue',
          isDismissPrimary: true,
        });
      }
      setNewForm(false);
    } else {
      setTempData(null);
      setAutoSaveTimeout(null);
      setShowAutoPidTooltip(true);
      setNewForm(true);
    }
  }, [search, userData]);

  React.useEffect(() => {
    const hasProtectionCleaningSpecs =
      Object.keys(values.cleaningFrequency).filter(
        (k) =>
          values?.cleaningFrequency?.[k]?.items?.filter((item) =>
            item?.title?.includes?.('Anago Protection+ Disinfection')
          )?.length > 0
      )?.length > 0;

    if (
      (!values.bidVersion || values.bidVersion != 2) &&
      hasProtectionCleaningSpecs &&
      values.status !== 2
    ) {
      setWarningModal({
        title: 'WARNING: Protection+ Change',
        message:
          'This proposal contains Protection+ Disinfection® Specifications from the previous version of CleanSource. To use the new Protection+ Disinfection® feature with this proposal, you must first remove Protection+ specifications from the Cleaning Specs section.',
        proceed: () => {
          setWarningModal(null);
        },
      });
    }
  }, [values.bidVersion]);

  React.useEffect(() => {
    if (franchiseId) {
      getFranchiseById(franchiseId).then(({ data }) => {
        // const newValues = {};
        // if (!values['proposalHolidays']) {
        //   newValues['proposalHolidays'] = data['ProposalHolidays'];
        // }

        // if (!values['proposalSection3']) {
        //   newValues['proposalSection3'] = data['ProposalSection3'];
        // }

        // setAllValues(newValues);
        setFranchiseData(data);
        setTimeout(() => {
          if (newForm) {
            setHadChanges(false);
            setReady(true);
          }
        }, 1000);
      });
    }
  }, [franchiseId]);

  const confirmationProps = {
    isOpen: showConfirmation,
    setWarningModal,
    showDeleteConfirmationModal,
    onSaveButtonClick: async (data) => {
      setIsLoading(true);
      let serverData = await getByUniqID(bidData.id);
      serverData = serverData.data['Data'];
      const values = parseGetToPost(serverData);
      const obj = {
        ...values,
        GenerateBlankPDF: false,
        GenerateAttachmentsPDF: true,
        SelectedAttachments: data.attachments.others,
        SelectedSystemAttachments: data.attachments.systemAttachments,
        SelectedFranchiseAttachments: data.attachments.franchiseAttachments,
        Supply: data.Supply,
        Reference: data.Reference,
        FlgProtectionDisinfectionPlan: data.FlgProtectionDisinfectionPlan,
        FlgSupply: data.FlgSupply,
        FlgSupplyCustomize: data.FlgSupplyCustomize,
        BidInformationSheetSupplys: data.BidInformationSheetSupplys,
        FlgReference: data.FlgReference,
        FlgReferenceCustomize: data.FlgReferenceCustomize,
        BidInformationSheetReferences: data.BidInformationSheetReferences,
        BidInformationSheetSupplyId: data.BidInformationSheetSupplyId,
        CreatedBy: serverData['CreatedBy'] || userId,
      };

      if (data.attachments) {
        if (
          data.attachments.others.filter((i) => i != '' && i != 'industry-na')
            .length === 0 &&
          data.attachments.systemAttachments.length === 0 &&
          data.attachments.franchiseAttachments.length === 0
        ) {
          obj.GenerateAttachmentsPDF = false;
        }
      }

      if (Object.keys(data.attachments.oneTimeAttachment).length > 0) {
        await uploadOneTimeAttachment(
          bidData.id,
          data.attachments.oneTimeAttachment
        );
        obj.IncludeOneTimeAttachment = true;
      }

      const postResponse: IAPIResponse = await postBidInfo(obj);
      const formInfo: IAPIResponse = await getByUniqID(bidData.id);
      const formInfoData = formInfo.data['Data'];
      const emails = data.emailProposal;
      const emailBody = decodeEntities(stripHTMLTags(formInfoData['BidEmail']));

      setBidData(postResponse.data as any);
      setSuccessModalData({
        ...successModalData,
        emails,
        emailBody,
        history: props.history,
        bidLink: formInfoData['BidLink'],
        franchiseId: formInfoData['FranchiseId'],
        formUniqueId: postResponse.data.id,
      });
      setShowSuccessModal(true);
      setIsLoading(false);
    },

    onBackButtonClick: async (data, terms) => {
      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, values);
      }

      setBlockAutoSave(true);
      setReady(false);

      setFieldValue('terms', terms);

      setTimeout(() => {
        setBlockAutoSave(false);
        setReady(ready);
        setShowConfirmationModal(false);
      });
    },

    onCustomizeTermsButtonClick() {},

    success: showSuccessModal,

    successModalData,

    bidData,

    bidFullData,

    franchiseId: franchiseId,

    franchiseData: franchiseData,
  };

  const doPost = async (
    values,
    callback = () => undefined,
    showToastr = true
  ) => {
    const response = await updateBid({
      values,
      userId,
      showToastr,
    });

    if (response.error) {
      setWarningModal({
        message: response.error as string,
        proceed: () => {
          setWarningModal(null);
        },
      });
      return false;
    }

    callback();

    if (response.data) {
      // needs refactoring
      const resp = await getByUniqID(response.data.id);
      setBidFullData(resp.data.Data);
      setFieldValue('uid', response.data.id);

      setAllValues({
        ...values,
        uid: response.data.id,
        sentStatus: false,
        isTemplate: false,
      });

      setBidData(response.data as any);
      setSuccessModalData({
        ...successModalData,
        formUniqueId: response.data.id,
        userId,
      });
      return true;
    }
  };

  const openProposalPDF = () => {
    // const url = `${getBaseURL()}/Pdf/Bid-Signed/AnagoProposal-${
    //   values.uid
    // }.pdf`;
    // window.location.href = url;

    // if (isWebView()) {
    //   props.history.push(
    //     `/bid-info-form/new-pdf-view?formUniqueId=${values.uid}&openingNewBidPDF=true`
    //   );
    // } else {
    //   window.open(
    //     `${getBaseURL()}/Pdf/Bid-Signed/AnagoProposal-${values.uid}.pdf`,
    //     '_blank'
    //   );
    //   // props.history.push(
    //   //   `/bid-info-form/new-pdf-view?formUniqueId=${values.uid}&openingNewBidPDF=true`
    //   // );
    // }

    setShowPdf(true);
  };

  const clearCleaningInconsistentValues = (values) => {
    console.log(values.cleaningFrequency, 'values.cleaningFrequency');
    Object.keys(values.cleaningFrequency).map((key) => {
      const cf = values.cleaningFrequency[key];
      const hasValidCa =
        cf?.items?.filter(
          (ca) =>
            ca?.title?.trim() !== '' &&
            ca?.frequencyTypes?.filter((ft) => ft.value.trim() != '').length > 0
        ).length > 0;
      const invalidCas = cf.items.filter(
        (ca) =>
          ca?.title?.trim() === '' &&
          ca?.frequencyTypes?.filter((ft) => ft.value.trim() === '').length ==
            ca?.frequencyTypes?.length
      );

      if (invalidCas.length > 0 && hasValidCa) {
        for (var i = cf.items.length - 1; i >= 0; i--) {
          const ca = cf.items[i];
          if (
            ca.title.trim() === '' &&
            ca.frequencyTypes.filter((ft) => ft.value.trim() === '').length ==
              ca.frequencyTypes.length
          ) {
            cf.items.splice(i, 1);
          }
        }
      }
    });
  };

  const clearProtectionInconsistentValues = (values) => {
    Object.keys(values.protectionSpecification).map((key) => {
      const ps = values.protectionSpecification[key];
      const hasValidCa =
        ps.items.filter((ca) => ca.title.trim() !== '').length > 0;
      const invalidCas = ps.items.filter((ca) => ca.title.trim() === '');

      if (invalidCas.length > 0 && hasValidCa) {
        for (var i = ps.items.length - 1; i >= 0; i--) {
          const ca = ps.items[i];
          if (ca.title.trim() === '') {
            ps.items.splice(i, 1);
          }
        }
      }
    });
  };

  const onCompleteButtonClick = async (
    saveAndComplete = true,
    { isOverwritten = false, isCityTemplate, isTemplate, isSaveAs } = {},
    callback = () => {}
  ) => {
    try {
      setBlockAutoSave(true);
      setReady(false);

      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        console.log('----- e- -----', e);
        handleError(e);

        callback();
        setBlockAutoSave(false);
        logSentry(e, values);
        return;
      }

      if (values.status === 2) {
        console.log('----- values.status- -----', values.status);
        openProposalPDF();
        callback();
        setBlockAutoSave(false);
        return;
      }

      if (
        values.quotes.length == 1 &&
        JSON.stringify(values.quotes[0]) ==
          JSON.stringify(additionalQuotesValues.quotes[0])
      ) {
        values.quotes = [];
      }

      setIsLoading(true);
      const newValues = JSON.parse(JSON.stringify(values));

      const hasCleaningSpecs = !!sessionStorage.getItem(
        'sessionStorageCleaningSpecification'
      );

      const cleanSpecObject = JSON.parse(
        sessionStorage.getItem('sessionStorageCleaningSpecification')
      );

      if (hasCleaningSpecs && !!cleanSpecObject) {
        newValues.cleaningFrequency = cleanSpecObject;
      }

      clearCleaningInconsistentValues(newValues);
      clearProtectionInconsistentValues(newValues);

      const errors = validateSaveTemplate(newValues);
      const hasErrors =
        errors.required.length > 0 || errors.incorrect.length > 0;
      setErrors(hasErrors ? errors : {});
      if (hasErrors) {
        setIsLoading(false);
        callback();
        setBlockAutoSave(false);
        return;
      }

      if (newValues.uid && saveAndComplete) {
        try {
          const blankPdfResult = await checkIsGeneratingPDF(newValues.uid);
        } catch (e) {
          const generating = e.data['isGenerating'];
          if (generating) {
            setWarningModal({
              message:
                'Previous PDF Version still being generated. Please try again.',
              proceed: () => {
                setWarningModal(null);
              },
            });
            callback();
            setBlockAutoSave(false);
            setIsLoading(false);
            return;
          }
        }
      }

      clearTimeout(autoSaveTimeout);
      setAutoSaveTimeout(null);
      setExecuteAutoSave(null);

      newValues.FranchiseId = Number(franchiseId);
      newValues.franchiseId = Number(franchiseId);
      newValues.cityId = Number(franchiseId);
      newValues.tabId = tabId;
      newValues.isSpecialty = isSS;
      // Cleaning Background Data
      if (newValues.frequencyService === 'monthly') {
        newValues.frequency = newValues.secondFrequency = {
          selecteds: [],
          timeWindow: null,
        };
        newValues.addFrequency = false;
        newValues.smartClean = false;
        newValues.disinfection = null;
        newValues.secondDisinfection = null;
      } else {
        newValues.cleaning = null;
        newValues.secondCleaning = null;
        newValues.timeWindow = null;
        newValues.secondTimeWindow = null;
        newValues.monthlyDisinfection = null;
        newValues.secondMonthlyDisinfection = null;
        newValues.monthlyAddFrequency = null;
      }

      if (newValues.uid && newValues.isTemplate) {
        newValues.uid = null;
      }

      newValues.sentStatus = false;
      newValues.isTemplate = isTemplate;
      newValues.isCityTemplate = isCityTemplate;

      if (saveAndComplete) {
        newValues.generateBlankPDF = true;
      } else {
        newValues.generateBlankPDF = false;
      }

      //set TabId before save
      newValues.tabId = tabId;
      newValues.bidVersion = 2;
      newValues.isAutoSave = false;

      if (Object.entries(bidFullData).length > 0) {
        newValues.FlgSupply = bidFullData.FlgSupply;
        newValues.FlgSupplyCustomize = bidFullData.FlgSupplyCustomize;
        newValues.BidInformationSheetSupplys =
          bidFullData.BidInformationSheetSupplys;

        newValues.FlgReference = bidFullData.FlgReference;
        newValues.FlgReferenceCustomize = bidFullData.FlgReferenceCustomize;
        newValues.BidInformationSheetReferences =
          bidFullData.BidInformationSheetReferences;

        newValues.FlgProtectionDisinfectionPlan =
          bidFullData.FlgProtectionDisinfectionPlan;
      }
      const result = await doPost(
        {
          ...newValues,
          BidUniqueID: isOverwritten
            ? values.newBidUniqueID || bidFullData.BidUniqueID
            : null,
          uid: isOverwritten ? values.uid : null,
          isOverwritten,
        },
        () => {},
        false
      );

      setAllValues(newValues);

      setHadChanges(false);

      setIsLoading(false);

      if (result) {
        setTempData(null);
        if (saveAndComplete) {
          setShowConfirmationModal(true);
        }

        let successMessage = '';

        if (newValues.isCityTemplate) {
          if (isOverwritten) {
            successMessage = 'City Template was overwritten successfully';
          } else {
            successMessage =
              'City Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
          }
        } else if (newValues.isTemplate) {
          if (isOverwritten) {
            successMessage = 'My Template was overwritten successfully';
          } else {
            successMessage =
              'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
          }
        } else if (newValues.sentStatus) {
          successMessage = 'Your sent proposal was saved successfully';
        } else {
          successMessage = 'Your draft was saved successfully';
        }

        toast.info(successMessage);
        // setTimeout(() => {
        //   window.location.href = `${process.env.process.env.REACT_APP_BACKEND_URL}/templates?isCityTemplate=${newValues.isCityTemplate}`;
        // }, 3000);
        callback();

        setTimeout(() => {
          setBlockAutoSave(false);
          setAutoSaveTimeout(1);
          setReady(true);
        }, 0);
      } else {
        callback();
      }
    } catch (e) {
      console.log('----- e2 -----', e);
      setError(e);
    }
  };

  const onSaveDraft = React.useCallback(
    async (isAutoSave = false, tempData = null) => {
      let v = tempData || values;
      if (Object.entries(bidFullData).length > 0) {
        v.FlgSupply = bidFullData.FlgSupply;
        v.FlgSupplyCustomize = bidFullData.FlgSupplyCustomize;
        v.BidInformationSheetSupplys = bidFullData.BidInformationSheetSupplys;

        v.FlgReference = bidFullData.FlgReference;
        v.FlgReferenceCustomize = bidFullData.FlgReferenceCustomize;
        v.BidInformationSheetReferences =
          bidFullData.BidInformationSheetReferences;

        v.FlgProtectionDisinfectionPlan =
          bidFullData.FlgProtectionDisinfectionPlan;
      }

      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e, isAutoSave);
        logSentry(e, values);
        return { error: true };
      }

      setIsLoading(true);

      if (
        v &&
        v.quotes &&
        v.quotes.length == 1 &&
        JSON.stringify(v.quotes[0]) ==
          JSON.stringify(additionalQuotesValues.quotes[0])
      ) {
        v.quotes = [];
      }

      //set TabId before save
      v.tabId = tabId;
      v.userId = userId;

      if (!isAutoSave) {
        v.isAutoSave = false;
      }

      setCompleteButtonEnabled(false);
      const response = await autoSave(v, userId, isAutoSave);
      setCompleteButtonEnabled(true);
      setHadChanges(false);
      setReady(false);
      if (response.data) {
        if (!isAutoSave) {
          setFieldValue('uid', response.data.id);
          setBidData(response.data as any);
        } else {
          setFieldValue('isAutoSave', true);
        }
      }

      if (!isAutoSave) {
        setIsLoading(false);
      }

      setTimeout(() => {
        setReady(true);
      }, 0);

      return response;
    },
    [
      values,
      isAlive,
      isTabIdValid,
      setWarningModal,
      setIsLoading,
      saveDraft,
      setHadChanges,
      setFieldValue,
      setBidData,
      bidFullData,
      setShowConnectionWarning,
      showConnectionWarning,
    ]
  );

  const onSaveTemplate = async (
    isCityTemplate = false,
    isSaveAs = false,
    isOverwritten = false
  ) => {
    setBlockAutoSave(true);
    setExecuteAutoSave(null);

    setDuplicateReferenceName(false);

    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    try {
      const newValues =
        isCityTemplate && !isCityUser
          ? JSON.parse(JSON.stringify(values))
          : values;

      if (isCityTemplate && !isCityUser) {
        newValues.uid = null;
      }

      try {
        if (newValues.uid && tabId) {
          await isTabIdValid(newValues.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, newValues);
        return;
      }

      const proceed = async () => {
        try {
          setWarningModal(null);
          setIsLoading(true);

          if (
            newValues.quotes.length == 1 &&
            JSON.stringify(newValues.quotes[0]) ==
              JSON.stringify(additionalQuotesValues.quotes[0])
          ) {
            newValues.quotes = [];
          }

          let needToOverwritten = false;

          if (isOverwritten) {
            if (
              (bidFullData.IsCityTemplate && isCityTemplate) ||
              (bidFullData.IsTemplate && !isCityTemplate) ||
              (!bidFullData.IsTemplate && !bidFullData.IsCityTemplate)
            ) {
              needToOverwritten = true;
            }
          }

          onCompleteButtonClick(false, {
            isOverwritten: needToOverwritten,
            isCityTemplate,
            isTemplate: true,
            isSaveAs,
          });
        } catch (err) {
          console.log(err, 'err');
          setGenericError(err);
        }
      };

      if (values.status == 2) {
        if (values.bidVersion == 1) {
          setWarningModal({
            title: 'WARNING: Removing Bid Overrides',
            message: (
              <>
                This proposal was created using previous version of Anago
                CleanSource®. Save this proposal as template will clear any Bid
                Overrides entered previously.
              </>
            ),
            dismiss: () => setWarningModal(null),
            proceed: proceed,
          });
          return;
        }
      }

      proceed();
    } catch (e) {
      console.log(e, 'e');
      setGenericError(e);
    }
  };

  const hasProtectionCleaningSpecs =
    Object.keys(values.cleaningFrequency).filter(
      (k) =>
        values?.cleaningFrequency?.[k]?.items?.filter((item) =>
          item?.title?.includes?.('Anago Protection+ Disinfection')
        )?.length > 0
    )?.length > 0;

  /// if is new

  React.useEffect(() => {
    if (executeAutoSave && !blockAutoSave && values.status !== 2) {
      if (!values.referenceName) {
        setTimeout(() => {
          startAutoSaveTimeout();
        });
        return;
      }

      const save = async () => {
        const hasTemp = !!tempData && Object.keys(tempData).length > 0;
        let tValues = null;
        if (hasTemp) {
          tValues = {
            ...values,
            uid: tempData.uid,
            bidInfoId: tempData.bidInfoId,
            originalBidUid: tempData.originalBidUid,
            isTemp: true,
            userId: userId,
            isAutoSave: false,
          };
        } else if (values.uid && !!values.sentStatus) {
          tValues = {
            ...values,
            uid: null,
            bidInfoId: null,
            originalBidUid: values.uid,
            isTemp: true,
            userId: userId,
            isAutoSave: false,
          };
        } else {
          tValues = { ...values, userId: userId, isAutoSave: false };
        }

        if (
          (hasTemp && !compareValues(tValues, tempData)) ||
          (!hasTemp && hadChanges)
        ) {
          let response;

          if (!values.isTemplate && !values.isCityTemplate) {
            response = await onSaveDraft(true, { ...tValues });

            if (!response.error) {
              // console.error('saved draft! id:' + response.data.id);
              if (!hasTemp && !values.sentStatus) {
                setFieldValue('uid', response.data.id);
              } else {
                tValues.uid = response.data.id;
                setTempData(tValues);
              }
              setTimeout(() => {
                setHadChanges(false);
              }, 0);
            } else {
              if (response.error !== true) {
                // toast.error('Error trying to Auto-Save');
                console.error('Error:' + response.error);

                if (response.status == '409') {
                  setTempData(null);
                  setAutoSaveTimeout(null);
                  setExecuteAutoSave(null);
                  clearTimeout(autoSaveTimeout);
                  setBlockAutoSave(true);

                  setWarningModal({
                    title: 'Warning!',
                    message:
                      'Autosave did not complete correctly. Please manually save this form to prevent loss of any data.',
                    proceed: () => setWarningModal(null),
                  });
                }
              }
            }
          }
        }
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      };
      save();
    }
  }, [executeAutoSave]);

  const startAutoSaveTimeout = React.useCallback(() => {
    // console.error('starting auto-save timeout');
    setAutoSaveTimeout(
      setTimeout(() => {
        if (!blockAutoSave) {
          setExecuteAutoSave(null);
        }
      }, 10 * 1000)
    );
  }, [hadChanges, blockAutoSave]);

  const initAutoSave = async () => {
    if (values.isTemplate) {
      return;
    }

    setShowEmptyReferenceNameWarning(false);
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e, true);
      logSentry(e, values);
      if (!executeAutoSave) {
        setBlockAutoSave(false);
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      }
      return;
    }

    setShowConnectionWarning(false);
  };

  const initAutoSaveOnBlur = () => {
    if (values.status != 2) {
      if (values.referenceName && !showEmptyReferenceNameWarning) {
        if (!autoSaveTimeout || autoSaveTimeout == 1) {
          initAutoSave();
        }
      }
    }
  };

  React.useEffect(() => {
    if (ready) {
      if (values.status != 2 && (!hadChanges || showConnectionWarning)) {
        setHadChanges(true);
        if (autoSaveTimeout == 1) {
          if (values.uid && !showConnectionWarning) {
            if (!values.referenceName) {
              setShowEmptyReferenceNameWarning(true);
            } else {
              initAutoSave();
            }
          }
        } else if (autoSaveTimeout == null) {
          if (!values.referenceName && newForm) {
            setShowEmptyReferenceNameWarning(true);
          } else if (newForm && !showConnectionWarning) {
            initAutoSave();
          }
        }
      }
    }
  }, [values]);

  React.useEffect(() => {
    return () => {
      setAutoSaveTimeout(null);
      setExecuteAutoSave(null);
      clearTimeout(autoSaveTimeout);
    };
  }, []);

  /// end

  React.useEffect(() => {
    (async () => {
      try {
        const result = await verifyServerUnavailable(userId);
        if (!result || result.error) {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        } else {
          setBlockingModal(null);
        }
      } catch (e) {
        const errorResponse = e && e.response ? e.response : e;
        if (errorResponse.status == '503') {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        }
      }
    })();
  }, []);

  return (
    <FormProvider
      needsSaving={needsSaving}
      setNeedsSaving={setNeedsSaving}
      formValues={values}
      onChange={setFieldValue}
      setFormValues={setAllValues}
      showWarningModal={setWarningModal}
      showConfirmationModal={setShowConfirmationModal}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    >
      <div className="inspection-root-div bid-form-root">
        <div className="inspection-content-screen bid-form-content-screen">
          {isLoading && <LoadingPage />}

          <ActionAlertModal
            message="This form has already been signed, and cannot be changed."
            isVisible={showAlreadySignedModal && !shouldShowSignNowModal}
            onConfirm={() => {
              setShowAlreadySignedModal(false);
            }}
          />

          {blockingModal && (
            <BlockingOverlay>
              <BlockingPopupModal message={blockingModal.message} />
            </BlockingOverlay>
          )}

          {shouldShowSignNowModal && (
            <BlockingOverlay>
              <SignNowScreen
                userId={userId}
                status={userStatus}
                setStatus={setUserStatus}
                setLoading={setIsLoading}
              />
            </BlockingOverlay>
          )}
          <TemplateHeader
            showConnectionWarning={showConnectionWarning}
            showEmptyReferenceNameWarning={showEmptyReferenceNameWarning}
            setShowEmptyReferenceNameWarning={setShowEmptyReferenceNameWarning}
            showAutoPidTooltip={showAutoPidTooltip}
            onExit={() => setShowExit(true)}
            franchiseId={franchiseId}
            openSearch={onOpenSearch}
            setIsLoading={setIsLoading}
            setNeedsSaving={setNeedsSaving}
            initAutoSave={initAutoSaveOnBlur}
            title="Bid Information Sheet"
            searchButtonLabel="Open Bid forms"
          />
          {isSS && (
            <>
              <ServiceProvider />
              <RoomInventory isSpecialty />
              <FloorTypeTotals />
            </>
          )}

          {!isSS && (
            <>
              <AccountInfo />
              <AdditionalInformation />
              <SectionGroup>
                <ServiceArea
                  title="SERVICE AREAS"
                  sectionId="serviceAreasSection"
                />
              </SectionGroup>
              <RoomInventory />
              <FloorTypeTotals />
              <RestroomFixtures />
              <ProductionRates />
              <CleaningSpecification />
              <DailyManHours />
            </>
          )}
          {!isSS && (
            <SectionGroup title="ADDITIONAL PRODUCTS & SERVICES">
              <Supplies
                onChange={(newBidFullData) => {
                  setFieldValue('flgSupply', newBidFullData.FlgSupply);
                  setFieldValue(
                    'flgSupplyCustomize',
                    newBidFullData.FlgSupplyCustomize
                  );
                  setFieldValue(
                    'bidInformationSheetSupplys',
                    newBidFullData.BidInformationSheetSupplys
                  );
                  setBidFullData(newBidFullData);
                }}
                franchiseId={franchiseId}
                title="SUPPLIES"
                sectionId="suppliesSection"
                bidFullData={bidFullData}
                canEdit={userData && userData.IsSuppliesAddEdit}
                shouldHideToggle
              />
              <References
                onChange={(newBidFullData) => {
                  setFieldValue('flgReference', newBidFullData.FlgReference);
                  setFieldValue(
                    'flgReferenceCustomize',
                    newBidFullData.FlgReferenceCustomize
                  );
                  setFieldValue(
                    'bidInformationSheetReferences',
                    newBidFullData.BidInformationSheetReferences
                  );
                  setBidFullData(newBidFullData);
                }}
                franchiseId={franchiseId}
                title="GOOGLE REVIEWS, REFERENCES, & TESTIMONIALS"
                sectionId="referencesSection"
                bidFullData={bidFullData}
                canEdit={userData && userData.IsReferenceAddEdit}
                shouldHideToggle
              />
              <DayPorter
                title="DAY PORTER SERVICES"
                sectionId="dayPorterSection"
              />
              <ProtectionDisinfectionPlans
                bidFullData={bidFullData}
                sectionId="protectionDisinfectionSection"
                onChangeFlag={(newBidFullData) => {
                  setFieldValue(
                    'flgProtectionDisinfectionPlan',
                    newBidFullData.FlgProtectionDisinfectionPlan
                  );
                  setBidFullData(newBidFullData);
                }}
                title="ANAGO PROTECTION+ DISINFECTION® PLAN(S)"
                disabled={
                  (values.smartClean &&
                    values.addFrequency &&
                    values.frequencyService == 'weekly') ||
                  hasProtectionCleaningSpecs
                }
                shouldKeepHidden={
                  values.smartClean || hasProtectionCleaningSpecs
                }
                canOpenSection={() => {
                  if (!values.smartClean && !hasProtectionCleaningSpecs) {
                    return true;
                  }
                  if (values.smartClean && values.addFrequency) {
                    setWarningModal({
                      message:
                        'Protection+ is not available when SmartClean is selected.',
                      proceed: () => {
                        setWarningModal(null);
                      },
                    });
                    return false;
                  }
                  if (hasProtectionCleaningSpecs) {
                    setWarningModal({
                      message:
                        'Protection+ is not available when has protection specification inside cleaning specification.',
                      proceed: () => {
                        setWarningModal(null);
                      },
                    });
                    return false;
                  }
                }}
              />
              <AdditionalQuotes
                title="SPECIALTY SERVICE QUOTE(S)"
                sectionId="additionalQuotesSection"
                isSpecialty={isSS}
                isTemplateScreen
              />
            </SectionGroup>
          )}

          {isSS && (
            <SectionGroup title="ADDITIONAL PRODUCTS & SERVICES">
              <Supplies
                onChange={(newBidFullData) => {
                  setFieldValue('flgSupply', newBidFullData.FlgSupply);
                  setFieldValue(
                    'flgSupplyCustomize',
                    newBidFullData.FlgSupplyCustomize
                  );
                  setFieldValue(
                    'bidInformationSheetSupplys',
                    newBidFullData.BidInformationSheetSupplys
                  );
                  setBidFullData(newBidFullData);
                }}
                franchiseId={franchiseId}
                title="SUPPLIES"
                sectionId="suppliesSection"
                isSpecialty
                bidFullData={bidFullData}
                canEdit={userData && userData.IsSuppliesAddEdit}
                shouldHideToggle
              />
              <References
                onChange={(newBidFullData) => {
                  setFieldValue('flgReference', newBidFullData.FlgReference);
                  setFieldValue(
                    'flgReferenceCustomize',
                    newBidFullData.FlgReferenceCustomize
                  );
                  setFieldValue(
                    'bidInformationSheetReferences',
                    newBidFullData.BidInformationSheetReferences
                  );
                  setBidFullData(newBidFullData);
                }}
                franchiseId={franchiseId}
                title="GOOGLE REVIEWS, REFERENCES, & TESTIMONIALS"
                sectionId="referencesSection"
                bidFullData={bidFullData}
                canEdit={userData && userData.IsReferenceAddEdit}
                shouldHideToggle
              />
              <ProtectionDisinfectionPlansSs
                bidFullData={bidFullData}
                onChangeFlag={(newBidFullData) => {
                  setFieldValue(
                    'flgProtectionDisinfectionPlan',
                    newBidFullData.FlgProtectionDisinfectionPlan
                  );
                  setBidFullData(newBidFullData);
                }}
                title="ANAGO PROTECTION+ DISINFECTION® PLAN(S)"
              />
            </SectionGroup>
          )}

          {!isSS && <BidInformation isTemplateScreen />}
          {isSS && <AdditionalQuotes isSpecialty isTemplateScreen />}
          <TemplateFooter
            onCompleteButtonClick={onCompleteButtonClick}
            completeButtonEnabled={completeButtonEnabled}
            saveDraft={onSaveDraft}
            isUserRole={isUserRole}
            isSpecialty={isSS}
            saveTemplate={onSaveTemplate}
          />
        </div>
      </div>

      {warningModal && (
        <ActionAlertModal
          title={warningModal.title}
          style={warningModal.style}
          message={warningModal.message}
          onConfirm={warningModal.proceed}
          onDismiss={warningModal.dismiss}
          isDismissPrimary={warningModal.isDismissPrimary}
          dismissText={warningModal.dismissText}
          confirmText={warningModal.confirmText}
          isVisible={true}
        />
      )}
      {duplicateReferenceName && (
        <ActionAlertModal
          title={`Uh oh, it appears that you already have a template named ${values.referenceName}`}
          message="If you do not wish to overwrite, please change the template name before proceeding"
          confirmText="OVERWRITE"
          titleStyle={{
            textTransform: 'inherit',
            fontSize: '22px',
            marginBottom: 0,
          }}
          onConfirm={() =>
            onSaveTemplate(isCurrentCityTemplate, isCurrentSaveAs, true)
          }
          onDismiss={() => {
            setDuplicateReferenceName(false);
          }}
          isVisible={true}
        />
      )}
      <ActionAlertModal
        title="Exit without saving?"
        message="Remember, that if you exit without saving, you will lose all progress."
        onConfirm={() => {
          setShowExit(true);

          if (isWebView()) {
            props.history.push('/close-form');
          } else {
            document.location.href = getBidURL();
          }
        }}
        onDismiss={() => setShowExit(false)}
        isVisible={showExit}
      />

      {/* <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors({})}
        isVisible={Object.keys(errors).length > 0}
      />
 */}
      {showMenu && (
        <GenericModal
          isOpen={showMenu}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="710px"
          width="600px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <BidFormMenuScreen
            {...props}
            showBackground={false}
            {...showMenu}
            doneAction={(state) => {
              setShowMenu(null);
              setIsLoading(state);
              if (!state) {
                setAutoSaveTimeout(1);
                setExecuteAutoSave(null);
                setReady(true);
              }
            }}
          />
        </GenericModal>
      )}

      {showPdf && (
        <GenericModal
          isOpen={showPdf}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="100%"
          width="100%"
          // maxWidth="1200px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <RenderPdf
            onClickClose={() => setShowPdf(false)}
            pdfName={`AnagoAgreement_${values.referenceName}_${values.uid}`}
            type={11}
            uid={values.uid}
          />
        </GenericModal>
      )}

      {showConfirmation && <ConfirmationModal {...confirmationProps} />}
      {warningCityTemplate && (
        <ActionAlertModal
          renderMessage={() => (
            <span
              className="required-field"
              style={{ textAlign: 'center', display: 'block' }}
            >
              Any changes made to a City Template must be saved under{' '}
              <span style={{ textDecoration: 'underline ' }}>My Templates</span>
            </span>
          )}
          titleStyle={{
            fontFamily: 'OpenSans-Regular',
            fontSize: '18px',
            textTransform: 'initial',
          }}
          title="You are not authorized to edit City Templates"
          confirmText="CLOSE"
          onConfirm={() => {
            setWarningCityTemplate(false);
          }}
          isVisible={true}
        />
      )}
    </FormProvider>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...defaultValues,
    quotes: [],
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const FormEnhanced = withFormik<any, IBidInfoForm>(mapForm)(
  withRouter(NewTemplate)
);

export { FormEnhanced as NewTemplate };
