import styled from 'styled-components';

export const IconButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid gray;
  cursor: pointer;
  min-width: 40px;

  ${(props) =>
    props.outline
      ? `background-color: transparent; border: 3px solid gray; font-weight: normal; color: gray;`
      : ''}
`;

export const IconWrapper = styled.span``;

export const CaptionWrapper = styled.span`
  display: flex;
  width: 100%;
  box-align: center;
  align-items: center;
  box-pack: center;
  justify-content: center;
  font-family: OpenSans-SemiBold;
  font-size: 8px;
  border-bottom: 1px solid gray;
`;
