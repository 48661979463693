import React from 'react';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PreviewItem } from './PreviewItem';
import Image1 from './assets/supplies-header-proposal.png';

interface SupplyDetail {
  SupplyDetailId: number | string;
  SupplyId: number | string;
  SupplyCategory: string;
  BrandInfo: string;
  Unit: string;
  Price: number;
  LastUpdate: string;
  UserId: number | string;
  Order: number;
  UserFirstName?: string;
  UserLastName?: string;
  Default: boolean;
}

interface PreviewContentProps {
  supplies: SupplyDetail[];
  introductionText: string;
  withDrag?: boolean;
  onDragEnd?: (item: any) => void;
  className?: string;
  withShadow?: boolean;
  showPrice?: boolean;
}

export const PreviewContent: React.FC<PreviewContentProps> = ({
  supplies = [],
  introductionText,
  className,
  withDrag = true,
  onDragEnd,
  showPrice,
  withShadow,
}) => {
  const renderIntroductionText = () => {
    const text = introductionText ? introductionText.split(/\r\n|\r|\n/) : [];
    return text.map((item, i) => (
      <>
        <span>{item}</span>
        {i < text.length - 1 && <br />}
      </>
    ));
  };

  const reorder = (
    list: SupplyDetail[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      // change background colour if dragging
      opacity: isDragging ? 0.3 : 1,
      width: '100%',
      background: '#ffffff',

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = () => ({
    width: '100%',
  });

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      supplies,
      result.source.index,
      result.destination.index
    );

    if (onDragEnd) {
      onDragEnd(items);
    }
  };

  return (
    <div className={classNames(className)}>
      <div className="preview-images">
        <img src={Image1} />
      </div>
      <div
        className={classNames('preview-content', {
          'with-shadow': withShadow,
        })}
      >
        <div className="preview-introduction-text">
          <h4>{renderIntroductionText()}</h4>
        </div>
        <div>
          <table className="table">
            <thead className="table-table">
              <tr className="table-tr">
                {withDrag && <th></th>}
                <th>Item</th>
                <th>Description</th>
                <th>Unit(s)</th>
                {showPrice && <th>Price*</th>}
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable2">
                {(provided) => (
                  <tbody
                    className="table-tbody"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle()}
                  >
                    {supplies.map((supplyDetailItem, index) => (
                      <Draggable
                        key={`item-each-${supplyDetailItem.SupplyDetailId}`}
                        draggableId={`item-each-${supplyDetailItem.SupplyDetailId}`}
                        index={index}
                        isDragDisabled={!withDrag}
                      >
                        {(provided, snapshot) => (
                          <tr
                            className="table-tr drag-table"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <PreviewItem
                              withDrag={withDrag}
                              supplyDetailItem={supplyDetailItem}
                              showPrice={showPrice}
                            />
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
        {showPrice && (
          <div style={{ padding: '20px 0', fontWeight: 'bold' }}>
            * Prices are subject to change
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewContent;
