export const getRatingClassName = (rating: number) => {
  /**
   * Color coding goes as followed:
      1 to 1.5 = Above Standard (green)
      1.6 to 2.5 = Standard (blue)
      2.6 to 3.5 = Needs Attention (gold)
      3.6 to 4.5 = Schedule Not Followed (orange)
      4.6 to 5 = Fail (red)
   */
  let areaRatingClassName = '';
  if (rating >= 1 && rating < 1.6) {
    areaRatingClassName = 'area-one';
  } else if (rating >= 1.6 && rating < 2.6) {
    areaRatingClassName = 'area-two';
  } else if (rating >= 2.6 && rating < 3.6) {
    areaRatingClassName = 'area-three';
  } else if (rating >= 3.6 && rating < 4.6) {
    areaRatingClassName = 'area-four';
  } else if (rating >= 4.6 && rating <= 5.0) {
    areaRatingClassName = 'area-five';
  } else {
    areaRatingClassName = 'area-rating-color-neutral';
  }
  return areaRatingClassName;
};

// ranges to push marker to the left
const rangesObj = {
  1.0: '12.5%',
  1.1: '13.95%',
  1.2: '15.85%',
  1.3: '17.75%',
  1.4: '19.65%',
  1.5: '21.55%',
  1.6: '23.45%',
  1.7: '25.35%',
  1.8: '27.25%',
  1.9: '29.15%',
  2.0: '31.5%',
  2.1: '32.95%',
  2.2: '34.85%',
  2.3: '36.75%',
  2.4: '38.65%',
  2.5: '40.55%',
  2.6: '42.45%',
  2.7: '44.35%',
  2.8: '46.25%',
  2.9: '48.15%',
  3.0: '50.5%',
  3.1: '51.95%',
  3.2: '53.85%',
  3.3: '55.75%',
  3.4: '57.65%',
  3.5: '59.55%',
  3.6: '61.45%',
  3.7: '63.35%',
  3.8: '65.25%',
  3.9: '67.15%',
  4.0: '69.5%',
  4.1: '70.95%',
  4.2: '72.85%',
  4.3: '74.75%',
  4.4: '76.65%',
  4.5: '78.55%',
  4.6: '80.45%',
  4.7: '82.35%',
  4.8: '84.25%',
  4.9: '86.15%',
  5.0: '88.5%',
};

export const adjustRating = (rating: number) => {
  const fixed = rating ? parseFloat(rating?.toFixed?.(1)) : rating;

  return rangesObj[fixed];
};
