import React from 'react';
import RenderPdf from 'core/components/RenderPdf';
import { getQueryString } from 'core/utils';

import { Content } from 'modules/BidInfoForm/components/atoms';
import { getBase64ByParams } from '../../services/bidInfoService';

export const PdfPreview = (props) => {
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(false);
  const [pid, setPid] = React.useState();
  const [uid, setUid] = React.useState();
  const [type, setType] = React.useState();

  const setBreakpoints = () => {
    setBiggerThan796(window.innerWidth >= 796);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    const uniqueId = getQueryString(window.location.href, 'uid');
    setUid(uniqueId);

    const type = getQueryString(window.location.href, 'typepdf');
    setType(type);

    const pdfUrlParam = getQueryString(window.location.href, 'pdfurl');
    setPdfUrl(pdfUrlParam);

    const formPid = getQueryString(window.location.href, 'pid');
    setPid(formPid);

    if (type && uniqueId && !pdfUrlParam) {
      (async () => {
        const { data } = await getBase64ByParams(type, uniqueId, false);
        setPdfUrl(data['URL']);
        setPid(data['PID']);
      })();
    }
  }, []);

  let pdfName = '';

  switch (type) {
    case '10':
      pdfName = `${pid}_AnagoProposal`;
      break;
    case '11':
      pdfName = `${pid}_AnagoAgreement`;
      break;
    case '12':
      pdfName = `${pid}_AnagoSpSvcCompleted`;
      break;
    case '13':
      pdfName = `${pid}_AnagoAgreement`;
      break;
    case '20':
      pdfName = `${pid}_SpSvcProposal`;
      break;
    case '21':
      pdfName = `${pid}_SpSvcAgreement`;
      break;
    case '22':
      pdfName = `${pid}_SpSvcCompleted`;
      break;
    case '23':
      pdfName = `${pid}_SpSvcAgreement`;
      break;
    case '30':
      pdfName = `${pid}_InspectionPics`;
      break;
    case '31':
      pdfName = `${pid}_Inspection`;
      break;
    case '41':
      pdfName = `${pid}_PerfEvalCompleted`;
      break;
    case '42':
      pdfName = `${pid}_PerfEval`;
      break;
    default:
      pdfName = `${pid}_Document`;
      break;
  }

  console.log('pdf name: ', pdfName);

  return (
    <>
      <Content padding="30px" maxWidth="720px" margin="auto">
        <RenderPdf
          onClickClose={null}
          pdfName={decodeURIComponent(pdfName).replace(/\&/, ' ')}
          type={type}
          uid={uid}
          pdfUrl={pdfUrl}
        />
      </Content>
    </>
  );
};
