import styled from 'styled-components';
import { ILayout } from '../interfaces';

const commonLayout = (props: ILayout) => `
    ${props.maxHeight ? `max-height: ${props.maxHeight}` : ''};
    ${props.borderRadius ? `border-radius: ${props.borderRadius}` : ''};
    ${props.justifyContent ? `justify-content: ${props.justifyContent}` : ''};
    ${props.paddingRight ? `padding-right: ${props.paddingRight};` : ''}
    ${props.paddingLeft ? `padding-left: ${props.paddingLeft};` : ''}
    ${props.paddingTop ? `padding-top: ${props.paddingTop};` : ''}
    ${props.paddingBottom ? `padding-bottom: ${props.paddingBottom};` : ''}
    ${props.padding ? `padding: ${props.padding};` : ''}
    ${props.marginRight ? `margin-right: ${props.marginRight};` : ''}
    ${props.marginLeft ? `margin-left: ${props.marginLeft};` : ''}
    ${props.marginTop ? `margin-top: ${props.marginTop};` : ''}
    ${props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ''}
    ${props.margin ? `margin: ${props.margin};` : ''}
    ${props.alignItems ? `align-items: ${props.alignItems};` : ''}
    ${props.width ? `width: ${props.width};` : ''}
    ${props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
    ${props.minWidth ? `min-width: ${props.minWidth};` : ''}
    ${props.color ? `color: ${props.color};` : ''}
    ${
      props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''
    }
    ${props.boxSizing ? `box-sizing: ${props.boxSizing};` : ''}
    ${props.flexDirection ? `flex-direction: ${props.flexDirection};` : ''}
    ${props.position ? `position: ${props.position};` : ''}
    ${props.overflow ? `overflow: ${props.overflow};` : ''}
    ${props.display ? `display: ${props.display};` : ''}
    ${props.flexWrap ? `flex-wrap: ${props.flexWrap};` : ''}



`;

export const Layout = styled.div<ILayout>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  // max-width: 900px;
  margin: auto;
  ${(props) => commonLayout(props)}
`;

export const ModalWrapper = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HiddenLayout = styled(Layout)`
  display: none;

  ${({ opened }: any) => (opened ? 'display: flex;' : '')}

  p {
    font-size: 10px !important;
    @media (min-width: 768px) {
      column-count: 1;
      font-size: 11px !important;
    }
  }

  ${({ hasSubtitle }: any) =>
    hasSubtitle
      ? `
  p {
    @media (min-width: 768px) {
      &:first-child {
        font-weight: bold;
        font-size: 14px !important;
        margin: 0 !important;
      }
    }
    &:first-child {
      font-weight: bold;
      font-size: 14px !important;
      margin: 0 !important;
    }
  }`
      : ''}
`;

export const ResponsiveLayout = styled(Layout)`
  width: 100%;
  ${({ width }: ILayout) => (width ? `width: ${width};` : '')}
  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    ${({ width }: ILayout) => (width ? `width: ${width};` : '')}
    align-items: center;
  }
`;

export const ResponsiveIpadLayout = styled(Layout)`
  width: 100%;
  ${({ width }: ILayout) => (width ? `width: ${width};` : '')}
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    ${({ width }: ILayout) => (width ? `width: ${width};` : '')}
    align-items: center;
  }
`;

export const ResponsiveBoxes = styled(ResponsiveLayout)`
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: 769px) {
    flex-direction: column;
    margin-bottom: 0;
    ${({ width }: ILayout) => (width ? `width: ${width};` : '')}
    ${({ minWidth }: ILayout) => (minWidth ? `min-width: ${minWidth};` : '')}
  }
`;

export const PricingBoxes = styled(Layout)`
  width: 100%;
  margin-bottom: 5px;
  flex-direction: column;
  margin-bottom: 0;
  ${({ width }: ILayout) => (width ? `width: ${width};` : '')}
  ${({ minWidth }: ILayout) => (minWidth ? `min-width: ${minWidth};` : '')}

  input {
    font-size: 14px;
  }
  span {
    font-size: 16px;
  }
`;

export const Section = styled(Layout)`
  align-items: center;
  // max-width: 900px;
  margin: 0;

  @media (min-width 768px) {
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
`;

export const WhiteSection = styled(Section)`
  background-color: #fff;
  color: #000;
`;

export const BlueSection = styled(Section)`
  background-color: #216eab;
  color: #fff;
`;

export const BlueSectionWhiteText = styled(Section)`
  background-color: #216eab;
  color: white;
`;

export const OrangeSection = styled(Section)`
  background-color: #f7a11d;
`;

export const GraySection = styled(Section)`
  background-color: #ededed;
  color: #000;
`;

export const SubSection = styled(Section)`
  // max-width: 900px;
  width: 100% !important;
`;

export const RoundSubSection = styled(SubSection)`
  ${({ marginTop }: ILayout) => (marginTop ? `margin-top: ${marginTop};` : '')}
  border-radius: 10px;
  box-shadow: rgb(128, 128, 128) 0px 1px 10px -5px;
`;

export const SubSectionHeader = styled(Section)`
  ${({ alignItems }: ILayout) =>
    alignItems ? `align-items: ${alignItems};` : ''}
  width: 100%;
  height: 10px;
  position: relative;
  text-align: left;
  border-radius: 10px 10px 0 0;
  ${({ height }: ILayout) => (height ? `height: ${height};` : '')}// ::after {
  //   content: '';
  //   position: absolute;
  //   right: -1px;
  //   border-top: 60px solid transparent;
  //   border-right: 40px solid white;
  // }
`;
