import { axiosInstance } from 'core/utils';

const pathUrl = '/InspectionReport';

export const validateInspectionReportTemplate = async ({
  pid,
  franchiseId,
}: {
  pid: string | number;
  franchiseId: string | number;
}) => {
  return axiosInstance.get(
    `${pathUrl}/ValidateInspectionReportTemplate?pid=${pid}&franchiseId=${franchiseId}`
  );
};
