import React, { useRef, useState } from 'react';
import {
  Button,
  useFormProps,
  BubbleButton,
  ActionAlertModal,
} from 'core/components';
import SaveTemplateModal from '../../../BidInfoForm/screens/BidForm/sections/SaveTemplateModal';
import { IconButton } from 'modules/BidInfoForm/components/molecules';
import { getQueryString, axiosInstance } from 'core/utils';
import { NoteModal } from './NoteModal';
import { CalculatorModal } from './CalculatorModal';
import addNoteIcon from '../../../../assets/icons/add-note.svg';
import calculatorIcon from '../../../../assets/icons/calculator.svg';
import { CalculatorImg, UnstyledButton, IconButtonsHolder } from './styles';
import {
  validateSaveDraft,
  validateSaveTemplate,
} from 'core/validators/BidInfoFormValidator';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Footer = ({
  onCompleteButtonClick,
  completeButtonEnabled,
  isSpecialty,
  showDuplicateButton,
  isUserRole,
  showContractButton,
  ...props
}) => {
  const { formValues, setFormValues } = useFormProps();
  const [isCurrentCityTemplate, setIsCurrentCityTemplate] = useState(false);
  const [isCurrentSaveAs, setIsCurrentSaveAs] = useState(false);
  const [warningCityTemplate, setWarningCityTemplate] = useState(false);
  const [duplicateReferenceName, setDuplicateReferenceName] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [openBuble, setOpenBuble] = useState(false);
  const [errors, setErrors] = useState({ required: [], incorrect: [] });
  const userId = getQueryString(window.location.href, 'userId');
  const formId = getQueryString(window.location.href, 'formId');
  const formUniqueId =
    getQueryString(window.location.href, 'formUniqueId') || formValues.uid;
  const formIsDraft = !formValues.sentStatus && !formValues.isTemplate;
  const formAlreadySentUnsigned =
    (formId || formUniqueId) && formValues.sentStatus && !formValues.isTemplate;
  const [completeButtonDisabled, setCompleteButtonDisabled] = useState(false);

  const biggerThan768 = window.innerWidth >= 769;

  const onCompleteButtonClickWrapper = (saveAndComplete = true) => {
    setCompleteButtonDisabled(true);
    onCompleteButtonClick(
      {
        saveAndComplete,
      },
      () => setCompleteButtonDisabled(false)
    );
  };

  const onClickContractButton = () => {
    setCompleteButtonDisabled(true);
    onCompleteButtonClick(
      {
        saveAndComplete: false,
        submitSignedContract: true,
      },
      () => setCompleteButtonDisabled(false)
    );
  };

  const saveDraft = async () => {
    const errors = validateSaveDraft(formValues);

    const hasErrors = errors.required.length > 0 || errors.incorrect.length > 0;
    setErrors(hasErrors ? errors : { required: [], incorrect: [] });

    if (hasErrors) {
      return;
    }

    await props.saveDraft();
    setOpenBuble(false);
  };

  const duplicateProposal = async () => {
    props.duplicateProposal();
    setOpenBuble(false);
  };

  const handleShowTemplateModal = async (isCityTemplate = false) => {
    setIsCurrentCityTemplate(isCityTemplate);
    setIsCurrentSaveAs(true);
    setShowSaveTemplateModal(true);
  };

  const saveTemplate = async (
    isCityTemplate = false,
    isSaveAs = false,
    isOverwritten = false
  ) => {
    const errors = validateSaveTemplate(formValues);

    const hasErrors = errors.required.length > 0 || errors.incorrect.length > 0;
    setErrors(hasErrors ? errors : { required: [], incorrect: [] });

    if (hasErrors) {
      return;
    }

    setShowSaveTemplateModal(false);
    setDuplicateReferenceName(false);

    await props.saveTemplate(isCityTemplate, isSaveAs, isOverwritten);
    setOpenBuble(false);
  };

  const getCompleteButtonLabel = () => {
    if (formValues.status === 2) {
      return 'VIEW PROPOSAL';
    }

    if (formIsDraft) {
      return 'COMPLETE';
    }

    // COMPLETED-UNSIGNED bids
    if (formAlreadySentUnsigned) {
      return 'UPDATE';
    }

    // Waiting for ticket to handle the logic for
    // COMPLETED-SIGNED bids
    // if (condition) {
    //   return 'VIEW PROPOSAL'
    // }

    return 'COMPLETE';
  };

  const getBubbleItems = () => {
    let arr = [];

    if (showDuplicateButton) {
      arr.push({
        value: 'DUPLICATE',
        onClick: duplicateProposal,
      });
    }

    arr.push({
      value: 'MY TEMPLATE',
      onClick: () => handleShowTemplateModal(false),
    });

    arr.push({
      value: 'CITY TEMPLATE',
      onClick: () => handleShowTemplateModal(true),
    });

    // arr.push({
    //   value: formValues.uid
    //     ? formValues.isTemplate
    //       ? 'UPDATE TEMPLATE'
    //       : 'NEW TEMPLATE'
    //     : 'NEW TEMPLATE',
    //   onClick: saveTemplate,
    // });

    return arr;
  };

  const onClickSave = () => {
    if (formValues.isCityTemplate) {
      saveTemplate(true);
    } else if (formValues.isTemplate) {
      saveTemplate(false);
    } else if (formIsDraft) {
      saveDraft();
    } else {
      onCompleteButtonClickWrapper(false);
    }
  };

  const validateReferenceName = async (isCityTemplate) => {
    try {
      const response = await axiosInstance.get(
        `BidSheet/ValidateBidTemplate?userId=${userId}&referenceName=${formValues.newReferenceName.trim()}&isCityTemplate=${isCityTemplate}&isSS=${!!isSpecialty}&franchiseId=${
          formValues.cityId
        }&isTemplate=true`
      );

      return response.data;
    } catch (response) {
      console.log(response);
    }
  };

  const validAndSaveTemplate = async () => {
    const errors = validateSaveTemplate(formValues);

    const hasErrors = errors.required.length > 0 || errors.incorrect.length > 0;
    setErrors(hasErrors ? errors : { required: [], incorrect: [] });

    if (hasErrors) {
      return;
    }

    if (!duplicateReferenceName && isCurrentSaveAs) {
      const { BidTemplate } = await validateReferenceName(
        isCurrentCityTemplate
      );
      if (BidTemplate.IsFound) {
        if (isUserRole && isCurrentCityTemplate) {
          setWarningCityTemplate(true);
          return;
        } else {
          setIsCurrentCityTemplate(isCurrentCityTemplate);
          setDuplicateReferenceName(true);
          setFormValues({
            ...formValues,
            newBidUniqueID: BidTemplate.BidUniqueID,
          });
        }
        return;
      }
    }

    saveTemplate(isCurrentCityTemplate, isCurrentSaveAs);
  };

  const fieldName = formValues.isTemplate
    ? 'referenceName'
    : 'newReferenceName';

  return (
    <>
      <div className="footer-root">
        <div className="footer-content">
          <IconButtonsHolder>
            <UnstyledButton onClick={() => setOpenCalculator(true)}>
              <CalculatorImg src={calculatorIcon} />
            </UnstyledButton>

            <div
              style={{
                background: `url(${addNoteIcon}) no-repeat`,
                width: '32px',
                height: '32px',
              }}
              onClick={() => setOpenNote(true)}
            />
            {formValues.status !== 2 && (
              <div
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  pointerEvents: completeButtonDisabled ? 'none' : 'auto',
                }}
                onClick={onClickSave}
              >
                <FontAwesomeIcon
                  icon={faSave}
                  style={{ fontSize: 32 }}
                  size={'1x'}
                  color={'#333'}
                />
              </div>
            )}
          </IconButtonsHolder>

          <div className="footer-content-buttons">
            {props.onClickCustomizeTerms && (
              <div>
                <IconButton
                  title="CUSTOMIZE TERMS"
                  icon={faEdit}
                  onClick={props.onClickCustomizeTerms}
                />
              </div>
            )}
            {showContractButton && (
              <Button
                kind="outline"
                disabled={completeButtonDisabled}
                isLoading={!completeButtonEnabled}
                reverseSkew
                style={{ width: 'auto', padding: '0 10px' }}
                color="#F7A11C"
                onClick={onClickContractButton}
              >
                SUBMIT SIGNED CONTRACT
              </Button>
            )}

            <BubbleButton
              reverseSkew
              color="#216eab"
              kind="outline"
              margin="0"
              showBubble={openBuble}
              onCloseBubble={() => setOpenBuble(false)}
              onClick={() => setOpenBuble(true)}
              bubbleStyles={{ color: '#216eab' }}
              bubbleItems={getBubbleItems()}
              style={
                (formValues.isTemplate &&
                  !biggerThan768 && {
                    left: '66% !important',
                    zIndex: 999,
                  }) || { zIndex: 999 }
              }
            >
              SAVE AS
            </BubbleButton>

            {/* {!formAlreadySentUnsigned && (
            )} */}

            <Button
              disabled={completeButtonDisabled}
              isLoading={!completeButtonEnabled}
              loadingText="Auto-Save in Progress"
              reverseSkew
              bgColor="#216eab"
              disabledStyles={{
                color: '#fff',
                background: '#ccc',
              }}
              style={{
                width: formValues.status === 2 ? '200px' : '150px',
              }}
              onClick={onCompleteButtonClickWrapper}
            >
              {getCompleteButtonLabel()}
            </Button>
          </div>
        </div>
      </div>
      {openNote && (
        <NoteModal
          onCancel={() => setOpenNote(false)}
          canEdit={formValues.status !== 2}
          onClose={() => setOpenNote(false)}
        />
      )}
      {openCalculator && (
        <CalculatorModal onClose={() => setOpenCalculator(false)} />
      )}

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors({ required: [], incorrect: [] })}
        isVisible={
          errors && (errors.required.length > 0 || errors.incorrect.length > 0)
        }
      />
      {showSaveTemplateModal && (
        <SaveTemplateModal
          onSaveTemplate={validAndSaveTemplate}
          background
          isCityTemplate={isCurrentCityTemplate}
          onCancel={() => {
            setShowSaveTemplateModal(false);
          }}
        />
      )}
      {duplicateReferenceName && (
        <ActionAlertModal
          title={`Uh oh, it appears that you already have a template named ${formValues[fieldName]}`}
          message="If you do not wish to overwrite, please change the template name before proceeding"
          confirmText="OVERWRITE"
          titleStyle={{
            textTransform: 'inherit',
            fontSize: '22px',
            marginBottom: 0,
          }}
          onConfirm={() =>
            saveTemplate(isCurrentCityTemplate, isCurrentSaveAs, true)
          }
          onDismiss={() => {
            setDuplicateReferenceName(false);
          }}
          isVisible={true}
        />
      )}

      {warningCityTemplate && (
        <ActionAlertModal
          renderMessage={() => (
            <span
              className="required-field"
              style={{ textAlign: 'center', display: 'block' }}
            >
              Any changes made to a City Template must be saved under{' '}
              <span style={{ textDecoration: 'underline ' }}>My Templates</span>
            </span>
          )}
          titleStyle={{
            fontFamily: 'OpenSans-Regular',
            fontSize: '18px',
            textTransform: 'initial',
          }}
          title="You are not authorized to edit City Templates"
          confirmText="CLOSE"
          onConfirm={() => {
            setWarningCityTemplate(false);
          }}
          isVisible={true}
        />
      )}
    </>
  );
};
