import React, { useState } from 'react';
import {
  Button,
  useFormProps,
  BubbleButton,
  ActionAlertModal,
} from 'core/components';
import { getQueryString } from 'core/utils';
import { NoteModal } from './NoteModal';
import { CalculatorImg, UnstyledButton, IconButtonsHolder } from './styles';
import { useMediaQuery } from 'react-responsive';

import {
  validateSaveDraft,
  validateSaveTemplate,
} from 'core/validators/PerformanceFormValidator';

import './styles.scss';

export const Footer = ({
  onCompleteButtonClick,
  userEvaluation,
  onClickPrintPdf,
  isCompleted,
  ...props
}) => {
  const [equalsOrBiggerThan768, setEqualsOrBiggerThan768] =
    React.useState(false);
  const [biggerThan796, setBiggerThan796] = React.useState(false);
  const [biggerThan768, setBiggerThan768] = React.useState(false);
  const [minorThan768, setMinorThan768] = React.useState(false);
  const [minorThan450, setMinorThan450] = React.useState(false);
  const [isTouchable, setIsTouchable] = React.useState(false);
  const [completeButtonDisabled, setCompleteButtonDisabled] = useState(false);

  const setBreakpoints = () => {
    setEqualsOrBiggerThan768(window.innerWidth >= 768);
    setBiggerThan796(window.innerWidth >= 796);
    setBiggerThan768(window.innerWidth >= 769);
    setMinorThan768(window.innerWidth < 768);
    setMinorThan450(window.innerWidth < 450);
    setIsTouchable('ontouchstart' in document.documentElement);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  const onCompleteButtonClickWrapper = () => {
    setCompleteButtonDisabled(true);
    onCompleteButtonClick(() => setCompleteButtonDisabled(false));
  };

  const { formValues } = useFormProps();
  const [openNote, setOpenNote] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [openBuble, setOpenBuble] = useState(false);
  const [errors, setErrors] = useState([]);
  const userId = getQueryString(window.location.href, 'userId');
  const formId = getQueryString(window.location.href, 'formId');
  const formUniqueId = getQueryString(window.location.href, 'formUniqueId');

  const formIsDraft = !formValues.sentStatus && !formValues.isTemplate;
  const formAlreadySentUnsigned =
    (formId || formUniqueId) && formValues.sentStatus && !formValues.isTemplate;

  const saveDraft = async () => {
    const errors = validateSaveDraft(formValues);
    setErrors(errors);

    if (errors && errors.length > 0) {
      return;
    }

    await props.saveDraft();
    setOpenBuble(false);
  };

  const saveTemplate = async () => {
    const errors = validateSaveTemplate(formValues);
    setErrors(errors);

    if (errors && errors.length > 0) {
      return;
    }

    await props.saveTemplate(formValues, userId);
    setOpenBuble(false);
  };

  const getCompleteButtonLabel = () => {
    if (formValues.status === 2) {
      return 'VIEW PROPOSAL';
    }

    if (userEvaluation) {
      return 'COMPLETE';
    }

    if (formIsDraft) {
      return 'SAVE & SEND';
    }

    // COMPLETED-UNSIGNED bids
    if (formAlreadySentUnsigned) {
      return 'UPDATE';
    }

    // Waiting for ticket to handle the logic for
    // COMPLETED-SIGNED bids
    // if (condition) {
    //   return 'VIEW PROPOSAL'
    // }

    return 'SAVE & SEND';
  };

  const getBubbleItems = () => {
    let arr = [
      {
        value: formValues.uid
          ? formValues.isTemplate
            ? 'UPDATE TEMPLATE'
            : 'NEW TEMPLATE'
          : 'NEW TEMPLATE',
        onClick: saveTemplate,
      },
    ];

    if (!formAlreadySentUnsigned) {
      arr.push({
        value: formValues.uid
          ? formIsDraft
            ? 'UPDATE DRAFT'
            : 'NEW DRAFT'
          : 'NEW DRAFT',
        onClick: saveDraft,
      });
    }

    return arr;
  };

  return (
    <>
      {!isTouchable && userEvaluation && (
        <span
          style={{
            width: 'calc(100% - 25px)',
            textAlign: 'right',
            paddingTop: '10px',
            fontFamily: 'OpenSans-SemiBold',
            marginBottom: '-10px',
          }}
        >
          By clicking Complete you are signing this form digitally
        </span>
      )}
      <div className="performance-footer-root">
        <div className="footer-content">
          <div
            style={{
              display: 'flex',
            }}
          >
            {/* {formValues.status !== 2 && !userEvaluation && (
              <BubbleButton
                reverseSkew
                color="#216eab"
                kind="outline"
                margin="0px 30px 0px 0px"
                showBubble={openBuble}
                onCloseBubble={() => setOpenBuble(false)}
                onClick={() => setOpenBuble(true)}
                bubbleItems={getBubbleItems()}
                bubbleStyles={{ color: '#216eab' }}
                style={
                  (formValues.isTemplate &&
                    !biggerThan768 && {
                      left: '60% !important',
                      width: formValues.status === 2 ? '200px' : '200px',
                    }) || {
                    width: formValues.status === 2 ? '200px' : '200px',
                  }
                }
              >
                SAVE AS
              </BubbleButton>
            )} */}

            {/* {!formAlreadySentUnsigned && (
            )} */}

            {(!userEvaluation || isCompleted) && (
              <Button
                reverseSkew
                color="#216eab"
                bgColor="white"
                disabledStyles={{
                  color: '#fff',
                  background: '#ccc',
                }}
                style={{
                  width: formValues.status === 2 ? '200px' : '200px',
                  border: '1px solid #216eab',
                  marginRight: isCompleted ? '0px' : '20px',
                }}
                onClick={onClickPrintPdf}
              >
                Print PDF
              </Button>
            )}

            {!isCompleted && (
              <Button
                reverseSkew
                bgColor="#216eab"
                disabled={completeButtonDisabled}
                disabledStyles={{
                  color: '#fff',
                  background: '#ccc',
                }}
                style={{
                  width: formValues.status === 2 ? '200px' : '200px',
                }}
                onClick={onCompleteButtonClickWrapper}
              >
                {getCompleteButtonLabel()}
              </Button>
            )}
          </div>
        </div>
      </div>

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors([])}
        isVisible={errors && errors.length > 0}
      />
    </>
  );
};
