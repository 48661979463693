/* eslint-disable no-console */
import {
  Checkbox,
  ButtonComponent,
  TableSummary,
  BaseButton,
  SVGIcon,
  Modal,
  AutocompleteSummary as Autocomplete,
  Tooltip,
} from 'core/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import PenIcon from './assets/Edit.svg';
import BackIcon from 'modules/shared/sections/Summary/References/assets/BackIcon.svg';
import React, { useEffect, useMemo, useState } from 'react';
import { IconWrapper, Footer } from '../styles';
import {
  getNames,
  getTitles,
  getCompanies,
  getReferenceByFranchiseId,
  getIndustries,
  getReferencesDetails,
  getListOrderReferenceTypeByReferenceID,
} from 'modules/BidInfoForm/services/bidInfoServiceClient';
import { Reference, ReferenceDetail } from 'modules/shared/sections/Summary';
import SaveIcon from './assets/SaveIcon.svg';
import { toast } from 'react-toastify';
import EditModal from './EditReferenceModal';
import Preview from './PreviewReference';

export type header = {
  sortable?: boolean;
  label?: string;
  width?: string;
  name: string;
  CustomComponent?: any;
  format?: any;
};

export interface TableSummaryProps {
  headers: header[];
  data: any[];
  loading?: boolean;
  pagination: Pagination;
  onSort?: (name: string, order: string) => void;
  sorting?: Sorting;
}

export interface Pagination {
  page: number;
  offset: number;
  pageCount: number;
  limit: number;
  count: number;
  total: number;
  onChangePage?: any;
}

export interface Sorting {
  order: string;
  field: string;
}

interface IReferencesProps {
  opened: boolean;
  canEdit: boolean;
  isReferenceCustomized: boolean;
  onClose: (initialReference?: Reference) => void;
  reference: Reference;
  onSave: (reference: Reference, isReferenceCustomized: boolean) => void;
}

const types = {
  1: 'Google Review',
  2: 'Testimonial',
  3: 'References',
};

const typesReverse = Object.fromEntries(
  Object.entries(types).map(([key, value]) => [value, key])
);

const formatContactInfo = (value, data) => {
  if (data.ContactType === 'Cell') {
    const formattedValue = value.replace(/\D/g, '');
    if (formattedValue.length === 10) {
      return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)}`;
    }
  } else if (data.ContactType === 'Work') {
    const formattedValue = value.replace(/\D/g, '');
    if (formattedValue.length === 10) {
      return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)}`;
    } else if (formattedValue.length === 13) {
      return `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)} ${formattedValue.slice(10, 13)}`;
    }
  }

  return value;
};

const References = ({
  opened,
  onClose,
  onSave,
  isReferenceCustomized: isReferenceCustomizedProp,
  reference: referenceProp,
  canEdit,
}: IReferencesProps) => {
  const [reference, setReference] = useState<Reference>(referenceProp);
  const { ReferenceId: referenceId } = reference;
  const [referenceDetailModal, setReferenceDetailModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [referenceDetailReference, setReferenceDetailReference] =
    useState<ReferenceDetail>();
  const [isReferenceCustomized, setIsReferenceCustomized] = useState(
    isReferenceCustomizedProp
  );
  const [referencesDetails, setReferencesDetails] = useState<ReferenceDetail[]>(
    referenceProp.ReferenceDetails
  );
  const [showPreview, setShowPreview] = useState(false);
  const [names, setNames] = useState<string[]>([]);
  const [titles, setTitles] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    offset: 0,
    pageCount: Math.ceil(referenceProp.ReferenceDetails.length / 10),
    limit: 10,
    total: referenceProp.ReferenceDetails.length,
    count: referenceProp.ReferenceDetails.length,
  });
  const [sorting, setSorting] = useState<Sorting>({
    order: 'ASC',
    field: 'Default',
    keepOrder: false,
  });

  const [referenceDetailFilters, setReferenceDetailFilters] = useState({
    defaultValue: '',
    name: '',
    type: '',
    title: '',
    company: '',
    industry: '',
  });

  const fetchListOrderReferenceType = async () => {
    const { orderReferenceType } = await getListOrderReferenceTypeByReferenceID(
      {
        referenceId: reference.ReferenceId,
      }
    );

    return orderReferenceType;
  };

  const paginate = (array) => {
    return array.slice(
      (pagination.page - 1) * pagination.limit,
      pagination.page * pagination.limit
    );
  };

  const handleClearFilters = () => {
    setReferenceDetailFilters({
      defaultValue: '',
      name: '',
      title: '',
      type: '',
      company: '',
      industry: '',
    });
    setSorting({
      order: 'ASC',
      field: 'Default',
      keepOrder: false,
    });
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const getDefaultReferences = () =>
    referencesDetails.filter((item) => item.Default);

  const onChangePage = (page: number) => {
    setPagination({ ...pagination, page });
  };

  useEffect(() => {
    if (referenceProp && opened) {
      setReference(referenceProp);
      setReferencesDetails(referenceProp.ReferenceDetails);
    }
  }, [referenceProp, opened]);

  const getFilters = () => {
    fetchNames();
    fetchTitles();
    fetchCompanies();
    fetchIndustries();
  };

  useEffect(() => {
    if (referenceId) {
      getFilters();
    }
  }, [referenceId]);

  const fetchNames = async () => {
    const namesResponse = await getNames({ referenceId });
    if (namesResponse) {
      setNames(namesResponse.names.map((item) => item.Name));
    }
  };

  const fetchTitles = async () => {
    const namesResponse = await getTitles({ referenceId });
    if (namesResponse) {
      setTitles(namesResponse.titles.map((item) => item.Title));
    }
  };

  const fetchCompanies = async () => {
    const namesResponse = await getCompanies({ referenceId });
    if (namesResponse) {
      setCompanies(namesResponse.companies.map((item) => item.Company));
    }
  };

  const fetchIndustries = async () => {
    const namesResponse = await getIndustries({ referenceId });
    if (namesResponse) {
      setIndustries(namesResponse.industries.map((item) => item.Industry));
    }
  };

  const openPreview = () => {
    const defaultReferences = getDefaultReferences();

    if (defaultReferences.length > 0) {
      setShowPreview(true);
    } else {
      toast.info(
        'You need to select at least one reference before using the Preview & Re-order!'
      );
    }
  };

  const onSort = (field: string, order: string) => {
    setSorting({ field, order, keepOrder: false });
    setPagination({ ...pagination, page: 1 });
  };

  const previewIcon = ({
    id,
    message,
    placement = 'left',
  }: {
    id: string;
    message: string;
    placement?: string;
  }) => {
    return (
      <Tooltip
        overlay={
          <span
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {message}
          </span>
        }
        // trigger="click"
        placement={placement}
        alignedItemId={`previewTooltip_${id}`}
        positioningFunction={(node, align) => {
          const targetEl = document.getElementById(`previewTooltip_${id}`);
          const rect = targetEl.getBoundingClientRect();
          node.style.left = `${
            placement === 'left' ? rect.left - 340 : rect.right - 10
          }px`;
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            e.isPropagationStopped();
          }}
        >
          <IconWrapper
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              e.isPropagationStopped();
            }}
            id={`previewTooltip_${id}`}
          >
            <FontAwesomeIcon
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                e.isPropagationStopped();
              }}
              icon={faInfoCircle}
              style={{ fontSize: 12 }}
              size="xs"
              color="#216eab"
            />
          </IconWrapper>
        </div>
      </Tooltip>
    );
  };

  const handleUpdateFilters = (referenceDetail: ReferenceDetail) => {
    const addProperty = (property: string, array: string[]) => {
      if (array.indexOf(property) === -1) array.push(property);
      return array;
    };

    const { Name, Title, Industry, Company } = referenceDetail;
    setNames(addProperty(Name, names));
    setTitles(addProperty(Title, titles));
    setIndustries(addProperty(Industry, industries));
    setCompanies(addProperty(Company, companies));
  };

  function addReferenceDetail(array, newObject) {
    let lastIndex = -1;
  
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i].Default === true) {
        lastIndex = i;
        break;
      }
    }
  
    if (lastIndex !== -1) {
      array.splice(lastIndex + 1, 0, newObject);
    }
  
    return array;
  }  

  const handleCreate = (referencesDetail: ReferenceDetail) => {
    const newReferencesDetail = {
      ...referencesDetail,
      createdByUser: true,
    };
    const newReferences = addReferenceDetail(referencesDetails, newReferencesDetail)
    setReferencesDetails(newReferences);
    getFilters();
    setIsReferenceCustomized(true);
  };

  const handleUpdateReference = (referenceDetail: ReferenceDetail, { keepOrder = false } = {}) => {
    const index = referencesDetails.findIndex(
      (item) => item.ReferenceDetailId === referenceDetail.ReferenceDetailId
    );

    const newReferencesDetails = referencesDetails.slice();
    setSorting({ ...sorting, keepOrder });
    newReferencesDetails[index] = referenceDetail;
    setReferencesDetails(newReferencesDetails);
    setIsReferenceCustomized(true);
    handleUpdateFilters(referenceDetail);
    toast.info('Reference updated successfully');
  };

  const setDefaultReferenceDetail = async (
    referenceDetailReference: ReferenceDetail
  ) => {
    const defaultReferences = getDefaultReferences();
    if (defaultReferences.length > 1 || referenceDetailReference.Default) {
      handleUpdateReference(referenceDetailReference, { keepOrder: true });
    } else {
      toast.info(
        "Unable to perform this action. The Custom Reference List can't be empty."
      );
    }
  };

  const DefaultCheckbox = ({ reference }: { reference: ReferenceDetail }) => (
    <Checkbox
      borderColor="gray"
      name="default"
      value={reference.Default}
      onChange={() => {
        setDefaultReferenceDetail({
          ...reference,
          Default: !reference.Default,
        });
      }}
      containerStyle={{ justifyContent: 'center' }}
      checked={reference.Default}
    />
  );

  const SelectedHeader = () => (
    <div style={{ display: 'flex' }}>
      {previewIcon({
        id: 'checkItem',
        message: 'Only items checked as Selected will show on the Proposal',
        placement: 'right',
      })}
      <span>Selected</span>
    </div>
  );

  const EditButton = ({ reference }: { reference: ReferenceDetail }) => (
    <button
      className="table-button"
      disabled={!canEdit}
      onClick={() => {
        setIsEdit(true);
        setReferenceDetailModal(true);
        setReferenceDetailReference(reference);
      }}
    >
      <SVGIcon size="20px" icon={PenIcon} />
    </button>
  );

  const formatType = (val: string) => {
    return types[Number(val)];
  };

  const headers: header[] = [
    {
      label: 'Selected',
      name: 'Default',
      CustomComponent: DefaultCheckbox,
      CustomHeader: SelectedHeader,
      sortable: true,
      textAlign: 'center',
      tdStyle: {
        textAlign: 'center',
      },
      display: 'flex',
    },
    { label: 'Name', name: 'Name', sortable: true },
    { label: 'Title', name: 'Title', sortable: true },
    { label: 'Company', name: 'Company', sortable: true },
    { label: 'Contact Info', name: 'ContactInfo', sortable: true, format: formatContactInfo },
    { label: 'Type', name: 'Type', sortable: true, format: formatType },
    { label: 'Industry', name: 'Industry', sortable: true },
    {
      label: 'Customize',
      name: '',
      textAlign: 'center',
      CustomComponent: EditButton,
      width: '50px',
    },
  ];

  const onChangeFilter = (field: string) => (value: string) => {
    setSorting({ ...sorting, keepOrder: false });
    setReferenceDetailFilters({ ...referenceDetailFilters, [field]: value });
    setPagination({ ...pagination, page: 1 });
  };

  const handleChangeDefaultValue = (value: string) => {
    setSorting({ ...sorting, keepOrder: false });
    setReferenceDetailFilters({
      ...referenceDetailFilters,
      defaultValue: value,
    });
    setPagination({ ...pagination, page: 1 });
  };

  const handleSave = async () => {
    onSave(
      {
        ...reference,
        ReferenceDetails: referencesDetails,
      },
      isReferenceCustomized
    );
    onClose();
    handleClearFilters();
    toast.info('Saved Successfully');
  };

  const fetchReferenceDetails = async (referenceResponse: Reference) => {
    const { referenceDetails } = await getReferencesDetails({
      referenceId: referenceResponse.ReferenceId,
      defaultValue: '',
      name: '',
      type: '',
      company: '',
      title: '',
      industry: '',
      limit: 999999,
      offset: 0,
      page: 1,
      order: 'ASC',
      sort: 'Order',
    });
    const { Data } = referenceDetails;
    return Data;
  };

  const handleRestoreDefault = async () => {
    try {
      const referenceResponse = await getReferenceByFranchiseId({
        franchiseId: referenceProp.FranchiseId,
      });
      const defaultOrderList = await fetchListOrderReferenceType();
      const defaultReferenceDetails = await fetchReferenceDetails(
        referenceResponse
      );
      setReference({
        ...referenceResponse,
        OrderReferenceTypes: defaultOrderList,
      });
      setReferencesDetails(defaultReferenceDetails);
      setIsReferenceCustomized(false);
      toast.info('Default values have been restored successfully!');
    } catch {
      toast.error('Error!', {
        icon: false,
        style: {
          backgroundColor: 'red',
          color: 'white',
        },
      });
    }
  };

  const handleCancel = () => {
    setReference(referenceProp);
    handleClearFilters();
    onClose({
      ...referenceProp,
      ReferenceDetails: referenceProp.ReferenceDetails,
    });
  };

  const normalizeReferences = useMemo(() => {
    const { defaultValue, name, title, company, industry, type } =
      referenceDetailFilters;
    
    const filteredReferences = referencesDetails
      .filter((item) => (defaultValue ? item.Default : item))
      .filter((item) =>
        !!name
          ? item.Name.toLocaleLowerCase().indexOf(name.toLocaleLowerCase()) > -1
          : item
      )
      .filter((item) =>
        !!title
          ? item.Title.toLocaleLowerCase().indexOf(title.toLocaleLowerCase()) >
            -1
          : item
      )
      .filter((item) =>
        !!company
          ? item.Company.toLocaleLowerCase().indexOf(
              company.toLocaleLowerCase()
            ) > -1
          : item
      )
      .filter((item) =>
        !!industry
          ? item.Industry.toLocaleLowerCase().indexOf(
              industry.toLocaleLowerCase()
            ) > -1
          : item
      )
      .filter((item) => (!!type ? item.Type == typesReverse[type] : item));

    if (sorting.keepOrder && sorting.field === 'Default') {
      return filteredReferences;
    }
    
    if (!sorting.field) {
      return filteredReferences;
    }

    const sortedReferences = filteredReferences.sort((a, b) => {
      let formatA = a[sorting.field];
      let formatB = b[sorting.field];

      if (sorting.field === 'Default') {
        if (sorting.order === 'DESC') {
          return String(formatA).localeCompare(String(formatB));
        } else {
          return String(formatB).localeCompare(String(formatA));
        }
      }

      if (sorting.field === 'Type') {
        formatA = types[formatA];
        formatB = types[formatB];
      }

      if (sorting.order === 'DESC') {
        return formatB.localeCompare(formatA);
      } else {
        return formatA.localeCompare(formatB);
      }
    });

    return sortedReferences;
  }, [sorting, referenceDetailFilters, referencesDetails.length, referencesDetails]);

  useEffect(() => {
    setPagination({
      ...pagination,
      offset: (pagination.page - 1) * 10,
      pageCount: Math.ceil(normalizeReferences.length / 10),
      total: normalizeReferences.length,
      count: normalizeReferences.length,
    });
  }, [normalizeReferences]);

  const handleMoveCard = (
    newReferencesDetails: ReferenceDetail[],
    orderList
  ) => {
    const notDefaultReferences = referencesDetails.filter(
      (item) => !item.Default
    );
    setReferencesDetails(newReferencesDetails.concat(notDefaultReferences));
    setReference({
      ...reference,
      OrderReferenceTypes: orderList,
    });
  };

  const typesList = Object.values(types);

  return (
    <>
      <Modal
        id="refence-modal"
        opened={opened}
        onClose={handleCancel}
        className="modal-supplies"
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <h1>Custom Reference List</h1>
            <div className="restore-default-container">
              <BaseButton onClick={handleRestoreDefault}>
                <span>Restore Defaults</span>
              </BaseButton>
            </div>
          </div>
        </div>
        <div className="supplies-container">
          {canEdit && (
            <div className="supplies-edit-price end">
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'flex-end',
                }}
              >
                {previewIcon({
                  id: 'add-reference-button',
                  message:
                    'Added References will be saved and made available to all Master City Users',
                })}
                <BaseButton
                  id="add-supply-button"
                  onClick={() => {
                    setIsEdit(false);
                    setReferenceDetailModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Reference</span>
                </BaseButton>
              </div>
            </div>
          )}
          <div>
            <div className="filter-container">
              <div className="checkItem">
                <div className="">
                  <label>Show Selected Only</label>
                </div>
                <div className="checkItem-box">
                  {/* {previewIcon({
                    id: 'checkItem-ref',
                    message:
                      'Only items checked as Selected will show on the Proposal',
                  })} */}
                  <Checkbox
                    borderColor="gray"
                    name="defaultValue"
                    value={Boolean(referenceDetailFilters.defaultValue)}
                    onChange={() => {
                      handleChangeDefaultValue(
                        referenceDetailFilters.defaultValue ? '' : 'true'
                      );
                      setPagination({ ...pagination, page: 1 });
                    }}
                    checked={Boolean(referenceDetailFilters.defaultValue)}
                  />
                </div>
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('name')}
                  value={referenceDetailFilters.name}
                  list={names}
                  placeholder="Filter"
                  maxLength={50}
                  label="Name"
                />
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('title')}
                  maxLength={50}
                  value={referenceDetailFilters.title}
                  list={titles}
                  placeholder="Filter"
                  label="Title"
                />
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('company')}
                  list={companies}
                  value={referenceDetailFilters.company}
                  maxLength={50}
                  placeholder="Filter"
                  label="Company"
                />
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('type')}
                  maxLength={50}
                  value={referenceDetailFilters.type}
                  list={typesList}
                  placeholder="Filter"
                  label="Type"
                />
              </div>
              <div>
                <Autocomplete
                  onChange={onChangeFilter('industry')}
                  list={industries}
                  value={referenceDetailFilters.industry}
                  maxLength={50}
                  placeholder="Filter"
                  label="Industry"
                />
              </div>
            </div>
          </div>
        </div>
        <TableSummary
          data={paginate(normalizeReferences)}
          headers={headers}
          pagination={{ ...pagination, onChangePage }}
          onSort={onSort}
          sorting={sorting}
        />
        <Footer style={{ padding: 0, width: '100%' }}>
          <div>
            <ButtonComponent icon={BackIcon} onClick={handleCancel}>
              BACK
            </ButtonComponent>
          </div>
          <div>
            <ButtonComponent outline secondary onClick={openPreview}>
              PREVIEW & RE-ORDER
            </ButtonComponent>
            <ButtonComponent secondary icon={SaveIcon} onClick={handleSave}>
              SAVE AND CLOSE
            </ButtonComponent>
          </div>
        </Footer>
      </Modal>
      <EditModal
        titles={titles}
        companies={companies}
        names={names}
        industries={industries}
        header={`${isEdit ? 'Update Reference' : 'Add Reference'}`}
        isEdit={isEdit}
        opened={referenceDetailModal}
        onClose={() => setReferenceDetailModal(false)}
        onCreate={handleCreate}
        onUpdate={handleUpdateReference}
        referenceId={referenceId}
        referenceDetailReference={referenceDetailReference}
      />
      <Preview
        onSave={handleMoveCard}
        onClose={() => {
          setShowPreview(false);
        }}
        orderList={reference.OrderReferenceTypes}
        referenceDetails={getDefaultReferences()}
        opened={showPreview}
      />
    </>
  );
};

export default References;
