import React, { useState, useEffect } from 'react';
import { useFormProps, BubbleButton, ActionAlertModal } from 'core/components';
import SaveTemplateModal from '../../../BidInfoForm/screens/BidForm/sections/SaveTemplateModal';
import { IconButton } from 'modules/BidInfoForm/components/molecules';
import { getQueryString, axiosInstance } from 'core/utils';
import { NoteModal } from '../Footer/NoteModal';
import addNoteIcon from '../../../../assets/icons/add-note.svg';
import { IconButtonsHolder } from './styles';
import useBeforeUnload from 'core/hooks/useBeforeUnload';
import { validateSaveTemplate } from 'core/validators/BidInfoFormValidator';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

export const TemplateFooter = ({
  onCompleteButtonClick,
  isUserRole,
  completeButtonEnabled,
  isSpecialty,
  ...props
}) => {
  const userId = getQueryString(window.location.href, 'userId');
  const franchiseId = getQueryString(window.location.href, 'franchiseId');
  const { formValues, setFormValues } = useFormProps();
  const [isCurrentCityTemplate, setIsCurrentCityTemplate] = useState(false);
  const [isCurrentSaveAs, setIsCurrentSaveAs] = useState(false);
  const [warningCityTemplate, setWarningCityTemplate] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [duplicateReferenceName, setDuplicateReferenceName] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openBuble, setOpenBuble] = useState(false);
  const [errors, setErrors] = useState({ required: [], incorrect: [] });
  const formUniqueId =
    getQueryString(window.location.href, 'formUniqueId') || formValues.uid;

  const biggerThan768 = window.innerWidth >= 769;

  const handleShowTemplateModal = async (isCityTemplate = false) => {
    setIsCurrentCityTemplate(isCityTemplate);
    setIsCurrentSaveAs(true);
    setShowSaveTemplateModal(true);
  };

  const saveTemplate = async (
    isCityTemplate = false,
    isSaveAs = false,
    isOverwritten = false
  ) => {
    const errors = validateSaveTemplate(formValues);

    const hasErrors = errors.required.length > 0 || errors.incorrect.length > 0;
    setErrors(hasErrors ? errors : { required: [], incorrect: [] });

    if (hasErrors) {
      return;
    }

    setShowSaveTemplateModal(false);
    setDuplicateReferenceName(false);

    await props.saveTemplate(isCityTemplate, isSaveAs, isOverwritten);
    setOpenBuble(false);
  };

  const validateReferenceName = async (isCityTemplate) => {
    try {
      const response = await axiosInstance.get(
        `BidSheet/ValidateBidTemplate?userId=${userId}&referenceName=${formValues.referenceName.trim()}&isCityTemplate=${isCityTemplate}&isSS=${!!isSpecialty}&franchiseId=${franchiseId}&isTemplate=true`
      );

      return response.data;
    } catch (response) {
      console.log(response);
    }
  };

  useBeforeUnload(() => {
    console.log(
      'The user is trying to close the tab or navigate to another page.'
    );
  });

  const validAndSaveTemplate = async () => {
    const errors = validateSaveTemplate(formValues);

    const hasErrors = errors.required.length > 0 || errors.incorrect.length > 0;
    setErrors(hasErrors ? errors : { required: [], incorrect: [] });

    if (hasErrors) {
      return;
    }

    if (!duplicateReferenceName && isCurrentSaveAs) {
      const { BidTemplate } = await validateReferenceName(
        isCurrentCityTemplate
      );
      if (BidTemplate.IsFound) {
        if (isUserRole && isCurrentCityTemplate) {
          setWarningCityTemplate(true);
          return;
        } else {
          setIsCurrentCityTemplate(isCurrentCityTemplate);
          setDuplicateReferenceName(true);
          setFormValues({
            ...formValues,
            newBidUniqueID: BidTemplate.BidUniqueID,
          });
        }
        return;
      }
    }

    saveTemplate(isCurrentCityTemplate, isCurrentSaveAs);
  };

  const getBubbleItems = () => {
    let arr = [];

    if (!!formUniqueId && !isUserRole) {
      arr.push({
        value: 'UPDATE TEMPLATE',
        onClick: () => saveTemplate(formValues.isCityTemplate, false, true),
      });
    }

    arr.push({
      value: 'SAVE AS NEW - MY TEMPLATE',
      onClick: () => handleShowTemplateModal(false),
    });

    arr.push({
      value: 'SAVE AS NEW - CITY TEMPLATE',
      onClick: () => handleShowTemplateModal(true),
    });

    return arr;
  };

  return (
    <>
      <div className="footer-root">
        <div className="footer-content">
          <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
            {!isUserRole ? (
              <IconButtonsHolder>
                <div
                  style={{
                    background: `url(${addNoteIcon}) no-repeat`,
                    width: '32px',
                    height: '32px',
                  }}
                  onClick={() => setOpenNote(true)}
                />
              </IconButtonsHolder>
            ) : null}
          </div>

          <div
            style={{
              display: 'flex',
            }}
          >
            {props.onClickCustomizeTerms && (
              <div style={{ marginRight: '25px' }}>
                <IconButton
                  title="CUSTOMIZE TERMS"
                  icon={faEdit}
                  onClick={props.onClickCustomizeTerms}
                />
              </div>
            )}
            <BubbleButton
              reverseSkew
              color="#216eab"
              kind="outline"
              margin="0px 30px 0px 0px"
              showBubble={openBuble}
              onCloseBubble={() => setOpenBuble(false)}
              onClick={() => setOpenBuble(true)}
              bubbleStyles={{ color: '#216eab' }}
              bubbleItems={getBubbleItems()}
              style={
                (formValues.isTemplate &&
                  !biggerThan768 && {
                    left: '66% !important',
                    zIndex: 999,
                  }) || { zIndex: 999 }
              }
            >
              SAVE TEMPLATE
            </BubbleButton>
          </div>
        </div>
      </div>
      {openNote && (
        <NoteModal
          onCancel={() => setOpenNote(false)}
          canEdit={formValues.status !== 2}
          onClose={() => setOpenNote(false)}
        />
      )}
      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors({ required: [], incorrect: [] })}
        isVisible={
          errors && (errors.required.length > 0 || errors.incorrect.length > 0)
        }
      />
      {showSaveTemplateModal && (
        <SaveTemplateModal
          onSaveTemplate={validAndSaveTemplate}
          background
          isTemplate
          isCityTemplate={isCurrentCityTemplate}
          onCancel={() => {
            setShowSaveTemplateModal(false);
          }}
        />
      )}
      {warningCityTemplate && (
        <ActionAlertModal
          renderMessage={() => (
            <span
              className="required-field"
              style={{ textAlign: 'center', display: 'block' }}
            >
              Any changes made to a City Template must be saved under{' '}
              <span style={{ textDecoration: 'underline ' }}>My Templates</span>
            </span>
          )}
          titleStyle={{
            fontFamily: 'OpenSans-Regular',
            fontSize: '18px',
            textTransform: 'initial',
          }}
          title="You are not authorized to edit City Templates"
          confirmText="CLOSE"
          onConfirm={() => {
            setWarningCityTemplate(false);
          }}
          isVisible={true}
        />
      )}
      {duplicateReferenceName && (
        <ActionAlertModal
          title={`Uh oh, it appears that you already have a template named ${formValues.referenceName}`}
          message="If you do not wish to overwrite, please change the template name before proceeding"
          confirmText="OVERWRITE"
          titleStyle={{
            textTransform: 'inherit',
            fontSize: '22px',
            marginBottom: 0,
          }}
          onConfirm={() =>
            saveTemplate(isCurrentCityTemplate, isCurrentSaveAs, true)
          }
          onDismiss={() => {
            setDuplicateReferenceName(false);
          }}
          isVisible={true}
        />
      )}
    </>
  );
};
