import React, { useState, useEffect } from 'react';
import { withFormik, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteComponentProps, withRouter } from 'react-router';
import { IBidInfoForm, IWarningModal } from 'modules/BidInfoForm/interfaces';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  defaultValues,
  defaultPostPayload as payload,
} from '../../shared/bidDefaultValues';
import { FormProvider, GenericModal } from 'core/components';
import ActionAlertModal from 'core/components/ActionAlertModal';
import { getQueryString, axiosInstance } from 'core/utils';
import { stripHTMLTags, decodeEntities, isWebView } from 'core/utils';
import {
  parseFromApi,
  parseGetToPost,
  parseWaterStreet,
} from 'modules/BidInfoForm/parser';
import { IAPIResponse } from 'modules/BidInfoForm/interfaces';
import { formatNumberByMask } from 'core/utils';
import SignNowScreen from 'modules/BidInfoForm/screens/SignNowScreen';
import BlockingPopupModal from 'modules/BidInfoForm/screens/BlockingPopupModal';
import {
  postBidInfo,
  getByUniqID,
  uploadOneTimeAttachment,
  getEasterEggSSTemplate,
  getBidInfoById,
  saveDraft,
  saveTemplate,
  postToApi,
  checkIsGeneratingPDF,
  isAlive,
  isTabIdValid,
} from 'modules/BidInfoForm/services/bidInfoService';
import {
  LoadingPage,
  BlockingOverlay,
} from 'modules/BidInfoForm/components/organisms';
import { toast } from 'react-toastify';
import EventEmitter from 'core/utils/events';
import {
  Header,
  ClientInfo,
  RoomInventory,
  FloorTypeTotals,
  Footer,
  AdditionalQuotes,
  ConfirmationModal,
} from 'modules/shared/sections';
import {
  Supplies,
  References,
  SectionGroup,
  ProtectionDisinfectionPlansSs,
} from 'modules/BidInfoForm/screens/BidForm/sections';

import { ServiceProvider } from './sections';

import { BidFormMenuScreen } from 'modules/shared/Menu';

// import './styles.scss';
import {
  validateForm,
  validateSaveTemplate,
} from 'core/validators/BidInfoFormValidator';
import { getSpecialtyURL } from '../../BidInfoForm/utils';
import {
  getSignNowStatus,
  setInvalidDocument,
} from '../../BidInfoForm/services/bidInfoServiceClient';

import {
  autoSave,
  getByUniqueIDNew,
  getFranchiseById,
  loadNonTemp,
  loadTemp,
  updateBid,
  verifyServerUnavailable,
} from '../../BidInfoForm/services/bidInfoService';

import { useActivityTracking } from '../../../core/hooks/useActivityTracking';
import { useAuthenticationHandler } from '../../../core/hooks/useAuthenticationHandler';
import { useGenericErrorHandler } from 'core/hooks/useGenericErrorHandler';
import { RenderPdf } from '../../../core/components';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from '../../../core/utils';
import { getCookie } from 'core/utils/cookiesUtils';
import LoadTemplateModal from '../../BidInfoForm/screens/BidForm/sections/LoadTemplateModal';
export * from './SummarySuccess';

sessionStorage.removeItem('sessionStorageCleaningSpecification');

type BidFormType = FormikProps<IBidInfoForm> & RouteComponentProps;

const compareValues = (v1, v2) => {
  const orderedV1 = {},
    orderedV2 = {};

  Object.keys(v1)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV1[key] = v1[key];
      }
    });

  Object.keys(v2)
    .sort()
    .forEach((key) => {
      if (
        key != 'uid' &&
        key != 'bidInfoId' &&
        key != 'originalBidUid' &&
        key != 'isTemp'
      ) {
        orderedV2[key] = v2[key];
      }
    });
  const equal = JSON.stringify(orderedV1) == JSON.stringify(orderedV2);
  return equal;
};

const SpecialtyForm = ({
  values,
  setFieldValue,
  setValues,
  dirty,
  ...props
}: BidFormType) => {
  const { search } = props.location;
  const uniqueId = getQueryString(search, 'formUniqueId');
  const [showExit, setShowExit] = useState(false);
  const [user, setUser] = useState(null);
  const [ready, setReady] = useState(false);
  const [tabId, setTabId] = useState(new Date().toISOString());
  const franchiseId = getQueryString(search, 'franchiseId');
  const easterEgg = window.location.href.indexOf('pre-filled') >= 0;
  const userId = getQueryString(window.location.href, 'userId');

  const setAllValues = (params: any) => {
    return setValues({ ...values, ...params });
  };
  const [showNoBidUniqueID, setShowNoBidUniqueID] = useState(false);
  const [bidData, setBidData] = useState({ id: '' });
  const [bidFullData, setBidFullData] = useState({});
  const [easterEggCreated, setEasterEggCreated] = useState(false);
  const [errors, setErrors] = useState({});
  const [showLoadTemplateModal, setShowLoadTemplateModal] = useState(false);
  const [warningModal, setWarningModal] = useState<IWarningModal>(null);
  const [blockingModal, setBlockingModal] = useState<IWarningModal>(null);
  const [hadChanges, setHadChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmation, setShowConfirmationModal] = useState(false);
  const [showMenu, setShowMenu] = useState();
  const [showPdf, setShowPdf] = useState(false);
  const [showAlreadySignedModal, setShowAlreadySignedModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showExpiredBid, setShowExpiredBid] = useState(false);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [successModalData, setSuccessModalData] = useState({
    emails: '',
    emailBody: '',
    location: props.location,
    formUniqueId: '',
    franchiseId: '',
    bidLink: '',
  });

  const { userSignNowStatus } = values;
  const [userStatus, setUserStatus] = React.useState(null);
  const shouldShowSignNowModal = userStatus !== null && userStatus !== 2;
  const [showCustomizeTerms, setShowCustomizeTerms] = React.useState(false);

  const [minorThan768, setMinorThan768] = React.useState(false);

  const [autoSaveTimeout, setAutoSaveTimeout] = React.useState(1);
  const [executeAutoSave, setExecuteAutoSave] = React.useState(null);
  const [tempData, setTempData] = React.useState({});
  const [completeButtonEnabled, setCompleteButtonEnabled] = useState(true);
  const [blockAutoSave, setBlockAutoSave] = React.useState(false);
  const [showConnectionWarning, setShowConnectionWarning] =
    React.useState(false);
  const [showEmptyPidWarning, setShowEmptyPidWarning] = React.useState(false);
  const [newForm, setNewForm] = React.useState(false);

  const [showAutoPidTooltip, setShowAutoPidTooltip] = React.useState(false);

  const { state = {} } = props.location;
  const [franchiseData, setFranchiseData] = useState(null);

  React.useEffect(() => {
    if (state.formValues) {
      setTimeout(() => {
        setAllValues(state.formValues);
        toast.info('The proposal was duplicated successfully.');
        window.scrollTo(0, 0);
      }, 2000);
    }
  }, [state]);

  const setBreakpoints = () => {
    setMinorThan768(window.innerWidth < 768);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useEffect(() => {
    if (!franchiseId && bidFullData.FranchiseId) {
      props.history.push(
        `${props.location.pathname}${props.location.search}&franchiseId=${bidFullData.FranchiseId}`
      );
    }
  }, [franchiseId, bidFullData.FranchiseId]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  React.useEffect(() => {
    const message =
      'This form is designed to be rendered on iPad sized screens or larger. Utilizing this form on a smaller screen will impact its usability. It is strongly recommended to change to larger device before proceeding.';
    if (minorThan768) {
      setWarningModal({
        message: message,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (warningModal && warningModal.message == message) {
        setWarningModal(null);
      }
    }
  }, [minorThan768]);

  React.useEffect(() => {
    const getUser = async () => {
      if (!user && userId) {
        const userData = await axiosInstance.get(
          `GetUserById?userid=${userId}`
        );
        setUser(userData.data);
      }
    };
    getUser();
  }, [user, userId]);

  const onOpenSearch = () => {
    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(null);
    setExecuteAutoSave(null);
    setReady(false);

    setShowMenu({
      renderTemplateSection: true,
      renderDraftSection: true,
      renderSentSection: true,
      renderSignedSection: true,
      title: 'SPECIALTY SERVICE SHEET',
    });
  };

  React.useEffect(() => {
    if (
      userSignNowStatus != null &&
      userSignNowStatus != undefined &&
      userSignNowStatus != userStatus
    ) {
      setUserStatus(userSignNowStatus == 1 ? 2 : userSignNowStatus);
    }
  }, [userSignNowStatus]);

  React.useEffect(() => {
    const openingPDF =
      getQueryString(window.location.href, 'openingPDF') === 'true';

    if (isWebView() && openingPDF) {
      props.history.goBack();
    }
  }, [props.history]);

  const showDeleteConfirmationModal = (
    proceedFunction: Function,
    isPlural: boolean = false
  ) => {
    setWarningModal({
      message: isPlural
        ? 'Are you sure you want to delete these items?'
        : 'Are you sure you want to delete this item?',
      proceed: () => {
        proceedFunction();
        setWarningModal(null);
      },
      dismiss: () => setWarningModal(null),
    });
  };

  // React.useEffect(() => {
  //   setFranchiseId(values.cityId);
  // }, [values.cityId]);

  const navigateToForm = async ({ BidUniqueID }) => {
    const data = await loadNonTemp(BidUniqueID);
    const {
      BidUniqueID: bidUniqueId,
      CreatedBy: createdBy,
      FlgSupply: flgSupply,
      FlgReference: flgReference,
      ...Data
    } = data.data.Data;
    setBidData({
      ...bidData,
      ...Data,
      BidUniqueID: !easterEgg ? bidFullData.BidUniqueID : null,
      CreatedBy: !easterEgg ? bidFullData.CreatedBy : null,
      FlgSupply: !easterEgg ? bidFullData.FlgSupply : null,
      FlgReference: !easterEgg ? bidFullData.FlgReference : null,
      ExpirationDays: !easterEgg ? values.expirationDays : null,
    });
    setBidFullData({
      ...bidFullData,
      ...Data,
      BidUniqueID: !easterEgg ? bidFullData.BidUniqueID : null,
      CreatedBy: !easterEgg ? bidFullData.CreatedBy : null,
      FlgSupply: !easterEgg ? bidFullData.FlgSupply : null,
      FlgReference: !easterEgg ? bidFullData.FlgReference : null,
      ExpirationDays: !easterEgg ? values.expirationDays : null,
    });
    setShowLoadTemplateModal(false);
    const newValues = parseFromApi(data.data['Data']);
    setAllValues({
      ...newValues,
      uid: values.uid,
      BidUniqueID: !easterEgg ? bidFullData.BidUniqueID : null,
      BidInformationSheetSupplys: Data.BidInformationSheetSupplys,
      FlgSupply: !easterEgg ? bidFullData.FlgSupply : null,
      FlgReference: !easterEgg ? bidFullData.FlgReference : null,
      expirationDays: !easterEgg ? values.expirationDays : null,
      BidInformationSheetReferences: Data.BidInformationSheetReferences,
      pid: values.pid,
      sid: values.sid,
      control: values.control,
      bidDate: values.bidDate,
      businessName: values.businessName,
      address: values.address,
      suite: values.suite,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      contactPhoneExtension: values.contactPhoneExtension,
      county: values.country,
      decisionMakerFirstName: values.decisionMakerFirstName,
      decisionMakerLastName: values.decisionMakerLastName,
      walkThruFirstName: values.walkThruFirstName,
      walkThruLastName: values.walkThruLastName,
      decisionMakerTitle: values.decisionMakerTitle,
      walkThruTitle: values.walkThruTitle,
      decisionMakerEmail: values.decisionMakerEmail,
      walkThruEmail: values.walkThruEmail,
      decisionMakerPhoneType: values.decisionMakerPhoneType,
      decisionMakerPhone: values.decisionMakerPhone,
      decisionMakerPhoneExtension: values.decisionMakerPhoneExtension,
      walkThruPhoneType: values.walkThruPhoneType,
      walkThruPhone: values.walkThruPhone,
      walkThruPhoneExtension: values.walkThruPhoneExtension,
      proposalAddressedTo: values.proposalAddressedTo,
      sic: values.sic,
      isCityTemplate: values.isCityTemplate,
      isTemplate: !!values.isTemplate,
      roomInventory: newValues.inventory || [],
      sentStatus: values.sentStatus,
      terms: null,
      externalId: bidFullData.ExternalId,
      physicalURL: values.physicalURL,
      createdBy: bidFullData.CreatedBy,
    });
  };

  const processSS = async (foundValues, askIfTemplate) => {
    const formId = getQueryString(search, 'formId');
    const urlTabId = getQueryString(search, 'tabId');

    if (!!urlTabId) {
      setTabId(urlTabId);
    }

    const validTabId = urlTabId || tabId;
    setBidFullData(foundValues.data['Data']);
    const parsedValues = parseFromApi(foundValues.data['Data']);

    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';

    if (askIfTemplate && parsedValues.isTemplate && !easterEgg) {
      const askForTemplateMode = () =>
        setWarningModal({
          title: 'Template',
          message:
            'Do you want to edit or start a new draft based on the current template?',
          proceed: async () => {
            setIsLoading(true);
            // setBlockAutoSave(true);
            setExecuteAutoSave(null);
            setReady(false);

            parsedValues.tabId = tabId;
            const saveDraftResponse = await autoSave(parsedValues, userId);

            window.location.replace(
              `#${
                parsedValues.isSpecialty ? '/specialty-form' : '/bid-info-form'
              }?userId=${userId}&franchiseId=${franchiseId}&formUniqueId=${
                saveDraftResponse.data.id
              }&tabId=${tabId}&v=${Math.random().toString(36).substring(7)}`
            );
            // setIsLoading(true);
            // setWarningModal(null)
          },
          dismiss: () => {
            fetch(false);
            setIsLoading(true);
            setWarningModal(null);
          },
          confirmText: 'NEW DRAFT',
          dismissText: 'EDIT TEMPLATE',
        });

      if (parsedValues.isCityTemplate) {
        if (!isCityUser) {
          // setError({status: 401})
          // return;
          setIsLoading(false);
          setWarningModal({
            title: 'Template',
            message: 'This will crate a new draft based on your City template',
            proceed: async () => {
              setIsLoading(true);
              setBlockAutoSave(true);
              setExecuteAutoSave(null);
              setReady(false);

              parsedValues.tabId = tabId;
              const saveDraftResponse = await autoSave(parsedValues, userId);
              window.location.replace(
                `#${
                  parsedValues.isSpecialty
                    ? '/specialty-form'
                    : '/bid-info-form'
                }?userId=${userId}&franchiseId=${franchiseId}&formUniqueId=${
                  saveDraftResponse.data.id
                }&tabId=${tabId}&v=${Math.random().toString(36).substring(7)}`
              );
              // setIsLoading(true);
              // setWarningModal(null)
            },
          });
        } else {
          setIsLoading(false);
          askForTemplateMode();
        }
      } else {
        setIsLoading(false);
        askForTemplateMode();
      }
      return;
    }

    const userData = await axiosInstance.get(`GetUserById?userid=${userId}`);
    setUser(userData.data);
    if (easterEgg) {
      parsedValues['uid'] = null;
      parsedValues['isTemplate'] = false;
      parsedValues['sentStatus'] = false;
      parsedValues['status'] = 0;
      parsedValues['bidInfoId'] = null;
      parsedValues['originalBidInfoId'] = null;
      parsedValues['createdBy'] = userId;
      parsedValues['userId'] = userId;
      parsedValues['bidDate'] = null;
      parsedValues['isExpired'] = false;
      parsedValues['createdByName'] = userData.data['Name'];
      parsedValues['expirationDays'] = 90;
    }

    let userHasBetaOptIn = false;
    let userSignNowStatus = -1;
    if (
      userData.data['IsOptIn'] != null &&
      userData.data['IsOptIn'] != undefined
    ) {
      userHasBetaOptIn = userData.data['IsOptIn'];
    }
    userSignNowStatus =
      userData.data['SignatureProviderStatus'] != null
        ? userData.data['SignatureProviderStatus']
        : -1;

    const openForm = (tempValues = false) => {
      const v = tempValues || parsedValues || {};
      setIsLoading(true);
      setTimeout(() => {
        setAllValues({
          ...v,
          userSignNowStatus,
          userHasBetaOptIn,
        });

        setNeedsSaving(false);
        setIsLoading(false);
        // if (tempValues && v.status !== 2) {
        //   toast.info('Draft Auto-Save Enabled');
        //   startAutoSaveTimeout();
        // }
        setTimeout(() => {
          setAutoSaveTimeout(1);
          setHadChanges(false);
          setReady(true);
          EventEmitter.emit('VALIDATE_FIELDS', '');
        }, 1500);
      }, 1000);

      setShowAlreadySignedModal(v.status === 2);
      setShowExpiredBid(v.isExpired && v.status != 2);
      setWarningModal(null);
    };

    const continueFlow = async () => {
      if (foundValues.data['Data']['Status'] != 2 && !easterEgg) {
        try {
          if (foundValues.data['Data']['BidUniqueID'] && validTabId) {
            await isTabIdValid(
              foundValues.data['Data']['BidUniqueID'],
              validTabId
            );
          } else {
            await isAlive();
          }
        } catch (e) {
          const msg =
            e &&
            e.response &&
            e.response.data &&
            typeof e.response.data == 'string'
              ? e.response.data
              : undefined;

          setTimeout(() => {
            handleError(e);
          }, 0);
          openForm();
          logSentry(e, values);
          return;
        }
      }

      const tempValues = foundValues.data['DataTemp'];

      if (
        tempValues &&
        foundValues.data['Data']['Status'] != 2 &&
        !easterEgg &&
        !foundValues.data['Data']['IsTemplate']
      ) {
        setIsLoading(false);
        setWarningModal({
          message:
            'We found a draft for this form on the server. Do you want to start from where stopped?',
          proceed: () => {
            setIsLoading(true);

            const parsedTempValues = tempValues
              ? parseFromApi(tempValues)
              : null;

            openForm({
              ...parsedTempValues,
              uid: parsedValues.uid,
              bidInfoId: parsedValues.bidInfoId,
              isTemp: parsedValues.isTemp,
              originalBidUid: parsedValues.originalBidUid,
              sentStatus: parsedValues.sentStatus,
              isTemplate: !!parsedValues.isTemplate,
              isAutoSave: false,
            });

            setTempData(parsedTempValues);
            // setShowAutoPidTooltip(true);
          },
          dismiss: async () => {
            setIsLoading(true);

            setTempData(null);

            setWarningModal(null);

            openForm();
          },
          confirmText: 'YES',
          dismissText: 'NO',
        });
      } else {
        if (!!parsedValues.sentStatus) {
          openForm();
        } else {
          openForm();
          setTimeout(() => {
            setTempData(null);
            // setShowAutoPidTooltip(true);
            setAutoSaveTimeout(1);
          }, 1500);
        }
      }
    };

    continueFlow();
  };

  React.useEffect(() => {
    const formId = getQueryString(search, 'formId');
    const urlTabId = getQueryString(search, 'tabId');

    if (!!urlTabId) {
      setTabId(urlTabId);
    }

    const validTabId = urlTabId || tabId;

    const fetch = async (askIfTemplate = true) => {
      const goGetIt = easterEgg
        ? () => getEasterEggSSTemplate()
        : uniqueId
        ? () => getByUniqueIDNew(uniqueId)
        : () => getBidInfoById(formId);

      try {
        await isTabIdValid(uniqueId, tabId);
        const foundValues = await goGetIt();
        processSS(foundValues, askIfTemplate);
      } catch (err) {
        if (err.status === 300) {
          setIsLoading(false);
          setWarningModal({
            message:
              'We found a draft for this form on the server. Do you want to start from where stopped?',
            proceed: async () => {
              setIsLoading(true);
              const getDraft = await loadTemp(uniqueId);
              processSS(getDraft, askIfTemplate);
            },
            dismiss: async () => {
              setIsLoading(true);
              const getBid = await loadNonTemp(uniqueId);
              processSS(getBid, askIfTemplate);
            },
            confirmText: 'YES',
            dismissText: 'NO',
          });
        }
        if (err.status === 409) {
          handleError(err);
          setIsLoading(false);
        }
      }
    };

    if (formId || uniqueId || easterEgg) {
      fetch().catch((e) => {
        logSentry(e, { uid: formId || uniqueId || 'easterEgg' });
        if (e.status === 500) {
          props.history.push(`/forms/unable-to-open`);
        }
      });
      setNewForm(false);
    } else {
      setShowNoBidUniqueID(true);
      setTempData(null);
      setAutoSaveTimeout(null);
      setShowAutoPidTooltip(true);
      setNewForm(true);
      setHadChanges(false);
    }
  }, [search, easterEgg]);

  React.useEffect(() => {
    if (franchiseId) {
      setFieldValue('franchiseId', franchiseId);
      getFranchiseById(franchiseId).then(({ data }) => {
        setFranchiseData(data);
        setTimeout(() => {
          if (newForm) {
            setHadChanges(false);
            setReady(true);
          }
        }, 1000);
      });
    }
  }, [franchiseId, newForm]);

  const confirmationProps = {
    isOpen: showConfirmation,
    setWarningModal,
    showDeleteConfirmationModal,
    onSaveButtonClick: async (data) => {
      setIsLoading(true);
      let serverData = await loadNonTemp(bidData.id);
      serverData = serverData.data['Data'];
      const values = parseGetToPost(serverData);

      let referenceData = [];
      let supplyData = [];
      const referenceDetailsData =
        data?.BidInformationSheetReferences?.[0]?.ReferenceDetails?.filter(
          (item) => item.Default
        );

      if (data?.BidInformationSheetReferences?.[0]) {
        referenceData = [
          {
            ...data.BidInformationSheetReferences[0],
            ReferenceDetails: referenceDetailsData,
          },
        ];
      }
      const supplyDetailsData =
        data?.BidInformationSheetSupplys?.[0]?.SupplyDetails?.filter(
          (item) => item.Default
        );

      if (data?.BidInformationSheetSupplys?.[0]) {
        supplyData = [
          {
            ...data.BidInformationSheetSupplys[0],
            SupplyDetails: supplyDetailsData,
          },
        ];
      }

      const obj = {
        ...values,
        GenerateBlankPDF: false,
        GenerateAttachmentsPDF: true,
        SelectedAttachments: data.attachments.others,
        SelectedSystemAttachments: data.attachments.systemAttachments,
        SelectedFranchiseAttachments: data.attachments.franchiseAttachments,
        CreatedBy: serverData['CreatedBy'] || userId,
        Supply: data.Supply,
        Reference: data.Reference,
        FlgProtectionDisinfectionPlan: data.FlgProtectionDisinfectionPlan,
        FlgSupply: data.FlgSupply,
        IsTemplate: !!data.IsTemplate,
        IsCityTemplate: !!data.IsCityTemplate,
        FlgSupplyCustomize: data.FlgSupplyCustomize,
        BidInformationSheetSupplys: supplyData,
        FlgReference: data.FlgReference,
        FlgReferenceCustomize: data.FlgReferenceCustomize,
        BidInformationSheetReferences: referenceData,
        BidInformationSheetSupplyId: data.BidInformationSheetSupplyId,
      };

      if (data.attachments) {
        if (
          data.attachments.others.filter((i) => i != '' && i != 'industry-na')
            .length === 0 &&
          data.attachments.systemAttachments.length === 0 &&
          data.attachments.franchiseAttachments.length === 0
        ) {
          obj.GenerateAttachmentsPDF = false;
        }
      }

      if (Object.keys(data.attachments.oneTimeAttachment).length > 0) {
        await uploadOneTimeAttachment(
          bidData.id,
          data.attachments.oneTimeAttachment
        );
        obj.IncludeOneTimeAttachment = true;
      }

      const postResponse: IAPIResponse = await postBidInfo(obj);
      const formInfo: IAPIResponse = await loadNonTemp(bidData.id);
      const formInfoData = formInfo.data['Data'];
      const emails = data.emailProposal;
      const emailBody = decodeEntities(stripHTMLTags(formInfoData['BidEmail']));
      setBidData(postResponse.data as any);
      setSuccessModalData({
        ...successModalData,
        emails,
        emailBody,
        history: props.history,
        bidLink: formInfoData['BidLink'],
        franchiseId: formInfoData['FranchiseId'],
        formUniqueId: postResponse.data.id,
      });
      setShowSuccessModal(true);
      setIsLoading(false);
    },
    onBackButtonClick: async (data, terms) => {
      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, values);
      }

      setBlockAutoSave(true);
      setReady(false);

      setFieldValue('terms', terms);

      setTimeout(() => {
        setBlockAutoSave(false);
        setReady(true);
        setShowConfirmationModal(false);
      });
    },
    onCustomizeTermsButtonClick() {},
    success: showSuccessModal,
    successModalData,
    bidData,
    bidFullData,
    isSpecialty: true,
    franchiseId: franchiseId,
    franchiseData: franchiseData,
  };

  const doPost = async (
    values,
    callback = () => undefined,
    showToastr = true
  ) => {
    const response = await updateBid({
      values,
      userId,
      showToastr,
    });
    if (response.error) {
      setWarningModal({
        message: response.error as string,
        proceed: () => {
          setWarningModal(null);
        },
      });
      return false;
    }
    if (response.data && !values.isTemplate && !values.isCityTemplate) {
      // needs refactoring
      if (values.isDuplicate) {
        window.location.replace(
          `/#/specialty-form?userId=${userId}&formUniqueId=${
            response.data.id
          }&franchiseId=${franchiseId}&tabId=${values.tabId}&v=${Math.random()
            .toString(36)
            .substring(7)}`
        );
        setWarningModal(null);
        window.location.reload();
        return;
      }
      const resp = await loadNonTemp(response.data.id);
      setBidFullData(resp.data.Data);
      setFieldValue('uid', response.data.id);
      setBidData(response.data as any);
      setAllValues({
        ...values,
        uid: response.data.id,
        sentStatus: true,
        isTemplate: false,
      });
      setSuccessModalData({
        ...successModalData,
        formUniqueId: response.data.id,
        userId,
      });

      if (easterEgg) {
        setEasterEggCreated(true);
      }
    }

    callback(response.data);
    return response.data;
  };

  const openProposalPDF = () => {
    // const url = `${getBaseURL()}/Pdf/Bid-Signed/AnagoProposal-${
    //   values.uid
    // }.pdf`;
    // window.location.href = url;
    // if (isWebView()) {
    //   props.history.push(
    //     `/bid-info-form/new-pdf-view?formUniqueId=${values.uid}&openingNewBidPDF=true`
    //   );
    // } else {
    //   window.open(
    //     `${getBaseURL()}/Pdf/Bid-Signed/AnagoProposal-${values.uid}.pdf`,
    //     '_blank'
    //   );
    //   // props.history.push(
    //   //   `/bid-info-form/new-pdf-view?formUniqueId=${values.uid}&openingNewBidPDF=true`
    //   // );
    // }
    setShowPdf(true);
  };

  const onDuplicateProposal = async () => {
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e);
      logSentry(e, values);
      return;
    }

    const proceed = () => {
      setIsLoading(true);
      // setTimeout(() => {
      //   props.history.push('/loading');
      // }, 0);

      // setTimeout(() => {
      //   props.history.push({
      //     pathname: '/specialty-form',
      //     search: `?userId=${userId}&franchiseId=${franchiseId}&isDuplicate=true`,
      //     state: {
      //       formValues: {
      //         ...values,
      //         pid: null,
      //         uid: null,
      //         id: null,
      //         bidInfoId: null,
      //         isTemplate: false,
      //         isCityTemplate: false,
      //         sentStatus: false,
      //         physicalURL: null,
      //         isPhysicalSigned: null,
      //         signatureDate: null,
      //         terms: null,
      //         status: null,
      //         externalId: null,
      //       },
      //     },
      //   });
      // }, 0);
      onCompleteButtonClick({
        isCityTemplate: false,
        isOverwritten: false,
        isTemplate: false,
        isDuplicate: true,
        submitSignedContract: false,
        saveAndComplete: false,
      });
    };

    if (values.status == 2) {
      if (values.bidVersion == 1) {
        setWarningModal({
          title: 'WARNING: Removing Bid Overrides',
          message: (
            <>
              This proposal was created using previous version of Anago
              CleanSource®. The duplicate of this proposal will clear any Bid
              Overrides entered previously.
            </>
          ),
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      } else {
        proceed();
      }
    } else {
      if (values.bidVersion == 1) {
        setWarningModal({
          title: 'WARNING: Removing Bid Overrides',
          message: (
            <>
              This proposal was created using previous version of Anago
              CleanSource®. The duplicate of this proposal will clear any Bid
              Overrides entered previously.
              <br />
              <br />
              Proceeding will lose any unsaved changes. Are you sure you will
              wish to proceed?"
            </>
          ),
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      } else {
        setWarningModal({
          message:
            'Proceeding will lose any unsaved changes. Are you sure you will wish to proceed?',
          dismiss: () => setWarningModal(null),
          proceed: proceed,
        });
      }
    }
  };

  const onCompleteButtonClick = async (
    {
      saveAndComplete = true,
      submitSignedContract = false,
      isOverwritten,
      isCityTemplate,
      isTemplate,
      isDuplicate = false,
    } = {},
    callback = () => {}
  ) => {
    setBlockAutoSave(true);
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e);
      callback();
      setBlockAutoSave(false);
      logSentry(e, values);
      return;
    }

    if (values.status === 2 && !isTemplate && !isDuplicate) {
      openProposalPDF();
      callback();
      setBlockAutoSave(false);
      return;
    }

    if (!isDuplicate) {
      const errors =
        isCityTemplate || isTemplate
          ? validateSaveTemplate(values)
          : validateForm(values, true);
      const hasErrors =
        errors.required.length > 0 || errors.incorrect.length > 0;
      setErrors(hasErrors ? errors : {});
      if (hasErrors) {
        callback();
        setBlockAutoSave(false);
        return;
      }
    }

    if (values.uid && saveAndComplete) {
      try {
        const blankPdfResult = await checkIsGeneratingPDF(values.uid);
      } catch (e) {
        const generating = e.data['isGenerating'];
        if (generating) {
          setWarningModal({
            message:
              'Previous PDF Version still being generated. Please try again.',
            proceed: () => {
              setWarningModal(null);
            },
          });
          callback();
          setBlockAutoSave(false);
          return;
        }
      }
    }

    clearTimeout(autoSaveTimeout);
    setAutoSaveTimeout(null);
    setExecuteAutoSave(null);

    values.tabId = tabId;

    // Cleaning Background Data
    if (values.frequencyService === 'monthly') {
      values.frequency = values.secondFrequency = {
        selecteds: [],
        timeWindow: null,
      };
      values.addFrequency = false;
      values.smartClean = false;
      values.disinfection = null;
      values.secondDisinfection = null;
    } else {
      values.cleaning = null;
      values.secondCleaning = null;
      values.timeWindow = null;
      values.secondTimeWindow = null;
      values.monthlyDisinfection = null;
      values.secondMonthlyDisinfection = null;
      values.monthlyAddFrequency = null;
    }
    if (values.uid && values.isTemplate) {
      values.uid = null;
    }
    values.sentStatus =
      values.isTemplate || values.isCityTemplate ? false : true;
    values.isSpecialty = true;

    if (saveAndComplete || submitSignedContract) {
      values.generateBlankPDF = true;
    } else {
      values.generateBlankPDF = false;
    }

    values.additionalQuotesTotal = 0;

    if (
      values.quotes.filter(
        (item) => item.unit || item.uponRequest || item.perHour || item.perMonth
      ).length > 0
    ) {
      values.additionalQuotesTotal = `${values.quotes.length} - Special Pricing`;
    } else {
      values.quotes
        .filter((item) => !!item.quoteSelect)
        .map((item) => {
          values.additionalQuotesTotal += parseFloat(item.quoteOverride)
            ? parseFloat(item.quoteOverride)
            : parseFloat(item.quoteTotal) || 0;
        });
      if (values.quotes.filter((item) => !!item.quoteSelect).length > 0) {
        values.additionalQuotesTotal = `${
          values.quotes.filter((item) => !!item.quoteSelect).length
        } - $${formatNumberByMask(values.additionalQuotesTotal, {
          decimalLimit: 2,
          thousandsSeparatorSymbol: ',',
        })}`;
      } else {
        values.additionalQuotesTotal = null;
      }
    }

    //set TabId before save
    values.tabId = tabId;
    values.bidVersion = 2;
    values.isAutoSave = false;
    values.isTemplate = isTemplate;
    values.isCityTemplate = isCityTemplate;

    if (Object.entries(bidFullData).length > 0) {
      if (
        bidFullData.BidUniqueID &&
        (!easterEgg || (easterEgg && easterEggCreated))
      ) {
        values.BidUniqueID = bidFullData.BidUniqueID;
      }
      values.FlgSupply = bidFullData.FlgSupply;
      values.FlgSupplyCustomize = bidFullData.FlgSupplyCustomize;
      values.BidInformationSheetSupplys =
        bidFullData.BidInformationSheetSupplys;

      values.FlgReference = bidFullData.FlgReference;
      values.FlgReferenceCustomize = bidFullData.FlgReferenceCustomize;
      values.BidInformationSheetReferences =
        bidFullData.BidInformationSheetReferences;

      values.FlgProtectionDisinfectionPlan =
        bidFullData.FlgProtectionDisinfectionPlan;
    }

    values.tempBidUniqueID = values.BidUniqueID;
    values.tempUid = values.uid;

    if (isOverwritten && (isCityTemplate || isTemplate)) {
      values.tempBidUniqueID = values.newBidUniqueID;
    }
    if (!isOverwritten && (isCityTemplate || isTemplate)) {
      values.tempBidUniqueID = undefined;
      values.tempUid = undefined;
    }

    if (values.status !== 2 && values.newReferenceName) {
      values.referenceName = values.newReferenceName;
    }

    let templateObj = {};

    if (isTemplate) {
      templateObj = {
        physicalURL: null,
        isPhysicalSigned: null,
        signatureDate: null,
        status: null,
        referenceName: values.newReferenceName,
        pid: '',
      };
    }

    if (isTemplate || isCityTemplate) {
      templateObj = {
        ...templateObj,
        sentStatus: false,
      };
    }

    setIsLoading(true);

    const data = await doPost(
      {
        ...values,
        BidUniqueID: values.tempBidUniqueID,
        uid: values.tempUid,
        ...templateObj,
        isDuplicate,
        isOverwritten,
      },
      () => {},
      false
    );

    setHadChanges(false);

    setIsLoading(false);

    if (data) {
      setTempData(null);
      if (saveAndComplete) {
        setShowConfirmationModal(true);
      } else if (submitSignedContract) {
        props.history.push(
          `proposal/physicalContract?formUniqueId=${data.id}&franchiseId=${franchiseId}&physicalContract=true`
        );
      }

      let successMessage = '';

      if (values.isCityTemplate) {
        if (isOverwritten) {
          successMessage = 'City Template was overwritten successfully';
        } else {
          successMessage =
            'City Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
        }
      } else if (values.isTemplate) {
        if (isOverwritten) {
          successMessage = 'My Template was overwritten successfully';
        } else {
          successMessage =
            'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
        }
      } else if (values.sentStatus) {
        successMessage = 'Your sent proposal was saved successfully';
      } else {
        successMessage = 'Your draft was saved successfully';
      }

      toast.info(successMessage);

      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 100);

      // setTimeout(() => {
      //   props.history.push(`/specialty-form/summary-success/${data.id}`);
      // }, 1000);

      callback();

      setTimeout(() => {
        setBlockAutoSave(false);
        setAutoSaveTimeout(1);
      }, 0);
    } else {
      callback();
    }
  };

  const onSaveDraft = React.useCallback(
    async (isAutoSave = false, tempData = null) => {
      const v = tempData || values;

      try {
        if (values.uid && tabId) {
          await isTabIdValid(values.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e, isAutoSave);
        logSentry(e, values);
        return { error: true };
      }

      if (!isAutoSave) {
        setIsLoading(true);
      } else {
        if (showConnectionWarning) {
          setShowConnectionWarning(false);
          const message =
            bidFullData.FirstSaveFromImport == '1'
              ? 'Auto-Save Enabled'
              : 'Draft Auto-Save Enabled';
          toast.info(message);
        }
      }

      v.isSpecialty = true;
      v.userId = userId;
      //set TabId before save
      v.tabId = tabId;
      if (v.uid) {
        v.BidUniqueID = v.uid;
      }

      if (!isAutoSave) {
        v.isAutoSave = false;
      }

      setCompleteButtonEnabled(false);
      let response;
      if (isAutoSave) {
        response = await autoSave(v, userId, isAutoSave);
      } else {
        response = await updateBid({ values: v, userId, showToastr: true });
      }
      setCompleteButtonEnabled(true);
      setHadChanges(false);
      setReady(false);
      if (response.data) {
        if (!isAutoSave) {
          setFieldValue('uid', response.data.id);
          setBidData(response.data as any);
        } else {
          setFieldValue('isAutoSave', true);
        }
      } else if (response.error) {
        toast.error(
          isAutoSave
            ? 'Auto-save failed. Please reload the page.'
            : response.error
        );
      }

      if (!isAutoSave) {
        setIsLoading(false);
      }

      setTimeout(() => {
        setReady(true);
      }, 0);

      return response;
    },
    [
      values,
      isAlive,
      setWarningModal,
      setIsLoading,
      saveDraft,
      setFieldValue,
      setHadChanges,
      setBidData,
      setShowConnectionWarning,
      showConnectionWarning,
    ]
  );

  const onSaveTemplate = async (
    isCityTemplate = false,
    isSaveAs = false,
    isOverwritten = false
  ) => {
    setBlockAutoSave(true);
    setExecuteAutoSave(null);
    const isCityUser = getCookie('ANAGO_USER_IS_CITY_USER') === 'true';
    try {
      const newValues =
        isCityTemplate && !isCityUser
          ? JSON.parse(JSON.stringify(values))
          : values;

      if (isCityTemplate && !isCityUser) {
        newValues.uid = null;
        newValues.bidInfoId = null;
      }

      try {
        if (newValues.uid && tabId) {
          await isTabIdValid(newValues.uid, tabId);
        } else {
          await isAlive();
        }
      } catch (e) {
        handleError(e);
        logSentry(e, newValues);
        return;
      }

      const proceed = async () => {
        try {
          setWarningModal(null);
          setIsLoading(true);

          onCompleteButtonClick({
            saveAndComplete: false,
            isOverwritten,
            isCityTemplate,
            isTemplate: true,
          });
        } catch (error) {
          setGenericError(error);
        }
      };

      proceed();
    } catch (e) {
      setGenericError(e);
    }
  };

  React.useEffect(() => {
    if (bidFullData?.Status !== 2 && franchiseData) {
      if (bidFullData['HasAlreadyGenerateDocForThisDocument']) {
        getSignNowStatus(bidFullData['BidUniqueID'])
          .then(({ data: result }) => {
            console.log(result['Status'], 'status');
            if (result['Status'] != 'true') {
              setWarningModal({
                message:
                  "You're trying to edit an ongoing Specialty Service, doing so will invalidate any progress the client may have done.",
                dismissText: 'Cancel',
                confirmText: 'Confirm',
                proceed: () =>
                  setInvalidDocument(bidFullData['BidUniqueID']).then(
                    (result) => {
                      window.location.reload();
                    }
                  ),
                dismiss: () => {
                  window.close();
                },
                isVisible: true,
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [bidFullData, franchiseData]);

  React.useEffect(() => {
    if (executeAutoSave && !blockAutoSave && values.status !== 2) {
      if (!values.pid) {
        setTimeout(() => {
          startAutoSaveTimeout();
        });
        return;
      }

      const save = async () => {
        // const hasTemp = !!tempData && Object.keys(tempData).length > 0;
        // let tValues = null;
        // debugger;
        // if (hasTemp) {
        //   tValues = {
        //     ...values,
        //     uid: tempData.uid,
        //     bidInfoId: tempData.bidInfoId,
        //     originalBidUid: tempData.originalBidUid,
        //     isTemp: true,
        //     userId: userId,
        //     isAutoSave: true,
        //   };
        // } else if (values.uid && !!values.sentStatus) {
        //   tValues = {
        //     ...values,
        //     uid: null,
        //     bidInfoId: null,
        //     originalBidUid: values.uid,
        //     isTemp: true,
        //     userId: userId,
        //     isAutoSave: true,
        //   };
        // } else {
        //   tValues = { ...values, userId: userId, isAutoSave: true };
        // }

        // if (
        //   (hasTemp && !compareValues(tValues, tempData)) ||
        //   (!hasTemp && hadChanges)
        // ) {
        if (!values.isTemplate && !values.isCityTemplate) {
          const response = await onSaveDraft(true, { ...values });
          if (!response.error) {
            // console.error('saved draft! id:' + response.data.id);
            // if (!hasTemp && !values.sentStatus) {
            //   setFieldValue('uid', response.data.id);
            // } else {
            //   tValues.uid = response.data.id;
            //   setTempData(tValues);
            // }
            setTimeout(() => {
              setHadChanges(false);
            }, 0);
          } else {
            if (response.error !== true) {
              // toast.error('Error trying to Auto-Save');
              console.error('Error:' + response.error);

              if (response.status == '409') {
                setTempData(null);
                setAutoSaveTimeout(null);
                setExecuteAutoSave(null);
                clearTimeout(autoSaveTimeout);
                setBlockAutoSave(true);

                setWarningModal({
                  title: 'Warning!',
                  message:
                    'Autosave did not complete correctly. Please manually save this form to prevent loss of any data.',
                  proceed: () => setWarningModal(null),
                });
              }
            }
          }
        }
        // }
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      };
      save();
    }
  }, [executeAutoSave]);

  const startAutoSaveTimeout = React.useCallback(() => {
    // console.error('starting auto-save timeout');
    setAutoSaveTimeout(
      setTimeout(() => {
        if (!blockAutoSave) {
          setExecuteAutoSave(Math.random());
        }
      }, 10 * 1000)
    );
  }, [hadChanges, blockAutoSave]);

  const initAutoSave = async () => {
    if (values.isTemplate) {
      return;
    }
    setShowEmptyPidWarning(false);
    try {
      if (values.uid && tabId) {
        await isTabIdValid(values.uid, tabId);
      } else {
        await isAlive();
      }
    } catch (e) {
      handleError(e, true);
      logSentry(e, values);
      if (!executeAutoSave) {
        setBlockAutoSave(false);
        setTimeout(() => {
          startAutoSaveTimeout();
        });
      }
      return;
    }

    setShowConnectionWarning(false);
    if (!executeAutoSave) {
      setBlockAutoSave(false);
      setTimeout(() => {
        startAutoSaveTimeout();
      });
      const message =
        bidFullData.FirstSaveFromImport == '1'
          ? 'Auto-Save Enabled'
          : 'Draft Auto-Save Enabled';
      toast.info(message);
    }
  };

  const initAutoSaveOnBlur = () => {
    if (values.status != 2) {
      if (values.pid && !showEmptyPidWarning) {
        if (!autoSaveTimeout || autoSaveTimeout == 1) {
          initAutoSave();
        }
      }
    }
  };

  React.useEffect(() => {
    if (ready && !document.querySelector('.save-template-modal')) {
      if (values.status != 2 && (!hadChanges || showConnectionWarning)) {
        if (autoSaveTimeout == 1) {
          if (values.uid && !showConnectionWarning) {
            if (!values.pid) {
              setShowEmptyPidWarning(true);
            } else {
              setHadChanges(true);
              initAutoSave();
            }
          }
        } else if (autoSaveTimeout == null) {
          if (!values.pid && newForm) {
            setShowEmptyPidWarning(true);
          } else if (newForm && !showConnectionWarning) {
            initAutoSave();
          }
        }
      }
    }
  }, [values]);

  React.useEffect(() => {
    return () => {
      setAutoSaveTimeout(null);
      setExecuteAutoSave(null);
      clearTimeout(autoSaveTimeout);
    };
  }, []);

  const { error, setError } = useAuthenticationHandler();
  const { error: genericError, setError: setGenericError } =
    useGenericErrorHandler();

  React.useEffect(() => {
    if (!!error && error.status == 401) {
      setIsLoading(false);
      setWarningModal({
        title: 'Access Denied',
        message:
          'You do not have permissions to access this form. Please contact Anago Support if you believe you have reached this in error.',
        proceed: () => {
          localStorage.setItem(
            'anago_redirect_after_login',
            window.location.href
          );
          window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
            'login',
            'home'
          );
        },
      });
    } else if (!!error && error.status == 403) {
      try {
        if (error.data.includes('CLIENT_VERSION_MISMATCH')) {
          setIsLoading(false);
          setWarningModal({
            title: 'Attention',
            message:
              'Seems like your app is outdated. This may happen due to browser cache issues. Click OK to get the latest version from server.',
            proceed: () => {
              window.location.reload(true);
            },
          });
        }
      } catch (e) {
        setGenericError(e);
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (!!genericError) {
      setIsLoading(false);
      logSentry(genericError, values);
      setWarningModal({
        title: 'Attention',
        message:
          'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
          sessionStorage.getItem('transaction_id'),
        proceed: () => {
          setWarningModal(null);
          setGenericError(undefined);
        },
      });
    }
  }, [genericError]);

  const logSentry = useActivityTracking(() => values);

  const handleError = (e, showBanner = false) => {
    // debugger;
    const msg =
      e && e.response && e.response.data && typeof e.response.data == 'string'
        ? e.response.data
        : undefined;

    if (
      e &&
      e.response &&
      (e.response.status == '401' || e.response.status == '409')
    ) {
      setBlockingModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else if (e && e.response && e.response.status == '503') {
      setWarningModal({
        message: msg || e.response.status,
        proceed: () => {
          setWarningModal(null);
        },
      });
    } else {
      if (showBanner) {
        setShowConnectionWarning(true);
      } else {
        setWarningModal({
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            setWarningModal(null);
          },
        });
      }
    }
  };

  /// end

  React.useEffect(() => {
    (async () => {
      try {
        const result = await verifyServerUnavailable(userId);
        if (!result || result.error) {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        } else {
          setBlockingModal(null);
        }
      } catch (e) {
        if (e.response.status == '503') {
          setBlockingModal({
            message:
              'Server under maintenance at this time, please try again later',
          });
        }
      }
    })();
  }, []);

  const refreshIntegration = async (externalId) => {
    try {
      const userData = await axiosInstance.get(
        `BidSheet/GetByUniqueWaterStreetID/${externalId}`
      );

      const apiValues = parseWaterStreet(userData.data.WaterStreet);
      toast.info('Refreshed with success!');

      setValues({ ...values, ...apiValues });
    } catch (response) {
      setWarningModal({
        message: 'Error when try to refresh the Bid info',
        proceed: () => {
          setWarningModal(null);
        },
      });
    }
  };

  return (
    <FormProvider
      needsSaving={needsSaving}
      setNeedsSaving={setNeedsSaving}
      formValues={values}
      onChange={setFieldValue}
      setFormValues={setAllValues}
      showWarningModal={setWarningModal}
      showConfirmationModal={setShowConfirmationModal}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
    >
      <div className="inspection-root-div bid-form-root">
        <div className="inspection-content-screen bid-form-content-screen">
          {isLoading && <LoadingPage />}

          {/* <div style={{ display: showCustomizeTerms ? 'block' : 'none' }}>
            <CustomizeTerms
              location={{ search: `?formId=${bidData.id}` }}
              uuid={bidData.id}
              data={values}
              showing={showCustomizeTerms}
              franchiseId={franchiseId}
              setAllValues={setAllValues}
              onSave={(termsValues) => {
                setShowCustomizeTerms(false);
                setAllValues(termsValues);
              }}
              onCancel={() => setShowCustomizeTerms(false)}
            />
          </div> */}

          <ActionAlertModal
            message="This form has already been signed, and cannot be changed."
            isVisible={showAlreadySignedModal && !shouldShowSignNowModal}
            onConfirm={() => {
              setShowAlreadySignedModal(false);
            }}
          />

          <ActionAlertModal
            title="Uh oh, it appears that the link you attempted to access is no longer supported. Due to recent updates, certain links have been deprecated."
            message="We understand the inconvenience this may cause and sincerely apologize for disrupting your experience. If you need assistance, please contact AFI Support at Anagocleaning.com"
            isVisible={showNoBidUniqueID}
            titleStyle={{
              fontSize: '20px',
              textTransform: 'unset',
            }}
            onConfirm={() => {
              window.location.href = ANAGO_CLEANSOURCE_DEV_LOGIN_URL.replace(
                'login',
                'home'
              );
            }}
          />

          <ActionAlertModal
            message="This Proposal has expired. Making any changes will reactivate the Proposal."
            isVisible={showExpiredBid}
            onConfirm={() => {
              setShowExpiredBid(false);
            }}
          />

          {blockingModal && (
            <BlockingOverlay>
              <BlockingPopupModal message={blockingModal.message} />
            </BlockingOverlay>
          )}

          {shouldShowSignNowModal && (
            <BlockingOverlay>
              <SignNowScreen
                userId={userId}
                status={userStatus}
                setStatus={setUserStatus}
                setLoading={setIsLoading}
              />
            </BlockingOverlay>
          )}
          <Header
            showConnectionWarning={showConnectionWarning}
            showAutoPidTooltip={showAutoPidTooltip}
            showEmptyPidWarning={showEmptyPidWarning}
            setShowEmptyPidWarning={setShowEmptyPidWarning}
            initAutoSave={initAutoSaveOnBlur}
            onExit={() => setShowExit(true)}
            openSearch={onOpenSearch}
            setIsLoading={setIsLoading}
            setNeedsSaving={setNeedsSaving}
            title="Specialty Service(s) Info"
            searchButtonLabel="Open Specialty forms"
          />
          <ClientInfo refreshIntegration={refreshIntegration} />
          <div className="header inline">
            <h1 style={{ margin: 0, padding: '20px' }}>Bid Details</h1>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button
                disabled={values.status === 2}
                className="button rounded"
                style={{
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
                onClick={() => {
                  setShowLoadTemplateModal(true);
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
                Load template
              </button>
              {values.referenceName && (
                <div
                  className="bid-input-content"
                  style={{ width: '300px', padding: '10px 20px 10px 0' }}
                >
                  <div className="bid-input-label">Template Name</div>
                  <input
                    value={values.referenceName}
                    onChange={(e) =>
                      setFieldValue('referenceName', e.target.value)
                    }
                    maxLength={24}
                    className="input-header input-bid validate-pid"
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
          <ServiceProvider />
          <RoomInventory isSpecialty />
          <FloorTypeTotals />
          <SectionGroup title="ADDITIONAL PRODUCTS & SERVICES">
            <Supplies
              onChange={(newBidFullData) => {
                setFieldValue('flgSupply', newBidFullData.FlgSupply);
                setFieldValue(
                  'flgSupplyCustomize',
                  newBidFullData.FlgSupplyCustomize
                );
                setFieldValue(
                  'bidInformationSheetSupplys',
                  newBidFullData.BidInformationSheetSupplys
                );
                setBidFullData(newBidFullData);
              }}
              franchiseId={franchiseId}
              title="SUPPLIES"
              formUniqueId={uniqueId}
              sectionId="suppliesSection"
              isSpecialty
              bidFullData={bidFullData}
              canEdit={user && user.IsSuppliesAddEdit}
              isExpandedByDefault={bidFullData.Status !== 2}
            />
            <References
              onChange={(newBidFullData) => {
                setFieldValue('flgReference', newBidFullData.FlgReference);
                setFieldValue(
                  'flgReferenceCustomize',
                  newBidFullData.FlgReferenceCustomize
                );
                setFieldValue(
                  'bidInformationSheetReferences',
                  newBidFullData.BidInformationSheetReferences
                );
                setBidFullData(newBidFullData);
              }}
              franchiseId={franchiseId}
              title="GOOGLE REVIEWS, REFERENCES, & TESTIMONIALS"
              formUniqueId={uniqueId}
              sectionId="referencesSection"
              formUniqueId={uniqueId}
              bidFullData={bidFullData}
              canEdit={user && user.IsReferenceAddEdit}
              isExpandedByDefault={bidFullData.Status !== 2}
            />
            <ProtectionDisinfectionPlansSs
              bidFullData={bidFullData}
              onChangeFlag={(newBidFullData) => {
                setFieldValue(
                  'flgProtectionDisinfectionPlan',
                  newBidFullData.FlgProtectionDisinfectionPlan
                );
                setBidFullData(newBidFullData);
              }}
              title="ANAGO PROTECTION+ DISINFECTION® PLAN(S)"
            />
          </SectionGroup>
          <AdditionalQuotes isSpecialty />
          <Footer
            isSpecialty
            easterEggCreated={easterEggCreated}
            onCompleteButtonClick={onCompleteButtonClick}
            completeButtonEnabled={completeButtonEnabled}
            saveDraft={onSaveDraft}
            showContractButton={
              !!bidFullData?.BidLink &&
              values.status === 1 &&
              values.sentStatus &&
              franchiseData &&
              franchiseData?.IsOptInCleanSuite
            }
            saveTemplate={onSaveTemplate}
            duplicateProposal={onDuplicateProposal}
            showDuplicateButton={!franchiseData?.IsOptInCleanSuite}
          />
        </div>
      </div>
      {warningModal && (
        <ActionAlertModal
          confirmText={warningModal.confirmText}
          dismissText={warningModal.dismissText}
          title={warningModal.title}
          message={warningModal.message}
          onConfirm={warningModal.proceed}
          onDismiss={warningModal.dismiss}
          isVisible={true}
        />
      )}
      <ActionAlertModal
        title="Exit without saving?"
        message="Remember, that if you exit without saving, you will lose all progress."
        onConfirm={() => {
          setShowExit(true);
          if (isWebView()) {
            props.history.push('/close-form');
          } else {
            document.location.href = getSpecialtyURL();
          }
        }}
        onDismiss={() => setShowExit(false)}
        isVisible={showExit}
      />

      <ActionAlertModal
        message={errors}
        onConfirm={() => setErrors({})}
        isVisible={Object.keys(errors).length > 0}
      />

      {showMenu && (
        <GenericModal
          isOpen={showMenu}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="710px"
          width="600px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <BidFormMenuScreen
            isSpecialty={true}
            {...props}
            showBackground={false}
            {...showMenu}
            doneAction={(state) => {
              setShowMenu(null);
              setIsLoading(state);
              if (!state) {
                setAutoSaveTimeout(1);
                setExecuteAutoSave(Math.random());
                setReady(true);
              }
            }}
          />
        </GenericModal>
      )}

      {showPdf && (
        <GenericModal
          isOpen={showPdf}
          showHeader={false}
          clickOutsideToClose={false}
          fullscreen={false}
          height="100%"
          width="100%"
          // maxWidth="1200px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <RenderPdf
            onClickClose={() => setShowPdf(false)}
            pdfName={`${values.pid}_SpSvcAgreement`}
            pdfUrl={
              bidFullData.IsPhysicalSigned ? bidFullData.PhysicalURL : undefined
            }
            type={21}
            uid={values.uid}
          />
        </GenericModal>
      )}

      {showConfirmation && <ConfirmationModal {...confirmationProps} />}
      {showLoadTemplateModal && (
        <LoadTemplateModal
          location={props.location}
          doneAction={() => {
            setShowLoadTemplateModal(false);
          }}
          isSpecialty
          franchiseName={franchiseData?.AnagoOfCityName}
          navigateToForm={navigateToForm}
        />
      )}
    </FormProvider>
  );
};

const mapForm = {
  mapPropsToValues: () => ({
    ...defaultValues,
    isSpecialty: 1,
  }),
  handleSubmit: () => '',
  enableReinitialize: true,
};

const FormEnhanced = withFormik<any, IBidInfoForm>(mapForm)(
  withRouter(SpecialtyForm)
);

export { FormEnhanced as SpecialtyForm };
