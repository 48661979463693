import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  validateEmail,
  validatePhone,
  validatePostalCode,
} from 'core/validators/utils';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InspectFormActions } from 'modules/InspectForm/redux/actions';
import * as Sentry from '@sentry/react';

// interfaces
import { IReduxState } from 'core/interfaces';
import {
  IStateToProps,
  IDispatchToProps,
} from 'modules/InspectForm/interfaces';
import { getQueryString } from 'core/utils';
import { isAlive } from 'modules/BidInfoForm/services/bidInfoService';

// ui
import './styles.scss';
import 'styles/colors.scss';
import { ActionAlertModal } from '../../../../../../core/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import addNoteIcon from '../../../../../../assets/icons/add-note.svg';

import OverwriteOptionsModal from 'core/components/OverwriteOptionsModal';

import { getFranchiseById } from '../../../../../BidInfoForm/services/bidInfoService';
import { validateInspectionReportTemplate } from '../../../../services';

interface IOwnProps {
  setNeedsSaving(needsSaving: boolean): void;
}

type IProps = IDispatchToProps &
  IStateToProps &
  IOwnProps &
  RouteComponentProps;

class FooterBar extends React.PureComponent<IProps> {
  public state = {
    showSaveBubble: false,
    hideButtonDesktop: '',
    warningModal: null,
    pidUrlParam: null,
    isOverwriteModalVisible: false,
  };

  public componentDidMount() {
    const currentUrl = this.props.location.search;
    const hideButtonDesktop = getQueryString(currentUrl, 'hideButtonDesktop');
    const pidUrlParam = getQueryString(currentUrl, 'pid');

    this.setState({
      hideButtonDesktop,
      pidUrlParam,
    });
  }

  public onClickSave = () => {
    const { showSaveBubble } = this.state;
    this.setState({ showSaveBubble: !showSaveBubble });
  };

  public validateFormData = () => {
    const incomp = [];

    if (!this.props.AccountName) {
      const el = document.querySelector('.validate-company-name');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      const el = document.querySelector('.validate-company-name');
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }

    if (this.props.Phone) {
      let el = document.querySelectorAll('.validate-phone')[0];
      if (!validatePhone(this.props.Phone)) {
        incomp.push('OFFICE PHONE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    if (this.props.Zip) {
      let el = document.querySelectorAll(
        '.postal-code-field-container > div'
      )[0];
      if (!validatePostalCode(this.props.Zip.replace(/\_/, ''))) {
        incomp.push('POSTAL CODE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    if (this.props.DecisionMakerEmail) {
      let el = document.getElementsByClassName(
        'validate-decision-maker-email'
      )[0];
      if (!validateEmail(this.props.DecisionMakerEmail)) {
        incomp.push('DECISION MAKER EMAIL');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    if (this.props.WalkThruEmail) {
      let el = document.getElementsByClassName(
        'validate-walkthru-maker-email'
      )[0];
      if (!validateEmail(this.props.WalkThruEmail)) {
        incomp.push('WALKTHRU EMAIL');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    if (this.props.DecisionMakerPhone) {
      let el = document.getElementsByClassName('validate-phone-decision')[0];
      if (
        !this.props.locationId &&
        !validatePhone(this.props.DecisionMakerPhone)
      ) {
        incomp.push('DECISION MAKER PHONE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    if (this.props.WalkThruPhone) {
      let el = document.getElementsByClassName('validate-phone-walk')[0];
      if (!this.props.locationId && !validatePhone(this.props.WalkThruPhone)) {
        incomp.push('WALK-THRU PHONE');
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    }

    return incomp;
  };

  public validateAndCreateOrEditTemplate = async (shouldChangePage = false) => {
    const { PID, FranchiseId, originalPid, setAccountInfo, isTemplate } =
      this.props;

    const currentUrl = this.props.location.search;
    const franchiseId = getQueryString(currentUrl, 'FranchiseId');

    const { data } = await validateInspectionReportTemplate({
      pid: PID,
      franchiseId: FranchiseId || franchiseId,
    });

    const isFound = data?.inspectionReportTemplate?.IsFound;
    const inspectionId = data?.inspectionReportTemplate?.InspectionReportId;

    if (isFound && PID !== originalPid) {
      this.props.setAccountInfo({ TemplateId: inspectionId });
      this.setState({ isOverwriteModalVisible: true });
    } else if (isFound) {
      setAccountInfo({ originalPid: PID });
      this.updateTemplate(false, inspectionId);
    } else if (isTemplate) {
      setAccountInfo({ originalPid: PID });
      this.updateTemplate(false, inspectionId);
    } else {
      setAccountInfo({ originalPid: PID });
      this.saveTemplate(shouldChangePage);
    }
  };

  public saveTemplate = async (shouldChangePage = false) => {
    const { setLoadingState, isSent } = this.props;

    this.setState({
      isOverwriteModalVisible: false,
    });

    try {
      const errors = this.validateFormData();

      if (errors.length > 0) {
        this.setState({
          warningModal: {
            message: { incorrect: errors, required: [] },
            proceed: () => {
              this.setState({ warningModal: null });
            },
          },
        });
        return;
      }
    } catch (e) {
      console.log({ e });

      return;
    }

    try {
      await isAlive();
    } catch (e) {
      this.setState({
        warningModal: {
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            this.setState({ warningModal: null });
          },
        },
      });

      Sentry.captureException(e);

      return;
    }

    setLoadingState(true);

    window.scrollTo(0, 0);

    if (isSent) {
      this.props.createTemplateFromSent();
    } else if (shouldChangePage) {
      this.onClickOptions('citytemplate', 'new');
    } else {
      this.props.createTemplateFromDraft();
    }
  };

  public updateTemplate = async (shouldChangePage = false, id = 0) => {
    const {
      PID,
      setLoadingState,
      isSent,
      FranchiseId,
      setAccountInfo,
      TemplateId,
    } = this.props;

    const currentUrl = this.props.location.search;
    const franchiseId = getQueryString(currentUrl, 'FranchiseId');

    this.setState({
      isOverwriteModalVisible: false,
    });

    try {
      const errors = this.validateFormData();
      if (errors.length > 0) {
        this.setState({
          warningModal: {
            message: { incorrect: errors, required: [] },
            proceed: () => {
              this.setState({ warningModal: null });
            },
          },
        });
        return;
      }
    } catch (e) {
      console.log({ e });

      return;
    }

    try {
      await isAlive();
    } catch (e) {
      this.setState({
        warningModal: {
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            this.setState({ warningModal: null });
          },
        },
      });

      Sentry.captureException(e);

      return;
    }

    setLoadingState(true);

    window.scrollTo(0, 0);

    const { data } = await validateInspectionReportTemplate({
      pid: PID,
      franchiseId: FranchiseId || franchiseId,
    });

    const inspectionTemplateId =
      data?.inspectionReportTemplate?.InspectionReportId;

    if (isSent) {
      this.props.createTemplateFromSent(inspectionTemplateId);
    } else if (TemplateId) {
      this.props.updateTemplate(TemplateId, shouldChangePage);
    } else {
      setAccountInfo({ originalPid: PID });
      this.props.updateTemplate(id || inspectionTemplateId, shouldChangePage);
    }
  };

  public onClickOptions = async (
    type: 'draft' | 'template' | 'sent' | 'citytemplate' | 'duplicate',
    newForm?: 'new' | 'notnew'
  ) => {
    const {
      isDraft,
      isSent,
      isOptInCleanSuite,
      IsOptInCleanSuite,
      PID,
      setAccountInfo,
    } = this.props;

    this.setState({
      isOverwriteModalVisible: false,
    });

    try {
      const errors = this.validateFormData();

      console.log('errors: ', errors);

      if (errors.length > 0) {
        this.setState({
          warningModal: {
            message: { incorrect: errors, required: [] },
            proceed: () => {
              this.setState({ warningModal: null });
            },
          },
        });
        return;
      }
    } catch (e) {
      console.log({ e });
      // Sentry.captureException(e);
      return;
    }

    try {
      await isAlive();
    } catch (e) {
      this.setState({
        warningModal: {
          message:
            "It looks like you don't have internet at the moment, please ensure you have internet access and try again.",
          proceed: () => {
            this.setState({ warningModal: null });
          },
        },
      });

      Sentry.captureException(e);
      return;
    }

    window.scrollTo(0, 0);

    const currentUrl = this.props.location.search;
    const franchiseId = getQueryString(currentUrl, 'FranchiseId');
    const userId = getQueryString(currentUrl, 'userId');
    const id = getQueryString(currentUrl, 'id');

    if (type !== 'duplicate') {
      const { saveReportRequest } = this.props;

      this.setState({ showSaveBubble: false });

      if (isDraft && !(isOptInCleanSuite || IsOptInCleanSuite)) {
        if (type === 'draft' || type === 'sent') {
          saveReportRequest(type, type === 'sent' && id ? 'notnew' : '');
        } else {
          saveReportRequest(type, 'saveas');
        }
      } else {
        setAccountInfo({ originalPid: PID });
        saveReportRequest(type, newForm);
      }

      if (this.props.onSaveCallback) {
        this.props.onSaveCallback();
      }
    } else {
      const proceed = () => {
        this.props.setLoadingState(true);

        const newValues = { ...this.props.values };

        newValues.id = null;
        newValues.InspectionReportId = null;
        newValues.inspectionReportId = null;
        newValues.formId = null;

        localStorage.setItem('INSPECTION_DUPLICATE', JSON.stringify(newValues));
        window.location.replace(
          `#/inspection-form?userId=${userId}&franchiseId=${franchiseId}&isDuplicate=true&v=${Math.random()
            .toString(36)
            .substring(7)}`
        );
        window.location.reload();
      };

      if (!isSent) {
        this.setState({
          warningModal: {
            message:
              'Proceeding will lose any unsaved changes. Are you sure you will wish to proceed?',
            dismiss: () => this.setState({ warningModal: null }),
            proceed: proceed,
          },
        });
      } else {
        proceed();
      }
    }
  };

  public closeSaveBubble = () => {
    this.setState({ showSaveBubble: false });
  };

  public render() {
    const { showSaveBubble, hideButtonDesktop, isOverwriteModalVisible } =
      this.state;

    const {
      setNotesModalState,
      isDraft,
      isTemplate,
      isCityTemplate,
      transformedToDraft,
      isOptInCleanSuite,
      IsOptInCleanSuite,
      isUserRole,
      IsTemplate,
    } = this.props; // saveReportRequest

    const isSentQueryString =
      getQueryString(window.location.href, 'isSent') == 'true';
    const isSent = isSentQueryString;

    const isDraftPopupDisabledQueryString =
      getQueryString(window.location.href, 'draftPopupDisabled') == 'true';
    const isDraftPopupDisabled = isDraftPopupDisabledQueryString;

    const locationIdQueryString = getQueryString(
      window.location.href,
      'locationId'
    );
    const externalIdQueryString = getQueryString(
      window.location.href,
      'externalId'
    );

    const locationId = !Number.isNaN(Number(locationIdQueryString))
      ? locationIdQueryString
      : !Number.isNaN(Number(externalIdQueryString))
      ? externalIdQueryString
      : null;

    if (isTemplate && isUserRole) {
      return null;
    }

    return (
      <div className="form-footer">
        <div className="content">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  background: `url(${addNoteIcon}) no-repeat`,
                  width: '32px',
                  height: '32px',
                }}
                onClick={() => setNotesModalState(true)}
              />
            </div>

            {!isTemplate ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {!isSent && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      pointerEvents: isSent ? 'none' : 'auto',
                    }}
                    onClick={() => {
                      const type =
                        !isSent && isDraft
                          ? 'draft'
                          : isTemplate || isCityTemplate || IsTemplate
                          ? 'citytemplate'
                          : 'draft';

                      const isNew = type === 'draft' && transformedToDraft;

                      if (type) {
                        if (isNew) {
                          this.onClickOptions(type, 'new');
                        } else {
                          this.onClickOptions(type, 'notnew');
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ fontSize: 36 }}
                      size={'1x'}
                      color={'black'}
                    />
                  </div>
                )}

                {!((isOptInCleanSuite || IsOptInCleanSuite) && !locationId) &&
                  !isUserRole &&
                  !isSent &&
                  (isDraft || transformedToDraft || !isTemplate) && (
                    <button
                      style={{
                        marginLeft: '35px',
                      }}
                      disabled={hideButtonDesktop === 'true'}
                      className={`button-secondary ${
                        hideButtonDesktop === 'true'
                          ? 'disabled button-dark'
                          : ''
                      }`}
                      onClick={() =>
                        isOptInCleanSuite || IsOptInCleanSuite
                          ? this.updateTemplate()
                          : this.validateAndCreateOrEditTemplate()
                      }
                    >
                      {isOptInCleanSuite || IsOptInCleanSuite
                        ? 'UPDATE TEMPLATE'
                        : 'SAVE TEMPLATE'}
                    </button>
                  )}

                <button
                  style={{
                    marginLeft: '35px',
                  }}
                  disabled={hideButtonDesktop === 'true'}
                  className={`button ${
                    hideButtonDesktop === 'true' ? 'disabled button-dark' : ''
                  }`}
                  onClick={() =>
                    isSent
                      ? this.props.openSentModal()
                      : this.onClickOptions('sent', 'notnew')
                  }
                >
                  {isSent ? 'EMAIL REPORT' : 'COMPLETE'}
                </button>

                {!isUserRole &&
                  !(isOptInCleanSuite || IsOptInCleanSuite) &&
                  isSent && (
                    <button
                      style={{
                        marginLeft: '35px',
                      }}
                      disabled={hideButtonDesktop === 'true'}
                      className={`button-secondary ${
                        hideButtonDesktop === 'true'
                          ? 'disabled button-dark'
                          : ''
                      }`}
                      onClick={this.validateAndCreateOrEditTemplate}
                    >
                      SAVE TEMPLATE
                    </button>
                  )}

                {isSent && !(isOptInCleanSuite || IsOptInCleanSuite) && (
                  <button
                    style={{
                      marginLeft: '35px',
                    }}
                    disabled={hideButtonDesktop === 'true'}
                    className={`button-secondary ${
                      hideButtonDesktop === 'true' ? 'disabled button-dark' : ''
                    }`}
                    onClick={() => this.onClickOptions('duplicate', 'new')}
                  >
                    DUPLICATE
                  </button>
                )}
              </div>
            ) : !isDraftPopupDisabled ? (
              <button
                style={{
                  marginLeft: '35px',
                }}
                className="button-secondary"
                onClick={() => {
                  this.onClickSave();
                  // hideButtonDesktop === 'true'
                  //   ? this.onClickOptions('template', 'new')
                  //   : this.onClickSave();
                }}
              >
                SAVE AS
                {/* {hideButtonDesktop === 'true' ? 'UPDATE TEMPLATE' : 'SAVE AS'} */}
                {showSaveBubble && (
                  <>
                    <div
                      onClick={this.closeSaveBubble}
                      className="save-as-backdrop"
                    />
                    <div className="bubble">
                      <div className="content">
                        <div
                          className={
                            isUserRole ? 'first-row' : 'first-row underlined'
                          }
                          onClick={() => this.onClickOptions('draft', 'new')}
                        >
                          NEW DRAFT
                        </div>
                        {!isUserRole && (
                          <div
                            className="second-row"
                            onClick={this.validateAndCreateOrEditTemplate}
                          >
                            SAVE TEMPLATE
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </button>
            ) : (
              <button
                style={{
                  marginLeft: '35px',
                }}
                disabled={hideButtonDesktop === 'true'}
                className={`button-secondary ${
                  hideButtonDesktop === 'true' ? 'disabled button-dark' : ''
                }`}
                onClick={this.validateAndCreateOrEditTemplate}
              >
                SAVE TEMPLATE
              </button>
            )}
          </div>
        </div>

        {this.state.warningModal && (
          <ActionAlertModal
            style={{ color: 'black' }}
            message={this.state.warningModal.message}
            onConfirm={this.state.warningModal.proceed}
            onDismiss={this.state.warningModal.dismiss}
            isVisible={true}
          />
        )}

        {isOverwriteModalVisible && (
          <OverwriteOptionsModal
            title="Uh oh, it seems you've already have a template with this PID"
            message="If you do not wish to overwrite, please change the template PID before proceeding"
            confirmText="OVERWRITE"
            titleStyle={{
              textTransform: 'inherit',
              fontSize: '22px',
              marginBottom: 0,
            }}
            onConfirm={() => this.updateTemplate(false)}
            onDismiss={() => this.setState({ isOverwriteModalVisible: false })}
            isVisible
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  ...state.inspectForm,
  values: state.inspectForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...InspectFormActions }, dispatch);

const Connected = connect<IStateToProps, IDispatchToProps, {}, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FooterBar));

export { Connected as FooterBar };
