import React from 'react';
import { PropsWithRef } from 'react';

import { TextAreaWrapper } from './style';

const TextArea: React.FC<PropsWithRef> = ({
  onChange,
  onPressEnter,
  inputRef,
  ...otherProps
}) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (onPressEnter && event.key == 'Enter') {
      onPressEnter(event.currentTarget.value);
    }
  };

  return (
    <TextAreaWrapper
      {...otherProps}
      onKeyDown={onKeyDown}
      onChange={e => onChange && onChange(e.target.value)}
      ref={inputRef}
    />
  );
};

export default TextArea;
