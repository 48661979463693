import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { ActionAlertModal } from './core/components';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { IWarningModal } from './modules/shared/bidInterfaces';
import { useAuthenticationHandler } from './core/hooks/useAuthenticationHandler';
const env = runtimeEnv();

const dsn = env.REACT_APP_SENTRY_DSN;

// Sentry.init({
//   dsn: `${dsn}`,
// });

const transactionId = Math.random().toString(36).substr(2, 5).toUpperCase();

// Sentry.configureScope(function (scope) {
//   scope.setTag('transaction_id', transactionId);
// });

sessionStorage.setItem('transaction_id', transactionId);

const AppWrapper = (props) => {
  const [warningModal, setWarningModal] = React.useState<IWarningModal>(null);

  window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
    setWarningModal({
      message:
        'An unexpected error has occurred. Please contact AFISupport with Error ID: ' +
        sessionStorage.getItem('transaction_id'),
      proceed: () => setWarningModal(null),
    });
    return true;
  };

  return (
    // <Sentry.ErrorBoundary
    //   fallback={'An unexpected error has occurred.'}
    //   showDialog
    // >
    <>
      <App {...props} />
      {warningModal && (
        <ActionAlertModal
          message={warningModal.message}
          onConfirm={() => setWarningModal(null)}
          isVisible={true}
        />
      )}
    </>
    // </Sentry.ErrorBoundary>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

serviceWorker.unregister();
