import React, { useState, useEffect } from 'react';
import { Layout, ModalWrapper } from '../../components/layout';
import { Actions } from './styles';
import { Button, ActionAlertModal } from 'core/components';
import { faRedo, faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DoneButton,
  DoneButtonWrapper,
} from 'modules/BidInfoForm/components/atoms';

import { TextAreaInput, SectionTitle } from '../../styles';
import { toast } from 'react-toastify';
import NewCustomizeTerms from '../NewCustomizeTerms';
import { parseTerms } from '../../parser';
import { parseDefaultCustomizeTermsPayloadFromServer } from '../../services/utils';
import { romanize } from '../NewCustomizeTerms/utils';
import IconButton from '../NewCustomizeTerms/components/IconButton';
import { checkIsGeneratingPDF } from '../../services/bidInfoService';

const ResetAllButton: React.FC = ({ onClick}) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '82px',
        top: '20px',
      }}
    >
      <IconButton
        icon={faRedo}
        iconStyle={{
          transformOrigin: 'center',
          transform: 'scaleX(-1)',
        }}
        caption="Reset All"
        onClick={onClick}
      />
    </div>
  );
};

export default ({
  data,
  franchiseData,
  update,
  cancel,
  showing,
  franchiseId,
  isSpecialty,
  setIsLoading,
}) => {
  const [previewState, setPreviewState] = useState(false);
  const [introduction, setIntroduction] = useState<string>('');
  const [introductionModified, setIntroductionModified] =
    useState<boolean>(false);
  const [paragraphs, setParagraphs] = useState([]);
  const [termsDefault, setTermsDefault] = useState([]);
  const [originalValues, setOriginalValues] = useState();
  const [genericModal, setGenericModal] = useState(undefined);

  React.useEffect(() => {
    if (data) {
      const key = isSpecialty ? 'SSDefaultTerms' : 'BidDefaultTerms';

      const dTerms = parseDefaultCustomizeTermsPayloadFromServer(
        isSpecialty,
        data[key]
      );

      setTermsDefault({
        paragraphs: dTerms.paragraphs,
        introduction: dTerms.introduction,
      });

      const terms = parseTerms(data['Terms'], true);
      if (terms) {
        const intr = terms.slice(0, 1);

        const par = terms.slice(1, terms.length);

        setParagraphs(par);
        setIntroduction(intr && intr[0] ? intr[0].value : '');
        setIntroductionModified(intr && intr[0] ? intr[0].modified : false);

        setOriginalValues({
          paragraphs: par,
          introduction: intr && intr[0] ? intr[0].value : '',
        });
      } else {
        setParagraphs(dTerms.paragraphs);
        setIntroduction(dTerms.introduction || '');
        setOriginalValues({
          paragraphs: dTerms.paragraphs,
          introduction: dTerms.introduction,
        });
      }

      // setIsLoading(false);
    }
  }, [data]);

  // setIsLoading(false);

  const onRestoreParagraph = (index: number) => () => {
    const restoreParagraph = () => {
      onChangeParagraph(index)(
        index === -1
          ? termsDefault
            ? termsDefault.introduction
            : ''
          : termsDefault &&
            termsDefault.paragraphs &&
            termsDefault.paragraphs[index]
          ? termsDefault.paragraphs[index].value
          : ''
      );
      setGenericModal(undefined);
    };
    setGenericModal({
      body: () => (
        <p className="alert-message">
          This will reset the default language for <i>this paragraph,</i> are
          you sure you want to proceed?
        </p>
      ),
      onConfirm: restoreParagraph,
    });
  };

  const onDeleteParagraph = (index: number) => () => {
    const deleteParagraph = () => {
      setParagraphs((p) =>
        p
          .filter((i, idx) => idx !== index)
          .map((i, idx) => ({ ...i, position: idx + 1 }))
      );
      setGenericModal(undefined);
    };
    setGenericModal({
      body: () => (
        <p className="alert-message">
          This will delete <i>this entire paragraph,</i> are you sure you want
          to proceed?
        </p>
      ),
      onConfirm: deleteParagraph,
    });
  };

  const onSave = async (callback: () => void = undefined) => {
    const invalidParagraphs = paragraphs
      .filter(
        (i, idx, arr) =>
          ((isSpecialty && idx > 4 ) ||
          (!isSpecialty && idx > 7)) && idx !== arr.length-1)
      .filter((i) => !i.value.trim());

    const errors = {
      required: invalidParagraphs.map(
        (i) => `Paragraph ${romanize(i.position)}`
      ),
      incorrect: [],
    };
    if (invalidParagraphs.length > 0) {
      setGenericModal({
        errors: errors,
        onConfirm: () => setGenericModal(undefined),
        hideDismiss: true,
      });

      return;
    }

    setIsLoading(true);

    try {
      const blankPdfResult = await checkIsGeneratingPDF(data['BidUniqueID']);
    } catch (e) {
      const generating = e.data['isGenerating'];
      if (generating) {
        setGenericModal({
          body: () => (
            <p className="alert-message">
              Previous PDF Version still being generated. Please try again.
            </p>
          ),

          onConfirm: () => {
            setGenericModal(undefined);
          },
          hideDismiss: true,
        });
        setIsLoading(false);
        return;
      }
    }

    const newTerms = [];
    newTerms.push({
      key: 'Introduction',
      systemReference: 'Introduction',
      value: introduction,
      modified: introductionModified,
      position: 0,
    });

    paragraphs.map((p, idx, arr) => {
      newTerms.push({
        key: `Term${idx + 1}`,
        systemReference:
          ((isSpecialty && idx > 4) || (!isSpecialty && idx > 7)) &&
          idx !== arr.length - 1
            ? null
            : idx === arr.length - 1
            ? isSpecialty
              ? `Term6`
              : `Term9`
            : `Term${idx + 1}`,
        value: p.value,
        modified: p.modified,
        position: idx + 1,
      });
    });

    update(newTerms);
  };

  const onChangeParagraph = (index: number) => (value: string) => {
    if (index === -1) {
      setIntroduction(value);
      setIntroductionModified(
        termsDefault ? termsDefault.introduction !== value : true
      );
    } else {
      setParagraphs((paragraphs) => {
        return paragraphs.map((paragraph, idx, arr) => {

          const defaultIdx =  ((isSpecialty && idx > 4) || (!isSpecialty && idx > 7)) &&
          idx !== arr.length - 1
            ? -1
            : idx === arr.length - 1
            ? isSpecialty
              ? 5
              : 8
            : idx


          if (idx === index) {
            return {
              ...paragraph,
              modified: (defaultIdx >= 0 && termsDefault.paragraphs[defaultIdx].value !== value) || defaultIdx == -1,
              position: idx + 1,
              value,
            };
          }
          return paragraph;
        });
      });
    }
  };

  const setOldData = () => {
    const terms = parseTerms(data['Terms'], true);
    if (terms) {
      const intr = terms.slice(0, 1);

      setParagraphs(terms.slice(1, terms.length));
      setIntroduction(intr && intr[0] ? intr[0].value : '');
      setIntroductionModified(intr && intr[0] ? intr[0].modified : false);
    } else {
      setParagraphs(termsDefault.paragraphs);
      setIntroduction(termsDefault.introduction || '');
    }
  };

  const setDefaultData = () => {
      setParagraphs(termsDefault.paragraphs);
      setIntroduction(termsDefault.introduction || '');
      setIntroductionModified(false);
  }

  const onClickRestore = () => {
    setGenericModal({
      body: () => (
        <p className="alert-message">
          This will reset the default language for <i>all the terms,</i> are you
          sure you want to proceed?
        </p>
      ),
      onConfirm: () => {
        setGenericModal(undefined);
        setDefaultData();
      },
    });
  };

  return (
    <ModalWrapper>
      <Layout
        padding="30px 0 40px 0"
        margin="40px"
        maxWidth="900px"
        maxHeight="70vh"
        backgroundColor="white"
        position="relative"
        style={{
          width: 'calc(100% - 10px)',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {!previewState && <ResetAllButton onClick={onClickRestore} />}
        <SectionTitle
          maxFontSize="22px"
          textAlign="center"
          style={{ textAlign: 'center', paddingBottom: '30px' }}
        >
          Customize Service Procurement Agreement Terms
        </SectionTitle>
        <NewCustomizeTerms
          franchiseId={franchiseId}
          isSpecialty={isSpecialty}
          previewState={previewState}
          introduction={introduction}
          introductionModified={introductionModified}
          paragraphs={paragraphs}
          onRestoreParagraph={onRestoreParagraph}
          onChangeParagraph={onChangeParagraph}
          onDeleteParagraph={onDeleteParagraph}
          setParagraphs={setParagraphs}
          setIntroduction={setIntroduction}
        />
      </Layout>
      <Actions>
        <DoneButtonWrapper>
          {!previewState && (
            <DoneButton
              style={{ marginRight: '70px' }}
              onClick={() => {
                setPreviewState(false);
                // setOldData();
                // setParagraphs(originalValues.paragraphs);
                // setIntroduction(originalValues.introduction);

                const proceed = () => {
                  setGenericModal(undefined);
                  cancel();
                };

                if (
                  JSON.stringify(paragraphs) !==
                    JSON.stringify(originalValues.paragraphs) ||
                  JSON.stringify(introduction) !==
                    JSON.stringify(originalValues.introduction)
                ) {
                  setGenericModal({
                    body: () => (
                      <p className="alert-message">
                        If you proceed, you will lose the current customization.
                        Are you sure you want to proceed?
                      </p>
                    ),
                    onConfirm: ()=>{
                      setOldData();
                      proceed();
                    },
                    onDismiss: () => {
                      setGenericModal(undefined);
                    },
                  });
                } else {
                  proceed();
                }
              }}
            >
              <FontAwesomeIcon icon={faReply} />
              BACK
            </DoneButton>
          )}

          {!previewState && (
            <Button
              reverseSkew
              kind="outline"
              color="#eda328"
              bgColor="#fff"
              onClick={() => setPreviewState(true)}
            >
              PREVIEW
            </Button>
          )}

          {previewState && (
            <DoneButton reverseSkew onClick={() => setPreviewState(false)}>
              DONE
            </DoneButton>
          )}

          {!previewState && (
            <Button
              reverseSkew
              bgColor="#eda328"
              onClick={() => {
                onSave();
              }}
            >
              SAVE
            </Button>
          )}
        </DoneButtonWrapper>
      </Actions>
      <ActionAlertModal
        title=""
        message={genericModal && genericModal.errors}
        renderMessage={
          genericModal && !genericModal.errors ? genericModal.body : undefined
        }
        onConfirm={genericModal && genericModal.onConfirm}
        onDismiss={
          genericModal && genericModal.hideDismiss
            ? undefined
            : () => setGenericModal(undefined)
        }
        isVisible={genericModal}
      />
    </ModalWrapper>
  );
};
