import React from 'react';
import ReactDOM from 'react-dom';
import { Input } from './styles';
import {
  capitalizeInput,
  formatNumberByMask,
} from 'core/utils';
import EventEmitter from 'core/utils/events';
import {formatNumberByMaskWithLimit} from 'core/utils/inputFormatsV2';;

interface IBoxInput {
  value: string | number;
  name: string;
  onChange: Function;
  mask?: any;
  maskParams?: any;
  type?: string;
  maxLength?: number;
  alignCenter?: boolean;
  onBlur?: any;
  width?: string;
  color?: string;
  flex?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  capitalizeFunction?: Function;
  inputStyle?: any;
  flexDirection?: string;
  minWidth?: string;
  maxWidth?: string;
  tabIndex?: string;
  id?: string;
  label?: string;
  fieldName?: string;
}


export const BoxInputWithValidation = ({
  name,
  onChange,
  mask,
  maskParams,
  maxLength,
  type,
  alignCenter = false,
  onBlur,
  width,
  color,
  flex,
  disabled,
  inputClassName,
  capitalizeFunction = capitalizeInput,
  flexDirection,
  inputStyle,
  minWidth,
  maxWidth,
  tabIndex,
  id,
  label,
  fieldName,
  ...props
}: IBoxInput) => {
  const [hasError, setHasError] = React.useState(false);
  const [hasFocus, setHasFocus] = React.useState(false);
  const inputRef = React.useRef(null);

  const capitalize = (value: string) => {
    return capitalizeFunction ? capitalizeFunction(value) : value;
  };

  const value =
    typeof props.value === 'string' ? capitalize(props.value) : props.value;

  const paddZeroToRight = (value: string | number) => {
    if ((!value && value != 0) || (typeof value !== 'string' && typeof value !== 'number')) {
      return value;
    }
    if (typeof value === 'number') {
      value = value.toString();
    }
    const thousandSeparatorPosition = value.indexOf(',') >= 0 ? value.indexOf(',') : value.length;
    const decimalSeparatorPosition = value.indexOf('.') >= 0 ? value.indexOf('.') : value.length;
    const diff = decimalSeparatorPosition - thousandSeparatorPosition;
  
    if (diff > 0 && diff <= 3) {
      const pad = new Array(4 - (decimalSeparatorPosition - thousandSeparatorPosition)).fill("0").join("");
      return value.substring(0, decimalSeparatorPosition) + pad + value.substring(decimalSeparatorPosition, value.length);
    }
    return value;
  }
  
  const applyMask = (value: string | number) => {
    if (!maskParams || (!value && value != 0) || (typeof value !== 'string' && typeof value !== 'number')) {
      return value;
    }
    if (typeof value === 'number') {
      value = value.toString();
    }

    const withMask = `${formatNumberByMask(paddZeroToRight(value), maskParams)}`;
    return withMask;
  };
  

  const onBlurWrapper = (fieldValue: string) => {
    setHasFocus(false);
    const trimmedValue = fieldValue.toString().trim()

    try {
      if (trimmedValue == '') {
        setHasError(false);
        if (onBlur) {
          onBlur(name, trimmedValue);
        } else {
          onChange(name, trimmedValue);
        }
        return;
      }
      const value = applyMask(trimmedValue);
      const numberValue = Number(
        value.toString().replace(',', '')
      );
      if (
        value && trimmedValue !== ',' &&
        !isNaN(numberValue) && numberValue >= 0 
        // && (maskParams.integerLimit && trimmedValue.split(".")[0].length <= maskParams.integerLimit || !maskParams.integerLimit)
      ) {
        if (onBlur) {
          onBlur(name, `${value}`);
        } else {
          onChange(name, `${value}`);
        }
      } else {
        setHasError(true);
        return;
      }
    } catch (e) {
      setHasError(true);
      return;
    }
    setHasError(false);
  };

  const onKeyDown = (e: any) => {
    let cursorPos = Number(e.target.selectionStart);
    let keyCode = Number(e.keyCode);

    if (cursorPos === 0 && keyCode === 194) {
      e.preventDefault();
      e.target.value = '0.';
      e.target.selectionStart = 2;
      if (onChange) {
        onChange(name, e.target.value);
      }
    }
  };

  const getValue = () => {
    const v = (!!value || value === 0 || value === "0")
      ? `${maskParams && maskParams.prefix && !hasFocus && !hasError
        ? maskParams.prefix
        : ''
      }${!hasFocus && !hasError ? applyMask(value) : value}`
      : ''
    return v;
  }

  // React.useEffect(() => {
  //   if (inputRef.current) {
  //     ReactDOM.findDOMNode(inputRef.current).setAttribute('data-label', fieldName || label);
  //   }
  // }, [inputRef.current])

  const validateFieldsListener = ()=> {
    const el = document.getElementById(id || name);
    const v = el ? el.value : null;
    if(v){
      setHasFocus(false);
      const trimmedValue = v.toString().trim()
      try {
        if (trimmedValue == '') {
          setHasError(false);
          return;
        }
        const value = applyMask(trimmedValue);
        const numberValue =  Number(
          value.toString().replace(',', '')
        );
        if (
          value && trimmedValue !== ',' &&
          !isNaN(numberValue) && numberValue >= 0 
          // && (maskParams.integerLimit && trimmedValue.split(".")[0].length <= maskParams.integerLimit || !maskParams.integerLimit)
        ) {
        } else {
          setHasError(true);
          return;
        }
      } catch (e) {
        setHasError(true);
        return;
      }
      setHasError(false);
    }
  }

  React.useEffect(() => {
    EventEmitter.on('VALIDATE_FIELDS', validateFieldsListener)
    return () => {
      EventEmitter.removeListener('VALIDATE_FIELDS', validateFieldsListener);
    };
  }, []);

  return (
    <Input
      value={
        getValue()
      }
      ref={inputRef}
      onKeyDown={onKeyDown}
      key={name}
      name={name}
      type={type}
      className={`${inputClassName || ''} ${hasError ? 'has-error is-invalid' : ''
        }`}
      onChange={(e: any) => onChange(name, capitalize(e.target.value))}
      maxLength={maxLength}
      center={alignCenter}
      onBlur={(e) => onBlurWrapper(e.target.value)}
      onFocus={(e: any) => {
        const target = e.target;
        setHasFocus(true);
        if (target) {
          setTimeout(() => {
            target.select();
          });
        }
      }}
      width={width}
      flex={flex}
      flexDirection={flexDirection}
      disabled={disabled}
      color={color}
      style={inputStyle}
      autoCapitalize="words"
      tabIndex={tabIndex}
      id={id || name}
      minWidth={minWidth}
      maxWidth={maxWidth}
      data-label={fieldName || label}
      data-section-id={props.sectionId}
    />
  );
};