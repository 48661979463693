export const romanize = (num: number) => {
    if (isNaN(num)) return NaN;
    const digits = String(+num).split(''),
      key = [
        '',
        'C',
        'CC',
        'CCC',
        'CD',
        'D',
        'DC',
        'DCC',
        'DCCC',
        'CM',
        '',
        'X',
        'XX',
        'XXX',
        'XL',
        'L',
        'LX',
        'LXX',
        'LXXX',
        'XC',
        '',
        'I',
        'II',
        'III',
        'IV',
        'V',
        'VI',
        'VII',
        'VIII',
        'IX',
      ];
    let roman = '',
      i = 3;
    while (i--) {
      const digit = digits.pop() || 0;
      roman = (key[+digit + i * 10] || '') + roman;
    }
    roman += '.';
    return Array(+digits.join('') + 1).join('M') + roman;
  };
  