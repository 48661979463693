import React from 'react';

import { ContentInput } from 'core/components';
import { CollapsibleSection } from '../../../../components/templates';

import './styles.scss';

export const SectionGroup = (props) => {
  const [heightChanged, setHeightChanged] = React.useState(false);

  const enhancedChildren = [];

  React.Children.map(props.children, (child, idx) => {
    const backgroundColor = idx % 2 > 0 ? '#ededed' : 'white';

    enhancedChildren.push(
      <CollapsibleSection
        containerStyle={!Array.isArray(props.children) ? { marginTop: 0 } : {}}
        headeTitle={child.props.title}
        backgroundColor={backgroundColor}
        heightChanged={heightChanged}
        canOpenSection={child.props.canOpenSection}
        disabled={child.props.disabled}
        shouldKeepHidden={child.props.shouldKeepHidden}
        sectionId={child.props.sectionId}
        isExpandedByDefault={child.props.isExpandedByDefault}
      >
        {React.cloneElement(child, {
          ...props,
          backgroundColor,
          heightChanged,
          setHeightChanged,
        })}
      </CollapsibleSection>
    );
  });

  return (
    <div className="section-group-root">
      <div className="section-group-content">
        {enhancedChildren.length > 1 && (
          <ContentInput width="100%" justify="flex-start">
            <h3 className="section-group-title">{props.title}</h3>
          </ContentInput>
        )}
        {enhancedChildren}
      </div>
    </div>
  );
};
