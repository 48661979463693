import React, { useRef, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SVGIcon } from 'core/components';
import DragIcon from './assets/Drag.svg';
import { ReferenceDetail } from 'modules/shared/sections/Summary';
import GoogleReview from '../../../../../assets/img/google-review.png';

export declare type Identifier = string | symbol;
export interface XYCoord {
  x: number;
  y: number;
}
export interface PreviewReferenceItemProps {
  id: string | number;
  referenceDetails: ReferenceDetail[];
  index: number;
  withDrag?: boolean;
  onMoveCard: (referenceDetail: ReferenceDetail[]) => void;
}

export const PreviewReferenceItem = ({
  referenceDetails: referenceDetailsProp,
  withDrag = true,
  index,
  onMoveCard,
}: PreviewReferenceItemProps) => {
  const [list, setList] = useState(referenceDetailsProp);
  const contentRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      const content = document.querySelector(
        `.preview-reference-item-content[data-index="${index}"]`
      ) as HTMLElement;
      if (content) {
        const newHeight = `${content.offsetHeight + 20}px`;
        content.style.height = newHeight;
      }
    }, 300);
  }, [contentRef]);

  useEffect(() => {
    if (referenceDetailsProp && !list) {
      setList(referenceDetailsProp);
    }
  }, [referenceDetailsProp]);

  const reorder = (
    list: ReferenceDetail[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      // change background colour if dragging
      background: isDragging ? '#fafafa' : '#ffffff',
      width: '100%',

      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };

  const getListStyle = () => ({
    width: '100%',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);

    let minimunIndex = items.map((item) => item.Order).sort((a, b) => a - b)[0];
    items.forEach((item) => {
      item.Order = minimunIndex;
      minimunIndex = minimunIndex + 1;
    });

    setList(items);
    onMoveCard(items);
  };

  const renderTestimonialReview = (referenceDetailItem: ReferenceDetail) => {
    const text =
      referenceDetailItem.TestimonialReview &&
      referenceDetailItem.TestimonialReview.split(/\r\n|\r|\n/);
    return (
      text &&
      text.map((item, i) => (
        <>
          <span>{item}</span>
          {i < text.length - 1 && <br />}
        </>
      ))
    );
  };

  const renderTestimonial = (referenceDetailItem: ReferenceDetail) => (
    <div
      key={referenceDetailItem.ReferenceDetailId}
      style={{ display: 'flex', alignItems: 'flex-start', padding: '20px' }}
    >
      {withDrag && (
        <div className="dragIcon">
          <SVGIcon icon={DragIcon} />
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {referenceDetailItem.TestimonialReview && (
          <p className="preview-reference-item-testimonial">
            {renderTestimonialReview(referenceDetailItem)}
          </p>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: 600,
          }}
        >
          {referenceDetailItem.Name} | {referenceDetailItem.Title}{' '}
          {referenceDetailItem.Company
            ? `| ${referenceDetailItem.Company}`
            : ''}
        </div>
      </div>
    </div>
  );

  const renderGoogleReview = (referenceDetailItem: ReferenceDetail) => (
    <div
      key={referenceDetailItem.ReferenceDetailId}
      style={{ display: 'flex', alignItems: 'flex-start', padding: '20px' }}
    >
      {withDrag && (
        <div className="dragIcon">
          <SVGIcon icon={DragIcon} />
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {referenceDetailItem.Name && (
          <p
            className="preview-reference-item-name"
            style={{ display: 'flex', marginBottom: '5px' }}
          >
            {referenceDetailItem.Name}
            <img
              src={GoogleReview}
              style={{ marginLeft: '10px' }}
              alt="stars"
            />
          </p>
        )}
        {referenceDetailItem.TestimonialReview && (
          <p className="preview-reference-item-testimonial">
            {renderTestimonialReview(referenceDetailItem)}
          </p>
        )}
      </div>
    </div>
  );

  const getContactType = (contactType) => {
    if (!contactType) {
      return 'Contact';
    }
    if (contactType === 'Cell') {
      return 'Cell Phone';
    }

    if (contactType === 'Work') {
      return 'Work Phone';
    }
    return contactType;
  };

  const renderReference = (referenceDetailItem: ReferenceDetail) => (
    <div
      key={referenceDetailItem.Order}
      style={{ display: 'flex', alignItems: 'flex-start', padding: '20px' }}
    >
      {withDrag && (
        <div className="dragIcon">
          <SVGIcon icon={DragIcon} />
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          gap: '5px',
        }}
      >
        {referenceDetailItem.Name && (
          <div
            className="preview-reference-item-container"
            style={{ width: '24%' }}
          >
            <p
              className="preview-reference-item-name"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              {referenceDetailItem.Name}
            </p>
            <p
              className="preview-reference-item-industry"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              Name
            </p>
          </div>
        )}
        {referenceDetailItem.Title && (
          <div
            className="preview-reference-item-container"
            style={{ width: '24%' }}
          >
            <p
              className="preview-reference-item-name"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              {referenceDetailItem.Title}
            </p>
            <p
              className="preview-reference-item-industry"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              Title
            </p>
          </div>
        )}
        {referenceDetailItem.Company && (
          <div
            className="preview-reference-item-container"
            style={{ width: '24%' }}
          >
            <p
              className="preview-reference-item-name"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              {referenceDetailItem.Company}
            </p>
            <p
              className="preview-reference-item-industry"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              Company
            </p>
          </div>
        )}
        {referenceDetailItem.ContactInfo && (
          <div
            className="preview-reference-item-container"
            style={{ width: '24%' }}
          >
            <p
              className="preview-reference-item-name"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              {referenceDetailItem.ContactInfo}
            </p>
            <p
              className="preview-reference-item-industry"
              style={{ textAlign: 'left', lineHeight: '18px' }}
            >
              {getContactType(referenceDetailItem.ContactType)}
            </p>
          </div>
        )}
      </div>
    </div>
  );

  const renderContent = (referenceDetailItem: ReferenceDetail) => {
    if (Number(referenceDetailItem.Type) === 1) {
      return renderGoogleReview(referenceDetailItem);
    } else if (Number(referenceDetailItem.Type) === 2) {
      return renderTestimonial(referenceDetailItem);
    }
    return renderReference(referenceDetailItem);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable2">
        {(provided) => (
          <div
            className="preview-reference-item-content"
            data-index={index}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
          >
            {list.map((referenceDetailItem: ReferenceDetail, i: number) => (
              <Draggable
                key={`item-each-${referenceDetailItem.ReferenceDetailId}`}
                draggableId={`item-each-${referenceDetailItem.ReferenceDetailId}`}
                index={i}
                isDragDisabled={!withDrag}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {renderContent(referenceDetailItem)}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
