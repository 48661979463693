import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { ButtonComponent, Modal, SVGIcon } from 'core/components';
import SaveIcon from './assets/SaveIcon.svg';
import BackIcon from './assets/BackIcon.svg';
import CloseIcon from './assets/Close.svg';
import { PreviewItem } from './PreviewItem';
import Image1 from './assets/supplies-header-proposal.png';
import PreviewContent from './PreviewContent';

interface SupplyDetail {
  SupplyDetailId: number | string;
  SupplyId: number | string;
  SupplyCategory: string;
  BrandInfo: string;
  Unit: string;
  Price: number;
  LastUpdate: string;
  UserId: number | string;
  Order: number;
  UserFirstName?: string;
  UserLastName?: string;
  Default: boolean;
}

interface PreviewProps {
  opened: boolean;
  onClose: (e?: any) => void;
  supplyDetails: SupplyDetail[];
  introductionText: string;
  withDrag?: boolean;
  onSave?: (supplyDetails: SupplyDetail[]) => void;
  showPrice?: boolean;
}

export const Preview: React.FC<PreviewProps> = ({
  opened,
  onClose,
  supplyDetails = [],
  introductionText,
  withDrag = true,
  onSave,
  showPrice,
}) => {
  const [currentSupplyDetails, setCurrentSupplyDetails] = useState(
    supplyDetails,
  );

  useEffect(() => {
    setTimeout(() => {
      const modal = document.querySelector('.modal-content') as HTMLElement;
      if (modal !== null) {
        const newHeight = `${modal.offsetHeight + 80}px`;
        modal.style.height = newHeight;
      }
    }, 500);
  }, [opened]);

  useEffect(() => setCurrentSupplyDetails(supplyDetails), [supplyDetails]);

  const saveOrderPreview = async () => {
    if (onSave) {
      onSave(currentSupplyDetails);
    }
    toast.info('Re-ordered with success!');
    onClose()
  };

  const onDragEnd = (result: any) => {
    setCurrentSupplyDetails(result);
  };

  return (
    <Modal onClose={onClose} opened={opened} className="preview-modal">
      <div className="modal-content">
        <div className="preview-close">
          <SVGIcon icon={CloseIcon} onClick={onClose} />
        </div>
        <PreviewContent 
          onDragEnd={onDragEnd}
          supplies={currentSupplyDetails}
          introductionText={introductionText}
          withDrag={withDrag}
          showPrice={showPrice}
        />
      </div>
      {withDrag && (
        <div className="confirm-buttons fixed edit-buttons">
          <ButtonComponent icon={BackIcon} onClick={onClose}>
            CANCEL
          </ButtonComponent>
          <ButtonComponent icon={SaveIcon} onClick={saveOrderPreview} secondary>
            SAVE
          </ButtonComponent>
        </div>
      )}
    </Modal>
  );
};

export default Preview;
