/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
// ui
import classNames from 'classnames';
import { useFormProps, ButtonComponent } from 'core/components';

export default (props) => {
  const [initialReferenceName, setInitialReferenceName] = useState();
  const { onChange, formValues } = useFormProps();
  const { background, className, onSaveTemplate, isCityTemplate, onCancel, isTemplate = false } = props;
  const fieldName = isTemplate ? 'referenceName' : 'newReferenceName';

  const handleCancel = () => {
    onChange(fieldName, initialReferenceName);
    onCancel();
  }

  useEffect(() => {
    setInitialReferenceName(formValues[fieldName]);
  }, [fieldName])
  
  return (
    <>
      <div
        className={
          background ? 'modal-background-bid' : 'modal-bid-no-background'
        }
      >
        <div 
          className={classNames("save-template-modal", "modal-content-container", className)}
          style={{
            width: '500px',
            height: '500px',
            minHeight: 'auto',
            margin: 'auto',
          }}
        >
          <div className="modal-title-container">
            <h3>
              {isCityTemplate ? 'Save as - City template' : 'Save as - My template'}</h3>
          </div>

          <div className="bid-menu-container">
            <div className="bid-input-content" style={{ width: '300px', padding: '10px 20px 10px 0' }}>
              <div className="bid-input-label" style={{ color: '#000000' }}>TEMPLATE NAME</div>
              <input
                value={formValues[fieldName]}
                onChange={(e) => onChange(fieldName, e.target.value)}
                maxLength={24}
                className="input-header input-bid"
                style={{ borderColor: '#000000', borderWidth: '1px' }}
              />
            </div>

            <div className="bid-input-content" style={{ width: '100%', padding: '10px 20px 10px 0' }}>
              <div className="bid-input-label" style={{ color: '#000000' }}>DESCRIPTION</div>
              <textarea
                value={formValues.description}
                onChange={(e) => onChange('description', e.target.value)}
                maxLength={140}
                className="input-header input-bid"
                style={{ borderColor: '#000000', borderWidth: '1px', width: '100%', height: '80px' }}
              />
            </div>
          </div>
          <div 
            className="modal-done-button" 
            style={{ gap: '80px' }}
          >
            <ButtonComponent onClick={handleCancel}>
              CANCEL
            </ButtonComponent>
            <ButtonComponent secondary onClick={onSaveTemplate} className="button">SAVE TEMPLATE</ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};
